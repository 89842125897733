import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { akitaDevtools } from '@datorama/akita';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';

import { environment } from '../environments/environment';

import { errorHandlerFactory } from './app.error-handler';
import { ShellPage } from './shell/shell/shell.page';
import { SummaPortalShellModule } from './shell/summa-portal-shell.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AkitaNgRouterStoreModule,
    MatDialogModule,
    RouterModule.forRoot([], { initialNavigation: 'enabledBlocking', preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
    SummaPortalShellModule,
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    { provide: ErrorHandler, useFactory: errorHandlerFactory, deps: [] },
  ],
  bootstrap: [ShellPage],
})
export class AppModule {
  constructor(ngZone: NgZone) {
    if (!environment.production) {
      akitaDevtools(ngZone, {});
    }
  }
}
