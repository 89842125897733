import { gql } from 'apollo-angular';

export const ControlPanelFields = gql`
  fragment ControlPanelFields on ControlPanel {
    id
    name
    projectKey
    description
    created
    modified
    removed
    devices {
      id
      key
    }
    defaultScene {
      id
      name
    }
    scenarios {
      id
      name
      sortOrder
      icon
      hidden
      type
      isActive
    }
    controls {
      id
      name
      icon
      sort
      isActive
      entities {
        device {
          id
          key
        }
        group {
          id
          title
          sortOrder
          hidden
        }
        scene {
          id
          name
          sortOrder
        }
      }
    }
  }
`;
