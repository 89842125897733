import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { NotificationPreviewSandbox } from './notification-preview.sandbox';

@Component({
  selector: 'summa-notification-preview',
  templateUrl: './notification-preview.page.html',
  styleUrls: ['./notification-preview.page.scss'],
  providers: [NotificationPreviewSandbox],
})
export class NotificationPreviewPage implements OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: NotificationPreviewSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getType(notification: DOMAIN.Notification): string {
    switch (notification.type) {
      case CONSTANTS.notificationError:
        return 'danger';
      case CONSTANTS.notificationWarning:
        return 'warning';
      case CONSTANTS.notificationSuccess:
        return 'success';
      default:
        return 'info';
    }
  }
}
