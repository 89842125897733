<div class="project-header" fxLayout="column wrap">
  <ng-container *ngIf="{ updatePermission: this.sandbox.updatePermission$ | async } as permissions">
    <ng-container *ngIf="sandbox.project$ | async as project">
      <section class="top" fxLayout="row" fxLayoutAlign="start start">
        <section>
          <h1 class="mt-2 mb-0">{{ project.name }}</h1>
          <div class="info mb-2" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
            <section>
              {{ project.key }}
            </section>
            <section *ngIf="project.timezone">
              <span class="mr-1">|</span> {{ project.timezone }}
              <!-- <ng-container *ngIf="project.geolocation as geolocation">
                | long. {{ geolocation.longitude }} - lat. {{ geolocation.latitude }}
              </ng-container> -->
            </section>
          </div>
        </section>
        <section fxFlex fxLayoutAlign="end center">
          <!-- BUTTONS -->
          <section fxLayout="row">
            <button
              class="button"
              mat-button
              mat-stroked-button
              title="{{ 'dashboard:dashboard.open' | i18nextCap }}"
              (click)="openDashboard$.next()"
            >
              <mat-icon class="mr-1" [svgIcon]="'icons.dashboard' | i18next"></mat-icon>
              {{ 'project:open-dashboard' | i18next | titlecase }}
            </button>
          </section>
          <!-- MORE -->
          <div class="ml-2" [permission-check]="permissions.updatePermission">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="dots-vertical"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="upsertProjectPanel$.next(project)">
                <mat-icon [svgIcon]="'icons.edit' | i18next"></mat-icon>
                <span class="pr-1">{{ 'project:edit-title' | i18nextCap }}</span>
              </button>
              <button mat-menu-item (click)="updateLocal$.next()">
                <mat-icon [svgIcon]="'icons.update-local' | i18next"></mat-icon>
                <span class="pr-1">{{ 'project:update-local' | i18nextCap }}</span>
              </button>
            </mat-menu>
          </div>
        </section>
      </section>

      <!-- <section fxLayout="column wrap">
        <div class="info-entry" fxLayout="row wrap">
          <div class="title" fxFlex="35">{{ 'dictionary.api-key' | i18nextCap }}</div>
          <div class="content" fxFlex="65">{{ project.apiKey }}</div>
        </div>

        <div *ngIf="project.timezone" class="info-entry" fxLayout="row wrap">
          <div class="title" fxFlex="40">{{ 'dictionary.timezone' | i18nextCap }}</div>
          <div class="content" fxFlex="50">{{ project.timezone }}</div>
        </div>

        <ng-container *ngIf="project.geolocation as geolocation">
          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="40">{{ 'dictionary.longitude' | i18nextCap }}</div>
            <div class="content" fxFlex="50">{{ geolocation.longitude }}</div>
          </div>
          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="40">{{ 'dictionary.latitude' | i18nextCap }}</div>
            <div class="content" fxFlex="50">{{ geolocation.latitude }}</div>
          </div>
        </ng-container>
      </section> -->
    </ng-container>
  </ng-container>
</div>
