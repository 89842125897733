import { IClientOptions } from 'mqtt';

export interface Environment {
  readonly app: {
    name: string;
    version: string;
    isPortal: boolean;
  };
  readonly production: boolean;
  readonly productId: string;
  readonly api: {
    httpHost: string;
    webSocketHost: string;
  };
  readonly token: string;
  readonly fixtureLogThreshold?: number;
  readonly floorplanUrl: string;
  readonly sliderDebounceTime: number;

  readonly awsConfig?: {
    region: string;
    baseUrl: string;
    floorplan: {
      bucket: string;
      folder: string;
      aclType: string;
    };
    firmware: {
      bucket: string;
      folder: string;
      aclType: string;
    };
    iam: {
      id: string;
      key: string;
    };
  };
}

export interface ApiEnvironment {
  readonly app: {
    name: string;
    version: string;
  };
  readonly production: boolean;
  readonly productId: string;
  readonly projectKey?: string; // Only for local API
  readonly appConfig?: {
    automationMotionGoodness: number;
  };
  readonly apiConfig: {
    readonly serverPort: string;
    readonly serverHost: string;
    readonly serverGlobalPrefix: string;
    readonly logLevel: string;
    readonly jwtSecret: string;
    readonly ignoreExpiration: boolean;
    readonly apiKeySecret: string;
    readonly adminToken?: string;
    readonly portalApi?: string; // Only for local API
    readonly logThreshold?: number; // only for local API
  };

  readonly assemblyApi?: {
    readonly url: string;
    readonly key: string;
  };

  readonly algorithmApiConfig: {
    baseUrl: string;
  };

  readonly mqttServiceConfig: {
    readonly mqttQos?: number;
    readonly mqttServiceOptions: IClientOptions;
  };

  readonly provisioningMqttServiceConfig?: {
    readonly provisioningMqttTopicBaseUrlIn?: string;
    readonly provisioningMqttTopicBaseUrlOut?: string;
    readonly provisioningMqttQos?: number;
    readonly provisioningMqttServiceOptions: IClientOptions;
  };

  readonly cacheConfig?: {
    host: string;
    port: number;
    username?: string;
    password?: string;
    expirationInSeconds?: number;
  };

  readonly mongoConfig: {
    user: string;
    password: string;
    host: string;
    port: number;
    prefix?: string;
    dbName: string;
  };

  readonly awsConfig?: {
    firmware: {
      baseUrl: string;
      folder: string;
    };
  };
}

export enum LogLevel {
  Debug,
  Info,
  Warn,
  Error,
}
