import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { defaultConfirmationString } from './confirmation-dialog.constants';

export interface ConfirmationModalData {
  title?: string;
  content: string;
  cancelButton: string;
  primaryButton: string;

  confirmationInputLabel?: string;
  confirmationString?: string;
  showConfirmationInput?: boolean;
  primaryButtonStyle?: ThemePalette;
}

@Component({
  selector: 'summa-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  @Output() confirm: EventEmitter<void> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  loading = false;
  confirmationInputValue = '';
  confirmationString: string;
  showConfirmationInput: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ConfirmationModalData) {
    this.showConfirmationInput = this.dialogData.showConfirmationInput || false;
    this.confirmationString = this.dialogData.confirmationString ?? defaultConfirmationString;
  }

  get primaryButtonColor(): ThemePalette {
    return this.dialogData.primaryButtonStyle ?? 'primary';
  }

  onCancelClick(): void {
    this.loading = true;
    this.cancel.emit();
  }

  onConfirmClick(): void {
    this.loading = true;
    this.confirm.emit();
  }
}
