import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { CONSTANTS } from '@summa/models';
import { Counter } from '@summa/shared/dashboard/ui';
import { I18NextCapPipe } from 'angular-i18next';
import { map } from 'rxjs';
import { CommandService, ProjectQuery, SceneQuery, SceneService } from '../../../../data-access';

@Injectable()
export class ProjectSceneWidgetSandbox {
  scenes$ = this.sceneQuery.selectAll();
  params$ = this.router.selectParams();
  totalScenes$ = this.sceneQuery.selectCount();
  sceneCounters$ = this.totalScenes$.pipe(
    map((scenesCount): Counter[] => [{ title: this.i18next.transform('common:dictionary.plural.scene'), class: 'success', count: scenesCount }]),
  );
  project$ = this.projectQuery.selectActive();

  constructor(
    private sceneService: SceneService,
    private commandService: CommandService,
    private sceneQuery: SceneQuery,
    private router: RouterQuery,
    private projectQuery: ProjectQuery,
    private i18next: I18NextCapPipe,
  ) {}

  getScenes(projectKey: string): void {
    this.sceneService.getScenes(projectKey);
  }

  reset(): void {
    this.sceneService.reset();
  }

  executeSync(projectKey: string, mode: 'partial' | 'full'): void {
    this.commandService.executeSyncCommand({ projectKey, mode, subject: CONSTANTS.syncScenes });
  }
}
