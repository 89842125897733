<div class="panel__header">
  <div class="panel__title-wrapper">
    <ng-content select="[header]"></ng-content>
  </div>
  <ng-content select="[image]"></ng-content>
</div>

<div *ngIf="showInfo" class="panel__info">
  <ng-content select="[info]"></ng-content>
</div>

<div class="panel__body">
  <ng-content select="[body]"></ng-content>
</div>

<div *ngIf="!hideFooter" class="panel__footer">
  <ng-content select="[footer]"></ng-content>
</div>
