export enum Roles {
  SUMMA_ADMIN = 'summa_admin',
  SUMMA_USER = 'summa_user',

  CUSTOMER_ADMIN = 'customer_admin',
  CUSTOMER_USER = 'customer_user',

  RESELLER_ADMIN = 'reseller_admin',
  RESELLER_USER = 'reseller_user',

  ADMIN = 'admin',
  USER = 'user',
}

// FIXME: BELOW IS OLD AND CAN BE REMOVED WHEN PERMISSIONS IS ON LOCAL DASHBOARD
export const roleHierarchy = {
  [Roles.SUMMA_ADMIN]: 0,
  [Roles.ADMIN]: 5,
  [Roles.USER]: 10,
};
