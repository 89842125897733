<ng-container *ngIf="sandbox.driver$ | async as driver">
  <!-- <p class="m-2 intro">{{ 'project:drivers.preview.actions-intro' | i18nextCap }}</p> -->

  <!-- INFORMATION -->
  <mat-accordion displayMode="flat" multi="true" class="action-tab">
    <!-- GENERAL -->
    <mat-expansion-panel expanded="true" class="w-100">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.actions.sections.actions' | i18nextCap | uppercase }}</mat-panel-title>
      </mat-expansion-panel-header>
      <section fxLayout="row wrap">
        <section
          class="action-item clickable"
          (click)="setReplaceDriver$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.general.replace-driver' | i18nextCap }}</div>
        </section>
      </section>

      <!-- <section fxLayout="column" fxLayoutAlign="start" fxLayoutGap="16px">
        <button mat-flat-button class="btn" (click)="setDriverType$.next(driver.key)">

          <span>{{ 'project:drivers.actions.actions.replace-driver' | i18nextCap }}</span>
        </button>
      </section> -->
    </mat-expansion-panel>
    <!-- SETTINGS -->
    <mat-expansion-panel class="w-100" [permission-check]="rndActionPermission">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.actions.sections.settings' | i18nextCap | uppercase }}</mat-panel-title>
      </mat-expansion-panel-header>

      <section fxLayout="row wrap">
        <section
          class="action-item clickable"
          (click)="setDriverType$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.settings.set-driver-type' | i18nextCap }}</div>
        </section>

        <section
          class="action-item clickable"
          (click)="setDriverCurrent$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.settings.set-current' | i18nextCap }}</div>
        </section>

        <section
          class="action-item clickable"
          (click)="setDriverContribution$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.settings.set-contribution-values' | i18nextCap }}</div>
        </section>

        <section
          class="action-item clickable"
          (click)="setTargetRipple$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.settings.set-target-ripple' | i18nextCap }}</div>
        </section>

        <section
          class="action-item clickable"
          (click)="deleteAllCurrentValues$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.actions.settings.delete-all-current-values' | i18nextCap }}</div>
        </section>
      </section>
    </mat-expansion-panel>
    <!-- SENSORS -->
    <mat-expansion-panel class="w-100" [permission-check]="rndActionPermission">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.actions.sections.sensors' | i18nextCap | uppercase }}</mat-panel-title>
      </mat-expansion-panel-header>
      <section fxLayout="row wrap">
        <section
          class="action-item clickable"
          (click)="setBaseline$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.set-sensors-baseline' | i18nextCap }}</div>
        </section>

        <section
          class="action-item clickable"
          (click)="resetSensors$.next(driver.key)"
          fxFlex.xs="100"
          fxFlex.gt-xs="50"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <div class="title">{{ 'project:drivers.reset-sensors' | i18nextCap }}</div>
        </section>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
