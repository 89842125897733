import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectCardPreviewSandbox } from './project-card-preview.sandbox';

@Component({
  selector: 'summa-project-card-preview',
  templateUrl: './project-card-preview.page.html',
  styleUrls: ['./project-card-preview.page.scss'],
  providers: [ProjectCardPreviewSandbox],
})
export class ProjectCardPreviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();

  currentRoute: string;

  constructor(public sandbox: ProjectCardPreviewSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url.includes('cards') ? this.router.url : `${this.router.url}/cards`;
  }

  closePanel(): void {
    this.sandbox.resetActive();
    this.sidePanelService.close();
  }
}
