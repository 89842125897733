import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

import { DOMAIN } from '@summa/models';

import { FixtureStateStore } from './fixture-state.store';
import { FixtureStateSubscription } from './graphql';
import { DriverQuery, DriverStore } from '../drivers';

@Injectable({
  providedIn: 'root',
})
export class FixtureStateService {
  subscriptions: Subscription;

  constructor(
    private apollo: Apollo,
    private fixtureStateStore: FixtureStateStore,
    private driverQuery: DriverQuery,
    private driverStore: DriverStore,
  ) {}

  reset(): void {
    this.fixtureStateStore.reset();
  }

  subscribe(projects: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: FixtureStateSubscription, variables: { projects } }).subscribe((fixtureState) => {
      const state = (fixtureState.data as { observeFixtureStates: DOMAIN.FixtureState }).observeFixtureStates;

      this.fixtureStateStore.upsert(state.topic, state);
      const driver = this.driverQuery.getAll().filter((dr) => dr.address === state.topic)[0];

      if (driver) {
        this.driverStore.update(driver.key, { state: state.state });
      }
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
