import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { NotificationWidgetSandbox } from './notification-widget.sandbox';

@Component({
  selector: 'summa-notification-widget',
  templateUrl: './notification-widget.component.html',
  providers: [NotificationWidgetSandbox],
})
export class NotificationWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: NotificationWidgetSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.userProjects$
      .pipe(
        filter(isNotNullOrUndefined),
        first(),
        withLatestFrom(this.sandbox.params$),
        filter(([reseller, user]) => typeof reseller !== 'undefined' && typeof user !== 'undefined'),
        takeUntil(this.destroy$),
      )
      .subscribe(([userProjects, params]) => {
        const projectKeys = params.projectKey ? [params.projectKey] : userProjects.map((project) => project.key);
        this.sandbox.getNotifications(projectKeys);
      });
  }

  getType(notification: DOMAIN.Notification): string {
    switch (notification.type) {
      case CONSTANTS.notificationError:
        return 'danger';
      case CONSTANTS.notificationWarning:
        return 'warning';
      case CONSTANTS.notificationSuccess:
        return 'success';
      default:
        return 'info';
    }
  }
}
