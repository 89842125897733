import { gql } from 'apollo-angular';

export const upsertDocumentQuery = gql`
  mutation upsertDocument($documentInput: DocumentInput!) {
    upsertDocument(documentInput: $documentInput) {
      id
      name
      content
      version
      lastModified
      type
      order
    }
  }
`;

export const removeDocumentQuery = gql`
  mutation removeDocument($id: String!) {
    removeDocument(id: $id)
  }
`;
