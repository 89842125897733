/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { ControlPanelStore } from './control-panel.store';
import { findControlPanelsQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class ControlPanelService {
  constructor(private apollo: Apollo, private controlPanelStore: ControlPanelStore) {}

  reset(): void {
    this.controlPanelStore.reset();
  }

  resetActive(): void {
    this.controlPanelStore.resetUIState();
    this.setActive(null);
  }

  resetUiState(): void {
    this.controlPanelStore.resetUIState();
  }

  setActive(id: string | null): void {
    this.controlPanelStore.setActive(id);
  }

  getControlPanels(projectKey: string, skip?: number, take?: number): void {
    this.apollo
      .subscribe({ query: findControlPanelsQuery, variables: { projectKey, skip, take } })
      .pipe(map((d: any) => d.data.findControlPanels))
      .subscribe((pagination) =>
        pagination && pagination.result.length > 0 ? this.controlPanelStore.set(pagination.result) : this.controlPanelStore.reset(),
      );
  }
}
