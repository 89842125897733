import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[summaLongPress]',
})
export class LongPressDirective {
  @Input() interval = 500;
  @Output() isPressed = new EventEmitter<MouseEvent | TouchEvent>();
  pressing: boolean;
  timeout: any;

  constructor(private el: ElementRef<HTMLElement>) {}

  @HostBinding('class.press')
  get press(): boolean {
    return this.pressing;
  }

  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
  onMouseDown(event): void {
    this.pressing = true;
    this.timeout = setTimeout(() => {
      this.isPressed.emit(event);
    }, 500);
  }

  @HostListener('touchend')
  @HostListener('mouseup')
  @HostListener('mouseleave')
  endPress(): void {
    clearTimeout(this.timeout);
    this.pressing = false;
  }
}
