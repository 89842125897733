import { InjectionToken, NgModule } from '@angular/core';
import { ActiveState, EntityState, QueryEntity } from '@datorama/akita';
import { DOMAIN } from '@summa/models';
import { ControlPanelModule } from './app/control-panel/control-panel.module';
import { DashboardAppModule } from './app/dashboard/dashboard-app.module';
import { DashboardDataAccessModule } from './data-access';

export interface AuthState extends EntityState<DOMAIN.User, string>, ActiveState {
  isSuccessful: boolean;
}

export const AUTHENTICATION_QUERY = new InjectionToken<QueryEntity<AuthState>>('authentication-query');

@NgModule({
  imports: [DashboardAppModule, DashboardDataAccessModule, ControlPanelModule],
})
export class DashboardModule {}
