import { Inject, Injectable } from '@angular/core';
import { resetStores } from '@datorama/akita';
import { Apollo, MutationResult } from 'apollo-angular';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS, DOMAIN, ENV, PortalAPI } from '@summa/models';
import { AuthenticationService as AuthService } from '@summa/shared/dashboard/ui';

import { AuthQuery } from './auth.query';
import { AuthStore } from './auth.store';
import { LoginQuery, CurrentUserQuery, UpdateCurrentUser } from './graphql';

@Injectable({ providedIn: 'root' })
export class AuthenticationService implements AuthService {
  private destroy$ = new Subject();
  public isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public currentUser$: Observable<DOMAIN.User>;

  constructor(
    @Inject('environment') private environment: ENV.Environment,
    private apollo: Apollo,
    private store: AuthStore,
    private authQuery: AuthQuery,
    private i18next: I18NextCapPipe,
    private notifier: NotifierService,
  ) {
    this.handleCurrentUser();
    this.currentUser$ = this.authQuery.selectActive();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  isLoggedIn(): boolean {
    const result = !!localStorage.getItem(this.environment.token);
    this.isLoggedIn$.next(result);
    return result;
  }

  getCurrentUser(): void {
    this.apollo
      .subscribe({ query: CurrentUserQuery })
      .pipe(map((d: any) => d.data.getCurrentUser as DOMAIN.User))
      .subscribe((user) => {
        this.store.set([user]);
        this.store.setActive(user.id);
      });
  }

  updateCurrentUser(userInput: PortalAPI.CurrentUserInput): void {
    this.apollo.mutate({ mutation: UpdateCurrentUser, variables: { userInput } }).subscribe((result: any) => {
      if (!result || result.errors || !result.data) {
        const errorMessage = result.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage));
        return;
      }

      const user = result.data.updateCurrentUser as DOMAIN.User;

      this.store.set([user]);
      this.store.setActive(user.id);
      this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
    });
  }

  // ACTIONS & QUERIES
  login(loginInput: PortalAPI.LoginInput): void {
    this.store.resetState();

    this.apollo
      .mutate({ mutation: LoginQuery, variables: { loginInput } })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resp: MutationResult<{ login: DOMAIN.Authorization }>) => {
          // login successful if there's a jwt token in the response
          if (resp && resp.data) {
            const { data } = resp;
            const auth = data.login;
            localStorage.setItem(this.environment.token, auth.accessToken);
            this.store.set([auth.user]);
            this.store.setActive(auth.user.id);
            this.store.update({ isSuccessful: true });
          }
          // login error
          if (resp.errors) {
            const { message } = resp.errors[0];
            this.store.setError(message.indexOf('message:') !== -1 ? message : 'message:something-went-wrong');
          }
          this.store.setLoading(false);
        },
        error: () => {
          this.store.setError('message:something-went-wrong');
        },
      });
  }

  logOut(): void {
    // some app logic
    localStorage.removeItem(this.environment.token);
    this.isLoggedIn$.next(false);
    resetStores();

    // reset the store after that
    this.apollo.getClient().resetStore();
  }

  private handleCurrentUser(): void {
    this.isLoggedIn$.pipe(withLatestFrom(this.authQuery.selectActive()), takeUntil(this.destroy$)).subscribe(([loggedIn, activeUser]) => {
      if (!loggedIn) {
        resetStores();
      }
      if (loggedIn && !activeUser) {
        this.getCurrentUser();
      }
    });
  }
}
