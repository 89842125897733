import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { ProjectScenarioAddSandbox } from './project-scenario-add.sandbox';

@Component({
  selector: 'summa-project-scenario-add',
  templateUrl: './project-scenario-add.page.html',
  styleUrls: ['./project-scenario-add.page.scss'],
  providers: [ProjectScenarioAddSandbox],
})
export class ProjectScenarioAddPage implements OnInit, OnDestroy {
  form: FormGroup;

  destroy$ = new Subject();
  submit$ = new Subject();

  cardFilter: FormControl;
  filteredCards$: ReplaySubject<DOMAIN.Card[]>;

  get entries(): FormArray {
    return this.form.get('entries') as FormArray;
  }

  constructor(public sandbox: ProjectScenarioAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.cardFilter = this.fb.control('');
    this.filteredCards$ = new ReplaySubject<DOMAIN.Card[]>(1);

    this.handleProject();
    this.handleCards();
    this.handleData();
    this.handleFilterCard();
    this.handleSubmit();
    this.handleSuccess();
  }

  public removeCard(index: number): void {
    this.entries.removeAt(index);
  }

  addEntry(): void {
    this.entries.push(
      this.fb.group({
        card: [''],
        sceneId: [''],
        state: [true],
      }),
    );
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getCards(project.key);
    });
  }

  private handleData(): void {
    this.sandbox.data$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([scenario, { projectKey }]) => {
      if (!scenario) {
        this.form = this.fb.group({
          projectKey: [projectKey, []],
          name: ['', Validators.required],
          description: ['', Validators.required],
          sortOrder: [0, Validators.required],
          icon: ['lightbulb-group-outline', Validators.required],
          hidden: [false],
          entries: this.fb.array([
            this.fb.group({
              card: [''],
              sceneId: [''],
              state: [true],
            }),
          ]),
        });
        return;
      }
      // Get latest scenario info
      this.sandbox.getScenario((scenario as DOMAIN.Scenario).id);
    });

    combineLatest([this.sandbox.scenario$, this.sandbox.cards$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([scenario, cards]) => {
        if (!cards) return;

        const entries = scenario.entries.map((ent) =>
          this.fb.group({
            card: cards?.find((c) => c.id === ent.cardId),
            sceneId: ent.sceneId,
            state: this.fb.control(ent.state === 'ON'),
          }),
        );

        this.form = this.fb.group({
          id: scenario.id,
          projectKey: scenario.projectKey,
          name: [scenario.name, Validators.required],
          description: [scenario.description, Validators.required],
          sortOrder: scenario.sortOrder,
          hidden: scenario.hidden,
          icon: [scenario.icon, Validators.required],
          entries: this.fb.array(entries),
        });
      });
  }

  private handleCards(): void {
    this.sandbox.cards$.pipe(takeUntil(this.destroy$)).subscribe((cards) => this.filteredCards$.next(cards));
  }

  private handleFilterCard(): void {
    this.cardFilter.valueChanges.pipe(withLatestFrom(this.sandbox.cards$), takeUntil(this.destroy$)).subscribe(([search, cards]) => {
      if (!search) {
        this.filteredCards$.next(cards);
        return;
      }

      this.filteredCards$.next(cards.filter((card) => card.title.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleSubmit(): void {
    this.submit$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.form.invalid) return;
      const form = this.form.value;

      const scenarioInput = {
        ...(form.id && { id: form.id }),
        projectKey: form.projectKey,
        name: form.name,
        description: form.description,
        sortOrder: form.sortOrder,
        icon: form.icon,
        hidden: form.hidden,
        entries: form.entries.map((entry) => ({
          cardId: entry.card.id,
          sceneId: entry.sceneId,
          state: entry.state ? 'ON' : 'OFF',
        })),
      };

      this.sandbox.upsertScenario(scenarioInput);
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }
}
