import { gql } from 'apollo-angular';

export const upsertClientQuery = gql`
  mutation upsertClient($client: ClientInput!) {
    upsertClient(client: $client) {
      id
      name
      contact {
        name
        phone
        email
      }
    }
  }
`;

export const removeClientQuery = gql`
  mutation removeClient($id: String!) {
    removeClient(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
