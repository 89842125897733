import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { flatten } from '@summa/shared/util/typescript';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { DTO } from '@summa/portal/models/dto';
import { UI } from '@summa/models';
import {
  ControlPanelQuery,
  ControlPanelService,
  DriverQuery,
  DriverService,
  ProjectQuery,
  ScenarioQuery,
  ScenarioService,
  SceneQuery,
  SceneService,
} from '../../../data-access';

@Injectable()
export class ControlPanelUpsertSandbox {
  project$: Observable<DTO.Project | undefined>;
  controlPanel$: Observable<DTO.ControlPanel>;
  controlPanels$: Observable<DTO.ControlPanel[]>;

  scenes$: Observable<DTO.Scene[]>;
  scenarios$: Observable<DTO.Scenario[]>;
  devices$: Observable<DTO.Device[]>;
  upsertState$: Observable<UI.State>;
  data$: Observable<string>;

  constructor(
    private sidePanelService: SidePanelService,
    private projectQuery: ProjectQuery,
    private controlPanelQuery: ControlPanelQuery,
    private controlPanelService: ControlPanelService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private sceneService: SceneService,
    private sceneQuery: SceneQuery,
  ) {
    this.project$ = this.projectQuery.selectActive();
    this.controlPanel$ = this.controlPanelQuery.selectActive();
    this.controlPanels$ = this.controlPanelQuery.selectAll();
    this.scenes$ = this.sceneQuery.selectAll();
    this.scenarios$ = this.scenarioQuery.selectAll();
    this.devices$ = this.driverQuery.selectAll().pipe(map((drivers) => flatten(drivers.map((d) => d.devices))));
    this.upsertState$ = this.controlPanelQuery.selectUpsertState$;
    this.data$ = this.sidePanelService.getData();
  }

  getControlPanel(id: string): void {
    this.controlPanelService.findControlPanel(id);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDrivers(projectKey);
  }

  getScenes(projectKey: string): void {
    this.sceneService.getScenes(projectKey);
  }

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  createControlPanel(controlPanel: DTO.ControlPanelInput): void {
    this.controlPanelService.createControlPanel(controlPanel);
  }

  patchControlPanel(controlPanel: Partial<DTO.ControlPanelInput>): void {
    this.controlPanelService.patchControlPanel(controlPanel);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.controlPanelService.resetActive();
    this.scenarioService.reset();
  }
}
