import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { upsertPresetQuery } from './graphql/preset.gql-mutation';
import { GetPresetQuery, GetPresetsQuery } from './graphql/preset.gql-query';
import { PresetStore } from './preset.store';

@Injectable({
  providedIn: 'root',
})
export class PresetService {
  constructor(private apollo: Apollo, private presetStore: PresetStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.presetStore.reset();
  }

  resetActive(): void {
    this.presetStore.resetUIState();
    this.presetStore.setActive(null);
  }

  getPreset(id: string): void {
    this.apollo
      .subscribe({ query: GetPresetQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getPreset as DOMAIN.Preset))
      .subscribe((preset) => {
        this.presetStore.upsert(preset.id, preset);
        this.presetStore.setActive(id);
      });
  }

  getPresets(resellerId: string): void {
    this.apollo
      .subscribe({ query: GetPresetsQuery, variables: { resellerId } })
      .pipe(map((d: any) => d.data.getPresets as DOMAIN.Preset[]))
      .subscribe((presets) => this.presetStore.set(presets));
  }

  upsert(preset: DOMAIN.PresetInput): void {
    this.presetStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertPresetQuery, variables: { preset } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.presetStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `preset ${preset.name}` }));

        this.presetStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertPreset: DOMAIN.Preset };
      this.presetStore.upsert(data.upsertPreset.id, data.upsertPreset);
      this.presetStore.setActive(data.upsertPreset.id);

      // set success;
      this.presetStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }
}
