import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { subDays, subWeeks } from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
import { ProjectAutomationHistorySandbox } from './project-automation-history-tab.sandbox';

@Component({
  selector: 'summa-project-automation-history-tab',
  templateUrl: './project-automation-history-tab.component.html',
  styleUrls: ['./project-automation-history-tab.component.scss'],
  providers: [ProjectAutomationHistorySandbox],
})
export class ProjectAutomationHistoryTabComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  timeInterval$ = new BehaviorSubject('last-twenty-four-hours');
  columnsToDisplay = ['state', 'time'];

  readonly timeInterval = [
    { key: 'last-twenty-four-hours', value: subDays(new Date(), 1).getTime() },
    { key: 'three-days', value: subDays(new Date(), 3).getTime() },
    { key: 'one-week', value: subWeeks(new Date(), 1).getTime() },
  ];

  constructor(public sandbox: ProjectAutomationHistorySandbox) {}

  ngOnInit(): void {
    // this.handleLogbook();
    this.sandbox.automation$
      .pipe(withLatestFrom(this.sandbox.params$, this.timeInterval$), takeUntil(this.destroy$))
      .subscribe(([automation, { projectKey }, timeInterval]) => {
        this.sandbox.getAutomationHistory(automation.id, projectKey, this.timeInterval.find((time) => time.key === timeInterval)?.value);
      });

    this.timeInterval$
      .pipe(withLatestFrom(this.sandbox.params$, this.sandbox.automation$), takeUntil(this.destroy$))
      .subscribe(([timeInterval, { projectKey }, automation]) => {
        this.sandbox.getAutomationHistory(automation.id, projectKey, this.timeInterval.find((time) => time.key === timeInterval)?.value);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
