import { Inject, Injectable } from '@angular/core';
import { ENV } from '@summa/models';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import * as AWS from 'aws-sdk';
import { ListObjectsOutput, PutObjectRequest } from 'aws-sdk/clients/s3';

import { StorageStore } from './storage.store';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  awsS3AccessKeyId = this.environment.awsConfig.iam.id;
  awsS3SecretAccessKey = this.environment.awsConfig.iam.key;
  awsRegion = this.environment.awsConfig.region;

  s3Bucket = this.getAwsS3Bucket(this.awsS3AccessKeyId, this.awsS3SecretAccessKey, this.awsRegion);

  constructor(
    private notifier: NotifierService,
    private storageStore: StorageStore,
    private i18next: I18NextCapPipe,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  reset(): void {
    this.storageStore.reset();
  }

  resetActive(): void {
    this.storageStore.setActive(null);
    this.storageStore.resetUIState();
  }

  public setActive(name: string | null): void {
    this.storageStore.setActive(name);
  }

  private getAwsS3Bucket(accessKeyId: string, secretAccessKey: string, region: string): AWS.S3 {
    return new AWS.S3({ accessKeyId, secretAccessKey, region });
  }

  public uploadFile(bucket: string, file: File, projectKey = '', notify = true): void {
    this.storageStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });
    const contentType = file.type;

    const params: PutObjectRequest = {
      Bucket: bucket,
      Key: `${projectKey}/${file.name}`,
      Body: file,
      ACL: this.environment.awsConfig.floorplan.aclType,
      ContentType: contentType,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.s3Bucket.upload(params, (err: AWS.AWSError, data: any) => {
      if (err) {
        this.notifier.notify('error', `ERROR: ${err.message}`);
        this.storageStore.setError(err ?? this.i18next.transform('message:something-went-wrong'));
        this.storageStore.updateUpsert({ errors: err, isLoading: false });
        return;
      }

      const { Key, key, ...content } = data;
      const name = Key.replace(`${projectKey}/`, '').replace('.svg', '');
      this.storageStore.upsert(data.Key, { ...content, Key, name });
      this.storageStore.updateUpsert({ isSuccessful: true, isLoading: false });
      if (notify) {
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      }
    });
  }

  public uploadFirmware(bucket: string, name: string, file: File, notify = true): void {
    this.storageStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    // POST to server
    const params: PutObjectRequest = {
      Bucket: bucket,
      Key: `${this.environment.awsConfig.firmware.folder}/${name}`,
      Body: file,
      ACL: this.environment.awsConfig.firmware.aclType,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.s3Bucket.upload(params, (err: AWS.AWSError, data: any) => {
      if (err) {
        this.notifier.notify('error', `ERROR: ${err.message}`);
        this.storageStore.setError(err ?? this.i18next.transform('message:something-went-wrong'));
        this.storageStore.updateUpsert({ errors: err, isLoading: false });
        return;
      }

      const { Key, key, ...content } = data;
      this.storageStore.upsert(data.Key, { ...content, Key, name });
      this.storageStore.setActive(data.Key);
      this.storageStore.updateUpsert({ isSuccessful: true, isLoading: false });
      if (notify) {
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      }
    });
  }

  public getFile(bucket: string, projectKey: string, fileName: string): void {
    const params = {
      Bucket: bucket,
      Key: `${projectKey}/${fileName}`,
    };
    this.s3Bucket.getObject(params, (err: AWS.AWSError, data: AWS.S3.GetObjectOutput) => {
      if (err) {
        this.notifier.notify('error', `Error retrieving your files: ${err.message}`);
        return;
      }
      // eslint-disable-next-line no-console
      console.log(data);
    });
  }

  public getFiles(bucket: string, projectKey = ''): void {
    const params = {
      Bucket: bucket,
      Prefix: projectKey,
    };

    this.s3Bucket.listObjects(params, (err: AWS.AWSError, data: ListObjectsOutput) => {
      if (err) {
        this.notifier.notify('error', `Error retrieving your files: ${err.message}`);
        return;
      }
      this.storageStore.set(
        data.Contents.map((content) => ({
          ...content,
          lastModified: content.LastModified,
          name: content.Key.replace(`${data.Prefix}/`, '').replace('.svg', ''),
        })),
      );
    });
  }

  public deleteFile(bucket: string, fileName: string): void {
    this.storageStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });
    const params = {
      Bucket: bucket,
      Key: `${fileName}`,
    };

    this.s3Bucket.deleteObject(params, (err: AWS.AWSError) => {
      if (err) {
        this.storageStore.updateRemove({ isLoading: false, isSuccessful: false, errors: err });
        this.notifier.notify('error', `Error deleting files: ${err.message}`);
        return;
      }
      this.storageStore.updateRemove({ isLoading: false, isSuccessful: true, errors: null });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
