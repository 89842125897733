<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.installer$ | async as installer">
  <ng-container header>
    <h1 class="px-4 print-hide"><span class="orange">{{'common:dictionary.installer' | i18nextCap}}: </span>{{ installer.name }}</h1>
  </ng-container>

  <ng-container info>
    <section class="section print">
      <p class="h1 mt-0"><span class="orange">{{'common:dictionary.project' | i18nextCap}}:</span> {{ installer.project.name }}</p>
      <p class="mb-0"><span class="orange">{{'common:dictionary.installer' | i18nextCap}}:</span> {{ installer.name }}</p>
    </section>
    <section class="section print-hide">
      <div>
        <span fxFlex="18" class="mr-1">{{'common:dictionary.project-name' | i18nextCap}}:</span>
        <span fxFlex="auto">{{ installer.project.name }}</span>
      </div>
      <div class="mt-1" fxFlexAlign="start center">
        <span fxFlex="18" class="mr-1">{{'common:dictionary.enabled' | i18nextCap}}:</span>
        <span fxFlex="auto">
          <mat-icon class="icon" [svgIcon]="installer.enabled ? 'check-circle' : 'close-circle'"></mat-icon>
        </span>
      </div>
    </section>
  </ng-container>

  <ng-container body>
    <h2 class="print-hide">QR Code</h2>
    <ng-container *ngIf="installer.enabled; else disabledInstaller">
      <qr-code
        class="print-hide"
        [margin]="1"
        [size]="250"
        errorCorrectionLevel="H"
        centerImageSize="100"
        centerImageSrc="assets/images/summa-icon.svg"
        [value]="installer.qrCodeData"
      ></qr-code>

      <qr-code
        class="print"
        *ngIf="installer.enabled;"
        [margin]="0"
        [size]="250"
        errorCorrectionLevel="H"
        centerImageSize="100"
        centerImageSrc="assets/images/summa-icon.svg"
        [value]="installer.qrCodeData"
      ></qr-code>
    </ng-container>
    <ng-template #disabledInstaller>
      <p>{{ 'project:installers.disabled-qr-code-description' | i18nextCap }}</p>
    </ng-template>
  </ng-container>

  <ng-container footer *ngIf="installer.enabled">
    <button mat-raised-button color="primary" (click)="printPage()">{{ 'common:buttons.print' | i18nextCap }}</button>
  </ng-container>
</summa-side-panel-content>
