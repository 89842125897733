import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ResellerClientAddPage } from '../client-add/reseller-client-add.page';

import { ResellerClientOverviewSandbox } from './reseller-client-overview.sandbox';

@Component({
  selector: 'summa-reseller-client-overview',
  templateUrl: './reseller-client-overview.page.html',
  styleUrls: ['./reseller-client-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResellerClientOverviewSandbox],
})
export class ResellerClientOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  clients = new MatTableDataSource<PortalAPI.Client>();

  columnsToDisplay = ['name', 'contactName', 'contactEmail', 'contactPhone'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private sandbox: ResellerClientOverviewSandbox, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleRoute();
    this.handleClientUpsertSuccess();

    this.sandbox.clients$.pipe(takeUntil(this.destroy$)).subscribe((clients: PortalAPI.Client[]) => {
      this.clients.data = clients;
      this.clients.sort = this.sort;
      this.clients.paginator = this.paginator;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.clients.filter = filterValue.trim().toLowerCase();
  }

  showLastFewDigits(id: string): string {
    return `...${id.replace(/.(?=.{8})/g, '')}`;
  }

  openAddClient(): void {
    this.sidePanelService.open(ResellerClientAddPage);
  }

  private handleRoute(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe((params) => this.sandbox.checkActive(params.resellerId));
  }

  private handleClientUpsertSuccess(): void {
    this.sandbox.upsertClientState$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([state, params]) => {
      if (state.isSuccessful) {
        this.sandbox.getReseller(params.resellerId);
        this.sidePanelService.close();
      }
    });
  }
}
