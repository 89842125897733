import { gql } from 'apollo-angular';

export const LoginQuery = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
      user {
        id
        username
        roles
        language
        permissions
        agreements {
          documentId
          version
          acceptedDate
        }
      }
    }
  }
`;

export const SignUpQuery = (username: string, password: string) => gql`
mutation {
  signUp(signUpInput:  { username: "${username}", password: "${password}" }) {
    id
    username
  }
}
`;

export const UpdateCurrentUser = gql`
  mutation updateCurrentUser($userInput: CurrentUserInput!) {
    updateCurrentUser(userInput: $userInput) {
      id
      username
      roles
      language
      permissions
      agreements {
        documentId
        version
      }
    }
  }
`;
