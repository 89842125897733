<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="center start">
  <div fxLayout="column" fxLayoutGap="16px" class="w-100">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card xl p-3">
        <div class="header">
          {{'reseller:notifications.overview.title' | i18nextCap}}
          <div class="header-tools">
            <div class="filter">
              <mat-form-field floatLabel="never">
                <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
              </mat-form-field>
            </div>
          </div>
        </div>

        <mat-table [dataSource]="notifications" class="w-100" matSort>
          <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.timestamp' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let notification">
              {{ (notification.timestamp <= 10000000000 ? notification.timestamp * 1000 : notification.timestamp) | date: 'd/MM/yy, h:mm a' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="project">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.project' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let notification">{{ notification.project }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.type' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let notification">
              <div>{{ notification.type }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.title' | i18nextCap}}
            </mat-header-cell>
            <mat-cell *matCellDef="let notification">
              <div>{{ notification.title }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="message">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.message' | i18nextCap}}
            </mat-header-cell>
            <mat-cell *matCellDef="let notification">
              <div>{{ notification.message }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="trace">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'reseller:notifications.overview.table-headers.trace' | i18nextCap}}
            </mat-header-cell>
            <mat-cell *matCellDef="let notification">
              <div>{{ notification.trace }}</div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row
            *matRowDef="let notification; columns: columnsToDisplay"
            class="clickable state"
            [ngClass]="getType(notification)"
            (click)="previewPanel$.next(notification)"
          ></mat-row>
          <div class="filter-notification" *matNoDataRow>
            {{'reseller:notifications.overview.filter-notification' | i18nextCap}} "{{input.value}}"
          </div>
        </mat-table>
        <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
