import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { CardQuery, CardService } from '../../../../data-access';

@Injectable()
export class ProjectCardPreviewSandbox {
  card$ = this.cardQuery.selectActive();
  devices$ = this.card$.pipe(map((c) => c.devices));

  constructor(private cardQuery: CardQuery, private cardService: CardService) {}

  resetActive(): void {
    this.cardService.setActive(null);
  }
}
