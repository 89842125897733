// QR codes are added to the shielding case of the modules.
// The information contained in the QR code consists of Fab code (2 bits) + Date code (4 bits) + MAC address (12 bits).

export interface ResolvedAddress {
  fabCode: string;
  dateCode: string;
  macAddress: string;
}

export function resolveQrAddress(qr: string): ResolvedAddress {
  if (qr.length !== 18) {
    return null;
  }
  // first 2 chars are Fab Code
  const fabCode = qr.substr(0, 2);
  // next 4 chars are date Code
  const dateCode = qr.substr(2, 4);
  // last 12 chars is the mac-address
  const macAddress = qr.substr(6, 18);

  return { fabCode, dateCode, macAddress };
}
