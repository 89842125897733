<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="this.sandbox.card$ | async as card">
  <ng-container header>
    <h1>{{ card.title }}</h1>
  </ng-container>

  <ng-container info>
    <div class="driver-info" fxLayout="row wrap">
      <ng-container *ngIf="selectedScene$ | async as scene; else noScene">
        <div class="item" fxFlex="33">
          <div class="title">{{ 'customer:card-details.current-scene' | i18nextCap }}</div>
          <div [title]="scene.description">{{ scene.name }}</div>
        </div>
      </ng-container>
      <ng-template #noScene>
        {{ 'customer:card-details.no-scene-selected' | i18nextCap }}
      </ng-template>
    </div>
  </ng-container>

  <ng-container body>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="center center">
            <mat-icon class="pr-1" svgIcon="image-filter"></mat-icon>
            <content>{{ 'customer:card-details.scenes' | i18nextCap }}</content>
          </section>
        </ng-template>
        <div class="mt-3 mx-2">
          <summa-card-detail-tab-scenes></summa-card-detail-tab-scenes>
        </div>
      </mat-tab>

      <mat-tab *ngIf="!(this.maximumEntriesExceeded$ | async)">
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="center center">
            <mat-icon class="pr-1" svgIcon="lightbulb-group-outline"></mat-icon>
            <content>{{ 'customer:card-details.lights' | i18nextCap }}</content>
          </section>
        </ng-template>
        <div class="mt-3 mx-2">
          <summa-card-detail-tab-lights></summa-card-detail-tab-lights>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="center center">
            <mat-icon class="pr-1" svgIcon="flask"></mat-icon>
            <content>{{ 'customer:card-details.lab' | i18nextCap }}</content>
          </section>
        </ng-template>
        <div class="mt-3 mx-2">
          <summa-card-detail-tab-labs></summa-card-detail-tab-labs>
        </div>
      </mat-tab>

      <mat-tab *ngIf="!(this.maximumEntriesExceeded$ | async)">
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="center center">
            <mat-icon class="pr-1" svgIcon="information-variant"></mat-icon>
            <content>{{ 'customer:card-details.info' | i18nextCap }}</content>
          </section>
        </ng-template>
        <div class="mt-3 mx-2">
          <summa-card-detail-tab-info></summa-card-detail-tab-info>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</summa-side-panel-content>
