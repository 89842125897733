import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

export type GridEyeClusterState = EntityState<DOMAIN.GridEyeCluster, string>;

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'grid-eye-clusters', idKey: 'id', resettable: true })
export class GridEyeClusterStore extends EntityStore<GridEyeClusterState, DOMAIN.GridEyeCluster> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
