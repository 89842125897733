import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, ENV } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { DTO } from '@summa/portal/models/dto';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationModalData } from '@summa/shared/ui/dialogs';
import { I18NextCapPipe } from 'angular-i18next';
import { FloorplanDxfViewerComponent } from '@summa/shared/ui/floorplan';
import { ProjectFloorplanDriversComponentSandbox } from './project-floorplan-drivers.sandbox';

@Component({
  selector: 'summa-project-floorplan-drivers',
  templateUrl: './project-floorplan-drivers.component.html',
  styleUrls: ['./project-floorplan-drivers.component.scss'],
  providers: [ProjectFloorplanDriversComponentSandbox],
})
export class ProjectFloorplanDriversComponent implements OnInit, OnDestroy {
  baseUrl = this.environment.floorplanUrl;
  file: string;
  project: string;

  form: FormGroup;
  formFixture: FormGroup;
  fileUrl: string;
  currentRoute: string;
  movingFixture = null;
  isFixtureChange = false;

  fixturesState$ = new ReplaySubject<any[]>(1);
  fixtures$ = new BehaviorSubject<DOMAIN.Fixture[]>([]);
  dxfClicked$ = new Subject<any>();
  destroy$ = new Subject();
  modus$ = new Subject<'addDevice' | null>();
  cancel$ = new Subject();
  enableMoveFixture$ = new BehaviorSubject<boolean>(false);
  addDeviceClick$ = new Subject<DOMAIN.Device>();
  removeDeviceClick$ = new Subject<DOMAIN.Device>();
  removeFloorplanKey$ = new Subject<DOMAIN.Device>();
  removeDeviceKey$ = new Subject<any>();
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, any>;

  driver: FormControl;
  driverChange$ = new Subject<void>();
  subscriber: any;

  @ViewChild('driverInput', { static: false }) driverInput: ElementRef;
  @ViewChild(FloorplanDxfViewerComponent) dxfViewer: FloorplanDxfViewerComponent;

  get formDevices(): FormArray {
    return this.form.get('devices') as FormArray;
  }

  get formSelectedDevices(): FormArray {
    return this.form.get('selectedDevices') as FormArray;
  }

  get formFixtureDevices(): FormArray {
    return this.formFixture.get('devices') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public sandbox: ProjectFloorplanDriversComponentSandbox,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.driver = this.fb.control('');
    this.form = this.fb.group({
      devices: this.fb.array([]),
      selectedDevices: this.fb.array([]),
    });
    this.formFixture = this.fb.group({
      id: this.fb.control(null),
      floorplanKey: this.fb.control(null),
      location: this.fb.group({
        x: this.fb.control(null),
        y: this.fb.control(null),
        height: this.fb.control(null),
      }),
      devices: this.fb.array([]),
      mainGroup: this.fb.control(null),
      type: this.fb.control(null),
      locationKey: this.fb.control(null),
      iconData: this.fb.control(null),
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getProject(projectKey);
    });
    this.enableMoveFixture$.next(false);

    this.handleCancel();
    this.handleFloorplan();
    this.handleChange();
    this.handleDeviceClick();
    this.handleFixtureClick();
    this.handleRemoveClick();
    this.handleDriverUpsertSuccess();
    this.updateFixtureState();
    this.handleRemoveDeviceInFixtureClick();
  }

  focusOnDriver(): void {
    this.driverInput.nativeElement.focus();
  }

  private handleCancel(): void {
    this.cancel$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.modus$.next(null);
    });
  }

  private handleFloorplan(): void {
    this.sandbox.floorplan$.pipe(takeUntil(this.destroy$)).subscribe((floorplan) => {
      this.file = floorplan.name;
      this.currentRoute = this.router.url.includes('floorplans') ? this.router.url : `${this.router.url}/floorplans`;
      this.fileUrl = floorplan.fileLocation;
      this.sandbox.getFixtures(floorplan.id);
      this.sandbox.fixtures$
        .pipe(
          map((fixtures) => fixtures.filter((fixture: DOMAIN.Fixture) => fixture.floorplanKey === floorplan.id)),
          takeUntil(this.destroy$),
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe((fixtures) => this.fixtures$.next(fixtures));
    });
  }

  // -----------------
  // Handle driver configuration
  // -----------------

  private handleChange(): void {
    this.driverChange$.pipe(withLatestFrom(this.sandbox.drivers$), takeUntil(this.destroy$)).subscribe(([, drivers]) => {
      this.formDevices.clear();
      this.formSelectedDevices.clear();
      this.subscriber?.unsubscribe();
      this.subscriber = null;
      const devicesInFixture = this.formFixtureDevices.value.map((device) => {
        return device.id;
      });
      const driverValue = this.driver.value;

      if (driverValue === '') return;

      if (this.driver.invalid) {
        this.driverInput.nativeElement.select();
        return;
      }

      if (drivers.map((d) => d.key).includes(driverValue)) {
        // add to devices
        const selectedDriver = drivers.find((d) => d.key === driverValue);
        const sortedDevices = selectedDriver.devices.slice().sort((a, b) => a.key.localeCompare(b.key));
        sortedDevices.forEach((device) => {
          if (devicesInFixture.includes(device.id)) {
            this.formSelectedDevices.push(this.fb.group({ device, locationKey: [{ value: device.locationKey, disabled: true }] }));
          } else {
            this.formDevices.push(this.fb.group({ device, locationKey: [{ value: device.locationKey, disabled: true }] }));
          }
        });

        // Automatic start the floorClick process
        // this.deviceClick$.next(selectedDriver.devices[0]);
        return;
      }

      this.sandbox.checkDriverAvailability(driverValue);
    });
  }

  private handleDriverUpsertSuccess(): void {
    this.sandbox.checkState$
      .pipe(
        withLatestFrom(this.sandbox.project$),
        filter(([state, project]) => !state.isLoading && isNotNullOrUndefined(project)),
        takeUntil(this.destroy$),
      )
      .subscribe(([state, project]) => {
        if (state.isAvailable && !state.isLoading) {
          // WHEN the type is a "Driver" add project default settings to devices
          if (DOMAIN.isCategoryDriver(state.entity.type)) {
            if (DOMAIN.isFusion(state.entity.devices[0].type)) {
              this.sandbox.upsertDriver(this.transformFusionCob(state.entity, project));
            } else {
              // default N-Channel
              this.transformNChannel(state.entity, project);
              this.sandbox.upsertDriver(this.transformNChannel(state.entity, project));
            }
          } else {
            // add to project only
            this.sandbox.upsertDriver({ ...state.entity, projectKey: project.key });
          }

          return;
        }

        if (state.errors) {
          this.driverInput.nativeElement.select();
          this.driverInput.nativeElement.focus();
          this.sandbox.resetDriverUIState();
        }
      });

    this.sandbox.upsertState$
      .pipe(
        filter((state) => !state.isLoading && state.isSuccessful),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.driverChange$.next();
      });
  }

  private handleDeviceClick(): void {
    this.addDeviceClick$.pipe(takeUntil(this.destroy$)).subscribe((device) => {
      const devicesKeyInFixture = this.formFixtureDevices.value.map((d) => {
        return d.key;
      });
      this.formDevices.removeAt(this.formDevices.value.findIndex((obj) => obj.device.key === device.key));
      this.formSelectedDevices.push(this.fb.group({ device, locationKey: [{ value: device.locationKey, disabled: true }] }));

      if (!devicesKeyInFixture.includes(device.key)) {
        this.formFixtureDevices.push(this.fb.group({ id: device.id, key: device.key }));
      }
    });

    this.removeDeviceClick$.pipe(takeUntil(this.destroy$)).subscribe((device) => {
      this.formDevices.push(this.fb.group({ device, locationKey: [{ value: device.locationKey, disabled: true }] }));
      this.formSelectedDevices.removeAt(this.formSelectedDevices.value.findIndex((obj) => obj.device.key === device.key));

      this.formFixtureDevices.removeAt(this.formFixtureDevices.value.findIndex((obj) => obj.id === device.id));
    });
  }

  private handleFixtureClick(): void {
    let temp = null;
    this.dxfClicked$.pipe(takeUntil(this.destroy$)).subscribe((fixture) => {
      if (!fixture) {
        this.formFixtureDevices.clear();
        this.formFixture.reset();
        return;
      }
      // detect fixture change
      if (this.movingFixture && this.movingFixture.id === fixture.id) {
        if (fixture.location.x !== this.movingFixture.location.x || fixture.location.y !== this.movingFixture.location.y) {
          this.isFixtureChange = true;
          temp = fixture; // to update new value of fixture in form
        }
      } else if (this.isFixtureChange) {
        this.handleUnSavedChangeConfirmDialog(
          () => {
            this.isFixtureChange = false;
            this.enableMoveFixture(false);
            this.dxfViewer.setSelectFixture(fixture);
          },
          () => {
            this.dxfViewer.setSelectFixture(temp);
          },
        );
      }

      this.modus$.next(null);
      this.formFixture.patchValue({
        id: fixture.id,
        floorplanKey: fixture.floorplanKey,
        location: fixture.location,
        mainGroup: fixture.mainGroup,
        type: fixture.type,
        iconData: fixture.iconData,
        locationKey: fixture.locationKey,
      });
      this.formFixtureDevices.clear();
      fixture.devices.forEach((device) => {
        this.formFixtureDevices.push(this.fb.group({ id: device.id, key: device.key }));
      });
      this.enableMoveFixture$.next(this.movingFixture && fixture.id === this.movingFixture.id);
    });
  }

  public handleSaveFixtureForm(): void {
    const lstFixtures = [];
    const fixture = { ...this.formFixture.value };

    this.fixtures$.pipe(takeUntil(this.destroy$)).subscribe((fixtures) => {
      /* Check if the devices in saved fixture is in any fixture. if the devices is already in a certain fixture
        then remove the devices from the old fixture and add the devices to the new fixture */
      fixtures.forEach((f) => {
        if (f.id !== fixture.id) {
          const devices = f.devices.filter(
            (device) =>
              !fixture.devices
                .map((d) => {
                  return d.key;
                })
                .includes(device.key),
          );
          if (devices.length !== f.devices.length) {
            lstFixtures.push({ ...f, devices });
          }
        }
      });
    });
    lstFixtures.push(fixture);
    lstFixtures.forEach((f) => {
      this.sandbox.upsertFixture(f);
    });
    fixture.devices.forEach((device) => {
      this.sandbox.updateLocation(device.key, fixture.id);
    });
    this.enableMoveFixture(false);
    this.formDevices.clear();
    this.formSelectedDevices.clear();
  }

  private handleRemoveClick(): void {
    this.removeFloorplanKey$
      .pipe(withLatestFrom(this.sandbox.drivers$, this.sandbox.fixtures$), takeUntil(this.destroy$))
      .subscribe(([device, drivers, fixtures]) => {
        this.sandbox.updateLocation(device.key, '');
        /* Remove device from fixture  */
        fixtures.forEach((fixture) => {
          let keys = fixture.devices.map((key) => {
            return key.key;
          });
          if (keys.find((key) => key === device.key)) {
            keys = keys.filter((key) => key !== device.key);
            const temp = { ...fixture, devices: keys };
            this.sandbox.upsertFixture(temp);
          }
        });
        const driver = drivers.find((d) => d.devices.some((dev) => dev.key === device.key));
        const sortedDevices = driver.devices.slice().sort((a, b) => a.key.localeCompare(b.key));
        const deviceIndex = sortedDevices.findIndex((dev) => dev.key === device.key);
        this.formDevices.at(deviceIndex).patchValue({ device, locationKey: '' });

        // Reset when only when you have one device
        if (driver && driver.devices.length < 2) {
          this.driverInput.nativeElement.select();
          this.driver.setValue('');
          this.formDevices.clear();
        }
      });
  }

  private handleRemoveDeviceInFixtureClick(): void {
    this.removeDeviceKey$.pipe(takeUntil(this.destroy$)).subscribe((deviceKey) => {
      this.formFixtureDevices.removeAt(this.formFixtureDevices.value.findIndex((obj) => obj.key === deviceKey));
    });
  }

  /**
   * Contains the logic for communicating with the floorplan
   * @param projectKey
   * @param deviceKey
   * @param drivers
   * @returns
   */
  private processFloorplanSteps(projectKey: any, deviceKey: string, drivers: DOMAIN.Driver[]): void {
    // params
    const driver = drivers.find((d) => d.devices.some((dev) => dev.key === deviceKey));
    const sortedDevices = driver.devices.slice().sort((a, b) => a.key.localeCompare(b.key));
    const index = sortedDevices.findIndex((dev) => dev.key === deviceKey);
    const selectedDevice = sortedDevices[index];
    // remove existing listeners
    this.subscriber?.unsubscribe();
    this.subscriber = null;

    // blink the lights
    this.sandbox.executeDeviceCommand(projectKey, selectedDevice, 'OFF');
    this.sandbox.executeDeviceCommand(projectKey, selectedDevice, 'ON');
    // focus on device
    this.formDevices.at(index).patchValue({ selectedDevice, locationKey: driver.devices[index].locationKey });
    this.formDevices.controls.forEach((control, i) => control.patchValue({ selectedDevice, locationKey: driver.devices[i].locationKey }));

    // listen to the first floorplan click
    this.subscriber = this.dxfClicked$
      .pipe(first(), withLatestFrom(this.sandbox.fixtures$), takeUntil(this.destroy$))
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .subscribe(([fixture_dxf, fixtures]) => {
        const lstFixtures = [];
        /* Check if the selected device is in any fixture. if the device is already in a certain fixture
        then remove the device from the old fixture and add the device to the new fixture */
        fixtures.forEach((fixture) => {
          if (fixture.id !== fixture_dxf.id && fixture.devices.find((device) => device.key === selectedDevice.key)) {
            lstFixtures.push({ ...fixture, devices: fixture.devices.filter((device) => device.key !== selectedDevice.key) });
          }
        });
        let devices = [];
        if (
          fixture_dxf.devices
            .map((device) => {
              return device.key;
            })
            .find((key) => key === selectedDevice.key)
        ) {
          devices = fixture_dxf.devices;
        } else {
          devices = [...fixture_dxf.devices, { key: selectedDevice.key }];
        }

        this.sandbox.updateLocation(selectedDevice.key, fixture_dxf.id);
        const newFixture = { ...fixture_dxf, devices };
        if (lstFixtures.length) {
          lstFixtures.push(newFixture);
          this.sandbox.upsertFixtures(lstFixtures);
        } else {
          this.sandbox.upsertFixture(newFixture);
        }

        this.formFixture.patchValue({
          id: newFixture.id,
          floorplanKey: newFixture.floorplanKey,
          location: newFixture.location,
          mainGroup: newFixture.mainGroup,
          type: newFixture.type,
        });
        this.formFixtureDevices.clear();
        newFixture.devices.forEach((device) => {
          this.formFixtureDevices.push(this.fb.group({ id: device.id, key: device.key }));
        });
        // update form
        this.formDevices.at(index).patchValue({ selectedDevice, locationKey: fixture_dxf });

        if (driver) {
          if (driver.devices.length > 1 && index !== driver.devices.length - 1) {
            this.addDeviceClick$.next(sortedDevices[index + 1]);
          } else if (driver.devices.length > 1 && index === driver.devices.length - 1) {
            // only focus driver input when last device
          } else {
            // Reset when only when you have one device
            this.driverInput.nativeElement.select();
            this.driver.setValue('');
            this.formDevices.clear();
          }
        }

        this.subscriber = null;
        this.sandbox.executeDeviceCommand(projectKey, selectedDevice, 'OFF');
      });
  }

  transformFusionCob(driver: DOMAIN.Driver, project: DTO.Project): DTO.DriverInput {
    const settings = driver.devices[0].settings as DOMAIN.FusionDeviceSettings;

    return {
      ...driver,
      projectKey: project.key,
      devices: [
        {
          ...driver.devices[0],
          settings: {
            ...settings,
          },
        },
      ],
    };
  }

  private transformNChannel(driver: DOMAIN.Driver, project: DTO.Project): DTO.DriverInput {
    return {
      ...driver,
      projectKey: project.key,
    };
  }

  private updateFixtureState(): void {
    this.fixtures$
      .pipe(
        map((fs) =>
          fs.map((fixture) => {
            let color = 0xcccccc;
            let isMove = false;
            if (fixture.devices.length > 0) {
              color = 0xff0000;
            }
            if (this.movingFixture && this.movingFixture.id === fixture.id) {
              isMove = true;
              color = 0xffa500;
            }
            return { ...fixture, color, isMove };
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((fs) => this.fixturesState$.next(fs));
  }

  public handleAddDevice(): void {
    if (this.isFixtureChange) {
      this.handleUnSavedChangeConfirmDialog(() => {
        this.isFixtureChange = false;
        this.modus$.next('addDevice');
        this.enableMoveFixture(false);
        this.driverChange$.next(null);
      });
    } else {
      this.modus$.next('addDevice');
      this.enableMoveFixture(false);
      this.driverChange$.next(null);
    }
  }

  public enableMoveFixture(isMove: boolean): void {
    if (isMove) {
      this.movingFixture = this.formFixture.value;
    } else {
      this.movingFixture = null;
    }
    this.updateFixtureState();
    this.enableMoveFixture$.next(isMove);
  }

  private handleUnSavedChangeConfirmDialog(confirmAction: VoidFunction, cancelAction: VoidFunction = null): void {
    const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
      data: {
        title: this.i18next.transform('common:dialog.confirm-unsaved-change.title'),
        content: this.i18next.transform('common:dialog.confirm-unsaved-change.content'),
        cancelButton: this.i18next.transform('common:buttons.no'),
        primaryButton: this.i18next.transform('common:buttons.yes'),
      },
    };

    this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    // eslint-disable-next-line rxjs/no-nested-subscribe
    this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
      confirmAction();
      this.confirmDialog.close();
    });

    this.confirmDialog.componentInstance.cancel.pipe(takeUntil(this.destroy$)).subscribe(() => {
      cancelAction();
      this.confirmDialog.close();
    });
  }
}
