/* eslint-disable no-underscore-dangle */
import { Injectable } from '@angular/core';

import denormalise from './denormalise';
import parseString from './parseString';
import DxfParser from '../parser/DxfParser';
import extractFixtureLocations from './extractFixtureLocations';

@Injectable({
  providedIn: 'root',
})
export class DxfService {
  _dxfString: any;
  _parsedDxfString: any;
  _denormalisedParsedDxfString: any;

  public setContent(contents: any): void {
    if (typeof contents !== 'string') {
      throw Error('Helper constructor expects a DXF string');
    }
    this._dxfString = contents;
    this._parsedDxfString = null;
    this._denormalisedParsedDxfString = null;
  }

  public parseDxfString(): string {
    this._parsedDxfString = parseString(this._dxfString);
    return this._parsedDxfString;
  }

  public parseDxfStringForViewer(): string {
    const parser = new DxfParser();
    this._parsedDxfString = parser.parseSync(this._dxfString);
    return this._parsedDxfString;
  }

  public get parsedDxfString(): string {
    if (this._parsedDxfString === null) {
      this.parseDxfString();
    }
    return this._parsedDxfString;
  }

  public denormalise(): string {
    this._denormalisedParsedDxfString = denormalise(this.parsedDxfString);
    return this._denormalisedParsedDxfString;
  }

  public get denormalised(): string {
    if (!this._denormalisedParsedDxfString) {
      this.denormalise();
    }
    return this._denormalisedParsedDxfString;
  }

  public toJson(dxfString, floorplanLayer: string) {
    const entities = dxfString.entities.filter((x) => x.layer === floorplanLayer);
    // eslint-disable-next-line no-param-reassign
    dxfString.entities = entities;
    return new TextEncoder().encode(JSON.stringify(dxfString));
  }

  public extractFixtureLocations(fixtureLayer: string) {
    return extractFixtureLocations(this.parsedDxfString, fixtureLayer);
  }
}
