import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { DriverQuery, DriverStore } from '../drivers';

import { DeviceStore } from './device.store';
import { GetDeviceQuery, updateDeviceDriverQuery, updateDeviceLocationQuery, upsertDeviceQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  destroy$ = new Subject();
  somethingWentWrong = 'message:something-went-wrong';

  constructor(
    private apollo: Apollo,
    private deviceStore: DeviceStore,
    private driverStore: DriverStore,
    private driverQuery: DriverQuery,
    private notifier: NotifierService,
    private i18next: I18NextCapPipe,
  ) {}

  reset(): void {
    this.deviceStore.reset();
  }

  resetActive(): void {
    this.deviceStore.setLoading(true);
    this.deviceStore.resetUIState();
    this.deviceStore.setActive(null);
    this.deviceStore.setError(null);
  }

  setActive(id: string | null): void {
    this.deviceStore.setActive(id);
  }

  resetDeviceUIState(): void {
    this.deviceStore.resetUIState();
  }

  getDevice(device: Partial<DOMAIN.Device>, withError = true): void {
    this.apollo
      .watchQuery({ query: GetDeviceQuery, variables: { device }, fetchPolicy: 'no-cache' })
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ data }: any) => {
          const dataDevice = data.getDevice;
          this.deviceStore.upsert(dataDevice.id, dataDevice);
          this.setActive(dataDevice?.id);
        },
        // FIXME: fix and remove eslint disable
        // eslint-disable-next-line rxjs/no-implicit-any-catch, @typescript-eslint/no-explicit-any
        (error: any) => {
          const er = error.message.replace('GraphQL error: ', '');
          if (withError) {
            this.notifier.notify('error', this.i18next.transform(`assembly:fusion.errors.${er}`));
          }
          this.deviceStore.setError(er);
          this.deviceStore.setActive(null);
          this.deviceStore.setLoading(false);
        },
      );
  }

  upsertDevice(device: DOMAIN.DeviceInput, message = 'message:successful-added'): void {
    this.deviceStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertDeviceQuery, variables: { device } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.deviceStore.setError(results?.errors ?? this.i18next.transform(this.somethingWentWrong));
          this.deviceStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertDevice: DOMAIN.Device };
        this.deviceStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.deviceStore.upsert(data.upsertDevice.id, data.upsertDevice);
        // update device in driver
        const driver = this.driverQuery.getActive();
        if (driver && device.id && driver.devices.some((d) => d.id === device.id)) {
          this.driverStore.update(driver.key, driver);
          this.driverStore.setActive(driver.key);
        }

        this.notifier.notify('success', this.i18next.transform(message));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  upsertDeviceDriver(deviceIds: string[], driverKey: string, driverId: string, message = 'message:successful-updated'): void {
    this.deviceStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: updateDeviceDriverQuery, variables: { deviceIds, driverKey, driverId } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.deviceStore.setError(results?.errors ?? this.i18next.transform(this.somethingWentWrong));
          this.deviceStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { updateDeviceDriver: DOMAIN.Device[] };
        this.deviceStore.upsertMany(data.updateDeviceDriver);
        this.deviceStore.updateUpsert({ isSuccessful: true, isLoading: false });

        this.notifier.notify('success', this.i18next.transform(message));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  updateDeviceLocation(key: string, locationKey: string | null): void {
    this.deviceStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: updateDeviceLocationQuery, variables: { key, locationKey } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.deviceStore.setError(results?.errors ?? this.i18next.transform(this.somethingWentWrong));
          this.deviceStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { updateDeviceLocation: DOMAIN.Device };
        this.deviceStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.deviceStore.upsert(data.updateDeviceLocation.id, data.updateDeviceLocation);

        // update device in driver
        const driver = this.driverQuery.getEntity(data.updateDeviceLocation.driver?.key);
        if (driver && data.updateDeviceLocation.id && driver.devices.some((d) => d.id === data.updateDeviceLocation.id)) {
          this.driverStore.update(driver.key, {
            ...driver,
            devices: driver.devices.map((dev) => (dev.id === data.updateDeviceLocation.id ? data.updateDeviceLocation : dev)),
          });
          this.driverStore.setActive(driver.key);
        }

        this.notifier.notify('success', this.i18next.transform('message:successful-saved'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }
}
