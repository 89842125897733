import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { AutomationStore } from './automation.store';
import { removeAutomationQuery, upsertAutomationQuery } from './graphql/automation.gql-mutation';
import { GetAutomationQuery, GetAutomationsQuery } from './graphql/automation.gql-query';

@Injectable({
  providedIn: 'root',
})
export class AutomationService {
  constructor(private apollo: Apollo, private automationStore: AutomationStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.automationStore.reset();
  }

  resetActive(): void {
    this.automationStore.resetUIState();
    this.setActive(null);
  }

  setActive(id: string | null): void {
    this.automationStore.setActive(id);
  }

  getAutomation(id: string): any {
    this.apollo
      .subscribe({ query: GetAutomationQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getAutomation as DOMAIN.Automation))
      .subscribe((automation) => {
        this.automationStore.upsert(automation.id, automation);
        this.setActive(automation?.id);
      });
  }

  getAutomations(projectKey: string, skip?: number, take?: number): void {
    this.automationStore.reset();

    this.apollo
      .subscribe({ query: GetAutomationsQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getAutomations as DOMAIN.Automation[]))
      .subscribe((automations) => this.automationStore.set(automations));
  }

  upsertAutomation(automation: DOMAIN.AutomationInput): void {
    this.automationStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertAutomationQuery, variables: { automation } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.automationStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `automation ${automation.name}` }));

        this.automationStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertAutomation: DOMAIN.Automation };
      this.automationStore.upsert(data.upsertAutomation.id, data.upsertAutomation);
      this.automationStore.setActive(data.upsertAutomation.id);

      // set success;
      this.automationStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  remove(id: string): void {
    this.automationStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeAutomationQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.automationStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `automation ${id}` }));

        this.automationStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.automationStore.remove(id);

      // set success;
      this.automationStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
