import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { CursorPanelComponent } from './cursor-panel.component';

type DimValue = { dimValue: number };

@Injectable({ providedIn: 'root' })
export class CursorPanelService {
  close$: Observable<void>;

  private ref: OverlayRef;
  private cursorPanelRef: ComponentRef<CursorPanelComponent>;
  private closeNotify$ = new Subject<void>();
  private data = new BehaviorSubject<DimValue | null>(null);

  constructor(private overlay: Overlay) {
    this.close$ = this.closeNotify$.asObservable();
  }

  getData(): Observable<DimValue> {
    return this.data.asObservable();
  }

  setData(data: DimValue): void {
    this.data.next(data);
  }

  hasComponentAttached(): boolean {
    return this.ref && this.ref.hasAttached();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async open(templateOrComponent: ComponentType<any>, event: MouseEvent | TouchEvent, data?: any): Promise<void> {
    if (this.ref && this.ref.hasAttached()) {
      await this.cleanup();
    }

    if (data !== undefined) {
      this.data.next(data);
    }

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();

    this.ref = this.overlay.create({
      positionStrategy,
      disposeOnNavigation: true,
      hasBackdrop: true,
      backdropClass: 'panel-backdrop',
      panelClass: 'panel-container',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const cursorPanelPortal = new ComponentPortal(CursorPanelComponent);
    this.cursorPanelRef = this.ref.attach(cursorPanelPortal);
    this.ref.backdropClick().subscribe(() => this.close());

    const sidePanelContentPortal = templateOrComponent instanceof TemplatePortal ? templateOrComponent : new ComponentPortal(templateOrComponent);
    this.cursorPanelRef.instance.contentPortal = sidePanelContentPortal;

    // pass through function to close and canClose
    this.cursorPanelRef.instance.onCloseFunc = this.close.bind(this);
  }

  async close(): Promise<void> {
    this.closeNotify$.next(null);
    await this.cleanup();
  }

  private async cleanup(): Promise<void> {
    await this.cursorPanelRef.instance.animateAway();
    this.ref.detach();
    this.ref.dispose();
  }
}
