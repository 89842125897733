<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="control$ | async as control">
  <ng-container header>
    <h1><span class="highlight">{{'reseller:controls.preview.title-prefix' |i18nextCap}}: </span>{{ control.name }}</h1>
  </ng-container>

  <ng-container info>
    <div class="control-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.name' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.name }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.description' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.description }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.project' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.projectKey }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.type' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.type }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.mode' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.mode }} - {{ 'common:dictionary.control-modes.' + control.mode | i18nextCap }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.address' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ control.address }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <div class="py-2">
      <!-- DEVICE -->
      <ng-container *ngFor="let channel of control.channels; let i=index">
        <h3>{{'reseller:controls.preview.device.title' |i18nextCap}} {{ i+1 }}</h3>
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="30">{{'common:dictionary.address' |i18nextCap}}</div>
            <div class="content" fxFlex="70">{{ channel.address }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="30">{{'common:dictionary.type' |i18nextCap}}</div>
            <div class="content" fxFlex="70">{{ control.type }}</div>
          </div>

          <ng-container [ngSwitch]="control.mode">
            <div class="content" fxFlex="70" *ngSwitchCase="0"></div>
            <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="infoList" [ngTemplateOutletContext]="{settings: [channel.single, channel.double]}">
            </ng-container>

            <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="infoList" [ngTemplateOutletContext]="{settings: [channel.high, channel.low]}">
            </ng-container>

            <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{setting: channel.single}"> </ng-container>

            <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{setting: channel.alternate}"> </ng-container>

            <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{setting: channel.alternate}"> </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
</summa-side-panel-content>

<ng-template #infoList let-settings="settings">
  <ng-container *ngFor="let setting of settings" [ngTemplateOutlet]="info" [ngTemplateOutletContext]="{ setting }"> </ng-container>
</ng-template>

<ng-template #info let-setting="setting">
  <div *ngIf="setting.scenario" class="item" fxLayout="row wrap">
    <div class="title" fxFlex="30">{{'common:dictionary.scenario' |i18nextCap}}</div>
    <div class="content" fxFlex="70">{{ setting.scenario }}</div>
  </div>

  <div *ngIf="setting.groups?.length > 0" class="item" fxLayout="row wrap">
    <div class="title" fxFlex="30">{{'common:dictionary.group_plural' |i18nextCap}}</div>
    <ul class="content m-0 p-0" fxFlex="70">
      <li *ngFor="let channelGroup of setting.groups">{{ channelGroup.group }} - {{ channelGroup.scene }}</li>
    </ul>
  </div>

  <div *ngIf="setting.alternateScenario" class="item" fxLayout="row wrap">
    <div class="title" fxFlex="30">{{'reseller:controls.add.alternate-scenario' |i18nextCap}}</div>
    <div class="content" fxFlex="70">{{ setting.alternateScenario }}</div>
  </div>

  <div *ngIf="setting.key" class="item" fxLayout="row wrap">
    <div class="title" fxFlex="30">{{'common:dictionary.key' |i18nextCap}}</div>
    <div class="content" fxFlex="70">{{ setting.key }}</div>
  </div>
</ng-template>
