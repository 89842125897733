import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ProjectQuery, ProjectService, ResellerQuery, ResellerService } from '../../../data-access';

@Injectable()
export class ProjectEditSandbox {
  params$ = this.router.selectParams();
  reseller$ = this.resellerQuery.selectActive();
  upsertState$ = this.projectQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData<DTO.Project>();
  project$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  constructor(
    private router: RouterQuery,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private sidePanelService: SidePanelService,
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
  ) {}

  getProject(id: string): void {
    this.projectService.getProject(id);
  }

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;

    this.resellerService.getReseller(id);
  }

  upsertProject(project: DTO.ProjectInput): void {
    this.projectService.upsertProject(project);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.projectService.resetProjectUIState();
  }
}
