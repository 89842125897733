export const openAreaOffice = 'open_area_office';
export const independentOffice = 'independent_office';
export const corridorOffice = 'corridor_office';
export const elevatorHallOffice = 'elevator_hall_office';
export const restAreaOffice = 'rest_area_office';
export const toiletOffice = 'toilet_office';
export const emergencyChannelOffice = 'emergency_channel_office';
export const meetingRoomOffice = 'meeting_room_office';
export const showroomOffice = 'showroom_office';
export const supportingEquipmentRoomOffice = 'supporting_equipment_room_office';
export const parkingLotOffice = 'parking_lot_office';
export const trafficLaneOffice = 'traffic_lane_office';
export const classroomEducational = 'classroom_educational';
export const multipurposeClassroomEducational = 'multipurpose_classroom_educational';
export const teachersOfficeEducational = 'teachers_office_educational';
export const openReadingAreaEducational = 'open_reading_area_educational';
export const meetingRoomEducational = 'meeting_room_educational';
export const corridorEducational = 'corridor_educational';
export const canteenEducational = 'canteen_educational';
export const gymEducational = 'gym_educational';
export const dormRoomEducational = 'dorm_room_educational';
export const dormitoryPublicAreaEducational = 'dormitory_public_area_educational';
export const dormitoryToiletEducational = 'dormitory_toilet_educational';
export const emergencyChannelEducational = 'emergency_channel_educational';
export const supportingEquipmentRoomEducational = 'supporting_equipment_room_educational';
export const entranceHealth = 'entrance_health';
export const receptionHealth = 'reception_health';
export const corridorHealth = 'corridor_health';
export const waitingHallHealth = 'waiting_hall_health';
export const patientRoomHealth = 'patient_room_health';
export const toiletHealth = 'toilet_health';
export const icuHealth = 'icu_health';
export const recoveryRoomHealth = 'recovery_room_health';
export const doctorRoomHealth = 'doctor_room_health';
export const restaurantHealth = 'restaurant_health';
export const openAreaCallCenter = 'open_area_callcenter';
export const independentOfficeCallCenter = 'independent_office_callcenter';
export const corridorCallCenter = 'corridor_callcenter';
export const toiletCallCenter = 'toilet_callcenter';
export const receptionCallCenter = 'reception_callcenter';
export const entranceRestaurant = 'entrance_restaurant';
export const mainRoomRestaurant = 'main_room_restaurant';
export const kitchenRestaurant = 'kitchen_restaurant';
export const toiletRestaurant = 'toilet_restaurant';
export const storeRoomRestaurant = 'store_room_restaurant';
export const coldRoomRestaurant = 'cold_room_restaurant';
export const dormitoryNursingHome = 'dormitory_nursing_home';
export const entranceNursingHome = 'entrance_nursing_home';
export const bathroomNursingHome = 'bathroom_nursing_home';
export const dinningRoomNursingHome = 'dinning_room_nursing_home';
export const livingRoomNursingHome = 'living_room_nursing_home';
export const corridorNursingHome = 'corridor_nursing_home';
export const kitchenNursingHome = 'kitchen_nursing_home';
export const laundryRoomNursingHome = 'laundry_room_nursing_home';
export const entranceGym = 'entrance_gym';
export const machineRoomGym = 'machine_room_gym';
export const corridorGym = 'corridor_gym';
export const toiletGym = 'toilet_gym';
export const lockerRoomGym = 'locker_room_gym';
export const spaGym = 'spa_gym';
export const restaurantGym = 'restaurant_gym';
export const barGym = 'bar_gym';
export const entranceIndustrial = 'entrance_industrial';
export const corridorIndustrial = 'corridor_industrial';
export const toiletIndustrial = 'toilet_industrial';
export const machineRoomIndustrial = 'machine_room_industrial';
export const storeRoomIndustrial = 'store_room_industrial';
export const warehouseIindustrial = 'warehouse_industrial';
export const parkingIndustrial = 'parking_industrial';
export const officeIndustrial = 'office_industrial';
export const entranceWarehouse = 'entrance_warehouse';
export const corridorWarehouse = 'corridor_warehouse';
export const toiletWarehouse = 'toilet_warehouse';
export const storeRoomWarehouse = 'store_room_warehouse';
export const mainRoomWarehouse = 'main_room_warehouse';
export const parkingWarehouse = 'parking_warehouse';
export const officeWarehouse = 'office_warehouse';
export const entranceHotel = 'entrance_hotel';
export const corridorHhtel = 'corridor_hotel';
export const roomHotel = 'room_hotel';
export const toiletHotel = 'toilet_hotel';
export const kitchenHotel = 'kitchen_hotel';
export const laundryRoomHotel = 'laundry_room_hotel';
export const barHotel = 'bar_hotel';
export const restaurantHotel = 'restaurant_hotel';
export const parkingHotel = 'parking_hotel';
export const gymHotel = 'gym_hotel';
export const entranceLaboratory = 'entrance_laboratory';
export const corridorLaboratory = 'corridor_laboratory';
export const toiletLaboratory = 'toilet_laboratory';
export const machineRoomLaboratory = 'machine_room_laboratory';
export const daylight = 'daylight';

export const dynamicSceneSpace = [
  openAreaOffice,
  independentOffice,
  corridorOffice,
  elevatorHallOffice,
  restAreaOffice,
  toiletOffice,
  emergencyChannelOffice,
  meetingRoomOffice,
  showroomOffice,
  supportingEquipmentRoomOffice,
  parkingLotOffice,
  trafficLaneOffice,
  classroomEducational,
  multipurposeClassroomEducational,
  teachersOfficeEducational,
  openReadingAreaEducational,
  meetingRoomEducational,
  corridorEducational,
  canteenEducational,
  gymEducational,
  dormRoomEducational,
  dormitoryPublicAreaEducational,
  dormitoryToiletEducational,
  emergencyChannelEducational,
  supportingEquipmentRoomEducational,
  entranceHealth,
  receptionHealth,
  corridorHealth,
  waitingHallHealth,
  patientRoomHealth,
  toiletHealth,
  icuHealth,
  recoveryRoomHealth,
  doctorRoomHealth,
  restaurantHealth,
  openAreaCallCenter,
  independentOfficeCallCenter,
  corridorCallCenter,
  toiletCallCenter,
  receptionCallCenter,
  entranceRestaurant,
  mainRoomRestaurant,
  kitchenRestaurant,
  toiletRestaurant,
  storeRoomRestaurant,
  coldRoomRestaurant,
  dormitoryNursingHome,
  entranceNursingHome,
  bathroomNursingHome,
  dinningRoomNursingHome,
  livingRoomNursingHome,
  corridorNursingHome,
  kitchenNursingHome,
  laundryRoomNursingHome,
  entranceGym,
  machineRoomGym,
  corridorGym,
  toiletGym,
  lockerRoomGym,
  spaGym,
  restaurantGym,
  barGym,
  entranceIndustrial,
  corridorIndustrial,
  toiletIndustrial,
  machineRoomIndustrial,
  storeRoomIndustrial,
  warehouseIindustrial,
  parkingIndustrial,
  officeIndustrial,
  entranceWarehouse,
  corridorWarehouse,
  toiletWarehouse,
  storeRoomWarehouse,
  mainRoomWarehouse,
  parkingWarehouse,
  officeWarehouse,
  entranceHotel,
  corridorHhtel,
  roomHotel,
  toiletHotel,
  kitchenHotel,
  laundryRoomHotel,
  barHotel,
  restaurantHotel,
  parkingHotel,
  gymHotel,
  entranceLaboratory,
  corridorLaboratory,
  toiletLaboratory,
  machineRoomLaboratory,
  daylight,
] as const;
export type DynamicSceneSpace = typeof dynamicSceneSpace[number];
