import { gql } from 'apollo-angular';

export const getFixtureStatesQuery = gql`
  query getFixtureStates($project: String!) {
    getFixtureStates(project: $project) {
      project
      timestamp
      topic
      state
      ip
      ticks
      temp
      version
      type
      channels
      dimLevel
      walkTime
      fadeRate
      amountCh
      vIn
      ledCurr
      ledVolt
    }
  }
`;
