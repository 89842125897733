<h1 class="mb-0" mat-dialog-title *ngIf="dialogData.title">
  {{ dialogData.title }}
</h1>

<div mat-dialog-content class="save-scene-modal__content">
  <p class="alert alert-secondary" [innerHTML]="dialogData.content"></p>
  <form *ngIf="form" [formGroup]="form" class="form w-100" (ngSubmit)="submit$.next()">
    <section fxLayout="column">
      <mat-form-field appearance="legacy">
        <mat-label>{{ 'common:dictionary.name' | i18nextCap }}</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
    </section>

    <section fxLayout="column">
      <mat-form-field appearance="legacy">
        <mat-label>{{ 'common:dictionary.description' | i18nextCap }}</mat-label>
        <input matInput type="text" formControlName="description" />
      </mat-form-field>
    </section>

    <section fxLayout="column">
      <mat-form-field appearance="legacy">
        <mat-label>{{ 'common:dictionary.sort-order' | i18nextCap }}</mat-label>
        <input matInput type="number" formControlName="sortOrder" />
      </mat-form-field>
    </section>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ dialogData.cancelButton }}
  </button>

  <button *ngIf="form" mat-button mat-raised-button [disabled]="form.invalid" color="primary" (click)="submit$.next()">
    {{ dialogData.primaryButton }}
  </button>
</div>
