import { gql } from 'apollo-angular';

export const upsertFixtureQuery = gql`
  mutation UpsertFixture($fixtureInput: FixtureInput!) {
    upsertFixture(fixtureInput: $fixtureInput) {
      id
      floorplanKey
      location {
        x
        y
        height
      }
      mainGroup
      type
      devices {
        key
      }
    }
  }
`;
export const upsertFixturesQuery = gql`
  mutation UpsertFixtures($fixturesInput: [FixtureInput!]!) {
    upsertFixtures(fixtureInput: $fixturesInput) {
      id
      floorplanKey
      location {
        x
        y
        height
      }
      mainGroup
      type
      devices {
        key
      }
    }
  }
`;
