import { gql } from 'apollo-angular';

export const GetDocumentsQuery = gql`
  query getDocuments($skip: Int, $take: Int, $type: String) {
    getDocuments(skip: $skip, take: $take, type: $type) {
      id
      name
      content
      version
      lastModified
      type
      order
    }
  }
`;

export const GetDocumentQuery = gql`
  query getDocument($id: String!) {
    getDocument(id: $id) {
      id
      name
      content
      version
      lastModified
      type
      order
    }
  }
`;
