import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { DeviceState, DeviceStore } from './device.store';

@Injectable({
  providedIn: 'root',
})
export class DeviceQuery extends QueryEntity<DeviceState, DOMAIN.Device> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: DeviceStore) {
    super(store);
  }
}
