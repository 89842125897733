import { gql } from 'apollo-angular';

export const upsertResellerQuery = gql`
  mutation upsertReseller($reseller: ResellerInput!) {
    upsertReseller(reseller: $reseller) {
      id
      name
      contact {
        name
        email
        phone
      }
      paymentInfo {
        accountName
        iban
        bic
      }
      clients {
        id
        name
      }
    }
  }
`;

export const removeResellerQuery = gql`
  mutation removeReseller($id: String!) {
    removeReseller(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
