import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { DOMAIN, ENV, PortalAPI } from '@summa/models';
import { CommandService, DeviceService, DriverQuery, DriverService, FloorplanQuery, FloorplanService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectFloorplansDetailsSandbox {
  project$ = this.projectQuery.selectActive();
  floorplans$ = this.floorplanQuery.selectAll();
  floorplan$ = this.floorplanQuery.selectActive();
  drivers$ = this.driverQuery.selectAll();
  driver$ = this.driverQuery.selectActive();
  selectedDriver$ = this.driverQuery.selectActive();
  params$ = this.router.params;
  url$ = this.routerQuery.select();
  // removeState$ = this.storageQuery.selectRemoveState$;

  constructor(
    private projectQuery: ProjectQuery,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private floorplanService: FloorplanService,
    private floorplanQuery: FloorplanQuery,
    private commandService: CommandService,
    private deviceService: DeviceService,
    private router: ActivatedRoute,
    private routerQuery: RouterQuery,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  getDrivers(projectKey: string): void {
    this.driverService.getDriverList(projectKey);
  }

  activateDriver(key: string): void {
    this.driverService.setActive(key);
  }

  getFloorplan(floorplanId: string): void {
    this.floorplanService.setActive(floorplanId);
  }

  getFloorplans(projectKey: string): void {
    this.floorplanService.getFloorplans(projectKey);
  }

  updateLocation(deviceKey: string, locationKey: string | null): void {
    this.deviceService.updateDeviceLocation(deviceKey, locationKey);
  }

  executeDeviceCommand(projectKey: string, device: DOMAIN.Device, state: 'ON' | 'OFF', dimValue?: number): void {
    const command: PortalAPI.CommandInput = { projectKey, state, deviceIds: [device.id], ...(dimValue && dimValue !== -1 && { dimValue }) };
    this.commandService.executeCommand(command);
  }

  reset(): void {
    this.driverService.reset();
    this.driverService.resetActive();
    this.floorplanService.reset();
    this.driverService.resetActive();
  }
}
