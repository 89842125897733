import { gql } from 'apollo-angular';

export const upsertSceneQuery = gql`
  mutation upsertScene($scene: SceneInput!) {
    upsertScene(scene: $scene) {
      id
      projectKey
      name
      description
      sortOrder
      types {
        deviceType
        dimValue
        cct
        duv
        x
        y
      }
      devices {
        deviceId
        dimValue
      }
    }
  }
`;

export const removeSceneQuery = gql`
  mutation removeScene($id: String!) {
    removeScene(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
