import { DOMAIN } from '../..';

import { FixtureStateState } from './fixture-state.constant';

export interface FixtureState {
  project: string;
  state: FixtureStateState;
  topic: string;
  timestamp: number;
  ip?: string;
  ticks: number;
  version: string;
  type: DOMAIN.DeviceType;
  temp: number;
  channels: Channel[];
  dimLevel?: number;
  fadeRate?: number;
  amountCh?: number;
  walkTime?: number;
  ledCurr?: number;
  ledVolt?: number;
}

export class Channel {
  id: string;
  intensity: number;
  cu: number;
  off: number;
  volt?: number;
}
