import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { InstallerQuery, InstallerService } from '../../../../data-access';

@Injectable()
export class ProjectInstallerDetailsSandbox {
  params$ = this.router.params;
  data$ = this.sidePanelService.getData();
  installer$ = this.installerQuery.selectActive();

  constructor(
    private router: ActivatedRoute,
    private installerQuery: InstallerQuery,
    private installerService: InstallerService,
    private sidePanelService: SidePanelService,
  ) {}

  getInstaller(id: string): void {
    this.installerService.getInstaller(id);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.installerService.resetActive();
  }
}
