import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DTO } from '@summa/portal/models/dto';

@Component({
  selector: 'summa-control-panel-control',
  templateUrl: './control-panel-control.component.html',
  styleUrls: ['./control-panel-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPanelControlComponent {
  @Input() control?: DTO.ControlPanelControl;
  @Input() hideEdit = false;

  @Output() updateControl: EventEmitter<void> = new EventEmitter();
  @Output() removeControl: EventEmitter<void> = new EventEmitter();
  @Output() executeControl: EventEmitter<boolean> = new EventEmitter();

  percentage = 100;
  get glowEffect(): string {
    return `0px 0px 28px ${this.percentage / 2}px rgb(255 165 0 / 15%)`;
  }

  public controlClicked(): void {
    this.executeControl.emit(!this.control?.isActive);
  }
}
