import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { ScenarioQuery, ScenarioService } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectScenarioOverviewSandbox {
  scenarios$ = this.scenarioQuery.selectAll().pipe(map((scenarios) => scenarios.sort((a, b) => a.sortOrder - b.sortOrder)));
  params$ = this.router.selectParams();
  removeState$ = this.scenarioQuery.selectRemoveState$;
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private router: RouterQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  getScenario(scenarioId: string): void {
    this.scenarioService.getScenario(scenarioId);
  }

  removeScenario(id: string): void {
    this.scenarioService.remove(id);
  }

  reset(): void {
    this.scenarioService.reset();
  }
}
