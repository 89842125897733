import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

import { ENV, UI } from '@summa/models';

@Component({
  selector: 'summa-portal-page-header',
  templateUrl: './portal-page-header.component.html',
  styleUrls: ['./portal-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalPageHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() defaultLink: string;
  @Input() menuItems: UI.MenuItem[];
  @Input() hideUser: boolean;
  @Input() hideBreadcrumb = false;
  @Input() hideProfile = false;
  @Input() currentUser: string | undefined;
  @Input() showPrevious: boolean;
  @Output() logout = new EventEmitter<void>();
  @Output() previous = new EventEmitter<void>();

  constructor(@Inject('environment') private environment: ENV.Environment) {}

  version: string;

  ngOnInit(): void {
    this.version = this.environment.app.version;
  }
}
