import { gql } from 'apollo-angular';

export const upsertDeviceQuery = gql`
  mutation upsertDevice($device: DeviceInput!) {
    upsertDevice(device: $device) {
      id
      key
      address
      type
      category
      settings
      locationKey
      driver {
        id
        key
        type
        projectKey
      }
    }
  }
`;

export const updateDeviceLocationQuery = gql`
  mutation updateDeviceLocation($key: String!, $locationKey: String!) {
    updateDeviceLocation(key: $key, locationKey: $locationKey) {
      id
      key
      address
      type
      category
      settings
      locationKey
      driver {
        id
        key
        type
        projectKey
      }
    }
  }
`;

export const updateDeviceDriverQuery = gql`
  mutation updateDeviceDriver($deviceIds: [String!]!, $driverKey: String!, $driverId: String!) {
    updateDeviceDriver(deviceIds: $deviceIds, driverKey: $driverKey, driverId: $driverId) {
      id
      key
      address
      type
      category
      settings
      locationKey
      driver {
        id
        key
        type
        projectKey
      }
    }
  }
`;
