<section class="mx-4" *ngIf="sandbox.controlPanel$ | async as controlPanel">
  <section class="mb-2" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <h1 class="m-0 p-0">{{ controlPanel.name }}</h1>
    <button *ngIf="isPortal && userHasEditPermission" mat-button (click)="updateControlPanel$.next(controlPanel.id)">
      <mat-icon svgIcon="pencil"></mat-icon>
    </button>
  </section>

  <summa-control-panel-scenario class="my-2" [scenarios]="controlPanel.scenarios"></summa-control-panel-scenario>

  <section fxLayout="row wrap" fxLayoutGap="1rem grid" class="mt-2">
    <div
      *ngFor="let control of controlPanel.controls"
      fxFlex.xs="100"
      fxFlex.sm="50"
      fxFlex.md="33"
      fxFlex.gt-md="20"
      fxLayout="column"
      fxLayoutAlign="start center"
    >
      <summa-control-panel-control
        [control]="control"
        [hideEdit]="!userHasEditPermission || !isPortal || control.id === 'all'"
        (updateControl)="upsertControl$.next(control)"
        (removeControl)="removeControl$.next(control)"
        (executeControl)="executeControl$.next({ control, isActive: $event })"
      ></summa-control-panel-control>
    </div>

    <div
      *ngIf="isPortal && userHasEditPermission"
      fxFlex.xs="100"
      fxFlex.sm="50"
      fxFlex.md="33"
      fxFlex.gt-md="20"
      fxLayout="column"
      (click)="upsertControl$.next(null)"
    >
      <div class="summa-card clickable add-control-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <span class="add-control-text">
          <mat-icon svgIcon="plus-thick"></mat-icon>
        </span>
      </div>
    </div>
  </section>
</section>
