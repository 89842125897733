import { gql } from 'apollo-angular';

export const upsertFirmwareQuery = gql`
  mutation upsertFirmware($firmware: FirmwareInput!) {
    upsertFirmware(firmware: $firmware) {
      name
      date
      lastModified
      projects {
        projectKey
        isExecuted
        executionDate
      }
    }
  }
`;

export const removeFirmwareQuery = gql`
  mutation removeFirmware($id: String!) {
    removeFirmware(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
