import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectSceneAddPage } from '../../project-scenes/project-scene-add/project-scene-add.page';
import { ProjectScenePreviewPage } from '../../project-scenes/project-scene-preview/project-scene-preview.page';

import { ProjectCardDetailsSandbox } from './project-card-details.sandbox';

@Component({
  selector: 'summa-project-card-details',
  templateUrl: './project-card-details.page.html',
  styleUrls: ['./project-card-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectCardDetailsSandbox],
})
export class ProjectCardDetailsPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertScenePanel$ = new Subject();

  constructor(public sandbox: ProjectCardDetailsSandbox, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ cardKey }) => {
      this.sandbox.getCard(cardKey);
    });

    this.handleSceneSuccess();
    this.handleUpsertScenePanel();
  }

  openScenePreview(sceneId: string): void {
    this.sandbox.getScene(sceneId);
    this.sidePanelService.open(ProjectScenePreviewPage);
  }

  private handleUpsertScenePanel(): void {
    this.upsertScenePanel$.pipe(takeUntil(this.destroy$)).subscribe((scene) => {
      this.sidePanelService.open(ProjectSceneAddPage, scene);
    });
  }

  private handleSceneSuccess(): void {
    this.sandbox.updateScene$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([sceneState, params]) => {
      if (sceneState.isSuccessful) {
        this.sandbox.getCard(params.cardKey);
      }
    });
  }
}
