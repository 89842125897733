import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { I18NextCapPipe } from 'angular-i18next';
import { combineLatest, map } from 'rxjs';
import { Counter } from '@summa/shared/dashboard/ui';
import { InstallerQuery, InstallerService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectInstallerWidgetSandbox {
  project$ = this.projectQuery.selectActive();
  totalInstallers$ = this.installerQuery.selectCount();
  enabledInstallers$ = this.installerQuery.selectAll({ filterBy: (installer) => installer.enabled });
  disabledInstallers$ = this.installerQuery.selectAll({ filterBy: (installer) => !installer.enabled });

  installerCounters$ = combineLatest([this.enabledInstallers$, this.disabledInstallers$]).pipe(
    map(([enabledInstallers, disabledInstallers]): Counter[] => {
      return [
        {
          title: this.i18next.transform('common:dictionary.enabled'),
          class: 'success',
          count: enabledInstallers.length,
        },
        {
          title: this.i18next.transform('project:installer-widget.disabled'),
          class: 'warning',
          count: disabledInstallers.length,
        },
      ];
    }),
  );

  params$ = this.routerQuery.selectParams();

  constructor(
    private routerQuery: RouterQuery,
    private projectQuery: ProjectQuery,
    private installerQuery: InstallerQuery,
    private installerService: InstallerService,
    private i18next: I18NextCapPipe,
  ) {}

  reset(): void {
    this.installerService.reset();
  }

  getInstallers(projectKey: string): void {
    this.installerService.getInstallers(projectKey);
  }
}
