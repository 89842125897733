<div fxLayout="row" fxLayoutGap="16px" class="w-100 mt-2">
  <div fxLayout="column" fxLayoutGap="1rem" class="left-column">
    <!-- FIXTURE -->
    <section class="reseller-card p-2 px-4" *ngIf="!(modus$ | async)">
      <h3>{{ 'common:dictionary.fixture' | i18nextCap }}</h3>
      <form [formGroup]="formFixture" class="form p-0" *ngIf="formFixture.value.id !== null; else text">
        <section fxLayout="column" fxLayoutGap="1rem">
          <div formGroupName="location" class="devices mb-0 pb-1">
            <section fxLayout="row" fxLayoutGap="1rem">
              <mat-form-field appearance="legacy" [style.width.px]="120">
                <mat-label>{{ 'project:floorplans.drivers.fixture.x' | i18nextCap }}</mat-label>
                <input matInput formControlName="x" type="number" />
              </mat-form-field>
              <mat-form-field appearance="legacy" [style.width.px]="120">
                <mat-label>{{ 'project:floorplans.drivers.fixture.y' | i18nextCap }}</mat-label>
                <input matInput formControlName="y" type="number" />
              </mat-form-field>
            </section>
            <mat-form-field appearance="legacy" class="w-100 mb-0">
              <mat-label>{{ 'project:floorplans.drivers.fixture.height' | i18nextCap }}</mat-label>
              <input matInput formControlName="height" type="number" />
            </mat-form-field>
          </div>
          <div *ngIf="formFixture.value.devices.length" formArrayName="devices">
            <h4>{{ 'project:floorplans.drivers.fixture-devices' | i18nextCap }}</h4>
            <div class="devices">
              <div *ngFor="let device of formFixture.value.devices; index as i">
                <section fxLayout="column">
                  <div fxLayoutAlign="start center">
                    <p>
                      <span class="number">{{ i + 1 }} | </span>{{ device.key }}
                    </p>
                    <mat-icon class="remove-icon" svgIcon="delete-forever-outline" (click)="removeDeviceKey$.next(device.key)"></mat-icon>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div>
            <mat-checkbox [checked]="enableMoveFixture$ | async" (change)="enableMoveFixture($event.checked)">{{
              'project:floorplans.drivers.move-fixture' | i18nextCap
            }}</mat-checkbox>
          </div>
        </section>
        <section class="buttons mt-1" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="0.5rem">
          <button mat-button type="button" class="button save" (click)="handleAddDevice()">
            {{ 'common:buttons.add-device' | i18nextCap }}
          </button>
          <button mat-button type="button" class="button save" (click)="handleSaveFixtureForm()">{{ 'common:buttons.save' | i18nextCap }}</button>
        </section>
      </form>
      <ng-template #text
        ><section class="p-1 devices">
          <p>{{ 'project:floorplans.drivers.select-fixture' | i18nextCap }}</p>
        </section></ng-template
      >
    </section>
    <!-- CONFIG DRIVERS -->
    <section class="reseller-card p-2 px-4" *ngIf="modus$ | async">
      <h3 fxLayoutAlign="start center">
        <mat-icon (click)="modus$.next(null)" class="mr-1 clickable" svgIcon="chevron-double-left"></mat-icon>
        <div>{{ 'common:dictionary.fixture' | i18nextCap }}</div>
      </h3>

      <!-- <h3>{{ 'project:add-drivers.drivers' | i18nextCap }}</h3> -->
      <p>{{ 'project:floorplans.select-driver-description' | i18nextCap }}</p>

      <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
        <mat-icon class="scan-icon mt-2" tabindex="5" svgIcon="qrcode-scan" (click)="focusOnDriver()"></mat-icon>
        <mat-form-field appearance="legacy">
          <mat-label>{{ 'common:domain.driver' | i18nextCap }}</mat-label>
          <input
            #driverInput
            [formControl]="driver"
            pattern="^((P|C|T)[0-9]{2}[0-9]{2}[sS])\w+"
            matInput
            autofocus
            tabindex="1"
            (blur)="driverChange$.next(null)"
          />
          <mat-error *ngIf="driver.hasError('pattern')">{{ 'assembly:drivers.add.driver-pattern-error' | i18nextCap }}</mat-error>
          <mat-error *ngIf="driver.hasError('exists')">{{ 'assembly:drivers.add.driver-exists-error' | i18nextCap }}</mat-error>
        </mat-form-field>
      </section>
      <form [formGroup]="form" class="floorplan-driver-form">
        <!-- DEVICES -->
        <div *ngIf="formDevices.controls.length > 0">
          <!-- SINGLE -->
          <ng-container *ngIf="formDevices.controls.length === 1; else multiple">
            <section class="devices px-4">
              <ng-container formArrayName="devices">
                <h3>{{ 'project:add-drivers.drivers' | i18nextCap }}</h3>
                <ng-container [formGroupName]="0">
                  <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                    <mat-form-field class="form-field">
                      <mat-label>{{ formDevices.controls[0].value.device.key }}</mat-label>
                      <input tabindex="3" formControlName="locationKey" matInput class="mr-1" />
                      <mat-icon
                        (click)="addDeviceClick$.next(formDevices.controls[0].value.device)"
                        [svgIcon]="'common:icons.add' | i18next"
                        matSuffix
                      ></mat-icon>
                    </mat-form-field>
                  </section>
                </ng-container>
              </ng-container>
            </section>
          </ng-container>
          <!-- MULTIPLE -->
          <ng-template #multiple>
            <section formArrayName="devices" class="devices px-4" *ngIf="formDevices.controls.length > 0">
              <h3>{{ 'project:add-drivers.drivers' | i18nextCap }}</h3>
              <ng-container *ngFor="let _ of formDevices.controls; index as i">
                <ng-container [formGroupName]="i">
                  <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                    <mat-form-field class="form-field">
                      <mat-label>{{ _.value.device.key }}</mat-label>
                      <input tabindex="3" formControlName="locationKey" matInput class="mr-1" />
                      <mat-icon (click)="addDeviceClick$.next(_.value.device)" [svgIcon]="'common:icons.add' | i18next" matSuffix></mat-icon>
                    </mat-form-field>
                  </section>
                </ng-container>
              </ng-container>
            </section>
          </ng-template>
        </div>

        <!-- SELECTED DEVICES -->
        <div *ngIf="formSelectedDevices.controls.length > 0">
          <!-- SINGLE -->
          <ng-container *ngIf="formSelectedDevices.controls.length === 1; else multiple">
            <section class="devices px-4">
              <ng-container formArrayName="selectedDevices">
                <h3>{{ 'project:add-drivers.select-drivers' | i18nextCap }}</h3>
                <ng-container [formGroupName]="0">
                  <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                    <mat-form-field class="form-field">
                      <mat-label>{{ formSelectedDevices.controls[0].value.device.key }}</mat-label>
                      <input tabindex="3" formControlName="locationKey" matInput class="mr-1" />
                      <mat-icon
                        (click)="removeDeviceClick$.next(formSelectedDevices.controls[0].value.device)"
                        svgIcon="close-circle-outline"
                        matSuffix
                      ></mat-icon>
                    </mat-form-field>
                  </section>
                </ng-container>
              </ng-container>
            </section>
          </ng-container>
          <!-- MULTIPLE -->
          <ng-template #multiple>
            <section formArrayName="selectedDevices" class="devices px-4" *ngIf="formSelectedDevices.controls.length > 0">
              <h3>{{ 'project:add-drivers.select-drivers' | i18nextCap }}</h3>
              <ng-container *ngFor="let _ of formSelectedDevices.controls; index as i">
                <ng-container [formGroupName]="i">
                  <section fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start start">
                    <mat-form-field class="form-field">
                      <mat-label>{{ _.value.device.key }}</mat-label>
                      <input tabindex="3" formControlName="locationKey" matInput class="mr-1" />
                      <mat-icon (click)="removeDeviceClick$.next(_.value.device)" svgIcon="close-circle-outline" matSuffix></mat-icon>
                    </mat-form-field>
                  </section>
                </ng-container>
              </ng-container>
            </section>
          </ng-template>
        </div>
      </form>
      <!-- <section *ngIf="subscriber" class="alert alert-warning mb-2" fxLayout="row" fxLayoutGap="0.5rem" fxLayoutAlign="start center">
        <mat-icon svgIcon="home-map-marker"> </mat-icon>
        <span> {{ 'project:floorplans.drivers.select-location' | i18nextCap }} </span>
      </section> -->
    </section>
    <section *ngIf="enableMoveFixture$ | async" fxLayoutAlign="space-around center" class="move-fixture-enable">
      <p>{{ 'project:floorplans.drivers.move-fixture-enable' | i18nextCap }}</p>
    </section>
  </div>
  <!-- FLOORPLAN -->
  <div fxFlex="auto" *ngIf="sandbox.floorplan$ | async" class="reseller-card lg">
    <ng-container *ngIf="!(sandbox.driverLoader$ | async)">
      <summa-floorplan-dxf-viewer
        [file]="fileUrl"
        [fixtures]="fixturesState$ | async"
        [customHeight]="'75vh'"
        [enableMoveFixture]="enableMoveFixture$ | async"
        (activateElement)="dxfClicked$.next($event)"
      ></summa-floorplan-dxf-viewer>
    </ng-container>
  </div>
</div>
