import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { getGridEyeClusterQuery, gridEyeClusterSubscription } from './graphql';
import { GridEyeClusterStore } from './grid-eye-cluster.store';

@Injectable({
  providedIn: 'root',
})
export class GridEyeClusterService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private gridEyeClusterStore: GridEyeClusterStore) {}

  reset(): void {
    this.gridEyeClusterStore.reset();
    this.subscriptions?.unsubscribe();
  }

  getGridEyeClusters(project: string): void {
    this.apollo
      .subscribe({ query: getGridEyeClusterQuery, variables: { project } })
      .pipe(map((d: any) => d.data.getGridEyeClusters as DOMAIN.GridEyeCluster[]))
      .subscribe((gridEyeCluster) => gridEyeCluster?.length > 0 && this.gridEyeClusterStore.upsertMany(gridEyeCluster));
  }

  subscribe(projects: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: gridEyeClusterSubscription, variables: { projects } }).subscribe((heatGridLog) => {
      const state = (heatGridLog.data as { observeGridEyeClusters: DOMAIN.GridEyeCluster }).observeGridEyeClusters;
      this.gridEyeClusterStore.upsert(state.id, state);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
