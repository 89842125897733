<ng-container
  *ngIf="{
    updatePermission: this.sandbox.updatePermission$ | async
  } as permissions"
>
  <summa-details-tile-widget
    [title]="'common:dictionary.driver_plural' | i18nextCap"
    [counters]="sandbox.driverCounters$ | async"
    [route]="{ destination: ['devices'], tooltip: 'common:dictionary.view-all' | i18nextCap }"
    [totalCountLabel]="['dashboard:dashboard.total-number-of' | i18nextCap, 'common:dictionary.driver_plural' | i18next].join(' ')"
  >
    <ng-container header>
      <button [permission-check]="permissions.updatePermission" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon svgIcon="dots-vertical"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="setBaseline$.next()">
          <mat-icon [svgIcon]="'common:icons.set-sensors-baseline' | i18next"></mat-icon>
          <span class="pr-1">{{ 'project:drivers.set-sensors-baseline' | i18nextCap }}</span>
        </button>
      </mat-menu>
    </ng-container>
  </summa-details-tile-widget>
</ng-container>
