import { gql } from 'apollo-angular';

export const GetProjectQuery = gql`
  query getProject($key: String, $id: String) {
    getProject(key: $key, id: $id) {
      id
      name
      key
      apiKey
      timezone
      location {
        id
      }
      settings {
        wifiSsid
        wifiPassword
      }
      geolocation {
        longitude
        latitude
      }
      gatewayKey
      gateway {
        state
        messageTime
        addresses
        key
        modified
      }
    }
  }
`;

export const GetProjectsQuery = gql`
  query getProjects($skip: Int, $take: Int) {
    getProjects(skip: $skip, take: $take) {
      id
      name
      key
      apiKey
      gateway {
        state
      }
    }
  }
`;
