import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map } from 'rxjs/operators';

import { PERMISSIONS } from '@summa/models';
import { flatten, isNotNullOrUndefined, onlyUnique } from '@summa/shared/util/typescript';
import { AuthenticationService, AuthQuery, NotificationQuery, NotificationService, ResellerQuery, ResellerService } from '../../data-access';

@Injectable()
export class ResellerSandbox {
  currentUser$ = this.authQuery.selectActive();
  currentReseller$ = this.resellerQuery.selectActive();
  currentResellers$ = this.currentUser$.pipe(
    filter(isNotNullOrUndefined),
    map((user) => {
      const permissions = user?.permissions
        .filter((p) => p.application.name === PERMISSIONS.applicationReseller)
        .map((p) => p.application.qualifiers);
      // first flatten [[]] =>  then filter unique values
      return flatten(permissions).filter(onlyUnique);
    }),
  );
  resellers$ = this.resellerQuery.selectAll();
  routeChanges$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
  params$ = this.routerQuery.selectParams();
  liveNotifications$ = this.notificationQuery.selectLive$;

  constructor(
    private authQuery: AuthQuery,
    private resellerQuery: ResellerQuery,
    private authenticationService: AuthenticationService,
    private resellerService: ResellerService,
    private notificationQuery: NotificationQuery,
    private notificationService: NotificationService,
    private router: Router,
    private routerQuery: RouterQuery,
  ) {}

  getReseller(id: string): void {
    if (id !== this.resellerQuery.getActiveId()) {
      this.notificationService.reset();
      this.resellerService.getReseller(id);
    }
  }

  getResellers(take?: number, skip?: number, ids?: string[]): void {
    this.resellerService.getResellers(skip, take, ids);
  }

  observeNotifications(projects: string[]): void {
    this.notificationService.subscribe(projects);
  }

  clearNotification(): void {
    this.notificationService.resetLive();
  }

  reset(): void {
    this.resellerService.reset();
  }

  resetActiveReseller(): void {
    this.resellerService.setActive(null);
  }

  logout(): void {
    this.authenticationService.logOut();
  }

  navigate(url: string[]): void {
    this.router.navigate(url);
  }
}
