import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DOMAIN } from '@summa/models';
import { FixtureState, FixtureStore } from './fixture.store';

@Injectable({
  providedIn: 'root',
})
export class FixtureQuery extends QueryEntity<FixtureState, DOMAIN.Fixture> {
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: FixtureStore) {
    super(store);
  }
}
