import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { DriverStore } from './driver.store';
import { GetDriversQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class DriverService {
  constructor(private apollo: Apollo, private driverStore: DriverStore) {}

  getDrivers(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetDriversQuery, variables: { projectKey } })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getDrivers as DOMAIN.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }
}
