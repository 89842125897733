<h1 class="mb-0" mat-dialog-title>{{ 'component:agreements-dialog.title' | i18nextCap }}</h1>
<div mat-dialog-content class="agreement-modal__content">
  <p>{{ 'component:agreements-dialog.description' | i18nextCap }}</p>
  <mat-horizontal-stepper [linear]="true" #stepper *ngIf="sandbox.unconfirmedDocuments$ | async as documents">
    <ng-template matStepperIcon="edit">
      <mat-icon svgIcon="check"></mat-icon>
    </ng-template>

    <mat-step *ngFor="let document of documents; index as index" [stepControl]="agreementFormGroups[index]" [label]="document.name">
      <div class="my-2 markdownViewer" markdown [data]="decodeDocumentContent(document.content)"></div>

      <form [formGroup]="agreementFormGroups[index]" fxLayout="row wrap" fxLayoutAlign="start center">
        <div fxFlex="auto" class="mb-2">
          <mat-checkbox formControlName="accept" required>{{ 'component:agreements-dialog.accept' | i18nextCap }} {{ document.name }}</mat-checkbox>
        </div>

        <button mat-button class="mb-2" matStepperPrevious *ngIf="index > 0">{{ 'common:buttons.back' | i18next }}</button>
        <button
          mat-raised-button
          class="ml-2 mb-2"
          color="primary"
          matStepperNext
          *ngIf="index !== documents.length - 1"
          [disabled]="!agreementFormGroups[index].valid"
        >
          {{ 'common:buttons.next' | i18next }}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="ml-2 mb-2"
          (click)="confirmAgreements$.next(null)"
          [disabled]="!agreementFormGroups[index].valid"
          *ngIf="index === documents.length - 1"
        >
          {{ 'common:buttons.confirm' | i18next }}
        </button>
      </form>
    </mat-step>
  </mat-horizontal-stepper>
</div>
