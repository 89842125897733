import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { CONSTANTS, DOMAIN } from '@summa/models';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FixtureStore } from './fixture.store';
import { getFixturesQuery } from './graphql';
import { upsertFixtureQuery, upsertFixturesQuery } from './graphql/fixture.gql-mutation';

@Injectable({ providedIn: 'root' })
export class FixtureService {
  destroy$ = new Subject();
  constructor(private apollo: Apollo, private fixtureStore: FixtureStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  reset(): void {
    this.fixtureStore.reset();
  }
  resetFixtureUIState(): void {
    this.fixtureStore.resetUIState();
  }

  storeFixture(fixture: DOMAIN.Fixture): void {
    this.fixtureStore.upsert(fixture.id, fixture);
  }

  setActive(id: ID | null): void {
    this.fixtureStore.setActive(id);
  }

  resetActive(): void {
    this.fixtureStore.resetUIState();
    this.fixtureStore.setActive(null);
    this.fixtureStore.setError(null);
  }

  fixtureToFixtureInput(fixture): DOMAIN.FixtureInput {
    return {
      ...fixture,
      devices: fixture.devices.map((device) => {
        return device.key;
      }),
    };
  }

  fixturesToFixturesInput(fixtures): DOMAIN.FixtureInput[] {
    return fixtures.map(this.fixtureToFixtureInput);
  }

  getFixtures(floorplanId: string): void {
    this.apollo
      .subscribe({ query: getFixturesQuery, variables: { floorplanId } })
      .pipe(map((f: any) => f.data.getFixtures as DOMAIN.Fixture[]))
      .subscribe((fixtures) => {
        this.fixtureStore.upsertMany(fixtures);
      });
  }

  upsertFixture(fixture: DOMAIN.Fixture): void {
    const fixtureInput = this.fixtureToFixtureInput(fixture);
    this.fixtureStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertFixtureQuery, variables: { fixtureInput } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.fixtureStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
          // set error:
          const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `key ${fixtureInput.id}` }));

          this.fixtureStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertFixture: DOMAIN.Fixture };
        this.fixtureStore.upsert(data.upsertFixture.id, data.upsertFixture);
        this.fixtureStore.setActive(data.upsertFixture.id);

        // set success:
        this.fixtureStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  upsertFixtures(fixtures: DOMAIN.Fixture[]): void {
    const fixturesInput = this.fixturesToFixturesInput(fixtures);
    this.fixtureStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertFixturesQuery, variables: { fixturesInput } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.fixtureStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
          // set error:
          const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `Fixtures: ${fixturesInput.length}` }));

          this.fixtureStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertFixtures: DOMAIN.Fixture[] };
        this.fixtureStore.upsertMany(data.upsertFixtures);

        // set success:
        this.fixtureStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }
}
