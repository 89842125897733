import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CONSTANTS } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';

import { ControlPanelScenarioSandbox } from './control-panel-scenario.sandbox';

@Component({
  selector: 'summa-control-panel-scenario',
  templateUrl: './control-panel-scenario.component.html',
  styleUrls: ['./control-panel-scenario.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ControlPanelScenarioSandbox],
})
export class ControlPanelScenarioComponent implements OnInit, OnDestroy {
  @Input() scenarios: DTO.ControlPanelScenario[] = [];

  controlPanelScenarioTypePulse = CONSTANTS.controlPanelScenarioTypePulse;

  scenarioSelected$ = new Subject<DTO.ControlPanelScenario>();
  destroy$ = new Subject();

  constructor(private sandbox: ControlPanelScenarioSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleSelectScenario();
  }

  handleSelectScenario(): void {
    this.scenarioSelected$.pipe(withLatestFrom(this.sandbox.routeParams$), takeUntil(this.destroy$)).subscribe(([scenario, params]) => {
      this.sandbox.executeScenarioCommand(
        params.controlPanelId,
        scenario.id,
        scenario.isActive ? CONSTANTS.stateOff : CONSTANTS.stateOn,
        params.projectKey,
      );
    });
  }
}
