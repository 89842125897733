import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

import { DxfService } from '@summa/shared/dxf';

@Component({
  selector: 'summa-upload-dxf',
  templateUrl: './upload-dxf.component.html',
  styleUrls: ['./upload-dxf.component.scss'],
})
export class UploadDxfComponent {
  @Input() fileTypes = ['dxf'];

  @Output() dxfString = new EventEmitter();
  @Output() dxfLayers = new EventEmitter();
  @Output() dxfFileName = new EventEmitter();

  selectedFiles$ = new Subject();
  fileName: string | any;

  constructor(private helper: DxfService) {}

  private onFileLoad(fileLoadedEvent: any): void {
    const dxfStringFromFileLoaded = fileLoadedEvent.target.result;
    this.helper.setContent(dxfStringFromFileLoaded);
    this.dxfString.emit(dxfStringFromFileLoaded);
    this.dxfLayers.emit(this.readLayers());
    this.dxfFileName.emit(this.fileName);
  }

  private readLayers(): string[] {
    const dxf = this.helper.parseDxfStringForViewer() as any;
    return Object.keys(dxf.tables.layer.layers);
  }

  public onFileSelect(input: any): void {
    const { files } = input;
    const encoding = 'utf-8';

    if (files && files.length) {
      this.selectedFiles$.next(input.files);
      const extension = input.files[0].name.split('.').pop().toLowerCase(); // file extension from input file

      // is extension in acceptable types
      if (this.fileTypes.indexOf(extension) > -1) {
        const fileToRead = files[0];
        const fileReader = new FileReader();
        fileReader.onload = this.onFileLoad.bind(this);
        fileReader.readAsText(fileToRead, encoding);
        this.fileName = fileToRead.name.replace('.dxf', '');
      } else {
        // tslint:disable-next-line: no-console
        // console.log('Invalid File Type', 'Failed');
      }
    }
  }
}
