import { gql } from 'apollo-angular';

export const getApplicationConfigQuery = gql`
  query getApplicationConfig {
    getApplicationConfig {
      portalApiVersion
      latestGatewayVersion
      minimumRequiredGatewayApiVersion
    }
  }
`;
