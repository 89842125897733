import { Injectable, OnDestroy } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { FirmwareStore } from './firmware.store';
import { GetFirmwareQuery, GetFirmwaresQuery, removeFirmwareQuery, upsertFirmwareQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class FirmwareService implements OnDestroy {
  destroy$ = new Subject();

  constructor(private apollo: Apollo, private firmwareStore: FirmwareStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  storeFirmware(firmware: DOMAIN.Firmware): void {
    this.firmwareStore.upsert(firmware.name, firmware);
  }

  reset(): void {
    this.firmwareStore.reset();
  }

  resetActive(): void {
    this.firmwareStore.resetUIState();
    this.firmwareStore.setActive(null);
    this.firmwareStore.setError(null);
  }

  setLoading(state: boolean): void {
    this.firmwareStore.setLoading(state);
  }

  setActive(name: string | null): void {
    this.firmwareStore.setActive(name);
  }

  getFirmware(name: string): void {
    this.resetActive();
    this.firmwareStore.setLoading(true);

    this.apollo
      .watchQuery({ query: GetFirmwareQuery, variables: { name }, fetchPolicy: 'no-cache' })
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ data }: any) => {
          const firmware = data.getFirmware;
          this.firmwareStore.upsert(firmware.name, firmware);
          this.setActive(firmware?.name);
          this.firmwareStore.setLoading(false);
        },
        // FIXME: fix and remove eslint disable
        // eslint-disable-next-line rxjs/no-implicit-any-catch, @typescript-eslint/no-explicit-any
        (error: any) => {
          const er = error.message.replace('GraphQL error: ', '');
          this.notifier.notify('error', this.i18next.transform(`assembly:fusion.errors.${er}`));
          this.firmwareStore.setError(er);
          this.firmwareStore.setLoading(false);
        },
      );
  }

  getFirmwares(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetFirmwaresQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getFirmwares as DOMAIN.Firmware[]))
      .subscribe((firmwares) => {
        this.firmwareStore.upsertMany(firmwares);
      });
  }

  resetFirmwareUIState(): void {
    this.firmwareStore.resetUIState();
  }

  upsertFirmware(firmware: DOMAIN.FirmwareInput, message = 'message:successful-added'): void {
    this.resetFirmwareUIState();
    this.firmwareStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo
        .mutate({
          mutation: upsertFirmwareQuery,
          variables: { firmware },
        })
        .subscribe((results) => {
          if (!results || results.errors || !results.data) {
            this.firmwareStore.updateUpsert({ errors: results?.errors, isLoading: false });
            return;
          }

          const data = results.data as { upsertFirmware: DOMAIN.Firmware };
          this.firmwareStore.upsert(data.upsertFirmware.name, data.upsertFirmware);
          this.firmwareStore.updateUpsert({ isSuccessful: true, isLoading: false });
          this.notifier.notify('success', this.i18next.transform(message));
        });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  remove(id: string): void {
    this.firmwareStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeFirmwareQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.firmwareStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `firmware ${id}` }));

        this.firmwareStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.firmwareStore.remove(id);

      // set success;
      this.firmwareStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
