import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { FixtureStateState, FixtureStateStore } from './fixture-state.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'timestamp',
  sortByOrder: Order.DESC,
})
export class FixtureStateQuery extends QueryEntity<FixtureStateState, DOMAIN.FixtureState> {
  constructor(protected store: FixtureStateStore) {
    super(store);
  }
}
