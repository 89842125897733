import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimingDiagramComponent } from './timing-diagram/timing-diagram.component';

@NgModule({
  imports: [CommonModule],
  exports: [TimingDiagramComponent],
  declarations: [TimingDiagramComponent],
})
export class SharedUiDiagramsModule {}
