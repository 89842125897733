import { gql } from 'apollo-angular';

export const executeCurrentCheckQuery = gql`
  mutation executeCurrentCheckCommand($command: CurrentCheckInput!) {
    executeCurrentCheckCommand(command: $command) {
      driverInfo {
        driverKey
        projectKey
        driverVolt
        driverCurrent
      }
      currentChannels {
        address
        cu
        off
        volt
      }
      suggestedChannels {
        address
        cu
        off
      }
      suggestedNChannelSettings {
        fixtureState
        minCurrent
        maxCurrent
      }
    }
  }
`;

export const executeCurrentChangeQuery = gql`
  mutation executeCurrentChangeCommand($command: CurrentChangeInput!) {
    executeCurrentChangeCommand(command: $command)
  }
`;
