<div *ngIf="sandbox.currentUser$ | async as currentUser" class="p-4" fxLayout="row" fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="center start">
  <div fxLayout="column" fxLayoutGap="16px" class="w-100">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card p-4 w-100">
        <div class="header">
          {{'user-management:title' | i18nextCap}}
          <div class="header-tools">
            <div class="filter">
              <mat-form-field floatLabel="never">
                <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
              </mat-form-field>
            </div>
            <div class="create" title="{{'reseller:users.overview.add' | i18nextCap}}" (click)="upsertPanel$.next(null)">
              <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
            </div>
          </div>
        </div>

        <mat-table [dataSource]="users" class="w-100" matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user-management:overview.table-headers.username' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.username }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'user-management:overview.table-headers.id' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.id }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let row">
              <ng-container *ngIf="currentUser.id !== row.id">
                <span
                  class="edit"
                  [title]="'user-management:overview.edit' | i18nextCap"
                  (click)="upsertPanel$.next(row) ; $event.stopPropagation() "
                >
                  <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
                </span>
                <div
                  class="remove ml-2"
                  [title]="'user-management:overview.remove' | i18nextCap"
                  (click)="remove$.next(row); $event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="'file-document-box-remove-outline'"></mat-icon>
                </div>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
          <div class="filter-notification" *matNoDataRow>{{'user-management:overview.filter-notification' | i18nextCap}} "{{input.value}}"</div>
        </mat-table>
      </div>
    </div>
  </div>
</div>
