import { gql } from 'apollo-angular';

export const GetDeviceQuery = gql`
  query getDevice($device: SearchDeviceInput!) {
    getDevice(device: $device) {
      id
      key
      address
      type
      category
      settings
      locationKey
      driver {
        id
        key
        type
        projectKey
      }
    }
  }
`;
