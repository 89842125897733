import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map } from 'rxjs/operators';

import { CONSTANTS } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { Counter } from '@summa/shared/dashboard/ui';
import { I18NextCapPipe } from 'angular-i18next';
import { AutomationQuery, AutomationService, CommandService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectDetailsAutomationWidgetSandbox {
  automations$ = this.automationQuery.selectAll();
  counters$ = this.automationQuery.selectAll().pipe(
    filter(isNotNullOrUndefined),
    map((automations) => {
      const states = automations.map((a) => a.state === CONSTANTS.stateOn);
      return { enabled: states.filter((s) => !!s), disabled: states.filter((s) => !s) };
    }),
  );

  automationCounters$ = this.counters$.pipe(
    map((automations): Counter[] => {
      return [
        {
          title: this.i18next.transform('project:automation-widget.enabled'),
          class: 'success',
          count: automations.enabled.length,
        },
        {
          title: this.i18next.transform('project:automation-widget.disabled'),
          class: 'warning',
          count: automations.disabled.length,
        },
      ];
    }),
  );

  params$ = this.router.selectParams();
  project$ = this.projectQuery.selectActive();

  constructor(
    private automationService: AutomationService,
    private commandService: CommandService,
    private automationQuery: AutomationQuery,
    private projectQuery: ProjectQuery,
    private router: RouterQuery,
    private i18next: I18NextCapPipe,
  ) {}

  getAutomations(projectKey: string): void {
    this.automationService.getAutomations(projectKey);
  }

  reset(): void {
    this.automationService.reset();
  }

  executeSync(projectKey: string, subject: CONSTANTS.SyncType, mode: 'partial' | 'full'): void {
    this.commandService.executeSyncCommand({ projectKey, subject, mode });
  }
}
