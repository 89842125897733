import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

export type FixtureStateState = EntityState<DOMAIN.FixtureState, string>;

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'fixture-states', idKey: 'topic', resettable: true })
export class FixtureStateStore extends EntityStore<FixtureStateState, DOMAIN.FixtureState> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
