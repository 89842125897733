import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { SidePanelService } from '@summa/shared/ui/dialogs';
import { FloorplanService, ProjectQuery, StorageQuery, StorageService } from '../../../../data-access';

@Injectable()
export class ProjectFloorplansAddSandbox {
  project$ = this.projectQuery.selectActive();
  params$ = this.routerQuery.selectParams();
  data$ = this.sidePanelService.getData();
  upsertState$ = this.storageQuery.selectUpsertState$;

  constructor(
    private sidePanelService: SidePanelService,
    private projectQuery: ProjectQuery,
    private routerQuery: RouterQuery,
    private storageService: StorageService,
    private storageQuery: StorageQuery,
    private floorplanService: FloorplanService,
  ) {}

  uploadFile(bucket: string, file: File, projectKey: string): void {
    this.storageService.uploadFile(bucket, file, projectKey);
  }

  InsertFloorplanAndFixture(floorplan: any, fixtures: any[]): void {
    this.floorplanService.upsertFloorplan(floorplan, fixtures);
  }

  removeFile(bucket: string, fileName: string): void {
    this.storageService.deleteFile(bucket, fileName);
  }

  reset(): void {
    this.storageService.resetActive();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
