import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { CardQuery, CardService, SceneQuery, SceneService } from '../../../../data-access';

@Injectable()
export class ProjectCardDetailsSandbox {
  params$ = this.router.params;
  card$ = this.cardQuery.selectActive();
  devices$ = this.cardQuery.selectActive().pipe(map((card) => card.devices));
  scenes$ = this.cardQuery
    .selectActive()
    .pipe(map((card) => card.scenes.map((scene: DOMAIN.Scene) => scene).sort((a, b) => a.sortOrder - b.sortOrder)));
  updateScene$ = this.sceneQuery.selectUpsertState$;

  constructor(
    private router: ActivatedRoute,
    private cardService: CardService,
    private sceneQuery: SceneQuery,
    private sceneService: SceneService,
    private cardQuery: CardQuery,
  ) {}

  getCard(cardId: string): void {
    this.cardService.getCardPreview(cardId);
  }

  getScene(sceneId: string): void {
    this.sceneService.getScene(sceneId);
  }

  reset(): void {
    this.cardService.reset();
  }
}
