import { Injectable } from '@angular/core';

import { DOMAIN, PERMISSIONS } from '@summa/models';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { AuthenticationService, AuthQuery, ProjectQuery, ResellerQuery, UserQuery, UserService } from '../../../data-access';

@Injectable()
export class UserOverviewSandbox {
  params$ = this.router.selectParams();
  loading$ = this.userQuery.selectLoading();
  project$ = this.projectQuery.selectActive();
  reseller$ = this.resellerQuery.selectActive();
  currentUser$ = this.authQuery.selectActive();
  application$ = this.router.selectData('application');
  removeState$ = this.userQuery.selectRemoveState$;
  upsertState$ = this.userQuery.selectUpsertState$;

  // The pipe hides specific project users in the reseller user-management
  // TODO: Fix permission-filters in API instead of front-end
  users$ = this.userQuery.selectAll().pipe(
    withLatestFrom(this.application$),
    map(([users, application]) => {
      if (application === PERMISSIONS.applicationReseller) {
        return users.filter((user) =>
          user.permissions.some(
            (p) =>
              p.application.name === PERMISSIONS.applicationReseller &&
              p.domain.name === PERMISSIONS.applicationProject &&
              p.domain.qualifiers.includes(PERMISSIONS.wildcardQualifier),
          ),
        );
      }

      return users;
    }),
  );

  constructor(
    private router: RouterQuery,
    private userQuery: UserQuery,
    private authQuery: AuthQuery,
    private userService: UserService,
    private projectQuery: ProjectQuery,
    private resellerQuery: ResellerQuery,
    private authenticationService: AuthenticationService,
  ) {}

  getUsers(): void {
    this.userService.getUsers();
  }

  updateUser(userInput: DOMAIN.UserInput): void {
    this.userService.updateUser(userInput);
  }

  removeUser(id: string): void {
    this.userService.removeUser(id);
  }

  getUsersByPermissions(permissions: PERMISSIONS.Permission[]): void {
    this.userService.getUsersByPermissions(permissions);
  }

  setActiveUser(user: DOMAIN.User): void {
    this.userService.setActive(user.id);
  }

  removeStoreUser(user: DOMAIN.User): void {
    this.userService.removeUserFromStore(user.id);
  }

  resetUpsertState(): void {
    this.userService.resetUpsertState();
  }

  resetRemoveState(): void {
    this.userService.resetRemoveState();
  }

  reset(): void {
    this.userService.reset();
  }

  logout(): void {
    this.authenticationService.logOut();
  }
}
