<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.controlPanel$ | async as controlPanel">
  <ng-container header>
    <h1><span class="highlight pr-0">{{'common:dictionary.control-panel' | i18nextCap}}: </span>{{ controlPanel.name }}</h1>
  </ng-container>

  <ng-container info>
    <div class="controlPanel-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.name' | i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ controlPanel.name }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.description' | i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ controlPanel.description }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.project' | i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ controlPanel.projectKey }}</div>
          </div>
          <div class="item" fxLayout="row wrap" *ngIf="controlPanel.defaultScene">
            <div class="title" fxFlex="20">{{'project:control-panels.default-scene' | i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ controlPanel.defaultScene.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <div class="pt-2">
      <h3>{{'common:dictionary.control_plural' | i18nextCap}}</h3>
      <small *ngIf="controlPanel.controls.length === 0">{{ 'project:control-panels.preview.no-controls' | i18nextCap }}</small>
      <div class="mb-2" *ngFor="let control of controlPanel.controls" fxLayout="column" fxLayoutGap="8px">
        <!-- control panel preview - id -->
        <!-- <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'common:dictionary.id' | i18nextCap}}</div>
          <div class="content" fxFlex="80">{{ control.id }}</div>
        </div> -->
        <!-- control panel preview - name -->
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'common:dictionary.name' | i18nextCap}}</div>
          <div class="content" fxFlex="80">{{ control.name }}</div>
        </div>
        <!-- control panel preview - sort -->
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'common:dictionary.sort' | i18nextCap}}</div>
          <div class="content" fxFlex="80">{{ control.sort }}</div>
        </div>
        <!-- control panel preview - icon -->
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20" fxLayoutAlign="start center">{{'common:dictionary.icon' | i18nextCap}}</div>
          <div class="content" fxFlex="80">
            <mat-icon [svgIcon]="control.icon"></mat-icon>
          </div>
        </div>
        <!-- control panel preview - scenarios -->
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'project:control-panels.control-entities' |i18nextCap}}</div>
          <div class="content" fxFlex="80">
            <div *ngFor="let entity of control.entities" class="mb-1" fxLayout="row" fxLayoutAlign="start center">
              <p class="m-0 p-0">{{ entity.group?.title ?? entity.device?.key }} - {{ entity.scene.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</summa-side-panel-content>
