import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { AuthQuery, NotificationQuery, NotificationService, ResellerQuery, ResellerService } from '../../../data-access';
import { filterResellerProjectsBasedOnPermissions } from '../../../permission-access/helpers';

@Injectable()
export class NotificationOverviewSandbox {
  notifications$ = this.notificationQuery.selectAll();
  params$ = this.router.selectParams();
  currentReseller$ = this.resellerQuery.selectActive();

  userProjects$: Observable<DTO.Project[]> = this.currentReseller$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.authQuery.selectActive()),
    map(([reseller, user]) => filterResellerProjectsBasedOnPermissions(reseller, user.permissions)),
  );

  constructor(
    private router: RouterQuery,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private notificationService: NotificationService,
    private notificationQuery: NotificationQuery,
    private authQuery: AuthQuery,
  ) {}

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;
    this.resellerService.getReseller(id);
  }

  getNotifications(projects: string[], take: number): void {
    this.notificationService.getNotifications(projects, null, take);
    this.notificationService.subscribe(projects);
  }

  observeNotifications(projects: string[]): void {
    this.notificationService.subscribe(projects);
  }

  reset(): void {
    // Reset Notification overview
    this.notificationService.resetLive();
    this.notificationService.reset();
  }
}
