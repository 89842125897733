/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
import * as THREE from 'three';
import { DxfViewer } from './DxfViewer/DxfViewer';
import ClusterManagement from './ClusterManagement';
import { devicesBoundingBox } from './Helper/DrawHelper';
import onProgress from './Helper/ProgressBarHelper';
import {
  getMouseActionPos,
  getMeshName,
  convertPositionInScene,
  convertScenePositionToDxf,
  updateMetaAndPos,
  convertLocation,
} from './Helper/TranformHelper';
import DeviceMesh from './DxfViewer/DeviceMesh';

const _ = require('lodash');

const wworld = 100000;
const planeshow = false;

const plane = new THREE.Mesh(new THREE.PlaneGeometry(wworld, wworld), new THREE.MeshBasicMaterial({ color: '#e6ecf7', visible: planeshow }));
plane.position.z = -1;
plane.name = 'plane';

const gridHelper = new THREE.GridHelper(wworld, 20);
gridHelper.rotateX(-Math.PI / 2);
gridHelper.position.z = -7500;

const createCanvasObjects = () => ({
  objects: [],
  raycaster: new THREE.Raycaster(),
  plane,
  grid: gridHelper,

  selectedDevice: null,
  preSelectDevice: null,
  movingMess: null,
  movingOffset: { x: 0, y: 0 },

  selectedObjects: [],
});

let canvasObjects = null;

let cameraDistance = 0;
let currentScale = 0;
let scaleCluster = 0;

export default class Viewer {
  constructor(domContainer, devices = [], backgroundViewer = '#fff') {
    canvasObjects = createCanvasObjects();
    this.dxfViewer = new DxfViewer(domContainer, {
      clearColor: new THREE.Color(backgroundViewer),
      autoResize: true,
      colorCorrection: true,
    });

    this.moveFixture = false;
    this.devices = devices;
    this.clusters = [];
    this.clusterManagement = new ClusterManagement(null);
    this.cluster_id = 0;
    this.onClick = null;
  }

  setEnableMoveFixture(status) {
    this.moveFixture = status;
    // disable move camera when move object
    this.dxfViewer.controls.enabled = !status;
  }

  updateDevicesData(devicesData, needRefresh) {
    this.devices = devicesData;
    if (needRefresh) {
      this.centerViewToDevices();
    }

    this.drawFromArray(this.dxfViewer.origin, this.devices);
    const { children } = this.dxfViewer.GetScene();
    const fixtureMeshs = children.filter((mesh) => mesh.name.includes('box-fixture-'));
    this.updateObjectsScale(true);
    this.clusterManagement.setFixtures(fixtureMeshs);
    this.refresh();
    this.updateObjectsScale();
    this.refresh();
  }

  clearViewer() {
    this.dxfViewer.Clear();
  }

  destroyViewer() {
    this.dxfViewer.Destroy();
  }

  async Load(url) {
    try {
      await this.dxfViewer.Load({
        url,
        fonts: [],
        progressCbk: (phase, size, totalSize) => {
          onProgress(phase, size, totalSize);
        },
        // workerFactory: DxfViewerWorker,
      });
    } catch (error) {
      console.warn(error);
    } finally {
      this.clusterManagement.scene = this.dxfViewer.GetScene();

      this.centerViewToDevices();
      this.updateDevicesData(this.devices, false);

      cameraDistance = this.dxfViewer.GetCamera().top;
      this.dxfViewer.GetScene().add(canvasObjects.grid);
      this.dxfViewer.GetScene().add(canvasObjects.plane);

      this.dxfViewer.GetCanvas().addEventListener('pointerdown', (e) => {
        this.pointerDown(e);
      });

      this.dxfViewer.GetCanvas().addEventListener('pointermove', (e) => {
        this.pointerMove(e);
      });

      this.dxfViewer.GetCanvas().addEventListener('pointerup', (e) => {
        this.pointerUp(e);
      });

      this.dxfViewer.GetCanvas().addEventListener('dblclick', () => {
        // this.dblClick(e);
      });

      const handleDrawClusters = _.debounce(() => {
        this.clusterManagement.refresh();
        this.dxfViewer.Render();
      }, 300);

      this.dxfViewer.Subscribe('cameraZoom', () => {
        this.updateObjectsScale();
        handleDrawClusters();
      });
      this.dxfViewer.Render();
    }
  }

  updateObjectsScale(force = false) {
    const api = canvasObjects;
    const camera = this.dxfViewer.GetCamera();

    if (camera) {
      // Scale fixtures
      const aa = (cameraDistance * 4) / 15000;
      const newScale = aa / camera.zoom;
      if (newScale != currentScale || force) {
        currentScale = newScale;
        api.objects.forEach((o) => {
          if (o.name.includes('box-fixture-')) {
            o.setScale(newScale);
          }
        });
      }

      // Scale clusters
      const bb = (cameraDistance * 4) / 15000;
      const newScale2 = bb / camera.zoom;
      if (newScale2 != scaleCluster || force) {
        scaleCluster = newScale2;
        this.clusterManagement.setScale(scaleCluster);
      }
    }
  }

  findObjectByName(name) {
    const canvas = this.dxfViewer;
    const scene = canvas.GetScene();
    const { children } = scene;
    const findData = children.filter((e) => e.name == name);
    return findData;
  }

  refresh() {
    const canvas = this.dxfViewer;

    if (!canvas) {
      return;
    }
    if (this.onClick) {
      this.onClick('single', null);
    }
    canvas.Render();
  }

  GetViewer() {
    return this.dxfViewer;
  }

  setSelectFixture(fixture) {
    const canvas = this.dxfViewer;
    const { children } = canvas.GetScene();
    children
      .filter((mesh) => mesh.name.includes('box-fixture-'))
      .forEach((mesh) => {
        if (mesh.exData.id === fixture.id) {
          mesh.select = true;
          this.onClick('single', fixture);
        } else {
          mesh.select = false;
        }
      });
    canvas.Render();
  }

  findObjectClicked() {
    const canvas = this.dxfViewer;
    const cam = canvas.GetCamera();
    const api = canvasObjects;
    // let update = false;
    api.raycaster.setFromCamera(api.v2, cam);

    const intersects = api.raycaster
      .intersectObjects(api.objects)
      .filter((obj) => obj.object.material.visible && obj.object.name.includes('box-fixture-'));

    return intersects;
  }

  findObjectAtPos(e) {
    const canvas = this.dxfViewer;
    const api = canvasObjects;
    if (!canvas || !api) {
      return [];
    }
    const x = (e.offsetX / e.target.clientWidth) * 2 - 1;
    const y = -(e.offsetY / e.target.clientHeight) * 2 + 1;
    const v2 = new THREE.Vector2(x, y);
    const cam = canvas.GetCamera();
    api.raycaster.setFromCamera(v2, cam);

    const intersects = api.raycaster.intersectObjects(api.objects).filter((obj) => obj.object?.material?.visible || false);

    return intersects;
  }

  drawFromArray(origin, items, type = '') {
    if (!origin) return;

    const api = canvasObjects;
    const names = api.objects.map((e) => e.name);
    const itemObjs = items.reduce((a, v) => ({ ...a, [getMeshName(v)]: v }), {});
    const canvas = this.dxfViewer;
    const scene = canvas.GetScene();
    const { children } = scene;
    const temp = {};

    children.forEach((e) => {
      if (itemObjs[e.name]) {
        e.exData = itemObjs[e.name];
      }
      temp[e.name] = e;
    });

    const results = items.filter((e) => !names.includes(getMeshName(e)));

    const updateMeta = items.filter((e) => names.includes(getMeshName(e)));
    updateMeta?.forEach((d) => {
      updateMetaAndPos(origin, d, temp, type);
    });

    results?.forEach(async (d) => {
      const v1 = convertPositionInScene({ x: d.location.x, y: d.location.y, z: 0 }, origin);
      if (d.color) {
        this.drawAt(v1, d);
      }
    });
    canvas.Render();
  }

  drawAt(vector, data) {
    const canvas = this.dxfViewer;
    const scene = canvas.GetScene();
    const api = canvasObjects;

    const block = data.iconData ? this.dxfViewer.blocks.get(data.iconData.name) : null;
    const mesh = new DeviceMesh(data, data.iconData, vector, block);
    mesh.name = getMeshName(data);
    scene.add(mesh);
    api.objects.push(mesh);
  }

  handleClickAt() {
    const api = canvasObjects;
    const intersects = this.findObjectClicked();
    if (intersects.length > 0) {
      const intersect = intersects[0];
      const mesh = intersect.object;
      if (intersect.object !== api.plane) {
        if (mesh.name.includes('box')) {
          const { exData } = mesh;
          if (this.onClick) {
            const result = {
              id: exData.id,
              floorplanKey: exData.floorplanKey,
              devices: exData.devices,
              location: exData.location,
              mainGroup: exData.mainGroup,
              type: exData.type,
              iconData: exData.iconData,
            };
            if (api.selectedObjects.length) {
              this.onClick('single', result);
            } else {
              this.onClick('single', null);
            }
          }
        }
      }
    } else {
      this.onClick('single', null);
    }
  }

  handleEmitMoveObject(mesh) {
    if (this.onClick) {
      let data = null;
      if (mesh) {
        data = mesh.exData;
        const { position } = mesh;
        const dxfPos = convertScenePositionToDxf(position, this.dxfViewer.origin);
        const location = {
          x: dxfPos.x,
          y: dxfPos.y,
          height: data.location.height,
        };
        data = { ...data, location };
      }
      mesh.exData = data;
      this.onClick('single', data);
    }
  }

  pointerDown(e) {
    const canvas = this.dxfViewer;
    const { children } = canvas.GetScene();
    if (!canvas) return;

    const api = canvasObjects;
    const cam = canvas.GetCamera();

    api.PointStart = {
      x: e.offsetX,
      y: e.offsetY,
    };
    api.zoom = cam.zoom;

    const x = (e.offsetX / e.target.clientWidth) * 2 - 1;
    const y = -(e.offsetY / e.target.clientHeight) * 2 + 1;

    api.v2 = new THREE.Vector2(x, y);

    children
      .filter((mesh) => mesh.name.includes('box-fixture-'))
      .forEach((mesh) => {
        mesh.select = false;
      });
    // Handle adding rect and moving
    if (e.button == 0) {
      const baseIntersects = this.findObjectClicked();
      const objects = baseIntersects.filter((obj) => {
        const mesh = obj.object;
        return mesh.name.includes('box-fixture-');
      });

      if (objects.length) {
        // Click to select 1 device
        const mesh = objects[0].object;
        api.selectedObjects = [mesh.name];
        api.preSelectDevice = mesh;
        mesh.select = true;
      } else {
        // Click none to deselect all objects
        api.selectedObjects = [];
        api.preSelectDevice = null;
      }
      // prepare object for moving function
      // eslint-disable-next-line prefer-destructuring
      api.movingMess = objects[0];
    }
  }

  pointerMove(e) {
    const api = canvasObjects;
    const canvas = this.dxfViewer;
    const currentPos = getMouseActionPos(e, canvas, api);

    if (this.moveFixture) {
      if (api.selectedDevice == api.preSelectDevice)
        if (api.movingMess && currentPos) {
          // prevent unselect device can move
          const newPos = new THREE.Vector3(currentPos.x - api.movingOffset.x, currentPos.y - api.movingOffset.y, 0);
          api.movingMess.object.position.copy(newPos);
        }
    }
    canvas.Render();
  }

  pointerUp(e) {
    const api = canvasObjects;
    if (api.preSelectDevice) {
      api.selectedDevice = api.preSelectDevice; // prevent unselect device can move
    }
    if (this.moveFixture) {
      if (api.movingMess) {
        const mesh = api.movingMess.object;
        this.handleEmitMoveObject(mesh);
      }
    } else if (e.button == 0) {
      this.handleClickAt();
    }
    api.movingMess = null;
    this.dxfViewer.Render();
  }

  moveCamera(coordinate, zoom) {
    const canvas = this.dxfViewer;
    if (!canvas || !canvas.origin) {
      return;
    }

    const location = convertLocation(canvas, coordinate.x, coordinate.y, zoom || canvas.GetCamera().zoom);

    canvas.GetControls().SetState(location);
    canvas.GetControls().reset();
  }

  zoomToGroup(group) {
    const canvas = this.dxfViewer;
    if (!canvas || !canvas.origin) {
      return;
    }

    const api = canvasObjects;
    api.v2 = new THREE.Vector2(group.x, group.y);

    const location = convertLocation(canvas, group.x, group.y, 4);

    canvas.GetControls().SetState(location);
    canvas.GetControls().reset();
  }

  centerViewToDevices() {
    if (!this.dxfViewer.origin) return;

    const canvas = this.dxfViewer;
    if (this.devices.length === 0) {
      return;
    }

    if (!canvas) {
      return;
    }

    const { minX, maxX, minY, maxY } = devicesBoundingBox(this.devices);

    this.dxfViewer.FitView(
      minX - this.dxfViewer.origin.x,
      maxX - this.dxfViewer.origin.x,
      minY - this.dxfViewer.origin.y,
      maxY - this.dxfViewer.origin.y,
      0.25,
    );
    const camera = this.dxfViewer.GetCamera();
    this.dxfViewer.GetControls().target = new THREE.Vector3(camera.position.x, camera.position.y, 0);
    this.dxfViewer.GetControls().update();
    cameraDistance = this.dxfViewer.GetCamera().top;
  }

  destroy() {
    this.dxfViewer.Destroy();
  }
}
