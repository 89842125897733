import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { ApplicationStore } from './application.store';
import { getApplicationConfigQuery } from './graphql';

@Injectable({ providedIn: 'root' })
export class ApplicationService {
  constructor(private apollo: Apollo, private store: ApplicationStore) {}

  getApplicationConfig(): void {
    this.apollo
      .subscribe({ query: getApplicationConfigQuery })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getApplicationConfig as DTO.ApplicationConfig))
      .subscribe((app) => {
        this.store.set([app]);
        this.store.setActive(app.portalApiVersion);
      });
  }
}
