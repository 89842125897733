import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { PresetState, PresetStore } from './preset.store';

@Injectable({
  providedIn: 'root',
})
export class PresetQuery extends QueryEntity<PresetState, DOMAIN.Preset> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);

  constructor(protected store: PresetStore) {
    super(store);
  }
}
