/* eslint-disable eqeqeq */
import * as THREE from 'three';
import { setLocation } from './DrawHelper';

export function getMouseActionPos(e, canvas, api) {
  if (!canvas || !api) {
    return null;
  }
  const x = (e.offsetX / e.target.clientWidth) * 2 - 1;
  const y = -(e.offsetY / e.target.clientHeight) * 2 + 1;
  const v2 = new THREE.Vector2(x, y);
  const cam = canvas.GetCamera();
  api.raycaster.setFromCamera(v2, cam);
  const intersects = api.raycaster.intersectObjects([api.plane]);
  if (intersects.length == 0) {
    return null;
  }
  const v3 = intersects[0].point;
  return { ...v3, z: 0 };
}

export function getMeshName(object) {
  return `box-fixture-${object.id}`;
}

export function convertPositionInScene(pos, originScene) {
  const x = parseFloat(pos.x);
  const y = parseFloat(pos.y);
  return new THREE.Vector3(x - originScene.x, y - originScene.y, pos.z);
}

export function convertScenePositionToDxf(pos, originScene) {
  const x = parseFloat(pos.x);
  const y = parseFloat(pos.y);
  return new THREE.Vector3(x + originScene.x, y + originScene.y, pos.z);
}

export function updateMetaAndPos(origin, data, meshes, type) {
  const v1 = convertPositionInScene({ x: data.location.x, y: data.location.y, z: 0 }, origin);
  const name = getMeshName(data);
  if (meshes[name]) {
    meshes[name].position.copy(v1);
    meshes[name].material.color.setHex(data.color || 0xcccccc);
  }
}

export function convertLocation(canvas, x, y, zoom) {
  const location = setLocation(x, y, zoom);

  location.target = convertPositionInScene(location.target, canvas.origin);

  location.position = convertPositionInScene(location.position, canvas.origin);

  return location;
}
