import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { QueryEntity } from '@datorama/akita';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { CardService, ControlPanelQuery, ControlPanelService, DriverService, ProjectService, SceneService } from '../../../data-access';
import { AUTHENTICATION_QUERY, AuthState } from '../../../dashboard.module';

@Injectable()
export class ControlPanelDetailSandbox {
  currentUser$: Observable<DOMAIN.User>;
  isLoading$: Observable<boolean>;
  routeParams$: Observable<Params>;
  controlPanel$: Observable<DTO.ControlPanel>;
  allControl$: Observable<DTO.ControlPanelControl>;

  constructor(
    private cardService: CardService,
    private sceneService: SceneService,
    private driverService: DriverService,
    private projectService: ProjectService,
    private controlPanelQuery: ControlPanelQuery,
    private controlPanelService: ControlPanelService,
    private route: ActivatedRoute,
    @Inject(AUTHENTICATION_QUERY) private authQuery: QueryEntity<AuthState>,
  ) {
    this.isLoading$ = this.controlPanelQuery.selectLoading();
    this.routeParams$ = this.route.params;
    this.currentUser$ = this.authQuery.selectActive().pipe(filter(isNotNullOrUndefined));
    this.controlPanel$ = this.controlPanelQuery.selectActive().pipe(
      filter(isNotNullOrUndefined),
      map((controlPanel) => ({
        ...controlPanel,
        controls:
          controlPanel.controls?.length > 0
            ? controlPanel.controls
                .slice()
                .sort((a, b) => a.sort - b.sort)
                // 20230920: Sander request to disable all card
                .filter((control) => control.id !== 'all')
            : [],
        scenarios:
          controlPanel.scenarios?.length > 0
            ? controlPanel.scenarios
                .slice()
                .filter((s) => !s.hidden)
                .sort((a, b) => a.sortOrder - b.sortOrder)
            : [],
      })),
    );

    this.allControl$ = this.controlPanel$.pipe(
      filter(isNotNullOrUndefined),
      map(() => ({
        id: 'all',
        name: 'All',
        icon: 'lightbulb-group-outline',
        sort: 0,
        isActive: false,
        entities: [],
      })),
    );
  }

  getProject(projectKey: string): void {
    this.projectService.getProject(projectKey);
  }

  getScenes(projectKey: string): void {
    this.sceneService.getScenes(projectKey);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDrivers(projectKey);
  }

  getGroups(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  reset(): void {
    this.controlPanelService.reset();
  }

  getControlPanel(id: string): void {
    this.controlPanelService.findControlPanel(id);
  }

  observeControlPanel(id: string): void {
    this.controlPanelService.subscribe(id);
  }

  removeControlPanelControl(id: string, controlPanelId: string): void {
    this.controlPanelService.removeControlPanelControl(id, controlPanelId);
  }

  executeControlCommand(controlPanelId: string, controlPanelControlId: string, state: CONSTANTS.StateType, projectKey?: string): void {
    const command: DTO.ControlPanelCommandInput = {
      controlPanelId,
      controlPanelControlId,
      state,
      ...(projectKey && { projectKey }),
    };
    this.controlPanelService.executeControlPanelCommand(command);
  }
}
