import { DOMAIN } from '@summa/models';

export const modelToCardInput = (model: DOMAIN.Card): DOMAIN.CardInput => ({
  ...(model.id && { id: model.id }),
  projectKey: model.projectKey,
  title: model.title,
  sortOrder: model.sortOrder,
  deviceIds: model.devices.map((device) => device.id),
  selectedSceneId: model.selectedSceneId ?? null,
  sceneIds: model.scenes.map((scene) => scene.id),
  hidden: model.hidden,
});
