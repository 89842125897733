<ng-container *ngIf="(scenarios$ | async) as scenarios">
  <section *ngIf="scenarios.length > 0, else noScenario" fxLayout="row wrap" fxLayoutGap="1rem grid" class="mx-4">
    <ng-container *ngFor="let scenario of scenarios">
      <div
        *ngIf="!scenario.hidden"
        [title]="scenario.description"
        (click)="scenarioSelected$.next(scenario.id)"
        fxFlex.xs="50"
        fxFlex.sm="16.5"
        fxFlex.md="12.5"
        fxFlex.gt-md="7.5"
      >
        <section class="scenario-item clickable w-100 h-100" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="scenario-icon mb-1" [svgIcon]="scenario?.icon || 'image'"></mat-icon>
          <div class="title">{{ scenario.name }}</div>
        </section>
      </div>
    </ng-container>
  </section>
</ng-container>

<ng-template #noScenario>
  <section class="mx-4 no-scenario">{{ 'customer:scenarios.no-scenarios-available' | i18nextCap }}</section>
</ng-template>
