import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PERMISSIONS, PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ResellerAddPage } from '../reseller-add/reseller-add.page';
import { ResellerSandbox } from '../reseller.sandbox';

@Component({
  selector: 'summa-reseller-overview',
  templateUrl: './reseller-overview.page.html',
  styleUrls: ['./reseller-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResellerOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  resellers = new MatTableDataSource<PortalAPI.Reseller>();

  columnsToDisplay = ['id', 'name', 'contactName', 'contactEmail', 'contactPhone'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public sandbox: ResellerSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleCurrentPermissions();

    this.sandbox.resellers$.pipe(takeUntil(this.destroy$)).subscribe((resellers: PortalAPI.Reseller[]) => {
      this.resellers.data = resellers;
      this.resellers.sort = this.sort;
      this.resellers.paginator = this.paginator;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.resellers.filter = filterValue.trim().toLowerCase();
  }

  /**
   * When user only has one reseller, redirect to the specific reseller
   */
  handleCurrentPermissions(): void {
    this.sandbox.currentResellers$.pipe(takeUntil(this.destroy$)).subscribe((resellers) => {
      if (!resellers) return;

      if (resellers.length === 1 && resellers[0] !== PERMISSIONS.wildcardQualifier) {
        // eslint-disable-next-line consistent-return
        return this.router.navigate(['/reseller', resellers[0]]);
      }

      const hasWildcard = resellers.some((x) => x === PERMISSIONS.wildcardQualifier);
      this.sandbox.getResellers(50, 0, hasWildcard ? null : resellers);
    });
  }

  openCreateReseller(): void {
    this.sandbox.resetActiveReseller();
    this.sidePanelService.open(ResellerAddPage);
  }
}
