<section fxLayout="column" *ngIf="sandbox.currentUser$ | async as user">
  <section class="header-gradient">
    <summa-portal-page-header
      [title]="'reseller:users.my-profile.title' | i18nextCap"
      (logout)="logOut()"
      (previous)="previousPage$.next()"
      [currentUser]="user.username"
      [hideBreadcrumb]="true"
      [showPrevious]="true"
    >
    </summa-portal-page-header>
  </section>

  <!-- LANGUAGE SELECTOR -->
  <!-- <form *ngIf="changeLanguageForm" [formGroup]="changeLanguageForm" fxLayout="column" class="form px-0 mb-2">
            <h2>{{ 'reseller:users.my-profile.change-language' | i18nextCap }}</h2>

            <mat-form-field appearance="legacy">
              <mat-select formControlName="language" (selectionChange)="submitLanguage$.next()" [placeholder]="'common:dictionary.language' | i18nextCap">
                <mat-option *ngFor="let language of languages" [value]="language"> {{ language }} </mat-option>
              </mat-select>
              </mat-form-field>
            </form> -->

  <section class="p-4" fxLayout="column" fxLayoutAlign="space-around center">
    <!-- Welcome -->
    <div class="welcome w-50" fxLayout="column">
      <h1><span>{{ 'reseller:users.my-profile.welcome' | i18nextCap}} </span> {{user.username}}</h1>
    </div>

    <!-- PASSWORD CHANGE -->
    <div class="reseller-card xl p-3 w-50" fxLayout="column">
      <div class="header">{{ 'reseller:users.my-profile.change-password'| i18nextCap}}</div>
      <form fxFlex fxLayout="column" *ngIf="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="submitPassword$.next(null)">
        <mat-form-field appearance="legacy">
          <mat-label>{{ 'reseller:users.my-profile.password-old' | i18nextCap }}</mat-label>
          <input type="password" formControlName="password_old" matInput />
        </mat-form-field>

        <mat-form-field appearance="legacy">
          <mat-label>{{ 'reseller:users.my-profile.password-new' | i18nextCap }}</mat-label>
          <input type="password" formControlName="password_new" matInput />
        </mat-form-field>

        <mat-form-field appearance="legacy">
          <mat-label>{{ 'reseller:users.my-profile.password-again' | i18nextCap }}</mat-label>
          <input type="password" formControlName="password_check" matInput />
        </mat-form-field>

        <p class="alert alert-danger" *ngIf="newPasswordCheckError$ | async" [@fadeInOut]>
          {{'common:validations.password-check-error' |i18nextCap}}
        </p>
        <button
          mat-button
          type="submit"
          class="button save"
          [disabled]="changePasswordForm.invalid || changePasswordForm.get('password_new').value !== changePasswordForm.get('password_check').value"
        >
          {{'common:buttons.save' | i18nextCap}}
        </button>
      </form>
    </div>
  </section>
  <!-- </div> -->
</section>
