import { gql } from 'apollo-angular';

export const GetResellersQuery = gql`
  query getResellers($skip: Int, $take: Int, $ids: [String!]) {
    getResellers(skip: $skip, take: $take, ids: $ids) {
      id
      name
      contact {
        name
        email
        phone
      }
    }
  }
`;

export const GetResellerQuery = gql`
  query getReseller($id: String!) {
    getReseller(id: $id) {
      id
      name
      contact {
        name
        email
        phone
      }
      paymentInfo {
        accountName
        iban
        bic
      }
      clients {
        id
        name
        contact {
          name
          email
          phone
        }
        locations {
          id
          name
          projects {
            id
            key
            name
            gatewayKey
          }
        }
      }
    }
  }
`;
