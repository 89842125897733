import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DriverState, DriverStore } from './driver.store';

// FIXME: create DRIVER DTO and replace ANY

@Injectable({
  providedIn: 'root',
})
export class DriverQuery extends QueryEntity<DriverState, any> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);
  selectCheckState$ = this.select((state) => state.ui.check);
  selectReplaceState$ = this.select((state) => state.ui.replace);

  constructor(protected store: DriverStore) {
    super(store);
  }
}
