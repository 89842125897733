import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ClientQuery } from '../../data-access';

@Injectable()
export class GlobalClientPermissions {
  private client$ = this.clientQuery.selectActive();
  private reseller$ = this.router.selectParams().pipe(map((params) => params.resellerId));

  constructor(private clientQuery: ClientQuery, private router: RouterQuery) {}

  updateClientPermission$: Observable<PERMISSIONS.Permission> = this.client$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.reseller$),
    map(([client, reseller]) => ({
      application: {
        name: PERMISSIONS.applicationReseller,
        qualifiers: [reseller],
      },
      domain: {
        name: PERMISSIONS.domainClient,
        qualifiers: [client.id],
      },
      subdomain: null,
      action: PERMISSIONS.actionUpsert,
    })),
  );
}
