import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs/operators';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { db } from '@summa/shared/ui/floorplan';
import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { ProjectQuery, FloorplanQuery, FloorplanService, StorageService, ProjectService } from '../../../../../../data-access';

@Injectable()
export class ReUploadFloorplanSandbox {
  project$ = this.projectQuery.selectActive();
  floorplans$ = this.floorplanQuery.selectAll();
  floorplan$ = this.floorplanQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  params$ = this.router.selectParams();
  data$ = this.sidePanelService.getData();
  removeState$ = this.floorplanQuery.selectRemoveState$;

  constructor(
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private floorplanQuery: FloorplanQuery,
    private floorplanService: FloorplanService,
    private storageService: StorageService,
    private sidePanelService: SidePanelService,
    private router: RouterQuery,
  ) {}

  getProject(projectKey: string): void {
    this.projectService.getProject(projectKey);
  }

  getFloorplan(floorplanKey: string): void {
    this.floorplanService.getFloorplan(floorplanKey);
  }

  uploadFile(bucket: string, file: File, projectKey: string): void {
    this.storageService.uploadFile(bucket, file, projectKey);
  }

  removeFileOnS3(bucket: string, floorplan: DOMAIN.Floorplan, fileName: string): void {
    // delete file on s3
    this.storageService.deleteFile(bucket, fileName);
    // delete file in indexedDB
    db.floorplan.delete(`${floorplan.projectKey}-${encodeURI(floorplan.fileName)}`);
  }

  insertFloorplanAndFixture(floorplan: any, fixtures: any[]): void {
    this.floorplanService.upsertFloorplan(floorplan, fixtures);
  }

  overrideFloorplan(floorplan: any, fixtures: any[], overrideOption: any): void {
    this.floorplanService.overrideFloorplan(floorplan, fixtures, overrideOption);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.floorplanService.reset();
  }
}
