import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

import { SharedAuthUiModule } from '@summa/shared/auth/ui';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';
import { I18NextModule } from 'angular-i18next';
import { LoginPage } from './login/login.page';
import { MyProfilePage } from './my-profile/my-profile.page';

@NgModule({
  imports: [
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CommonModule,
    FlexLayoutModule,
    SharedAuthUiModule,
    SharedUiHeadersModule,
    I18NextModule,
    RouterModule.forChild([{ path: '', pathMatch: 'full', component: LoginPage }]),
  ],
  declarations: [LoginPage, MyProfilePage],
  exports: [],
})
export class SummaPortalAuthModule {}
