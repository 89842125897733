import { Injectable } from '@angular/core';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, map } from 'rxjs';
import { DriverQuery } from '../../../../../data-access';

@Injectable()
export class ProjectDriverInfoSandbox {
  driver$ = this.driverQuery.selectActive();
  driverLog$ = this.driver$.pipe(
    filter(isNotNullOrUndefined),
    map((driver) => {
      if (!driver.lastDriverLog) return null;
      const { channels, ...rest } = driver.lastDriverLog;
      const chs = channels.map((channel) => {
        const { id, ...ch } = channel;
        return { id: channel.id, properties: Object.keys(ch).map((prop) => ({ name: prop, value: ch[prop] })) };
      });

      const properties = Object.keys(rest).map((prop) => ({ name: prop, value: rest[prop] }));
      return {
        channels: chs,
        properties,
      };
    }),
  );

  constructor(private driverQuery: DriverQuery) {}
}
