import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResellerDashboardSandbox } from '../reseller-dashboard.sandbox';

@Component({
  selector: 'summa-reseller-user-widget',
  templateUrl: './reseller-user-widget.component.html',
  styleUrls: ['./reseller-user-widget.component.scss'],
})
export class ResellerUserWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: ResellerDashboardSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleParams();
  }

  handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ resellerId }) => this.sandbox.getUsers(resellerId));
  }
}
