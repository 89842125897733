<div class="header-gradient">
  <summa-portal-page-header (logout)="logOut()" [currentUser]="(sandbox.currentUser$ | async)?.username"></summa-portal-page-header>
</div>

<div fxLayout="row wrap" class="content-body mt-4" fxLayoutGap.gt-sm="16px" fxLayoutAlign="center center" *ngIf="!loading">
  <div
    fxLayoutAlign="center center"
    class="summa-card clickable pt-4 pb-4"
    *ngFor="let app of sandbox.applications$ | async"
    fxFlex.xs="100%"
    fxFlex.sm="33%"
    fxFlex.gt-md="25%"
    fxFlex.gt-md="20%"
    [routerLink]="['/', app]"
  >
    <div fxFlexFill class="text-center" fxLayout="column" fxLayoutAlign="start center">
      <mat-icon [inline]="true" [svgIcon]="'common:icons.' + app | i18next"></mat-icon>
      <h2 class="p-0 m-0 mt-1">{{ 'dictionary.' + app | i18nextCap | titlecase }}</h2>
    </div>
  </div>
</div>
