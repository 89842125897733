<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{ ((sandbox.user$ | async) ? 'reseller:users.upsert.edit-title' : 'reseller:users.upsert.add-title') | i18nextCap}}</h1>
  </ng-container>

  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.user$ |async as user; else idToggle">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ user.id}}</div>
      </div>
      <ng-template #idToggle>
        <div>
          <label fxFlex="15">{{'reseller:users.upsert.type' | i18nextCap}}</label>
          <div fxFlex="auto" class="clickable" (click)="isNewUser$.next(null)">{{'common:dictionary.user' | i18nextCap}}</div>
        </div>
      </ng-template>
    </section>
  </ng-container>

  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form px-0">
      <ng-container *ngIf="form.get('username'); else idField">
        <mat-form-field appearance="legacy">
          <mat-label>{{'reseller:users.upsert.username' | i18nextCap}}</mat-label>
          <input formControlName="username" matInput />
        </mat-form-field>
        <mat-form-field *ngIf="!(sandbox.user$ | async)" appearance="legacy">
          <mat-label>{{'reseller:users.upsert.password' | i18nextCap}}</mat-label>
          <input formControlName="password" matInput />
          <mat-error *ngIf="form.get('password')?.hasError('required')">
            {{ 'validations.is-required' | i18next: { field: 'reseller:users.upsert.password' } }}
          </mat-error>
          <mat-error *ngIf="form.get('password')?.hasError('minlength')"> min. 6 characters </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-template #idField>
        <mat-form-field appearance="legacy">
          <mat-label>{{'reseller:users.upsert.id' | i18nextCap}}</mat-label>
          <input formControlName="id" matInput />
          <mat-error *ngIf="form.get('id')?.hasError('required')">
            {{ 'validations.is-required' | i18next: { field: 'reseller:users.upsert.id' } }}
          </mat-error>
        </mat-form-field>
      </ng-template>

      <mat-form-field appearance="legacy">
        <mat-label>{{'reseller:users.upsert.language' | i18nextCap}}</mat-label>
        <input formControlName="language" matInput />
      </mat-form-field>

      <div *ngIf="rolesList">
        <mat-label>Roles for {{rolesList.application}}</mat-label>
        <ul>
          <li *ngFor="let role of rolesList.roles">
            <mat-checkbox (change)="selectedRoles.toggle(role)" [checked]="selectedRoles.isSelected(role)"
              >{{'user-management:' + role.role | i18nextCap}}</mat-checkbox
            >
          </li>
        </ul>
      </div>

      <mat-form-field *ngIf="isProjectLevel$ |async">
        <mat-select formControlName="controlPanels" placeholder="Control panels" [multiple]="true" #multiSelect>
          <mat-option *ngFor="let controlPanel of sandbox.controlPanels$ | async" [value]="controlPanel.id" [title]="controlPanel.description">
            {{ controlPanel.name | titlecase }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxFlex="100" fxLayoutGap="10px">
      <button mat-button type="button" (click)="sandbox.close()" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="!form || form.invalid || selectedRoles.selected.length === 0" mat-button (click)="submit$.next(null)" class="button save">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
