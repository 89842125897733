import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

export type HeatGridLogState = EntityState<DOMAIN.TemperatureSensorLog, string>;

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'heat-grid-logs', idKey: 'address', resettable: true })
export class HeatGridLogStore extends EntityStore<HeatGridLogState, DOMAIN.TemperatureSensorLog> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
