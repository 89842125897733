import { gql } from 'apollo-angular';

export const GetFloorplansQuery = gql`
  query GetFloorplans($projectKey: String) {
    getFloorplans(projectKey: $projectKey) {
      id
      name
      projectKey
      description
      fileName
      fileLocation
    }
  }
`;

export const GetFloorplanQuery = gql`
  query GetFloorplan($id: String) {
    getFloorplan(id: $id) {
      id
      name
      projectKey
      description
      fileName
      fileLocation
    }
  }
`;

export const RemoveFloorplanQuery = gql`
  mutation RemoveFloorplan($id: String!) {
    removeFloorplan(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
