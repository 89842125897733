import { Injectable } from '@angular/core';
import { combineLatest, filter, map } from 'rxjs';

import { DOMAIN } from '@summa/models';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AuthQuery, DocumentQuery, DocumentService, UserQuery, UserService } from '../../../data-access';

@Injectable()
export class AgreementsDialogSandbox {
  currentUser$ = this.authQuery.selectActive();
  documents$ = this.documentQuery.selectAll().pipe(filter(isNotNullUndefinedOrEmpty));
  unconfirmedDocuments$ = combineLatest([this.currentUser$, this.documents$]).pipe(
    filter(([user, documents]) => isNotNullUndefinedOrEmpty(documents) && isNotNullOrUndefined(user)),
    map(([user, documents]) =>
      documents
        .filter(
          (document) =>
            !user.agreements.find(
              (agreementDocument) => agreementDocument.documentId === document.id && agreementDocument.version === document.version,
            ),
        )
        .sort((a, b) => a.order - b.order),
    ),
  );
  upsertState$ = this.userQuery.selectUpsertState$;

  constructor(
    private authQuery: AuthQuery,
    private userQuery: UserQuery,
    private userService: UserService,
    private documentQuery: DocumentQuery,
    private documentService: DocumentService,
  ) {}

  updateUserAgreements(documents: DOMAIN.UserAgreementInput[]): void {
    this.userService.updateUserAgreements(documents);
  }

  reset(): void {
    this.documentService.reset();
  }
}
