import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LongPressDirective } from './long-press/long-press.directive';

const declareExport = [LongPressDirective];

@NgModule({
  imports: [CommonModule],
  declarations: [...declareExport],
  exports: [...declareExport],
})
export class SharedUtilDirectivesModule {}
