/**
 * Flattens an nested array into a single array
 * example: [[x][x][[xx][xxx]]] =>  [x,x,xx,xxx]
 * @param arr nested array
 * @returns single array
 */
export function flatten(arr: any): any[] {
  return arr.reduce((flat: any, toFlatten: any) => {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
}
