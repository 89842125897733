import { Inject, Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { CONSTANTS, ENV, PERMISSIONS } from '@summa/models';
import { Observable } from 'rxjs';
import {
  AuthQuery,
  CommandService,
  ControlQuery,
  ControlService,
  DriverQuery,
  DriverService,
  FloorplanQuery,
  FloorplanService,
  ProjectQuery,
  ProjectService,
  ResellerQuery,
  ScenarioQuery,
  ScenarioService,
} from '../../../data-access';
import { GlobalProjectPermissions } from '../../../permission-access/rules';

@Injectable()
export class ProjectDetailsSandbox {
  drivers$ = this.driverQuery.selectAll();
  driverCount$ = this.drivers$.pipe(map((driver) => driver?.length));
  scenarios$ = this.scenarioQuery.selectAll();
  controls$ = this.controlQuery.selectAll();
  params$ = this.router.selectParams();
  reseller$ = this.resellerQuery.selectActive();
  project$ = this.projectQuery.selectActive();
  floorplans$ = this.floorplanQuery.selectAll();
  currentUser$ = this.authQuery.selectActive();
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;
  floorplanName: string;

  constructor(
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private controlService: ControlService,
    private controlQuery: ControlQuery,
    private commandService: CommandService,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
    private floorplanQuery: FloorplanQuery,
    private floorplanService: FloorplanService,
    private router: RouterQuery,
    private resellerQuery: ResellerQuery,
    private authQuery: AuthQuery,
    private globalProjectPermission: GlobalProjectPermissions,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  getControls(projectKey: string): void {
    this.controlService.getControls(projectKey);
  }

  getFloorplan(id: string): void {
    this.floorplanService.setActive(id);
  }

  getFloorplans(projectKey: string): void {
    this.floorplanService.getFloorplans(projectKey);
  }

  getControl(controlId: string): void {
    this.controlService.getControl(controlId);
  }

  getScenario(scenarioId: string): void {
    this.scenarioService.getScenario(scenarioId);
  }

  getProject(projectKey: string): void {
    this.projectService.getProject(projectKey);
  }

  executeSync(projectKey: string, subject: CONSTANTS.SyncType, mode: 'partial' | 'full'): void {
    this.commandService.executeSyncCommand({ projectKey, subject, mode });
  }

  updateLocalProject(projectKey: string): void {
    this.commandService.executeUpdateLocalCommand(projectKey);
  }

  reset(): void {
    this.driverService.reset();
    this.scenarioService.reset();
    this.controlService.reset();
    this.floorplanService.reset();
    this.projectService.setActive(null);
  }
}
