import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { AutomationQuery, AutomationService } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectAutomationOverviewSandbox {
  params$ = this.router.selectParams();
  removeState$ = this.automationQuery.selectRemoveState$;
  automations$ = this.automationQuery.selectAll();
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private router: RouterQuery,
    private automationService: AutomationService,
    private automationQuery: AutomationQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  getAutomations(projectKey: string): void {
    this.automationService.getAutomations(projectKey);
  }

  getAutomation(id: string): void {
    this.automationService.getAutomation(id);
  }

  remove(id: string): void {
    this.automationService.remove(id);
  }

  reset(): void {
    this.automationService.reset();
  }
}
