import { gql } from 'apollo-angular';

export const getGridEyeClusterQuery = gql`
  query getGridEyeClusters($project: String!) {
    getGridEyeClusters(project: $project) {
      id
      name
      projectKey
      timestamp
      sensors {
        address
        data
        width
        height
      }
      amounts {
        current
        daily
        weekly
        monthly
        yearly
        allTime
      }
    }
  }
`;
