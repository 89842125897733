import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { PERMISSIONS } from '@summa/models';
import { isNotNullOrUndefined, onlyUnique } from '@summa/shared/util/typescript';

import { filter, Subject, takeUntil } from 'rxjs';

import { ApplicationSandbox } from './applications.sandbox';

@Component({
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ApplicationSandbox],
})
export class ApplicationsComponent {
  destroy$ = new Subject<void>();
  loading = true;

  constructor(private router: Router, public sandbox: ApplicationSandbox) {}

  ngOnInit(): void {
    this.handleCurrentPermissions();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logOut(): void {
    this.sandbox.logout();
    this.router.navigate(['/login']);
  }

  /**
   * 1. When user only has one project, redirect to the specific project
   * 2. When user only has one reseller, redirect to the specific reseller
   */
  handleCurrentPermissions(): void {
    this.sandbox.currentUser$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe(({ permissions }) => {
      const resellers = permissions
        .filter((perm) => perm.application.name === PERMISSIONS.applicationReseller)
        .flatMap((r) => r.application.qualifiers)
        .filter(onlyUnique);

      const projects = permissions
        .filter((perm) => perm.application.name === PERMISSIONS.applicationReseller && perm.domain.name === PERMISSIONS.domainProject)
        .flatMap((p) => p.domain.qualifiers)
        .filter(onlyUnique);

      let permission: PERMISSIONS.Permission;
      switch (true) {
        // #1
        case projects.length === 1 && projects[0] !== PERMISSIONS.wildcardQualifier:
          permission = permissions.find(
            (p) =>
              p.application.name === PERMISSIONS.applicationReseller &&
              p.domain.name === PERMISSIONS.domainProject &&
              p.domain.qualifiers.includes(projects[0]),
          );
          // eslint-disable-next-line rxjs/no-nested-subscribe
          this.router.navigate([`/reseller/${permission.application.qualifiers[0]}/projects/${projects[0]}`]);
          break;

        case resellers.length === 1 && resellers[0] !== PERMISSIONS.wildcardQualifier:
          this.router.navigate([`/reseller/${resellers[0]}`]);
          break;

        default:
          this.loading = false;
      }
    });
  }
}
