import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { I18NextCapPipe } from 'angular-i18next';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, ENV } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectFloorplanAdminComponentSandbox } from './project-floorplan-admin.sandbox';
import { ReUploadFloorplanPage } from './re-upload-floorplan/re-upload-floorplan.page';

@Component({
  selector: 'summa-project-floorplan-admin',
  templateUrl: './project-floorplan-admin.component.html',
  styleUrls: ['./project-floorplan-admin.component.scss'],
  providers: [ProjectFloorplanAdminComponentSandbox],
})
export class ProjectFloorplanAdminComponent implements OnInit, OnDestroy {
  baseUrl = this.environment.floorplanUrl;
  file: string;
  fileName: string;
  project: string;

  form: FormGroup;
  fileUrl: string;
  currentRoute: string;

  destroy$ = new Subject();

  filter: FormControl;
  filteredAdmin$: ReplaySubject<DOMAIN.Driver[]>;
  fixtures$ = new BehaviorSubject<DOMAIN.Fixture[]>([]);

  remove$ = new Subject<any>();
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, any>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public sandbox: ProjectFloorplanAdminComponentSandbox,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    private sidePanelService: SidePanelService,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleFloorplan();
    this.handleRemove();
  }

  private handleFloorplan(): void {
    this.sandbox.floorplan$.pipe(takeUntil(this.destroy$)).subscribe((floorplan) => {
      this.file = floorplan.name;
      this.fileName = floorplan.fileName.replace('.json', '');
      this.currentRoute = this.router.url.includes('floorplans') ? this.router.url : `${this.router.url}/floorplans`;
      this.fileUrl = floorplan.fileLocation;
      this.sandbox.getFixtures(floorplan.id);
      this.sandbox.fixtures$
        .pipe(
          map((fixtures) => fixtures.filter((fixture: DOMAIN.Fixture) => fixture.floorplanKey === floorplan.id)),
          takeUntil(this.destroy$),
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe((fixtures) => this.fixtures$.next(fixtures));
    });
  }

  handleAddFloorplan(): void {
    this.sidePanelService.open(ReUploadFloorplanPage);
  }

  // -----------------
  // Handle Remove Floorplan
  // -----------------

  private handleRemove(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.remove$.pipe(withLatestFrom(this.sandbox.floorplan$), takeUntil(this.destroy$)).subscribe(([_, floorplan]) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: floorplan.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeFile(this.environment.awsConfig.floorplan.bucket, floorplan, `${floorplan.projectKey}/${floorplan.fileName}`);
        this.confirmDialog.close();
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (!state.isSuccessful) return;
      this.sandbox.reset();
      this.router.navigate(['../../'], { relativeTo: this.activatedRoute.parent });
    });
  }
}
