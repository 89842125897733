import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { PERMISSIONS, PERMISSIONS as PERMISSIONS_MODEL } from '@summa/models';
import { AuthQuery, ClientQuery, ClientService, LocationQuery, ProjectService } from '../../../../data-access';
import { permittedProjects as permittedProjectsHelper } from '../../../../permission-access/helpers';
import { GlobalClientPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ResellerClientDetailsSandbox {
  params$ = this.router.params;
  upsertLocationState$ = this.locationQuery.selectUpsertState$;
  client$ = this.clientQuery.selectActive();
  userPermissions$ = this.authQuery.selectActive().pipe(map((user) => user.permissions));

  filteredClient$ = this.client$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.userPermissions$),
    map(([client, permissions]) => {
      const permittedProjects = permittedProjectsHelper(permissions, client.reseller.id);

      const locations = !permittedProjects.includes(PERMISSIONS_MODEL.wildcardQualifier)
        ? client.locations
            .map((location) => ({
              ...location,
              projects: location.projects.filter((project) => permittedProjects.includes(project.key)),
            }))
            .filter((location) => location.projects.length > 0)
        : client.locations;

      return { ...client, locations };
    }),
  );

  updateClientPermission$: Observable<PERMISSIONS.Permission> = this.clientPermissions.updateClientPermission$;

  constructor(
    private projectService: ProjectService,
    private clientService: ClientService,
    private clientQuery: ClientQuery,
    private locationQuery: LocationQuery,
    private router: ActivatedRoute,
    private authQuery: AuthQuery,
    private clientPermissions: GlobalClientPermissions,
  ) {}

  checkActive(id: string): void {
    if (this.clientQuery.getActive()?.id !== id) this.getClient(id);
  }

  resetActiveClient(): void {
    this.clientService.setActive(null);
  }

  resetActiveProject(): void {
    this.projectService.setActive(null);
  }

  getClient(id: string): void {
    this.clientService.getClient(id);
  }
}
