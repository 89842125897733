import { gql } from 'apollo-angular';

export const GetScenesQuery = gql`
  query getScenes($skip: Int, $take: Int, $projectKey: String) {
    getScenes(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      projectKey
      name
      description
      sortOrder
    }
  }
`;

export const GetSceneQuery = gql`
  query getScene($id: String!) {
    getScene(id: $id) {
      id
      projectKey
      name
      description
      sortOrder
      default {
        dimValue
        cct
        duv
        x
        y
      }
      dynamic {
        service
        geolocation {
          latitude
          longitude
          timezone
        }
        use_geolocation
        minute_interval
        space
        limit_latitude
        lower_cct
        higher_cct
        indoor_location
      }
    }
  }
`;
