import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { AutomationQuery, AutomationService } from '../../../../data-access';

@Injectable()
export class ProjectAutomationPreviewSandbox {
  params$ = this.router.selectParams();
  removeState$ = this.automationQuery.selectRemoveState$;
  automation$ = this.automationQuery.selectActive();

  constructor(
    private router: RouterQuery,
    private automationService: AutomationService,
    private automationQuery: AutomationQuery,
  ) {}

  getAutomation(id: string): void {
    this.automationService.getAutomation(id);
  }

  reset(): void {
    this.automationService.resetActive();
  }
}
