import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { SceneState, SceneStore } from './scene.store';

@Injectable({
  providedIn: 'root',
})
export class SceneQuery extends QueryEntity<SceneState, DOMAIN.Scene> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: SceneStore) {
    super(store);
  }
}
