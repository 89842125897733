<title>403 Forbidden</title>

<div class="container">
  <div class="content">
    <div class="error-code">
      <h1>We are sorry...</h1>
    </div>
    <div class="error-message">
      <p>
        The page you're trying to access has restricted access. <br />
        Please refer to your system administrator.
      </p>
    </div>
    <div class="button-wrapper">
      <button class="button" (click)="goBack()">Go Back</button>
    </div>
  </div>
</div>
