import { Injectable } from '@angular/core';
import { DOMAIN, PortalAPI } from '@summa/models';
import { CommandService, DriverQuery, DriverService } from '../../../../../data-access';

@Injectable()
export class ProjectDriverActionsSandbox {
  driver$ = this.driverQuery.selectActive();
  replaceState$ = this.driverQuery.selectReplaceState$;

  constructor(private driverQuery: DriverQuery, private driverService: DriverService, private commandService: CommandService) {}

  getDriver(driverKey: string): void {
    if (driverKey === this.driverQuery.getActiveId()) {
      return;
    }
    this.driverService.getDriver(driverKey);
  }

  resetReplaceState(): void {
   this.driverService.driverStore.resetReplace();
  }

  replaceDriver(driverKey: string, newDriverKey: string, replaceState: string): void {
    if (!newDriverKey) return;
    this.driverService.replaceDriver(driverKey, newDriverKey, replaceState);
  }

  executeSetBaseline(driverKey: string, baseline: number = DOMAIN.defaultSensorBaselineFrames): void {
    this.commandService.executeSetBaselineCommand({ driverKeys: [driverKey], baseline });
  }

  executeResetSensors(driverKey: string): void {
    this.commandService.executeResetSensorsCommand({ driverKey });
  }

  executeDeleteCurrentValues(driverKey: string): void {
    this.commandService.executeDeleteCurrentValuesCommand({ driverKey });
  }

  executeSetDriverType(driverKey: string, driverType: number = DOMAIN.defaultDriverTypeId): void {
    this.commandService.executeDriverSettingsCommand({ driverKey, driverType });
  }

  executeSetDriverChannels(driverKey: string, channels: PortalAPI.DriverSettingsChannel[]): void {
    this.commandService.executeDriverSettingsCommand({ driverKey, channels });
  }
}
