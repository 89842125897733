import { gql } from 'apollo-angular';

export const CurrentUserQuery = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      username
      roles
      language
      permissions
      agreements {
        documentId
        version
      }
    }
  }
`;
