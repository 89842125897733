import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UI } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';

export interface ControlPanelState extends EntityState<DTO.ControlPanel, string>, ActiveState {
  ui: {
    upsert: UI.State;
    remove: UI.State;
  };
}

const initialUiState = {
  isLoading: false,
  errors: null,
  isSuccessful: false,
};

const initialState = {
  ui: {
    upsert: initialUiState,
    remove: initialUiState,
  },
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'controlPanels', idKey: 'id', resettable: true })
export class ControlPanelStore extends EntityStore<ControlPanelState, DTO.ControlPanel> {
  constructor() {
    super(initialState);
  }

  resetUIState = () => this.update({ ...initialState });
  updateUpsert = (upsert: Partial<UI.State>) => this.update({ ui: { ...this.getValue().ui, upsert: { ...this.getValue().ui.upsert, ...upsert } } });
  updateRemove = (remove: Partial<UI.State>) => this.update({ ui: { ...this.getValue().ui, remove: { ...this.getValue().ui.remove, ...remove } } });
}
