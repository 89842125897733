import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectSceneAddPage } from '../project-scene-add/project-scene-add.page';
import { ProjectScenePreviewPage } from '../project-scene-preview/project-scene-preview.page';

import { ProjectSceneOverviewSandbox } from './project-scene-overview.sandbox';

@Component({
  selector: 'summa-project-scene-overview',
  templateUrl: './project-scene-overview.page.html',
  styleUrls: ['./project-scene-overview.page.scss'],
  providers: [ProjectSceneOverviewSandbox],
})
export class ProjectSceneOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  remove$ = new Subject<DOMAIN.Scene>();
  upsertPanel$ = new Subject<DOMAIN.Scene>();
  detailsPanel$ = new Subject<DOMAIN.Scene>();
  scenes = new MatTableDataSource<DOMAIN.Scene>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  columnsToDisplay = ['name', 'description', 'sortOrder', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ProjectSceneOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnInit(): void {
    this.handleParams();
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();
  }

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getScenes(projectKey);
    });

    this.sandbox.scenes$.pipe(takeUntil(this.destroy$)).subscribe((scenes) => {
      this.scenes.data = scenes as DOMAIN.Scene[];
      this.scenes.sort = this.sort;
      this.scenes.paginator = this.paginator;
    });
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scenes.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((scene) => {
      this.sidePanelService.open(ProjectSceneAddPage, scene);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((scene: DOMAIN.Scene) => {
      this.sandbox.getScene(scene.id);
      this.sidePanelService.open(ProjectScenePreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((scene: DOMAIN.Scene) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: scene.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeScene(scene.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
