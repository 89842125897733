/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { NotifierService } from 'angular-notifier';
import { I18NextCapPipe } from 'angular-i18next';

import { DOMAIN } from '@summa/models';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty, isNullOrUndefined } from '@summa/shared/util/typescript';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { ProjectControlAddSandbox } from './project-control-add.sandbox';

@Component({
  selector: 'summa-project-control-add',
  templateUrl: './project-control-add.page.html',
  styleUrls: ['./project-control-add.page.scss'],
  providers: [ProjectControlAddSandbox],
})
export class ProjectControlAddPage implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject<void>();
  destroy$ = new Subject();
  addControl$ = new Subject<void>();
  selectedDriver$ = new Subject<DOMAIN.Driver>();
  channelTypes = DOMAIN.channelType;
  scenarioSelection$ = new Subject<{ channel: number; scenario: DOMAIN.Scenario }>();
  @ViewChild('scenarioSelector', { static: false }) scenarioSelector: MatSelect;

  choiceKey = 'key';
  choiceGroups = 'groups';
  choiceScenario = 'scenario';
  choiceAlternateScenario = 'alternate-scenario';
  controlModes = [0, 1, 2, 3, 4];

  @ViewChild('controlInput', { static: false }) controlInput: ElementRef;

  public channelFormControl(index: number, value: string): any {
    return this.channels.controls[index].get(value);
  }

  public channelValue(index: number, value: string): string {
    return this.channelFormControl(index, value)?.value;
  }

  get channels(): FormArray {
    return this.form.get('channels') as FormArray;
  }

  public scenarios(channel: number): FormArray {
    return this.channelFormControl(channel, 'alternate').get('scenarios') as FormArray;
  }

  constructor(
    public sandbox: ProjectControlAddSandbox,
    private fb: FormBuilder,
    private notifier: NotifierService,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.sandbox.resetDriver();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.resetDriver();
    this.handleData();
    this.handleProject();
    this.handleSubmit();
    this.handleSuccess();
    this.handleAddControl();
    this.handleDriverCheck();
    this.handleScenarioSelection();
  }

  removeChannel(index: number): void {
    this.channels.removeAt(index);
  }

  addChannel(): void {
    this.channels.push(this.createChannel());
  }

  removeScenario(channel: number, index: number): void {
    this.scenarios(channel).removeAt(index);
  }

  drop(ch: number, event: CdkDragDrop<string[]>) {
    const scenariosControl = this.scenarios(ch);
    const scenarios = scenariosControl.value;
    moveItemInArray(scenarios, event.previousIndex, event.currentIndex);
    scenariosControl.setValue(scenarios);
  }

  private handleData(): void {
    this.sandbox.data$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([control, { projectKey }]) => {
      if (!control) {
        this.form = this.fb.group({
          projectKey: [projectKey],
          name: ['', [Validators.required]],
          description: ['', []],
          type: [DOMAIN.controlChannel, [Validators.required]],
          mode: [2, [Validators.required]],
          driver: ['', [Validators.required]],
          channels: this.fb.array([this.createChannel()]),
        });
        return;
      }
      this.sandbox.getControl((control as DOMAIN.Control).id);
    });

    /*
     * 1. Get control
     * 2. Get Driver based on control.address
     * 3. Combine control, driver & groups to create form
     */
    this.sandbox.control$
      .pipe(
        filter(isNotNullOrUndefined),
        tap((control) => this.sandbox.checkDriverAvailability(null, control.address)),
        switchMap((control) =>
          combineLatest([this.selectedDriver$, this.sandbox.groups$, this.sandbox.scenarios$]).pipe(
            map(([driver, groups, scenarios]) => ({ control, driver, groups, scenarios })),
          ),
        ),
        first(),
        takeUntil(this.destroy$),
      )
      .subscribe(({ control, driver, scenarios }) => {
        this.form = this.fb.group({
          id: control.id,
          projectKey: control.projectKey,
          name: [control.name, [Validators.required]],
          description: [control.description, []],
          type: [control.type, [Validators.required]],
          driver: [driver?.key ?? '', [Validators.required]],
          mode: [control.mode, [Validators.required]],
          channels: this.fb.array(control.channels.map((channel) => this.createChannel(channel, scenarios))),
        });
      });
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getScenarios(project.key);
      this.sandbox.getGroups(project.key);
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  private handleScenarioSelection(): void {
    this.scenarioSelection$.pipe(takeUntil(this.destroy$)).subscribe(({ channel, scenario }) => {
      const formArray = this.scenarios(channel);
      formArray.push(this.fb.group(scenario));

      // unfocus selectScenario
      this.scenarioSelector.value = null;
      this.scenarioSelector.close();
    });
  }

  private handleDriverCheck(): void {
    this.sandbox.driverCheckEntity$
      .pipe(
        filter(({ errors, entity }) => isNotNullOrUndefined(errors) || isNotNullOrUndefined(entity)),
        withLatestFrom(this.sandbox.project$),
        takeUntil(this.destroy$),
      )
      .subscribe(([{ errors, entity: driver }, project]) => {
        switch (true) {
          case isNotNullOrUndefined(errors):
          case isNullOrUndefined(driver):
            this.resetControlDriver();
            return;
          case driver.projectKey === project.key:
          case isNullOrUndefined(driver.projectKey):
            this.selectedDriver$.next(driver);
            return;
          case driver.projectKey !== project.key:
            this.notifier.notify(
              'error',
              this.i18next.transform('message:driver-already-added', {
                field: driver.key,
                field2: driver.projectKey,
              }),
            );
            this.resetControlDriver();
            break;
          default:
            this.resetControlDriver();
        }
      });
  }

  private resetControlDriver(): void {
    this.selectedDriver$.next(null);
    this.form?.patchValue({ driver: null });
    this.controlInput?.nativeElement.focus();
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        withLatestFrom(this.sandbox.project$, this.selectedDriver$),
        filter(([, driver]) => this.form.valid && isNotNullOrUndefined(driver)),
        filter(() => {
          const formIsValid = this.validateFormChannelFields();
          if (!formIsValid) this.notifier.notify('error', this.i18next.transform('message:invalid-form'));
          return formIsValid;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(([, project, driver]) => {
        this.sandbox.upsertControl(this.mapToInput(this.form.getRawValue(), driver));
        if (isNullOrUndefined(driver.projectKey)) {
          this.sandbox.upsertDriver({ ...driver, projectKey: project.key });
        }
      });
  }

  private validateFormChannelFields(): boolean {
    return this.form.getRawValue().channels.some((channel) => {
      const settingCheck = (settings) =>
        isNotNullOrUndefined(settings) &&
        ((settings.choice === this.choiceGroups && isNotNullUndefinedOrEmpty(settings.groups)) ||
          (settings.choice === this.choiceScenario && isNotNullUndefinedOrEmpty(settings.scenario)));

      switch (this.form.value.mode) {
        case 0:
          return settingCheck(channel.single);

        case 1:
          return settingCheck(channel.high) && settingCheck(channel.low);

        case 2:
          return settingCheck(channel.single);

        case 3:
        case 4:
          return (
            isNotNullOrUndefined(channel.alternate) &&
            ((channel.alternate.choice === this.choiceKey && isNotNullUndefinedOrEmpty(channel.alternate.referenceKey)) ||
              (channel.alternate.choice === this.choiceAlternateScenario &&
                isNotNullUndefinedOrEmpty(channel.alternate.key) &&
                isNotNullUndefinedOrEmpty(channel.alternate.scenarios)))
          );

        default:
          return false;
      }
    });
  }

  private createChannel(channel?: DOMAIN.ControlChannel, scenarios?: DOMAIN.Scenario[]): FormGroup {
    const createChannelSettings = (settings: DOMAIN.ControlChannelSettings) => {
      const hasGroups = settings?.groups?.length > 0;
      return this.fb.control({
        choice: hasGroups ? this.choiceGroups : this.choiceScenario,
        scenario: settings?.scenario ?? '',
        groups: hasGroups ? settings.groups : [],
      });
    };

    return this.fb.group({
      address: [channel?.address ?? '', [Validators.required]],

      // Pulse
      single: createChannelSettings(channel?.single),
      enableDouble: [isNotNullOrUndefined(channel?.double)],
      double: createChannelSettings(channel?.double),

      // High-Low
      high: createChannelSettings(channel?.high),
      low: createChannelSettings(channel?.low),

      // Alternating Pulse
      alternate: this.fb.group({
        choice:
          isNullOrUndefined(channel?.alternate) || isNotNullUndefinedOrEmpty(channel.alternate?.scenarios)
            ? this.choiceAlternateScenario
            : this.choiceKey,
        scenarios: this.fb.array(
          channel?.alternate?.scenarios?.map((scenario) => {
            const scenarioEntity = scenarios.find((s) => s.id === scenario);
            return this.fb.group(scenarioEntity);
          }) ?? [],
        ),

        key: [{ value: channel?.alternate?.key ?? crypto.randomUUID(), disabled: true }, []],
        referenceKey: [channel?.alternate?.key, []],
      }),
    });
  }

  private handleAddControl(): void {
    this.addControl$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.form.get('driver')) return;

      const driverKey = this.form.get('driver').value;
      this.sandbox.checkDriverAvailability(driverKey);
    });
  }

  private mapToInput(form: any, driver: DOMAIN.Driver): DOMAIN.ControlInput {
    return {
      ...(form.id && { id: form.id }),
      name: form.name,
      type: form.type,
      mode: form.mode,
      address: driver.address,
      projectKey: form.projectKey,
      description: form.description,
      channels: form.channels.map((ch: any) => {
        const defaultSettings = {
          address: ch.address,
        };

        const mapChannelSettings = (settings): DOMAIN.ControlChannelSettingsInput => {
          const groups = settings.groups?.filter((channelGroup: any) => channelGroup.group) ?? [];
          return {
            scenario: settings.choice === this.choiceScenario ? settings.scenario : null,
            groups: settings.choice === this.choiceGroups ? groups : null,
          };
        };

        const mapAlternateSettings = (settings) => {
          if (settings.choice === this.choiceKey) {
            return {
              key: settings.referenceKey,
            };
          }

          return {
            key: settings.key,
            scenarios: settings.scenarios.map((scenario) => scenario.id),
          };
        };

        switch (form.mode) {
          case 0:
            return { ...defaultSettings, single: mapChannelSettings(ch.single), ...(ch.enableDouble && { double: mapChannelSettings(ch.double) }) };

          case 1:
            return { ...defaultSettings, high: mapChannelSettings(ch.high), low: mapChannelSettings(ch.low) };

          case 2:
            return { ...defaultSettings, single: mapChannelSettings(ch.single) };

          case 3:
          case 4:
            return { ...defaultSettings, alternate: mapAlternateSettings(ch.alternate) };

          default:
            return { ...defaultSettings };
        }
      }),
    };
  }
}
