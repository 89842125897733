import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import {
  AutomationQuery,
  AutomationService,
  CardQuery,
  CardService,
  DriverQuery,
  DriverService,
  ProjectQuery,
  ScenarioQuery,
  ScenarioService,
} from '../../../../data-access';

@Injectable()
export class ProjectAutomationAddSandbox {
  groups$ = this.cardQuery.selectAll();
  project$ = this.projectQuery.selectActive();
  params$ = this.router.selectParams();
  data$ = this.sidePanelService.getData();
  drivers$ = this.driverQuery.selectAll();
  automation$ = this.automationQuery.selectActive();
  updateState$ = this.automationQuery.selectUpsertState$;
  scenarios$ = this.scenarioQuery.selectAll();

  constructor(
    private sidePanelService: SidePanelService,
    private router: RouterQuery,
    private cardQuery: CardQuery,
    private projectQuery: ProjectQuery,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private cardService: CardService,
    private automationService: AutomationService,
    private automationQuery: AutomationQuery,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
  ) {}

  getGroups(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  upsertAutomation(automation: DOMAIN.AutomationInput): void {
    this.automationService.upsertAutomation(automation);
  }

  getAutomation(id: string): void {
    this.automationService.getAutomation(id);
  }

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDriverDeviceList(projectKey);
  }

  reset(): void {
    this.automationService.resetActive();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
