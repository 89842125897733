import { ChangeDetectionStrategy, Component } from '@angular/core';

import { CustomerCardSandbox } from '../../../customer-card/customer-card.sandbox';

@Component({
  selector: 'summa-card-detail-tab-info',
  templateUrl: './card-detail-tab-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./card-detail-tab-info.component.scss'],
  providers: [CustomerCardSandbox],
})
export class CardDetailTabInfoComponent {
  cardLogs$;

  constructor(protected sandbox: CustomerCardSandbox) {
    this.cardLogs$ = sandbox.cardLogs$;
  }
}
