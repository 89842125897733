import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, first, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { ProjectGatewayUpsertSandbox } from './project-gateway-upsert.sandbox';

@Component({
  selector: 'summa-project-gateway-upsert',
  templateUrl: './project-gateway-upsert.page.html',
  styleUrls: ['./project-gateway-upsert.page.scss'],
  providers: [ProjectGatewayUpsertSandbox],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectGatewayUpsertPage implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  submit$ = new Subject<void>();

  form: FormGroup;
  updateGateway: boolean;

  constructor(private fb: FormBuilder, public sandbox: ProjectGatewayUpsertSandbox) {}

  ngOnInit(): void {
    this.initializeForm();
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeForm(gatewayKey?: string): void {
    this.form = this.fb.group({
      gatewayKey: [gatewayKey ?? '', Validators.required],
    });
  }

  private handleData(): void {
    this.sandbox.data$.pipe(filter(isNotNullOrUndefined), first(), takeUntil(this.destroy$)).subscribe((gatewayKey: string) => {
      this.updateGateway = true;
      this.initializeForm(gatewayKey);
    });
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.form?.valid),
        withLatestFrom(this.sandbox.project$),
        takeUntil(this.destroy$),
      )
      .subscribe(([, project]) => {
        const partialProject = {
          key: project.key,
          gatewayKey: this.form.get('gatewayKey').value,
        };

        this.sandbox.updateProjectGatewayKey(partialProject);
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$
      .pipe(
        filter((state) => state.isSuccessful),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.sandbox.close());
  }
}
