import { gql } from 'apollo-angular';

export const getGatewayQuery = gql`
  query getGateway($key: String!) {
    getGateway(key: $key) {
      state
      messageTime
      addresses
      key
      modified
      applications {
        name
        timestamp
        type
        version
      }
    }
  }
`;

export const getGatewaysQuery = gql`
  query getGateways($keys: [String!]!) {
    getGateways(keys: $keys) {
      state
      messageTime
      addresses
      key
      modified
    }
  }
`;
