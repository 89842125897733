export const mqttOrganization = 'summa';
export const mqttLocal = 'local';
export const mqttOrganizationLocal = `${mqttOrganization}-local`;

export const mqttTopicFixtureUrlIn = `${mqttOrganization}/fixture/in`;
export const mqttTopicFixtureUrlOut = `${mqttOrganization}/fixture/out`;
export const mqttTopicFixtureStateOut = `${mqttOrganization}/fixture-state/out`;

export const mqttTopicSensorTemperatureOut = `${mqttOrganizationLocal}/sensor/temperature/out`;

export const mqttLocalUpdate = `${mqttOrganization}/local-update`;
export const mqttGatewayUpdate = `${mqttOrganization}/configuration-update`;

export const mqttFirmwareUpdate = `${mqttOrganization}/firmware/update`;
export const mqttFirmwareUpdateAvailable = `${mqttLocal}/firmware/update-available`;
export const mqttFirmwareUpdateDownloaded = `${mqttOrganization}/firmware/update-downloaded`;

export const mqttTopicFixtureUpdateUrlIn = `${mqttOrganization}/fixture-update/in`;

export const mqttTopicControlUrlIn = `${mqttOrganization}/control/in`;
export const mqttTopicControlUrlOut = `${mqttOrganization}/control/out`;

export const mqttTopicProvisioningIn = 'provisioning/in';
export const mqttTopicProvisioningOut = 'provisioning/out';

export const mqttTopicSyncOut = `${mqttOrganization}/sync/out`;

export const mqttNotificationOut = `${mqttOrganization}/notification/out`;
export const mqttCurrentCheckIn = `${mqttOrganization}/current-check/in`;

export const mqttTopicControlPanelIn = `${mqttOrganization}/control-panel/in`;
export const mqttTopicControlPanelOut = `${mqttOrganization}/control-panel/out`;

export const mqttCommandIn = `${mqttOrganization}/command/in`;
export const mqttPortalCommandIn = `${mqttOrganization}/portal-command/in`;

export const mqttType = [
  mqttTopicFixtureUrlIn,
  mqttTopicFixtureUrlOut,
  mqttTopicFixtureUpdateUrlIn,
  mqttTopicControlUrlIn,
  mqttTopicControlUrlOut,
  mqttTopicSyncOut,
  mqttNotificationOut,
  mqttCurrentCheckIn,
  mqttLocalUpdate,
  mqttGatewayUpdate,
  mqttFirmwareUpdate,
  mqttFirmwareUpdateAvailable,
  mqttTopicControlPanelIn,
  mqttTopicControlPanelOut,
  mqttCommandIn,
  mqttPortalCommandIn,
] as const;
export type MqttType = typeof mqttType[number];
