/**
 * SUB-DOMAINS
 */
export const subDomainDrivers = 'drivers';

export const permissionSubDomains = [subDomainDrivers] as const;
export type PermissionSubDomains = typeof permissionSubDomains[number];

/**
 * QUALIFIERS
 */
export const subQualifierAddDriver = 'add-driver';
export const subQualifierRemoveDriver = 'remove-driver';
export const subQualifierDriverActions = 'driver-actions';

export const permissionSubQualifiers = [subQualifierAddDriver] as const;
export type PermissionSubQualifiers = typeof permissionSubQualifiers[number];
