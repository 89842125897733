import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectInstallerWidgetSandbox } from './project-installer-widget.sandbox';

@Component({
  selector: 'summa-project-installer-widget',
  templateUrl: './project-installer-widget.component.html',
  styleUrls: ['./project-installer-widget.component.scss'],
  providers: [ProjectInstallerWidgetSandbox],
})
export class ProjectInstallerWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: ProjectInstallerWidgetSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.reset();
  }

  ngOnInit(): void {
    this.handleParams();
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getInstallers(projectKey);
    });
  }
}
