import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ENV } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ControlPanelPreviewPage } from '../preview/control-panel-preview.page';

import { ControlPanelOverviewSandbox } from './control-panel-overview.sandbox';
import { ControlPanelUpsertPage } from '../upsert';
import { controlPanelDeletePermission, controlPanelUpsertPermission } from '../control-panel.constants';

@Component({
  selector: 'summa-control-overview',
  templateUrl: './control-panel-overview.page.html',
  styleUrls: ['./control-panel-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ControlPanelOverviewSandbox],
})
export class ControlPanelOverviewPage implements OnInit {
  destroy$ = new Subject();
  remove$ = new Subject<DTO.ControlPanel>();
  upsertPanel$ = new Subject<string>();
  detailsPanel$ = new Subject<DTO.ControlPanel>();
  controlPanels = new MatTableDataSource<DTO.ControlPanel>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  controlPanelUpsertPermission = controlPanelUpsertPermission;
  controlPanelDeletePermission = controlPanelDeletePermission;

  get columnsToDisplay() {
    if (this.environment.app.isPortal) {
      return ['name', 'description', 'actions'];
    }
    return ['name', 'description'];
  }

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ControlPanelOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnInit(): void {
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();

    this.sandbox.controlPanels$.pipe(takeUntil(this.destroy$)).subscribe((controlPanels: DTO.ControlPanel[]) => {
      this.controlPanels.data = controlPanels;
      this.controlPanels.sort = this.sort;
      this.controlPanels.paginator = this.paginator;
    });

    if (this.environment.app.isPortal) {
      this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe((params: { projectKey?: string }) => {
        if (!params.projectKey) return;

        this.sandbox.getProject(params.projectKey);
        this.sandbox.getControlPanels(params.projectKey);
      });
    } else {
      this.sandbox.getLocalControlPanels();
    }
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.controlPanels.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      this.sidePanelService.open(ControlPanelUpsertPage, id);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((control: DTO.ControlPanel) => {
      this.sandbox.getControlPanel(control.id);
      this.sidePanelService.open(ControlPanelPreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((control: DTO.ControlPanel) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: control.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeControlPanel(control.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
