import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { I18NextModule } from 'angular-i18next';
import { FlexLayoutModule } from '@angular/flex-layout';

import { SharedUiCardsModule } from '@summa/shared/ui/cards';
import { SharedUiIconModule } from '@summa/shared/ui/icon';
import { DetailsTileWidgetComponent } from './details-tile-widget';

const components = [DetailsTileWidgetComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    I18NextModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatSliderModule,
    MatTabsModule,
    MatIconModule,
    I18NextModule,
    SharedUiCardsModule,
    SharedUiIconModule,
  ],
  declarations: [components],
  exports: [components],
})
export class SharedDashboardUiModule {}
