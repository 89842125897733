/* eslint-disable eqeqeq */
function setFetchingSize(val) {
  const progress = document.getElementById('progress-viewer');
  if (progress) {
    progress.innerHTML = val;
  }
}

function setProgress(val) {
  const progress = document.getElementById('progress-bar');
  if (progress) {
    progress.style.width = `${val}%`;
    // eslint-disable-next-line no-restricted-syntax
    for (const element of document.getElementsByClassName('contain-progress')) {
      if (val == 1) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
    }
  }
}

export default function onProgress(phase, size, totalSize) {
  if (totalSize === null) {
    setFetchingSize(null);
    setProgress(1);
  } else {
    setFetchingSize(`${(size / 1000000).toFixed(2)}MB/${(totalSize / 1000000).toFixed(2)}MB`);
    setProgress((size / totalSize) * 100);
  }
}
