import { combineLatest, Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { PERMISSIONS } from '@summa/models';
import { AuthQuery, GatewayQuery, GatewayService, ProjectQuery, ProjectService, ResellerQuery, ResellerService } from '../../../data-access';
import { filterResellerProjectsBasedOnPermissions } from '../../../permission-access/helpers';
import { GlobalProjectPermissions } from '../../../permission-access/rules';

@Injectable()
export class ProjectOverviewSandbox {
  params$ = this.router.selectParams();
  removeState$ = this.projectQuery.selectRemoveState$;
  reseller$ = this.resellerQuery.selectActive();

  userProjects$: Observable<DTO.Project[]> = this.reseller$.pipe(
    filter((reseller) => isNotNullOrUndefined(reseller)),
    withLatestFrom(this.authQuery.selectActive()),
    map(([reseller, user]) => filterResellerProjectsBasedOnPermissions(reseller, user.permissions)),
  );

  combinedProjects$: Observable<DTO.Project[]> = combineLatest([this.userProjects$, this.gatewayQuery.selectAll()]).pipe(
    filter(([projects]) => isNotNullOrUndefined(projects)),
    map(([projects, gateways]) => projects.map((p) => ({ ...p, gateway: gateways.find((g) => g.key === p.gatewayKey) }))),
  );

  deletePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.deleteProjectPermission$;
  createPermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.createProjectPermission$;

  constructor(
    private gatewayQuery: GatewayQuery,
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private gatewayService: GatewayService,
    private resellerService: ResellerService,
    private router: RouterQuery,
    private resellerQuery: ResellerQuery,
    private authQuery: AuthQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  reset(): void {
    this.projectService.reset();
  }

  checkActive(id: string): void {
    if (this.projectQuery.getActive()?.id !== id) this.getProject(id);
  }

  resetActiveProject(): void {
    this.projectService.setActive(null);
  }

  getProjects(): void {
    this.projectService.getProjects();
  }

  getGatewayStates(keys: string[]): void {
    this.gatewayService.getGateways(keys);
  }

  getProject(id: string): void {
    this.projectService.getProject(id);
  }

  removeProject(key: string, id: string) {
    this.projectService.removeProject(key, id);
  }

  resetReseller(resellerId: string) {
    this.resellerService.getReseller(resellerId);
  }
}
