import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { ColorSliderModule } from 'ngx-color/slider';

import { CustomerDashboardPage, DashboardModule } from '@summa/dashboard';
import { SharedUiCardsModule } from '@summa/shared/ui/cards';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedUiFloorplanModule } from '@summa/shared/ui/floorplan';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';

import { PermissionGuard } from '@summa/shared/dashboard/util';
import { PERMISSIONS } from '@summa/models';
import { CustomerShellPage } from './customer-shell.page';
import { CustomerOverviewPage } from './overview/customer-overview.page';
import { AuthStore } from '../../data-access';

@NgModule({
  imports: [
    CommonModule,
    I18NextModule,
    LayoutModule,
    FlexLayoutModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSliderModule,
    MatSlideToggleModule,
    ColorSliderModule,
    SharedUiCardsModule,
    SharedUiHeadersModule,
    SharedUiDialogsModule,
    SharedUiFloorplanModule,
    DashboardModule,
    RouterModule.forChild([
      {
        path: '',
        component: CustomerShellPage,
        children: [
          { path: '', pathMatch: 'full', component: CustomerOverviewPage },
          {
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                application: { name: PERMISSIONS.applicationCustomer, qualifiers: [PERMISSIONS.wildcardQualifier] },
                domain: { name: PERMISSIONS.applicationProject, qualifiers: [':projectKey'] },
                // TODO: resolve :projectKey (route)  :projectId (permission) issue
                subdomain: null,
                action: PERMISSIONS.actionRead,
              },
            },
            path: ':projectKey',
            component: CustomerDashboardPage,
          },
        ],
      },
    ]),
  ],
  declarations: [CustomerShellPage, CustomerOverviewPage],
  providers: [{ provide: 'AuthStore', useExisting: AuthStore }],
})
export class SummaPortalCustomerModule {}
