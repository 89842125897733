import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ENV } from '@summa/models';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { CustomerScenarioSandbox } from './customer-scenario.sandbox';

@Component({
  selector: 'summa-scenario',
  templateUrl: './customer-scenario.page.html',
  styleUrls: ['./customer-scenario.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerScenarioSandbox],
})
export class CustomerScenarioPage implements OnInit, OnDestroy {
  scenarioSelected$ = new Subject<string>();
  destroy$ = new Subject();
  scenarios$ = this.sandbox.scenarios$;

  constructor(private sandbox: CustomerScenarioSandbox, @Inject('environment') private environment: ENV.Environment) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (this.environment.app.isPortal) {
      this.sandbox.activeProject$.pipe(takeUntil(this.destroy$)).subscribe((project) => this.sandbox.getScenarios(project?.key));
      this.handleSelectScenario();
    } else {
      this.handleLocalDashboard();
    }
  }

  handleSelectScenario(): void {
    this.scenarioSelected$.pipe(withLatestFrom(this.sandbox.activeProject$), takeUntil(this.destroy$)).subscribe(([id, project]) => {
      this.sandbox.executeScenarioCommand(id, project.key);
    });
  }

  handleLocalDashboard(): void {
    this.sandbox.getScenarios();
    this.scenarioSelected$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      this.sandbox.executeScenarioCommand(id);
    });
  }
}
