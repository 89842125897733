import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { UserPreviewSandbox } from './user-preview.sandbox';

@Component({
  selector: 'summa-user-preview',
  templateUrl: './user-preview.page.html',
  styleUrls: ['./user-preview.page.scss'],
  providers: [UserPreviewSandbox],
})
export class UserPreviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: UserPreviewSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleData();
  }

  handleData(): void {
    this.sandbox.data$
      .pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([user, { resellerId }]) => this.sandbox.getUser(user.id, resellerId));
  }
}
