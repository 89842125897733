/* eslint-disable no-restricted-syntax */
import blocksHandler from './handlers/blocks';
import entitiesHandler from './handlers/entities';
import headerHandler from './handlers/header';
import tablesHandler from './handlers/tables';

// Parse the value into the native representation
export function parseValue(type: number, value: string): any {
  if (type >= 10 && type < 60) {
    return parseFloat(value);
  }
  if (type >= 210 && type < 240) {
    return parseFloat(value);
  }
  if (type >= 60 && type < 100) {
    return parseInt(value, 10);
  }
  return value;
}

// Content lines are alternate lines of type and value
export function convertToTypesAndValues(contentLines: string[]): any {
  let state = 'type';
  let type;
  const typesAndValues = [];
  for (const line of contentLines) {
    if (state === 'type') {
      type = parseInt(line, 10);
      state = 'value';
    } else {
      typesAndValues.push([type, parseValue(type, line)]);
      state = 'type';
    }
  }
  return typesAndValues;
}

export const separateSections = (tuples) => {
  let sectionTuples;
  return tuples.reduce((sections, tuple) => {
    if (tuple[0] === 0 && tuple[1] === 'SECTION') {
      sectionTuples = [];
    } else if (tuple[0] === 0 && tuple[1] === 'ENDSEC') {
      sections.push(sectionTuples);
      sectionTuples = undefined;
    } else if (sectionTuples !== undefined) {
      sectionTuples.push(tuple);
    }
    return sections;
  }, []);
};

// Each section start with the type tuple, then proceeds
// with the contents of the section
// eslint-disable-next-line @typescript-eslint/ban-types
export const reduceSection = (acc: { header: {}; tables: { layers: any; styles: any }; blocks: any[]; entities: any[] }, section: string | any[]) => {
  const sectionType = section[0][1];
  const contentTuples = section.slice(1);
  switch (sectionType) {
    case 'HEADER':
      acc.header = headerHandler(contentTuples);
      break;
    case 'TABLES':
      acc.tables = tablesHandler(contentTuples);
      break;
    case 'BLOCKS':
      acc.blocks = blocksHandler(contentTuples);
      break;
    case 'ENTITIES':
      acc.entities = entitiesHandler(contentTuples);
      break;
    default:
  }
  return acc;
};

export default (string: string) => {
  const lines = string.split(/\r\n|\r|\n/g);
  const tuples = convertToTypesAndValues(lines);
  const sections = separateSections(tuples);

  return sections.reduce(reduceSection, {
    // Start with empty defaults in the event of empty sections
    header: {},
    blocks: [],
    entities: [],
    tables: { layers: {}, styles: {} },
  });
};
