<ng-container *ngIf="this.sandbox.updatePermission$ | async as updatePermission">
  <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutGap="16px">
      <div [permission-check]="updatePermission" fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card lg pt-1 driver-add-header">
          <summa-project-driver-add></summa-project-driver-add>

          <button class="sync-drivers" [title]="'project:tooltips.sync-project-drivers' | i18nextCap" (click)="syncDrivers$.next()">
            <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.sync' | i18next"></mat-icon>
          </button>
        </div>
      </div>

      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card lg p-3">
          <div class="header">
            {{'common:dictionary.driver_plural' | i18nextCap}}
            <div class="header-tools">
              <div class="filter">
                <mat-form-field floatLabel="never">
                  <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                  <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
                </mat-form-field>
              </div>
            </div>
          </div>

          <mat-table [dataSource]="drivers" matSort class="w-100">
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:drivers.overview.table-headers.id' | i18nextCap}}</mat-header-cell>
              <mat-cell class="highlight" *matCellDef="let driver">{{ driver.key }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="address">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:drivers.overview.table-headers.address' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let driver">{{ driver.address }}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:drivers.overview.table-headers.type' | i18nextCap}} </mat-header-cell>
              <mat-cell *matCellDef="let driver">
                <div>{{ driver.type }} <small *ngIf="driver.devices.length > 0"> ({{ driver.devices[0].type }})</small></div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell class="align-right" *matHeaderCellDef mat-sort-header>
                {{'reseller:drivers.overview.table-headers.state' | i18nextCap}}
              </mat-header-cell>
              <mat-cell *matCellDef="let driver">
                <ng-container [ngSwitch]="driver.state">
                  <div class="badge success" *ngSwitchCase="'online'">{{ 'common:dictionary.states.online' | i18next }}</div>
                  <div class="badge danger" *ngSwitchCase="'offline'">{{ 'common:dictionary.states.offline' | i18next }}</div>
                  <div class="badge unknown" *ngSwitchDefault>{{ 'common:dictionary.states.unknown' | i18next }}</div>
                </ng-container>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="provisioning-state">
              <mat-header-cell class="align-right" *matHeaderCellDef mat-sort-header>
                {{'reseller:drivers.overview.table-headers.provisioning-state' | i18nextCap}}
              </mat-header-cell>
              <mat-cell *matCellDef="let driver">
                <div
                  *ngIf="!driver.provisioningLog || driver.provisioningLog.provisioningLog?.error !== undefined; else provisioningLog"
                  class="badge danger"
                >
                  {{ 'common:dictionary.states.not-provisioned' | i18next }}
                </div>
                <ng-template #provisioningLog>
                  <div class="badge success">{{ driver.provisioningLog.timestamp | date: 'd/M/yy, h:mm a' }}</div>
                </ng-template>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let row">
                <div
                  class="remove ml-2"
                  [title]="'reseller:cards.overview.remove' | i18nextCap"
                  (click)="remove$.next(row) ; $event.stopPropagation() "
                  [permission-check]="updatePermission"
                >
                  <mat-icon [svgIcon]="'file-document-box-remove-outline'"></mat-icon>
                </div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" (click)="detailsPanel$.next(row)"> </mat-row>
            <div class="filter-notification" *matNoDataRow>{{ 'reseller:drivers.overview.filter-notification' | i18nextCap }} "{{input.value}}"</div>
          </mat-table>
          <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-container>
