/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { CardStore } from './card.store';
import { activateCardSceneQuery, removeCardQuery, upsertCardQuery } from './graphql/card.gql-mutation';
import { GetCardOverviewQuery, GetCardPreviewQuery, GetCardQuery, GetCardsQuery } from './graphql/card.gql-query';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private apollo: Apollo, private cardStore: CardStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.cardStore.reset();
  }

  resetActive(): void {
    this.cardStore.resetUIState();
    this.cardStore.setActive(null);
  }

  setActive(id: string | null): void {
    this.cardStore.setActive(id);
  }

  setCardActive(cardId?: string): void {
    this.cardStore.setActive(cardId);
  }

  getCardPreview(id: string): void {
    this.apollo
      .subscribe({ query: GetCardPreviewQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getCard as DOMAIN.Card))
      .subscribe((card) => {
        this.cardStore.upsert(card.id, card);
        this.setActive(card?.id);
      });
  }

  getCard(id: string): void {
    this.apollo
      .subscribe({ query: GetCardQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getCard as DOMAIN.Card))
      .subscribe((card) => {
        this.cardStore.upsert(card.id, card);
        this.setActive(card?.id);
      });
  }

  getCards(projectKey: string, skip?: number, take?: number): void {
    this.apollo
      .subscribe({ query: GetCardsQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getCards as DOMAIN.Card[]))
      .subscribe((cards) => this.cardStore.set(cards));
  }

  getCardOverview(projectKey: string, skip?: number, take?: number): void {
    this.apollo
      .subscribe({ query: GetCardOverviewQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getCards as DOMAIN.Card[]))
      .subscribe((cards) => this.cardStore.set(cards));
  }

  upsertCard(card: DOMAIN.CardInput): void {
    this.cardStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertCardQuery, variables: { card } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.cardStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `title ${card.title}` }));

        this.cardStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertCard: DOMAIN.Card };
      this.cardStore.upsert(data.upsertCard.id, data.upsertCard);
      this.cardStore.setActive(data.upsertCard.id);

      // set success:
      this.cardStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  activateCardScene(cardId: string, sceneId: string): void {
    this.cardStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: activateCardSceneQuery, variables: { cardId, sceneId } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.cardStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `id: ${cardId}` }));

        this.cardStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { activateCardScene: DOMAIN.Card };
      this.cardStore.upsert(data.activateCardScene.id, data.activateCardScene);
      this.cardStore.setActive(data.activateCardScene.id);

      // set success:
      this.cardStore.updateUpsert({ isSuccessful: true, isLoading: false });
    });
  }

  remove(id: string): void {
    this.cardStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeCardQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.cardStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `card ${id}` }));

        this.cardStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.cardStore.remove(id);

      // set success;
      this.cardStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
