<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.automation$ | async as automation">
  <ng-container header>
    <h1><span class="orange">{{'reseller:automation.preview.title-prefix' |i18nextCap}}: </span>{{ automation.name }}</h1>
  </ng-container>

  <ng-container info>
    <div class="control-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="4px">
          <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="title" fxFlex="20">{{'common:dictionary.name' | i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ automation.name }}</div>
          </div>
          <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="title" fxFlex="20">{{'common:dictionary.description'|i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ automation.description }}</div>
          </div>
          <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
            <div class="title" fxFlex="20">{{'common:dictionary.enabled'|i18nextCap}}</div>
            <div class="content" fxFlex="80" [ngSwitch]="automation.state">
              <div class="badge success m-0" *ngSwitchCase="'ON'">{{ 'common:dictionary.yes' | i18next | uppercase }}</div>
              <div class="badge danger m-0" *ngSwitchCase="'OFF'">{{ 'common:dictionary.no' | i18next | uppercase}}</div>
              <div class="badge warning m-0" *ngSwitchDefault>{{ 'common:dictionary.unknown' | i18next | uppercase}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <!-- TABS -->
    <mat-tab-group id="tabs" fxFill>
      <!-- INFO -->
      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
            <mat-icon [svgIcon]="'common:icons.info' | i18next"></mat-icon>
            <content fxFlexAlign="center">{{ 'common:dictionary.info' | i18nextCap }}</content>
          </section>
        </ng-template>
        <ng-template matTabContent>
          <summa-project-automation-info-tab></summa-project-automation-info-tab>
        </ng-template>
      </mat-tab>
      <!-- INFO -->
      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
            <mat-icon [svgIcon]="'common:icons.history' | i18next"></mat-icon>
            <content fxFlexAlign="center">{{ 'common:dictionary.history' | i18nextCap }}</content>
          </section>
        </ng-template>
        <ng-template matTabContent>
          <summa-project-automation-history-tab></summa-project-automation-history-tab>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <!-- buttons -->
    </section>
  </ng-container>
</summa-side-panel-content>
