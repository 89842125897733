import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { FirmwareState, FirmwareStore } from './firmware.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'lastModified',
  sortByOrder: Order.DESC,
})
export class FirmwareQuery extends QueryEntity<FirmwareState, DOMAIN.Firmware> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);
  selectCheckState$ = this.select((state) => state.ui.check);

  constructor(protected store: FirmwareStore) {
    super(store);
  }
}
