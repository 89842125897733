import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Subject, takeUntil } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { DOMAIN, PERMISSIONS } from '@summa/models';
import { userHasPermission } from '@summa/shared/auth/util';
import { ProjectDriverPreviewSandbox } from './project-driver-preview.sandbox';

@Component({
  selector: 'summa-project-driver-preview',
  templateUrl: './project-driver-preview.page.html',
  styleUrls: ['./project-driver-preview.page.scss'],
  providers: [ProjectDriverPreviewSandbox],
})
export class ProjectDriverPreviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  columnsToDisplay = ['title', 'hidden'];

  isCategoryControl = DOMAIN.isCategoryControl;
  isCategoryDriver = DOMAIN.isCategoryDriver;

  readonly driverActionPermission: PERMISSIONS.Permission[] = [
    {
      application: { name: PERMISSIONS.applicationReseller, qualifiers: [PERMISSIONS.wildcardQualifier] },
      domain: { name: PERMISSIONS.domainProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
      subdomain: null, // { name: PERMISSIONS.subDomainDrivers, qualifiers: [PERMISSIONS.subQualifierDriverActions] },
      action: PERMISSIONS.actionRead,
    },
    {
      application: { name: PERMISSIONS.applicationRnD, qualifiers: [PERMISSIONS.wildcardQualifier] },
      domain: { name: PERMISSIONS.domainProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
      subdomain: null,
      action: PERMISSIONS.actionRead,
    },
  ];

  hasActionPermission$ = this.sandbox.currentUser$.pipe(
    map((user) => userHasPermission(user.permissions, this.driverActionPermission, PERMISSIONS.PermissionMatchingStrategy.AtLeastOne)),
  );

  constructor(public sandbox: ProjectDriverPreviewSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleUpsertSuccess();
    this.handleReplaceSuccess();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  closePanel(): void {
    this.sidePanelService.close();
    this.sandbox.resetActive();
  }

  private handleUpsertSuccess(): void {
    this.sandbox.upsertState$
      .pipe(
        filter((state) => state.isSuccessful),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.closePanel());
  }


  private handleReplaceSuccess(): void {
    this.sandbox.replaceState$
      .pipe(
        filter((state) => state.isSuccessful),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.closePanel());
  }
}
