import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { DriverTargetRippleDialogData } from './driver-target-ripple-dialog.model';

@Component({
  selector: 'summa-driver-target-ripple-dialog',
  templateUrl: './driver-target-ripple-dialog.component.html',
  styleUrls: ['./driver-target-ripple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverTargetRippleDialogComponent implements OnInit {
  form: FormGroup;
  submit$ = new Subject<void>();

  get channels(): FormArray {
    return this.form.get('channels') as FormArray;
  }

  @Output() confirm: EventEmitter<unknown> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DriverTargetRippleDialogData, private fb: FormBuilder) {
    const channels = [];

    for (let index = 0; index < 3; index += 1) {
      channels.push(
        this.fb.group({
          id: `ch${index + 1}`,
          targetRipple: [0, [Validators.required]],
        }),
      );
    }

    this.form = this.fb.group({
      channels: this.fb.array(channels),
    });
  }

  ngOnInit(): void {
    this.handleSubmit();
  }

  private handleSubmit(): void {
    this.submit$.subscribe(() => this.confirm.emit(this.form.value));
  }
}
