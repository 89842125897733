import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForbiddenComponent {
  constructor(private location: Location) {}

  goBack(): void {
    this.location.back(); // Go back one step in the browser history
    this.location.back(); // Go back one more step
  }
}
