import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, TemplatePortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { SidePanelComponent } from './side-panel.component';

@Injectable({ providedIn: 'root' })
export class SidePanelService {
  close$: Observable<void>;

  private ref: OverlayRef;

  private sidePanelRef: ComponentRef<SidePanelComponent>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private data$ = new BehaviorSubject<any>(null);
  private hasChanges$ = new BehaviorSubject<boolean>(false);

  private closeNotify$ = new Subject<void>();

  constructor(private overlay: Overlay) {
    this.close$ = this.closeNotify$.asObservable();
    this.setHasChanges(false);
  }

  getData<T>(): Observable<T> {
    return this.data$.asObservable();
  }

  public setHasChanges(newValue: boolean): void {
    this.hasChanges$.next(newValue);
  }

  hasComponentAttached(): boolean {
    return this.ref && this.ref.hasAttached();
  }

  showDiscardDialog(): boolean {
    return this.hasChanges$.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async open(templateOrComponent: TemplatePortal<any> | ComponentType<any>, data?: any): Promise<void> {
    if (this.ref && this.ref.hasAttached()) {
      await this.cleanup();
    }

    if (data !== undefined) {
      this.data$.next(data);
    }

    this.ref = this.overlay.create({
      disposeOnNavigation: true,
      hasBackdrop: true,
      height: '100vh',
      width: '100vw',
      backdropClass: 'panel-backdrop',
      panelClass: 'panel-container',
      positionStrategy: this.overlay.position().global(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });
    const sidePanelPortal = new ComponentPortal(SidePanelComponent);
    this.sidePanelRef = this.ref.attach(sidePanelPortal);
    const sidePanelContentPortal = templateOrComponent instanceof TemplatePortal ? templateOrComponent : new ComponentPortal(templateOrComponent);
    this.sidePanelRef.instance.contentPortal = sidePanelContentPortal;

    // pass through function to close and canClose
    this.sidePanelRef.instance.onCloseFunc = this.close.bind(this);
    this.sidePanelRef.instance.showDiscardDialogFunc = this.showDiscardDialog.bind(this);
  }

  async close(): Promise<void> {
    this.closeNotify$.next(null);
    await this.cleanup();
  }

  private async cleanup(): Promise<void> {
    this.data$.next(null);

    await this.sidePanelRef.instance.animateAway();
    this.ref.detach();
    this.ref.dispose();
  }
}
