// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DOMAIN } from '@summa/models';

export function resetDriver(driver: DOMAIN.Driver | DOMAIN.DriverInput): DOMAIN.DriverInput {
  const getDevices = (driverType: DOMAIN.DriverType): DOMAIN.Device[] => {
    switch (true) {
      case DOMAIN.isCategoryDriver(driverType):
        return newCategoryDriver(driver.settings.channels, driver.key);
      case DOMAIN.isCategoryControl(driverType):
        return newCategoryControl(driver.settings.channels, driver.key);
      default:
        throw new Error(`Driver type ${driverType} not found`);
    }
  };

  return {
    ...driver,
    projectKey: null,
    devices: getDevices(driver.type),
  };
}

export function newCategoryDriver(channelCount: number, driverKey: string): DOMAIN.DeviceInput[] {
  const devices: DOMAIN.DeviceInput[] = new Array(channelCount).fill('').map((_, index) => {
    const uniqueAddress = `ch${index + 1}`;

    return {
      key: `${driverKey}:${uniqueAddress}`,
      address: uniqueAddress,
      type: DOMAIN.deviceNChannel,
      category: DOMAIN.deviceCategoryLight,
      settings: {
        dimValue: 80,
        cu: 20,
        off: 10,
        mA: 0.36,
      },
    };
  });

  return devices;
}

export function newCategoryControl(channelCount: number, driverKey: string): DOMAIN.DeviceInput[] {
  const count = channelCount > 0 ? channelCount : 1;
  const devices: DOMAIN.DeviceInput[] = new Array(count).fill('').map((_, index) => {
    const uniqueAddress = `ch${index + 1}`;

    return {
      key: `${driverKey}:${uniqueAddress}`,
      address: uniqueAddress,
      type: DOMAIN.deviceControl,
      category: DOMAIN.deviceCategoryControl,
      settings: {
        actions: [],
      },
    };
  });

  return devices;
}
