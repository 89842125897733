import { gql } from 'apollo-angular';

export const ControlFields = gql`
  fragment ControlFields on Control {
    id
    projectKey
    name
    description
    address
    mode
    type
    channels {
      address
      high {
        scenario
        groups {
          group
          scene
        }
      }
      low {
        scenario
        groups {
          group
          scene
        }
      }
      single {
        scenario
        groups {
          group
          scene
        }
      }
      double {
        scenario
        groups {
          group
          scene
        }
      }
      alternate {
        key
        scenarios
      }
    }
  }
`;
