/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';

import { upsertLocationQuery } from './graphql/location.gql-mutation';
import { GetCustomerLocationsQuery, GetLocationQuery } from './graphql/location.gql-query';
import { LocationStore } from './locations.store';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private apollo: Apollo, private locationStore: LocationStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}
  somethingWentWrong = 'message:something-went-wrong';

  setActive(id: string | null): void {
    this.locationStore.setActive(id);
  }

  reset(): void {
    this.locationStore.resetUIState();
    this.setActive(null);
  }

  getLocation(id: string): any {
    this.apollo
      .subscribe({ query: GetLocationQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getLocation as PortalAPI.Location))
      .subscribe((location) => {
        this.locationStore.upsert(location.id, location);
        this.setActive(location.id);
      });
  }

  getLocations(): void {
    this.apollo
      .subscribe({ query: GetCustomerLocationsQuery })
      .pipe(map((d: any) => d.data.getCustomerLocations as PortalAPI.Location[]))
      .subscribe((locations) => this.locationStore.set(locations));
  }

  upsertLocation(location: PortalAPI.LocationInput): void {
    this.locationStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertLocationQuery, variables: { location } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.locationStore.updateUpsert({ errors: results?.errors, isLoading: false });
          // set error:
          const errorMessage = results.errors[0]?.message ?? this.somethingWentWrong;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `name ${location.name}` }));

          this.locationStore.setError(results?.errors ?? this.i18next.transform(this.somethingWentWrong));
          return;
        }

        const data = results.data as { upsertLocation: PortalAPI.Location };
        this.locationStore.upsert(data.upsertLocation.id, data.upsertLocation);

        // set success:
        this.locationStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }
}
