import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { StorageState, StorageStore } from './storage.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'LastModified',
  sortByOrder: Order.DESC,
})
export class StorageQuery extends QueryEntity<StorageState, any> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: StorageStore) {
    super(store);
  }
}
