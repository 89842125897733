<section class="control-panel-scenarios pt-2 m-0 w-100" fxLayout="row wrap" fxLayoutGap="1rem grid">
  <ng-container *ngIf="scenarios.length > 0; else noScenario">
    <section *ngFor="let scenario of scenarios" fxFlex.xs="50" fxFlex.sm="16.5" fxFlex.md="12.5" fxFlex.gt-md="7.5">
      <section
        class="scenario-item clickable w-100 h-100"
        [class.is-active]="scenario.type !== controlPanelScenarioTypePulse && scenario.isActive"
        fxLayout="column"
        fxLayoutAlign="center center"
        [title]="scenario.description"
        (click)="scenarioSelected$.next(scenario)"
      >
        <mat-icon class="scenario-icon mb-1" [svgIcon]="scenario?.icon || 'image'"></mat-icon>
        <div class="title">{{ scenario.name }}</div>
      </section>
    </section>
  </ng-container>

  <ng-template #noScenario>
    <p class="m-0 no-scenario">{{ 'customer:scenarios.no-scenarios-available' | i18nextCap }}</p>
  </ng-template>
</section>
