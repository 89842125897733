import { gql } from 'apollo-angular';

export const GetDriversQuery = gql`
  query getDrivers($skip: Int, $take: Int, $projectKey: String) {
    getDrivers(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      state
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;
