import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ProjectScenePreviewSandbox } from './project-scene-preview.sandbox';

@Component({
  selector: 'summa-project-scene-preview',
  templateUrl: './project-scene-preview.page.html',
  styleUrls: ['./project-scene-preview.page.scss'],
  providers: [ProjectScenePreviewSandbox],
})
export class ProjectScenePreviewPage implements OnDestroy {
  scene$ = this.sandbox.scene$;
  destroy$ = new Subject();

  constructor(private sandbox: ProjectScenePreviewSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.resetActive();
  }
}
