import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { CardQuery, CardService, ProjectQuery, ProjectService, FixtureStateService, FixtureStateQuery } from '../../data-access';
import { FixtureLogService } from '../../data-access/fixture-logs';

@Injectable()
export class CustomerDashboardSandbox {
  isLoading$: Observable<boolean>;
  routeParams$: Observable<Params>;
  currentProject$: Observable<DTO.Project>;
  fixtureLogs$: Observable<DOMAIN.FixtureState[]>;
  cards$: Observable<DOMAIN.Card[]>;

  constructor(
    private cardQuery: CardQuery,
    private cardService: CardService,
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private fixtureStateService: FixtureStateService,
    private fixtureStateQuery: FixtureStateQuery,
    private fixtureLogService: FixtureLogService,
  ) {
    this.isLoading$ = this.cardQuery.selectLoading();
    this.routeParams$ = this.route.params;
    this.currentProject$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));
    this.fixtureLogs$ = this.fixtureStateQuery.selectAll();

    this.cards$ = this.cardQuery.selectAll().pipe(map((cards) => cards.sort((a, b) => a.sortOrder - b.sortOrder)));
  }

  getProject(projectKey: string): void {
    if (this.projectQuery.getActiveId() !== projectKey) {
      this.projectService.getProject(projectKey);
    }
  }

  reset(): void {
    this.cardService.reset();
  }

  getCards(projectKey?: string, skip = 0, take = 50): void {
    this.cardService.getCards(projectKey, skip, take);
  }

  getFixtures(projectKey?: string): void {
    this.fixtureStateService.getFixtureStates(projectKey);
  }

  subscribeToFixtures(projectKey?: string): void {
    this.fixtureStateService.subscribe(projectKey ? [projectKey] : []);
  }

  getLocalFixtureLogs(): void {
    this.fixtureLogService.findAll();
  }

  subscribeToLocalFixture(): void {
    this.fixtureLogService.subscribe();
  }
}
