/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { removeSceneQuery, upsertSceneQuery } from './graphql/scene.gql-mutation';
import { GetSceneQuery, GetScenesQuery } from './graphql/scene.gql-query';
import { SceneStore } from './scene.store';

@Injectable({
  providedIn: 'root',
})
export class SceneService {
  constructor(private apollo: Apollo, private sceneStore: SceneStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.sceneStore.reset();
  }

  resetActive(): void {
    this.sceneStore.setActive(null);
    this.sceneStore.resetUIState();
  }

  setActive(id: string | null): void {
    this.sceneStore.setActive(id);
  }

  getScenes(projectKey: string, skip?: number, take?: number): void {
    this.sceneStore.reset();

    this.apollo
      .subscribe({ query: GetScenesQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getScenes as DOMAIN.Scene[]))
      .subscribe((scenes) => this.sceneStore.set(scenes));
  }

  getScene(id: string): void {
    this.apollo
      .subscribe({ query: GetSceneQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getScene as DOMAIN.Scene))
      .subscribe((scene) => {
        this.sceneStore.upsert(scene.id, scene);
        this.setActive(scene?.id);
      });
  }

  upsertScene(scene: DOMAIN.SceneInput): void {
    this.sceneStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertSceneQuery, variables: { scene } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.sceneStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `scene ${scene.name}` }));

        this.sceneStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertScene: DOMAIN.Scene };
      this.sceneStore.upsert(data.upsertScene.id, data.upsertScene);
      this.sceneStore.setActive(data.upsertScene.id);

      // set success;
      this.sceneStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  remove(id: string): void {
    this.sceneStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeSceneQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.sceneStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `scene ${id}` }));

        this.sceneStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.sceneStore.remove(id);

      // set success;
      this.sceneStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
