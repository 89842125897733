import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { PERMISSIONS } from '@summa/models';
import { userHasPermission } from '@summa/shared/auth/util';
import { Subject, takeUntil } from 'rxjs';
import { AuthQuery } from '../../data-access';

@Directive({
  selector: '[permission-check]',
})
export class PermissionAccessDirective implements OnInit {
  @Input('permission-check') accessPermissions: PERMISSIONS.Permission[] | PERMISSIONS.Permission = [];

  user$ = this.authQuery.selectActive();
  destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private el: ElementRef<any>, private authQuery: AuthQuery) {}

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      const userPermissions = user?.permissions;
      if (!this.accessPermissions || !userPermissions || !userHasPermission(userPermissions, this.accessPermissions)) {
        this.el.nativeElement.hidden = true;
        this.el.nativeElement.style.display = 'none';
      }
    });
  }
}
