import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FixtureLogState extends EntityState<DOMAIN.FixtureLog, string> {}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'fixture-logs', resettable: true, idKey: 'topic' })
export class FixtureLogStore extends EntityStore<FixtureLogState, DOMAIN.FixtureLog> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
