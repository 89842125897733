import { Injectable } from '@angular/core';
import { DOMAIN } from '@summa/portal/models/domain';
import { DTO } from '@summa/portal/models/dto';
import { Apollo } from 'apollo-angular';
import { of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GatewayStore } from './gateway.store';
import { GatewaySubscription, getGatewayQuery, getGatewaysQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class GatewayService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private gatewayStore: GatewayStore) {}

  reset(): void {
    this.gatewayStore.reset();
  }

  getGateway(key: string): void {
    this.apollo
      .subscribe({ query: getGatewayQuery, variables: { key } })
      .pipe(
        map((d: { data: { getGateway: DTO.Gateway } }) => d.data.getGateway),
        catchError(() => of(null)),
      )
      .subscribe((gateway) => {
        if (gateway) {
          this.gatewayStore.upsert(gateway.key, gateway);
          this.gatewayStore.setActive(gateway.key);
        }
      });
  }

  getGateways(keys: string[]): void {
    if (keys.length === 0) return;
    this.apollo
      .subscribe({ query: getGatewaysQuery, variables: { keys } })
      .pipe(map((d: { data: { getGateways: DOMAIN.Gateway[] } }) => d.data.getGateways))
      .subscribe((gateways) => (gateways ? this.gatewayStore.set(gateways) : this.gatewayStore.set(null)));
  }

  subscribe(projects: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: GatewaySubscription, variables: { projects } }).subscribe((response) => {
      const state = (response.data as { observeGatewayStates: DOMAIN.Gateway }).observeGatewayStates;
      this.gatewayStore.upsert(state.key, state);
      this.gatewayStore.setActive(state.key);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
