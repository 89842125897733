<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="center start">
  <div fxLayout="column" fxLayoutGap="16px" class="w-100">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card xl p-3">
        <div class="header">{{'reseller:common.dashboards' | i18nextCap}}</div>
        <mat-table [dataSource]="sandbox.projects$" class="w-100">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>{{'customer:overview.table-headers.id' | i18nextCap}}</mat-header-cell>
            <mat-cell class="highlight" *matCellDef="let project">{{ project.key }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>{{'customer:overview.table-headers.name' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let project">{{ project.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef>{{'customer:overview.table-headers.location' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let project">
              <div>{{ project.location.name }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef>{{'customer:overview.table-headers.country' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let project">
              <div>{{ project.location.country }}</div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="clickable" [routerLink]="['/customer/', myRowData.key]"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
