import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { IconComponent } from './icon/icon.component';

@NgModule({
  imports: [MatIconModule, CommonModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class SharedUiIconModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mdi.svg'));
    matIconRegistry.addSvgIcon('summa-loader', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/loader.svg'));
  }
}
