import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DOMAIN, PERMISSIONS } from '@summa/models';
import { userHasPermission } from '@summa/shared/auth/util';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { AUTHENTICATION_SERVICE, AuthenticationService } from '@summa/shared/dashboard/ui';

@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  constructor(private router: Router, @Inject(AUTHENTICATION_SERVICE) private authService: AuthenticationService) {}

  canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const { permissions } = routeSnapshot.data;

    if (permissions && permissions.application?.name === PERMISSIONS.applicationReseller && !!routeSnapshot.params.resellerId) {
      permissions.application.qualifiers[0] = routeSnapshot.params.resellerId;
    }
    if (permissions && permissions.domain?.name === PERMISSIONS.domainProject && !!routeSnapshot.params.projectKey) {
      permissions.domain.qualifiers[0] = routeSnapshot.params.projectKey;
    }
    if (permissions && permissions.subdomain?.qualifiers[0] === ':controlPanelId') {
      permissions.subdomain.qualifiers[0] = routeSnapshot.params.controlPanelId;
    }

    if (this.authService.isLoggedIn()) {
      if (permissions) {
        return this.checkPermissions(permissions);
      }

      return of(true);
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }

  private checkPermissions(permissionData: PERMISSIONS.Permission): Observable<boolean | UrlTree> {
    return this.authService.currentUser$.pipe(
      filter(isNotNullOrUndefined),
      map((currentUser: DOMAIN.User) => (userHasPermission(currentUser.permissions, permissionData) ? true : this.router.parseUrl('/forbidden'))),
    );
  }
}
