<mat-form-field appearance="legacy" class="w-100">
  <mat-label>{{ 'component:form.timezone.placeholder' | i18nextCap }}</mat-label>
  <mat-select [formControl]="value">
    <mat-option>
      <ngx-mat-select-search
        closeIcon="cross"
        [formControl]="timezoneFilter"
        [placeholderLabel]="'component:form.timezone.title' | i18nextCap"
        [noEntriesFoundLabel]="'component:form.timezone.no-search-result' | i18nextCap"
      >
        <mat-icon ngxMatSelectSearchClear svgIcon="close"></mat-icon>
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let timezone of filteredTimezones$ | async" [value]="timezone">{{ timezone }}</mat-option>
  </mat-select>
</mat-form-field>
