<ng-container *ngIf="sandbox.project$ |async as project">
  <ng-container
    *ngIf="{
  updatePermission: sandbox.updatePermission$ | async
} as permissions"
  >
    <!-- PROJECT INFO  -->
    <summa-project-info-widget></summa-project-info-widget>
    <summa-notification-bar></summa-notification-bar>

    <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start" fxLayout.lt-sm="column">
      <div fxLayout="column" fxLayoutGap="16px">
        <!-- GATEWAY  -->
        <summa-project-gateway-widget></summa-project-gateway-widget>

        <!-- NOTIFICATIONS -->
        <summa-notification-widget></summa-notification-widget>

        <!-- USERS -->
        <summa-details-tile-widget
          [permission-check]="userManagementPermission"
          [title]="'dictionary.user_plural' | i18nextCap"
          [route]="{destination: ['user-management'], tooltip: 'dictionary.view-all' | i18nextCap }"
        ></summa-details-tile-widget>
      </div>

      <!-- COLUMN.02 -->
      <div fxLayout="column" fxLayoutGap="16px">
        <!-- CONTROL PANEL -->
        <summa-control-panel-widget
          [summaPermissions]="controlPanelPermission"
          [userPermissions]="(this.sandbox.currentUser$ | async)?.permissions"
        ></summa-control-panel-widget>

        <!-- FLOORPLANS -->
        <div class="reseller-card sm p-2" *ngIf="floorplans$ | async as floorplans">
          <div class="header">
            {{ 'dictionary.plural.floorplan' | i18nextCap }}
            <ng-container *ngIf="floorplans.length > 5">
              <div class="view-all" title="{{ 'reseller:floorplans.view-all' | i18next }}" [routerLink]="['floorplans']">
                <small class="small-text">{{ 'dictionary.view-all' | i18next }}</small>
                <mat-icon [svgIcon]="'chevron-double-right'"></mat-icon>
              </div>
            </ng-container>
            <ng-container *ngIf="floorplans.length < 10">
              <div
                [permission-check]="permissions.updatePermission"
                class="create"
                title="{{ 'reseller:floorplans.add' | i18next }}"
                (click)="addFloorplan$.next(null)"
              >
                <mat-icon fxFlexAlign="start center" [svgIcon]="'icons.add' | i18next"></mat-icon>
              </div>
            </ng-container>
          </div>

          <!-- CONTROL: ENTRY -->
          <div
            *ngFor="let floorplan of floorplans | slice:0:7; let i=index"
            class="floorplan entry clickable"
            (click)="openFloorplanPreview(floorplan.id)"
          >
            <span class="number">0{{i+1}} |</span>
            <div class="title">{{ floorplan.name }}</div>
          </div>
        </div>

        <!-- INSTALLER WIDGET -->
        <summa-project-installer-widget></summa-project-installer-widget>
      </div>

      <!-- COLUMN.03 -->
      <div fxLayout="column" fxLayoutGap="16px">
        <!-- DRIVER WIDGET -->
        <summa-project-driver-widget> </summa-project-driver-widget>

        <!-- SCENE WIDGET -->
        <summa-project-scene-widget></summa-project-scene-widget>

        <!-- SCENARIOS -->
        <summa-project-scenario-widget></summa-project-scenario-widget>
      </div>

      <!-- COLUMN.04 -->
      <div fxLayout="column" fxLayoutGap="16px">
        <!-- CARDS -->
        <summa-project-card-widget></summa-project-card-widget>

        <!-- CONTROLS -->
        <summa-details-tile-widget
          [title]="'dictionary.plural.control' | i18nextCap"
          [counters]="controlCounters$ | async"
          [totalCountLabel]="['dashboard:dashboard.total-number-of' | i18nextCap, 'dictionary.plural.control' | i18next].join(' ')"
          [route]="{destination: ['controls'], tooltip: 'dictionary.view-all' | i18nextCap }"
        >
          <!-- add control button -->
          <mat-icon
            header
            class="create"
            [svgIcon]="'icons.add' | i18next"
            [title]="'reseller:floorplans.add' | i18next"
            [permission-check]="permissions.updatePermission"
            (click)="addControl$.next(null)"
          ></mat-icon>
        </summa-details-tile-widget>

        <!-- AUTOMATION WIDGET -->
        <summa-project-automation-widget> </summa-project-automation-widget>
      </div>
    </div>
  </ng-container>
</ng-container>
