import { Injectable } from '@angular/core';
import { DTO } from '@summa/portal/models/dto';
import { compareVersions, isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { ApplicationQuery, CommandService, GatewayQuery, GatewayService, ProjectQuery } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectGatewayWidgetSandbox {
  project$ = this.projectQuery.selectActive();
  activeGateway$ = this.gatewayQuery.selectActive() as Observable<DTO.Gateway>;
  gateway$: Observable<DTO.Gateway> = combineLatest([this.project$, this.activeGateway$]).pipe(
    filter(([project]) => isNotNullOrUndefined(project?.gatewayKey)),
    map(([project, gateway]) => ({ ...gateway, key: project.gatewayKey })),
  );
  apiVersion$ = this.gateway$.pipe(
    map((gateway) => (isNotNullUndefinedOrEmpty(gateway.applications) ? gateway.applications.find((x) => x.name === 'local-api')?.version : '')),
  );
  application$ = this.applicationQuery.selectActive();

  upgradeRequired$: Observable<boolean> = combineLatest([this.apiVersion$, this.application$]).pipe(
    map(([apiVersion, application]) => application && compareVersions(apiVersion, application.minimumRequiredGatewayApiVersion)),
  );

  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private projectQuery: ProjectQuery,
    private gatewayQuery: GatewayQuery,
    private gatewayService: GatewayService,
    private commandService: CommandService,
    private applicationQuery: ApplicationQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  findGateway(key: string): void {
    this.gatewayService.getGateway(key);
  }

  observeGatewayState(projectKey: string): void {
    this.gatewayService.subscribe([projectKey]);
  }

  updateGateway(projectKey: string): void {
    this.commandService.executeUpdateLocalCommand(projectKey);
  }

  syncGateway(projectKey: string): void {
    this.commandService.executeSyncCommand({ projectKey, subject: 'all', mode: 'full' });
  }

  unsubscribe(): void {
    this.gatewayService.unsubscribe();
  }
}
