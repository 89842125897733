import { Component, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { CONSTANTS } from '@summa/portal/models/constants';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'summa-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimezoneSelectorComponent,
    },
  ],
})
export class TimezoneSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  destroy$ = new Subject<void>();

  public timezones = CONSTANTS.timezones;
  public value = new FormControl('', [Validators.required]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (value: string) => {};
  private _onTouched = () => {};

  timezoneFilter: FormControl;
  filteredTimezones$: ReplaySubject<string[]>;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeTimezoneSelector();
    this.handleFilterTimezones();
    this.handleValueChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  writeValue(obj: string): void {
    this.value.patchValue(obj);
  }

  registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  private initializeTimezoneSelector(): void {
    this.filteredTimezones$ = new ReplaySubject<string[]>(1);
    this.filteredTimezones$.next(this.timezones);
    this.timezoneFilter = this.fb.control('');
  }

  private handleFilterTimezones(): void {
    this.timezoneFilter.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((search) => {
      if (!search) {
        this.filteredTimezones$.next(this.timezones);
        return;
      }

      this.filteredTimezones$.next(this.timezones.filter((timezone) => timezone.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleValueChange(): void {
    this.value.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this._onTouched();
      this._onChange(value);
    });
  }
}
