import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSliderChange } from '@angular/material/slider';
import { DOMAIN, ENV, PERMISSIONS } from '@summa/models';
import { CONSTANTS } from '@summa/portal/models/constants';
import { I18NextCapPipe } from 'angular-i18next';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { SaveSceneDialogComponent, SaveSceneDialogData } from '../../../../../components/save-scene-dialog';
import { CustomerCardSandbox } from '../../../customer-card/customer-card.sandbox';

@Component({
  selector: 'summa-card-detail-tab-labs',
  templateUrl: './card-detail-tab-lab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./card-detail-tab-lab.component.scss'],
  providers: [CustomerCardSandbox],
})
export class CardDetailTabLabsComponent implements OnInit, OnDestroy {
  cctChangedValue$ = new Subject<MatSliderChange>();
  duvChangedValue$ = new Subject<MatSliderChange>();
  dimChangedValue$ = new Subject<MatSliderChange>();
  executeCommand$ = new Subject();

  cctValue$ = new BehaviorSubject<number>(1800);
  duvValue$ = new BehaviorSubject<number>(0);
  dimValue$ = new BehaviorSubject<number>(100);
  destroy$ = new Subject();
  color$ = new BehaviorSubject({
    r: 77,
    g: 53,
    b: 25,
    a: 1,
  });

  red$ = new Subject<MatSliderChange>();
  green$ = new Subject<MatSliderChange>();
  blue$ = new Subject<MatSliderChange>();

  saveLab$ = new Subject<void>();
  confirmDialog: MatDialogRef<SaveSceneDialogComponent, MatDialogConfig>;

  readonly cctSettings = {
    min: 1800,
    max: 10000,
    steps: 50,
    interval: 1000,
  };

  readonly duvSettings = {
    min: -0.006,
    max: 0.006,
    steps: 0.001,
    interval: 1000,
  };

  readonly dimSettings = {
    min: 0,
    max: 100,
    steps: 1,
    interval: 10,
    useNewCalculation: false,
  };

  readonly color = {
    min: 0,
    max: 255,
    steps: 1,
    interval: 1,
  };

  get rgbColor(): string {
    const { r, g, b } = this.color$.value;
    return `r: ${r} <br/> g: ${g} <br/> b: ${b}`;
  }

  readonly debounceTime = 500;

  isPortal = false;

  readonly rndPermission: PERMISSIONS.Permission = {
    application: { name: PERMISSIONS.applicationRnD, qualifiers: [PERMISSIONS.wildcardQualifier] },
    domain: { name: PERMISSIONS.domainProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
    subdomain: null,
    action: PERMISSIONS.actionRead,
  };

  constructor(
    public sandbox: CustomerCardSandbox,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    @Inject('environment') private environment: ENV.Environment,
  ) {
    this.isPortal = this.environment.app.isPortal;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleChangedValue();
    this.handleChannelChange();
    this.handleExecuteCommand();

    if (this.environment.app.isPortal) {
      this.handleSaveLab();
      this.handleSaveSceneSuccess();
    }
  }

  public formatDimValue(input: number): string {
    return CONSTANTS.newDimmingCalculation(input).toFixed(2);
  }

  handleChangedValue(): void {
    this.cctChangedValue$
      .pipe(
        debounceTime(this.debounceTime),
        withLatestFrom(this.sandbox.card$, this.sandbox.params$, this.dimValue$, this.duvValue$),
        takeUntil(this.destroy$),
      )
      .subscribe(([slider, card, { projectKey }, dimValue, duv]) => {
        const value = slider.value ?? 0;
        this.cctValue$.next(value);
        // execute scene activation
        this.sandbox.executeCardCommand(card, 'ON', dimValue, projectKey, value, duv);
      });

    this.dimChangedValue$
      .pipe(
        debounceTime(this.debounceTime),
        withLatestFrom(this.sandbox.card$, this.sandbox.params$, this.cctValue$, this.duvValue$),
        takeUntil(this.destroy$),
      )
      .subscribe(([slider, card, { projectKey }, cct, duv]) => {
        const sliderValue = slider.value ?? 0;
        const value = this.dimSettings.useNewCalculation ? CONSTANTS.newDimmingCalculation(sliderValue) : sliderValue;
        this.dimValue$.next(value);
        // execute scene activation
        this.sandbox.executeCardCommand(card, 'ON', value, projectKey, cct, duv);
      });

    this.duvChangedValue$
      .pipe(
        debounceTime(this.debounceTime),
        withLatestFrom(this.sandbox.card$, this.sandbox.params$, this.cctValue$, this.dimValue$),
        takeUntil(this.destroy$),
      )
      .subscribe(([slider, card, { projectKey }, cct, dim]) => {
        const value = slider.value ?? 0;
        this.duvValue$.next(value);
        // execute scene activation
        this.sandbox.executeCardCommand(card, 'ON', dim, projectKey, cct, value);
      });
  }

  private handleChannelChange(): void {
    this.red$.pipe(debounceTime(this.debounceTime), withLatestFrom(this.color$), takeUntil(this.destroy$)).subscribe(([red, color]) => {
      this.color$.next({ ...color, r: red.value ?? 0 });
      this.executeCommand$.next(null);
    });
    this.green$.pipe(debounceTime(this.debounceTime), withLatestFrom(this.color$), takeUntil(this.destroy$)).subscribe(([green, color]) => {
      this.color$.next({ ...color, g: green.value ?? 0 });
      this.executeCommand$.next(null);
    });
    this.blue$.pipe(debounceTime(this.debounceTime), withLatestFrom(this.color$), takeUntil(this.destroy$)).subscribe(([blue, color]) => {
      this.color$.next({ ...color, b: blue.value ?? 0 });
      this.executeCommand$.next(null);
    });
  }

  private handleExecuteCommand(): void {
    this.executeCommand$
      .pipe(withLatestFrom(this.color$, this.sandbox.card$, this.sandbox.params$, this.dimValue$), takeUntil(this.destroy$))
      .subscribe(([, color, card, { projectKey }, dimValue]) => {
        const { r, g, b } = color;
        this.sandbox.executeCardColorCommand(card, 'ON', projectKey, [r, g, b], dimValue);
      });
  }

  private handleSaveLab(): void {
    this.saveLab$
      .pipe(
        withLatestFrom(this.sandbox.params$, this.cctValue$, this.duvValue$, this.dimValue$),
        map(([, params, cct, duv, dimValue]) => {
          const dialogConfig: MatDialogConfig<SaveSceneDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('customer:lab.save-scene-title'),
              content: `
                ${this.i18next.transform('customer:lab.save-scene-intro')}<br/>
                CCT: ${cct}K<br/>
                DUV: ${duv}<br/>
                Dim value: ${dimValue}<br/>
              `,
              cancelButton: this.i18next.transform('common:buttons.cancel'),
              primaryButton: this.i18next.transform('common:buttons.submit'),
            },
          };

          this.confirmDialog = this.dialog.open(SaveSceneDialogComponent, dialogConfig);
          return this.confirmDialog.componentInstance.confirm
            .pipe(first())
            .subscribe((value: { name: string; description: string; sortOrder: number }) => {
              const sceneInput: DOMAIN.SceneInput = {
                projectKey: params.projectKey,
                name: value.name ?? '',
                description: value.description ?? '',
                sortOrder: value.sortOrder ?? 0,
                default: {
                  dimValue,
                  cct,
                  duv,
                },
              };

              this.sandbox.upsertScene(sceneInput);
              this.confirmDialog.close();
            });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSaveSceneSuccess(): void {
    this.sandbox.updateSceneState$
      .pipe(
        filter((state) => state.isSuccessful),
        withLatestFrom(this.sandbox.activeScene$, this.sandbox.card$),
        takeUntil(this.destroy$),
      )
      .subscribe(([, scene, card]) => {
        this.sandbox.updateCard({ ...card, scenes: [...card.scenes, scene] });
        this.sandbox.resetActiveScene();
      });
  }
}
