import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { SidePanelService } from '@summa/shared/ui/dialogs';
import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { FixtureQuery, FixtureService, FloorplanQuery, FloorplanService, ProjectQuery } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access';

@Injectable()
export class ProjectFloorplansPreviewSandbox {
  project$ = this.projectQuery.selectActive();
  params$ = this.routerQuery.selectParams();
  data$ = this.sidePanelService.getData();
  floorplan$ = this.floorplanQuery.selectActive();
  fixtures$ = this.fixtureQuery.selectAll();
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private sidePanelService: SidePanelService,
    private projectQuery: ProjectQuery,
    private routerQuery: RouterQuery,
    private floorplanService: FloorplanService,
    private floorplanQuery: FloorplanQuery,
    private fixtureService: FixtureService,
    private fixtureQuery: FixtureQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  getFixtures(floorplanId: string): void {
    this.fixtureService.getFixtures(floorplanId);
  }

  reset(): void {
    this.floorplanService.resetActive();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
