import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENV } from '@summa/models';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(@Inject('environment') private environment: ENV.Environment) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.environment.api || !request.url.includes(this.environment.api.httpHost)) {
      return next.handle(request);
    }

    const accessToken = localStorage.getItem(this.environment.token);
    return next.handle(request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } }));
  }
}
