import { gql } from 'apollo-angular';

export const GetInstallersQuery = gql`
  query getInstallers($skip: Int, $take: Int, $projectKey: String!) {
    getInstallers(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      name
      enabled
    }
  }
`;

export const GetInstallerQuery = gql`
  query getInstaller($id: String!) {
    getInstaller(id: $id) {
      id
      name
      enabled
      qrCodeData
      project {
        name
        key
      }
    }
  }
`;
