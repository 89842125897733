import { gql } from 'apollo-angular';

export const gridEyeClusterSubscription = gql`
  subscription observeGridEyeClusters($projects: [String!]!) {
    observeGridEyeClusters(projects: $projects) {
      id
      name
      projectKey
      timestamp
      sensors {
        address
        data
        width
        height
      }
      amounts {
        current
        daily
        weekly
        monthly
        yearly
        allTime
      }
    }
  }
`;
