<section class="log-row mb-4" *ngFor="let log of cardLogs$ | async">
  <!-- CARD INFO -->
  <section fxLayout="row wrap">
    <div class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.mac-address' | i18nextCap }}</div>
      <div>{{ log.topic }}</div>
    </div>
    <div class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.ip' | i18nextCap }}</div>
      <div>{{ log.ip }}</div>
    </div>
    <div class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'dashboard:dashboard.last-logging' | i18nextCap }}</div>
      <div>{{ log.timestamp * 1000 | date: 'mediumTime' }}</div>
    </div>
    <div class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.type' | i18nextCap }}</div>
      <div>{{ log.type }}</div>
    </div>
    <div class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.version' | i18nextCap }}</div>
      <div>{{ log.version }}</div>
    </div>
    <div *ngIf="log.temp as temp" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.temp' | i18nextCap }}</div>
      <div>{{ temp }}</div>
    </div>
    <div *ngIf="log.dimLevel as dimLevel" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.dimLevel' | i18nextCap }}</div>
      <div>{{ dimLevel }}</div>
    </div>
    <div *ngIf="log.fadeRate as fadeRate" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.fadeRate' | i18nextCap }}</div>
      <div>{{ fadeRate }}</div>
    </div>
    <div *ngIf="log.walkTime as walkTime" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.walkTime' | i18nextCap }}</div>
      <div>{{ walkTime }}</div>
    </div>
    <div *ngIf="log.amountCh as amountCh" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.amountCh' | i18nextCap }}</div>
      <div>{{ amountCh }}</div>
    </div>
    <div *ngIf="log.ledVolt as ledVolt" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.led-volt' | i18nextCap }}</div>
      <div>{{ ledVolt }}</div>
    </div>
    <div *ngIf="log.ledCurr as ledCurr" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'domain.led-current' | i18nextCap }}</div>
      <div>{{ ledCurr }}</div>
    </div>
  </section>

  <section fxLayout="row wrap">
    <div *ngFor="let channel of log.channels" class="item" fxFlex.xs="50" fxFlex.gt-xs="33">
      <div class="title">{{ 'dashboard:dashboard.channel' | i18nextCap }}</div>
      <div fxLayout="row wrap">
        <div class="sub-title" fxFlex="40">
          {{ 'domain.address' | i18nextCap }}
        </div>
        <div fxFlex="60">
          {{ channel.id }}
        </div>
        <div class="sub-title" fxFlex="40">
          {{ 'domain.intensity' | i18nextCap }}
        </div>
        <div fxFlex="60">
          {{ channel.intensity }}
        </div>
        <div class="sub-title" fxFlex="40">
          {{ 'domain.current' | i18nextCap }}
        </div>
        <div fxFlex="60">
          {{ channel.cu }}
        </div>
        <div class="sub-title" fxFlex="40">
          {{ 'domain.off-time' | i18nextCap }}
        </div>
        <div fxFlex="60">
          {{ channel.off }}
        </div>
        <div class="sub-title" fxFlex="40">
          {{ 'domain.volt' | i18nextCap }}
        </div>
        <div fxFlex="60">
          {{ channel.volt }}
        </div>
      </div>
    </div>
  </section>
</section>
