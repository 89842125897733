import { APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { defaultInterpolationFormat, I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import * as LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import { CONSTANTS } from '@summa/models';
import { TRANSLATION_OPTIONS } from './shared-util-translations.constants';
import { TranslationOptions } from './shared-util-translations.module';

/*
 * Platform and Environment providers/directives/pipes
 */
const i18nextOptions = {
  whitelist: Object.values(CONSTANTS.Languages),
  fallbackLng: 'en',
  debug: false, // true, // set debug?
  returnEmptyString: true,
  ns: ['common', 'component', 'customer', 'dashboard', 'message', 'reseller', 'project', 'user-management'],
  defaultNS: 'common',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },
  // backend plugin options
  backend: {
    loadPath: 'locales/{{lng}}/{{ns}}.json',
  },
  // lang detection plugin options
  detection: {
    // order and from where user language should be detected
    order: ['localStorage', 'cookie'],

    // keys or params to lookup language from
    lookupCookie: 'lang',

    // cache user language on
    caches: ['localStorage', 'cookie'],

    // optional expire and domain for set cookie
    cookieMinutes: 10080, // 7 days
    cookieDomain: 'I18NEXT_LANG_COOKIE_DOMAIN',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function appInit(i18next: ITranslationService, translationOptions: TranslationOptions): any {
  const options = i18nextOptions;
  if (translationOptions && translationOptions.useCacheBuster && translationOptions.version) {
    options.backend.loadPath = `locales/{{lng}}/{{ns}}.json?v=${translationOptions.version}`;
  }
  return () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return i18next.use(XHR).use<any>(LanguageDetector).init(options);
  };
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE, TRANSLATION_OPTIONS],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
