<div
  *ngIf="sandbox.filteredClient$ | async as client"
  class="p-4"
  fxLayout="row"
  fxLayoutGap="16px"
  fxLayoutAlign="center start"
  fxLayout.lt-sm="column"
>
  <ng-container
    *ngIf="{
  updatePermission: sandbox.updateClientPermission$ | async
} as permissions"
  >
    <!-- COLUMN.01 -->
    <div fxLayout="column" fxLayoutGap="16px">
      <!-- INFORMATION -->
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card sm p-2">
          <div class="header">
            {{ 'reseller:clients.info-title' | i18nextCap}}
            <div
              class="action clickable"
              [title]="'reseller:clients.edit-client' | i18nextCap"
              [permission-check]="permissions.updatePermission"
              (click)="upsertClientPanel$.next(client); $event.stopPropagation()"
            >
              <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
            </div>
          </div>
          <!-- Info -->
          <div class="info entry">
            <div class="title left">{{'common:dictionary.name' | i18nextCap}}</div>
            <div class="title right">{{client.name}}</div>
          </div>
          <!-- Info -->
          <div class="info entry">
            <div class="title left">{{'common:dictionary.contact' | i18nextCap}}</div>
            <div class="title right">{{client.contact?.name}}</div>
          </div>
          <!-- Info -->
          <div class="info entry">
            <div class="title left">{{'common:dictionary.email' | i18nextCap}}</div>
            <div class="title right">{{client.contact?.email}}</div>
          </div>
          <!-- Info -->
          <div class="info entry">
            <div class="title left">{{'common:dictionary.phone' | i18nextCap}}</div>
            <div class="title right">{{client.contact?.phone || 'n.a.'}}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- COLUMN.02 -->
    <div fxLayout="column" fxLayoutGap="16px">
      <!-- LOCATION -->
      <div *ngFor="let location of client.locations" fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card md p-2">
          <div class="header">
            {{location.name}}
            <div
              [permission-check]="permissions.updatePermission"
              class="action clickable"
              [title]="'reseller:clients.edit-location' | i18nextCap"
              (click)="upsertLocationPanel$.next(location); $event.stopPropagation()"
            >
              <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutGap="16px">
            <!-- COLUMN.01 | INFORMATION -->
            <div fxLayout="column" fxLayoutGap="16px" fxFlex="1 0 calc(50%-32px)">
              <!-- Info -->
              <div class="info" fxLayout="row wrap" fxLayoutGap="16px">
                <div class="key">{{'common:dictionary.address' | i18nextCap}}</div>
                <div class="value">{{location.street}} {{location.number}}</div>
              </div>
              <!-- Info -->
              <div class="info" fxLayout="row wrap" fxLayoutGap="16px">
                <div class="key">{{'reseller:clients.zipcode-and-city' | i18nextCap}}</div>
                <div class="value">{{location.zipCode}} {{location.city}}</div>
              </div>
              <!-- Info -->
              <div class="info" fxLayout="row wrap" fxLayoutGap="16px">
                <div class="key">{{'reseller:clients.state-or-province' | i18nextCap}}</div>
                <div class="value">{{location.state}}</div>
              </div>
              <!-- Info -->
              <div class="info" fxLayout="row wrap" fxLayoutGap="16px">
                <div class="key">{{'common:dictionary.country' | i18nextCap}}</div>
                <div class="value">{{location.country}}</div>
              </div>
            </div>
            <!-- COLUMN.02 | PROJECTS -->
            <div fxLayout="column" fxLayoutGap="16px" fxFlex="1 0 50%">
              <div
                fxFlexAlign="start center"
                *ngFor="let project of location.projects"
                class="client-project entry state success p-3 clickable"
                (click)="navigateTo(project.key)"
              >
                <div fxFlex class="name">{{project.name}}</div>
                <mat-icon
                  class="clickable-icon"
                  [title]="'reseller:clients.navigate-to-dashboard' | i18nextCap"
                  fxFlexAlign="end center"
                  [svgIcon]="'common:icons.dashboard' | i18next"
                  (click)="openTab($event, project.key)"
                ></mat-icon>
              </div>
              <ng-container *ngIf="sandbox.params$ | async as params">
                <div
                  [permission-check]="permissions.updatePermission"
                  class="add-project clickable-no-hover"
                  [routerLink]="['/reseller', params.resellerId, 'projects', 'add']"
                  [queryParams]="{ clientId: params.clientKey, locationId: location.id }"
                >
                  <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
                  <div class="info">{{ 'reseller:clients.add-project' | i18nextCap}}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div
        class="add-location clickable-no-hover"
        [permission-check]="permissions.updatePermission"
        (click)="upsertLocationPanel$.next(null); $event.stopPropagation()"
      >
        <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
        <div class="info">{{ 'reseller:clients.add-location' | i18nextCap}}</div>
      </div>
    </div>
  </ng-container>
</div>
