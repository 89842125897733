import { gql } from 'apollo-angular';

export const GetDriverListQuery = gql`
  query getDrivers($skip: Int, $take: Int, $projectKey: String) {
    getDrivers(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      key
      type
      projectKey
      state
      address
    }
  }
`;

export const GetDriverDeviceListQuery = gql`
  query getDrivers($skip: Int, $take: Int, $projectKey: String) {
    getDrivers(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      key
      type
      projectKey
      state
      address
      devices {
        id
        key
        address
        type
        category
      }
    }
  }
`;

export const GetDriverOverviewQuery = gql`
  query getDrivers($skip: Int, $take: Int, $projectKey: String) {
    getDrivers(skip: $skip, take: $take, projectKey: $projectKey) {
      key
      type
      projectKey
      state
      address
      devices {
        key
        type
      }
      provisioningLog {
        timestamp
        provisioningLog
      }
    }
  }
`;

export const GetDriversQuery = gql`
  query getDrivers($skip: Int, $take: Int, $projectKey: String) {
    getDrivers(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      key
      type
      projectKey
      # batch
      # fabCode
      # dateCode
      address
      # settings
      state
      devices {
        id
        key
        address
        type
        # category
        # settings
        # locationKey
      }
      provisioningLog {
        timestamp
        provisioningLog
      }
    }
  }
`;

export const GetDriverQuery = gql`
  query getDriver($key: String, $address: String) {
    getDriver(key: $key, address: $address) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
      lastDriverLog
      provisioningLog {
        address
        project
        timestamp
        provisioningLog
      }
      cards {
        title
        sortOrder
        hidden
      }
    }
  }
`;

export const GetAssemblyDriverQuery = gql`
  query getAssemblyDriver($key: String, $address: String) {
    getAssemblyDriver(key: $key, address: $address) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
      }
    }
  }
`;
