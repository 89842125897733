import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { AutomationState, AutomationStore } from './automation.store';

@Injectable({
  providedIn: 'root',
})
export class AutomationQuery extends QueryEntity<AutomationState, DOMAIN.Automation> {
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: AutomationStore) {
    super(store);
  }
}
