import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { CardQuery, CardService } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectCardOverviewSandbox {
  cards$ = this.cardQuery.selectAll();
  params$ = this.router.params;
  removeState$ = this.cardQuery.selectRemoveState$;
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private cardService: CardService,
    private cardQuery: CardQuery,
    private router: ActivatedRoute,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  getCards(projectKey: string): void {
    this.cardService.getCardOverview(projectKey);
  }

  getCard(cardId: string): void {
    this.cardService.getCardPreview(cardId);
  }

  removeCard(id: string): void {
    this.cardService.remove(id);
  }

  reset(): void {
    this.cardService.reset();
  }
}
