<ng-container *ngIf="sandbox.driver$ | async as driver">
  <div class="notification-info m-3 mb-0" fxLayout="row wrap" fxLayoutGap="2rem">
    <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
      <div class="title" fxFlex="55">{{ 'common:dictionary.batch' | i18nextCap }}</div>
      <div class="content" fxFlex="45">{{ driver.batch }}</div>
    </div>
    <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
      <div class="title" fxFlex="55">{{ 'common:dictionary.fab-code' | i18nextCap }}</div>
      <div class="content" fxFlex="45">{{ driver.fabCode }}</div>
    </div>
    <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
      <div class="title" fxFlex="55">{{ 'common:dictionary.date-code' | i18nextCap }}</div>
      <div class="content" fxFlex="45">{{ driver.dateCode }}</div>
    </div>
    <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
      <div class="title" fxFlex="55">{{ 'common:dictionary.channel_plural' | i18nextCap }}</div>
      <div class="content" fxFlex="45">{{ driver.settings?.channels }}</div>
    </div>
  </div>

  <!-- INFORMATION -->
  <mat-accordion displayMode="flat" multi="true">
    <!-- ENTITIES -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.preview.info.entity_plural' | i18nextCap | uppercase }}</mat-panel-title>
        <mat-panel-description>
          {{ driver.devices.length }} {{ 'project:drivers.preview.info.entity' | i18nextCap: { count: driver.devices.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="driver.devices" fxFill>
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef>{{ 'dictionary.key' | i18nextCap }}</th>
          <td mat-cell class="highlight" *matCellDef="let entity">{{ entity.key }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>{{ 'dictionary.address' | i18nextCap }}</th>
          <td mat-cell *matCellDef="let entity">{{ entity.address }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ 'dictionary.type' | i18nextCap }}</th>
          <td mat-cell *matCellDef="let entity">{{ entity.type }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="entityColumnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: entityColumnsToDisplay"></tr>
      </table>
    </mat-expansion-panel>
    <!-- PROVISIONING -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.preview.info.provisioning' | i18nextCap | uppercase }} </mat-panel-title>
        <mat-panel-description *ngIf="driver.provisioningLog as provisioningLog">
          <ng-container *ngIf="!provisioningLog.provisioningLog.error; else provisioningErrorDescription">
            {{ provisioningLog.timestamp | date: 'short' }}
          </ng-container>
          <ng-template #provisioningErrorDescription>
            <span class="text-danger">{{ 'project:drivers.preview.info.provisioning-error' | i18nextCap }}</span>
          </ng-template>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div fxFill fxLayout="row wrap" fxLayoutGap="2rem">
        <ng-container *ngIf="driver.provisioningLog as provisioningLog; else noProvisioningFound">
          <ng-container *ngIf="!provisioningLog.provisioningLog.error; else provisioningError">
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.project' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.project }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex="100" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.key' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.provisioningLog.project_id }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.wifi' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.provisioningLog.wifiSsid }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'project:drivers.preview.info.last-logging' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.timestamp | date: 'short' }}</div>
            </div>
          </ng-container>
          <ng-template #provisioningError>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.message' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.provisioningLog.error.message }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.error' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.provisioningLog.error.error }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'common:dictionary.source' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.provisioningLog.source }}</div>
            </div>
            <div class="item mr-0" fxLayout="row wrap" fxFlex.gt-md="calc(50%-2rem)">
              <div class="title" fxFlex="35">{{ 'project:drivers.preview.info.last-logging' | i18nextCap }}</div>
              <div class="content" fxFlex="65">{{ provisioningLog.timestamp | date: 'short' }}</div>
            </div>
          </ng-template>
        </ng-container>

        <ng-template #noProvisioningFound> {{ 'project:drivers.preview.info.provisioning-404' | i18nextCap }} </ng-template>
      </div>
    </mat-expansion-panel>
    <!-- LOGS -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'project:drivers.preview.info.last-logging' | i18nextCap | uppercase }}</mat-panel-title>
        <mat-panel-description>
          <ng-container *ngIf="driver.lastDriverLog as driverLog; else noDriverLogFound">
            {{ driverLog.timestamp * 1000 | date: 'short' }}
          </ng-container>
          <ng-template #noDriverLogFound>
            <span class="text-danger">{{ 'project:drivers.preview.info.last-logging-error' | i18nextCap }}</span>
          </ng-template>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div fxFill fxLayout="row wrap" fxLayoutGap="2rem" *ngIf="sandbox.driverLog$ | async as driverLog">
        <!-- CHANNELS -->
        <section [fxFlex]="100" *ngFor="let channel of driverLog.channels">
          <div fxFlex="100" fxFlex.gt-md="calc(25%-2rem)">
            <strong>{{ channel.id }}</strong>
          </div>
          <div *ngFor="let prop of channel.properties" class="item mr-0" fxLayout="row wrap" fxFlex="100" fxFlex.gt-md="calc(25%-2rem)">
            <div class="title" fxFlex="35" [title]="prop.name">{{ prop.name }}</div>
            <div class="content" fxFlex="65">{{ prop.value }}</div>
          </div>
        </section>
        <!-- REST -->
        <div *ngFor="let prop of driverLog.properties" class="item mr-0 mt-2" fxLayout="row wrap" fxFlex="50" fxFlex.gt-md="calc(50%-2rem)">
          <div class="title" fxFlex="35" [title]="prop.name">{{ prop.name }}</div>
          <div class="content" fxFlex="65">{{ prop.value }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
