<h1 class="mb-0" mat-dialog-title *ngIf="dialogData.title">
  {{ dialogData.title }}
</h1>

<mat-progress-bar class="mb-2" mode="indeterminate" *ngIf="(state$ | async).loading"></mat-progress-bar>
<div [ngSwitch]="currentStep$ | async" mat-dialog-content class="sensor-baseline-modal__content">
  <!-- STEP 1: Select reason -->
  <ng-container *ngSwitchCase="0">
    <p [innerHTML]="'project:drivers.actions.general.reason-content' | i18nextCap"></p>

    <section fxLayout="row wrap" fxLayoutGap="16px">
      <section
        class="action-item clickable"
        (click)="reason$.next('defect')"
        fxFlex.xs="90"
        fxFlex.gt-xs="40"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div class="title">
          {{ 'project:drivers.actions.general.reason-defect' | i18nextCap }}
        </div>
      </section>

      <section
        class="action-item clickable"
        (click)="reason$.next('replace')"
        fxFlex.xs="90"
        fxFlex.gt-xs="40"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div class="title">{{ 'project:drivers.actions.general.reason-replaced' | i18nextCap }}</div>
      </section>
    </section>
  </ng-container>

  <!-- STEP 2: New driver -->
  <ng-container *ngSwitchCase="1">
    <div class="alert alert-danger mb-2" *ngIf="(state$ | async).errors as errors">
      {{ 'project:drivers.actions.general.error.' + errors[0].message.split(':')[0] | i18nextCap }}
    </div>

    <mat-form-field appearance="legacy">
      <mat-label> {{ 'project:drivers.actions.general.scan-content' | i18nextCap }} </mat-label>
      <input #scanInput matInput (blur)="newDriver$.next()" />
      <mat-icon matSuffix svgIcon="qrcode-scan"></mat-icon>
      <mat-hint *ngIf="error$ | async as error; else showHint" class="error">{{ 'project:drivers.actions.general.' + error | i18nextCap }}</mat-hint>
      <ng-template #showHint>
        <mat-hint>{{ 'project:drivers.actions.general.scan-info' | i18nextCap }}</mat-hint>
      </ng-template>
    </mat-form-field>
  </ng-container>

  <!-- Default -->
  <ng-container *ngSwitchDefault></ng-container>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ dialogData.cancelButton }}
  </button>

  <ng-container *ngIf="currentStep$ | async as step">
    <button mat-stroked-button *ngIf="step > 0" (click)="currentStep$.next(step - 1)">
      {{ 'buttons.previous-step' | i18nextCap }}
    </button>

    <button mat-stroked-button color="primary" *ngIf="step === 1" [disabled]="(state$ | async).loading">
      {{ 'buttons.save' | i18nextCap }}
    </button>
  </ng-container>
</div>
