export interface GridEyeCluster {
  id: string;
  name: string;
  projectKey: string;
  timestamp: number; // Unix timestamp
  sensors: GridEyeSensor[]; // always horizontal  array[0]  =>  array[1] => array[2], unless something else is needed (maybe nestedArray)
  amounts: GridEyeAmount;
}

export interface GridEyeSensor {
  address: string; // Mac-address
  data: number[]; // Already configured horizontal aligned ()
  width: number;
  height: number;
}

export interface GridEyeAmount {
  current: number;
  daily: number;
  weekly: number;
  monthly: number;
  yearly: number;
  allTime: number;
}

export const gridEyeKey = (projectKey: string, address: string): string => `grid-eye:${projectKey}:${address}`;
