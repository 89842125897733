import { InjectionToken } from '@angular/core';
import { DOMAIN } from '@summa/models';
import { Observable } from 'rxjs';

export const AUTHENTICATION_SERVICE = new InjectionToken<AuthenticationService>('authentication-service');

export interface AuthenticationService {
  currentUser$: Observable<DOMAIN.User>;
  isLoggedIn: () => boolean;
  getCurrentUser: () => void;
  // login: (loginInput: API.LoginInput) => void;
  logOut: () => void;
}
