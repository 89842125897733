<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$|async) ? 'reseller:clients.edit-location' :'reseller:clients.add-location') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section *ngIf="sandbox.client$ |async as client" class="section">
      <div>
        <label fxFlex="15">{{'common:dictionary.reseller' | i18nextCap}}</label>
        <div fxFlex="auto">{{ client.name}}</div>
      </div>
      <div>
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ client.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section class="location-info">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field class="street" appearance="legacy">
          <mat-label>{{'common:dictionary.street' | i18nextCap}}</mat-label>
          <input matInput formControlName="street" />
        </mat-form-field>
        <mat-form-field class="house-number" appearance="legacy">
          <mat-label>{{'common:dictionary.housenumber' | i18nextCap}}</mat-label>
          <input matInput formControlName="number" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.zipcode' | i18nextCap}}</mat-label>
          <input matInput formControlName="zipCode" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.city' | i18nextCap}}</mat-label>
          <input matInput formControlName="city" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'reseller:clients.state-or-province' | i18nextCap}}</mat-label>
          <input matInput formControlName="state" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.country' | i18nextCap}}</mat-label>
          <input matInput formControlName="country" />
        </mat-form-field>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxFlex="100" fxLayoutGap="10px">
      <button mat-button type="button" (click)="sandbox.close()" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="!form || form.invalid" mat-button (click)="submit$.next(null)" class="button save">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
