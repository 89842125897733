import { Routes as RouterRoutes } from '@angular/router';
import { PermissionGuard } from '@summa/shared/dashboard/util';
import { PERMISSIONS } from '@summa/models';
import { ResellerClientDetailsPage } from './clients/client-details/reseller-client-details.page';
import { ResellerClientOverviewPage } from './clients/client-overview/reseller-client-overview.page';
import { ResellerDashboardPage } from './dashboard/reseller-dashboard.page';
import { ResellerOverviewPage } from './overview/reseller-overview.page';
import { ResellerShellPage } from './reseller-shell.page';
import { SummaPortalProjectsModule } from '../projects';
import { SummaPortalUserManagementModule } from '../user-management';
import { SummaPortalNotificationsModule } from '../notifications';

export const Routes: RouterRoutes = [
  {
    path: '',
    component: ResellerShellPage,
    data: {
      breadcrumb: 'OEM | System Integrator',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ResellerOverviewPage,
      },
      {
        canActivate: [PermissionGuard],
        data: {
          tag: 'reseller-id',
          permissions: {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            application: { name: PERMISSIONS.applicationReseller, qualifiers: [':resellerId'] },
            action: PERMISSIONS.actionRead,
          },
          application: PERMISSIONS.applicationReseller,
        },
        path: ':resellerId',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: ResellerDashboardPage,
            data: {
              breadcrumb: {
                alias: 'resellerName',
              },
            },
          },
          {
            path: 'projects',
            data: {
              breadcrumb: 'Projects',
            },
            loadChildren: () => SummaPortalProjectsModule,
          },
          {
            path: 'user-management',
            canActivate: [PermissionGuard],
            loadChildren: () => SummaPortalUserManagementModule,
            data: {
              tag: 'user-management',
              breadcrumb: 'User management',
              permissions: {
                application: { name: PERMISSIONS.applicationReseller, qualifiers: [':resellerId'] },
                domain: { name: PERMISSIONS.domainUserManagement, qualifiers: [PERMISSIONS.wildcardQualifier] },
                action: PERMISSIONS.actionRead,
              },
              application: PERMISSIONS.applicationReseller,
            },
          },
          {
            path: 'notifications',
            loadChildren: () => SummaPortalNotificationsModule,
          },
          {
            path: 'clients',
            children: [
              {
                data: {
                  breadcrumb: 'Clients',
                },
                path: '',
                component: ResellerClientOverviewPage,
              },
              {
                path: ':clientKey',
                children: [{ path: '', component: ResellerClientDetailsPage }],
              },
            ],
          },
        ],
      },
    ],
  },
];
