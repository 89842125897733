import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ControlPanelPreviewSandbox } from './control-panel-preview.sandbox';

@Component({
  selector: 'summa-control-preview',
  templateUrl: './control-panel-preview.page.html',
  styleUrls: ['./control-panel-preview.page.scss'],
  providers: [ControlPanelPreviewSandbox],
})
export class ControlPanelPreviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();

  currentRoute: string;

  constructor(public sandbox: ControlPanelPreviewSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.resetActive();
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url.includes('control-panels') ? this.router.url : `${this.router.url}/control-panels`;
  }

  closePanel(): void {
    this.sandbox.resetActive();
    this.sidePanelService.close();
  }
}
