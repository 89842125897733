/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CONSTANTS } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ControlPanelStore } from './control-panel.store';
import { ControlPanelSubscription, findControlPanelQuery, findControlPanelsQuery, findLocalControlPanelsQuery } from './graphql';
import {
  createControlPanelQuery,
  executeControlPanelCommandQuery,
  patchControlPanelQuery,
  removeControlPanelControl,
  removeControlPanelQuery,
  upsertControlPanelControl,
} from './graphql/control-panel.gql-mutation';

@Injectable({
  providedIn: 'root',
})
export class ControlPanelService {
  subscriptions: Subscription | undefined;
  successfulAddedMessage = 'message:successful-updated';

  constructor(
    private apollo: Apollo,
    private i18next: I18NextCapPipe,
    private notifier: NotifierService,
    private controlPanelStore: ControlPanelStore,
  ) {}

  reset(): void {
    this.controlPanelStore.reset();
  }

  resetActive(): void {
    this.controlPanelStore.resetUIState();
    this.subscriptions?.unsubscribe();
    this.setActive(null);
  }

  resetUiState(): void {
    this.controlPanelStore.resetUIState();
  }

  setActive(id: string | null): void {
    this.controlPanelStore.setActive(id);
  }

  findControlPanel(id: string): void {
    this.apollo
      .subscribe({ query: findControlPanelQuery, variables: { id } })
      .pipe(
        map((d: any) => d.data.findControlPanel),
        catchError(() => of(null)),
      )
      .subscribe((controlPanel) => {
        if (controlPanel) {
          this.controlPanelStore.upsert(controlPanel.id, controlPanel);
          this.controlPanelStore.setActive(controlPanel.id);
        }
      });
  }

  getControlPanels(projectKey: string, skip?: number, take?: number): void {
    this.apollo
      .subscribe({ query: findControlPanelsQuery, variables: { projectKey, skip, take } })
      .pipe(map((d: any) => d.data.findControlPanels))
      .subscribe((pagination) =>
        pagination && pagination.result.length > 0 ? this.controlPanelStore.set(pagination.result) : this.controlPanelStore.reset(),
      );
  }

  getLocalControlPanels(skip?: number, take?: number): void {
    this.apollo
      .subscribe({ query: findLocalControlPanelsQuery, variables: { skip, take } })
      .pipe(map((d: any) => d.data.findControlPanels))
      .subscribe((controlPanels) =>
        controlPanels && controlPanels.length > 0 ? this.controlPanelStore.set(controlPanels) : this.controlPanelStore.reset(),
      );
  }

  remove(id: string): void {
    this.controlPanelStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeControlPanelQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlPanelStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage =
          results.errors && results.errors.length > 0 && results.errors[0].message
            ? results.errors[0]?.message
            : CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control ${id}` }));

        this.controlPanelStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.controlPanelStore.remove(id);

      // set success;
      this.controlPanelStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }

  patchControlPanel(controlPanelInput: Partial<DTO.ControlPanelInput>): void {
    this.controlPanelStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: patchControlPanelQuery, variables: { controlPanel: controlPanelInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlPanelStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage =
          results.errors && results.errors.length > 0 && results.errors[0].message
            ? results.errors[0]?.message
            : CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control-panel ${controlPanelInput.name}` }));

        this.controlPanelStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { patchControlPanel: DTO.ControlPanel };
      this.controlPanelStore.upsert(data.patchControlPanel.id, data.patchControlPanel);
      this.controlPanelStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfulAddedMessage));
    });
  }

  createControlPanel(controlPanelInput: DTO.ControlPanelInput): void {
    this.controlPanelStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: createControlPanelQuery, variables: { controlPanel: controlPanelInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlPanelStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage =
          results.errors && results.errors.length > 0 && results.errors[0].message
            ? results.errors[0]?.message
            : CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control-panel ${controlPanelInput.name}` }));

        this.controlPanelStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { createControlPanel: DTO.ControlPanel };
      this.controlPanelStore.upsert(data.createControlPanel.id, data.createControlPanel);
      this.controlPanelStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfulAddedMessage));
    });
  }

  upsertControlPanelControl(id: string, controlPanelControl: DTO.ControlPanelControlInput): void {
    this.controlPanelStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertControlPanelControl, variables: { id, controlPanelControl } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlPanelStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage =
          results.errors && results.errors.length > 0 && results.errors[0].message
            ? results.errors[0]?.message
            : CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control-panel control ${controlPanelControl.name}` }));

        this.controlPanelStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertControlPanelControl: DTO.ControlPanel };
      this.controlPanelStore.upsert(data.upsertControlPanelControl.id, data.upsertControlPanelControl);
      this.controlPanelStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfulAddedMessage));
    });
  }

  removeControlPanelControl(id: string, controlPanelId: string): void {
    this.controlPanelStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeControlPanelControl, variables: { id, controlPanelId } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlPanelStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage =
          results.errors && results.errors.length > 0 && results.errors[0].message
            ? results.errors[0]?.message
            : CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control-panel control ${id}` }));

        this.controlPanelStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { removeControlPanelControl: DTO.ControlPanel };
      this.controlPanelStore.upsert(data.removeControlPanelControl.id, data.removeControlPanelControl);
      this.controlPanelStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfulAddedMessage));
    });
  }

  executeControlPanelCommand(command: DTO.ControlPanelCommandInput): void {
    this.apollo.mutate({ mutation: executeControlPanelCommandQuery, variables: { command } }).subscribe();
  }

  subscribe(id: string): void {
    this.unsubscribe();
    this.subscriptions = this.apollo.subscribe({ query: ControlPanelSubscription, variables: { id } }).subscribe((log) => {
      const updatedControlPanel = (log.data as { observeControlPanel: DTO.ControlPanel }).observeControlPanel;
      this.controlPanelStore.upsert(updatedControlPanel.id, updatedControlPanel);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
