/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DOMAIN } from '@summa/models';
import { Subject, takeUntil } from 'rxjs';
import { ControlCardsAddSandbox } from './control-cards.sandbox';

@Component({
  selector: 'summa-control-cards',
  templateUrl: './control-cards.component.html',
  styleUrls: ['./control-cards.component.scss'],
  providers: [
    ControlCardsAddSandbox,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ControlCardsComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlCardsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private destroy$ = new Subject<void>();

  get groupsFormArray(): FormArray {
    return this.form.get('groups') as FormArray;
  }

  choiceGroups = 'groups';
  choiceScenario = 'scenario';
  form: FormGroup = new FormGroup({
    choice: new FormControl(this.choiceGroups),
    scenario: new FormControl(''),
    groups: new FormArray([]),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: any) => {};
  private onTouched: () => void = () => {};

  constructor(public sandbox: ControlCardsAddSandbox, private fb: FormBuilder) {}

  ngOnInit(): void {
    // this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
    //   this.sandbox.getGroups(projectKey);
    //   this.sandbox.getScenarios(projectKey);
    // });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.onChange(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addGroup(): void {
    this.groupsFormArray.push(this.createGroup());
  }

  removeGroup(index: number): void {
    this.groupsFormArray.removeAt(index);
  }

  getGroupScenes(index: number, groups: DOMAIN.Card[]): DOMAIN.Scene[] {
    const groupId = this.groupsFormArray.controls.at(index).value?.group;
    return groupId ? groups.find((g) => g.id === groupId).scenes : [];
  }

  writeValue(value: any) {
    this.form.patchValue({
      choice: value?.choice ?? '',
      scenario: value?.scenario ?? '',
    });
    value?.groups.forEach((element) => this.groupsFormArray.push(this.fb.group(element)));
  }

  registerOnChange(fn: (v: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  private createGroup(value?: DOMAIN.ControlCard): FormGroup {
    return this.fb.group({
      group: [value?.group ?? ''],
      scene: [value?.scene ?? ''],
    });
  }
}
