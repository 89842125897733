import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { ClientQuery, ClientService, ResellerQuery, ResellerService } from '../../../../data-access';

@Injectable()
export class ResellerClientAddSandbox {
  params$ = this.router.selectParams();
  client$ = this.clientQuery.selectActive();
  reseller$ = this.resellerQuery.selectActive();
  upsertSuccess$ = this.clientQuery.selectUpsertState$.pipe(map((state) => state.isSuccessful));
  data$ = this.sidePanelService.getData();

  constructor(
    private sidePanelService: SidePanelService,
    private clientService: ClientService,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private clientQuery: ClientQuery,
    private router: RouterQuery,
  ) {}

  getClient(id: string): void {
    this.clientService.getClient(id);
  }

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;

    this.resellerService.getReseller(id);
  }

  upsertClient(client: PortalAPI.ClientInput): void {
    this.clientService.upsertClient(client);
  }

  setHasChanges(hasChanges: boolean): void {
    this.sidePanelService.setHasChanges(hasChanges);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.clientService.resetClientUIState();
  }
}
