import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { filter, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { isNotNullOrUndefined, isNullOrUndefined } from '@summa/shared/util/typescript';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { DTO } from '@summa/portal/models/dto';
import { ProjectDriverPreviewPage } from '../preview/project-driver-preview.page';

import { ProjectDriverOverviewSandbox } from './project-driver-overview.sandbox';

@Component({
  selector: 'summa-project-driver-overview',
  templateUrl: './project-driver-overview.page.html',
  styleUrls: ['./project-driver-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectDriverOverviewSandbox],
})
export class ProjectDriverOverviewPage implements OnInit, OnDestroy {
  remove$ = new Subject<DTO.Driver>();
  detailsPanel$ = new Subject<DTO.Driver>();
  syncDrivers$ = new Subject<void>();
  drivers = new MatTableDataSource<DTO.Driver>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  columnsToDisplay = ['id', 'address', 'type', 'state', 'provisioning-state', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  destroy$ = new Subject();

  constructor(
    public sandbox: ProjectDriverOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.resetDrivers();
  }

  ngOnInit(): void {
    this.handleDetailsPanel();
    this.handleParams();
    this.handleDrivers();
    this.handleRemove();
    this.handleSyncDrivers();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.drivers.filter = filterValue.trim().toLowerCase();
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getDrivers(projectKey);
      this.sandbox.observeFixtureState(projectKey);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((driver: DTO.Driver) => {
      this.sandbox.getDriver(driver.key);
      this.sidePanelService.open(ProjectDriverPreviewPage);
    });
  }

  handleDrivers(): void {
    // SETUP TABLE
    this.sandbox.drivers$.pipe(takeUntil(this.destroy$)).subscribe((drivers: DTO.Driver[]) => {
      this.drivers.data = drivers;
      this.drivers.sort = this.sort;
      this.drivers.paginator = this.paginator;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.drivers.sortingDataAccessor = (item: any, property) => {
        if (property === 'provisioning-state') {
          return isNotNullOrUndefined(item?.provisioningLog?.timestamp) && isNullOrUndefined(item.provisioningLog?.provisioningLog?.error)
            ? item.provisioningLog.timestamp
            : -1;
        }
        return item[property];
      };
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((driver: any) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('reseller:drivers.overview.confirm-remove.title'),
          content: this.i18next.transform('reseller:drivers.overview.confirm-remove.content', { field: driver.address }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([, params]) => {
        const { state, ...removedDriver } = driver;
        this.sandbox.removeDriverFromProject(removedDriver, params.projectKey);
        this.sandbox.resetActive();
        this.confirmDialog.close();
      });
    });
  }

  private handleSyncDrivers(): void {
    this.syncDrivers$
      .pipe(
        withLatestFrom(this.sandbox.params$),
        filter(([, params]) => isNotNullOrUndefined(params)),
      )
      .subscribe(([, params]) => {
        this.sandbox.syncDriversByProjectKey(params.projectKey);
      });
  }
}
