import { Injectable } from '@angular/core';

import { SceneQuery, SceneService } from '../../../../data-access';

@Injectable()
export class ProjectScenePreviewSandbox {
  scene$ = this.sceneQuery.selectActive();

  constructor(private sceneQuery: SceneQuery, private sceneService: SceneService) {}

  resetActive(): void {
    this.sceneService.setActive(null);
  }
}
