import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectControlAddPage } from '../project-control-add/project-control-add.page';
import { ProjectControlPreviewPage } from '../project-control-preview/project-control-preview.page';

import { ProjectControlOverviewSandbox } from './project-control-overview.sandbox';

@Component({
  selector: 'summa-project-control-overview',
  templateUrl: './project-control-overview.page.html',
  styleUrls: ['./project-control-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectControlOverviewSandbox],
})
export class ProjectControlOverviewPage implements OnInit {
  destroy$ = new Subject();
  remove$ = new Subject<DOMAIN.Control>();
  upsertPanel$ = new Subject<DOMAIN.Control>();
  detailsPanel$ = new Subject<DOMAIN.Control>();
  controls = new MatTableDataSource<DOMAIN.Control>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  columnsToDisplay = ['name', 'description', 'type', 'address', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ProjectControlOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnInit(): void {
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();

    this.sandbox.controls$.pipe(takeUntil(this.destroy$)).subscribe((controls: DOMAIN.Control[]) => {
      this.controls.data = controls;
      this.controls.sort = this.sort;
      this.controls.paginator = this.paginator;
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getControls(projectKey);
    });
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.controls.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((control: DOMAIN.Control) => {
      this.sidePanelService.open(ProjectControlAddPage, control);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((control: DOMAIN.Control) => {
      this.sandbox.getControl(control.id);
      this.sidePanelService.open(ProjectControlPreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((control: DOMAIN.Control) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: control.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeControl(control.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
