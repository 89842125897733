import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';

import { I18N_PROVIDERS } from './i18next.config';
import { TRANSLATION_OPTIONS } from './shared-util-translations.constants';

export interface TranslationOptions {
  useCacheBuster: boolean;
  version: number | string;
}

@NgModule({
  imports: [CommonModule, I18NextModule.forRoot()],
  providers: [
    {
      provide: TRANSLATION_OPTIONS,
      useValue: null,
    },
    I18N_PROVIDERS,
  ],
})
export class SharedUtilTranslationsModule {
  static forRoot(options: TranslationOptions): ModuleWithProviders<SharedUtilTranslationsModule> {
    return {
      ngModule: SharedUtilTranslationsModule,
      providers: [
        {
          provide: TRANSLATION_OPTIONS,
          useValue: options,
        },
        ...I18N_PROVIDERS,
      ],
    };
  }
}
