import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ENV } from '@summa/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomerDashboardSandbox } from './customer-dashboard.sandbox';

@Component({
  selector: 'summa-customer-dashboard',
  templateUrl: './customer-dashboard.page.html',
  styleUrls: ['./customer-dashboard.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerDashboardSandbox],
})
export class CustomerDashboardPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  initializing = true;

  logout$ = new Subject<void>();

  constructor(public sandbox: CustomerDashboardSandbox, @Inject('environment') private environment: ENV.Environment) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (this.environment.app.isPortal) {
      this.handleRouteParam();
      this.handleProject();
    } else {
      this.getLocalDashboard();
    }
  }

  private handleRouteParam(): void {
    this.sandbox.routeParams$.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.sandbox.getProject(params.projectKey);
    });
  }

  private handleProject(): void {
    this.sandbox.currentProject$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getCards(project.key);
      this.sandbox.getFixtures(project.key);
      this.sandbox.subscribeToFixtures(project.key);
    });
  }

  private getLocalDashboard(): void {
    this.sandbox.getCards();
    this.sandbox.getLocalFixtureLogs();
    this.sandbox.subscribeToLocalFixture();
  }
}
