import { Injectable } from '@angular/core';

import { SidePanelService } from '@summa/shared/ui/dialogs';
import { DTO } from '@summa/portal/models/dto';
import { filter, map, Observable, withLatestFrom } from 'rxjs';
import { DOMAIN, UI } from '@summa/models';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { flatten, isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { CardQuery, ControlPanelQuery, ControlPanelService, DriverQuery, SceneQuery } from '../../../../data-access';

@Injectable()
export class ControlPanelUpsertControlSandbox {
  upsertState$: Observable<UI.State>;
  data$: Observable<{
    control: DTO.ControlPanelControl;
    availableDevices: { id: string; key: string }[];
  }>;
  devices$: Observable<DTO.Device[]>;
  scenes$: Observable<DTO.Scene[]>;
  groups$: Observable<DOMAIN.Card[]>;
  params$: Observable<{
    resellerId: string;
    projectKey: string;
    controlPanelId: string;
  }>;

  constructor(
    private sceneQuery: SceneQuery,
    private groupQuery: CardQuery,
    private routerQuery: RouterQuery,
    private driverQuery: DriverQuery,
    private sidePanelService: SidePanelService,
    private controlPanelQuery: ControlPanelQuery,
    private controlPanelService: ControlPanelService,
  ) {
    this.upsertState$ = this.controlPanelQuery.selectUpsertState$;
    this.data$ = this.sidePanelService.getData();
    this.params$ = this.routerQuery.selectParams();
    this.scenes$ = this.sceneQuery.selectAll();
    this.groups$ = this.groupQuery.selectAll();
    this.devices$ = this.driverQuery.selectAll().pipe(
      withLatestFrom(this.data$.pipe(filter(isNotNullOrUndefined))),
      map(([drivers, data]) => {
        if (data.availableDevices && data.availableDevices.length > 0) {
          return data.availableDevices;
        }

        return flatten(drivers.map((d) => d.devices));
      }),
    );
  }

  patchControlPanel(controlPanelId: string, controlInput: DTO.ControlPanelControlInput): void {
    this.controlPanelService.upsertControlPanelControl(controlPanelId, controlInput);
  }

  close(): void {
    this.controlPanelService.resetUiState();
    this.sidePanelService.close();
  }
}
