import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { ProjectAutomationInfoSandbox } from './project-automation-info-tab.sandbox';

@Component({
  selector: 'summa-project-automation-info-tab',
  templateUrl: './project-automation-info-tab.component.html',
  styleUrls: ['./project-automation-info-tab.component.scss'],
  providers: [ProjectAutomationInfoSandbox],
})
export class ProjectAutomationInfoTabComponent implements OnDestroy {
  destroy$ = new Subject();

  motion = DOMAIN.automationMotion;
  typeTimer = DOMAIN.automationTimer;
  typeMotion = DOMAIN.automationMotion;
  groupTrigger = DOMAIN.automationMotionGroupTrigger;
  scenarioTrigger = DOMAIN.automationMotionScenarioTrigger;

  public getCustomDay(days: CONSTANTS.Days[]) {
    return CONSTANTS.getCustomDay(days);
  }

  public motionSettings(automation: DOMAIN.Automation): DOMAIN.AutomationMotionSettings {
    return automation.settings as DOMAIN.AutomationMotionSettings;
  }

  public timerSettings(automation: DOMAIN.Automation): DOMAIN.AutomationTimerSettings {
    return automation.settings as DOMAIN.AutomationTimerSettings;
  }

  constructor(public sandbox: ProjectAutomationInfoSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
