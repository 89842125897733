import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, first, pairwise, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';

import { RequiredIfValidator } from '@summa/shared/util/validators';
import { ProjectAutomationAddSandbox } from './project-automation-add.sandbox';

@Component({
  selector: 'summa-project-automation-add',
  templateUrl: './project-automation-add.page.html',
  styleUrls: ['./project-automation-add.page.scss'],
  providers: [ProjectAutomationAddSandbox],
})
export class ProjectAutomationAddPage implements OnInit, OnDestroy {
  motionTriggerTypes = DOMAIN.automationMotionTriggerType;
  triggerType = 'motionSettings.triggerType';

  destroy$ = new Subject();
  submit$ = new Subject();

  form: FormGroup;
  groups: DOMAIN.Card[] = [];
  driverFilter: FormControl;
  filteredDrivers$: ReplaySubject<DOMAIN.Driver[]>;
  loading$ = new BehaviorSubject<boolean>(true);

  readonly stateTypes = CONSTANTS.stateType;
  readonly types = DOMAIN.automationType;
  readonly days = CONSTANTS.days;
  readonly customDays = CONSTANTS.customDays;

  get triggers(): FormArray {
    return this.form.get('triggers') as FormArray;
  }

  get motionGroupScenes(): DOMAIN.Scene[] {
    const group = this.form.get('motionSettings.motionGroupTrigger.group')?.value;
    return this.groups.length > 0 && group ? this.groups.find((g) => g.id === group ?? '').scenes : [];
  }

  constructor(public sandbox: ProjectAutomationAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.driverFilter = this.fb.control('');
    this.filteredDrivers$ = new ReplaySubject<DOMAIN.Driver[]>(1);

    this.handleData();
    this.handleRoute();
    this.handleSubmit();
    this.handleSuccess();
    this.handleDrivers();
    this.handleGroups();
    this.handleFilterDriver();
  }

  private handleGroups() {
    this.sandbox.groups$.pipe(filter(isNotNullUndefinedOrEmpty), takeUntil(this.destroy$)).subscribe((groups) => {
      this.groups = groups;
    });
  }

  public addTrigger(trigger?: DOMAIN.AutomationTriggerSetting): void {
    const customDay = trigger && trigger.days.length > 0 ? CONSTANTS.getCustomDay(trigger.days) : '';
    const specificDays = trigger && customDay === CONSTANTS.dayCustom ? trigger.days : [];
    this.triggers.push(
      this.fb.group({
        customDay: [customDay],
        specificDays: [specificDays],
        startTime: [trigger?.startTime ?? '00:00'],
        endTime: [trigger?.endTime ?? '00:00'],
      }),
    );
  }

  public removeTrigger(index: number): void {
    this.triggers.removeAt(index);
  }

  private handleData(): void {
    this.sandbox.data$.pipe(first(), takeUntil(this.destroy$)).subscribe((data: { automation: DOMAIN.Automation | null; projectKey: string }) => {
      const { automation, projectKey } = data;
      this.sandbox.getGroups(projectKey);

      if (!automation) {
        this.form = this.fb.group({
          state: [true, Validators.required],
          name: ['', Validators.required],
          description: ['', Validators.required],
          type: ['', Validators.required],
          triggers: this.fb.array([]),

          // Timer specific form-elements
          timerSettings: this.fb.group({
            scenarioId: ['', RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationTimer)],
          }),

          // Motion specific form-elements
          motionSettings: this.fb.group({
            triggerType: [
              DOMAIN.automationMotionScenarioTrigger,
              RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
            ],
            sensorDrivers: ['', RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
            threshold: [4, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],

            motionScenarioTrigger: this.fb.group({
              presence: this.fb.group({
                scenario: [
                  '',
                  RequiredIfValidator(
                    () =>
                      this.form.get('type')?.value === DOMAIN.automationMotion &&
                      this.form.get(this.triggerType)?.value === DOMAIN.automationMotionScenarioTrigger,
                  ),
                ],
                holdTime: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                fadeInDuration: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              }),
              idle: this.fb.group({
                brightness: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                holdTime: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                fadeInDuration: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              }),
            }),

            motionGroupTrigger: this.fb.group({
              group: [
                '',
                RequiredIfValidator(
                  () =>
                    this.form.get('type')?.value === DOMAIN.automationMotion &&
                    this.form.get(this.triggerType)?.value === DOMAIN.automationMotionGroupTrigger,
                ),
              ],
              presence: this.fb.group({
                scene: [
                  '',
                  RequiredIfValidator(
                    () =>
                      this.form.get('type')?.value === DOMAIN.automationMotion &&
                      this.form.get(this.triggerType)?.value === DOMAIN.automationMotionGroupTrigger,
                  ),
                ],
                holdTime: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                fadeInDuration: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              }),

              idle: this.fb.group({
                brightness: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                holdTime: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
                fadeInDuration: [0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              }),
            }),
          }),
        });
        this.handleMotionTriggerToggle();
        this.loading$.next(false);
        return;
      }
      this.sandbox.getAutomation(automation.id);
    });

    // eslint-disable-next-line sonarjs/cognitive-complexity
    this.sandbox.automation$.pipe(takeUntil(this.destroy$)).subscribe((automation) => {
      if (!automation) return;

      const motionSettings = automation.settings as DOMAIN.AutomationMotionSettings;

      this.form = this.fb.group({
        id: [automation.id],
        name: [automation.name, Validators.required],
        description: [automation.description, Validators.required],
        type: [automation.type, Validators.required],
        state: [automation.state === CONSTANTS.stateOn, Validators.required],
        triggers: this.fb.array([]),

        // Timer specific form-elements
        timerSettings: this.fb.group({
          scenarioId: [automation.scenario?.id || '', RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationTimer)],
        }),

        // Motion specific form-elements
        motionSettings: this.fb.group({
          triggerType: [motionSettings.triggerType, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
          motionScenarioTrigger: this.fb.group({
            presence: this.fb.group({
              scenario: [motionSettings.triggerType === DOMAIN.automationMotionScenarioTrigger ? motionSettings.presence?.scenario?.id : ''],
              holdTime: [motionSettings.presence?.holdTime ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              fadeInDuration: [
                motionSettings.presence?.fadeInDuration ?? 0,
                RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
              ],
            }),
            idle: this.fb.group({
              brightness: [motionSettings.idle?.brightness ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              holdTime: [motionSettings.idle?.holdTime ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              fadeInDuration: [
                motionSettings.idle?.fadeInDuration ?? 0,
                RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
              ],
            }),
          }),

          motionGroupTrigger: this.fb.group({
            group: [motionSettings.presence?.group?.id ?? ''],
            presence: this.fb.group({
              scene: [motionSettings.presence?.scene?.id ?? ''],
              holdTime: [motionSettings.presence?.holdTime ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              fadeInDuration: [
                motionSettings.presence?.fadeInDuration ?? 0,
                RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
              ],
            }),

            idle: this.fb.group({
              brightness: [motionSettings.idle?.brightness ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              holdTime: [motionSettings.idle?.holdTime ?? 0, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
              fadeInDuration: [
                motionSettings.idle?.fadeInDuration ?? 0,
                RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
              ],
            }),
          }),

          sensorDrivers: [
            motionSettings.sensorDrivers?.map((driver) => driver.id),
            RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion),
          ],
          threshold: [motionSettings.threshold ?? 4, RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion)],
        }),
      });

      // Add automation triggers
      automation.settings.triggers?.forEach((trigger) => this.addTrigger(trigger));

      this.handleMotionTriggerToggle();
      this.loading$.next(false);
    });
  }

  toggleSelectAllSensorDrivers(options, selectAllValue: boolean): void {
    options.pipe(first(), takeUntil(this.destroy$)).subscribe((val) => {
      if (selectAllValue) {
        this.form.patchValue({ motionSettings: { sensorDrivers: val.map((i) => i.id) } });
      } else {
        this.form.patchValue({ motionSettings: { sensorDrivers: [] } });
      }
    });
  }

  private handleRoute(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getScenarios(projectKey);
      this.sandbox.getDrivers(projectKey);
    });
  }

  private handleDrivers(): void {
    this.sandbox.drivers$.pipe(takeUntil(this.destroy$)).subscribe((drivers) => this.filteredDrivers$.next(drivers));
  }

  private handleFilterDriver(): void {
    this.driverFilter.valueChanges.pipe(withLatestFrom(this.sandbox.drivers$), takeUntil(this.destroy$)).subscribe(([search, drivers]) => {
      if (!search) {
        this.filteredDrivers$.next(drivers);
        return;
      }

      this.filteredDrivers$.next(drivers.filter((driver) => driver.key.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      if (this.form.invalid) return;

      const form = this.form.value;
      const triggers = form.triggers
        ? form.triggers.map((trigger) => ({
            days: CONSTANTS.getDays(trigger.customDay, trigger.specificDays),
            startTime: trigger.startTime,
            endTime: trigger.endTime,
          }))
        : [];

      const automationInput = {
        ...(form.id && { id: form.id }),
        projectKey: project.key,
        name: form.name,
        description: form.description,
        type: form.type,
        state: form.state ? CONSTANTS.stateType[1] : CONSTANTS.stateType[0],

        // Motion Automation Settings
        ...(form.type === DOMAIN.automationMotion &&
          form.motionSettings.triggerType === DOMAIN.automationMotionScenarioTrigger && {
            settings: {
              triggers,
              triggerType: form.motionSettings.triggerType,
              sensorDrivers: form.motionSettings.sensorDrivers,
              threshold: form.motionSettings.threshold,
              presence: {
                scenario: form.motionSettings.motionScenarioTrigger.presence.scenario,
                holdTime: form.motionSettings.motionScenarioTrigger.presence.holdTime,
                fadeInDuration: form.motionSettings.motionScenarioTrigger.presence.fadeInDuration,
              },
              idle: {
                brightness: form.motionSettings.motionScenarioTrigger.idle.brightness,
                holdTime: form.motionSettings.motionScenarioTrigger.idle.holdTime,
                fadeInDuration: form.motionSettings.motionScenarioTrigger.idle.fadeInDuration,
              },
            },
          }),

        ...(form.type === DOMAIN.automationMotion &&
          form.motionSettings.triggerType === DOMAIN.automationMotionGroupTrigger && {
            settings: {
              triggers,
              triggerType: form.motionSettings.triggerType,
              sensorDrivers: form.motionSettings.sensorDrivers,
              threshold: form.motionSettings.threshold,
              presence: {
                group: form.motionSettings.motionGroupTrigger.group,
                holdTime: form.motionSettings.motionGroupTrigger.presence.holdTime,
                fadeInDuration: form.motionSettings.motionGroupTrigger.presence.fadeInDuration,
                scene: form.motionSettings.motionGroupTrigger.presence.scene,
              },
              idle: {
                brightness: form.motionSettings.motionGroupTrigger.idle.brightness,
                holdTime: form.motionSettings.motionGroupTrigger.idle.holdTime,
                fadeInDuration: form.motionSettings.motionGroupTrigger.idle.fadeInDuration,
              },
            },
          }),

        // Timer Automation Settings
        ...(form.type === DOMAIN.automationTimer && {
          scenarioId: form.timerSettings.scenarioId,
          settings: {
            triggers,
          },
        }),
      };
      this.sandbox.upsertAutomation(automationInput);
    });
  }

  private handleMotionTriggerToggle(): void {
    this.form.valueChanges
      .pipe(
        filter(isNotNullOrUndefined),
        startWith(this.form.getRawValue()),
        pairwise(),
        filter(([v1, v2]) => v1.motionSettings?.triggerType !== v2.motionSettings?.triggerType),
        takeUntil(this.destroy$),
      )
      .subscribe(([, form]) => {
        const groupFields = ['motionSettings.motionGroupTrigger.group', 'motionSettings.motionGroupTrigger.presence.scene'];
        const scenarioFields = ['motionSettings.motionScenarioTrigger.presence.scenario'];

        if (form.motionSettings.triggerType === DOMAIN.automationMotionGroupTrigger) {
          groupFields.forEach((field) => {
            this.form.get(field).setValidators(RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion));
          });
          scenarioFields.forEach((field) => {
            this.form.get(field).clearValidators();
          });
        } else {
          scenarioFields.forEach((field) => {
            this.form.get(field).setValidators(RequiredIfValidator(() => this.form.get('type')?.value === DOMAIN.automationMotion));
          });
          groupFields.forEach((field) => {
            this.form.get(field).clearValidators();
          });
        }

        [...groupFields, ...scenarioFields].forEach((field) => {
          this.form.get(field).updateValueAndValidity();
        });
      });
  }

  private handleSuccess(): void {
    this.sandbox.updateState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (!state.isSuccessful) return;
      this.sandbox.close();
    });
  }
}
