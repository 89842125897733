import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { CardQuery, CardService, ProjectQuery, ScenarioQuery, ScenarioService } from '../../../../data-access';

@Injectable()
export class ProjectScenarioAddSandbox {
  project$ = this.projectQuery.selectActive();
  cards$ = this.cardQuery.selectAll();
  upsertState$ = this.scenarioQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData();
  params$ = this.routerQuery.selectParams();
  scenario$ = this.scenarioQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  constructor(
    private sidePanelService: SidePanelService,
    private projectQuery: ProjectQuery,
    private cardQuery: CardQuery,
    private cardService: CardService,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private routerQuery: RouterQuery,
  ) {}

  upsertScenario(scenario: DOMAIN.ScenarioInput): void {
    this.scenarioService.upsertScenario(scenario);
  }

  getCards(projectKey: string): void {
    this.cardService.getCards(projectKey, null, null);
  }

  getScenario(scenarioId: string): void {
    this.scenarioService.getScenario(scenarioId);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.scenarioService.resetActive();
  }
}
