import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { ClientQuery, ClientService, LocationQuery, LocationService, ResellerQuery, ResellerService } from '../../../../data-access';

@Injectable()
export class ResellerLocationAddSandbox {
  params$ = this.router.selectParams();
  client$ = this.clientQuery.selectActive();
  reseller$ = this.resellerQuery.selectActive();
  upsertSuccess$ = this.clientQuery.selectUpsertState$.pipe(map((state) => state.isSuccessful));
  data$ = this.sidePanelService.getData();
  location$ = this.locationQuery.selectActive();

  constructor(
    private router: RouterQuery,
    private clientQuery: ClientQuery,
    private clientService: ClientService,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private sidePanelService: SidePanelService,
    private locationService: LocationService,
    private locationQuery: LocationQuery,
  ) {}

  getLocation(id: string): void {
    this.locationService.getLocation(id);
  }

  getClient(id: string): void {
    this.clientService.getClient(id);
  }

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;

    this.resellerService.getReseller(id);
  }

  upsertLocation(location: PortalAPI.LocationInput): void {
    this.locationService.upsertLocation(location);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.locationService.reset();
  }
}
