import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { DOMAIN, PERMISSIONS } from '@summa/models';
import { flatten } from '@summa/shared/util/typescript';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Observable } from 'rxjs';
import { AuthQuery, DriverQuery, DriverService, FixtureStateService } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectDriverOverviewSandbox {
  drivers$ = this.driverQuery.selectAll({ filterBy: (driver) => !!driver.projectKey });
  devices$ = this.driverQuery.selectAll().pipe(map((drivers) => flatten(drivers.map((driver) => driver.devices))));
  upsertState$ = this.driverQuery.selectUpsertState$;
  params$ = this.routerQuery.selectParams();
  currentUser$ = this.authQuery.selectActive();
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private fixtureStateService: FixtureStateService,
    private routerQuery: RouterQuery,
    private authQuery: AuthQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}


  observeFixtureState(projectKey: string): void {
    this.fixtureStateService.subscribe([projectKey]);
  }

  removeDriverFromProject(driver: DOMAIN.DriverInput, projectKey: string): void {
    this.driverService.removeDriverFromProject(driver.key, projectKey);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDriverOverview(projectKey);
  }

  getDriver(driverKey: string): void {
    this.driverService.getDriver(driverKey);
  }

  syncDriversByProjectKey(projectKey: string): void {
    this.driverService.syncDriversByProjectKey(projectKey);
  }

  resetActive(): void {
    this.driverService.resetActive();
  }

  resetDrivers(): void {
    this.driverService.reset();
    this.fixtureStateService.reset();
  }
}
