<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.gateway$ | async as gateway; else noGateway">
  <ng-container header>
    <h1><span class="highlight pr-0">{{ 'project:gateway.detail.title' | i18nextCap }}:</span> {{ gateway.key }}</h1>
  </ng-container>

  <ng-container info>
    <div class="scenario-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.key' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ gateway.key }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.state' | i18nextCap }}</div>
            <div class="content" fxFlex="80">
              <ng-container [ngSwitch]="gateway.state">
                <div class="badge success m-0" *ngSwitchCase="'ON'">{{ 'common:dictionary.states.online' | i18next }}</div>
                <div class="badge danger m-0" *ngSwitchCase="'OFF'">{{ 'common:dictionary.states.offline' | i18next }}</div>
                <div class="badge warning m-0" *ngSwitchDefault>{{ 'common:dictionary.states.unknown' | i18next }}</div>
              </ng-container>
            </div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'project:gateway.gateway-time' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ gateway.messageTime ? (gateway.messageTime * 1000 | date: 'd/M/yyyy, hh:mm a') : '' }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'project:gateway.log-time' | i18nextCap }}</div>
            <div class="content" fxFlex="80">
              {{ gateway.modified?.timestamp ? (gateway.modified.timestamp | date: 'd/M/yyyy, hh:mm a') : '' }}
            </div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.address_plural' | i18nextCap }}</div>
            <div class="content content-list" fxFlex="80">
              <ul>
                <li *ngFor="let address of gateway.addresses">{{ address }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container body>
    <h2>{{ 'project:gateway.detail.application' | i18nextCap:{count:gateway.applications.length } }}</h2>
    <mat-table [dataSource]="gateway.applications" class="w-100" matSort>
      <!-- name -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'project:gateway.detail.table.name' | i18nextCap}}</mat-header-cell>
        <mat-cell *matCellDef="let app">{{ app.name}} </mat-cell>
      </ng-container>
      <!-- version -->
      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'project:gateway.detail.table.version' | i18nextCap}}</mat-header-cell>
        <mat-cell *matCellDef="let app">{{ app.version}} </mat-cell>
      </ng-container>
      <!-- type -->
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'project:gateway.detail.table.type' | i18nextCap}}</mat-header-cell>
        <mat-cell *matCellDef="let app">{{ app.type}} </mat-cell>
      </ng-container>
      <!-- timestamp -->
      <ng-container matColumnDef="modified">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'project:gateway.detail.table.modified' | i18nextCap}}</mat-header-cell>
        <mat-cell *matCellDef="let app">
          {{ (app.timestamp <= 10000000000 ? app.timestamp * 1000 : app.timestamp) | date: 'd/MM/yy, h:mm a' }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
      <mat-row *matRowDef="let app; columns: columnsToDisplay"></mat-row>
    </mat-table>
  </ng-container>

  <ng-container footer>
    <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{ 'common:buttons.close' | i18nextCap }}</button>
  </ng-container>
</summa-side-panel-content>

<ng-template #noGateway>
  <summa-side-panel-content [showInfo]="true" class="side-panel">
    <ng-container header>
      <h1><span class="highlight">{{ 'project:gateway.detail.title' | i18nextCap }}</span></h1>
    </ng-container>

    <ng-container info>{{ 'project:gateway.no-information' | i18nextCap }}</ng-container>

    <ng-container footer>
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{ 'common:buttons.cancel' | i18nextCap }}</button>
    </ng-container>
  </summa-side-panel-content>
</ng-template>
