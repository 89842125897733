import { gql } from 'apollo-angular';

export const upsertProjectQuery = gql`
  mutation upsertProject($project: ProjectInput!) {
    upsertProject(project: $project) {
      id
      name
      key
      apiKey
      timezone
      location {
        id
      }
      settings {
        wifiSsid
        wifiPassword
      }
      geolocation {
        longitude
        latitude
      }
      gatewayKey
      gateway {
        state
        messageTime
        addresses
        key
        modified
      }
    }
  }
`;

export const patchProjectQuery = gql`
  mutation patchProject($project: ProjectPatchInput!) {
    patchProject(project: $project) {
      id
      name
      key
      apiKey
      timezone
      location {
        id
      }
      settings {
        wifiSsid
        wifiPassword
      }
      geolocation {
        longitude
        latitude
      }
      gatewayKey
      gateway {
        state
        messageTime
        addresses
        key
        modified
      }
    }
  }
`;

export const removeProjectQuery = gql`
  mutation removeProject($id: String!, $key: String!) {
    removeProject(id: $id, key: $key) {
      acknowledged
      deletedCount
    }
  }
`;
