import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { DOMAIN, PortalAPI } from '@summa/models';
import { flatten, isNotNullOrUndefined } from '@summa/shared/util/typescript';
import {
  CardQuery,
  CardService,
  CommandService,
  DriverQuery,
  DriverService,
  FixtureQuery,
  FixtureService,
  FloorplanQuery,
  FloorplanService,
  SceneQuery,
  SceneService,
} from '../../../../../data-access';

@Injectable()
export class ProjectFloorplanCardsComponentSandbox {
  floorplan$ = this.floorplanQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  fixtures$ = this.fixtureQuery.selectAll();
  selectedDriver$ = this.driverQuery.selectActive();
  params$ = this.router.parent.params;
  driverLoader$ = this.driverQuery.selectLoading();
  scenes$ = this.sceneQuery.selectAll();
  devices$ = this.driverQuery.selectAll().pipe(map((drivers) => flatten(drivers.map((d) => d.devices))));
  cards$ = this.cardQuery.selectAll();
  upsertState$ = this.cardQuery.selectUpsertState$;
  removeState$ = this.cardQuery.selectRemoveState$;
  upsertLoading$ = this.upsertState$.pipe(map((state) => state.isLoading));

  constructor(
    private floorplanQuery: FloorplanQuery,
    private floorplanService: FloorplanService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private commandService: CommandService,
    private router: ActivatedRoute,
    private cardQuery: CardQuery,
    private cardService: CardService,
    private sceneQuery: SceneQuery,
    private sceneService: SceneService,
    private fixtureService: FixtureService,
    private fixtureQuery: FixtureQuery,
  ) {}

  getCards(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  getScenes(projectKey: string): void {
    this.sceneService.getScenes(projectKey);
  }

  activateDriver(key: string): void {
    this.driverService.setActive(key);
  }

  getFloorplan(floorplanId: string): void {
    this.floorplanService.setActive(floorplanId);
  }

  getFixtures(floorplanId: string): void {
    this.fixtureService.getFixtures(floorplanId);
  }

  upsertFixture(fixture: DOMAIN.Fixture) {
    this.fixtureService.upsertFixture(fixture);
  }

  upsertFixtures(fixtures: DOMAIN.Fixture[]) {
    this.fixtureService.upsertFixtures(fixtures);
  }

  executeDeviceCommand(projectKey: string, device: DOMAIN.Device, state: 'ON' | 'OFF', dimValue?: number): void {
    const command: PortalAPI.CommandInput = { projectKey, state, deviceIds: [device.id], ...(dimValue && dimValue !== -1 && { dimValue }) };
    this.commandService.executeCommand(command);
  }

  upsertCard(card: DOMAIN.CardInput): void {
    this.cardService.upsertCard(card);
  }

  removeCard(id: string): void {
    this.cardService.remove(id);
  }

  reset(): void {
    this.cardService.resetActive();
  }
}
