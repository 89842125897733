<div fxLayout="row" fxLayoutGap="16px" class="w-100 mt-2">
  <div class="left-column reseller-card p-2" fxLayout="column" fxLayoutGap="0.5rem">
    <!-- CHOOSE  -->
    <section class="px-4" *ngIf="!(modus$ | async)">
      <h3>{{ 'project:floorplans.cards.choose' | i18nextCap }}</h3>

      <section fxLayout="row" fxLayoutGap="0.5rem" class="mb-2">
        <div (click)="modus$.next('edit')" class="sync-button">
          <div class="content">
            <mat-icon svgIcon="newspaper-variant-multiple-outline"></mat-icon>
            <div class="title">{{ 'project:floorplans.cards.edit' | i18nextCap }}</div>
          </div>
        </div>

        <section fxLayoutAlign="center center" class="or">
          <strong>{{ 'common:dictionary.or' | i18next | uppercase }}</strong>
        </section>

        <div (click)="modus$.next('new')" class="sync-button">
          <div class="content">
            <mat-icon svgIcon="new-box"></mat-icon>
            <div class="title">{{ 'project:floorplans.cards.create-new' | i18nextCap }}</div>
          </div>
        </div>
      </section>
    </section>
    <!-- FORM -->
    <ng-container *ngIf="modus$ | async as modus">
      <h3 *ngIf="modus === 'edit'" class="px-2" fxLayoutAlign="start center">
        <mat-icon (click)="cancel$.next(null)" class="mr-1 clickable" svgIcon="chevron-double-left"></mat-icon>
        <div>
          {{ 'project:floorplans.cards.select-card' | i18nextCap }}
        </div>
      </h3>
      <section class="edit" *ngIf="modus === 'edit'">
        <mat-form-field *ngIf="(sandbox.cards$ | async).length > 0">
          <mat-select [formControl]="card" [placeholder]="'common:dictionary.card_plural' | i18nextCap">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="filter"
                [placeholderLabel]="'reseller:floorplans.cards.find-card' | i18nextCap"
                [noEntriesFoundLabel]="'message:no-search-result' | i18nextCap"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let card of filteredCards$ | async" [value]="card.id">
              {{ card.title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>
      <section [class]="modus === 'edit' ? 'edit-modus' : 'px-4'" *ngIf="form">
        <h3 fxLayoutAlign="start center">
          <mat-icon *ngIf="modus === 'new'" (click)="cancel$.next(null)" class="mr-1 clickable" svgIcon="chevron-double-left"></mat-icon>
          <div class="mr-1">
            {{ 'project:floorplans.cards.form-title' | i18nextCap }}
          </div>
          <small class="orange mr-1">{{ form.get('id')?.value }}</small>
          <mat-icon
            *ngIf="modus === 'edit'"
            [title]="'project:floorplans.cards.remove' | i18nextCap"
            (click)="remove$.next(form.get('id')?.value)"
            class="remove-icon"
            svgIcon="file-remove-outline"
          ></mat-icon>
        </h3>

        <form class="form" [formGroup]="form">
          <section fxLayout="column" fxLayoutGap="1rem">
            <mat-form-field appearance="legacy">
              <mat-label>{{ 'common:dictionary.title' | i18nextCap }}</mat-label>
              <input matInput formControlName="title" />
              <mat-error *ngIf="form.get('title').invalid">{{ 'reseller:form.errors.required' | i18nextCap }} </mat-error>
            </mat-form-field>
            <section fxLayout="row">
              <mat-form-field appearance="legacy" class="w-50 pr-1">
                <mat-label>{{ 'common:dictionary.sort-order' | i18nextCap }}</mat-label>
                <input matInput type="number" formControlName="sortOrder" />
              </mat-form-field>
              <mat-checkbox class="mb-1" formControlName="hidden">{{ 'reseller:cards.hide-card' | i18nextCap }} </mat-checkbox>
            </section>
            <mat-form-field>
              <mat-select formControlName="sceneIds" placeholder="Scenes" [multiple]="true" #multiSelect>
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="sceneFilter"
                    [placeholderLabel]="'reseller:cards.find-scene' | i18nextCap"
                    [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                  ></ngx-mat-select-search>
                </mat-option>
                <header class="select-button-header">
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredScenes$, form.controls.sceneIds, true)">
                    {{ 'common:buttons.select-all' | i18nextCap }}
                  </button>
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredScenes$, form.controls.sceneIds, false)">
                    {{ 'common:buttons.deselect-all' | i18nextCap }}
                  </button>
                </header>
                <ng-container *ngFor="let scene of filteredScenes$ | async">
                  <mat-option [value]="scene.id">
                    {{ scene.name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-select formControlName="deviceIds" placeholder="Devices" [multiple]="true" #multiSelect>
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="deviceFilter"
                    [placeholderLabel]="'reseller:cards.find-fixture' | i18nextCap"
                    [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                  ></ngx-mat-select-search>
                </mat-option>
                <header class="select-button-header">
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, form.controls.deviceIds, true)">
                    {{ 'common:buttons.select-all' | i18nextCap }}
                  </button>
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, form.controls.deviceIds, false)">
                    {{ 'common:buttons.deselect-all' | i18nextCap }}
                  </button>
                </header>
                <mat-option *ngFor="let device of filteredDevices$ | async" [value]="device.id">
                  {{ device.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </section>
        </form>
        <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
          <button (click)="cancel$.next(null)" mat-button type="button" class="button cancel">{{ 'common:buttons.cancel' | i18nextCap }}</button>
          <button
            mat-button
            type="button"
            class="button save"
            [disabled]="form.invalid || (sandbox.upsertLoading$ | async)"
            (click)="submit$.next(null)"
          >
            {{ 'common:buttons.save' | i18nextCap }}
          </button>
        </section>
      </section>
    </ng-container>
  </div>
  <!-- FLOORPLAN -->
  <div fxFlex="auto" class="reseller-card lg white">
    <div fxFlex="auto" *ngIf="sandbox.floorplan$ | async" class="reseller-card lg white">
      <ng-container *ngIf="!(sandbox.driverLoader$ | async)">
        <summa-floorplan-dxf-viewer
          [file]="fileUrl"
          [fixtures]="fixturesState$ | async"
          (activateElement)="dxfClicked$.next($event)"
          [customHeight]="'75vh'"
        ></summa-floorplan-dxf-viewer>
      </ng-container>
    </div>
  </div>
</div>
