import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PortalAPI } from '@summa/models';

import { ClientState, ClientStore } from './client.store';

@Injectable({
  providedIn: 'root',
})
export class ClientQuery extends QueryEntity<ClientState, PortalAPI.Client> {
  // upsert
  selectUpsertState$ = this.select((state) => state.ui.upsert);

  constructor(protected store: ClientStore) {
    super(store);
  }
}
