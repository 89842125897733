import { gql } from 'apollo-angular';

export const upsertLocationQuery = gql`
  mutation upsertLocation($location: LocationInput!) {
    upsertLocation(location: $location) {
      id
      name
      street
      number
      zipCode
      city
      state
      country
    }
  }
`;

export const removeLocationQuery = gql`
  mutation removeLocation($id: String!) {
    removeLocation(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
