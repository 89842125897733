<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$|async) ? 'reseller:cards.add.edit-title' :'reseller:cards.add.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{project.key}}) {{ project.name}}</div>
      </div>
      <div *ngIf="sandbox.card$ |async as card">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ card.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.title' | i18nextCap}}</mat-label>
          <input matInput formControlName="title" />
          <mat-error *ngIf="form.get('title').invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.sort-order' | i18nextCap}}</mat-label>
          <input matInput type="number" formControlName="sortOrder" />
        </mat-form-field>
        <mat-checkbox class="mb-1" formControlName="hidden">{{'reseller:cards.hide-card' | i18nextCap}}</mat-checkbox>
        <mat-form-field>
          <mat-select [formControl]="devicesCtrl" placeholder="Devices" [multiple]="true" #multiSelect>
            <mat-option>
              <ngx-mat-select-search
                [formControl]="deviceFilter"
                [placeholderLabel]="'reseller:cards.find-fixture' | i18nextCap"
                [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
              ></ngx-mat-select-search>
            </mat-option>
            <header class="select-button-header">
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, devicesCtrl, true)">
                {{'common:buttons.select-all' | i18nextCap}}
              </button>
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, devicesCtrl, false)">
                {{'common:buttons.deselect-all' | i18nextCap}}
              </button>
            </header>
            <mat-option *ngFor="let device of filteredDevices$ | async" [value]="device.id"> {{device.key}} </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select formControlName="sceneIds" placeholder="Scenes" [multiple]="true" #multiSelect>
            <mat-option>
              <ngx-mat-select-search
                [formControl]="sceneFilter"
                [placeholderLabel]="'reseller:cards.find-scene' | i18nextCap"
                [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
              ></ngx-mat-select-search>
            </mat-option>
            <header class="select-button-header">
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredScenes$, form.controls.sceneIds, true)">
                {{'common:buttons.select-all' | i18nextCap}}
              </button>
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredScenes$, form.controls.sceneIds, false)">
                {{'common:buttons.deselect-all' | i18nextCap}}
              </button>
            </header>
            <ng-container *ngFor="let scene of filteredScenes$ | async">
              <mat-option [value]="scene.id"> {{scene.name }} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </section>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button *ngIf="form" mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
