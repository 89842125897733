export const notificationError = 'error';
export const notificationWarning = 'warning';
export const notificationUpdate = 'info';
export const notificationNotify = 'info';
export const notificationSuccess = 'success';
export const notificationAll = 'all';

export const notificationType = [
  notificationError,
  notificationWarning,
  notificationUpdate,
  notificationSuccess,
  notificationNotify,
  notificationAll,
] as const;
export type NotificationType = typeof notificationType[number];
