export const duvMin = -0.006;
export const duvMax = 0.006;
export const duvDefault = 0;

export const cctMin = 0;
export const cctMax = 20000;
export const cctDefault = 3500;

export const dimValueMin = 0;
export const dimValueMax = 100;
export const dimValueDefault = 100;
