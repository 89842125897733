import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

@Injectable()
export class NotificationPreviewSandbox {
  data$ = this.sidePanelService.getData<DOMAIN.Notification>().pipe(filter(isNotNullOrUndefined));

  constructor(private sidePanelService: SidePanelService) {}
}
