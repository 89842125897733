/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { FixtureStateStore } from './fixture-state.store';
import { FixtureStateSubscription, getFixtureStatesQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class FixtureStateService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private fixtureStateStore: FixtureStateStore) {}

  reset(): void {
    this.fixtureStateStore.reset();
  }

  getFixtureStates(project?: string): void {
    this.apollo
      .subscribe({ query: getFixtureStatesQuery, variables: { project } })
      .pipe(map((d: any) => d.data.getFixtureStates as DOMAIN.FixtureState[]))
      .subscribe((fixtureStates) => (fixtureStates ? this.fixtureStateStore.set(fixtureStates) : this.fixtureStateStore.reset()));
  }

  subscribe(projects?: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: FixtureStateSubscription, variables: { projects } }).subscribe((fixtureState) => {
      const state = (fixtureState.data as { observeFixtureStates: DOMAIN.FixtureState }).observeFixtureStates;
      this.fixtureStateStore.upsert(state.topic, state);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
