import { gql } from 'apollo-angular';

export const GetFixtureLogs = gql`
  query {
    getFixtureLogs {
      timestamp
      topic
      ip
      ticks
      temp
      version
      type
      channels
      dimLevel
      walkTime
      fadeRate
      amountCh
    }
  }
`;

export const FixtureLogsSubscription = gql`
  subscription {
    observeFixtureLogs {
      timestamp
      topic
      ip
      ticks
      temp
      version
      type
      channels
      dimLevel
      walkTime
      fadeRate
      amountCh
    }
  }
`;
