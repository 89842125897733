/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { DTO } from '@summa/portal/models/dto';
import { findNotificationsQuery, getNotificationsQuery, getTodaysNotificationsQuery } from './graphql/notification.gql-query';
import { NotificationsSubscription } from './graphql/notification.gql-subscription';
import { NotificationStore, TodaysNotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private notificationStore: NotificationStore, private todaysNotificationStore: TodaysNotificationStore) {}

  reset(): void {
    this.notificationStore.reset();
    this.todaysNotificationStore.reset();
  }

  resetLive(): void {
    this.notificationStore.resetLiveState();
  }

  getNotifications(projects: string[], types?: CONSTANTS.NotificationType[], take?: number): void {
    this.apollo
      .subscribe({ query: getNotificationsQuery, variables: { projects, take, types } })
      .pipe(map((d: any): DOMAIN.Notification[] => d.data.getNotifications))
      .subscribe((notifications) => this.notificationStore.upsertMany(notifications));
  }

  subscribe(projects: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: NotificationsSubscription, variables: { projects } }).subscribe((notification) => {
      const notify = (notification.data as { observeNotifications: DOMAIN.Notification }).observeNotifications;
      this.notificationStore.add(notify);
      this.notificationStore.addLiveNotifications(notify);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }

  getTodaysNotifications(projects: string[]): void {
    this.apollo
      .subscribe({ query: getTodaysNotificationsQuery, variables: { projects } })
      .pipe(map((d: any): DOMAIN.Notification[] => d.data.getTodaysNotifications))
      .subscribe((notifications) => this.todaysNotificationStore.upsertMany(notifications));
  }

  findNotifications(args: DTO.PaginationInput): void {
    this.notificationStore.setLoading(true);
    this.apollo
      .subscribe({ query: findNotificationsQuery, variables: { ...args } })
      .pipe(map((d: any): any => d.data.findNotifications))
      .subscribe((response) => {
        this.notificationStore.reset();
        this.notificationStore.upsertMany(response.result);
        this.notificationStore.setTotal(response.meta?.total);
        this.notificationStore.setLoading(false);
      });
  }
}
