import { isEqual, isObject } from './is-equal';

/**
 * Compare 2 objects A, B with similar structure and return differences in object A
 * @param changed
 * @param original
 * @param skipObjectKeys skip keys when comparing objects
 * @returns type of changed or Partial<changed>
 *
 * Example:
 * changed: {prop1: 3, prop2: [123], prop3: [456]}
 * original: {prop1: 1, prop2: [123], prop3: [789]}
 * getDifference(changed,original) returns { prop1: 3, prop3: [ 456 ] }
 * getDifference(original,changed) returns { prop1: 1, prop3: [ 789 ] }
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getDifferences<C, O>(changed: C, original: O, skipObjectKeys: string[] = []): Partial<C> {
  if (typeof changed !== typeof original) return changed;

  if (Array.isArray(changed)) {
    return isEqual(changed, original) ? null : changed;
  }

  if (isObject(changed) && isObject(original)) {
    const allKeys = Array.from(new Set([...Object.keys(changed), ...Object.keys(original)])).filter((key) => !skipObjectKeys.includes(key));
    const differentFields = {};
    allKeys.forEach((k) => {
      if (!(k in changed)) {
        differentFields[k] = null;
      } else if (!isObject(changed[k]) && changed[k] !== original[k]) {
        differentFields[k] = changed[k];
      } else {
        const diff = getDifferences(changed[k], original[k]);
        if (diff) differentFields[k] = diff;
      }
    });
    if (Object.keys(differentFields).length === 0) return null;
    return differentFields;
  }

  return isEqual(changed, original) ? null : changed;
}
