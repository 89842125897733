<div class="reseller-card sm p-2">
  <div class="header" fxFlexAlign="row" fxLayoutAlign="space-between center">
    <div>{{ title | i18nextCap }}</div>

    <!-- content aligned to the right -->
    <ng-content select="[header]"></ng-content>
  </div>

  <!-- COUNTER BUTTONS -->
  <section class="mb-3" *ngIf="counters" fxLayout="row wrap" fxLayoutGap="4px">
    <section class="counter-button" *ngFor="let counter of counters">
      <div class="content">
        <div class="counter" [title]="counter.tooltip || counter.title | i18nextCap" [ngClass]="counter.count > 0 && counter.class">
          {{ counter.count > 0 ? counter.count : '-' }}
        </div>
        <div class="title">{{ counter.title | i18nextCap }}</div>
      </div>
    </section>
  </section>

  <!-- ACTION BUTTONS -->
  <section fxLayout="row wrap" fxLayoutGap="4px">
    <div class="sync-button" [title]="route.tooltip" [routerLink]="route.destination">
      <div class="content">
        <mat-icon svgIcon="view-list"></mat-icon>
        <div class="title">{{ 'component:widget.overview' | i18nextCap }}</div>
      </div>
    </div>
  </section>
  <ng-content select="[footer]"></ng-content>
  <div class="info">
    {{ totalCountLabel }} <span *ngIf="counters">{{ totalCount() }}</span>
  </div>
</div>
