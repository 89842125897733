import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { LocationQuery, LocationService } from '../../../data-access';
import { GlobalProjectPermissions } from '../../../permission-access/rules';

@Injectable()
export class CustomerOverviewSandbox {
  isLoading$ = this.locationQuery.selectLoading();
  locations$ = this.locationQuery.selectAll();
  projects$ = this.locations$.pipe(
    map((locs) => {
      const array = locs.map((loc) => loc.projects.map((proj) => ({ ...proj, location: loc })));
      return array.length > 0 ? Array.prototype.concat.apply([], array) : null;
    }),
    filter(isNotNullOrUndefined),
  );
  projectUpdatePermission$ = this.projectPermissions.updateProjectPermission$;

  constructor(private locationQuery: LocationQuery, private locationService: LocationService, private projectPermissions: GlobalProjectPermissions) {}

  getLocations(): void {
    this.locationService.getLocations();
  }
}
