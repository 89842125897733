import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DOMAIN } from '@summa/models';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { onlyUniqueObjects } from '@summa/shared/util/typescript';
import { AutomationQuery, NotificationQuery, NotificationService } from '../../../../../data-access';

@Injectable()
export class ProjectAutomationHistorySandbox {
  automation$: Observable<DOMAIN.Automation> = this.automationQuery.selectActive();
  notifications$: Observable<DOMAIN.Notification[]> = this.notificationQuery.selectAll();
  params$ = this.router.selectParams();
  logbook$: Observable<{ state: string; time: number }[]> = this.notifications$.pipe(
    map((notifications) =>
      notifications.map((notification) => ({
        state: this.determineState(notification.message),
        time: notification.timestamp,
      })),
    ),
  );

  logbookChart$: Observable<{ state: string; start: Date; end: Date }[]> = this.logbook$.pipe(
    map((logbook) => {
      const logs = logbook.filter(onlyUniqueObjects).reverse();
      return logs.map((log, index) => {
        const end = logs[index + 1]?.time || new Date().getTime();
        return { state: log.state, start: new Date(log.time), end: new Date(end) };
      });
    }),
  );

  constructor(
    private automationQuery: AutomationQuery,
    private notificationQuery: NotificationQuery,
    private notificationService: NotificationService,
    private router: RouterQuery,
  ) {}

  getAutomationHistory(automationId: string, project: string, start: number): void {
    this.notificationService.findNotifications({ filters: [{ field: 'project', value: project }], timeInterval: { start }, query: automationId });
  }

  private determineState(message: string): string {
    switch (true) {
      case message.includes('-idle'):
        return 'idle';
      case message.includes('-off'):
        return 'off';
      case message.includes('-on'):
        return 'on';
      case message.includes('is activated'):
        // time automation
        return 'on';
      default:
        return 'unknown';
    }
  }
}
