/* eslint-disable @typescript-eslint/no-explicit-any */
import { DOMAIN } from '@summa/models';
import { isNullOrUndefined } from '@summa/shared/util/typescript';
import { Observable, of, throwError } from 'rxjs';
import { resolveDriverId } from './driver-key-resolver.util';

export const currentControl = {
  11916: { type: DOMAIN.device18Up, mA: 360 },
  11972: { type: DOMAIN.device27Up, mA: 360 },
  11965: { type: DOMAIN.device33Up, mA: 360 },
  21031: { type: DOMAIN.deviceLedStrip, mA: 65 },
  12318: { type: DOMAIN.deviceBoard, mA: 65 },
  12319: { type: DOMAIN.deviceBoard2, mA: 130 },
};

// puck => 150 -1000mA
// driver => 100 - 360mA

export function getCurrent(ledSpecName: string): number {
  const key = ledSpecName.substring(0, 5);
  const current = currentControl[key];
  if (!current) return -1;
  return current.mA;
}

export function getLedSpecificationType(ledSpecName: string): DOMAIN.DeviceType {
  const key = ledSpecName.substring(0, 5);
  const current = currentControl[key];
  if (!current) return null;
  return current.type;
}

/**
 * MQTT DriverType: default = 1
 * source: https://summa-systems.atlassian.net/wiki/spaces/RND/pages/281477121/FW+Driver+Firmware+-+ESP+-+New+implementation+current+setting+FW+version+TBD
 * 1 = Track/Compact- LED_DRIVER_3CH_13Watt
 * 2= Track/Compact - LED_DRIVER_1CH_40Watt
 * 3= DCdriver/Puck 3channel_DC_40Watt
 */
const DriverTypeMap = [
  { type: DOMAIN.driverPuck, currentType: [{ channels: 3, cuDriverType: 3 }] },
  {
    type: DOMAIN.driverCompact,
    currentTypes: [
      { channels: 1, cuDriverType: 2 },
      { channels: 3, cuDriverType: 1 },
    ],
  },
  {
    type: DOMAIN.driverTrack,
    currentTypes: [
      { channels: 1, cuDriverType: 2 },
      { channels: 3, cuDriverType: 1 },
    ],
  },
];

export function resolveCuDriverType(driverId: string): Observable<number> {
  // resolve driverId into driver domain
  const resolvedDriver = resolveDriverId(driverId);
  if (isNullOrUndefined(resolvedDriver))
    return throwError(() => {
      const error: any = new Error(`Can't resolve ${driverId}`);
      error.timestamp = Date.now();
      return error;
    });

  // search driver
  const driver = DriverTypeMap.find((dtm) => dtm.type === resolvedDriver.driverType);
  if (isNullOrUndefined(driver))
    return throwError(() => {
      const error: any = new Error(`Can't resolve ${driverId}, ${resolvedDriver.driverType} channels is not found`);
      error.timestamp = Date.now();
      return error;
    });

  // find the correct currentType:
  const cuDriver = driver.currentTypes.find((ct) => ct.channels === resolvedDriver.channelCount);
  if (isNullOrUndefined(cuDriver))
    return throwError(() => {
      const error: any = new Error(
        `Can't resolve ${driverId}, ${resolvedDriver.driverType} with ${resolvedDriver.channelCount} channels is not found`,
      );
      error.timestamp = Date.now();
      return error;
    });

  return of(cuDriver.cuDriverType);
}
