import { Injectable } from '@angular/core';
import { DOMAIN } from '@summa/portal/models/domain';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectQuery, ProjectService } from '../../../../data-access';

@Injectable()
export class ProjectGatewayUpsertSandbox {
  data$ = this.sidePanelService.getData();
  project$ = this.projectQuery.selectActive();
  upsertState$ = this.projectQuery.selectUpsertState$;

  constructor(private projectQuery: ProjectQuery, private projectService: ProjectService, private sidePanelService: SidePanelService) {}

  updateProjectGatewayKey(projectInput: Partial<DOMAIN.ProjectInput>) {
    this.projectService.patchProject(projectInput);
  }

  close(): void {
    this.projectService.resetProjectUIState();
    this.sidePanelService.close();
  }
}
