import { gql } from 'apollo-angular';

export const upsertCardQuery = gql`
  mutation upsertCard($card: CardInput!) {
    upsertCard(card: $card) {
      id
      projectKey
      title
      scenes {
        id
        projectKey
        name
        description
      }
      selectedSceneId
      sortOrder
      hidden
      devices {
        id
        key
        type
        address
        driver {
          key
          address
        }
      }
    }
  }
`;

export const activateCardSceneQuery = gql`
  mutation activateCardScene($cardId: String!, $sceneId: String!) {
    activateCardScene(cardId: $cardId, sceneId: $sceneId) {
      id
      projectKey
      title
      scenes {
        id
        projectKey
        name
        description
      }
      selectedSceneId
      sortOrder
      hidden
      devices {
        id
        key
        type
        address
        driver {
          key
          address
        }
      }
    }
  }
`;
