import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { ENV } from '@summa/models';
import { generateKey } from '@summa/shared/util/typescript';

import { DTO } from '@summa/portal/models/dto';
import { ProjectEditSandbox } from './project-edit.sandbox';

@Component({
  selector: 'summa-project-edit',
  templateUrl: './project-edit.page.html',
  styleUrls: ['./project-edit.page.scss'],
  providers: [ProjectEditSandbox],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectEditPage implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject();
  destroy$ = new Subject();

  projectKey: string;
  timezone = new FormControl('', [Validators.required]);

  @Output() cancelled$: Observable<void>;

  constructor(public sandbox: ProjectEditSandbox, private fb: FormBuilder, @Inject('environment') private environment: ENV.Environment) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleParams();
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
  }

  private handleSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      if (this.form.invalid) return;

      const projectInfo = {
        ...this.form.getRawValue(),
        key: project.key,
        locationId: project.location?.id || '',
        settings: {
          ...this.form.value.settings,
        },
      };
      this.sandbox.upsertProject(projectInfo);
    });
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => this.sandbox.getProject(projectKey));
  }

  private handleData(): void {
    this.sandbox.data$.pipe(takeUntil(this.destroy$)).subscribe((project: DTO.Project) => {
      if (!project) {
        return;
      }
      this.sandbox.getProject((project as DTO.Project).key);
    });

    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project: DTO.Project) => {
      this.projectKey = project.key;
      this.form = this.fb.group({
        id: project.id,
        name: [project.name, [Validators.required]],
        key: [{ value: project.key, disabled: true }, [Validators.required]],
        apiKey: [{ value: project.apiKey, disabled: true }, [Validators.required]],
        timezone: this.timezone,
        geolocation: this.fb.group({
          longitude: new FormControl(project.geolocation?.longitude, [Validators.min(-180), Validators.max(180)]),
          latitude: new FormControl(project.geolocation?.latitude, [Validators.min(-90), Validators.max(90)]),
        }),
        settings: this.fb.group({
          wifiSsid: [project.settings.wifiSsid, [Validators.required]],
          wifiPassword: [project.settings.wifiPassword, [Validators.required]],
        }),
      });
      this.form.patchValue({ timezone: project.timezone });
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  public generateApiKey(): void {
    this.form.get('apiKey').setValue(generateKey(this.projectKey, '-xxyxyx-xyxyxx'));
  }
}
