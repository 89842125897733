import { gql } from 'apollo-angular';

export const DriverFields = gql`
  fragment DriverFields on Driver {
    id
    key
    type
    projectKey
    batch
    fabCode
    dateCode
    address
    settings
    devices {
      id
      key
      address
      type
      category
      settings
    }
  }
`;
