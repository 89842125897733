import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg';

import { FloorplanDxfViewerComponent } from './floorplan-dxf-viewer/floorplan-dxf-viewer.component';

@NgModule({
  imports: [CommonModule, InlineSVGModule.forRoot()],
  declarations: [FloorplanDxfViewerComponent],
  exports: [FloorplanDxfViewerComponent],
})
export class SharedUiFloorplanModule {}
