import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultConfirmationString } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { I18NextCapPipe } from 'angular-i18next';
import { filter, first, map, Subject, switchMap, takeUntil, withLatestFrom } from 'rxjs';

import { PERMISSIONS } from '@summa/models';
import { ProjectDriverActionsSandbox } from './project-driver-actions-tab.sandbox';
import { SensorBaselineDialogComponent, SensorBaselineDialogData } from '../../../../components/sensor-baseline-dialog';
import { DriverTypeDialogComponent, DriverTypeDialogData } from '../../../../components/driver-type-dialog';
import { DriverCurrentDialogComponent, DriverCurrentDialogData } from '../../../../components/driver-current-dialog';
import { DriverContributionDialogComponent, DriverContributionDialogData } from '../../../../components/driver-contribution-dialog';
import { DriverTargetRippleDialogComponent, DriverTargetRippleDialogData } from '../../../../components/driver-target-ripple-dialog';
import { ReplaceDriverDialogComponent, ReplaceDriverDialogData } from '../../../../components/replace-driver-dialog';

const cancelButton = 'common:buttons.cancel';
const submitButton = 'common:buttons.submit';
@Component({
  selector: 'summa-project-driver-actions-tab',
  templateUrl: './project-driver-actions-tab.component.html',
  styleUrls: ['./project-driver-actions-tab.component.scss'],
  providers: [ProjectDriverActionsSandbox],
})
export class ProjectDriverActionsTabComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  setBaseline$ = new Subject<string>();
  resetSensors$ = new Subject<string>();
  deleteAllCurrentValues$ = new Subject<string>();
  setDriverType$ = new Subject<string>();
  setDriverCurrent$ = new Subject<string>();
  setDriverContribution$ = new Subject<string>();
  setTargetRipple$ = new Subject<string>();
  setReplaceDriver$ = new Subject<string>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  sensorBaseline: MatDialogRef<SensorBaselineDialogComponent, MatDialogConfig>;
  driverType: MatDialogRef<DriverTypeDialogComponent, MatDialogConfig>;
  driverCurrent: MatDialogRef<DriverCurrentDialogComponent, MatDialogConfig>;
  driverContribution: MatDialogRef<DriverContributionDialogComponent, MatDialogConfig>;
  targetRipple: MatDialogRef<DriverTargetRippleDialogComponent, MatDialogConfig>;
  replaceDriver: MatDialogRef<ReplaceDriverDialogComponent, MatDialogConfig>;

  readonly rndActionPermission: PERMISSIONS.Permission[] = [
    {
      application: { name: PERMISSIONS.applicationRnD, qualifiers: [PERMISSIONS.wildcardQualifier] },
      domain: { name: PERMISSIONS.domainProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
      subdomain: null,
      action: PERMISSIONS.actionRead,
    },
  ];

  constructor(public sandbox: ProjectDriverActionsSandbox, private dialog: MatDialog, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.replaceDriver?.close();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleSetBaseline();
    this.handleResetSensors();
    this.handleDeleteAllCurrentValues();
    this.handleSetDriverType();
    this.handleSetDriverCurrent();
    this.handleSetDriverContribution();
    this.handleSetTargetRipple();
    this.handleReplaceDriver();
  }

  private handleReplaceDriver(): void {
    this.setReplaceDriver$
      .pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.sandbox.driver$),
        switchMap(([key, driver]) => {
          const dialogConfig: MatDialogConfig<ReplaceDriverDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.actions.general.replace-driver'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
              driver,
            },
          };

          this.sandbox.resetReplaceState();
          this.replaceDriver = this.dialog.open(ReplaceDriverDialogComponent, dialogConfig);
          this.replaceDriver.componentInstance.state$ = this.sandbox.replaceState$;

          return this.replaceDriver.componentInstance.scan.pipe(
            map(({ newKey, replaceState }) => this.sandbox.replaceDriver(key, newKey, replaceState)),
          );
        }),
        map(() => {
          // todo: wait until success is true or error is true
          // this.replaceDriver.close();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSetBaseline(): void {
    this.setBaseline$
      .pipe(
        filter(isNotNullOrUndefined),
        map((key) => {
          const dialogConfig: MatDialogConfig<SensorBaselineDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.set-sensors-baseline'),
              content: this.i18next.transform('project:drivers.actions.sensors.confirm-set-sensors-to-baseline'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
            },
          };

          this.sensorBaseline = this.dialog.open(SensorBaselineDialogComponent, dialogConfig);
          return this.sensorBaseline.componentInstance.confirm.pipe(first()).subscribe((value: { baseline: number }) => {
            this.sandbox.executeSetBaseline(key, value.baseline);
            this.sensorBaseline.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleResetSensors(): void {
    this.resetSensors$
      .pipe(
        filter(isNotNullOrUndefined),
        map((key) => {
          const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
            autoFocus: true,
            data: {
              showConfirmationInput: true,
              title: this.i18next.transform('project:drivers.reset-sensors'),
              content: this.i18next.transform('project:drivers.actions.sensors.confirm-reset-sensors', { field: key }),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform('common:buttons.confirm'),
              confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', {
                field: defaultConfirmationString,
              }),
              confirmationString: defaultConfirmationString,
            },
          };

          this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
          return this.confirmDialog.componentInstance.confirm.pipe(first()).subscribe(() => {
            this.sandbox.executeResetSensors(key);
            this.confirmDialog.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleDeleteAllCurrentValues(): void {
    this.deleteAllCurrentValues$
      .pipe(
        filter(isNotNullOrUndefined),
        map((key) => {
          const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
            autoFocus: true,
            data: {
              showConfirmationInput: true,
              title: this.i18next.transform('project:drivers.actions.settings.delete-all-current-values'),
              content: this.i18next.transform('project:drivers.actions.settings.confirm-delete-all', { field: key }),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform('common:buttons.confirm'),
              confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', {
                field: defaultConfirmationString,
              }),
              confirmationString: defaultConfirmationString,
            },
          };

          this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
          return this.confirmDialog.componentInstance.confirm.pipe(first()).subscribe(() => {
            this.sandbox.executeDeleteCurrentValues(key);
            this.confirmDialog.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSetDriverType(): void {
    this.setDriverType$
      .pipe(
        filter(isNotNullOrUndefined),
        map((key) => {
          const dialogConfig: MatDialogConfig<DriverTypeDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.actions.settings.set-driver-type'),
              content: this.i18next.transform('project:drivers.actions.settings.set-driver-type-content'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
            },
          };

          this.driverType = this.dialog.open(DriverTypeDialogComponent, dialogConfig);
          return this.driverType.componentInstance.confirm.pipe(first()).subscribe((value: { driverType: number }) => {
            this.sandbox.executeSetDriverType(key, value.driverType);
            this.driverType.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSetDriverCurrent(): void {
    this.setDriverCurrent$
      .pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.sandbox.driver$),
        map(([key, driver]) => {
          const dialogConfig: MatDialogConfig<DriverCurrentDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.actions.settings.set-driver-current'),
              content: this.i18next.transform('project:drivers.actions.settings.set-driver-current-content'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
              driver,
            },
          };

          this.driverCurrent = this.dialog.open(DriverCurrentDialogComponent, dialogConfig);
          return this.driverCurrent.componentInstance.confirm.pipe(first()).subscribe((value: { channels: [] }) => {
            this.sandbox.executeSetDriverChannels(key, value.channels);
            this.driverCurrent.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSetDriverContribution(): void {
    this.setDriverContribution$
      .pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.sandbox.driver$),
        map(([key, driver]) => {
          const dialogConfig: MatDialogConfig<DriverContributionDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.actions.settings.set-target-ripple'),
              content: this.i18next.transform('project:drivers.actions.settings.set-target-ripple-content'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
              driver,
            },
          };

          this.driverContribution = this.dialog.open(DriverContributionDialogComponent, dialogConfig);
          return this.driverContribution.componentInstance.confirm.pipe(first()).subscribe((value: { channels: [] }) => {
            this.sandbox.executeSetDriverChannels(key, value.channels);
            this.driverContribution.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleSetTargetRipple(): void {
    this.setTargetRipple$
      .pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.sandbox.driver$),
        map(([key, driver]) => {
          const dialogConfig: MatDialogConfig<DriverTargetRippleDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.actions.settings.set-target-ripple'),
              content: this.i18next.transform('project:drivers.actions.settings.set-target-ripple-content'),
              cancelButton: this.i18next.transform(cancelButton),
              primaryButton: this.i18next.transform(submitButton),
              driver,
            },
          };

          this.targetRipple = this.dialog.open(DriverTargetRippleDialogComponent, dialogConfig);
          return this.targetRipple.componentInstance.confirm.pipe(first()).subscribe((value: { channels: [] }) => {
            this.sandbox.executeSetDriverChannels(key, value.channels);
            this.targetRipple.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
