import { gql } from 'apollo-angular';

export const GetCustomerLocationsQuery = gql`
  query {
    getCustomerLocations {
      id
      name
      city
      country
      street
      number
      zipCode
      state
      projects {
        id
        name
        key
      }
    }
  }
`;

export const GetLocationQuery = gql`
  query getLocation($id: String!) {
    getLocation(id: $id) {
      id
      name
      city
      country
      street
      number
      zipCode
      state
      projects {
        id
        name
        key
      }
    }
  }
`;
