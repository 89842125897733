<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$ | async) ? 'project:control-panels.upsert.edit-title' :'project:control-panels.upsert.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ | async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{project.key}}) {{project.name}}</div>
      </div>
      <div *ngIf="sandbox.controlPanel$ | async as controlPanel">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ controlPanel.id}}</div>
      </div>
    </section>
  </ng-container>

  <ng-container body>
    <form id="controlPanelForm" *ngIf="form" [formGroup]="form" class="form" (ngSubmit)="submit$.next(form)">
      <section fxLayout="column">
        <!-- Control Panel name -->
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
          <mat-error *ngIf="form.get('name')?.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>

        <!-- Control Panel description -->
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.description' | i18nextCap}}</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>

        <!-- Control Panel Default Scene  -->
        <mat-form-field>
          <mat-select formControlName="defaultScene" [placeholder]="'project:control-panels.default-scene' | i18nextCap">
            <mat-option *ngFor="let scene of sandbox.scenes$ | async" [value]="scene.id"> {{scene.name}} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="useAllDevices" class="mb-2">{{'project:control-panels.upsert.use-all-devices' | i18nextCap}}</mat-checkbox>

        <!-- Control Panel Devices  -->
        <mat-form-field *ngIf="!form.value.useAllDevices" class="mb-2">
          <mat-select formControlName="devices" [placeholder]="'common:dictionary.device_plural' | i18nextCap" [multiple]="true" #multiSelect>
            <mat-option>
              <ngx-mat-select-search
                [formControl]="deviceFilter"
                [placeholderLabel]="'project:control-panels.upsert.search-device' | i18nextCap"
                [noEntriesFoundLabel]="'common:dictionary.no-search-result' | i18nextCap"
              ></ngx-mat-select-search>
            </mat-option>
            <header class="select-button-header">
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, form.controls.devices, true)">
                {{'common:buttons.select-all' | i18nextCap}}
              </button>
              <button mat-button class="button mat-button-dark" (click)="toggleSelectAll(filteredDevices$, form.controls.devices, false)">
                {{'common:buttons.deselect-all' | i18nextCap}}
              </button>
            </header>
            <ng-container *ngFor="let device of filteredDevices$ | async">
              <mat-option [value]="device.id"> {{device.key}} </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <!-- Control Panel Scenarios  -->
        <section formArrayName="scenarios">
          <section *ngFor="let _ of scenarios.controls; index as i">
            <section class="card-entry pb-2" [formGroupName]="i">
              <section class="remove mt-2">
                <mat-icon (click)="removeScenario(i)" title="Remove channel" [svgIcon]="'common:icons.remove-card' | i18next"></mat-icon>
              </section>

              <section fxLayout="column">
                <mat-form-field>
                  <mat-select formControlName="id" [placeholder]="'common:dictionary.scenario' | i18nextCap">
                    <!-- <mat-option>
                      <ngx-mat-select-search
                        [formControl]="scenarioFilter"
                        [placeholderLabel]="'project:control-panels.upsert.search-scenario' | i18nextCap"
                        [noEntriesFoundLabel]="'common:dictionary.no-search-result' | i18nextCap"
                      ></ngx-mat-select-search>
                    </mat-option> -->
                    <ng-container *ngFor="let scenario of filteredScenarios$ | async">
                      <mat-option [value]="scenario.id">{{scenario.name}}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-select formControlName="type" [placeholder]="'common:dictionary.type' | i18nextCap">
                    <ng-container *ngFor="let scenarioType of scenarioTypes">
                      <mat-option [value]="scenarioType">{{scenarioType}}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </section>
            </section>
          </section>

          <mat-icon
            class="add mt-2"
            (click)="addScenario()"
            [title]="'reseller:controls.add.channel-tooltip' | i18nextCap"
            [svgIcon]="'common:icons.add-card' | i18next"
          >
            {{'reseller:controls.add.channel-text' | i18nextCap}}
          </mat-icon>
        </section>
      </section>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="submit" form="controlPanelForm" class="button save" [disabled]="form?.invalid">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
