<div class="summa-card clickable control-card" [class.is-active]="control.isActive" *ngIf="control" fxLayout="column" fxLayoutAlign="start center">
  <section class="info-button" *ngIf="!hideEdit">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon svgIcon="dots-vertical"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="updateControl.emit()">
        <mat-icon svgIcon="file-document-edit-outline"></mat-icon>
        <span class="pr-1">{{ 'project:control-panels.detail.edit-control' | i18nextCap }}</span>
      </button>
      <button mat-menu-item (click)="removeControl.emit()">
        <mat-icon svgIcon="file-document-box-remove-outline"></mat-icon>
        <span class="pr-1">{{ 'project:control-panels.detail.remove-control' | i18nextCap }}</span>
      </button>
    </mat-menu>
  </section>

  <section class="py-3" (click)="controlClicked()">
    <div class="title-container" fxLayout="row" fxLayoutAlign="center" [title]="control.name">
      <h2 class="m-0 p-0 mb-1 title">{{ control.name }}</h2>
    </div>

    <div fxLayout="column" fxLayoutAlign="start center">
      <div class="circle mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewbox="0 0 100 100">
          <path class="background" d="M 62 120 A 70 70 90 1 1 166 120" style="fill: none" />
          <path class="brightness" d="M 62 120 A 70 70 90 1 1 166 120" style="fill: none" />
        </svg>
      </div>

      <mat-icon class="light" [svgIcon]="control.icon" [ngStyle]="{ 'box-shadow': control.isActive ? glowEffect : 'none' }"></mat-icon>
    </div>
  </section>
</div>
