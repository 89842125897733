import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectCardAddPage } from '../project-card-add/project-card-add.page';
import { ProjectCardPreviewPage } from '../project-card-preview/project-card-preview.page';

import { ProjectCardOverviewSandbox } from './project-card-overview.sandbox';

@Component({
  selector: 'summa-project-card-overview',
  templateUrl: './project-card-overview.page.html',
  styleUrls: ['./project-card-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectCardOverviewSandbox],
})
export class ProjectCardOverviewPage implements OnInit {
  destroy$ = new Subject();
  remove$ = new Subject<DOMAIN.Card>();
  upsertPanel$ = new Subject<DOMAIN.Card>();
  detailsPanel$ = new Subject<DOMAIN.Card>();
  cards = new MatTableDataSource<DOMAIN.Card>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  columnsToDisplay = ['title', 'sortOrder', 'hidden', 'selectedScene', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ProjectCardOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnInit(): void {
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();

    this.sandbox.cards$.pipe(takeUntil(this.destroy$)).subscribe((cards: DOMAIN.Card[]) => {
      this.cards.data = cards;
      this.cards.sort = this.sort;
      this.cards.paginator = this.paginator;
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getCards(projectKey);
    });
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.cards.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((card) => {
      this.sidePanelService.open(ProjectCardAddPage, card);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((card: DOMAIN.Card) => {
      this.sandbox.getCard(card.id);
      this.sidePanelService.open(ProjectCardPreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((card: DOMAIN.Card) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: card.title }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeCard(card.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
