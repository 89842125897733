import { gql } from 'apollo-angular';

export const upsertSceneQuery = gql`
  mutation upsertScene($scene: SceneInput!) {
    upsertScene(scene: $scene) {
      id
      projectKey
      name
      description
      sortOrder
      dynamic {
        service
        geolocation {
          latitude
          longitude
          timezone
        }
        space
      }
      default {
        dimValue
        cct
        duv
        x
        y
      }
    }
  }
`;

export const removeSceneQuery = gql`
  mutation removeScene($id: String!) {
    removeScene(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
