import { Injectable } from '@angular/core';
import { ActiveState, arrayAdd, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

export interface NotificationState extends EntityState<DOMAIN.Notification, string>, ActiveState {
  live: DOMAIN.Notification[];
  total: number;
}

const initialState = {
  live: [],
  total: 0,
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'notifications', idKey: 'id', resettable: true })
export class NotificationStore extends EntityStore<NotificationState, DOMAIN.Notification> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  resetLiveState = () => this.update({ live: initialState.live });
  resetTotal = () => this.update({ total: 0 });
  addLiveNotifications = (notification: DOMAIN.Notification) =>
    this.update(({ live }) => ({
      live: arrayAdd(live, notification),
    }));
  setTotal = (total: number) => this.update({ total });
}

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'todaysNotifications', idKey: 'id', resettable: true })
export class TodaysNotificationStore extends EntityStore<NotificationState, DOMAIN.Notification> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  resetLiveState = () => this.update({ ...initialState });
  addLiveNotifications = (notification: DOMAIN.Notification) =>
    this.update(({ live }) => ({
      live: arrayAdd(live, notification),
    }));
}
