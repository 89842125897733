<div class="reseller-card p-2 gateway" fxLayout="column wrap">
  <ng-container
    *ngIf="{
      updatePermission: this.sandbox.updatePermission$ | async
    } as permissions"
  >
    <ng-container *ngIf="sandbox.gateway$ | async as gateway; else noGateway">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header pb-0">{{ 'project:gateway.widget-title' | i18nextCap }}</div>

        <div fxFlex fxLayoutAlign="end center">
          <ng-container [ngSwitch]="gateway.state">
            <div class="badge success m-0" *ngSwitchCase="'ON'">{{ 'common:dictionary.states.online' | i18next }}</div>
            <div class="badge danger m-0" *ngSwitchCase="'OFF'">{{ 'common:dictionary.states.offline' | i18next }}</div>
            <div class="badge warning m-0" *ngSwitchDefault>{{ 'common:dictionary.states.unknown' | i18next }}</div>
          </ng-container>

          <button [permission-check]="permissions.updatePermission" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="upsertGateway$.next(gateway.key)">
              <mat-icon [svgIcon]="'common:icons.edit' | i18next"></mat-icon>
              <span class="pr-1">{{ 'project:gateway.edit.title' | i18nextCap }}</span>
            </button>
            <button mat-menu-item (click)="updateGateway$.next()">
              <mat-icon [svgIcon]="'common:icons.update-local' | i18next"></mat-icon>
              <span class="pr-1">{{ 'project:gateway.update.title' | i18nextCap }}</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <section class="mt-2" fxLayout="row" fxLayoutGap="4px">
        <button (click)="gatewayDetail$.next()" class="sync-button" [title]="'dashboard:dashboard.open' | i18nextCap">
          <div class="content">
            <mat-icon [svgIcon]="'common:icons.info' | i18next"></mat-icon>
            <div class="title">{{ 'common:dictionary.information' | i18nextCap }}</div>
          </div>
        </button>
        <button
          [disabled]="gateway.state !== 'ON' || !!(sandbox.upgradeRequired$ | async)"
          [title]="'common:buttons.sync-all' | i18nextCap"
          [title]="(gateway.state !== 'ON' || !!(sandbox.upgradeRequired$ | async)) && 'project:gateway.sync-disabled' | i18nextCap"
          [permission-check]="permissions.updatePermission"
          (click)="syncGateway$.next()"
          class="sync-button"
        >
          <div class="content">
            <mat-icon svgIcon="cloud-sync-outline"></mat-icon>
            <div class="title">{{ 'common:buttons.sync-data' | i18nextCap }}</div>
          </div>
        </button>
      </section>

      <div class="info mt-2">{{ 'project:gateway.api-version' | i18nextCap }} {{ sandbox.apiVersion$ | async }}</div>
    </ng-container>

    <ng-template #noGateway>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="header pb-0">{{ 'project:gateway.widget-title' | i18nextCap }}</div>

        <div
          [permission-check]="permissions.updatePermission"
          class="create"
          [title]="'project:gateways.add' | i18nextCap"
          (click)="upsertGateway$.next(null)"
        >
          <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
        </div>
      </div>

      <div class="not-available mt-2">{{ 'project:gateway.nothing-connected' | i18nextCap }}</div>
    </ng-template>
  </ng-container>
</div>
