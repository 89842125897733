<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{ ((sandbox.automation$ |async)? 'reseller:automation.add.edit-title': 'reseller:automation.add.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{ project.key}}){{ project.name}}</div>
      </div>
      <div *ngIf="sandbox.automation$ |async as automation">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{automation.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="!(loading$ | async)" [formGroup]="form" class="form p-0 pt-2">
      <section fxLayout="column wrap" fxLayoutGap="1rem">
        <mat-checkbox class="mb-1" formControlName="state">{{'reseller:automation.add.status' | i18nextCap}} </mat-checkbox>

        <mat-form-field fxFlex appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>

        <mat-form-field fxFlex appearance="legacy">
          <mat-label>{{'common:dictionary.description' | i18nextCap}}</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>

        <mat-form-field fxFlex appearance="legacy">
          <mat-label>{{'common:dictionary.type' | i18nextCap}}</mat-label>
          <mat-select formControlName="type">
            <mat-option value="">{{ 'reseller:automation.add.select-type' | i18nextCap }}</mat-option>
            <mat-option *ngFor="let type of types" [value]="type"> {{ type |titlecase}} </mat-option>
          </mat-select>
        </mat-form-field>
        <!--
          TIMER SETTINGS
         -->

        <section formGroupName="timerSettings" fxFlex *ngIf='form.value.type === "timer"'>
          <mat-form-field class="w-100" appearance="legacy">
            <mat-label>{{'common:dictionary.scenario' | i18nextCap}}</mat-label>
            <mat-select formControlName="scenarioId">
              <mat-option *ngFor="let scenario of sandbox.scenarios$ | async" [value]="scenario.id" [title]="scenario.description">
                {{ scenario.name |titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>

        <!--
          MOTION SETTINGS
         -->
        <section formGroupName="motionSettings" fxFlex fxLayout="column" *ngIf='form.value.type === "motion"' class="mb-0">
          <section fxLayout="row" fxLayoutGap="0.5rem">
            <mat-form-field appearance="legacy" fxFlex.gt-xs="50">
              <mat-select formControlName="sensorDrivers" [placeholder]="'assembly:drivers.title' | i18nextCap" [multiple]="true">
                <mat-option>
                  <ngx-mat-select-search
                    [formControl]="driverFilter"
                    [placeholderLabel]="'reseller:cards.find-fixture' | i18nextCap"
                    [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                  ></ngx-mat-select-search>
                </mat-option>
                <header class="select-button-header">
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAllSensorDrivers(filteredDrivers$, true)">
                    {{'common:buttons.select-all' | i18nextCap}}
                  </button>
                  <button mat-button class="button mat-button-dark" (click)="toggleSelectAllSensorDrivers(filteredDrivers$, false)">
                    {{'common:buttons.deselect-all' | i18nextCap}}
                  </button>
                </header>
                <mat-option *ngFor="let driver of filteredDrivers$ | async" [value]="driver.id"> {{ driver.key | uppercase}} </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex appearance="legacy" fxFlex.gt-xs="50">
              <mat-label>{{'common:dictionary.threshold' | i18nextCap}}</mat-label>
              <input type="number" matInput formControlName="threshold" />
            </mat-form-field>
          </section>

          <mat-radio-group formControlName="triggerType" aria-label="Select an option" class="mb-3">
            <mat-radio-button class="mr-2" *ngFor="let choice of motionTriggerTypes" [value]="choice">{{choice}} </mat-radio-button>
          </mat-radio-group>

          <!-- Motion Group Trigger -->
          <ng-container
            formGroupName="motionGroupTrigger"
            *ngIf="form.get('motionSettings.triggerType').value === 'group' && groups && groups.length > 0"
          >
            <mat-form-field appearance="legacy">
              <mat-select formControlName="group" [placeholder]="'common:dictionary.card' | i18nextCap">
                <mat-option *ngFor="let group of groups" [value]="group.id">{{ group.title }}</mat-option>
              </mat-select>
            </mat-form-field>

            <p class="mb-1 mt-0">{{ 'reseller:automation.add.motion-detected' | i18nextCap }}</p>
            <section formGroupName="presence">
              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-select formControlName="scene" [placeholder]="'common:dictionary.scene' | i18nextCap">
                  <mat-option *ngFor="let scene of motionGroupScenes" [value]="scene.id">{{ scene.name }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.hold-time' | i18nextCap}}</mat-label>
                <input matInput formControlName="holdTime" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy">
                <mat-label>{{'common:dictionary.fade-duration' | i18nextCap}}</mat-label>
                <input matInput formControlName="fadeInDuration" type="number" />
              </mat-form-field>
            </section>

            <p class="mb-1 mt-0">{{ 'reseller:automation.add.no-motion-detected' | i18nextCap }}</p>
            <section formGroupName="idle">
              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.brightness' | i18nextCap}}</mat-label>
                <input matInput formControlName="brightness" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.hold-time' | i18nextCap}}</mat-label>
                <input matInput formControlName="holdTime" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy">
                <mat-label>{{'common:dictionary.fade-duration' | i18nextCap}}</mat-label>
                <input matInput formControlName="fadeInDuration" type="number" />
              </mat-form-field>
            </section>
          </ng-container>

          <!-- Motion Scenario Trigger -->
          <ng-container formGroupName="motionScenarioTrigger" *ngIf="form.get('motionSettings.triggerType').value === 'scenario'">
            <p class="mb-1 mt-0">{{ 'reseller:automation.add.motion-detected' | i18nextCap }}</p>
            <section formGroupName="presence">
              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-select formControlName="scenario" [placeholder]="'common:dictionary.scenario' | i18nextCap">
                  <mat-option *ngFor="let scenario of sandbox.scenarios$ | async" [value]="scenario.id" [title]="scenario.description">
                    {{ scenario.name | titlecase }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.hold-time' | i18nextCap}}</mat-label>
                <input matInput formControlName="holdTime" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy">
                <mat-label>{{'common:dictionary.fade-duration' | i18nextCap}}</mat-label>
                <input matInput formControlName="fadeInDuration" type="number" />
              </mat-form-field>
            </section>

            <p class="mb-1 mt-0">{{ 'reseller:automation.add.no-motion-detected' | i18nextCap }}</p>
            <section formGroupName="idle">
              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.brightness' | i18nextCap}}</mat-label>
                <input matInput formControlName="brightness" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy" class="mr-2">
                <mat-label>{{'common:dictionary.hold-time' | i18nextCap}}</mat-label>
                <input matInput formControlName="holdTime" type="number" />
              </mat-form-field>

              <mat-form-field fxFlex appearance="legacy">
                <mat-label>{{'common:dictionary.fade-duration' | i18nextCap}}</mat-label>
                <input matInput formControlName="fadeInDuration" type="number" />
              </mat-form-field>
            </section>
          </ng-container>
        </section>

        <!-- TRIGGERS -->
        <ng-container *ngIf='form.value.type === "motion" || form.value.type === "timer"'>
          <p class="section-title mb-2 mt-0">{{'common:dictionary.trigger' | i18nextCap}}</p>
          <ng-container formArrayName="triggers">
            <section class="card-entry pb-1" *ngFor="let _ of triggers.controls; index as i" [formGroupName]="i">
              <div class="text-right">
                <mat-icon
                  class="remove mr-0"
                  (click)="removeTrigger(i)"
                  title="Remove trigger"
                  [svgIcon]="'common:icons.remove-card' | i18next"
                ></mat-icon>
              </div>

              <div fxLayoutGap="16px">
                <mat-form-field fxFlex appearance="legacy">
                  <mat-select formControlName="customDay" [placeholder]="'common:dictionary.day' | i18nextCap">
                    <mat-option *ngFor="let day of customDays" [value]="day"> {{'common:dictionary.days.'+ day | i18nextCap }} </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex appearance="legacy" *ngIf="form.value.triggers[i].customDay === 'custom'">
                  <mat-select formControlName="specificDays" [multiple]="true" [placeholder]="'common:dictionary.day' | i18nextCap">
                    <mat-option *ngFor="let day of days" [value]="day"> {{'common:dictionary.days.'+ day | i18nextCap }} </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayoutGap="16px">
                <mat-form-field fxFlex appearance="legacy">
                  <mat-label>{{'common:dictionary.start-time' | i18nextCap}}</mat-label>
                  <input type="time" matInput formControlName="startTime" />
                </mat-form-field>

                <mat-form-field fxFlex appearance="legacy" *ngIf="form.value.type === 'motion'">
                  <mat-label>{{'common:dictionary.end-time' | i18nextCap}}</mat-label>
                  <input type="time" matInput formControlName="endTime" />
                </mat-form-field>
              </div>
            </section>
            <mat-icon
              class="add mt-2"
              (click)="addTrigger()"
              [title]="'reseller:automation.add.add-trigger' | i18nextCap"
              [svgIcon]="'common:icons.add-card' | i18next"
            >
              {{'reseller:automation.add.add-trigger' | i18nextCap}}
            </mat-icon>
          </ng-container>
        </ng-container>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section *ngIf="!(loading$ |async)" class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="form.invalid" mat-button type="button" (click)="submit$.next(null)" class="button save">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
