import { gql } from 'apollo-angular';

export const GatewaySubscription = gql`
  subscription observeGateways($projects: [String!]!) {
    observeGatewayStates(projects: $projects) {
      state
      messageTime
      addresses
      key
      modified
    }
  }
`;
