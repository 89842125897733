import { gql } from 'apollo-angular';

export const GetAutomationsQuery = gql`
  query getAutomations($skip: Int, $take: Int, $projectKey: String) {
    getAutomations(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      # projectKey
      name
      description
      type
      state
      scenario {
        id
        name
        description
        # icon
        # sortOrder
      }
      settings {
        triggers {
          days
          startTime
          endTime
        }
        sensorDrivers {
          id
          key
        }
        threshold
        triggerType
        presence {
          scenario {
            id
            name
          }
          group {
            id
            title
          }
          scene {
            id
            name
          }
          holdTime
          fadeInDuration
        }
        idle {
          brightness
          holdTime
          fadeInDuration
        }
      }
    }
  }
`;

export const GetAutomationQuery = gql`
  query getAutomation($id: String!) {
    getAutomation(id: $id) {
      id
      # projectKey
      name
      description
      type
      state
      scenario {
        id
        name
        description
        # icon
        # sortOrder
      }
      settings {
        triggers {
          days
          startTime
          endTime
        }
        sensorDrivers {
          id
          key
        }
        threshold
        triggerType
        presence {
          scenario {
            id
            name
          }
          group {
            id
            title
          }
          scene {
            id
            name
          }
          holdTime
          fadeInDuration
        }
        idle {
          brightness
          holdTime
          fadeInDuration
        }
      }
    }
  }
`;
