import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { NotificationPreviewPage } from '../notification-preview/notification-preview.page';

import { NotificationOverviewSandbox } from './notification-overview.sandbox';

@Component({
  selector: 'summa-notification-overview',
  templateUrl: './notification-overview.page.html',
  styleUrls: ['./notification-overview.page.scss'],
  providers: [NotificationOverviewSandbox],
})
export class NotificationOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  previewPanel$ = new Subject();
  notifications = new MatTableDataSource<DOMAIN.Notification>();

  columnsToDisplay = ['timestamp', 'project', 'type', 'title', 'message'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public sandbox: NotificationOverviewSandbox, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleParams();
    this.handleNotifications();
    this.handlePreview();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.notifications.filter = filterValue.trim().toLowerCase();
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ resellerId }) => {
      this.sandbox.getReseller(resellerId);
    });

    this.sandbox.userProjects$
      .pipe(
        withLatestFrom(this.sandbox.params$),
        filter(([projects]) => projects && projects.length > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(([projects, params]) => {
        const notificationProjects = params.projectKey ? [params.projectKey] : projects.map((project) => project.key);

        // Get initial notifications
        this.sandbox.getNotifications(notificationProjects, 1000);

        // Observe all projects for notifications
        this.sandbox.observeNotifications(notificationProjects);
      });
  }

  private handleNotifications(): void {
    this.sandbox.notifications$.pipe(takeUntil(this.destroy$)).subscribe((notifications) => {
      this.notifications.data = notifications;
      this.notifications.sort = this.sort;
      this.notifications.paginator = this.paginator;
    });
  }

  private handlePreview(): void {
    this.previewPanel$.pipe(takeUntil(this.destroy$)).subscribe((notification: DOMAIN.Notification) => {
      this.sidePanelService.open(NotificationPreviewPage, notification);
    });
  }

  getType(notification: DOMAIN.Notification): string {
    switch (notification.type) {
      case CONSTANTS.notificationError:
        return 'danger';
      case CONSTANTS.notificationWarning:
        return 'warning';
      case CONSTANTS.notificationSuccess:
        return 'success';
      default:
        return 'info';
    }
  }
}
