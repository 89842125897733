<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.data$ | async as notification">
  <ng-container header>
    <h1>
      <mat-icon class="text-state" [ngClass]="getType(notification)" [svgIcon]="'common:icons.notification' | i18next"> </mat-icon>
      {{ notification.title }}
    </h1>
  </ng-container>

  <ng-container info>
    <div class="notification-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.project' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ notification.project }}</div>
          </div>

          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.timestamp' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ notification.timestamp * 1000 | date: "full" }}</div>
          </div>

          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.type' | i18nextCap }}</div>
            <div class="content, text-state" [ngClass]="getType(notification)" fxFlex="80">{{ notification.type |uppercase}}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <div class="notification-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.title' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ notification.title | json }}</div>
          </div>

          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.message' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ notification.message | json }}</div>
          </div>

          <div *ngIf="notification.trace" class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:notifications.preview.trace' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ notification.trace | json }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <!-- buttons -->
    </section>
  </ng-container>
</summa-side-panel-content>
