/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { DOMAIN, PortalAPI } from '@summa/models';

import {
  executeCommandQuery,
  executeCurrentChangeCommandQuery,
  executeMqttCommandQuery,
  executeResetSensorsCommandQuery,
  executeSetBaselineCommandQuery,
  executeSyncCommandQuery,
  executeDeleteCurrentValuesCommandQuery,
  // executeUpdateFirmwareCommandQuery,
  executeUpdateLocalCommandQuery,
  executeDriverSettingsCommandQueryQuery,
} from './graphql/command.gql-mutation';

const somethingWentWrong = 'message:something-went-wrong';
const successfulExecuted = 'message:successful-executed';
@Injectable({
  providedIn: 'root',
})
export class CommandService {
  constructor(private apollo: Apollo, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  executeCommand(command: DOMAIN.CommandInput): void {
    this.apollo.mutate({ mutation: executeCommandQuery, variables: { command } }).subscribe();
  }

  executeMqttCommand(command: PortalAPI.MqttCommand): void {
    this.apollo.mutate({ mutation: executeMqttCommandQuery, variables: { command } }).subscribe();
  }

  executeUpdateLocalCommand(projectKey: string): void {
    this.apollo.mutate({ mutation: executeUpdateLocalCommandQuery, variables: { projectKey } }).subscribe();
  }

  executeSyncCommand(command: PortalAPI.SyncCommand): void {
    this.apollo.mutate({ mutation: executeSyncCommandQuery, variables: { command } }).subscribe();
  }

  executeCurrentChangeCommand(command: PortalAPI.CurrentChangeInput): void {
    this.apollo.mutate({ mutation: executeCurrentChangeCommandQuery, variables: { command } }).subscribe();
  }

  // executeUpdateFirmwareCommand(command: PortalAPI.UpdateFirmwareCommand): void {
  //   this.apollo.mutate({ mutation: executeUpdateFirmwareCommandQuery, variables: { command } }).subscribe();
  // }

  executeSetBaselineCommand(command: PortalAPI.SetBaselineCommand): void {
    this.apollo.mutate({ mutation: executeSetBaselineCommandQuery, variables: { command } }).subscribe((response: any) => {
      if (response.data?.executeSetBaselineCommand) {
        this.notifier.notify('success', this.i18next.transform(successfulExecuted));
      } else {
        this.notifier.notify('warning', this.i18next.transform(somethingWentWrong));
      }
    });
  }

  executeResetSensorsCommand(driverKey): void {
    this.apollo.mutate({ mutation: executeResetSensorsCommandQuery, variables: driverKey }).subscribe((response: any) => {
      if (response.data?.executeResetSensorsCommand) {
        this.notifier.notify('success', this.i18next.transform('message:successful-reset'));
      } else {
        this.notifier.notify('warning', this.i18next.transform(somethingWentWrong));
      }
    });
  }

  executeDeleteCurrentValuesCommand(driverKey): void {
    this.apollo.mutate({ mutation: executeDeleteCurrentValuesCommandQuery, variables: driverKey }).subscribe((response: any) => {
      if (response.data?.executeDeleteCurrentValuesCommand) {
        this.notifier.notify('success', this.i18next.transform(successfulExecuted));
      } else {
        this.notifier.notify('warning', this.i18next.transform(somethingWentWrong));
      }
    });
  }

  executeDriverSettingsCommand(command: PortalAPI.DriverSettingsCommand): void {
    this.apollo.mutate({ mutation: executeDriverSettingsCommandQueryQuery, variables: { command } }).subscribe((response: any) => {
      if (response.data?.executeDriverSettingsCommand) {
        this.notifier.notify('success', this.i18next.transform(successfulExecuted));
      } else {
        this.notifier.notify('warning', this.i18next.transform(somethingWentWrong));
      }
    });
  }
}
