import { DOMAIN } from '..';

export const defaultFixtureLog: DOMAIN.FixtureLog = {
  topic: 'fixture_log_topic',
  amountCh: 1,
  channels: [
    {
      id: 'ch1',
      intensity: -1,
      // current: -1,
      // offTime: -1,
    },
  ],
  ip: 'n.a.',
  temp: -1,
  ticks: -1,
  type: 'nChannel',
  version: '-1',
  timestamp: -1,
  // walkTime: -1,
  // dimLevel: -1,
  // fadeRate: -1,
};
