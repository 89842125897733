import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { PERMISSIONS } from '@summa/models';
import { userHasPermission } from '@summa/shared/auth/util';

@Directive({
  selector: '[summaPermissions]',
})
export class PermissionsDirective implements OnInit {
  @Input('summaPermissions') requiredPermissions: PERMISSIONS.Permission[] | PERMISSIONS.Permission = [];

  @Input() userPermissions: PERMISSIONS.Permission[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private el: ElementRef<any>) {}

  ngOnInit(): void {
    if (!this.requiredPermissions || !this.userPermissions || !userHasPermission(this.userPermissions, this.requiredPermissions)) {
      this.el.nativeElement.hidden = true;
      this.el.nativeElement.style.display = 'none';
    }
  }
}
