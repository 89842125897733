import { gql } from 'apollo-angular';

export const insertFloorplanAndFixtures = gql`
  mutation InsertFloorplanAndFixtures($floorplanInput: FloorplanInput!, $fixtureInputs: [FixtureInput!]!) {
    insertFloorplanAndFixtures(floorplanInput: $floorplanInput, fixtureInputs: $fixtureInputs) {
      id
      name
      projectKey
      description
      fileName
      fileLocation
    }
  }
`;

export const overrideFloorplan = gql`
  mutation Mutation($floorplanOverrides: FloorplanOverrideOptions!, $fixtureInputs: [FixtureInput!]!, $floorplanInput: FloorplanInput!) {
    overrideFloorplan(floorplanOverrides: $floorplanOverrides, fixtureInputs: $fixtureInputs, floorplanInput: $floorplanInput) {
      description
      fileLocation
      fileName
      id
      projectKey
      name
    }
  }
`;
