import { Component, OnInit } from '@angular/core';

import { CustomerOverviewSandbox } from './customer-overview.sandbox';

@Component({
  selector: 'summa-customer-overview',
  templateUrl: './customer-overview.page.html',
  styleUrls: ['./customer-overview.page.scss'],
  providers: [CustomerOverviewSandbox],
})
export class CustomerOverviewPage implements OnInit {
  columnsToDisplay = ['id', 'name', 'location', 'country'];

  constructor(public sandbox: CustomerOverviewSandbox) {}

  ngOnInit(): void {
    this.sandbox.getLocations();
  }
}
