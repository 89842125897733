<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$|async) ? 'reseller:clients.edit-client' :'reseller:clients.add-client') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section *ngIf="sandbox.reseller$ |async as reseller" class="section">
      <div>
        <label fxFlex="15">{{'common:dictionary.reseller' | i18nextCap}}</label>
        <div fxFlex="auto">{{ reseller.name}}</div>
      </div>
      <div>
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ reseller.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section class="client-info">
        <div class="section-title mb-2">{{'reseller:clients.client-name' | i18nextCap}}</div>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>
      </section>
      <section formGroupName="contact" class="contact-person">
        <div class="section-title mb-2">{{'reseller:clients.contact-person' | i18nextCap}}</div>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.email' | i18nextCap}}</mat-label>
          <input formControlName="email" matInput />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.phone' | i18nextCap}}</mat-label>
          <input formControlName="phone" matInput />
        </mat-form-field>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxFlex="100" fxLayoutGap="10px">
      <button mat-button type="button" (click)="sandbox.close()" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="form.invalid" mat-button (click)="submit$.next(null)" class="button save">{{'common:buttons.save' | i18nextCap}}</button>
    </section>
  </ng-container>
</summa-side-panel-content>
