/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { I18NextCapPipe } from 'angular-i18next';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, ENV } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString } from '@summa/shared/ui/dialogs';
// import { FloorplanElementState } from '@summa/shared/ui/floorplan';

import { ProjectFloorplanCardsComponentSandbox } from './project-floorplan-cards.sandbox';

@Component({
  selector: 'summa-project-floorplan-cards',
  templateUrl: './project-floorplan-cards.component.html',
  styleUrls: ['./project-floorplan-cards.component.scss'],
  providers: [ProjectFloorplanCardsComponentSandbox],
})
export class ProjectFloorplanCardsComponent implements OnInit, OnDestroy {
  filter: FormControl;
  filteredCards$: ReplaySubject<DOMAIN.Card[]>;
  destroy$ = new Subject();
  modus$ = new Subject<'new' | 'edit' | null>();
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  // FLOORPLAN PARAMS
  baseUrl = this.environment.floorplanUrl;
  file: string;
  fileUrl: string;
  fileName: string;
  currentRoute: string;
  selectedFixtures = [];
  fixturesState$ = new ReplaySubject<any[]>(1);
  fixtures$ = new BehaviorSubject<DOMAIN.Fixture[]>([]);
  dxfClicked$ = new Subject<any>();
  selectedDevice$ = new Subject<any>();

  // FORM PARAMS
  form: FormGroup;
  card: FormControl;
  sceneFilter: FormControl;
  deviceFilter: FormControl;
  submit$ = new Subject();
  cancel$ = new Subject();
  remove$ = new Subject();
  card$ = new Subject<DOMAIN.Card>();
  filteredScenes$: ReplaySubject<DOMAIN.Scene[]>;
  filteredDevices$: ReplaySubject<DOMAIN.Device[]>;

  get formDevices(): FormArray {
    return this.form.get('deviceIds') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public sandbox: ProjectFloorplanCardsComponentSandbox,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleProject();
    this.handleFilter();
    this.handleFilterScene();
    this.handleFilterDevice();
    this.handleFloorplan();
    this.handleForm();
    this.handleChange();
    this.handleNewCard();
    this.handleSubmit();
    this.handleCancel();
    this.handleSuccess();
    this.handleRemove();
    this.handleDxfClicked();
    this.updateFixtureState();
  }

  toggleSelectAll(options, selectOptions, selectAllValue: boolean): void {
    options.pipe(take(1), takeUntil(this.destroy$)).subscribe((val) => {
      if (selectAllValue) {
        selectOptions.patchValue(val.map((i) => i.id));
      } else {
        selectOptions.patchValue([]);
      }
    });
  }

  // -----------------
  // Handle initial page setup
  // -----------------
  private handleProject(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getCards(projectKey);
      this.sandbox.getScenes(projectKey);
    });
  }

  private handleFloorplan(): void {
    this.sandbox.floorplan$.pipe(takeUntil(this.destroy$)).subscribe((floorplan) => {
      this.file = floorplan.name;
      this.fileName = floorplan.fileName.replace('.json', '');
      this.currentRoute = this.router.url.includes('floorplans') ? this.router.url : `${this.router.url}/floorplans`;
      this.fileUrl = floorplan.fileLocation;
      this.sandbox.getFixtures(floorplan.id);
      this.sandbox.fixtures$
        .pipe(
          map((fixtures) => fixtures.filter((fixture: DOMAIN.Fixture) => fixture.floorplanKey === floorplan.id)),
          takeUntil(this.destroy$),
        )
        // eslint-disable-next-line rxjs/no-nested-subscribe
        .subscribe((fixtures) => this.fixtures$.next(fixtures));
    });
  }

  private handleNewCard(): void {
    this.modus$
      .pipe(
        filter((modus) => modus === 'new'),
        withLatestFrom(this.sandbox.scenes$, this.sandbox.devices$),
        takeUntil(this.destroy$),
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([_, scenes, devices]) => {
        const mappedScenes = scenes.map((scene) => scene.id);

        this.form = this.fb.group({
          title: ['', Validators.required],
          sortOrder: [0, Validators.required],
          hidden: false,
          deviceIds: '',
          sceneIds: [mappedScenes],
        });
      });
  }

  private handleChange(): void {
    // ON CARD SELECT
    this.card.valueChanges
      .pipe(withLatestFrom(this.sandbox.cards$, this.fixtures$), takeUntil(this.destroy$))
      .subscribe(([card, cards, fixtures]) => {
        const devicesInFixtures = fixtures
          .filter((fixture) => fixture.devices.length > 0)
          .map((fixture) => {
            return fixture.devices;
          })
          .map((device) => {
            return device.map((key) => {
              return key.key;
            });
          });
        let devicesKey = [];
        devicesInFixtures.forEach((device) => {
          devicesKey = devicesKey.concat(device);
        });

        const selectedCard = cards.find((c) => c.id === card);
        this.card$.next(selectedCard);
      });
  }

  private handleFilter(): void {
    this.filter = this.fb.control('');
    this.filteredCards$ = new ReplaySubject<DOMAIN.Card[]>(1);

    // handle cards
    this.sandbox.cards$.pipe(takeUntil(this.destroy$)).subscribe((cards) => this.filteredCards$.next(cards));

    // handle filter
    this.filter.valueChanges.pipe(withLatestFrom(this.sandbox.cards$), takeUntil(this.destroy$)).subscribe(([search, cards]) => {
      if (!search) {
        this.filteredCards$.next(cards);
        return;
      }

      this.filteredCards$.next(cards.filter((card) => card.title.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  // -----------------
  // Handle Form
  // -----------------

  private handleForm(): void {
    this.card = this.fb.control('');

    this.card$.pipe(withLatestFrom(this.fixtures$), takeUntil(this.destroy$)).subscribe(([card, fixtures]) => {
      if (!card) {
        this.form = null;
        return;
      }
      // edit mode:
      const deviceIds = card.devices.map((e) => e.id) ?? '';
      const scenes = card.scenes.map((c) => c.id) ?? '';
      this.selectedFixtures = fixtures.filter((fixture) => fixture.mainGroup === card.id);
      this.form = this.fb.group({
        id: card.id,
        title: [card.title, Validators.required],
        sortOrder: [card.sortOrder, Validators.required],
        hidden: card.hidden,
        deviceIds: [deviceIds],
        sceneIds: [scenes],
      });

      this.updateFixtureState(this.selectedFixtures, 0xffa500);
    });
  }

  private handleSubmit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.submit$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([, { projectKey }]) => {
      if (this.form.invalid) return;
      const { id, deviceIds, sceneIds, ...rest } = this.form.value;
      // Update the mainGroup prop in fixture

      const fixtures = this.selectedFixtures.map((fixture) => {
        return { ...fixture, mainGroup: id, floorplan: '' };
      });

      this.sandbox.upsertFixtures(fixtures);

      const cardInput: DOMAIN.CardInput = {
        ...rest,
        id,
        projectKey,
        deviceIds: [...deviceIds],
        sceneIds: [...sceneIds],
      };

      this.sandbox.upsertCard(cardInput);
      this.updateFixtureState();
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state.isSuccessful) {
        this.form = null;
        this.card.setValue(null);
        this.sandbox.reset();
        this.modus$.next(null);
      }
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (state.isSuccessful) {
        this.form = null;
        this.card.setValue(null);
        this.sandbox.reset();
        this.confirmDialog.close();
      }
    });
  }

  private handleCancel(): void {
    this.cancel$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.form = null;
      this.card.setValue(null);
      this.modus$.next(null);
      this.updateFixtureState();
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((id: string) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: id }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeCard(id);
      });
    });
  }

  private handleDxfClicked(): void {
    this.dxfClicked$
      .pipe(
        filter((fixture) => fixture != null),
        withLatestFrom(this.sandbox.devices$),
        takeUntil(this.destroy$),
      )
      .subscribe(([fixture, devices]) => {
        if (!this.form) return;

        let devicesInFixture = [];
        let deselectDevice = [];

        if (this.selectedFixtures.find((f) => f.id === fixture.id)) {
          deselectDevice = fixture.devices.map((device) => {
            return device.id;
          });
          this.selectedFixtures = this.selectedFixtures.filter((f) => !(f.id === fixture.id));
        } else {
          this.selectedFixtures.push(fixture);
        }

        devicesInFixture = devicesInFixture.concat(
          fixture.devices.map((device) => {
            return device.key;
          }),
        );

        const clickedDevices = devices.filter((device) => devicesInFixture.includes(device.key));
        // toggle the clickedDevice
        const deviceValues = this.formDevices.value;
        const newValue =
          deselectDevice.length > 0
            ? [...deviceValues.filter((id: string) => !deselectDevice.includes(id))]
            : [
                ...new Set(
                  clickedDevices
                    .map((device) => {
                      return device.id;
                    })
                    .concat(deviceValues),
                ),
              ];
        this.formDevices.setValue(newValue);
        this.updateFixtureState(this.selectedFixtures, 0xffa500);
      });
  }

  private handleFilterScene(): void {
    this.sceneFilter = this.fb.control('');
    this.filteredScenes$ = new ReplaySubject<DOMAIN.Scene[]>(1);

    // handle scenes
    this.sandbox.scenes$.pipe(takeUntil(this.destroy$)).subscribe((scenes) => this.filteredScenes$.next(scenes));

    // handle filter
    this.sceneFilter.valueChanges.pipe(withLatestFrom(this.sandbox.scenes$), takeUntil(this.destroy$)).subscribe(([search, scenes]) => {
      if (!search) {
        this.filteredScenes$.next(scenes);
        return;
      }

      this.filteredScenes$.next(scenes.filter((card) => card.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleFilterDevice(): void {
    this.deviceFilter = this.fb.control('');
    this.filteredDevices$ = new ReplaySubject<DOMAIN.Device[]>(1);

    // handle devices
    this.sandbox.devices$.pipe(takeUntil(this.destroy$)).subscribe((devices) => this.filteredDevices$.next(devices));

    // handle filter
    this.deviceFilter.valueChanges.pipe(withLatestFrom(this.sandbox.devices$), takeUntil(this.destroy$)).subscribe(([search, devices]) => {
      if (!search) {
        this.filteredDevices$.next(devices);
        return;
      }

      this.filteredDevices$.next(devices.filter((device) => device.key.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  /**
   * HELPERS
   */

  private updateFixtureState(fixtures = [], color = 0xcccccc): void {
    const fixtureIds = fixtures.map((f) => {
      return f.id;
    });
    this.fixtures$
      .pipe(
        map((fs) =>
          fs.map((fixture) => {
            if (fixture.devices.length === 0) {
              return { ...fixture };
            }
            if (fixtureIds.includes(fixture.id)) {
              return { ...fixture, color };
            }
            return { ...fixture, color: 0xcccccc };
          }),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((fs) => this.fixturesState$.next(fs));
  }
}
