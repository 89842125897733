/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';

import { ClientStore } from './client.store';
import { removeClientQuery, upsertClientQuery, GetClientQuery, GetClientsQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  somethingWentWrong = 'message:something-went-wrong';
  constructor(private apollo: Apollo, private clientStore: ClientStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  storeClient(client: PortalAPI.Client): void {
    this.clientStore.upsert(client.id, client);
  }

  reset(): void {
    this.clientStore.reset();
  }

  resetActive(): void {
    this.clientStore.resetUIState();
    this.clientStore.setActive(null);
  }

  setActive(id: ID | null): void {
    this.clientStore.setActive(id);
  }

  getClient(id: string): any {
    this.apollo
      .subscribe({ query: GetClientQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getClient as PortalAPI.Client))
      .subscribe((client) => {
        this.clientStore.upsert(client.id, client);
        this.setActive(client?.id);
      });
  }

  getClients(resellerId: string): any {
    this.apollo
      .subscribe({ query: GetClientsQuery, variables: { resellerId } })
      .pipe(map((d: any) => d.data.getClients as PortalAPI.Client[]))
      .subscribe((clients) => {
        this.clientStore.set(clients);
      });
  }

  resetClientUIState(): void {
    this.clientStore.resetUIState();
  }

  upsertClient(client: PortalAPI.ClientInput): void {
    this.clientStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertClientQuery, variables: { client } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.clientStore.updateUpsert({ errors: results?.errors, isLoading: false });
          const errorMessage =
            results.errors[0]?.message.indexOf('E11000 duplicate key error collection') !== -1
              ? 'reseller:clients.duplicate-client-name-error'
              : this.somethingWentWrong;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: client.name }));

          this.clientStore.setError(this.i18next.transform(errorMessage, { field: client.name }));
          return;
        }

        const data = results.data as { upsertClient: PortalAPI.Client };
        this.clientStore.upsert(data.upsertClient.id, data.upsertClient);

        // set success:
        this.clientStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  removeClient(client: PortalAPI.Client): void {
    this.clientStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeClientQuery, variables: { id: client.id } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.clientStore.setError(results.errors);
          this.clientStore.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        this.clientStore.remove(client.id);
        this.clientStore.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }
}
