import { Inject, Injectable } from '@angular/core';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';

import { DOMAIN } from '@summa/models';
import { isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { filter, Observable } from 'rxjs';
import { ApplicationService, AuthenticationService, AuthQuery, DocumentQuery, DocumentService } from '../../../data-access';

@Injectable()
export class ShellSandbox {
  currentUser$: Observable<DOMAIN.User>;
  documents$: Observable<DOMAIN.Document[]>;

  constructor(
    private authQuery: AuthQuery,
    private documentQuery: DocumentQuery,
    private documentService: DocumentService,
    private authenticationService: AuthenticationService,
    private applicationService: ApplicationService,
    @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
  ) {
    this.currentUser$ = this.authQuery.selectActive();
    this.documents$ = this.documentQuery.selectAll().pipe(filter(isNotNullUndefinedOrEmpty));
  }
  getApplicationInfo(): void   {
    this.applicationService.getApplicationConfig();
  }

  getDocuments(): void {
    this.documentService.getDocuments(DOMAIN.agreement);
  }

  getCurrentUser(): void {
    this.authenticationService.getCurrentUser();
  }

  setLanguage(language: string): void {
    if (!language || this.i18NextService.language === language) return;

    this.i18NextService.changeLanguage(language).then(() => {
      document.location.reload();
    });
  }

  logout(): void {
    this.authenticationService.logOut();
  }
}
