import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectAutomationAddPage } from '../project-automation-add/project-automation-add.page';
import { ProjectAutomationPreviewPage } from '../project-automation-preview/project-automation-preview.page';

import { ProjectAutomationOverviewSandbox } from './project-automation-overview.sandbox';

@Component({
  selector: 'summa-project-automation-overview',
  templateUrl: './project-automation-overview.page.html',
  styleUrls: ['./project-automation-overview.page.scss'],
  providers: [ProjectAutomationOverviewSandbox],
})
export class ProjectAutomationOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertPanel$ = new Subject();
  remove$ = new Subject();
  openPreview$ = new Subject();
  automations = new MatTableDataSource<DOMAIN.Automation>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, any>;
  columnsToDisplay = ['name', 'description', 'type', 'state', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ProjectAutomationOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleUpsertPanel();
    this.handlePreview();
    this.handleRemove();

    this.sandbox.automations$.pipe(takeUntil(this.destroy$)).subscribe((automations: DOMAIN.Automation[]) => {
      this.automations.data = automations;
      this.automations.sort = this.sort;
      this.automations.paginator = this.paginator;
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getAutomations(projectKey);
    });
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.automations.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([automation, params]) => {
      this.sidePanelService.open(ProjectAutomationAddPage, { projectKey: params.projectKey, automation });
    });
  }

  private handlePreview(): void {
    this.openPreview$.pipe(takeUntil(this.destroy$)).subscribe((automation: DOMAIN.Automation) => {
      this.sandbox.getAutomation(automation.id);
      this.sidePanelService.open(ProjectAutomationPreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((automation: DOMAIN.Automation) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: automation.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.remove(automation.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
