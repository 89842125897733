import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ProjectDriverInfoSandbox } from './project-driver-info-tab.sandbox';

@Component({
  selector: 'summa-project-driver-info-tab',
  templateUrl: './project-driver-info-tab.component.html',
  styleUrls: ['./project-driver-info-tab.component.scss'],
  providers: [ProjectDriverInfoSandbox],
})
export class ProjectDriverInfoTabComponent implements OnDestroy {
  destroy$ = new Subject();
  entityColumnsToDisplay = ['key', 'address', 'type'];

  constructor(public sandbox: ProjectDriverInfoSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
