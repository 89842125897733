import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { UploadBinComponent } from './upload-bin/upload-bin.component';
import { UploadCsvComponent } from './upload-csv/upload-csv.component';
import { UploadDxfComponent } from './upload-dxf/upload-dxf.component';

@NgModule({
  imports: [CommonModule, MatInputModule, MatIconModule, MatButtonModule],
  declarations: [UploadCsvComponent, UploadDxfComponent, UploadBinComponent],
  exports: [UploadCsvComponent, UploadDxfComponent, UploadBinComponent],
})
export class SharedUiUploadModule {}
