export const driverPuck = 'puck';
/** DRIVER */
export const driverTrack = 'track';
export const driverCompact = 'compact';
export const isCategoryDriver = (type: string): boolean => [driverTrack, driverCompact].includes(type);

/** CONTROL */
export const driverDimmer = 'dimmer';
export const driverSwitch = 'switch';
export const driverRelay = 'relay';
export const isCategoryControl = (type: string): boolean => [driverDimmer, driverSwitch, driverRelay].includes(type);

/** ALL */
export const driverType = [driverPuck, driverTrack, driverCompact, driverDimmer, driverSwitch, driverRelay] as const;
export type DriverType = typeof driverType[number];

/* DRIVER STATE */
export const driverReplaced = 'replaced';
export const driverDefect = 'defect';
export const driverState = [driverDefect, driverReplaced] as const;
export type DriverState = typeof driverState[number];
