import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOMAIN } from '@summa/models';
import { Subject } from 'rxjs';
import { SensorBaselineDialogData } from './sensor-baseline-dialog.model';

@Component({
  selector: 'summa-sensor-baseline-dialog',
  templateUrl: './sensor-baseline-dialog.component.html',
  styleUrls: ['./sensor-baseline-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensorBaselineDialogComponent implements OnInit {
  form: FormGroup;
  submit$ = new Subject<void>();

  @Output() confirm: EventEmitter<unknown> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: SensorBaselineDialogData, private fb: FormBuilder) {
    this.form = this.fb.group({
      baseline: [DOMAIN.defaultSensorBaselineFrames, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.handleSubmit();
  }

  private handleSubmit(): void {
    this.submit$.subscribe(() => this.confirm.emit(this.form.value));
  }
}
