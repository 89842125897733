/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { GetScenariosQuery } from './graphql/scenario.gql-query';
import { ScenarioStore } from './scenario.store';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {
  constructor(private apollo: Apollo, private scenarioStore: ScenarioStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.scenarioStore.reset();
  }

  resetActive(): void {
    this.scenarioStore.resetUIState();
    this.setActive(null);
  }

  setActive(id: string | null): void {
    this.scenarioStore.setActive(id);
  }

  getScenarios(projectKey?: string, skip?: number, take?: number): void {
    this.scenarioStore.reset();

    this.apollo
      .subscribe({ query: GetScenariosQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getScenarios as DOMAIN.Scenario[]))
      .subscribe((scenarios) => this.scenarioStore.set(scenarios));
  }
}
