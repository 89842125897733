<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
  <!-- COLUMN.01 -->
  <div fxLayout="column" fxLayoutGap="16px">
    <!-- COUNTERS -->
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card lg p-3">
        <div class="header">
          {{'reseller:clients.overview.title' | i18nextCap}}
          <div class="header-tools">
            <div class="filter">
              <mat-form-field floatLabel="never">
                <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
              </mat-form-field>
            </div>
            <div class="create" title="{{'reseller:clients.overview.create-client' | i18nextCap}}" (click)="openAddClient()">
              <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
            </div>
          </div>
        </div>

        <mat-table [dataSource]="clients" matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{'reseller:clients.overview.table-headers.client-name' | i18nextCap}}</mat-header-cell
            >
            <mat-cell class="highlight" *matCellDef="let client">{{ client.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactName">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{'reseller:clients.overview.table-headers.contact-name' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let client">{{ client.contact.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactPhone">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{'reseller:clients.overview.table-headers.contact-phone' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let client">{{ client.contact.phone }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="contactEmail">
            <mat-header-cell *matHeaderCellDef mat-sort-header
              >{{'reseller:clients.overview.table-headers.contact-email' | i18nextCap}}</mat-header-cell
            >
            <mat-cell *matCellDef="let client">{{ client.contact.email }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" [routerLink]="[row.id]"></mat-row>
          <div class="filter-notification" *matNoDataRow>{{'reseller:clients.overview.filter-notification' | i18nextCap}} "{{input.value}}"</div>
        </mat-table>
        <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
