import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectControlPreviewSandbox } from './project-control-preview.sandbox';

@Component({
  selector: 'summa-project-control-preview',
  templateUrl: './project-control-preview.page.html',
  styleUrls: ['./project-control-preview.page.scss'],
  providers: [ProjectControlPreviewSandbox],
})
export class ProjectControlPreviewPage implements OnInit, OnDestroy {
  control$ = this.sandbox.control$;
  destroy$ = new Subject();

  currentRoute: string;

  constructor(private sandbox: ProjectControlPreviewSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.resetActive();
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url.includes('controls') ? this.router.url : `${this.router.url}/controls`;
  }

  closePanel(): void {
    this.sidePanelService.close();
  }
}
