import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { removeInstallerQuery, upsertInstallerQuery, GetInstallerQuery, GetInstallersQuery } from './graphql';

import { InstallerStore } from './installers.store';

@Injectable({
  providedIn: 'root',
})
export class InstallerService {
  constructor(private apollo: Apollo, private store: InstallerStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.store.reset();
    this.resetActive();
  }

  resetActive(): void {
    this.store.resetInstallerUIState();
    this.store.setActive(null);
  }

  setActive(id: ID | null): void {
    this.store.setActive(id);
  }

  getInstallers(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetInstallersQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getInstallers as DOMAIN.Installer[]))
      .subscribe((installers) => this.store.set(installers));
  }

  getInstaller(id: string): void {
    this.apollo
      .subscribe({ query: GetInstallerQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getInstaller as DOMAIN.Installer))
      .subscribe((installer) => {
        this.store.upsert(installer.id, installer);
        this.store.setActive(installer.id);
      });
  }

  resetInstallerUIState(): void {
    this.store.resetInstallerUIState();
  }

  upsertInstaller(installerInput: DOMAIN.InstallerInput): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertInstallerQuery, variables: { installerInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `installer ${installerInput.name}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertInstaller: DOMAIN.Installer };
      this.store.upsert(data.upsertInstaller.id, data.upsertInstaller);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
    });
  }

  removeInstaller(id: string): void {
    this.store.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeInstallerQuery, variables: { id } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.store.setError(results.errors);
          this.store.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        // const data = results.data as { removeInstaller: PortalAPI.RemoveResponse };
        this.store.remove(id);
        this.store.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  removeInstallerFromStore(id: string) {
    this.store.remove(id);
  }

  resetUpsertState() {
    this.store.updateUpsert({ isSuccessful: false, isLoading: false, errors: null });
  }

  resetRemoveState() {
    this.store.updateRemove({ isSuccessful: false, isLoading: false, errors: null });
  }
}
