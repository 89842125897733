import { Injectable } from '@angular/core';

import { PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { ResellerQuery, ResellerService } from '../../../data-access';

@Injectable()
export class ResellerAddSandbox {
  upsertState$ = this.resellerQuery.selectUpsertState$;
  reseller$ = this.resellerQuery.selectActive();
  data$ = this.sidePanelService.getData();

  constructor(private resellerService: ResellerService, private resellerQuery: ResellerQuery, private sidePanelService: SidePanelService) {}

  getReseller(resellerKey: string): void {
    this.resellerService.getReseller(resellerKey);
  }

  setHasChanges(hasChanges: boolean): void {
    this.sidePanelService.setHasChanges(hasChanges);
  }

  upsertReseller(resellerInput: PortalAPI.ResellerInput): void {
    this.resellerService.upsertReseller(resellerInput);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.resellerService.resetResellerUIState();
  }
}
