/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, first, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { MatSelect } from '@angular/material/select';
import { RequiredIfValidator } from '@summa/shared/util/validators';
import { CONSTANTS } from '@summa/models';
import { ControlPanelUpsertSandbox } from './control-panel-upsert.sandbox';

@Component({
  selector: 'control-panel-upsert',
  templateUrl: './control-panel-upsert.page.html',
  styleUrls: ['./control-panel-upsert.page.scss'],
  providers: [ControlPanelUpsertSandbox],
})
export class ControlPanelUpsertPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submit$ = new Subject();

  form: FormGroup;

  scenarioFilter: FormControl;
  filteredScenarios$: ReplaySubject<DTO.Scenario[]>;
  scenarioTypes = CONSTANTS.controlPanelScenarioType;

  deviceFilter: FormControl;
  filteredDevices$: ReplaySubject<DTO.Device[]>;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  get scenarios(): FormArray {
    return this.form?.get('scenarios') as FormArray;
  }

  constructor(private fb: FormBuilder, public sandbox: ControlPanelUpsertSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.scenarioFilter = this.fb.control('');
    this.filteredScenarios$ = new ReplaySubject<DTO.Scenario[]>(1);
    this.deviceFilter = this.fb.control('');
    this.filteredDevices$ = new ReplaySubject<DTO.Device[]>(1);

    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
    this.handleProject();
    this.handleDevices();
    this.handleScenarios();
    this.handleFilterScenario();
  }

  toggleSelectAll(options, selectOptions, selectAllValue: boolean): void {
    options.pipe(take(1), takeUntil(this.destroy$)).subscribe((val) => {
      if (selectAllValue) {
        selectOptions.patchValue(val.map((i) => i.id));
      } else {
        selectOptions.patchValue([]);
      }
    });
  }

  removeScenario(index: number): void {
    this.scenarios.removeAt(index);
  }

  addScenario(scenario?: DTO.ControlPanelScenario): void {
    this.scenarios.push(this.createScenario(scenario));
  }

  private createScenario(scenario?: DTO.ControlPanelScenario): FormGroup {
    return this.fb.group({
      id: [scenario && scenario.id ? scenario.id : '', Validators.required],
      type: [scenario && scenario.type ? scenario.type : CONSTANTS.controlPanelScenarioTypePulse, Validators.required],
    });
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getScenes(project.key);
      this.sandbox.getScenarios(project.key);
      this.sandbox.getDrivers(project.key);
    });
  }

  private handleData(): void {
    // based on side-panel to decide create/edit
    this.sandbox.data$.pipe(first(), takeUntil(this.destroy$)).subscribe((id): void => {
      if (id) {
        this.sandbox.getControlPanel(id);
      } else {
        this.form = this.fb.group({
          name: ['', [Validators.required]],
          description: ['', []],
          scenarios: this.fb.array([this.createScenario()]),
          defaultScene: ['', [Validators.required]],
          useAllDevices: [true, []],
          devices: [[], [RequiredIfValidator(() => this.form.value.useAllDevices === false)]],
        });
      }
    });

    // receive control info from server
    this.sandbox.controlPanel$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((controlPanel) => {
      const hasDevices = controlPanel.devices && controlPanel.devices.length > 0;
      this.form = this.fb.group({
        name: [controlPanel.name, [Validators.required]],
        description: [controlPanel.description, []],
        scenarios: this.fb.array(controlPanel.scenarios.map((s) => this.createScenario(s))),
        defaultScene: [controlPanel.defaultScene?.id ?? '', [Validators.required]],
        useAllDevices: [!hasDevices, []],
        devices: [hasDevices ? controlPanel.devices.map((device) => device.id) : [], []],
      });
    });
  }

  private handleScenarios(): void {
    this.sandbox.scenarios$.pipe(takeUntil(this.destroy$)).subscribe((scenario) => this.filteredScenarios$.next(scenario));
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.form.valid),
        withLatestFrom(this.sandbox.controlPanel$, this.sandbox.project$),
        takeUntil(this.destroy$),
      )
      .subscribe(([, originalControlPanel, project]) => {
        if (originalControlPanel) {
          const patchControlPanelInput: Partial<DTO.ControlPanelInput> = {
            id: originalControlPanel.id,
            name: this.form.value.name,
            description: this.form.value.description,
            scenarios: this.form.value.scenarios.map((e: any) => ({
              id: e.id,
              type: e.type,
              isActive: false,
            })),
            defaultScene: this.form.value.defaultScene,
            devices: this.form.value.useAllDevices === false ? this.form.value.devices : [],
          };

          this.sandbox.patchControlPanel(patchControlPanelInput);
          return;
        }

        const newControlPanelInput: DTO.ControlPanelInput = {
          name: this.form.value.name,
          projectKey: project.key,
          description: this.form.value.description,
          scenarios: this.form.value.scenarios.map((e: any) => ({
            id: e.id,
            type: e.type,
            isActive: false,
          })),
          controls: [],
          defaultScene: this.form.value.defaultScene,
          devices: this.form.value.useAllDevices === false ? this.form.value.devices : [],
        };

        this.sandbox.createControlPanel(newControlPanelInput);
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  private handleFilterScenario(): void {
    this.scenarioFilter.valueChanges.pipe(withLatestFrom(this.sandbox.scenarios$), takeUntil(this.destroy$)).subscribe(([search, scenarios]) => {
      if (!search) {
        this.filteredScenarios$.next(scenarios);
        return;
      }
      this.filteredScenarios$.next(scenarios.filter((controlPanel) => controlPanel.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleDevices(): void {
    this.sandbox.devices$.pipe(takeUntil(this.destroy$)).subscribe((devices) => this.filteredDevices$.next(devices));

    this.deviceFilter.valueChanges.pipe(withLatestFrom(this.sandbox.devices$), takeUntil(this.destroy$)).subscribe(([search, devices]) => {
      if (!devices) {
        return;
      }
      if (!search) {
        this.filteredDevices$.next(devices);
        return;
      }

      this.filteredDevices$.next(devices.filter((device) => device.key.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }
}
