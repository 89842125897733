import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout';

import { SharedUiHeadersModule } from '@summa/shared/ui/headers';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';

import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { UserUpsertPage } from './upsert/user-upsert.page';
import { UserPreviewPage } from './preview/user-preview.page';
import { UserOverviewPage } from './overview/user-overview.page';

@NgModule({
  imports: [
    FlexModule,
    CommonModule,
    I18NextModule,
    MatSortModule,
    MatIconModule,
    MatInputModule,
    MatTableModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SharedUiDialogsModule,
    SharedUiHeadersModule,
    RouterModule.forChild([{ path: '', component: UserOverviewPage, data: { breadcrumb: 'Users' } }]),
  ],
  declarations: [UserOverviewPage, UserPreviewPage, UserUpsertPage],
})
export class SummaPortalUserManagementModule {}
