export const channelSingle = 'single';
export const channelSingleAlternate = 'single-alternate';
export const channelDouble = 'double';
export const channelHigh = 'high';
export const channelLow = 'low';
export const channelLong = 'long';
export const channelLongEnd = 'longend';

export const channelType = [channelSingle, channelSingleAlternate, channelDouble, channelLow, channelHigh, channelLong, channelLongEnd] as const;
export type ChannelType = typeof channelType[number];

export const highLowChannelType = 'high-low';
export const singleDoubleChannelType = 'single-double';

export const checkChannelType = (type: string): string => {
  switch (true) {
    case [channelSingle, channelSingleAlternate, channelDouble].includes(type) === true:
      return singleDoubleChannelType;

    case [channelHigh, channelLow].includes(type) === true:
      return highLowChannelType;

    default:
      return '';
  }
};
