import { PERMISSIONS, PortalAPI } from '@summa/models';
import { flatten, isNotNullOrUndefined, onlyUnique } from '@summa/shared/util/typescript';

/**
 * Checks if the user has permission
 * @param userPermissions
 * @param permissionRequest
 * @param matchingStrategy
 */
export function userHasPermission(
  userPermissions: PERMISSIONS.Permission[],
  permissionRequest: PERMISSIONS.Permission[] | PERMISSIONS.Permission,
  matchingStrategy = PERMISSIONS.PermissionMatchingStrategy.All,
): boolean {
  const permissionRequests = Array.isArray(permissionRequest) ? permissionRequest : [permissionRequest];
  const hasPermissions = (permissions: PERMISSIONS.Permission[]): boolean => permissions.length > 0;

  // Ignore the context when asking for read permission on application level only
  if (permissionRequests.length === 1 && !permissionRequests[0].domain && !permissionRequests[0].subdomain) {
    return hasPermissions(
      userPermissions
        .filter((p) => p.action === permissionRequests[0].action)
        .filter((p) => userPermissionContextMatchesRequest(p.application, permissionRequests[0].application)),
    );
  }

  // Match  specific permissions
  const matchedPermissions = permissionRequests.map((request) => matchingPermissions(userPermissions, request));

  switch (matchingStrategy) {
    case PERMISSIONS.PermissionMatchingStrategy.All:
      return matchedPermissions.every(hasPermissions);
    case PERMISSIONS.PermissionMatchingStrategy.AtLeastOne:
      return matchedPermissions.some(hasPermissions);
    default:
      return false;
  }
}

function userPermissionContextMatchesRequest(userContext: PERMISSIONS.PermissionContext | null, request: PERMISSIONS.PermissionContext): boolean {
  if (userContext === null || request.name !== userContext.name) {
    return false;
  }

  if (request.matchingStrategy === PERMISSIONS.PermissionMatchingStrategy.All) {
    return (
      request.qualifiers.some((q) => q === PERMISSIONS.wildcardQualifier) ||
      userContext.qualifiers.some((q) => q === PERMISSIONS.wildcardQualifier) ||
      JSON.stringify(userContext) === JSON.stringify(request)
    );
  }

  return (
    request.qualifiers.some((q) => q === PERMISSIONS.wildcardQualifier) ||
    request.qualifiers.some((q) => userContext.qualifiers.includes(q)) ||
    userContext.qualifiers.some((q) => q === PERMISSIONS.wildcardQualifier)
  );
}

function matchingPermissions(userPermissions: PERMISSIONS.Permission[], permissionRequest: PERMISSIONS.Permission): PERMISSIONS.Permission[] {
  return userPermissions
    .filter((p) => p.action === permissionRequest.action)
    .filter((p) =>
      isNotNullOrUndefined(permissionRequest.subdomain) ? userPermissionContextMatchesRequest(p.subdomain, permissionRequest.subdomain) : true,
    )
    .filter((p) => (permissionRequest.domain ? userPermissionContextMatchesRequest(p.domain, permissionRequest.domain) : p.domain === null))
    .filter((p) => userPermissionContextMatchesRequest(p.application, permissionRequest.application));
}

/**
 * This function is used on the PermissionGuard
 * matching the userPermissions based on the permissionRequest
 * @param userPermissions
 * @param permissionRequest
 * @param matchingStrategy
 */
export function userHasPartialPermission(
  userPermissions: PERMISSIONS.Permission[],
  permissionRequest: Partial<PERMISSIONS.Permission>[] | Partial<PERMISSIONS.Permission>,
  matchingStrategy = PERMISSIONS.PermissionMatchingStrategy.All,
): boolean {
  const permissionRequests = Array.isArray(permissionRequest) ? permissionRequest : [permissionRequest];
  const hasPermissions = (permissions: PERMISSIONS.Permission[]): boolean => permissions.length > 0;

  // Match  specific permissions
  const matchedPermissions = permissionRequests.map((request) => matchingPartialPermissions(userPermissions, request));
  switch (matchingStrategy) {
    case PERMISSIONS.PermissionMatchingStrategy.All:
      return matchedPermissions.every(hasPermissions);
    case PERMISSIONS.PermissionMatchingStrategy.AtLeastOne:
      return matchedPermissions.some(hasPermissions);
    default:
      return false;
  }
}

// Match partial permissions based on the permissionRequest
function matchingPartialPermissions(
  userPermissions: PERMISSIONS.Permission[],
  permissionRequest: Partial<PERMISSIONS.Permission>,
): PERMISSIONS.Permission[] {
  return userPermissions
    .filter((p) => (permissionRequest.action ? p.action === permissionRequest.action : p))
    .filter((p) => (permissionRequest.application ? userPermissionContextMatchesRequest(p.application, permissionRequest.application) : p))
    .filter((p) => (permissionRequest.domain ? userPermissionContextMatchesRequest(p.domain, permissionRequest.domain) : p))
    .filter((p) => (permissionRequest.subdomain ? userPermissionContextMatchesRequest(p.subdomain, permissionRequest.subdomain) : p));
}

/**
 * Exclude all the resellers permissions
 * @param permissions
 * @param reseller
 */
export function excludeResellerFromPermissions(
  permissions: PERMISSIONS.Permission[],
  resellerId: string,
  clients: PortalAPI.DomainClient[],
): PERMISSIONS.Permission[] {
  const locations: PortalAPI.Location[] = flatten(clients.map((c) => c.locations)).filter(onlyUnique);
  return permissions
    .map((permission) => {
      // reseller filter RESELLER
      if (permission.application.name === PERMISSIONS.applicationReseller) {
        const qualifiers = permission.application.qualifiers.filter((x) => x !== resellerId);
        if (qualifiers.length > 0) {
          return {
            ...permission,
            application: { ...permission.application, qualifiers },
          };
        }

        return null;
      }
      // location filter CUSTOMER
      if (permission.application.name === PERMISSIONS.applicationCustomer) {
        const locQualifiers = permission.application.qualifiers.filter((x) => locations.every((loc) => loc.id !== x));

        if (locQualifiers.length > 0) {
          return {
            ...permission,
            application: {
              ...permission.application,
              qualifiers: locQualifiers,
            },
          };
        }

        return null;
      }
      return permission;
    })
    .filter(isNotNullOrUndefined);
}

/**
 * Filter all the resellers permissions
 * @param permissions
 * @param reseller
 */
export function filterResellerPermissions(
  permissions: PERMISSIONS.Permission[],
  resellerId: string,
  clients: PortalAPI.DomainClient[],
): PERMISSIONS.Permission[] {
  const locations: PortalAPI.Location[] = flatten(clients.map((c) => c.locations)).filter(onlyUnique);
  return permissions
    .map((permission) => {
      // reseller filter RESELLER
      if (permission.application.name === PERMISSIONS.applicationReseller) {
        const qualifiers = permission.application.qualifiers.filter((x) => x === resellerId);

        if (qualifiers.length > 0) {
          return {
            ...permission,
            application: { ...permission.application, qualifiers },
          };
        }

        return null;
      }
      // location filter CUSTOMER
      if (permission.application.name === PERMISSIONS.applicationCustomer) {
        const locQualifiers = permission.application.qualifiers.filter((x) => locations.some((loc) => loc.id === x));

        if (locQualifiers.length > 0) {
          return {
            ...permission,
            application: {
              ...permission.application,
              qualifiers: locQualifiers,
            },
          };
        }
      }
      return null;
    })
    .filter(isNotNullOrUndefined);
}

/**
 * Transform an permission entity to a string
 * @param permission PERMISSION.Permission
 */
export function modelToPermissionString(permission: PERMISSIONS.Permission): string {
  const permissionContext = (context) => `${context.name}:${context.qualifiers}` ?? ':';

  return `${permissionContext(permission.application)}:${permissionContext(permission.domain)}:${permissionContext(permission.subdomain)}:${
    permission.action
  }`;
}

/**
 * Transform string into entity
 * @param permission string
 */
export function stringToPermissionModel(permission: string): PERMISSIONS.Permission {
  const config = permission.split(':');

  if (config.length !== 7) return null;

  const application = {
    name: config[0].toLowerCase(),
    qualifiers: config[1].toLowerCase().split(','),
  };
  const domain = {
    name: config[2].toLowerCase(),
    qualifiers: config[3].split(','),
  };
  const subdomain = {
    name: config[4].toLowerCase(),
    qualifiers: config[5].toLowerCase().split(','),
  };
  return { application, domain, subdomain, action: config[6].toLowerCase() as PERMISSIONS.PermissionActions };
}
