export const dimmingN = 1.9834;
export const dimmingX = 0.0394;
export const dimmingConstant = 40.59;

export const newDimmingCalculation = (input: number, returnDimValue = false): number => {
  const calculation = dimmingN * Math.exp(dimmingX * input) - dimmingN;
  if (!returnDimValue) return calculation;

  const dimValue = calculation * dimmingConstant;
  return Math.round(dimValue);
};
