export const syncAll = 'all';
export const syncAutomations = 'automations';
export const syncCards = 'cards';
export const syncControls = 'controls';
export const syncDrivers = 'drivers';
export const syncLedSpecifications = 'led-specifications';
export const syncPresets = 'presets';
export const syncScenarios = 'scenarios';
export const syncScenes = 'scenes';
export const syncSumma = 'sync-summa';

export const syncType = [
  syncAll,
  syncAutomations,
  syncCards,
  syncControls,
  syncDrivers,
  syncLedSpecifications,
  syncPresets,
  syncScenes,
  syncScenarios,
] as const;
export type SyncType = typeof syncType[number];
