import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { I18NextModule } from 'angular-i18next';

import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedUiIconModule } from '@summa/shared/ui/icon';
import { SharedUtilDirectivesModule } from '@summa/shared/util/directives';

import { LightCardComponent } from './light-card/light-card.component';
import { LightDimmerComponent } from './light-card/light-dimmer';

const components = [LightCardComponent];

@NgModule({
  imports: [
    I18NextModule,
    A11yModule,
    MatInputModule,
    SharedUiDialogsModule,
    FormsModule,
    CommonModule,
    SharedUtilDirectivesModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    MatIconModule,
    FlexLayoutModule,
    SharedUiIconModule,
  ],
  declarations: [components, LightDimmerComponent],
  exports: [components],
})
export class SharedUiCardsModule {}
