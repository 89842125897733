<div fxLayout="column" class="px-3 pt-2">
  <div class="no-select mb-3">{{ 'component:light-card.brightness' | i18nextCap }}</div>
  <mat-slider
    #matSlider
    class="px-3"
    matInput
    thumbLabel
    [disabled]="disabled"
    [(ngModel)]="dimValue"
    (change)="sliderChanged$.next($event.value)"
    [max]="100"
    [min]="0"
    [step]="1"
  >
  </mat-slider>
</div>
