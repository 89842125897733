/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, ENV, UI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { calculateDimPercentage } from '@summa/shared/util/domain';

import { CardDetailComponent } from '../card-details/card-detail.component';

import { CustomerCardSandbox } from './customer-card.sandbox';

@Component({
  selector: 'summa-customer-card',
  template: ` <summa-light-card
    *ngIf="uiCard$ | async as uiCard"
    [card]="uiCard"
    [showExtraInfoButton]="showExtraInfoButton"
    (extraInfoClick)="openCardDetails($event)"
    (cardStateChange)="cardStateChange$.next({ event: $event, card: card || null })"
  ></summa-light-card>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerCardSandbox],
})
export class CustomerCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() card?: DOMAIN.Card;
  @Input() showExtraInfoButton = false;

  cardStateChange$ = new Subject<{ event: any; card: DOMAIN.Card | null }>();
  uiCard$?: Observable<UI.Card>;

  destroy$ = new Subject();

  constructor(
    private sandbox: CustomerCardSandbox,
    private sidePanelService: SidePanelService,
    @Inject('environment') private environment: ENV.Environment,
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleCardStateChange();
  }

  ngOnChanges({ card }: SimpleChanges): void {
    if (card?.currentValue) {
      const currentCard: DOMAIN.Card = card.currentValue;
      this.uiCard$ = this.environment.app.isPortal ? this.portalCard(currentCard) : this.gatewayCard(currentCard);
    }
  }

  openCardDetails(cardId: string): void {
    this.sandbox.getCard(cardId);
    this.sidePanelService.open(CardDetailComponent);
  }

  handleCardStateChange(): void {
    this.cardStateChange$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([{ event, card }, route]) => {
      if (!card) return;
      this.sandbox.executeCardCommand(card, event.state ? 'ON' : 'OFF', event.dimValue, route.projectKey);
    });
  }

  private portalCard(currentCard: DOMAIN.Card): Observable<UI.Card> {
    return this.sandbox.fixtureStates$.pipe(
      map((fixtureStates) => {
        if (fixtureStates.length === 0) {
          return { ...currentCard, dimValue: 0, state: 'unknown', fixtureStates: [] } as UI.Card;
        }
        const cardFixtures = fixtureStates.filter((log) => currentCard.devices.some((device) => device.driver?.address === log.topic));
        const state = this.sandbox.isCardTurnedOn(currentCard, cardFixtures) ? 'on' : 'off';

        const dimValues = currentCard.devices
          .map((ce) => {
            const log = fixtureStates.find((l) => l.topic === ce.driver?.address);
            return log ? calculateDimPercentage(log, ce.address) : undefined;
          })
          .filter((value, index, self) => self.indexOf(value) === index);
        const dimValue = dimValues.length > 1 ? -1 : dimValues[0];

        return { ...currentCard, dimValue, state, fixtureStates: cardFixtures } as UI.Card;
      }),
    );
  }

  private gatewayCard(currentCard: DOMAIN.Card): Observable<UI.Card> {
    return this.sandbox.fixtureLogs$.pipe(
      map((fixtureLogs) => {
        if (fixtureLogs.length === 0) {
          return { ...currentCard, dimValue: 0, state: 'unknown', fixtureStates: [] } as UI.Card;
        }
        const cardFixtures = fixtureLogs.filter((log) => currentCard.devices.some((device) => device.driver?.address === log.topic));

        const state = this.sandbox.isCardTurnedOn(currentCard, cardFixtures as any[]) ? 'on' : 'off';

        const dimValues = currentCard.devices
          .map((ce) => {
            const log = fixtureLogs.find((l) => l.topic === ce.driver?.address);
            return log ? calculateDimPercentage(log as any, ce.address) : undefined;
          })
          .filter((value, index, self) => self.indexOf(value) === index);
        const dimValue = dimValues.length > 1 ? -1 : dimValues[0];

        return { ...currentCard, dimValue, state } as UI.Card;
      }),
    );
  }
}
