import { gql } from 'apollo-angular';

export const FixtureStateSubscription = gql`
  subscription observeFixtureStates($projects: [String!]!) {
    observeFixtureStates(projects: $projects) {
      project
      timestamp
      topic
      state
      ip
      ticks
      temp
      version
      type
      channels
      dimLevel
      walkTime
      fadeRate
      amountCh
      vIn
    }
  }
`;
