import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { compareVersions, isNotNullUndefinedOrEmpty, isNullOrUndefined } from '@summa/shared/util/typescript';
import { ProjectNotificationBarSandbox } from './project-notification-bar.sandbox';

@Component({
  selector: 'summa-notification-bar',
  templateUrl: './project-notification-bar.component.html',
  styleUrls: ['./project-notification-bar.component.scss'],
  providers: [ProjectNotificationBarSandbox],
})
export class ProjectNotificationBarComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  notification$ = new Subject<string | null>();

  gatewayNewVersion = 'gateway_new_version';
  gatewayOutdatedVersion = 'gateway_outdated_version';

  constructor(public sandbox: ProjectNotificationBarSandbox) {}

  // check if the gateway version is outdated
  // check if the gateway version has a new version available
  ngOnInit(): void {
    // check if the gateway version is outdated
    combineLatest([this.sandbox.application$, this.sandbox.gateway$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([application, gateway]) => {
        if (isNullOrUndefined(gateway) && !isNotNullUndefinedOrEmpty(gateway?.applications)) this.notification$.next(null);
        const gatewayApiVersion = gateway?.applications.find((app) => app.name === 'local-api')?.version;

        switch (true) {
          case !gateway:
            this.notification$.next(null);
            break;
          case isNullOrUndefined(gatewayApiVersion) ||
            (application && compareVersions(gatewayApiVersion, application.minimumRequiredGatewayApiVersion)):
            this.notification$.next(this.gatewayOutdatedVersion);
            break;
          case application && compareVersions(gatewayApiVersion, application.latestGatewayVersion):
            this.notification$.next(this.gatewayNewVersion);
            break;

          default:
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
