import { gql } from 'apollo-angular';

export const GetUsersQuery = gql`
  query getUsers($resellerId: String) {
    getUsers(resellerId: $resellerId) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const GetUserQuery = gql`
  query getUser($id: String, $resellerId: String) {
    getUser(id: $id, resellerId: $resellerId) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const GetUsersByPermission = gql`
  query GetUsersByPermission($skip: Int, $take: Int, $permission: PermissionInput!) {
    getUsersByPermission(skip: $skip, take: $take, permission: $permission) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const GetUsersByPermissions = gql`
  query GetUsersByPermissions($skip: Int, $take: Int, $permissions: [PermissionInput!]!) {
    getUsersByPermissions(skip: $skip, take: $take, permissions: $permissions) {
      id
      username
      roles
      permissions
      language
    }
  }
`;
