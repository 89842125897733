import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ResellerQuery, ResellerService, UserQuery, UserService } from '../../../data-access';

@Injectable()
export class UserPreviewSandbox {
  params$ = this.router.selectParams();
  data$ = this.sidePanelService.getData<DOMAIN.User>().pipe(filter(isNotNullOrUndefined));
  user$ = this.userQuery.selectActive();

  constructor(
    private userService: UserService,
    private router: RouterQuery,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private sidePanelService: SidePanelService,
    private userQuery: UserQuery,
  ) {}

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;
    this.resellerService.getReseller(id);
  }

  getUser(id: string, resellerId: string): void {
    this.userService.getUser(id, resellerId);
  }

  reset(): void {
    this.userService.resetActive();
  }
}
