import arc from './entity/arc';
import circle from './entity/circle';
import ellipse from './entity/ellipse';
import insert from './entity/insert';
import line from './entity/line';
import lwpolyline from './entity/lwpolyline';
import mtext from './entity/mtext';
import point from './entity/point';
import polyline from './entity/polyline';
import solid from './entity/solid';
import spline from './entity/spline';
import threeDFace from './entity/threeDFace';
import vertex from './entity/vertex';

const handlers = [point, line, lwpolyline, polyline, vertex, circle, arc, ellipse, spline, solid, mtext, insert, threeDFace].reduce((acc, mod) => {
  acc[mod.TYPE] = mod;
  return acc;
}, {});

export default (tuples) => {
  const entities = [];
  const entityGroups = [];
  let currentEntityTuples;

  // First group them together for easy processing
  tuples.forEach((tuple) => {
    const type = tuple[0];
    if (type === 0) {
      currentEntityTuples = [];
      entityGroups.push(currentEntityTuples);
    }
    currentEntityTuples.push(tuple);
  });

  let currentPolyline;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  entityGroups.forEach((tuples) => {
    const entityType = tuples[0][1];
    const contentTuples = tuples.slice(1);

    if (handlers[entityType] !== undefined) {
      const e = handlers[entityType].process(contentTuples);
      // "POLYLINE" cannot be parsed in isolation, it is followed by
      // N "VERTEX" entities and ended with a "SEQEND" entity.
      // Essentially we convert POLYLINE to LWPOLYLINE - the extra
      // vertex flags are not supported
      if (entityType === 'POLYLINE') {
        currentPolyline = e;
        entities.push(e);
      } else if (entityType === 'VERTEX') {
        if (currentPolyline) {
          currentPolyline.vertices.push(e);
        }
      } else if (entityType === 'SEQEND') {
        currentPolyline = undefined;
      } else {
        // All other entities
        entities.push(e);
      }
    }
  });

  return entities;
};
