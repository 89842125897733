import Redis from 'ioredis';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Scans all keys from redis
 * Using the scan function of redis wont lock the whole store, but scan will give a random amount of results back.
 * This helper wil just iterate through the whole store.
 * @param redisStore Redis instance
 * @param key partial key/pattern that should match
 * @param target (leave empty: used for recursive function)
 * @param keys (leave empty: used for recursive function)
 * @returns
 */
export function scanKeysFromRedis(redisStore: Redis.Redis, key: string, target = 0, keys: string[] = []): Observable<string[]> {
  return from(redisStore.scan(target, 'MATCH', key)).pipe(
    switchMap((_keys) => {
      const redisTarget = Number(_keys[0]);
      if (redisTarget !== 0) {
        return scanKeysFromRedis(redisStore, key, redisTarget, [...keys, ..._keys[1]]);
      }
      return of([...keys, ..._keys[1]]);
    }),
  );
}
