import { Portal } from '@angular/cdk/portal';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationModalData } from '..';

@Component({
  selector: 'summa-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  opened = false;
  onCloseFunc: (() => Promise<void>) | null;
  showDiscardDialogFunc: (() => boolean) | null;

  constructor(private dialog: MatDialog, private i18next: I18NextCapPipe) {}

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentPortal: Portal<any>;

  ngOnInit(): void {
    this.opened = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  async onClose(): Promise<void> {
    if (!this.onCloseFunc) {
      return;
    }

    if (this.showDiscardDialogFunc && this.showDiscardDialogFunc()) {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          title: this.i18next.transform('component:confirmation-dialog.discard-changes-title'),
          content: this.i18next.transform('component:confirmation-dialog.discard-changes-content'),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.confirm'),
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.confirmDialog.close();

        this.onCloseFunc();
      });
    } else {
      this.onCloseFunc();
    }
  }

  animateAway(): Promise<boolean> {
    this.opened = false;

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.opened);
      }, 300);
    });
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    this.onClose();
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(): boolean | null {
    return this.showDiscardDialogFunc ? this.showDiscardDialogFunc() : true;
  }
}
