import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DOMAIN } from '@summa/models';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectInstallerDetailsSandbox } from './project-installer-details.sandbox';

@Component({
  selector: 'summa-project-installer-details',
  templateUrl: './project-installer-details.page.html',
  styleUrls: ['./project-installer-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectInstallerDetailsSandbox],
})
export class ProjectInstallerDetailsPage implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: ProjectInstallerDetailsSandbox, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((installer: DOMAIN.Installer) => installer && this.sandbox.getInstaller(installer.id));
  }

  public printPage(): void {
    window.print();
  }

  public generateMailtoString(installer: DOMAIN.Installer): string {
    const subject = this.i18next.transform('project:installers.mail-subject');
    const body = this.i18next.transform('project:installers.mail-body', { data: installer.qrCodeData });
    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  }
}
