import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';

import { LoginSandbox } from './login.sandbox';

@Component({
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginSandbox],
})
export class LoginPage implements OnInit, OnDestroy {
  login$ = new Subject<PortalAPI.LoginInput>();
  destroy$ = new Subject();

  constructor(public sandbox: LoginSandbox, private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.checkIfAlreadyLogged();

    this.handleLogin();
    this.handleIsLoggedIn();
  }

  handleLogin(): void {
    this.login$.pipe(takeUntil(this.destroy$)).subscribe((login) => {
      this.sandbox.login(login);
    });
  }

  handleIsLoggedIn(): void {
    this.sandbox.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((currentUser) => (currentUser ? this.router.navigate(['applications']) : null));
  }
}
