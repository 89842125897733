import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { I18NextModule } from 'angular-i18next';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';

import { SharedUiCardsModule } from '@summa/shared/ui/cards';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedAuthUtilModule } from '@summa/shared/dashboard/util';
import { SharedDashboardUiModule } from '@summa/shared/dashboard/ui';

import { DashboardComponentsModule } from '../../components/components.module';
import { ControlPanelControlComponent } from './detail/control-panel-control/control-panel-control.component';
import { ControlPanelUpsertPage } from './upsert/control-panel-upsert.page';
import { ControlPanelOverviewPage } from './overview/control-panel-overview.page';
import { ControlPanelPreviewPage } from './preview/control-panel-preview.page';
import { ControlPanelDetailPage } from './detail/control-panel-detail.page';
import { ControlPanelUpsertControlPage } from './detail/control-panel-upsert-control/control-panel-upsert-control.page';
import { ControlPanelScenarioComponent } from './detail/scenarios/control-panel-scenario.component';
import { ControlPanelWidgetComponent } from './widget/control-panel-widget.component';

@NgModule({
  imports: [
    CommonModule,
    I18NextModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    SharedUiCardsModule,
    DashboardComponentsModule,
    SharedUiDialogsModule,
    SharedAuthUtilModule,
    SharedDashboardUiModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  declarations: [
    ControlPanelDetailPage,
    ControlPanelUpsertPage,
    ControlPanelPreviewPage,
    ControlPanelOverviewPage,
    ControlPanelUpsertControlPage,
    ControlPanelControlComponent,
    ControlPanelScenarioComponent,
    ControlPanelWidgetComponent,
  ],
  exports: [ControlPanelDetailPage, ControlPanelUpsertPage, ControlPanelPreviewPage, ControlPanelOverviewPage, ControlPanelWidgetComponent],
})
export class ControlPanelModule {}
