import { gql } from 'apollo-angular';
import { ControlPanelFields } from './control-panel.gql-fragment';

export const executeControlPanelCommandQuery = gql`
  mutation executeControlPanelCommand($command: ControlPanelCommandInput!) {
    executeControlPanelCommand(command: $command)
  }
`;

export const removeControlPanelQuery = gql`
  mutation removeControlPanel($id: String!) {
    removeControlPanel(id: $id) {
      result
      affected
    }
  }
`;

export const patchControlPanelQuery = gql`
  mutation patchControlPanel($controlPanel: ControlPanelPatchInput!) {
    patchControlPanel(controlPanel: $controlPanel) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;

export const createControlPanelQuery = gql`
  mutation CreateControlPanel($controlPanel: ControlPanelInput!) {
    createControlPanel(controlPanel: $controlPanel) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;

export const upsertControlPanelControl = gql`
  mutation UpsertControlPanelControl($id: String!, $controlPanelControl: ControlPanelControlInput!) {
    upsertControlPanelControl(id: $id, controlPanelControl: $controlPanelControl) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;

export const removeControlPanelControl = gql`
  mutation RemoveControlPanelControl($id: String!, $controlPanelId: String!) {
    removeControlPanelControl(id: $id, controlPanelId: $controlPanelId) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;
