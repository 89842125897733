import { Inject, Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, filter } from 'rxjs';

import { DOMAIN, UI } from '@summa/models';
import { QueryEntity } from '@datorama/akita';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { ControlPanelQuery, ControlPanelService, ProjectService } from '../../../data-access';
import { AUTHENTICATION_QUERY, AuthState } from '../../../dashboard.module';

@Injectable()
export class ControlPanelOverviewSandbox {
  currentUser$: Observable<DOMAIN.User>;
  controlPanels$: Observable<DTO.ControlPanel[]>;
  params$: Observable<unknown>;
  removeState$: Observable<UI.State>;

  constructor(
    private router: RouterQuery,
    private projectService: ProjectService,
    private controlPanelQuery: ControlPanelQuery,
    private controlPanelService: ControlPanelService,
    @Inject(AUTHENTICATION_QUERY) private authQuery: QueryEntity<AuthState>,
  ) {
    this.controlPanels$ = this.controlPanelQuery.selectAll();
    this.params$ = this.router.selectParams();
    this.removeState$ = this.controlPanelQuery.selectRemoveState$;
    this.currentUser$ = this.authQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  }

  getProject(projectKey: string): void {
    this.projectService.getProject(projectKey);
  }

  getControlPanels(projectKey: string): void {
    this.controlPanelService.getControlPanels(projectKey);
  }

  getLocalControlPanels(): void {
    this.controlPanelService.getLocalControlPanels();
  }

  getControlPanel(controlPanelId: string): void {
    this.controlPanelService.findControlPanel(controlPanelId);
  }

  removeControlPanel(id: string): void {
    this.controlPanelService.remove(id);
  }

  reset(): void {
    this.controlPanelService.reset();
  }
}
