import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomerSandbox } from './customer.sandbox';

@Component({
  selector: 'summa-customer-shell',
  template: `<section class="header-gradient">
      <summa-portal-page-header
        [title]="title$ | async"
        [currentUser]="(sandbox.currentUser$ | async).username"
        [hideBreadcrumb]="true"
        (logout)="logOut()"
      >
      </summa-portal-page-header>
    </section>
    <router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerSandbox],
})
export class CustomerShellPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  title$ = new BehaviorSubject<string>('');
  currentProject$ = this.sandbox.currentProject$;

  constructor(private router: Router, public sandbox: CustomerSandbox) {}

  ngOnInit(): void {
    this.handleRouteChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  logOut(): void {
    this.sandbox.logout();
    this.router.navigate(['/login']);
  }

  private handleRouteChanges(): void {
    const resolve = (url: string) => this.resolveTitle(url.split('/'));

    this.title$.next(resolve(this.router.url));
    this.sandbox.routeChanges$.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => this.title$.next(resolve(event.url)));
  }

  // eslint-disable-next-line consistent-return
  private resolveTitle(urlSegments: string[]): string {
    switch (true) {
      case urlSegments.length === 2: // ''/customer
        this.sandbox.resetActiveProject();
        return 'customer:common.overview';
      case urlSegments.length === 3: // ''/customer/:id
        return 'customer:common.dashboard';
      default:
    }
  }
}
