import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, first, take, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ProjectCardAddSandbox } from './project-card-add.sandbox';

@Component({
  selector: 'summa-project-card-add',
  templateUrl: './project-card-add.page.html',
  styleUrls: ['./project-card-add.page.scss'],
  providers: [ProjectCardAddSandbox],
})
export class ProjectCardAddPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submit$ = new Subject();

  form: FormGroup;
  deviceFilter: FormControl;
  devicesCtrl: FormControl = new FormControl();
  filteredDevices$: ReplaySubject<DOMAIN.Device[]>;

  cardFilter: FormControl;
  filteredCards$: ReplaySubject<DOMAIN.Card[]>;
  sceneFilter: FormControl;
  filteredScenes$: ReplaySubject<DOMAIN.Scene[]>;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  constructor(private fb: FormBuilder, public sandbox: ProjectCardAddSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.deviceFilter = this.fb.control('');
    this.filteredDevices$ = new ReplaySubject<DOMAIN.Device[]>(1);

    this.sceneFilter = this.fb.control('');
    this.filteredScenes$ = new ReplaySubject<DOMAIN.Scene[]>(1);

    this.handleProject();
    this.handleDevices();
    this.handleCard();
    this.handleScenes();
    this.handleFilterDevice();
    this.handleFilterScene();
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
  }

  toggleSelectAll(options, selectOptions, selectAllValue: boolean): void {
    options.pipe(take(1), takeUntil(this.destroy$)).subscribe((val) => {
      if (selectAllValue) {
        selectOptions.patchValue(val.map((i) => i.id));
      } else {
        selectOptions.patchValue([]);
      }
    });
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getDrivers(project.key);
      this.sandbox.getScenes(project.key);
    });
  }

  private handleData(): void {
    this.sandbox.data$.pipe(first(), takeUntil(this.destroy$)).subscribe((card: DOMAIN.Card) => {
      if (!card) {
        this.form = this.fb.group({
          title: ['', Validators.required],
          sortOrder: [0, Validators.required],
          hidden: false,
          sceneIds: '',
        });
        return;
      }

      // edit mode:
      this.sandbox.getCard(card.id);
    });
  }

  private handleDevices(): void {
    this.sandbox.devices$.pipe(takeUntil(this.destroy$)).subscribe((devices) => this.filteredDevices$.next(devices));
  }

  private handleCard(): void {
    this.sandbox.card$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((card) => {
      // edit mode:
      const deviceIds = card.devices.map((e) => e.id);
      const scenes = card.scenes.map((c) => c.id) ?? '';

      this.form = this.fb.group({
        id: card.id,
        title: [card.title, Validators.required],
        sortOrder: [card.sortOrder, Validators.required],
        hidden: card.hidden,
        sceneIds: [scenes],
      });

      this.devicesCtrl.setValue(deviceIds);
    });
  }

  private handleScenes(): void {
    this.sandbox.scenes$.pipe(takeUntil(this.destroy$)).subscribe((scenes) => this.filteredScenes$.next(scenes));
  }

  private handleSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      if (this.form.invalid) return;

      const { cardIds, sceneIds, ...rest } = this.form.value;

      const cardInput = {
        ...rest,
        deviceIds: this.devicesCtrl.value,
        projectKey: project.key,
        sceneIds: [...sceneIds],
      };

      this.sandbox.upsertCard(cardInput);
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  private handleFilterDevice(): void {
    this.deviceFilter.valueChanges.pipe(withLatestFrom(this.sandbox.devices$), takeUntil(this.destroy$)).subscribe(([search, devices]) => {
      if (!devices) {
        return;
      }
      if (!search) {
        this.filteredDevices$.next(devices);
        return;
      }

      this.filteredDevices$.next(devices.filter((device) => device.key.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleFilterScene(): void {
    this.sceneFilter.valueChanges.pipe(withLatestFrom(this.sandbox.scenes$), takeUntil(this.destroy$)).subscribe(([search, scenes]) => {
      if (!search) {
        this.filteredScenes$.next(scenes);
        return;
      }
      this.filteredScenes$.next(scenes.filter((card) => card.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }
}
