import { Injectable } from '@angular/core';

import { DOMAIN, PortalAPI } from '@summa/models';
import { Observable } from 'rxjs';
import { AuthenticationService, AuthQuery } from '../../../data-access';

@Injectable()
export class LoginSandbox {
  isLoading$: Observable<boolean>;
  error$: Observable<any>;
  successful: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;
  currentUser$: Observable<DOMAIN.User | undefined>;

  constructor(private authenticationService: AuthenticationService, private authQuery: AuthQuery) {
    this.isLoading$ = this.authQuery.isLoading$;
    this.error$ = this.authQuery.error$;
    this.successful = this.authQuery.isSuccessful$;
    this.isLoggedIn$ = this.authenticationService.isLoggedIn$;
    this.currentUser$ = this.authQuery.selectActive();
  }

  checkIfAlreadyLogged(): void {
    if (!this.authenticationService.isLoggedIn()) {
      this.authQuery.selectActiveId();
    }
  }

  login(login: PortalAPI.LoginInput): void {
    this.authenticationService.login(login);
  }
}
