import { PortalAPI, DOMAIN, PERMISSIONS } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { RESELLER_ROLES } from '../roles/reseller-roles.constant';
import { PROJECT_ROLES } from '../roles/project.roles.constants';

export const getUserRoles = (
  type: string,
  reseller?: PortalAPI.Reseller,
  project?: DTO.Project,
  controlPanelIds?: string[],
  // clientIds?: string[],
): DOMAIN.ApplicationRole => {
  switch (type) {
    case PERMISSIONS.applicationReseller:
      return RESELLER_ROLES(reseller);
    case PERMISSIONS.applicationProject:
      return PROJECT_ROLES(reseller, project, controlPanelIds);
    default:
      return null;
  }
};
