/* eslint-disable @typescript-eslint/no-explicit-any */
export default (parsedFile: any, fixtureLayerName: string) => {
  return parsedFile.entities
    .filter((entity: any) => entity.type === 'INSERT' && entity.layer === fixtureLayerName)
    .map((entity: any) => ({
      block: entity.name,
      x: entity.position.x,
      y: entity.position.y,
      iconData: entity,
    }));
};
