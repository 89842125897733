import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOMAIN } from '@summa/models';
import { AutomationQuery } from '../../../../../data-access';

@Injectable()
export class ProjectAutomationInfoSandbox {
  automation$: Observable<DOMAIN.Automation> = this.automationQuery.selectActive();

  constructor(private automationQuery: AutomationQuery) {}
}
