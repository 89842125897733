import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { I18NextModule } from 'angular-i18next';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CursorPanelComponent } from './cursor-panel/cursor-panel.component';
import { LoadingDialogComponent } from './loading-dialog/loading-dialog.component';
import { SidePanelContentComponent } from './side-panel/side-panel-content/side-panel-content.component';
import { SidePanelComponent } from './side-panel/side-panel.component';

const declareAndExport = [SidePanelComponent, SidePanelContentComponent, ConfirmationDialogComponent, LoadingDialogComponent, CursorPanelComponent];
@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    I18NextModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    PortalModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatStepperModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  declarations: [...declareAndExport],
  exports: [...declareAndExport],
})
export class SharedUiDialogsModule {}
