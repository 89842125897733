<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.card$ | async as card">
  <ng-container header>
    <h1><span class="orange">{{'reseller:cards.preview.title-prefix' |i18nextCap}}: </span>{{ card.title }}</h1>
  </ng-container>

  <ng-container info>
    <div class="control-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.project' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ card.projectKey }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.selected-scene' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ card.selectedSceneId }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.sort-order' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ card.sortOrder }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.visibility' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ card.hidden }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <div class="control-info" fxLayout="row wrap">
      <div class="item mt-2" fxFlex="100">
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'reseller:cards.preview.devices' |i18nextCap}}</div>
          <div class="content" fxFlex="80">
            <div *ngFor="let device of sandbox.devices$ |async" class="mb-1" fxLayout="row" fxLayoutAlign="start center">
              <div [title]="device.id">{{device.key}} - {{device.address}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item mt-2" fxFlex="100">
        <div class="item" fxLayout="row wrap">
          <div class="title" fxFlex="20">{{'reseller:cards.preview.available-scenes' |i18nextCap}}</div>
          <div class="content" fxFlex="80">
            <div *ngFor="let scene of card.scenes" class="mb-1" fxLayout="row" fxLayoutAlign="start center">
              <div [title]="scene.description">{{scene.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer></ng-container>
</summa-side-panel-content>
