import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, map, takeUntil, withLatestFrom } from 'rxjs';
import { resolveDriverId } from '@summa/shared/util/domain';
import { ReplaceDriverDialogData } from './replace-driver-dialog.model';

@Component({
  selector: 'summa-replace-driver-dialog',
  templateUrl: './replace-driver-dialog.component.html',
  styleUrls: ['./replace-driver-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReplaceDriverDialogComponent implements OnInit {
  currentStep$ = new BehaviorSubject<number>(0);
  reason$ = new Subject<string>();
  newDriver$ = new Subject<void>();
  destroy$ = new Subject<void>();
  error$ = new Subject<string | null>();

  @ViewChild('scanInput', { static: false }) scanInputField: ElementRef;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Input() state$: Observable<any>;
  @Input() response$: Observable<boolean>;
  @Output() scan: EventEmitter<unknown> = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: ReplaceDriverDialogData, private changeDetectorRef: ChangeDetectorRef) {
    this.currentStep$.next(0);
  }

  ngOnInit(): void {
    this.handleReason();
    this.handleNewDriver();
  }

  private handleReason(): void {
    this.reason$
      .pipe(
        map(() => {
          this.currentStep$.next(1);
          // Required to access this.scanInputField below,
          // otherwise scanInputField will be undefined
          this.changeDetectorRef.detectChanges();
          this.scanInputField.nativeElement.focus();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleNewDriver(): void {
    this.newDriver$
      .pipe(
        withLatestFrom(this.reason$),
        map(([, reason]) => {
          this.error$.next(null);
          // check if scanInput has a value
          const { value } = this.scanInputField.nativeElement;
          if (!value) return;
          // check if the value is the same as the current driver
          if (value === this.dialogData.driver?.key) {
            this.error$.next('error.driver-same-as-current');
            this.scanInputField.nativeElement.focus();
            this.scanInputField.nativeElement.select();
            return;
          }
          // check if the driver type is the same as the current driver type
          const deviceType = this.dialogData.driver?.type;
          const newDeviceType = resolveDriverId(value).driverType;
          if (deviceType !== newDeviceType) {
            this.error$.next('error.driver-type-mismatch');
            this.scanInputField.nativeElement.focus();
            this.scanInputField.nativeElement.select();
            return;
          }

          this.scan.emit({ newKey: value, replaceState: reason });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
