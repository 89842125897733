import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CONSTANTS, PERMISSIONS } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { BreadcrumbService } from 'xng-breadcrumb';
import { isNotNullOrUndefined, onlyUnique, onlyUniqueObjects } from '@summa/shared/util/typescript';
import { Counter } from '@summa/shared/dashboard/ui';
import { I18NextCapPipe } from 'angular-i18next';
import { ProjectControlAddPage } from '../project-controls/project-control-add/project-control-add.page';
import { ProjectControlPreviewPage } from '../project-controls/project-control-preview/project-control-preview.page';
import { ProjectFloorplansAddPage } from '../project-floorplans/project-floorplans-add/project-floorplans-add.page';
import { ProjectFloorplansPreviewPage } from '../project-floorplans/project-floorplans-preview/project-floorplans-preview.page';
import { ProjectScenarioAddPage } from '../project-scenarios/project-scenario-add/project-scenario-add.page';
import { ProjectScenarioPreviewPage } from '../project-scenarios/project-scenario-preview/project-scenario-preview.page';

import { ProjectDetailsSandbox } from './project-details.sandbox';

@Component({
  selector: 'summa-project-details',
  templateUrl: './project-details.page.html',
  styleUrls: ['./project-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectDetailsSandbox],
})
export class ProjectDetailsPage implements OnInit, OnDestroy {
  controls$ = this.sandbox.controls$;
  drivers$ = this.sandbox.drivers$;
  driverCount$ = this.sandbox.driverCount$;

  scenarioCounters$: Observable<Counter[]> = this.sandbox.scenarios$.pipe(
    map((scenarios): Counter[] => [
      { title: this.i18next.transform('common:dictionary.plural.scenario'), count: scenarios.length, class: 'success' },
    ]),
  );

  controlCounters$: Observable<Counter[]> = this.sandbox.controls$.pipe(
    map((controls): Counter[] => [{ title: this.i18next.transform('common:dictionary.plural.control'), count: controls.length, class: 'success' }]),
  );

  floorplans$ = this.sandbox.floorplans$;

  addControl$ = new Subject();
  addFloorplan$ = new Subject();
  addCard$ = new Subject();
  addScenario$ = new Subject();

  destroy$ = new Subject();

  userManagementPermission: PERMISSIONS.Permission;
  controlPanelPermission: PERMISSIONS.Permission;

  private projectKey: string;

  constructor(
    public sandbox: ProjectDetailsSandbox,
    private sidePanelService: SidePanelService,
    private breadcrumbService: BreadcrumbService,
    private i18next: I18NextCapPipe,
    private router: Router,
  ) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleCurrentUserPermissions();
    this.handleProject();
    this.handleAddControl();
    this.handleAddFloorplan();
    this.handleAddScenario();
  }

  openControlPreview(controlId: string): void {
    this.sandbox.getControl(controlId);
    this.sidePanelService.open(ProjectControlPreviewPage);
  }

  openFloorplanPreview(floorplanId: string): void {
    this.sandbox.getFloorplan(floorplanId);
    this.sidePanelService.open(ProjectFloorplansPreviewPage);
  }

  openScenarioPreview(scenarioId: string): void {
    this.sandbox.getScenario(scenarioId);
    this.sidePanelService.open(ProjectScenarioPreviewPage);
  }

  private handleProject(): void {
    this.sandbox.project$
      .pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$))
      .subscribe((project) => this.breadcrumbService.set('@projectName', project.name));
  }

  private handleCurrentUserPermissions(): void {
    // Check if user only has control-panel permissions, if so navigate to control-panels
    this.sandbox.currentUser$
      .pipe(withLatestFrom(this.sandbox.params$), filter(isNotNullOrUndefined), takeUntil(this.destroy$))
      .subscribe(([{ permissions }, { projectKey, resellerId }]) => {
        const filteredPermissions = permissions.filter(
          (perm) =>
            perm.application.name === PERMISSIONS.applicationReseller &&
            perm.domain.name === PERMISSIONS.applicationProject &&
            [projectKey, PERMISSIONS.wildcardQualifier].includes(perm.domain.qualifiers[0]),
        );
        const subdomains = filteredPermissions.map((p) => p.subdomain).filter(onlyUniqueObjects);

        if (subdomains.every((cpp) => cpp.name === 'control-panel')) {
          // check if there is only 1 qualifier or multiple, redirect accordingly
          const qualifiers = subdomains
            .filter((sd) => sd.name === 'control-panel')
            .flatMap((cpp) => cpp.qualifiers)
            .filter(onlyUnique);

          if (qualifiers.length > 1 || qualifiers[0] === PERMISSIONS.wildcardQualifier) {
            this.router.navigate([`/reseller/${resellerId}/projects/${projectKey}/control-panels`]);
            return;
          }

          this.router.navigate([`/reseller/${resellerId}/projects/${projectKey}/control-panels/${qualifiers[0]}`]);
          return;
        }

        // user-permissions checked and user is allowed to see this page, get controls and floorplans
        this.sandbox.getControls(projectKey);
        this.sandbox.getFloorplans(projectKey);
        this.projectKey = projectKey;

        // SETUP user-management permission and control-panel permission
        this.userManagementPermission = {
          application: {
            name: PERMISSIONS.applicationReseller,
            qualifiers: [resellerId, PERMISSIONS.wildcardQualifier],
          },
          domain: {
            name: PERMISSIONS.applicationProject,
            qualifiers: [projectKey, PERMISSIONS.wildcardQualifier],
          },
          subdomain: {
            name: PERMISSIONS.domainUserManagement,
            qualifiers: [PERMISSIONS.wildcardQualifier],
          },
          action: PERMISSIONS.actionRead,
        };

        this.controlPanelPermission = {
          application: {
            name: PERMISSIONS.applicationReseller,
            qualifiers: [resellerId, PERMISSIONS.wildcardQualifier],
          },
          domain: {
            name: PERMISSIONS.applicationProject,
            qualifiers: [projectKey, PERMISSIONS.wildcardQualifier],
          },
          subdomain: {
            name: PERMISSIONS.domainControlPanel,
            qualifiers: [PERMISSIONS.wildcardQualifier],
          },
          action: PERMISSIONS.actionRead,
        };
      });
  }

  // ADD PANELS ===========================================================

  private handleAddFloorplan(): void {
    this.addFloorplan$.pipe(takeUntil(this.destroy$)).subscribe((floorplan) => {
      this.sidePanelService.open(ProjectFloorplansAddPage, floorplan);
    });
  }

  private handleAddControl(): void {
    this.addControl$.pipe(takeUntil(this.destroy$)).subscribe((control) => {
      this.sidePanelService.open(ProjectControlAddPage, control);
    });
  }

  private handleAddScenario(): void {
    this.addScenario$.pipe(takeUntil(this.destroy$)).subscribe((scenario) => {
      this.sidePanelService.open(ProjectScenarioAddPage, scenario);
    });
  }

  // ======================================================================

  executeSync(subject: CONSTANTS.SyncType): void {
    this.sandbox.executeSync(this.projectKey, subject, 'full');
  }
}
