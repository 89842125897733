import { gql } from 'apollo-angular';

export const findControlPanelsQuery = gql`
  query FindControlPanels($projectKey: String!, $take: Int, $skip: Int) {
    findControlPanels(projectKey: $projectKey, take: $take, skip: $skip) {
      result {
        id
        name
        projectKey
      }
      meta {
        total
        page
        limit
        query
        sort {
          field
          order
        }
        filters {
          field
          value
        }
      }
    }
  }
`;
