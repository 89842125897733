import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SharedDashboardUiModule } from '@summa/shared/dashboard/ui';
import { I18NextModule } from 'angular-i18next';
import { SummaComponentsModule } from '../components/components.module';
import { NotificationWidgetComponent } from './notification-widget/notification-widget.component';

@NgModule({
  imports: [SummaComponentsModule, RouterModule, CommonModule, I18NextModule, MatIconModule, FlexLayoutModule, SharedDashboardUiModule],
  declarations: [NotificationWidgetComponent],
  exports: [NotificationWidgetComponent],
})
export class SummaPortalNotificationsSharedModule {}
