import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ENV } from '@summa/models';
import { DxfService } from '@summa/shared/dxf';
import { BehaviorSubject, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { ReUploadFloorplanSandbox } from './re-upload-floorplan.sandbox';

@Component({
  selector: 'portal-dashboard-re-upload-floorplan',
  templateUrl: './re-upload-floorplan.page.html',
  styleUrls: ['./re-upload-floorplan.page.scss'],
  providers: [ReUploadFloorplanSandbox],
})
export class ReUploadFloorplanPage implements OnInit, OnDestroy {
  form: FormGroup;
  floorplanLayer: FormControl;
  fixtureLayer: FormControl;
  shouldUploadFloorplanOnly: FormControl;
  fixtureFieldsOverride = [];

  dxfString$ = new Subject();
  dxfLayers$ = new BehaviorSubject<any>(null);
  dxfFileName$ = new Subject<string>();
  isOverrideFixture$ = new Subject<boolean>();
  submit$ = new Subject();
  destroy$ = new Subject();

  constructor(
    private helper: DxfService,
    public sandbox: ReUploadFloorplanSandbox,
    private fb: FormBuilder,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      floorplanLayer: ['', Validators.required],
      fixtureLayer: ['', Validators.required],
      shouldUploadFloorplanOnly: ['', Validators.required],
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getProject(projectKey);
    });

    this.handleSubmit();
  }

  private handleSubmit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.submit$
      .pipe(withLatestFrom(this.sandbox.project$, this.sandbox.floorplan$, this.dxfFileName$), takeUntil(this.destroy$))
      .subscribe(([, project, floorplan, fileName]) => {
        if (this.form.invalid) return;
        const form = this.form.value;
        const dxfString = this.helper.parsedDxfString;
        const fixtures = this.helper.extractFixtureLocations(form.fixtureLayer);
        const dxfJsonData = this.helper.toJson(dxfString, form.floorplanLayer);
        const dataInFile = new Blob([dxfJsonData], { type: 'application/json;charset=utf-8' });
        const file = this.createFile(dataInFile, fileName);

        // Remove old floorplan file on S3
        this.sandbox.removeFileOnS3(this.environment.awsConfig.floorplan.bucket, floorplan, `${floorplan.projectKey}/${floorplan.fileName}`);

        // Upload new floorplan file on S3
        this.sandbox.uploadFile(this.environment.awsConfig.floorplan.bucket, file, project.key);

        // Upsert floorplan in DB
        const overrideFloorplan = {
          id: floorplan.id,
          name: fileName.toLowerCase(),
          projectKey: project.key,
          description: '',
          fileName: `${fileName}.json`,
          fileLocation: `${this.environment.floorplanUrl}/${project.key}/${file.name}`,
        };
        const fixtureInputs = fixtures.map((fixture) => ({
          floorplanKey: '',
          location: {
            x: fixture.x,
            y: fixture.y,
            height: 0,
          },
          locationKey: fixture.iconData.handle,
          iconData: fixture.iconData,
          mainGroup: '',
          devices: [],
          status: 'to-be-configured',
        }));

        const floorplanOverrides = {
          fixtureOverrides: this.fixtureFieldsOverride,
          floorplanOnly: form.shouldUploadFloorplanOnly,
        };

        this.sandbox.overrideFloorplan(overrideFloorplan, fixtureInputs, floorplanOverrides);
        this.sandbox.close();
      });
  }

  private createFile = (data: Blob, fileName: string): File => {
    const blob: any = data;
    blob.lastModifiedDate = Date.now();
    blob.name = `${fileName}.json`;
    return data as File;
  };

  handleCheckboxValue(value) {
    if (this.fixtureFieldsOverride.find((v) => v === value)) {
      this.fixtureFieldsOverride = this.fixtureFieldsOverride.filter((v) => v !== value);
    } else {
      this.fixtureFieldsOverride.push(value);
    }
  }
}
