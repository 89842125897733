import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { I18NextCapPipe } from 'angular-i18next';

import { ProjectDriverWidgetSandbox } from './project-driver-widget.sandbox';
import { SensorBaselineDialogComponent } from '../../../components/sensor-baseline-dialog/sensor-baseline-dialog.component';
import { SensorBaselineDialogData } from '../../../components/sensor-baseline-dialog/sensor-baseline-dialog.model';

@Component({
  selector: 'summa-project-driver-widget',
  templateUrl: './project-driver-widget.component.html',
  styleUrls: ['./project-driver-widget.component.scss'],
  providers: [ProjectDriverWidgetSandbox],
})
export class ProjectDriverWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  syncClick$ = new Subject();
  currentCheck$ = new Subject();
  setBaseline$ = new Subject<void>();

  confirmDialog: MatDialogRef<SensorBaselineDialogComponent, MatDialogConfig>;

  constructor(public sandbox: ProjectDriverWidgetSandbox, private dialog: MatDialog, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.reset();
  }

  ngOnInit(): void {
    this.handleParams();
    this.handleSync();
    this.handleCurrentCheck();
    this.handleSetBaseline();
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getDrivers(projectKey);
      this.sandbox.observeFixtureState(projectKey);
    });
  }

  private handleSync(): void {
    this.syncClick$
      .pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([, params]) => this.sandbox.executeSync(params.projectKey, 'drivers', 'full'));
  }

  private handleCurrentCheck(): void {
    this.currentCheck$
      .pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([, params]) => this.sandbox.executeCurrentCheck(params.projectKey));
  }

  private handleSetBaseline(): void {
    this.setBaseline$
      .pipe(
        withLatestFrom(this.sandbox.params$),
        map(([, params]) => {
          const dialogConfig: MatDialogConfig<SensorBaselineDialogData> = {
            autoFocus: true,
            data: {
              title: this.i18next.transform('project:drivers.set-sensors-baseline'),
              content: this.i18next.transform('project:drivers.actions.sensors.confirm-set-sensors-to-baseline'),
              cancelButton: this.i18next.transform('common:buttons.cancel'),
              primaryButton: this.i18next.transform('common:buttons.submit'),
            },
          };

          this.confirmDialog = this.dialog.open(SensorBaselineDialogComponent, dialogConfig);
          return this.confirmDialog.componentInstance.confirm.pipe(first()).subscribe((value: { baseline: number }) => {
            this.sandbox.executeSetBaseline(params.projectKey, value.baseline);
            this.confirmDialog.close();
          });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
