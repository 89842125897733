import { Injectable } from '@angular/core';

import { ScenarioQuery, ScenarioService } from '../../../../data-access';

@Injectable()
export class ProjectScenarioPreviewSandbox {
  scenario$ = this.scenarioQuery.selectActive();

  constructor(private scenarioQuery: ScenarioQuery, private scenarioService: ScenarioService) {}

  resetActive(): void {
    this.scenarioService.setActive(null);
  }
}
