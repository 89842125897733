<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.project$ | async as project">
  <ng-container header>
    <h1>{{ (updateGateway ? 'project:gateway.edit.title' : 'project:gateway.add.title') | i18nextCap }}</h1>
  </ng-container>

  <ng-container info>
    <section class="section">
      <div>
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">{{ project.key}}</div>
      </div>
    </section>
  </ng-container>

  <ng-container body>
    <form *ngIf="form" [formGroup]="form" (ngSubmit)="submit$.next()" class="form p-0 pt-2">
      <mat-form-field fxFlex appearance="legacy">
        <mat-label>{{'common:dictionary.serial-number' | i18nextCap}}</mat-label>
        <input matInput formControlName="gatewayKey" />
      </mat-form-field>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="form.invalid" mat-button type="button" (click)="submit$.next()" class="button save">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
