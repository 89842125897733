import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { HeatGridLogState, HeatGridLogStore } from './heat-grid-log.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'address',
  sortByOrder: Order.ASC,
})
export class HeatGridLogQuery extends QueryEntity<HeatGridLogState, DOMAIN.TemperatureSensorLog> {
  constructor(protected store: HeatGridLogStore) {
    super(store);
  }
}
