import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, first, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { ProjectGatewayDetailsPage } from '../../project-gateway/project-gateway-details/project-gateway-details.page';
import { ProjectGatewayUpsertPage } from '../../project-gateway/project-gateway-upsert/project-gateway-upsert.page';
import { ProjectGatewayWidgetSandbox } from './project-gateway-widget.sandbox';

@Component({
  selector: 'summa-project-gateway-widget',
  templateUrl: './project-gateway-widget.component.html',
  styleUrls: ['./project-gateway-widget.component.scss'],
  providers: [ProjectGatewayWidgetSandbox],
})
export class ProjectGatewayWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  syncGateway$ = new Subject<void>();
  gatewayDetail$ = new Subject<void>();
  updateGateway$ = new Subject<void>();
  upsertGateway$ = new Subject<string>();


  constructor(public sandbox: ProjectGatewayWidgetSandbox, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleProject();
    this.handleSyncGateway();
    this.handleGatewayDetail();
    this.handleUpsertGateway();
    this.handleUpdateGateway();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.sandbox.unsubscribe();
  }

  private handleProject(): void {
    this.sandbox.project$
      .pipe(
        filter((project) => isNotNullOrUndefined(project?.gatewayKey)),
        first(),
        takeUntil(this.destroy$),
      )
      .subscribe((project) => {
        this.sandbox.findGateway(project.gatewayKey);
        this.sandbox.observeGatewayState(project.key);
      });
  }

  private handleUpsertGateway(): void {
    this.upsertGateway$.pipe(takeUntil(this.destroy$)).subscribe((gateway) => {
      this.sidePanelService.open(ProjectGatewayUpsertPage, gateway);
    });
  }

  private handleGatewayDetail(): void {
    this.gatewayDetail$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.sidePanelService.open(ProjectGatewayDetailsPage);
    });
  }

  private handleUpdateGateway(): void {
    this.updateGateway$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      this.sandbox.updateGateway(project.key);
    });
  }

  private handleSyncGateway(): void {
    this.syncGateway$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      this.sandbox.syncGateway(project.key);
    });
  }
}
