import { gql } from 'apollo-angular';

export const upsertScenarioQuery = gql`
  mutation upsertScenario($scenario: ScenarioInput!) {
    upsertScenario(scenario: $scenario) {
      id
      projectKey
      name
      description
      icon
      hidden
      entries {
        cardId
        sceneId
      }
    }
  }
`;

export const removeScenarioQuery = gql`
  mutation removeScenario($id: String!) {
    removeScenario(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
