import { gql } from 'apollo-angular';

export const getNotificationsQuery = gql`
  query getNotifications($projects: [String!]!, $take: Float, $types: [String!]) {
    getNotifications(projects: $projects, take: $take, types: $types) {
      id
      timestamp
      project
      type
      title
      message
      trace
    }
  }
`;

export const getTodaysNotificationsQuery = gql`
  query getTodaysNotifications($projects: [String!]!) {
    getTodaysNotifications(projects: $projects) {
      id
      timestamp
      project
      type
      title
      message
      trace
    }
  }
`;

export const findNotificationsQuery = gql`
  query FindNotifications(
    $timeInterval: PaginatedNotificationTimeIntervalPagination
    $query: String
    $sort: PaginatedNotificationSortPagination
    $filters: [PaginatedNotificationFilterPagination!]
  ) {
    findNotifications(timeInterval: $timeInterval, query: $query, sort: $sort, filters: $filters) {
      result {
        id
        timestamp
        # project
        # type
        # title
        message
      }
      meta {
        total
      }
    }
  }
`;
