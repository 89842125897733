import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { DOMAIN, ENV } from '@summa/models';
import { isDeviceTurnedOn } from '@summa/shared/util/domain';

import { CustomerCardSandbox } from '../../../customer-card/customer-card.sandbox';

@Component({
  selector: 'summa-card-detail-tab-lights',
  templateUrl: './card-detail-tab-lights.component.html',
  styleUrls: ['./card-detail-tab-lights.component.scss'],
  providers: [CustomerCardSandbox],
})
export class CardDetailTabLightsComponent implements OnInit, OnDestroy {
  entityToggle$ = new BehaviorSubject(null);
  destroy$ = new Subject();

  constructor(public sandbox: CustomerCardSandbox, @Inject('environment') private environment: ENV.Environment) {}

  ngOnInit(): void {
    this.handleToggleDevice();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleToggleDevice(): void {
    this.entityToggle$.pipe(withLatestFrom(this.sandbox.card$)).subscribe(([event, card]) => {
      if (!event || !card) return;

      const state = event.state.checked ? 'ON' : 'OFF';
      const projectKey = this.environment.app.isPortal ? card.projectKey : null;
      this.sandbox.executeDeviceCommand(event.entity, projectKey, state, card.selectedSceneId);
    });
  }

  isActive(entity: DOMAIN.Device, fixtureLogs: DOMAIN.FixtureLog[]): boolean {
    return isDeviceTurnedOn(entity, fixtureLogs);
  }
}
