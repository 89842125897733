import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';

import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, PERMISSIONS } from '@summa/models';
import { modelToPermissionString } from '@summa/shared/auth/util';
import { flatten, isNotNullOrUndefined, onlyUnique } from '@summa/shared/util/typescript';
import { UserUpsertSandbox } from './user-upsert.sandbox';
import { getUserRoles } from '../../../permission-access/helpers';

@Component({
  selector: 'summa-reseller-user-upsert',
  templateUrl: './user-upsert.page.html',
  styleUrls: ['./user-upsert.page.scss'],
  providers: [UserUpsertSandbox],
})
export class UserUpsertPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submit$ = new Subject();
  isNewUser$ = new Subject();
  isResellerLevel$ = new BehaviorSubject<boolean>(false);
  isProjectLevel$ = new BehaviorSubject<boolean>(false);

  form: FormGroup;
  rolesList: DOMAIN.ApplicationRole;
  selectedRoles = new SelectionModel(true, []);

  constructor(private fb: FormBuilder, public sandbox: UserUpsertSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleApplication();
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
    this.handleIsNewUser();
  }

  private handleData(): void {
    this.sandbox.data$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (!user) {
        this.form = this.fb.group({
          username: ['', [Validators.required, Validators.minLength(2)]],
          password: ['', [Validators.required, Validators.minLength(6)]],
          language: ['en', Validators.required],
          permissions: [[]],
          controlPanels: [[]],
          // clients: [[]],
        });
        return;
      }

      this.sandbox.getUser(user.id);
    });

    this.sandbox.user$
      .pipe(withLatestFrom(this.sandbox.reseller$, this.sandbox.project$, this.isResellerLevel$), takeUntil(this.destroy$))
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .subscribe(([user, reseller, project, isResellerLevel]) => {
        let selectedControlPanels = null;
        if (!isResellerLevel) {
          selectedControlPanels = user.permissions.filter(
            (p) =>
              p.application.qualifiers[0] === reseller.id &&
              p.domain.qualifiers[0] === project.key &&
              p.subdomain.name.includes(PERMISSIONS.domainControlPanel),
          );
        }

        const userPermissions = user.permissions.map(modelToPermissionString);
        this.rolesList.roles.forEach((role) => {
          switch (role.role) {
            case 'roles.control-panel-admin':
              if (selectedControlPanels.some((cp) => cp.action === PERMISSIONS.actionUpsert)) this.selectedRoles.select(role);
              break;
            case 'roles.control-panel-user':
              if (selectedControlPanels.some((cp) => cp.action === PERMISSIONS.actionRead)) this.selectedRoles.select(role);
              break;
            default:
              if (role.permissions.reduce((exists, permission) => (!userPermissions.includes(permission) ? false : exists), true))
                this.selectedRoles.select(role);
          }
        });

        this.form = this.fb.group({
          id: user.id,
          username: [user.username, Validators.required],
          language: [user.language, Validators.required],
          permissions: [userPermissions],
          controlPanels: [selectedControlPanels?.map((c) => c.subdomain.qualifiers[0])],
          // clients: [],
        });
      });
  }

  private handleApplication(): void {
    this.sandbox.application$
      .pipe(filter(isNotNullOrUndefined), withLatestFrom(this.sandbox.reseller$, this.sandbox.project$), takeUntil(this.destroy$))
      .subscribe(([application, reseller, project]) => {
        switch (application) {
          case PERMISSIONS.applicationReseller:
            this.isResellerLevel$.next(true);
            this.rolesList = getUserRoles(application, reseller, project);
            break;
          case PERMISSIONS.applicationProject:
            this.isProjectLevel$.next(true);
            this.rolesList = getUserRoles(application, reseller, project);
            this.sandbox.getControlPanels(project.key);
            break;
          default:
            this.rolesList = null;
            break;
        }
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(withLatestFrom(this.sandbox.application$, this.sandbox.reseller$, this.sandbox.project$), takeUntil(this.destroy$))
      .subscribe(([, application, reseller, project]) => {
        if (this.form.invalid || !application || this.selectedRoles.selected.length === 0) return;
        const rolesListPermissions = flatten(this.rolesList.roles.map((role) => role.permissions)).filter(onlyUnique);
        const selectedRoles = this.selectedRoles.selected.map((role) => role.permissions);
        const filteredFormPermissions = this.form.value.permissions.filter(
          (permission) => !rolesListPermissions.includes(permission) && !permission.includes(PERMISSIONS.domainControlPanel),
        );
        let permissions = [...new Set([...filteredFormPermissions, ...flatten(selectedRoles)])];

        const formControlPanels = this.form.getRawValue().controlPanels;
        if (formControlPanels?.length > 0) {
          const controlPanelRolesList = getUserRoles(application, reseller, project, formControlPanels)
            .roles.filter(({ role }) => role.includes('control-panel') && this.selectedRoles.selected.some((r) => r.role === role))
            .map((role) => role.permissions);

          const filteredSelectedRoles = this.selectedRoles.selected
            .filter(({ role }) => !role.includes('control-panel'))
            .map((role) => role.permissions);

          permissions = [...new Set([...filteredFormPermissions, ...flatten(filteredSelectedRoles), ...flatten(controlPanelRolesList)])];
        }

        const formValues = this.form.value;
        delete formValues.controlPanels;

        const userInput = {
          ...formValues,
          permissions,
        };

        if (!this.form.get('id')) {
          this.sandbox.createUser(userInput);
        } else if (this.form.get('id') && !this.form.get('username')) {
          this.sandbox.addUserPermissions(userInput);
        } else {
          this.sandbox.updateUser(userInput);
        }
      });
  }

  private handleIsNewUser(): void {
    this.isNewUser$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (this.form.get('id')) {
        // show username and password
        this.form.removeControl('id');
        this.form.addControl('username', this.fb.control('', [Validators.required]));
        this.form.addControl('password', this.fb.control('', [Validators.required]));
        return;
      }

      // show ID field
      this.form.removeControl('username');
      this.form.removeControl('password');
      this.form.addControl('id', this.fb.control('', [Validators.required]));
    });
  }
}
