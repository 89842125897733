import { Injectable } from '@angular/core';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter } from 'rxjs';
import { InstallerQuery, InstallerService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectInstallerUpsertSandbox {
  project$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  installer$ = this.installerQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  upsertState$ = this.installerQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData();

  constructor(
    private projectQuery: ProjectQuery,
    private installerQuery: InstallerQuery,
    private installerService: InstallerService,
    private sidePanelService: SidePanelService,
  ) {}

  getInstaller(id: string): void {
    this.installerService.getInstaller(id);
  }

  upsertInstaller(installer: DOMAIN.InstallerInput): void {
    this.installerService.upsertInstaller(installer);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.installerService.resetActive();
  }
}
