/**
 * const currentVersion = '1.0.0-xxx';
 * const newVersion = '2.0.0';
 * const isOutdated = compareVersions(currentVersion, newVersion);
 */
export function compareVersions(currentVersion: string, newVersion: string): boolean {
  const currentParts = currentVersion.split('.');
  const newParts = newVersion.split('.');

  for (let i = 0; i < Math.max(currentParts.length, newParts.length); i += 1) {
    const currentPart = parseInt(currentParts[i], 10) || 0;
    const newPart = parseInt(newParts[i], 10) || 0;

    if (newPart > currentPart) {
      return true;
    }
    if (newPart < currentPart) {
      return false;
    }
  }
  // If both versions are equal, the current version is not outdated.
  return false;
}
