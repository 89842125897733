import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectScenarioPreviewSandbox } from './project-scenario-preview.sandbox';

@Component({
  selector: 'summa-project-scenario-preview',
  templateUrl: './project-scenario-preview.page.html',
  styleUrls: ['./project-scenario-preview.page.scss'],
  providers: [ProjectScenarioPreviewSandbox],
})
export class ProjectScenarioPreviewPage implements OnInit, OnDestroy {
  scenario$ = this.sandbox.scenario$;
  destroy$ = new Subject();

  currentRoute: string;

  constructor(private sandbox: ProjectScenarioPreviewSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.resetActive();
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url.includes('scenarios') ? this.router.url : `${this.router.url}/scenarios`;
  }

  closePanel(): void {
    this.sidePanelService.close();
  }
}
