import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PERMISSIONS, PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ResellerAddPage } from '../reseller-add/reseller-add.page';

import { ResellerDashboardSandbox } from './reseller-dashboard.sandbox';

@Component({
  selector: 'summa-reseller-dashboard',
  templateUrl: './reseller-dashboard.page.html',
  styleUrls: ['./reseller-dashboard.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResellerDashboardSandbox],
})
export class ResellerDashboardPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertResellerPanel$ = new Subject<PortalAPI.Reseller>();
  openPreset$ = new Subject<string>();
  userManagementPermission: PERMISSIONS.Permission;

  constructor(public sandbox: ResellerDashboardSandbox, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleUpsertResellerPanel();
    this.handleCurrentUserPermissions();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
    this.sandbox.reset();
  }

  public propertyExists(value: string): boolean {
    return value && value !== '';
  }

  private handleCurrentUserPermissions(): void {
    this.sandbox.reseller$.pipe(takeUntil(this.destroy$)).subscribe((reseller) => {
      // TODO: Create migration for existing users to add user-management permissions
      this.userManagementPermission = {
        application: {
          name: PERMISSIONS.applicationReseller,
          qualifiers: [reseller?.id, PERMISSIONS.wildcardQualifier],
        },
        domain: {
          name: PERMISSIONS.domainUserManagement,
          qualifiers: [PERMISSIONS.wildcardQualifier],
        },
        subdomain: null,
        action: PERMISSIONS.actionRead,
      };
    });
  }

  private handleUpsertResellerPanel(): void {
    this.upsertResellerPanel$.pipe(takeUntil(this.destroy$)).subscribe((reseller: PortalAPI.Reseller) => {
      this.sidePanelService.open(ResellerAddPage, reseller);
    });
  }
}
