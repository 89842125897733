import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';
import { Observable } from 'rxjs';

import { NotificationState, NotificationStore, TodaysNotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'timestamp',
  sortByOrder: Order.DESC,
})
export class NotificationQuery extends QueryEntity<NotificationState, DOMAIN.Notification> {
  selectLive$: Observable<DOMAIN.Notification[]>;

  constructor(protected store: NotificationStore) {
    super(store);

    this.selectLive$ = this.select((state) => state.live);
  }
}

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'timestamp',
  sortByOrder: Order.DESC,
})
export class TodaysNotificationQuery extends QueryEntity<NotificationState, DOMAIN.Notification> {
  selectLive$: Observable<DOMAIN.Notification[]>;

  // eslint-disable-next-line sonarjs/no-identical-functions
  constructor(protected store: TodaysNotificationStore) {
    super(store);

    this.selectLive$ = this.select((state) => state.live);
  }
}
