// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CONSTANTS } from '@summa/models';

export type StateType = CONSTANTS.StateType;
export type RedisType = CONSTANTS.RedisType;
export type NotificationType = CONSTANTS.NotificationType;
export type MqttType = CONSTANTS.MqttType;

export const {
  cctDefault,
  cctMin,
  cctMax,
  defaultSceneSettings,
  dimValueMin,
  dimValueMax,
  dimValueDefault,
  duvDefault,
  duvMin,
  duvMax,
  mqttNotificationOut,
  redisKeyGateways,
  redisKeyControlPanels,
  stateOn,
  stateOff,
} = CONSTANTS;
