import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { map } from 'rxjs/operators';

import { I18NextCapPipe } from 'angular-i18next';
import { Observable } from 'rxjs';
import { Counter } from '@summa/shared/dashboard/ui';
import { DTO } from '@summa/portal/models/dto';

import { ControlPanelQuery, ControlPanelService } from '../../../data-access';

@Injectable()
export class ControlPanelWidgetSandbox {
  controlPanels$: Observable<DTO.ControlPanel[]>;
  params$: Observable<{ projectKey: string }>;
  controlPanelCounters$: Observable<Counter[]>;

  constructor(
    private controlPanelService: ControlPanelService,
    private controlPanelQuery: ControlPanelQuery,
    private router: RouterQuery,
    private i18next: I18NextCapPipe,
  ) {
    this.controlPanels$ = this.controlPanelQuery.selectAll();
    this.params$ = this.router.selectParams();
    this.controlPanelCounters$ = this.controlPanels$.pipe(
      map((controls): Counter[] => [
        { title: this.i18next.transform('common:dictionary.control-panel_plural'), count: controls.length, class: 'success' },
      ]),
    );
  }

  getControlPanels(projectKey: string): void {
    this.controlPanelService.getControlPanels(projectKey);
  }

  reset(): void {
    this.controlPanelService.reset();
  }
}
