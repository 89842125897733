import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { getHeatGridLogsQuery, heatGridLogSubscription } from './graphql';
import { HeatGridLogStore } from './heat-grid-log.store';

@Injectable({
  providedIn: 'root',
})
export class HeatGridLogService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private heatGridLogStore: HeatGridLogStore) {}

  reset(): void {
    this.heatGridLogStore.reset();
  }

  getHeatGridLogs(project: string): void {
    this.apollo
      .subscribe({ query: getHeatGridLogsQuery, variables: { project } })
      .pipe(map((d: any) => d.data.getHeatGridLogs as DOMAIN.TemperatureSensorLog[]))
      .subscribe((heatGridLogs) => heatGridLogs?.length > 0 && this.heatGridLogStore.upsertMany(heatGridLogs));
  }

  subscribe(projects: string[]): void {
    this.unsubscribe();

    this.subscriptions = this.apollo.subscribe({ query: heatGridLogSubscription, variables: { projects } }).subscribe((heatGridLog) => {
      const state = (heatGridLog.data as { observeHeatGridLogs: DOMAIN.TemperatureSensorLog }).observeHeatGridLogs;
      this.heatGridLogStore.upsert(state.address, state);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
  }
}
