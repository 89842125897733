<h1 class="mb-0" mat-dialog-title *ngIf="dialogData.title">
  {{ dialogData.title }}
</h1>
<div mat-dialog-content class="confirmation-modal__content">
  <p class="alert alert-secondary" [innerHTML]="dialogData.content"></p>
  <mat-form-field *ngIf="showConfirmationInput" class="example-full-width" appearance="legacy">
    <mat-label>{{ dialogData.confirmationInputLabel }}</mat-label>
    <input
      matInput
      [placeholder]="confirmationString"
      [(ngModel)]="confirmationInputValue"
      (keyup.enter)="showConfirmationInput && confirmationInputValue === confirmationString ? onConfirmClick() : undefined"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close (click)="onCancelClick()">
    {{ dialogData.cancelButton }}
  </button>
  <button
    mat-button
    mat-raised-button
    [disabled]="showConfirmationInput && confirmationInputValue !== confirmationString"
    [color]="primaryButtonColor"
    (click)="onConfirmClick()"
  >
    {{ dialogData.primaryButton }}
  </button>
  <ng-template #spinner><mat-spinner [diameter]="42"></mat-spinner></ng-template>
</div>
