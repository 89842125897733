import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs/operators';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { db } from '@summa/shared/ui/floorplan';
import { DOMAIN } from '@summa/models';
import { ProjectQuery, FloorplanQuery, FloorplanService, StorageService, FixtureService, FixtureQuery } from '../../../../../data-access';

@Injectable()
export class ProjectFloorplanAdminComponentSandbox {
  project$ = this.projectQuery.selectActive();
  floorplans$ = this.floorplanQuery.selectAll();
  floorplan$ = this.floorplanQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  fixtures$ = this.fixtureQuery.selectAll();
  params$ = this.router.selectParams();
  removeState$ = this.floorplanQuery.selectRemoveState$;

  constructor(
    private projectQuery: ProjectQuery,
    private floorplanQuery: FloorplanQuery,
    private floorplanService: FloorplanService,
    private fixtureService: FixtureService,
    private fixtureQuery: FixtureQuery,
    private storageService: StorageService,
    private router: RouterQuery,
  ) {}

  getFixtures(floorplanId: string): void {
    this.fixtureService.getFixtures(floorplanId);
  }

  removeFile(bucket: string, floorplan: DOMAIN.Floorplan, fileName: string): void {
    // delete file on s3
    this.storageService.deleteFile(bucket, fileName);
    // delete file in DB
    this.floorplanService.removeFloorplan(floorplan.id);
    // delete file in indexedDB
    db.floorplan.delete(`${floorplan.projectKey}-${encodeURI(floorplan.fileName)}`);
  }

  reset(): void {
    this.floorplanService.reset();
  }
}
