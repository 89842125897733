import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { removeScenarioQuery, upsertScenarioQuery } from './graphql/scenario.gql-mutation';
import { GetScenarioQuery, GetScenariosQuery } from './graphql/scenario.gql-query';
import { ScenarioStore } from './scenario.store';

@Injectable({
  providedIn: 'root',
})
export class ScenarioService {
  constructor(private apollo: Apollo, private scenarioStore: ScenarioStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.scenarioStore.reset();
  }

  resetActive(): void {
    this.scenarioStore.resetUIState();
    this.setActive(null);
  }

  setActive(id: string | null): void {
    this.scenarioStore.setActive(id);
  }

  getScenario(id: string): any {
    this.apollo
      .subscribe({ query: GetScenarioQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getScenario as DOMAIN.Scenario))
      .subscribe((scenario) => {
        this.scenarioStore.upsert(scenario.id, scenario);
        this.setActive(scenario?.id);
      });
  }

  getScenarios(projectKey: string, skip?: number, take?: number): void {
    this.scenarioStore.reset();

    this.apollo
      .subscribe({ query: GetScenariosQuery, variables: { skip, take, projectKey } })
      .pipe(map((d: any) => d.data.getScenarios as DOMAIN.Scenario[]))
      .subscribe((scenarios) => this.scenarioStore.set(scenarios));
  }

  upsertScenario(scenario: DOMAIN.ScenarioInput): void {
    this.scenarioStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertScenarioQuery, variables: { scenario } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.scenarioStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `scenario ${scenario.name}` }));

        this.scenarioStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertScenario: DOMAIN.Scenario };
      this.scenarioStore.upsert(data.upsertScenario.id, data.upsertScenario);
      this.scenarioStore.setActive(data.upsertScenario.id);

      // set success;
      this.scenarioStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  remove(id: string): void {
    this.scenarioStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeScenarioQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.scenarioStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `scenario ${id}` }));

        this.scenarioStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.scenarioStore.remove(id);

      // set success;
      this.scenarioStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
