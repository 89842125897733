<form
  [formGroup]="projectForm"
  (ngSubmit)="onSubmit()"
  class="p-4"
  fxLayout="row"
  fxLayoutGap="16px"
  fxLayoutAlign="start start"
  fxLayout.lt-sm="column"
>
  <!-- COLUMN.01 -->
  <div fxLayout="column" fxFlex="40%" fxFlexOffset="5">
    <mat-accordion>
      <!-- PANEL: PROJECT-INFORMATION -->
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)">
        <mat-expansion-panel-header>
          <mat-panel-title class="title"> {{'project:information' | i18nextCap}} </mat-panel-title>
          <span class="step-count">1 / 3</span>
        </mat-expansion-panel-header>
        <!-- client -->
        <div class="project-client-select">
          <mat-form-field appearance="legacy">
            <mat-label>{{'project:select-client' | i18nextCap}}</mat-label>
            <mat-select [formControl]="clientId">
              <mat-option *ngFor="let client of clients$ | async" [value]="client.id"> {{ client.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="clientId.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
          </mat-form-field>
        </div>
        <!-- location -->
        <div class="project-location-select">
          <mat-form-field appearance="legacy">
            <mat-label>{{'project:select-location' | i18nextCap}}</mat-label>
            <mat-select [formControl]="locationId" [disabled]="!projectForm.get('clientId').value">
              <mat-option *ngFor="let location of locations$ |async" [value]="location.id"> {{ location.name }} </mat-option>
            </mat-select>
            <mat-error *ngIf="locationId.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
          </mat-form-field>
        </div>
        <div class="project-timezone-select">
          <summa-timezone-selector [formControl]="timezone"></summa-timezone-selector>
        </div>
        <!-- GEOLOCATION -->
        <section formGroupName="geolocation">
          <section fxLayout="row wrap" fxLayoutGap="0.5rem grid">
            <mat-form-field appearance="legacy" fxFlex="50">
              <mat-label>{{'common:dictionary.longitude' | i18nextCap}}</mat-label>
              <input type="number" matInput formControlName="longitude" />
            </mat-form-field>
            <mat-form-field appearance="legacy" fxFlex="50">
              <mat-label>{{'common:dictionary.latitude' | i18nextCap}}</mat-label>
              <input type="number" matInput formControlName="latitude" />
            </mat-form-field>
          </section>
        </section>
        <!-- project name -->
        <div class="project-name-input">
          <mat-form-field appearance="legacy">
            <mat-label>{{'project:enter-name' | i18nextCap}}</mat-label>
            <input [formControl]="name" matInput />
            <mat-error *ngIf="name.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
          </mat-form-field>
        </div>
        <!-- buttons -->
        <div class="buttons">
          <button [disabled]="!projectSectionCompleted" mat-button type="button" class="button save" (click)="nextStep(); generateKey$.next()">
            {{ 'common:buttons.next-step' | i18next}}
          </button>
        </div>
      </mat-expansion-panel>

      <!-- PANEL: PROJECT-SETTINGS -->
      <mat-expansion-panel formGroupName="settings" [expanded]="step === 1" (opened)="setStep(1)">
        <mat-expansion-panel-header>
          <mat-panel-title class="title"> {{'project:project-settings' | i18nextCap}} </mat-panel-title>
          <span class="step-count">2 / 3</span>
        </mat-expansion-panel-header>
        <div class="project-wifi-settings">
          <div class="section-title title">{{'common:dictionary.wifi' | i18nextCap}}</div>
          <!-- SSID name -->
          <div class="ssid-name-input">
            <mat-form-field appearance="legacy">
              <mat-label>{{'project:enter-ssid' |i18nextCap}}</mat-label>
              <input [formControl]="wifiSsid" matInput />
              <mat-error *ngIf="wifiSsid.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
            </mat-form-field>
          </div>
          <!-- SSID password -->
          <div class="ssid-password-input">
            <mat-form-field appearance="legacy">
              <mat-label>{{'project:enter-ssid-password' | i18nextCap}}</mat-label>
              <input [formControl]="wifiPassword" matInput />
              <mat-error *ngIf="wifiPassword.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="buttons">
          <button mat-button type="button" class="button cancel" (click)="prevStep()">{{ 'common:buttons.previous-step' | i18next}}</button>
          <button [disabled]="!settingsSectionCompleted" mat-button type="button" class="button save ml-2" (click)="nextStep()">
            {{ 'common:buttons.next-step' | i18next}}
          </button>
        </div>
      </mat-expansion-panel>

      <!-- LED SETTINGS -->
      <mat-expansion-panel formGroupName="defaultSceneSettings" [expanded]="step === 2" (opened)="setStep(2)">
        <mat-expansion-panel-header>
          <mat-panel-title class="title"> {{'project:scene-settings' | i18nextCap}} </mat-panel-title>
          <span class="step-count">3 / 3</span>
        </mat-expansion-panel-header>
        <!-- Multi-color -->
        <section class="card-entry">
          <section fxLayout="column" fxLayoutGap="0.5rem">
            <mat-form-field appearance="legacy" fxFlex.gt-sm="50">
              <mat-label>{{'common:dictionary.dim-value' | i18nextCap}}</mat-label>
              <input
                type="number"
                matInput
                formControlName="dimValue"
                [min]="defaultSceneSettings.dimValueMin"
                [max]="defaultSceneSettings.dimValueMax"
              />
              <mat-error *ngIf="dimValue.invalid"
                >{{'reseller:form.errors.required' | i18nextCap}} [ {{ defaultSceneSettings.dimValueMin }} - {{ defaultSceneSettings.dimValueMax }}
                ]</mat-error
              >
            </mat-form-field>

            <ng-container>
              <mat-form-field appearance="legacy">
                <mat-label>{{'common:domain.cct' | i18nextCap}}</mat-label>
                <input formControlName="cct" type="number" matInput [min]="defaultSceneSettings.cctMin" [max]="defaultSceneSettings.cctMax" />
                <mat-error *ngIf="cct.invalid"
                  >{{'reseller:form.errors.required' | i18nextCap}} [ {{ defaultSceneSettings.cctMin }} - {{ defaultSceneSettings.cctMax }}
                  ]</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="legacy">
                <mat-label>{{'common:domain.duv' | i18nextCap}}</mat-label>
                <input formControlName="duv" type="number" matInput [min]="defaultSceneSettings.duvMin" [max]="defaultSceneSettings.duvMax" />
                <mat-error *ngIf="duv.invalid"
                  >{{'reseller:form.errors.required' | i18nextCap}} [ {{ defaultSceneSettings.duvMin }} - {{ defaultSceneSettings.duvMax }}
                  ]</mat-error
                >
              </mat-form-field>
            </ng-container>
          </section>
        </section>
        <div class="buttons">
          <button mat-button type="button" class="button cancel" (click)="prevStep()">{{ 'common:buttons.previous-step' | i18next}}</button>
          <button [disabled]="!sceneSettingsSectionCompleted" mat-button type="button" class="button save ml-2" (click)="nextStep()">
            {{ 'common:buttons.next-step' | i18next}}
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <!-- COLUMN.01 - SUMMARY-->
  <section fxLayout="column" fxFlex="40" class="summary">
    <div class="summary-title">{{'common:dictionary.summary'| i18nextCap}}</div>
    <section class="section">
      <div class="pb-1">
        <label fxFlex="25">{{'common:dictionary.client' | i18nextCap}}</label>
        <div fxFlex="auto">{{ (selectedClient$ |async)?.name}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:dictionary.location' | i18nextCap}}</label>
        <div fxFlex="auto">{{ (selectedLocation$ |async)?.name}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:dictionary.name' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.name}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'component:form.timezone.title' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.timezone}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:dictionary.key' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.key}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:dictionary.api-key' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.apiKey}}</div>
      </div>
    </section>
    <section [hidden]="!settingsSectionCompleted" class="section">
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{'common:dictionary.ssid' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.settings.wifiSsid}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:dictionary.password' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.settings.wifiPassword}}</div>
      </div>
    </section>
    <!-- scene settings summary -->
    <section [hidden]="!sceneSettingsSectionCompleted" class="section">
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{'common:dictionary.dim-value' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.defaultSceneSettings.dimValue}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:domain.cct' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.defaultSceneSettings.cct}}</div>
      </div>
      <div class="pt-1 pb-1">
        <label fxFlex="25">{{ 'common:domain.duv' | i18nextCap}}</label>
        <div fxFlex="auto">{{ projectForm.value?.defaultSceneSettings.duv}}</div>
      </div>
    </section>
    <div class="buttons mt-2">
      <button mat-button type="button" class="button cancel" (click)="cancel()">cancel</button>
      <button [disabled]="!projectSettingsCompleted" mat-button type="submit" class="button save ml-2">
        {{'project:create-project'| i18nextCap}}
      </button>
    </div>
  </section>
</form>
