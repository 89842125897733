import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { PERMISSIONS, PortalAPI } from '@summa/models';
import { isNotNullOrUndefined, onlyUnique } from '@summa/shared/util/typescript';
import { AuthQuery, ResellerQuery, ResellerService, UserQuery, UserService } from '../../../data-access';
import { filterResellerProjectsBasedOnPermissions, permittedProjects } from '../../../permission-access/helpers';

@Injectable()
export class ResellerDashboardSandbox {
  params$ = this.routerQuery.selectParams();
  reseller$ = this.resellerQuery.selectActive<PortalAPI.Reseller>();
  userCount$ = this.userQuery.selectCount();
  currentUser$ = this.authQuery.selectActive();

  projectCount$ = this.reseller$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.currentUser$),
    map(([reseller, user]) => filterResellerProjectsBasedOnPermissions(reseller, user.permissions).length),
  );
  clientCount$ = this.reseller$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.currentUser$),
    map(([reseller, user]) => {
      // show all clients when user has wildcard on projects
      const permissions = permittedProjects(user.permissions, reseller.id);
      if (permissions.includes(PERMISSIONS.wildcardQualifier)) {
        return reseller.clients.length;
      }

      const filteredClients = filterResellerProjectsBasedOnPermissions(reseller, user.permissions).map((project) => project.location.client);
      return filteredClients.map((client) => client.id).filter(onlyUnique).length;
    }),
  );

  constructor(
    private resellerService: ResellerService,
    private resellerQuery: ResellerQuery,
    private routerQuery: RouterQuery,
    private userService: UserService,
    private userQuery: UserQuery,
    private authQuery: AuthQuery,
  ) {}

  // Reduce helper
  sum = (sum: number, current: number) => sum + current;

  getReseller(id: string): void {
    this.resellerService.getReseller(id);
  }

  getUsers(resellerId: string): void {
    this.userService.getUsersByResellerId(resellerId);
  }

  reset(): void {
    this.userService.reset();
  }
}
