import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DOMAIN, PERMISSIONS } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { combineLatest, filter, pairwise, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { ProjectDriverControlSettingsSandbox } from './project-driver-control-settings-tab.sandbox';

@Component({
  selector: 'summa-project-driver-control-settings-tab',
  templateUrl: './project-driver-control-settings-tab.component.html',
  styleUrls: ['./project-driver-control-settings-tab.component.scss'],
  providers: [ProjectDriverControlSettingsSandbox],
})
export class ProjectDriverControlSettingsTabComponent implements OnInit, OnDestroy {
  scenario = 'scenario';
  groups = 'groups';

  destroy$ = new Subject();
  submit$ = new Subject<void>();
  form: FormGroup;
  actionTypes = DOMAIN.deviceControlAction;
  actionChoices = [this.scenario, this.groups];
  panelOpenState = false;

  readonly updatePermission: PERMISSIONS.Permission = {
    application: { name: PERMISSIONS.applicationReseller, qualifiers: [PERMISSIONS.wildcardQualifier] },
    domain: { name: PERMISSIONS.domainProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
    subdomain: null, // { name: PERMISSIONS.subDomainDrivers, qualifiers: [PERMISSIONS.subQualifierAddDriver] },
    action: PERMISSIONS.actionUpsert,
  };

  get channels(): FormArray {
    return this.form.get('channels') as FormArray;
  }

  getActions = (index: number): FormArray => {
    return this.channels.controls[index].get('actions') as FormArray;
  };

  getGroups = (channelIndex: number, actionIndex: number): FormArray => {
    return this.getActions(channelIndex).controls[actionIndex].get(this.groups) as FormArray;
  };

  actionAlreadyTaken = (channelIndex: number, type: string): boolean => {
    const actions = this.getActions(channelIndex).value.map((a: DOMAIN.DeviceControlActions) => a.action);
    return actions.includes(type);
  };

  constructor(public sandbox: ProjectDriverControlSettingsSandbox, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.handleData();
    this.handleProject();
    this.handleSubmit();
  }

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  addAction(actionIndex: number): void {
    const action = this.getActions(actionIndex);
    action.push(
      this.fb.group({
        action: ['', [Validators.required]],
        choice: ['', [Validators.required]],
        scenarioId: '',
        groups: this.fb.array([]),
      }),
    );

    // clear other option when switching choice
    if (action.controls.length === 1) {
      action.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
        for (let index = 0; index < prev.length; index += 1) {
          if (prev[index]?.choice !== next[index]?.choice) {
            if (next[index]?.choice === this.groups) {
              action.controls[index].get('scenarioId').reset();
            } else if (next[index]?.choice === this.scenario) {
              (action.controls[index].get(this.groups) as FormArray).clear();
            }
          }
        }
      });
    }
  }

  removeAction(channelIndex: number, actionIndex: number): void {
    this.getActions(channelIndex).removeAt(actionIndex);
  }

  removeActionGroup(channelIndex: number, actionIndex: number, groupIndex: number): void {
    this.getGroups(channelIndex, actionIndex).removeAt(groupIndex);
  }

  addActionGroup(channelIndex: number, actionIndex: number): void {
    const actionGroup = this.getGroups(channelIndex, actionIndex);

    actionGroup.push(
      this.fb.group({
        group: ['', [Validators.required]],
        sceneId: ['', [Validators.required]],
      }),
    );

    if (actionGroup.controls.length === 1) {
      actionGroup.valueChanges.pipe(pairwise()).subscribe(([prev, next]) => {
        for (let index = 0; index < prev.length; index += 1) {
          if (prev[index].group?.id !== next[index].group?.id) {
            actionGroup.controls[index].get('sceneId').reset();
          }
        }
      });
    }
  }

  private handleData(): void {
    combineLatest([this.sandbox.driver$, this.sandbox.groups$])
      .pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$))
      .subscribe(([driver, groups]) => {
        this.form = this.fb.group({
          channels: this.fb.array(
            driver.devices
              .filter((d) => d.category === DOMAIN.deviceControl)
              .map((device) =>
                this.fb.group({
                  address: [device.address, [Validators.required]],
                  actions:
                    (device.settings as DOMAIN.DeviceControlSettings).actions.length > 0
                      ? this.fb.array([
                          ...(device.settings as DOMAIN.DeviceControlSettings).actions.map((settings) =>
                            this.fb.group({
                              action: settings.action,
                              choice: [settings.scenarioId || settings.groups?.length === 0 ? this.scenario : this.groups, [Validators.required]],
                              scenarioId: [settings.scenarioId],
                              groups:
                                settings.groups?.length > 0
                                  ? this.fb.array(
                                      settings.groups.map((group) =>
                                        this.fb.group({
                                          group: groups.find((c) => c.id === group.groupId),
                                          sceneId: group.sceneId,
                                        }),
                                      ),
                                    )
                                  : this.fb.array([]),
                            }),
                          ),
                        ])
                      : this.fb.array([]),
                }),
              ),
          ),
        });
      });
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project) => {
      this.sandbox.getScenarios(project.key);
      this.sandbox.getGroups(project.key);
    });
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.form.valid),
        withLatestFrom(this.sandbox.driver$),
        takeUntil(this.destroy$),
      )
      .subscribe(([, driver]) => {
        const channels = this.form.value;

        const result = {
          ...driver,
          devices: driver.devices.map((device) => {
            const settings = channels.channels.find((channel) => channel.address === device.address);
            if (!settings) {
              return device;
            }

            return {
              ...device,
              settings: {
                actions: settings.actions.map((action) => ({
                  action: action.action,
                  ...(action.choice === this.scenario && { scenarioId: action.scenarioId }),
                  ...(action.choice === this.groups && {
                    groups: action.groups.map((ag) => ({ sceneId: ag.sceneId, groupId: ag.group.id })),
                  }),
                })),
              },
            };
          }),
        };

        if (JSON.stringify(driver) !== JSON.stringify(result)) {
          this.sandbox.updateDriver(result);
        }
      });
  }
}
