import { gql } from 'apollo-angular';
import { DriverFields } from './driver.gql-fragment';

export const upsertDriverQuery = gql`
  mutation upsertDriver($driver: DriverInput!, $create: Boolean) {
    upsertDriver(driver: $driver, create: $create) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;

export const upsertDriversQuery = gql`
  mutation upsertDrivers($drivers: [DriverInput!]!) {
    upsertDrivers(drivers: $drivers) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;

export const addToProjectQuery = gql`
  mutation addToProject($key: String!, $projectKey: String!) {
    addToProject(key: $key, projectKey: $projectKey) {
      ...DriverFields
    }
  }
  ${DriverFields}
`;

export const removeFromProjectQuery = gql`
  mutation removeFromProject($key: String!, $projectKey: String!) {
    removeFromProject(key: $key, projectKey: $projectKey) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;

export const replaceDriverQuery = gql`
  mutation replaceDriver($oldDriverKey: String!, $newDriverKey: String!, $replaceState: String!) {
    replaceDriver(oldDriverKey: $oldDriverKey, newDriverKey: $newDriverKey, replaceState: $replaceState) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;

export const removeDriverQuery = gql`
  mutation removeDriver($key: String!) {
    removeDriver(key: $key) {
      acknowledged
      deletedCount
    }
  }
`;

export const SyncDriversByProjectKeyQuery = gql`
  mutation syncDriversByProjectKey($projectKey: String!) {
    syncDriversByProjectKey(projectKey: $projectKey) {
      id
      key
      type
      projectKey
      batch
      fabCode
      dateCode
      address
      settings
      state
      devices {
        id
        key
        address
        type
        category
        settings
        locationKey
      }
    }
  }
`;
