import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CONSTANTS } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { Observable } from 'rxjs';
import { ControlPanelService } from '../../../../data-access';

@Injectable()
export class ControlPanelScenarioSandbox {
  routeParams$: Observable<Params>;

  constructor(private controlPanelService: ControlPanelService, private route: ActivatedRoute) {
    this.routeParams$ = this.route.params;
  }

  executeScenarioCommand(controlPanelId: string, controlPanelScenarioId: string, state: CONSTANTS.StateType, projectKey?: string): void {
    const command: DTO.ControlPanelCommandInput = {
      controlPanelId,
      controlPanelScenarioId,
      state,
      ...(projectKey && { projectKey }),
    };
    this.controlPanelService.executeControlPanelCommand(command);
  }
}
