import { DOMAIN } from '@summa/models';

export function isDeviceTurnedOn(device: DOMAIN.Device, logs: DOMAIN.FixtureLog[]): boolean {
  if (logs?.length === 0) return false;
  // function:
  const intensityCheck = (channel: DOMAIN.ChannelLog): boolean => channel?.intensity > 0;
  const log = logs.find((l) => l.topic === device.driver.address);

  if (!log) return false;

  // specific:  N-Channel logic ( only check channel intensity )
  if (log.type === DOMAIN.deviceNChannel) {
    const channel = log.channels.find((ch) => ch.id === device.address);
    return channel && intensityCheck(channel);
  }

  // default: fusionCob logic ( only checks on dimlevel)
  return log.dimLevel > 0;
}
