import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { I18NextCapPipe } from 'angular-i18next';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectScenarioAddPage } from '../project-scenario-add/project-scenario-add.page';
import { ProjectScenarioPreviewPage } from '../project-scenario-preview/project-scenario-preview.page';

import { ProjectScenarioOverviewSandbox } from './project-scenario-overview.sandbox';

@Component({
  selector: 'summa-project-scenario-overview',
  templateUrl: './project-scenario-overview.page.html',
  styleUrls: ['./project-scenario-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectScenarioOverviewSandbox],
})
export class ProjectScenarioOverviewPage implements OnInit {
  destroy$ = new Subject();
  remove$ = new Subject<DOMAIN.Scenario>();
  upsertPanel$ = new Subject<DOMAIN.Scenario>();
  detailsPanel$ = new Subject<DOMAIN.Scenario>();
  scenarios = new MatTableDataSource<DOMAIN.Scenario>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;
  columnsToDisplay = ['name', 'description', 'hidden', 'icon', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public sandbox: ProjectScenarioOverviewSandbox,
    private sidePanelService: SidePanelService,
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
  ) {}

  ngOnInit(): void {
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();

    this.sandbox.scenarios$.pipe(takeUntil(this.destroy$)).subscribe((scenarios) => {
      this.scenarios.data = scenarios as DOMAIN.Scenario[];
      this.scenarios.sort = this.sort;
      this.scenarios.paginator = this.paginator;
    });

    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getScenarios(projectKey);
    });
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.scenarios.filter = filterValue.trim().toLowerCase();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((scenario) => {
      this.sidePanelService.open(ProjectScenarioAddPage, scenario);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((scenario: DOMAIN.Scenario) => {
      this.sandbox.getScenario(scenario.id);
      this.sidePanelService.open(ProjectScenarioPreviewPage);
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((scenario: DOMAIN.Scenario) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: scenario.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeScenario(scenario.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
