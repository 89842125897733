export const dayMonday = 'monday';
export const dayTuesday = 'tuesday';
export const dayWednesday = 'wednesday';
export const dayThursday = 'thursday';
export const dayFriday = 'friday';
export const daySaturday = 'saturday';
export const daySunday = 'sunday';

export const dayAllDays = 'allDays';
export const dayWeekdays = 'weekdays';
export const dayWeekends = 'weekends';
export const dayCustom = 'custom';

export const weekdays = [dayMonday, dayTuesday, dayWednesday, dayThursday, dayFriday] as const;
export const weekends = [daySunday, daySaturday] as const;
export const days = [daySunday, dayMonday, dayTuesday, dayWednesday, dayThursday, dayFriday, daySaturday] as const;
export type Days = typeof days[number];

export const customDays = [dayAllDays, dayWeekdays, dayWeekends, dayCustom] as const;
export type CustomDays = typeof customDays[number];

// TODO: Move functions to typescript-utils
export const getDays = (customDay: CustomDays, selectedDays?: Days[]): Days[] => {
  switch (customDay) {
    case dayAllDays:
      return [...days];
    case dayWeekdays:
      return [...weekdays];
    case dayWeekends:
      return [...weekends];
    case dayCustom:
      return [...selectedDays];
    default:
      return [];
  }
};

export const getCustomDay = (daysArray: Days[]): CustomDays => {
  switch (true) {
    case isEqual(daysArray, days):
      return dayAllDays;
    case isEqual(daysArray, weekdays):
      return dayWeekdays;
    case isEqual(daysArray, weekends):
      return dayWeekends;
    default:
      return dayCustom;
  }
};

// TODO: Remove functions when moved to typescript-utils
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isEqual(value1: any, value2: any): boolean {
  if (isObject(value1) && isObject(value2)) return JSON.stringify(value1) === JSON.stringify(value2);
  return value1 === value2;
}
