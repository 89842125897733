import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BreadcrumbModule } from 'xng-breadcrumb';

import { SharedAuthUtilModule } from '@summa/shared/dashboard/util';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedUiFloorplanModule } from '@summa/shared/ui/floorplan';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';
import { SharedUiUploadModule } from '@summa/shared/ui/upload';
import { SharedDashboardUiModule } from '@summa/shared/dashboard/ui';

import { ResellerClientAddPage } from './clients/client-add/reseller-client-add.page';
import { ResellerClientDetailsPage } from './clients/client-details/reseller-client-details.page';
import { ResellerClientOverviewPage } from './clients/client-overview/reseller-client-overview.page';
import { ResellerDashboardPage } from './dashboard/reseller-dashboard.page';
import { ResellerUserWidgetComponent } from './dashboard/user-widget/reseller-user-widget.component';
import { ResellerLocationAddPage } from './locations/location-add/reseller-location-add.page';
import { ResellerOverviewPage } from './overview/reseller-overview.page';
import { ResellerPresetAddPage } from './presets/preset-add/reseller-preset-add.page';
import { ResellerPresetOverviewPage } from './presets/preset-overview/reseller-preset-overview.page';
import { ResellerAddPage } from './reseller-add/reseller-add.page';
import { ResellerShellPage } from './reseller-shell.page';
import { Routes } from './reseller.routes';
import { SummaPortalNotificationsSharedModule } from '../notifications';
import { SummaComponentsModule } from '../components/components.module';
import { SummaPortalPermissionModule } from '../../permission-access/summa-portal-permission.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    SharedUiHeadersModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatBadgeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    I18NextModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    BreadcrumbModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSortModule,
    ReactiveFormsModule,
    SharedUiDialogsModule,
    SharedUiUploadModule,
    SharedUiFloorplanModule,
    SharedAuthUtilModule,
    RouterModule.forChild(Routes),
    SharedDashboardUiModule,
    SummaPortalNotificationsSharedModule,
    SummaComponentsModule,
    SummaPortalPermissionModule,
  ],
  declarations: [
    ResellerShellPage,
    ResellerOverviewPage,
    ResellerDashboardPage,
    ResellerClientOverviewPage,
    ResellerClientDetailsPage,
    ResellerLocationAddPage,
    ResellerClientAddPage,
    ResellerAddPage,
    ResellerPresetOverviewPage,
    ResellerPresetAddPage,
    ResellerUserWidgetComponent,
  ],
  exports: [ResellerLocationAddPage, ResellerClientAddPage, ResellerAddPage],
})
export class SummaPortalResellerModule {}
