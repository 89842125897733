import { gql } from 'apollo-angular';

export const executeMqttCommandQuery = gql`
  mutation executeMqttCommand($command: MqttCommandInput!) {
    executeMqttCommand(command: $command)
  }
`;

export const executeUpdateLocalCommandQuery = gql`
  mutation executeUpdateLocalCommand($projectKey: String!) {
    executeUpdateLocalCommand(projectKey: $projectKey)
  }
`;

export const executeCommandQuery = gql`
  mutation executeCommand($command: CommandInput!) {
    executeCommand(command: $command)
  }
`;

export const executeSyncCommandQuery = gql`
  mutation executeSyncCommand($command: SyncCommandInput!) {
    executeSyncCommand(command: $command)
  }
`;

export const executeCurrentChangeCommandQuery = gql`
  mutation executeCurrentChangeCommand($command: CurrentChangeInput!) {
    executeCurrentChangeCommand(command: $command)
  }
`;

// export const executeUpdateFirmwareCommandQuery = gql`
//   mutation executeUpdateFirmwareCommand($command: UpdateFirmwareCommandInput!) {
//     executeUpdateFirmwareCommand(command: $command)
//   }
// `;

export const executeSetBaselineCommandQuery = gql`
  mutation executeSetBaselineCommand($command: SetBaselineCommand!) {
    executeSetBaselineCommand(command: $command)
  }
`;

export const executeResetSensorsCommandQuery = gql`
  mutation executeResetSensorsCommand($driverKey: String!) {
    executeResetSensorsCommand(driverKey: $driverKey)
  }
`;

export const executeDeleteCurrentValuesCommandQuery = gql`
  mutation executeDeleteCurrentValuesCommand($driverKey: String!) {
    executeDeleteCurrentValuesCommand(driverKey: $driverKey)
  }
`;

export const executeDriverSettingsCommandQueryQuery = gql`
  mutation executeDriverSettingsCommand($command: DriverSettingsCommandInput!) {
    executeDriverSettingsCommand(command: $command)
  }
`;
