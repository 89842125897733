import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

export interface AuthState extends EntityState<DOMAIN.User, string>, ActiveState {
  isSuccessful: boolean;
}

const initialState = {
  loading: false,
  error: null,
  isSuccessful: false,
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'auth', idKey: 'id', resettable: true })
export class AuthStore extends EntityStore<AuthState> {
  constructor() {
    super(initialState);
  }

  resetState = () => this.update({ ...initialState });
}
