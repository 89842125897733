/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN, PERMISSIONS } from '@summa/models';

import {
  addUserPermissionsQuery,
  createUserQuery,
  removeUserQuery,
  updateUserQuery,
  upsertUserQuery,
  GetUserQuery,
  GetUsersByPermission,
  GetUsersQuery,
  GetUsersByPermissions,
  updateUserAgreementsQuery,
} from './graphql';

import { UserStore } from './users.store';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  successfullUpdatedMsg = 'message:successful-updated';
  constructor(private apollo: Apollo, private store: UserStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.store.reset();
    this.resetActive();
  }

  resetActive(): void {
    this.store.resetUserUIState();
    this.store.setActive(null);
  }

  setActive(id: ID | null): void {
    this.store.setActive(id);
  }

  getUsers(): void {
    this.apollo
      .subscribe({ query: GetUsersQuery })
      .pipe(map((d: any) => d.data.getUsers as DOMAIN.User[]))
      .subscribe((users) => this.store.set(users));
  }

  getUsersByResellerId(resellerId: string): void {
    this.apollo
      .subscribe({ query: GetUsersQuery, variables: { resellerId } })
      .pipe(map((d: any) => d.data.getUsers as DOMAIN.User[]))
      .subscribe((users) => this.store.set(users));
  }

  getUsersByPermission(permission: PERMISSIONS.Permission, skip = 0, take = 50): void {
    this.apollo
      .subscribe({ query: GetUsersByPermission, variables: { skip, take, permission } })
      .pipe(map((d: any) => d.data.getUsersByPermission as DOMAIN.User[]))
      .subscribe((users) => this.store.set(users));
  }

  getUsersByPermissions(permissions: PERMISSIONS.Permission[], skip = 0, take = 50): void {
    this.apollo
      .subscribe({ query: GetUsersByPermissions, variables: { skip, take, permissions } })
      .pipe(map((d: any) => d.data.getUsersByPermissions as DOMAIN.User[]))
      .subscribe((users) => this.store.set(users));
  }

  getUser(id: string, resellerId?: string): void {
    this.apollo
      .subscribe({ query: GetUserQuery, variables: { id, resellerId } })
      .pipe(map((d: any) => d.data.getUser as DOMAIN.User))
      .subscribe((user) => {
        this.store.upsert(user.id, user);
        this.store.setActive(user.id);
      });
  }

  resetUserUIState(): void {
    this.store.resetUserUIState();
  }

  upsertUser(userInput: DOMAIN.UserInput, resellerId?: string): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertUserQuery, variables: { userInput, resellerId } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `user ${userInput.username}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertUser: DOMAIN.User };
      this.store.upsert(data.upsertUser.id, data.upsertUser);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfullUpdatedMsg));
    });
  }

  createUser(userInput: DOMAIN.UserInput): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: createUserQuery, variables: { userInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `user ${userInput.username}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { createUser: DOMAIN.User };
      this.store.upsert(data.createUser.id, data.createUser);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfullUpdatedMsg));
    });
  }

  updateUser(userInput: DOMAIN.UserInput, resellerId?: string): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: updateUserQuery, variables: { userInput, resellerId } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `user ${userInput.username}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { updateUser: DOMAIN.User };
      this.store.upsert(data.updateUser.id, data.updateUser);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
    });
  }

  addUserPermissions(userInput: DOMAIN.UserInput): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: addUserPermissionsQuery, variables: { userInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `user ${userInput.username}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { addUserPermissions: DOMAIN.User };
      this.store.upsert(data.addUserPermissions.id, data.addUserPermissions);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform(this.successfullUpdatedMsg));
    });
  }

  updateUserAgreements(agreements: DOMAIN.UserAgreementInput[]): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo
      .subscribe({ query: updateUserAgreementsQuery, variables: { agreements } })
      .pipe(map((d: any) => d.data.updateUserAgreements as DOMAIN.User))
      .subscribe((user) => {
        this.store.upsert(user.id, user);
        this.store.setActive(user.id);
        this.store.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform(this.successfullUpdatedMsg));
      });
  }

  removeUser(id: string, resellerId?: string): void {
    this.store.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeUserQuery, variables: { id, resellerId } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.store.setError(results.errors);
          this.store.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        // const data = results.data as { removeUser: PortalAPI.RemoveResponse };
        this.store.remove(id);
        this.store.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  removeUserFromStore(id: string) {
    this.store.remove(id);
  }

  resetUpsertState() {
    this.store.updateUpsert({ isSuccessful: false, isLoading: false, errors: null });
  }

  resetRemoveState() {
    this.store.updateRemove({ isSuccessful: false, isLoading: false, errors: null });
  }
}
