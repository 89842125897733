import Dexie, { Table } from 'dexie';

export interface Floorplan {
  file_name: string;
  data: string;
}
export class AppDB extends Dexie {
  floorplan!: Table<Floorplan, string>;
  constructor() {
    super('ngdexieliveQuery');
    this.version(1).stores({
      floorplan: 'file_name',
    });
  }
}
export const db = new AppDB();
