import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subject, takeUntil } from 'rxjs';

import { MatTableDataSource } from '@angular/material/table';
import { DOMAIN } from '@summa/models';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { I18NextCapPipe } from 'angular-i18next';
import { ProjectInstallerOverviewSandbox } from './project-installer-overview.sandbox';
import { ProjectInstallerUpsertPage } from '../project-installer-upsert/project-installer-upsert.page';
import { ProjectInstallerDetailsPage } from '../project-installer-details/project-installer-details.page';

@Component({
  selector: 'summa-project-installer-overview',
  templateUrl: './project-installer-overview.page.html',
  styleUrls: ['./project-installer-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectInstallerOverviewSandbox],
})
export class ProjectInstallerOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  remove$ = new Subject<DOMAIN.Installer>();
  upsertPanel$ = new Subject<DOMAIN.Installer>();
  detailsPanel$ = new Subject<DOMAIN.Installer>();

  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  installers = new MatTableDataSource<DOMAIN.Installer>();
  columnsToDisplay = ['name', 'enabled', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    private sidePanelService: SidePanelService,
    public sandbox: ProjectInstallerOverviewSandbox,
  ) {}

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getInstallers(projectKey);
    });

    this.handleInstallers();
    this.handleUpsertPanel();
    this.handleDetailsPanel();
    this.handleRemove();
  }

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((installer) => {
      this.sidePanelService.open(ProjectInstallerUpsertPage, installer);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((installer: DOMAIN.Installer) => {
      this.sidePanelService.open(ProjectInstallerDetailsPage, installer);
    });
  }

  private handleInstallers(): void {
    this.sandbox.installers$.pipe(takeUntil(this.destroy$)).subscribe((installers: DOMAIN.Installer[]) => {
      this.installers.data = installers;
      this.installers.sort = this.sort;
      this.installers.paginator = this.paginator;
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((installer: DOMAIN.Installer) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: installer.name }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeInstaller(installer.id);
      });
    });

    this.sandbox.removeState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.confirmDialog.close());
  }
}
