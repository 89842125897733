<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
  <!-- COLUMN.01 -->

  <div fxLayout="column" fxLayoutGap="16px">
    <!-- Information -->

    <div class="reseller-card sm p-2">
      <div class="header">
        {{'common:dictionary.information' | i18nextCap}}
        <ng-container *ngIf="sandbox.reseller$ |async as reseller">
          <div
            class="action clickable"
            (click)="upsertResellerPanel$.next(reseller); $event.stopPropagation()"
            [title]="'reseller:resellers.edit.title' | i18nextCap"
          >
            <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="sandbox.reseller$ |async as reseller">
        <div class="info-block">
          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.name' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.name }}</div>
          </div>
        </div>

        <div class="info-block mt-2">
          <p class="mb-1 mt-0 p-0 contact-title">{{ 'common:dictionary.contact-information' | i18nextCap }}</p>

          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.name' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.contact.name }}</div>
          </div>

          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.email' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.contact.email }}</div>
          </div>

          <div class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.phone' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.contact.phone }}</div>
          </div>
        </div>

        <div
          *ngIf="reseller.paymentInfo && (propertyExists(reseller.paymentInfo.accountName) || propertyExists(reseller.paymentInfo.iban) || propertyExists(reseller.paymentInfo.bic))"
          class="info-block mt-2"
        >
          <p class="mb-1 mt-0 p-0 payment-title">{{ 'common:dictionary.payment-information' | i18nextCap }}</p>

          <div *ngIf="propertyExists(reseller.paymentInfo.accountName)" class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.name' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.paymentInfo.accountName }}</div>
          </div>

          <div *ngIf="propertyExists(reseller.paymentInfo.iban)" class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.iban' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.paymentInfo.iban }}</div>
          </div>

          <div *ngIf="propertyExists(reseller.paymentInfo.bic)" class="info-entry" fxLayout="row wrap">
            <div class="title" fxFlex="25">{{ 'common:dictionary.bic' | i18nextCap }}</div>

            <div class="content" fxFlex="70">{{ reseller.paymentInfo.bic }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Notifications -->
    <div >
      <summa-notification-widget></summa-notification-widget>
    </div>
  </div>

  <!-- COLUMN.02 -->

  <div fxLayout="column" fxLayoutGap="16px">
    <!-- Clients -->

    <div class="reseller-card sm p-2">
      <div class="header">{{'common:dictionary.summary' | i18nextCap}}</div>

      <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="row wrap" fxLayoutGap="16px">
          <!-- Clients -->

          <section class="counter-button">
            <div class="content">
              <div class="counter warning">{{(sandbox.clientCount$ | async) || '-'}}</div>

              <div class="title">{{ 'reseller:common.clients' | i18nextCap }}</div>
            </div>
          </section>

          <!-- Projects -->

          <section class="counter-button">
            <div class="content">
              <div class="counter warning">{{(sandbox.projectCount$ | async) || '-'}}</div>

              <div class="title">{{ 'reseller:common.projects' | i18nextCap }}</div>
            </div>
          </section>
        </div>

        <!-- Action buttons -->

        <div fxLayout="row wrap" fxLayoutGap="16px">
          <div class="sync-button" title="Clients overview" [routerLink]="['clients']">
            <div class="content">
              <mat-icon svgIcon="view-list"></mat-icon>

              <div class="title">{{ 'reseller:common.clients' | i18nextCap }} overview</div>
            </div>
          </div>

          <div class="sync-button" title="Projects overview" [routerLink]="['projects']">
            <div class="content">
              <mat-icon svgIcon="view-list"></mat-icon>

              <div class="title">{{ 'reseller:common.projects' | i18nextCap }} overview</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- COLUMN.03 -->
    <div fxLayout="column" fxLayoutGap="16px">
      <!-- USERS -->
      <summa-reseller-user-widget
        [summaPermissions]="userManagementPermission"
        [userPermissions]="(this.sandbox.currentUser$ | async)?.permissions"
      ></summa-reseller-user-widget>
    </div>
  </div>
</div>
