import { gql } from 'apollo-angular';
import { ControlFields } from './control.gql-fragment';

export const GetControlsQuery = gql`
  query getControls($skip: Int, $take: Int, $projectKey: String) {
    getControls(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      projectKey
      name
      description
      type
      address
      # channels
    }
  }
`;

export const GetControlQuery = gql`
  query getControl($id: String!) {
    getControl(id: $id) {
      ...ControlFields
    }
  }
  ${ControlFields}
`;
