import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { CONSTANTS } from '@summa/models';
import { DOMAIN } from '@summa/portal/models/domain';
import { Counter } from '@summa/shared/dashboard/ui';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { I18NextCapPipe } from 'angular-i18next';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { AuthQuery, NotificationService, ResellerQuery, TodaysNotificationQuery } from '../../../data-access';
import { filterResellerProjectsBasedOnPermissions } from '../../../permission-access/helpers';

const getCountFor = (notifications: DOMAIN.Notification[], type: CONSTANTS.NotificationType): number =>
  notifications.filter((notification) => notification.type === type).length;

@Injectable()
export class NotificationWidgetSandbox {
  params$ = this.routerQuery.selectParams();
  notifications$ = this.todaysNotificationQuery.selectAll();
  notificationCounters$ = this.notifications$.pipe(
    map((notifications): Counter[] => {
      return [
        {
          title: this.i18next.transform(CONSTANTS.notificationWarning),
          class: 'warning',
          count: getCountFor(notifications, CONSTANTS.notificationWarning),
        },
        {
          title: this.i18next.transform(CONSTANTS.notificationError),
          class: 'danger',
          count: getCountFor(notifications, CONSTANTS.notificationError),
        },
      ];
    }),
  );
  currentUser$ = this.authQuery.selectActive();
  currentReseller$ = this.resellerQuery.selectActive();

  userProjects$: Observable<DTO.Project[]> = this.currentReseller$.pipe(
    filter((reseller) => isNotNullOrUndefined(reseller)),
    withLatestFrom(this.authQuery.selectActive()),
    map(([reseller, user]) => filterResellerProjectsBasedOnPermissions(reseller, user.permissions)),
  );

  constructor(
    private todaysNotificationQuery: TodaysNotificationQuery,
    private routerQuery: RouterQuery,
    private resellerQuery: ResellerQuery,
    private authQuery: AuthQuery,
    private notificationService: NotificationService,
    private i18next: I18NextCapPipe,
  ) {}

  getNotifications(projects: string[]): void {
    this.notificationService.getTodaysNotifications(projects);
  }

  reset(): void {
    this.notificationService.resetLive();
    this.notificationService.reset();
  }
}
