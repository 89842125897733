import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { DOMAIN, PERMISSIONS } from '@summa/models';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { combineLatest, map, Subject } from 'rxjs';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { I18NextCapPipe } from 'angular-i18next';
import { modelToPermissionString } from '@summa/shared/auth/util';
import { flatten, isNotNullUndefinedOrEmpty, onlyUnique } from '@summa/shared/util/typescript';
import { UserPreviewPage } from '../preview/user-preview.page';
import { UserUpsertPage } from '../upsert/user-upsert.page';
import { UserOverviewSandbox } from './user-overview.sandbox';
import { getUserRoles } from '../../../permission-access/helpers';

@Component({
  selector: 'summa-user-overview',
  templateUrl: './user-overview.page.html',
  styleUrls: ['./user-overview.page.scss'],
  providers: [UserOverviewSandbox],
})
export class UserOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertPanel$ = new Subject<DOMAIN.User>();
  detailsPanel$ = new Subject<DOMAIN.User>();

  users = new MatTableDataSource<DOMAIN.User>();
  columnsToDisplay = ['name', 'id', 'actions'];

  remove$ = new Subject<DOMAIN.User>();
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    public sandbox: UserOverviewSandbox,
    private sidePanelService: SidePanelService,
  ) {}

  ngOnInit() {
    this.handleUsers();
    this.handleDetailsPanel();
    this.handleUpsertPanel();
    this.handleApplication();
    this.handleRemove();
  }

  ngOnDestroy() {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.users.filter = filterValue.trim().toLowerCase();
  }

  private handleUsers(): void {
    combineLatest([this.sandbox.users$, this.sandbox.loading$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([users, loading]) => {
        if (loading) {
          return;
        }

        this.users.data = users;
        this.users.sort = this.sort;
        this.users.paginator = this.paginator;
      });
  }

  private handleUpsertPanel(): void {
    this.upsertPanel$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.sidePanelService.open(UserUpsertPage, user);
    });
  }

  private handleDetailsPanel(): void {
    this.detailsPanel$.pipe(takeUntil(this.destroy$)).subscribe((user: DOMAIN.User) => {
      this.sidePanelService.open(UserPreviewPage, user);
    });
  }

  private handleApplication(): void {
    combineLatest([this.sandbox.params$, this.sandbox.application$, this.sandbox.reseller$, this.sandbox.project$])
      .pipe(
        map(([params, application, reseller, project]) => {
          if (![PERMISSIONS.applicationReseller, PERMISSIONS.applicationProject].includes(application)) return [application];

          // Checks when application is reseller level
          if (params.resellerId && reseller && params.projectKey && project) {
            return [PERMISSIONS.applicationReseller, reseller.id, project];
          }
          if (params.resellerId && reseller && !params.projectKey) {
            return [PERMISSIONS.applicationReseller, reseller.id, null];
          }
          if (!params.resellerId && !params.projectKey) {
            return [application, null, null];
          }

          return null;
        }),
        filter(isNotNullUndefinedOrEmpty),
        takeUntil(this.destroy$),
      )
      .subscribe(([application, resellerId, project]) => {
        const permissionListInput: PERMISSIONS.Permission[] = [
          {
            application: { name: application, qualifiers: [resellerId || ''] },
            domain: project ? { name: PERMISSIONS.applicationProject, qualifiers: [project.key] } : null,
            subdomain: null,
            action: PERMISSIONS.actionRead,
          },
        ];

        // Customer dashboard permission uses other application when project users are retrieved
        if (project) {
          permissionListInput.push({
            application: { name: PERMISSIONS.applicationCustomer, qualifiers: [project.location?.id || ''] },
            domain: project ? { name: PERMISSIONS.applicationProject, qualifiers: [project.key] } : null,
            subdomain: null,
            action: PERMISSIONS.actionRead,
          });
        }

        this.sandbox.getUsersByPermissions(permissionListInput);
      });
  }

  private handleRemove(): void {
    this.remove$
      .pipe(withLatestFrom(this.sandbox.application$, this.sandbox.reseller$, this.sandbox.project$), takeUntil(this.destroy$))
      .subscribe(([user, application, reseller, project]) => {
        const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
          autoFocus: true,
          data: {
            showConfirmationInput: true,
            confirmationString: defaultDeletionString,
            title: this.i18next.transform('common:dialog.confirm-remove.title'),
            content: this.i18next.transform('common:dialog.confirm-remove.content', { field: user.username }),
            cancelButton: this.i18next.transform('common:buttons.cancel'),
            primaryButton: this.i18next.transform('common:buttons.delete'),
            confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', {
              field: defaultDeletionString,
            }),
          },
        };

        this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        // eslint-disable-next-line rxjs/no-nested-subscribe
        this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
          const rolesList = getUserRoles(application, reseller, project);
          const rolesListPermissions = flatten(rolesList.roles.map((role) => role.permissions)).filter(onlyUnique);
          const filteredUserPermissions = user.permissions
            .map(modelToPermissionString)
            .filter((permission) => !rolesListPermissions.includes(permission));

          if (filteredUserPermissions.length === 0) {
            this.sandbox.removeUser(user.id);
          } else {
            const userInput: DOMAIN.UserInput = {
              ...user,
              permissions: filteredUserPermissions,
            };
            this.sandbox.updateUser(userInput);
          }
        });

        combineLatest([this.sandbox.upsertState$, this.sandbox.removeState$])
          .pipe(takeUntil(this.destroy$))
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe(([upsert, remove]) => {
            if (upsert.isSuccessful && this.confirmDialog) {
              this.confirmDialog.close();
              this.sandbox.removeStoreUser(user);
              this.sandbox.resetUpsertState();
            }
            if (remove.isSuccessful && this.confirmDialog) {
              this.confirmDialog.close();
              this.sandbox.resetRemoveState();
            }
          });
      });
  }
}
