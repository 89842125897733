import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UI } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { Observable } from 'rxjs';

import { ProjectState, ProjectStore } from './project.store';

@Injectable({
  providedIn: 'root',
})
export class ProjectQuery extends QueryEntity<ProjectState, DTO.Project> {
  // upsert
  selectUpsertState$: Observable<UI.State>;
  selectRemoveState$: Observable<UI.State>;

  constructor(protected store: ProjectStore) {
    super(store);

    this.selectUpsertState$ = this.select((state) => state.ui.upsert);
    this.selectRemoveState$ = this.select((state) => state.ui.remove);
  }
}
