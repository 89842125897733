import { Injectable } from '@angular/core';
import { DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, map, Observable } from 'rxjs';
import { ScenarioService, CommandService, ProjectQuery, ScenarioQuery } from '../../../data-access';

@Injectable()
export class CustomerScenarioSandbox {
  activeProject$: Observable<DTO.Project>;
  scenarios$: Observable<DOMAIN.Scenario[]>;

  constructor(
    private scenarioService: ScenarioService,
    private commandService: CommandService,
    private projectQuery: ProjectQuery,
    private scenarioQuery: ScenarioQuery,
  ) {
    this.activeProject$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));
    this.scenarios$ = this.scenarioQuery.selectAll().pipe(map((scenarios) => scenarios.slice().sort((a, b) => a.sortOrder - b.sortOrder)));
  }

  getScenarios(projectKey?: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  executeScenarioCommand(scenarioId: string, projectKey?: string): void {
    this.commandService.executeCommand({ scenarioId, state: 'ON', ...(projectKey && { projectKey }) });
  }
}
