import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DOMAIN } from '@summa/models';
import { FloorplanState, FloorplanStore } from './floorplan.store';

@Injectable({
  providedIn: 'root',
})
export class FloorplanQuery extends QueryEntity<FloorplanState, DOMAIN.Floorplan> {
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: FloorplanStore) {
    super(store);
  }
}
