import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter } from 'rxjs';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { ProjectQuery, ProjectService } from '../../data-access';

@Injectable()
export class ProjectSandbox {
  params$ = this.routerQuery.selectParams();
  project$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  constructor(private projectQuery: ProjectQuery, private projectService: ProjectService, private routerQuery: RouterQuery) {}

  getProject(key: string): void {
    if (key !== this.projectQuery.getActiveId()) {
      this.projectService.getProject(key);
    }
  }

  reset(): void {
    this.projectService.reset();
  }
}
