import { gql } from 'apollo-angular';

export const upsertPresetQuery = gql`
  mutation upsertPreset($preset: PresetInput!) {
    upsertPreset(preset: $preset) {
      id
      # resellerId
      name
      description
      cct
      duv
      x
      y
    }
  }
`;

export const removePresetQuery = gql`
  mutation removePreset($id: String!) {
    removePreset(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
