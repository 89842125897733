import { Injectable } from '@angular/core';
import { SidePanelService } from '@summa/shared/ui/dialogs';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { DTO } from '@summa/portal/models/dto';
import {
  AuthQuery,
  CardQuery,
  CardService,
  DriverQuery,
  DriverService,
  ProjectQuery,
  ScenarioQuery,
  ScenarioService,
} from '../../../../../data-access';

@Injectable()
export class ProjectDriverControlSettingsSandbox {
  driver$ = this.driverQuery.selectActive();
  scenarios$ = this.scenarioQuery.selectAll();
  groups$ = this.cardQuery.selectAll();
  project$ = this.projectQuery.selectActive();
  currentUser$ = this.authQuery.selectActive();

  constructor(
    private projectQuery: ProjectQuery,
    private driverQuery: DriverQuery,
    private driverService: DriverService,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private cardService: CardService,
    private cardQuery: CardQuery,
    private sidePanelService: SidePanelService,
    private authQuery: AuthQuery,
  ) {}

  getGroups(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  updateDriver(driver: DTO.DriverInput): void {
    this.driverService.upsertDriver(driver);
  }

  reset(): void {
    this.cardService.reset();
    this.scenarioService.reset();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
