import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ID } from '@datorama/akita';

import { DTO } from '@summa/portal/models/dto';

import { GetProjectQuery } from './graphql';
import { ProjectStore } from './project.store';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private apollo: Apollo, private projectStore: ProjectStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.projectStore.reset();
  }

  resetProjectUIState(): void {
    this.projectStore.resetUIState();
  }

  setActive(id: ID | null): void {
    this.projectStore.setActive(id);
  }

  getProject(key?: string, id?: string): void {
    this.apollo
      .subscribe({ query: GetProjectQuery, variables: { key, id } })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getProject as DTO.Project))
      .subscribe((project) => {
        this.projectStore.upsert(project.key, project);
        this.projectStore.setActive(project.key);
      });
  }
}
