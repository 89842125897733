import { InjectionToken } from '@angular/core';
import { DOMAIN } from '@summa/models';
import { Observable } from 'rxjs';

export const PROJECT_SERVICE = new InjectionToken<ProjectService>('project-service');

export interface ProjectService {
  currentProject$: Observable<DOMAIN.Project>;
  getCurrentProject: () => void;
}
