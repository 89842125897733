import { gql } from 'apollo-angular';

export const upsertUserQuery = gql`
  mutation upsertUser($userInput: UserInput!, $resellerId: String) {
    upsertUser(userInput: $userInput, resellerId: $resellerId) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const createUserQuery = gql`
  mutation createUser($userInput: UserInput!) {
    createUser(userInput: $userInput) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const updateUserQuery = gql`
  mutation updateUser($userInput: UserInput!, $resellerId: String) {
    updateUser(userInput: $userInput, resellerId: $resellerId) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const addUserPermissionsQuery = gql`
  mutation addUserPermissions($userInput: UserInput!) {
    addUserPermissions(userInput: $userInput) {
      id
      username
      roles
      permissions
      language
    }
  }
`;

export const updateUserAgreementsQuery = gql`
  mutation updateUserAgreements($agreements: [UserAgreementInput!]!) {
    updateUserAgreements(agreements: $agreements) {
      id
      username
      roles
      permissions
      language
      agreements {
        documentId
        version
        acceptedDate
      }
    }
  }
`;

export const removeUserQuery = gql`
  mutation removeUser($id: String!, $resellerId: String) {
    removeUser(id: $id, resellerId: $resellerId)
  }
`;
