import { gql } from 'apollo-angular';

export const GetFirmwaresQuery = gql`
  query getFirmwares($skip: Int, $take: Int, $projectKey: String) {
    getFirmwares(skip: $skip, take: $take, projectKey: $projectKey) {
      name
      date
      lastModified
      projects {
        projectKey
        isExecuted
        executionDate
      }
    }
  }
`;

export const GetFirmwareQuery = gql`
  query getFirmware($name: String) {
    getFirmware(name: $name) {
      name
      date
      lastModified
      projects {
        projectKey
        isExecuted
        executionDate
      }
    }
  }
`;
