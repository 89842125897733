import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { CustomerCardSandbox } from '../../../customer-card/customer-card.sandbox';

@Component({
  selector: 'summa-card-detail-tab-scenes',
  template: `
    <div *ngIf="sandbox.card$ | async as card">
      <div class="mt-1 mb-4" fxLayout="row wrap">
        <ng-container *ngIf="card.scenes.length > 0; else noScene">
          <ng-container *ngIf="sandbox.sortedScenes$ | async as scenes">
            <div
              *ngFor="let scene of scenes"
              class="scene-item clickable"
              [class.selected]="scene.id === card.selectedSceneId"
              (click)="sceneSelected$.next(scene.id)"
              [title]="scene.description"
              fxFlex.xs="50"
              fxFlex.gt-xs="25"
              fxLayout="column"
              fxLayoutAlign="center center"
            >
              <div class="title">{{ scene.name }}</div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #noScene>
          {{ 'customer:card-details.no-scenes-available' | i18nextCap }}
        </ng-template>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./card-detail-tab-scene.component.scss'],
  providers: [CustomerCardSandbox],
})
export class CardDetailTabScenesComponent implements OnInit, OnDestroy {
  sceneSelected$ = new Subject<string>();

  destroy$ = new Subject();

  constructor(public sandbox: CustomerCardSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleSceneSelect();
  }

  handleSceneSelect(): void {
    this.sceneSelected$
      .pipe(withLatestFrom(this.sandbox.card$, this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([sceneId, card, { projectKey }]) => {
        // execute scene activation
        this.sandbox.executeSceneCommand(card, projectKey, sceneId);
        // save card
        this.sandbox.activateScene(card.id, sceneId);
      });
  }
}
