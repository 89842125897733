<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{ ((sandbox.control$ |async) ? 'reseller:controls.upsert.edit-title': 'reseller:controls.upsert.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{ project.key}}) {{ project.name}}</div>
      </div>
      <div *ngIf="sandbox.control$ |async as control">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ control.id }}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
          <mat-error *ngIf="form.get('name').invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.description' | i18nextCap}}</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
        <mat-form-field fxFlex="50" appearance="legacy">
          <mat-icon matPrefix class="mr-1" svgIcon="qrcode-scan" (click)="controlInput.focus()"></mat-icon>
          <mat-label>{{ 'project:add-drivers.scan-driver' | i18nextCap }}</mat-label>
          <input #controlInput matInput formControlName="driver" (blur)="this.form.get('driver').value && addControl$.next()" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-select formControlName="mode" [placeholder]="'common:dictionary.mode' | i18nextCap">
            <mat-option *ngFor="let control of controlModes" [value]="control">
              {{control}} - {{ 'common:dictionary.control-modes.' + control | i18nextCap }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <section formArrayName="channels">
          <section *ngFor="let _ of channels.controls; index as ch">
            <section class="card-entry" [formGroupName]="ch">
              <section *ngIf="channels.controls.length > 1" class="remove mt-2">
                <mat-icon (click)="removeChannel(ch)" title="Remove channel" [svgIcon]="'common:icons.remove-card' | i18next"></mat-icon>
              </section>
              <section fxLayout="column">
                <mat-form-field appearance="legacy">
                  <mat-label>{{'common:dictionary.address' | i18nextCap}}</mat-label>
                  <input matInput formControlName="address" />
                </mat-form-field>

                <ng-container [ngSwitch]="form.get('mode').value">
                  <ng-container [ngTemplateOutlet]="doublePulseSettings" *ngSwitchCase="0"></ng-container>
                  <ng-container [ngTemplateOutlet]="highLowSettings" *ngSwitchCase="1"></ng-container>
                  <ng-container [ngTemplateOutlet]="pulseSettings" *ngSwitchCase="2"></ng-container>
                  <ng-container [ngTemplateOutlet]="alternateSettings" *ngSwitchCase="3"></ng-container>
                  <ng-container [ngTemplateOutlet]="alternateSettings" *ngSwitchCase="4"></ng-container>
                </ng-container>
              </section>

              <ng-template #doublePulseSettings>
                <h3>{{ 'reseller:controls.upsert.channel-types.single' | i18nextCap }}</h3>
                <summa-control-cards [formControl]="channelFormControl(ch, 'single')"></summa-control-cards>

                <section class="mt-2" fxLayout="row" fxLayoutAlign="start center">
                  <h3 class="m-0 d-inline-block">{{ 'reseller:controls.upsert.channel-types.double' | i18nextCap }}</h3>
                  <mat-checkbox class="ml-2" formControlName="enableDouble">&nbsp;</mat-checkbox>
                </section>
                <summa-control-cards
                  class="mt-2"
                  *ngIf="channelValue(ch, 'enableDouble')"
                  [formControl]="channelFormControl(ch, 'double')"
                ></summa-control-cards>
              </ng-template>

              <ng-template #pulseSettings>
                <h3>{{ 'reseller:controls.upsert.channel-types.single' | i18nextCap }}</h3>
                <summa-control-cards [formControl]="channelFormControl(ch, 'single')"></summa-control-cards>
              </ng-template>

              <ng-template #highLowSettings>
                <h3>{{ 'reseller:controls.upsert.channel-types.high' | i18nextCap }}</h3>
                <summa-control-cards [formControl]="channelFormControl(ch, 'high')"></summa-control-cards>

                <h3>{{ 'reseller:controls.upsert.channel-types.low' | i18nextCap }}</h3>
                <summa-control-cards [formControl]="channelFormControl(ch, 'low')"></summa-control-cards>
              </ng-template>

              <ng-template #alternateSettings>
                <ng-container formGroupName="alternate">
                  <mat-radio-group formControlName="choice" aria-label="Select an option" class="mb-1">
                    <mat-radio-button class="mr-2" *ngFor="let choice of [choiceAlternateScenario, choiceKey]" [value]="choice">
                      {{'reseller:controls.add.' + choice | i18nextCap}}
                    </mat-radio-button>
                  </mat-radio-group>

                  <ng-container *ngIf="channelValue(ch, 'alternate.choice') === choiceAlternateScenario">
                    <!-- drag and drop -->
                    <div *ngIf="scenarios(ch) as scenarios" cdkDropList class="example-list" (cdkDropListDropped)="drop(ch, $event)">
                      <ng-container *ngIf="scenarios.controls?.length > 0">
                        <ng-container *ngFor="let scenario of scenarios.controls; let index = index">
                          <div class="example-box" cdkDrag cdkDragHandle>
                            <mat-icon class="mr-1" svgIcon="drag"></mat-icon>
                            <span>{{ scenario?.value?.name }}</span>
                            <mat-icon (click)="removeScenario(ch, index)" svgIcon="close"></mat-icon>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>

                    <!-- select to add scenario -->
                    <mat-form-field appearance="legacy">
                      <mat-select
                        #scenarioSelector
                        (selectionChange)="scenarioSelection$?.next({ channel: ch, scenario:  $event.value})"
                        placeholder="{{'reseller:controls.upsert.select-to-add-scenario' | i18nextCap}} *"
                      >
                        <mat-option *ngFor="let scenario of sandbox.scenarios$ | async" [value]="scenario"> {{ scenario.name }} </mat-option>
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="50" appearance="legacy">
                      <mat-label>{{ 'reseller:controls.add.key' | i18nextCap }} *</mat-label>
                      <input matInput formControlName="key" />
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngIf="channelValue(ch, 'alternate.choice') === choiceKey">
                    <mat-form-field fxFlex="50" appearance="legacy">
                      <mat-label>{{ 'reseller:controls.add.key' | i18nextCap }} *</mat-label>
                      <input matInput formControlName="referenceKey" />
                    </mat-form-field>
                  </ng-container>
                </ng-container>
              </ng-template>
            </section>
          </section>
          <mat-icon
            class="add mt-2"
            (click)="addChannel()"
            title="{{'reseller:controls.add.channel-tooltip' | i18nextCap}}"
            [svgIcon]="'common:icons.add-card' | i18next"
          >
            {{'reseller:controls.add.channel-text' | i18nextCap}}</mat-icon
          >
        </section>
      </section>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button *ngIf="form" mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
