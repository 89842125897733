import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DOMAIN } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CustomerCardSandbox } from '../customer-card/customer-card.sandbox';

@Component({
  selector: 'summa-customer-dashboard',
  templateUrl: './card-detail.component.html',
  styleUrls: ['./card-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerCardSandbox],
})
export class CardDetailComponent {
  readonly maximumEntries = 50;

  selectedScene$: Observable<DOMAIN.Scene>;
  maximumEntriesExceeded$: Observable<boolean>;

  constructor(public sandbox: CustomerCardSandbox) {
    this.selectedScene$ = this.sandbox.card$.pipe(
      map((card) => card.scenes.find((s) => s.id === card.selectedSceneId)),
      filter(isNotNullOrUndefined),
    );
    this.maximumEntriesExceeded$ = this.sandbox.card$.pipe(map((card) => card.devices.length > this.maximumEntries));
  }
}
