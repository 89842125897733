import { Injectable } from '@angular/core';

import { CONSTANTS } from '@summa/models';

@Injectable({ providedIn: 'root' })
export class RolesToggleService {
  hasRequiredRoles(requiredRoles: CONSTANTS.Roles[], userRoles: CONSTANTS.Roles[]): boolean {
    // Get hierarchy index value for each role
    const rolesIndex = requiredRoles.map((r) => CONSTANTS.roleHierarchy[r.toLowerCase()]);
    const userRolesIndex = userRoles.map((r) => CONSTANTS.roleHierarchy[r.toLowerCase()]);

    // User should have the same or higher role hierarchy
    return rolesIndex.map((r) => CONSTANTS.roleHierarchy[r.toLowerCase()]).some((ri) => userRolesIndex.some((uri) => uri <= ri));
  }
}
