import { gql } from 'apollo-angular';

export const GetCardOverviewQuery = gql`
  query getCards($skip: Int, $take: Int, $projectKey: String) {
    getCards(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      projectKey
      title
      # scenes {
      #   id
      #      projectKey
      #   name
      #     description
      #     types {
      #       deviceType
      #       dimValue
      #     }
      #     devices {
      #       deviceId
      #       dimValue
      #    }
      # }
      selectedSceneId
      sortOrder
      hidden
      # devices {
      #   id
      #   key
      #   type
      #   address
      #   category
      #   settings
      #   driver {
      #     key
      #     address
      #   }
      # }
    }
  }
`;

export const GetCardsQuery = gql`
  query getCards($skip: Int, $take: Int, $projectKey: String) {
    getCards(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      projectKey
      title
      scenes {
        id
        projectKey
        name
        description
      }
      selectedSceneId
      sortOrder
      hidden
      devices {
        id
        key
        type
        address
        category
        settings
        driver {
          key
          address
        }
      }
    }
  }
`;

export const GetCardQuery = gql`
  query getCard($id: String!) {
    getCard(id: $id) {
      id
      projectKey
      title
      scenes {
        id
        name
        description
      }
      selectedSceneId
      sortOrder
      hidden
      devices {
        id
        key
        type
        address
        # category
        # settings
        # driver {
        #   key
        #   address
        # }
      }
    }
  }
`;

export const GetCardPreviewQuery = gql`
  query getCard($id: String!) {
    getCard(id: $id) {
      id
      projectKey
      title
      scenes {
        id
        name
      }
      selectedSceneId
      sortOrder
      hidden
      devices {
        id
        key
      }
    }
  }
`;
