import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ControlPanelWidgetSandbox } from './control-panel-widget.sandbox';

@Component({
  selector: 'summa-control-panel-widget',
  templateUrl: './control-panel-widget.component.html',
  styleUrls: ['./control-panel-widget.component.scss'],
  providers: [ControlPanelWidgetSandbox],
})
export class ControlPanelWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public sandbox: ControlPanelWidgetSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getControlPanels(projectKey);
    });
  }
}
