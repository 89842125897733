import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { ScenarioState, ScenarioStore } from './scenario.store';

@Injectable({
  providedIn: 'root',
})
export class ScenarioQuery extends QueryEntity<ScenarioState, DOMAIN.Scenario> {
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: ScenarioStore) {
    super(store);
  }
}
