/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { InstallerQuery, InstallerService, ProjectQuery } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectInstallerOverviewSandbox {
  params$ = this.router.selectParams();
  installers$ = this.installerQuery.selectAll();
  removeState$ = this.installerQuery.selectRemoveState$;
  project$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private router: RouterQuery,
    private projectQuery: ProjectQuery,
    private installerQuery: InstallerQuery,
    private installerService: InstallerService,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  reset(): void {}

  getInstallers(projectKey: string) {
    this.installerService.getInstallers(projectKey);
  }

  removeInstaller(id: string): void {
    this.installerService.removeInstaller(id);
  }
}
