<summa-side-panel-content class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$ | async) ? 'project:control-panels.detail.edit-control' : 'project:control-panels.detail.add-control') | i18nextCap}}</h1>
  </ng-container>

  <ng-container body>
    <form id="upsertControlForm" *ngIf="form" [formGroup]="form" class="w-100" fxLayout="column" (ngSubmit)="submit$.next(form)">
      <mat-form-field appearance="legacy">
        <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="form.get('name')?.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>{{'common:dictionary.sort' | i18nextCap}}</mat-label>
        <input matInput type="number" formControlName="sort" />
        <mat-error *ngIf="form.get('sort')?.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="legacy">
        <mat-label>{{'common:dictionary.icon' | i18nextCap}}</mat-label>
        <input matInput formControlName="icon" />
        <mat-error *ngIf="form.get('icon')?.invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
      </mat-form-field>

      <section formArrayName="entities">
        <section *ngFor="let _ of entities.controls; index as i">
          <section class="card-entry pb-2" [formGroupName]="i">
            <section *ngIf="entities.controls.length > 1" class="remove mt-2">
              <mat-icon (click)="removeEntity(i)" title="Remove channel" [svgIcon]="'common:icons.remove-card' | i18next"></mat-icon>
            </section>

            <section fxLayout="column">
              <mat-radio-group formControlName="choice" aria-label="Select an option" class="mb-1">
                <mat-radio-button class="mr-2" *ngFor="let choice of entityChoices" [value]="choice">{{choice}} </mat-radio-button>
              </mat-radio-group>

              <ng-container *ngIf="entities.controls[i].get('choice')?.value === 'group'">
                <mat-form-field class="mt-2">
                  <mat-select formControlName="group" [placeholder]="'common:dictionary.card' | i18nextCap">
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="groupFilter"
                        [placeholderLabel]="'reseller:cards.find-card' | i18nextCap"
                        [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let group of filteredGroups$ | async" [value]="group.id">{{ group.title }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <ng-container *ngIf="entities.controls[i].get('choice')?.value === 'device'">
                <mat-form-field class="mt-2">
                  <mat-select formControlName="device" placeholder="Devices">
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="deviceFilter"
                        [placeholderLabel]="'reseller:cards.find-fixture' | i18nextCap"
                        [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let device of filteredDevices$ | async" [value]="device.id"> {{device.key}} </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-container>

              <mat-form-field class="mb-0">
                <mat-select formControlName="scene" placeholder="Scenes">
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="sceneFilter"
                      [placeholderLabel]="'common:dictionary.scene' | i18nextCap"
                      [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                    ></ngx-mat-select-search>
                  </mat-option>
                  <ng-container *ngFor="let scene of filteredScenes$ | async">
                    <mat-option [value]="scene.id"> {{scene.name }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </section>
          </section>
        </section>

        <mat-icon
          class="add mt-2"
          (click)="addEntity()"
          [title]="'reseller:controls.add.channel-tooltip' | i18nextCap"
          [svgIcon]="'common:icons.add-card' | i18next"
        >
          {{'reseller:controls.add.channel-text' | i18nextCap}}
        </mat-icon>
      </section>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="submit" form="upsertControlForm" class="button save" [disabled]="form?.invalid">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
