<div *ngIf="sandbox.card$ | async as card">
  <div class="mt-2 mb-4" fxLayout="row wrap">
    <article class="w-100" [class.card-entry]="isPortal">
      <p class="mt-1 mb-3" [innerHTML]="'customer:lab.intro-warning' | i18nextCap"></p>

      <section class="w-100">
        <p class="lab-slider-title mb-0">
          {{ 'common:dictionary.channel' | i18nextCap }} 1: <span class="lab-value">{{ (color$ | async)?.r ?? 0 }}</span>
        </p>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
          <small fxFlex="8">{{ color.min }}</small>
          <mat-slider
            [value]="(color$ | async)?.r ?? 0"
            fxFlex
            [max]="color.max"
            [min]="color.min"
            [step]="color.steps"
            thumbLabel="true"
            [tickInterval]="color.interval"
            (change)="red$.next($event)"
          >
          </mat-slider>
          <small fxFlex="8">{{ color.max }}</small>
        </div>
      </section>

      <section class="mt-1 w-100">
        <p class="lab-slider-title mb-0">
          {{ 'common:dictionary.channel' | i18nextCap }} 2: <span class="lab-value">{{ (color$ | async)?.g ?? 0 }}</span>
        </p>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
          <small fxFlex="8">{{ color.min }}</small>
          <mat-slider
            [value]="(color$ | async)?.g ?? 0"
            fxFlex
            [max]="color.max"
            [min]="color.min"
            [step]="color.steps"
            thumbLabel="true"
            [tickInterval]="color.interval"
            (change)="green$.next($event)"
          >
          </mat-slider>
          <small fxFlex="8">{{ color.max }}</small>
        </div>
      </section>

      <section class="mt-1 w-100">
        <p class="lab-slider-title mb-0">
          {{ 'common:dictionary.channel' | i18nextCap }} 3: <span class="lab-value">{{ (color$ | async)?.b ?? 0 }}</span>
        </p>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
          <small fxFlex="8">{{ color.min }}</small>
          <mat-slider
            [value]="(color$ | async)?.b ?? 0"
            fxFlex
            [max]="color.max"
            [min]="color.min"
            [step]="color.steps"
            thumbLabel="true"
            [tickInterval]="color.interval"
            (change)="blue$.next($event)"
          >
          </mat-slider>
          <small fxFlex="8">{{ color.max }}</small>
        </div>
      </section>
    </article>

    <section class="mt-1 w-100" *ngIf="dimValue$ | async as dimValue">
      <p class="lab-slider-title mb-0">
        {{ 'common:dictionary.brightness' | i18nextCap }}:
        <span class="lab-value">{{ dimSettings.useNewCalculation ? (dimValue | number: '1.2-2') : (dimValue | number: '1.0-0') }}</span>
        <span [summaPermissions]="rndPermission" [userPermissions]="(this.sandbox.currentUser$ | async)?.permissions">
          <mat-checkbox
            class="ml-2"
            [checked]="dimSettings.useNewCalculation"
            (change)="dimSettings.useNewCalculation = !dimSettings.useNewCalculation"
          >
            {{ 'customer:lab.exponential-dimming' | i18nextCap }}
          </mat-checkbox>
        </span>
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <small fxFlex="8">{{ dimSettings.min }}</small>
        <mat-slider
          *ngIf="!dimSettings.useNewCalculation; else newCalculationSlider"
          [value]="dimValue"
          fxFlex
          [max]="dimSettings.max"
          [min]="dimSettings.min"
          [step]="dimSettings.steps"
          thumbLabel="true"
          [tickInterval]="dimSettings.interval"
          (change)="dimChangedValue$.next($event)"
        >
        </mat-slider>
        <ng-template #newCalculationSlider>
          <mat-slider
            [value]="dimSettings.max"
            fxFlex
            [displayWith]="formatDimValue"
            [max]="dimSettings.max"
            [min]="dimSettings.min"
            thumbLabel="true"
            [tickInterval]="dimSettings.interval"
            (change)="dimChangedValue$.next($event)"
          >
          </mat-slider>
        </ng-template>
        <small fxFlex="8">{{ dimSettings.max }}</small>
      </div>
    </section>

    <section class="mt-1 w-100">
      <p class="lab-slider-title mb-0">
        CCT: <span class="lab-value">{{ cctValue$ | async }}K</span>
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <small fxFlex="8">{{ cctSettings.min }}K</small>
        <mat-slider
          [value]="cctValue$ | async"
          fxFlex
          [max]="cctSettings.max"
          [min]="cctSettings.min"
          [step]="cctSettings.steps"
          thumbLabel="true"
          [tickInterval]="cctSettings.interval"
          (change)="cctChangedValue$.next($event)"
        >
        </mat-slider>
        <small fxFlex="8">{{ cctSettings.max }}K</small>
      </div>
    </section>

    <section class="mt-1 w-100">
      <p class="lab-slider-title mb-0">
        DUV: <span class="lab-value">{{ duvValue$ | async }}</span>
      </p>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="1rem">
        <small fxFlex="8">{{ duvSettings.min }}</small>
        <mat-slider
          [value]="duvValue$ | async"
          fxFlex
          [max]="duvSettings.max"
          [min]="duvSettings.min"
          [step]="duvSettings.steps"
          thumbLabel="true"
          [tickInterval]="duvSettings.interval"
          (change)="duvChangedValue$.next($event)"
        >
        </mat-slider>
        <small fxFlex="8">{{ duvSettings.max }}</small>
      </div>
    </section>

    <button *ngIf="isPortal" mat-raised-button color="primary" (click)="saveLab$.next()" class="mt-2">
      {{ 'customer:lab.save-settings-btn' | i18nextCap }}
    </button>
  </div>
</div>
