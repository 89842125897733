import { DOMAIN } from '../..';

export interface FixtureLog {
  project?: string;
  key?: string;
  state?: 'online' | 'offline';
  topic: string;
  timestamp: number;
  localTimestamp?: number;
  ip?: string;
  ticks?: number;
  version?: string;
  type?: DOMAIN.DeviceType;
  temp?: number;
  channels?: ChannelLog[];
  dimLevel?: number;
  fadeRate?: number;
  amountCh?: number;
  walkTime?: number;
  ledCurr?: number;
  ledVolt?: number;
}

export class ChannelLog {
  id: string;
  intensity: number;
  current?: number;
  offTime?: number;
  volt?: number;
}
