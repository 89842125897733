import { gql } from 'apollo-angular';

export const upsertInstallerQuery = gql`
  mutation upsertInstaller($installerInput: InstallerInput!) {
    upsertInstaller(installerInput: $installerInput) {
      id
      name
      enabled
      projectKey
    }
  }
`;

export const removeInstallerQuery = gql`
  mutation removeInstaller($id: String!) {
    removeInstaller(id: $id)
  }
`;
