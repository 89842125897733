import { PERMISSIONS } from '@summa/models';

export const controlPanelUpsertPermission: PERMISSIONS.Permission = {
  application: {
    name: PERMISSIONS.applicationReseller,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  domain: {
    name: PERMISSIONS.applicationProject,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  subdomain: {
    name: PERMISSIONS.domainControlPanel,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  action: PERMISSIONS.actionUpsert,
};

export const controlPanelDeletePermission: PERMISSIONS.Permission = {
  application: {
    name: PERMISSIONS.applicationReseller,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  domain: {
    name: PERMISSIONS.applicationProject,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  subdomain: {
    name: PERMISSIONS.domainControlPanel,
    qualifiers: [PERMISSIONS.wildcardQualifier],
  },
  action: PERMISSIONS.actionDelete,
};
