import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';

import { PortalAPI } from '@summa/models';

import { CurrentCheckStore } from './current-check.store';
import { executeCurrentChangeQuery, executeCurrentCheckQuery } from './graphql';

@Injectable({
  providedIn: 'root',
})
export class CurrentCheckService {
  subscriptions: Subscription;

  constructor(
    private apollo: Apollo,
    private currentCheckStore: CurrentCheckStore,
    private notifier: NotifierService,
    private i18next: I18NextCapPipe,
  ) {
    this.currentCheckStore.setLoading(false);
  }

  reset(): void {
    this.currentCheckStore.reset();
    this.currentCheckStore.setLoading(false);
  }

  executeCurrentCheck(command: PortalAPI.CurrentCheckInput): void {
    this.currentCheckStore.setLoading(true);

    this.apollo.mutate({ mutation: executeCurrentCheckQuery, variables: { command }, fetchPolicy: 'no-cache' }).subscribe(({ data, errors }: any) => {
      if (!data || errors) {
        this.currentCheckStore.setError(errors);
        this.currentCheckStore.setActive(null);
        this.currentCheckStore.setLoading(false);
        this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
        return;
      }
      const commandResult = data.executeCurrentCheckCommand;
      this.currentCheckStore.setLoading(false);
      this.currentCheckStore.upsert(commandResult.driverInfo.driverKey, commandResult);
      this.currentCheckStore.setActive(commandResult?.driverInfo.driverKey);
    });
  }

  executeCurrentChange(command: PortalAPI.CurrentChangeInput): void {
    this.currentCheckStore.updateChange({ isLoading: true, errors: null, isSuccessful: false });
    this.apollo.mutate({ mutation: executeCurrentChangeQuery, variables: { command } }).subscribe(({ data, errors }: any) => {
      this.currentCheckStore.updateChange({ isLoading: false });
      if (!data || errors) {
        // raise error
        this.currentCheckStore.updateChange({ errors, isLoading: false });
        this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));

        return;
      }
      this.notifier.notify('success', this.i18next.transform('reseller:project.drivers.preview.success-message'));
      this.currentCheckStore.updateChange({ isLoading: false, isSuccessful: true });
    });
  }
}
