import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { CommandService, ProjectQuery } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectInfoWidgetSandbox {
  project$ = this.projectQuery.selectActive();
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;
  params$ = this.router.selectParams();

  constructor(
    private projectQuery: ProjectQuery,
    private commandService: CommandService,
    private globalProjectPermission: GlobalProjectPermissions,
    private router: RouterQuery,
  ) {}

  updateLocalProject(projectKey: string): void {
    this.commandService.executeUpdateLocalCommand(projectKey);
  }
}
