<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{'project:edit-title' | i18nextCap}}</h1>
  </ng-container>

  <ng-container info>
    <section class="section" *ngIf="sandbox.project$ |async as project">
      <section>
        <label fxFlex="15">{{'dictionary.project-key' | i18nextCap}}</label>
        <div fxFlex="auto">{{ project.key}}</div>
      </section>
    </section>
  </ng-container>

  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section class="project-info">
        <mat-form-field class="projectName" appearance="legacy">
          <mat-label>{{'project:name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <div fxLayout="row wrap">
          <div fxFlex="70">
            <mat-form-field class="apiKey" appearance="legacy">
              <mat-label>{{'dictionary.api-key' | i18nextCap}}</mat-label>
              <input #apiKey class="disabled" matInput formControlName="apiKey" />
            </mat-form-field>
          </div>
          <div fxFlex="25">
            <button class="generate-button" (click)="generateApiKey()">{{'buttons.generate-api-key' | i18nextCap}}</button>
          </div>
        </div>

        <summa-timezone-selector [formControl]="timezone" class="timezone-form-field"></summa-timezone-selector>
        <!-- GEOLOCATION -->
        <h4 class="mt-1">{{ 'dictionary:geolocation' | i18next | uppercase}}</h4>
        <section formGroupName="geolocation">
          <mat-form-field appearance="legacy">
            <mat-label>{{'dictionary.longitude' | i18nextCap}}</mat-label>
            <input type="number" matInput formControlName="longitude" />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>{{'dictionary.latitude' | i18nextCap}}</mat-label>
            <input type="number" matInput formControlName="latitude" />
          </mat-form-field>
        </section>
        <!-- CONNECTIONS -->
        <h4 class="mt-1">{{ 'dictionary:connection' | i18next | uppercase}}</h4>
        <div formGroupName="settings">
          <mat-form-field class="wifiSsid" appearance="legacy">
            <mat-label>{{'project:ssid' | i18nextCap}}</mat-label>
            <input matInput formControlName="wifiSsid" />
          </mat-form-field>

          <mat-form-field class="wifiPassword" appearance="legacy">
            <mat-label>{{'project:ssid-password' | i18nextCap}}</mat-label>
            <input matInput type="password" formControlName="wifiPassword" />
          </mat-form-field>
        </div>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxFlex="100" fxLayoutGap="10px">
      <button mat-button type="button" (click)="sandbox.close()" class="button cancel">{{'buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="!form || form.invalid" mat-button (click)="submit$.next(null)" class="button save">{{'buttons.save' | i18nextCap}}</button>
    </section>
  </ng-container>
</summa-side-panel-content>
