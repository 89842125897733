<!-- SCENARIO -->
<section class="scenarios">
  <summa-scenario></summa-scenario>
</section>

<ng-container *ngIf="sandbox.cards$ | async as cards">
  <ng-container>
    <section *ngIf="cards.length > 0 else noCards" fxLayout="row wrap" fxLayoutGap="1rem grid" class="m-4">
      <ng-container *ngFor="let card of cards">
        <summa-customer-card
          *ngIf="!card.hidden"
          fxFlex.xs="100"
          fxFlex.sm="33"
          fxFlex.md="25"
          fxFlex.gt-md="15"
          fxLayout="column"
          fxLayoutAlign="start center"
          [card]="card"
          [showExtraInfoButton]="true"
        >
        </summa-customer-card>
      </ng-container>
    </section>
  </ng-container>
</ng-container>

<ng-template #noCards>
  <section class="m-4">{{ 'customer:cards.no-cards-available' | i18nextCap }}</section>
</ng-template>
