<div fxLayout="row" fxLayoutGap="16px" class="w-100 mt-2">
  <div class="left-column reseller-card p-2" fxLayout="column" fxLayoutGap="0.5rem">
    <section fxLayoutGap="0.5rem">
      <div class="sync-button" (click)="handleAddFloorplan()">
        <div class="content">
          <mat-icon svgIcon="autorenew"></mat-icon>
          <div class="title">{{ 'project:floorplans.admin.re-upload-floorplan' | i18nextCap }}</div>
        </div>
      </div>
    </section>
    <section fxLayoutGap="0.5rem">
      <div class="sync-button" (click)="remove$.next(null)">
        <div class="content">
          <mat-icon svgIcon="delete-outline"></mat-icon>
          <div class="title">{{ 'reseller:floorplans.remove' | i18nextCap }}</div>
        </div>
      </div>
    </section>
    <!-- Remove button -->
    <!-- <button mat-button class="remove w-100" fxLayoutGap="1rem" fxLayoutAlign="start center" (click)="remove$.next(null)">
      <mat-icon svgIcon="delete-outline"> </mat-icon>
      {{ 'reseller:floorplans.remove' | i18nextCap }}
    </button> -->
  </div>
  <!-- <div fxLayout="column" fxLayoutGap="1rem"></div> -->
  <!-- FLOORPLAN -->
  <div fxFlex="auto" *ngIf="sandbox.floorplan$ | async" class="reseller-card lg white">
    <summa-floorplan-dxf-viewer [fixtures]="fixtures$ | async" [file]="fileUrl" [customHeight]="'75vh'"></summa-floorplan-dxf-viewer>
  </div>
</div>
