import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ID } from '@datorama/akita';

import { CONSTANTS } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';

import { removeProjectQuery, upsertProjectQuery, GetProjectQuery, GetProjectsQuery, patchProjectQuery } from './graphql';
import { ProjectStore } from './project.store';
import { GatewayService } from '../gateways';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private apollo: Apollo,
    private projectStore: ProjectStore,
    private notifier: NotifierService,
    private i18next: I18NextCapPipe,
    private gatewayService: GatewayService,
  ) {}

  reset(): void {
    this.projectStore.reset();
    this.gatewayService.reset();
  }

  resetProjectUIState(): void {
    this.projectStore.resetUIState();
  }

  setActive(id: ID | null): void {
    this.projectStore.setActive(id);
  }

  getProject(key: string): void {
    this.apollo
      .subscribe({ query: GetProjectQuery, variables: { key } })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getProject as DTO.Project))
      .subscribe((project) => {
        this.projectStore.upsert(project.key, project);
        this.projectStore.setActive(project.key);
      });
  }

  removeProject(key: string, id: string): void {
    this.projectStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeProjectQuery, variables: { id, key } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.projectStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `project ${key}` }));

        this.projectStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.projectStore.remove(key);

      // set success;
      this.projectStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }

  getProjects(): void {
    this.apollo
      .subscribe({ query: GetProjectsQuery })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getProjects as DTO.Project[]))
      .subscribe((projects) => {
        this.projectStore.upsertMany(projects);
      });
  }

  upsertProject(project: DTO.ProjectInput): void {
    this.projectStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertProjectQuery, variables: { project } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.projectStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));

          // set error:
          const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `key ${project.key}` }));

          this.projectStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertProject: DTO.Project };
        this.projectStore.upsert(data.upsertProject.key, data.upsertProject);
        this.projectStore.setActive(data.upsertProject.key);

        // set success:
        this.projectStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-added'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  patchProject(project: Partial<DTO.ProjectInput>): void {
    this.projectStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: patchProjectQuery, variables: { project } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.projectStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
          // set error:
          const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `key ${project.key}` }));

          this.projectStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { patchProject: DTO.Project };
        this.projectStore.upsert(data.patchProject.key, data.patchProject);
        this.projectStore.setActive(data.patchProject.key);

        // set success:
        this.projectStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }
}
