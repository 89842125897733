import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DOMAIN } from '@summa/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { ProjectFloorplansPreviewSandbox } from './project-floorplans-preview.sandbox';

@Component({
  selector: 'summa-project-floorplans-preview',
  templateUrl: './project-floorplans-preview.page.html',
  styleUrls: ['./project-floorplans-preview.page.scss'],
  providers: [ProjectFloorplansPreviewSandbox],
})
export class ProjectFloorplansPreviewPage implements OnInit, OnDestroy {
  baseUrl = 'https://summa-floorplan-storage.s3-eu-west-1.amazonaws.com';
  fileName: string;
  currentRoute: string;

  destroy$ = new Subject();
  fixtures$ = new BehaviorSubject<DOMAIN.Fixture[]>([]);

  constructor(private router: Router, public sandbox: ProjectFloorplansPreviewSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.floorplan$
      .pipe(
        filter((floorplan) => floorplan != null),
        takeUntil(this.destroy$),
      )
      .subscribe((floorplan) => {
        this.fileName = floorplan.fileName.replace('.json', '');
        this.sandbox.getFixtures(floorplan.id);
        this.sandbox.fixtures$
          .pipe(
            map((fixtures) => fixtures.filter((fixture: DOMAIN.Fixture) => fixture.floorplanKey === floorplan.id)),
            takeUntil(this.destroy$),
          )
          // eslint-disable-next-line rxjs/no-nested-subscribe
          .subscribe((fixtures) => this.fixtures$.next(fixtures));
      });
    this.currentRoute = this.router.url.includes('floorplans') ? this.router.url : `${this.router.url}/floorplans`;
  }
}
