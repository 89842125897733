import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { ProjectDriverAddSandbox } from './project-driver-add.sandbox';

@Component({
  selector: 'summa-project-driver-add',
  templateUrl: './project-driver-add.component.html',
  styleUrls: ['./project-driver-add.component.scss'],
  providers: [ProjectDriverAddSandbox],
})
export class ProjectDriverAddComponent implements OnInit, OnDestroy {
  form: FormGroup;
  destroy$ = new Subject();
  submit$ = new Subject();

  @ViewChild('driverInput', { static: false }) driverInput: ElementRef;

  constructor(private fb: FormBuilder, public sandbox: ProjectDriverAddSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      driver: '',
    });

    this.handleSuccess();
    this.handleSubmit();
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter(() => isNotNullOrUndefined(this.form.get('driver'))),
        withLatestFrom(this.sandbox.project$),
        takeUntil(this.destroy$),
      )
      .subscribe(([, project]) => {
        const driverKey = this.form.get('driver').value;
        this.sandbox.addToProject(driverKey, project.key);
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$
      .pipe(
        filter((state) => !state.isSuccessful),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.form.reset();
        this.driverInput?.nativeElement.focus();
        this.sandbox.reset();
      });
  }

  focusOnDriver(): void {
    this.driverInput?.nativeElement.focus();
  }
}
