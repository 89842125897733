import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { generateKey } from '@summa/shared/util/typescript';

import { DTO } from '@summa/portal/models/dto';
import { ProjectInstallerUpsertSandbox } from './project-installer-upsert.sandbox';

@Component({
  selector: 'summa-project-installer-upsert',
  templateUrl: './project-installer-upsert.page.html',
  styleUrls: ['./project-installer-upsert.page.scss'],
  providers: [ProjectInstallerUpsertSandbox],
})
export class ProjectInstallerUpsertPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submit$ = new Subject();

  form: FormGroup = this.fb.group({
    id: [null],
    name: ['', Validators.required],
    enabled: [false, Validators.required],
    apiKey: [''],
    projectKey: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, public sandbox: ProjectInstallerUpsertSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.project$.pipe(takeUntil(this.destroy$)).subscribe((project: DTO.Project) => {
      this.form.get('projectKey').setValue(project.key);

      this.handleData();
      this.handleSubmit();
      this.handleSuccess();
      this.handleInstaller();
    });
  }

  private handleData(): void {
    this.sandbox.data$
      .pipe(takeUntil(this.destroy$))
      .subscribe((installer: DOMAIN.Installer) => installer && this.sandbox.getInstaller(installer.id));
  }

  private handleInstaller(): void {
    this.sandbox.installer$.pipe(takeUntil(this.destroy$)).subscribe((installer: DOMAIN.Installer) => {
      this.form = this.fb.group({
        id: [installer.id, Validators.required],
        name: [installer.name, Validators.required],
        enabled: [installer.enabled, Validators.required],
        apiKey: [installer.apiKey],
        projectKey: [installer.project.key],
      });
    });
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter(() => this.form.get('projectKey').value && this.form.get('projectKey').value.length > 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        if (this.form.invalid) return;

        const generatedApiKey = this.generateNewApiKey(this.form.get('projectKey').value, this.form.get('name').value);

        const installerInput: DOMAIN.InstallerInput = {
          id: this.form.get('id').value,
          name: this.form.get('name').value,
          apiKey: generatedApiKey,
          enabled: this.form.get('enabled').value,
          projectKey: this.form.get('projectKey').value,
        };

        this.sandbox.upsertInstaller(installerInput);
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }

  private generateNewApiKey(projectKey: string, name: string): string {
    const clientPart = projectKey
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 2)
      .toLowerCase();
    const projectPart = name
      .replace(/[^a-zA-Z0-9]/g, '')
      .substring(0, 2)
      .toLowerCase();
    const prefix = `ssin-${clientPart}${projectPart}`; // ssin = Summa Systems Installer
    return generateKey(prefix, '-xxxxxx');
  }
}
