import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { combineLatest, filter, first, Subject, takeUntil } from 'rxjs';
import { AgreementsDialogComponent } from '../agreements-dialog/agreements-dialog.component';

import { ShellSandbox } from './shell.sandbox';

@Component({
  selector: 'summa-shell',
  templateUrl: './shell.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ShellSandbox],
})
export class ShellPage implements OnInit, OnDestroy {
  destroy$ = new Subject();

  confirmDialog: MatDialogRef<AgreementsDialogComponent, MatDialogConfig>;

  constructor(private router: Router, private dialog: MatDialog, public sandbox: ShellSandbox) {
    this.sandbox.getApplicationInfo();
  }

  ngOnInit(): void {
    this.sandbox.currentUser$
      .pipe(
        filter(isNotNullOrUndefined),
        filter((user) => !user.agreements || user.agreements.length <= 0),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.sandbox.getDocuments();
        this.handleUserAgreements();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  logOut(): void {
    this.sandbox.logout();
    this.router.navigate(['/login']);
  }

  private handleUserAgreements(): void {
    combineLatest([this.sandbox.currentUser$, this.sandbox.documents$])
      .pipe(
        filter(isNotNullOrUndefined),
        first(),
        filter(
          ([user, documents]) =>
            documents.filter(
              (document) =>
                !user.agreements.find(
                  (agreementDocument) => agreementDocument.documentId === document.id && agreementDocument.version === document.version,
                ),
            ).length > 0,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const dialogConfig: MatDialogConfig = {
          autoFocus: true,
          disableClose: true,
          width: '90vw',
          maxHeight: '90vh',
          id: 'agreement-dialog',
        };

        this.confirmDialog = this.dialog.open(AgreementsDialogComponent, dialogConfig);
        // eslint-disable-next-line rxjs/no-nested-subscribe
        this.confirmDialog.componentInstance.closeDialog.subscribe(() => {
          this.confirmDialog.close();
        });
      });
  }
}
