/* eslint-disable no-bitwise */

/**
 * Generates a random key based on the given structure
 * example: hello-xxxx-xxxx-xxxx
 * @param prefix plain text put before the generated code
 * @param pattern transform (xy) into a random string
 * @returns
 */
export function generateKey(prefix: string, pattern: string): string {
  return (
    prefix +
    pattern.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
  );
}
