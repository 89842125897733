import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DTO } from '@summa/portal/models/dto';

import { ControlPanelState, ControlPanelStore } from './control-panel.store';

@Injectable({
  providedIn: 'root',
})
export class ControlPanelQuery extends QueryEntity<ControlPanelState, DTO.ControlPanel> {
  selectUpsertState$ = this.select((state) => state.ui.upsert);
  selectRemoveState$ = this.select((state) => state.ui.remove);

  constructor(protected store: ControlPanelStore) {
    super(store);
  }
}
