export const redisKeyTemperatureSensor = 'sensor:temperature';
export const redisKeyAutomationMotion = 'automation:motion';

export const redisKeyGroupDynamicScene = 'group-dynamic-scene';
export const redisKeyFixture = 'fixture'; // always contains the latest log
export const redisKeyFixtureState = 'fixture-state'; // only changes when a channel or temp changed
export const redisKeyFixtureLog = 'fixture-log'; // always contains the latest log

export const redisKeyCommand = 'command';
export const redisKeyGateways = 'gateways';
export const redisKeyControlPanels = 'control-panels';

export const redisKeyControlChannelAlternate = 'control:alternate';
export const redisKeyControlActionLongPress = 'control:long-press';

export const redisType = [redisKeyTemperatureSensor, redisKeyFixture, redisKeyFixtureState, redisKeyFixtureLog] as const;
export type RedisType = typeof redisType[number];


/**
 * PUBSUB Events
 */

export const redisEventNewFixtureInProject = 'new-fixture-in-project';
export interface RedisEventNewFixtureInProject {
  topic: string;
  project: string;
}
