import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { flatten } from '@summa/shared/util/typescript';
import { CardQuery, CardService, DriverQuery, DriverService, ProjectQuery, SceneQuery, SceneService } from '../../../../data-access';

@Injectable()
export class ProjectCardAddSandbox {
  project$ = this.projectQuery.selectActive();
  card$ = this.cardQuery.selectActive();

  cards$ = this.cardQuery.selectAll();
  devices$ = this.driverQuery.selectAll().pipe(map((drivers) => flatten(drivers.map((d) => d.devices))));
  scenes$ = this.sceneQuery.selectAll();
  upsertState$ = this.cardQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData();

  constructor(
    private sidePanelService: SidePanelService,
    private projectQuery: ProjectQuery,
    private cardQuery: CardQuery,
    private cardService: CardService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private sceneService: SceneService,
    private sceneQuery: SceneQuery,
  ) {}

  getCard(id: string): void {
    this.cardService.getCard(id);
  }

  getCards(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDriverDeviceList(projectKey);
  }

  getScenes(projectKey: string): void {
    this.sceneService.getScenes(projectKey);
  }

  upsertCard(card: DOMAIN.CardInput): void {
    this.cardService.upsertCard(card);
  }

  close(): void {
    this.sidePanelService.close();
  }

  reset(): void {
    this.cardService.resetActive();
    this.sceneService.reset();
  }
}
