// D3 contains issues with the Y-values
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';

interface Data {
  start: Date;
  end: Date;
  state: string;
}

interface State {
  time: Date;
  state: string;
}

@Component({
  selector: 'summa-timing-diagram',
  template: '<div #container><svg #svg></svg></div>',
  styleUrls: ['./timing-diagram.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimingDiagramComponent implements OnChanges {
  @Input()
  public data: Data[] = [];

  @ViewChild('svg', { static: true }) svgRef!: ElementRef<SVGSVGElement>;
  private svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private zoomBehavior!: d3.ZoomBehavior<SVGSVGElement, unknown>;
  private xScale!: d3.ScaleTime<number, number>;
  private yScale!: d3.ScalePoint<string>;
  private xAxis!: d3.Axis<d3.NumberValue | Date>;
  private yAxis!: d3.Axis<string>;
  private gX!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private gY!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private line!: d3.Line<State>;
  private container!: HTMLElement;
  private width!: number;
  private height!: number;
  private margin!: { top: number; right: number; bottom: number; left: number };
  private innerWidth!: number;
  private innerHeight!: number;
  private dataPoints!: State[];

  ngOnInit() {
    const container = this.svgRef?.nativeElement?.parentElement;
    if (!container) return;

    this.container = container;
    this.width = this.container.clientWidth || 600;
    this.height = this.container.clientHeight || 200;
    this.margin = { top: 10, right: 20, bottom: 10, left: 30 };
    this.innerWidth = this.width - this.margin.left - this.margin.right;
    this.innerHeight = this.height - this.margin.top - this.margin.bottom;

    this.initChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Object.prototype.hasOwnProperty.call(changes, 'data') && this.data?.length > 0) {
      if (this.zoomBehavior) {
        const transform = d3.zoomIdentity.scale(1);
        this.svg.call(this.zoomBehavior.transform, transform);
      }

      this.drawChart();
    }
  }

  initChart() {
    this.svg = d3.select(this.svgRef.nativeElement).attr('width', this.width).attr('height', this.height);

    // create a tooltip
    d3.select(this.container)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('background-color', '#36455a')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '2px')
      .style('padding', '10px')
      .style('position', 'absolute');
  }

  drawChart() {
    this.svg.selectAll('*').remove();

    // Add x-axis
    const min = d3.min(this.data, (d: Data) => d.start);
    const max = d3.max(this.data, (d: Data) => d.end);
    if (!min || !max) return;

    this.xScale = d3.scaleTime().domain([min, max]).range([this.margin.left, this.innerWidth]);
    this.xAxis = d3.axisBottom(this.xScale);
    this.gX = this.svg.append('g').attr('transform', `translate(0, ${this.innerHeight})`).call(this.xAxis);

    // Add y-axis
    this.yScale = d3
      .scalePoint()
      .domain(['on', 'idle', 'off'])
      .range([this.margin.top, this.innerHeight - 5]);
    this.yAxis = d3.axisLeft(this.yScale);
    this.gY = this.svg.append('g').attr('transform', `translate(${this.margin.left}, 0)`).call(this.yAxis);

    this.line = d3
      .line<State>()
      .x((d) => this.xScale(d.time))
      .y((d) => this.yScale(d.state)!);

    this.dataPoints = this.data.flatMap((d) => [
      { time: d.start, state: d.state },
      { time: d.end, state: d.state },
    ]);

    this.svg
      .append('path')
      .datum(this.dataPoints)
      .attr('class', 'line')
      .attr('d', this.line)
      .attr('fill', 'none')
      .attr('stroke', '#cd702b')
      .attr('stroke-width', 1);

    // Add the points
    this.svg
      .append('g')
      .selectAll('dot')
      .data(this.data)
      .enter()
      .append('circle')
      .attr('class', 'myCircle')
      .attr('cx', (d) => this.xScale(d.start))
      .attr('cy', (d) => this.yScale(d.state)!)
      .attr('r', 2)
      .attr('stroke', '#cd702b')
      .attr('stroke-width', 1)
      .attr('fill', 'white')
      .attr('opacity', 0.8)
      .attr('cursor', 'pointer')
      .on('mouseover', (event) => {
        d3.select('.tooltip').style('opacity', 1);
        d3.select(event).style('stroke', 'black').style('opacity', 1);
      })
      .on('mousemove', (event, d) => {
        const pointer = d3.pointer(event);
        const formatTime = d3.timeFormat('%d-%m-%Y, %H:%M');
        d3.select('.tooltip')
          .html(`date: ${formatTime(d.start)}`)
          .style('top', `${pointer[1]}px`)
          .style('left', `${pointer[0] + 10}px`);
      })
      .on('mouseleave', (event) => {
        d3.select('.tooltip').style('opacity', 0);
        d3.select(event).style('stroke', '#cd702b').style('opacity', 0.8);
      });

    // max zoom is the ratio of the initial domain extent to the minimum
    // unit that you want to zoom to (1 minute == 1000*60 milliseconds)
    const zoomMax = (max.getTime() - min.getTime()) / (1000 * 60);
    const zoomMin = 1;

    // Enable zoom behavior
    this.zoomBehavior = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([zoomMin, zoomMax]) // Adjust the scale extent as needed
      .translateExtent([
        [1, 30],
        [this.width, this.height],
      ])
      .on('zoom', this.handleZoom.bind(this));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.svg.call(this.zoomBehavior as any);
  }

  private handleZoom = (event: d3.D3ZoomEvent<SVGElement, unknown>) => {
    const transform = event.transform as d3.ZoomTransform;
    const currentScale = transform.k;

    // Update the x and y scales based on the zoom event
    const newXScale = transform.rescaleX(this.xScale);
    const newYScale = this.yScale; // Assuming yScale remains unchanged

    // Update the x and y scales based on the zoom event
    this.xAxis.scale(newXScale);
    this.yAxis.scale(newYScale);
    this.gX.call(this.xAxis);
    this.gY.call(this.yAxis);

    this.line = d3
      .line<State>()
      .x((d) => newXScale(d.time))
      .y((d) => newYScale(d.state)!);

    // Update the line path and axes based on the zoom transform
    const data = this.dataPoints.filter((d) => {
      const dt = newXScale(d.time);
      return dt >= this.margin.left && dt <= this.innerWidth;
    });
    this.svg.select('.line').attr('d', this.line(data));

    // Redraw the points
    this.svg
      .selectAll('.myCircle')
      /* eslint-disable @typescript-eslint/no-explicit-any */
      .attr('cx', (d: any) => newXScale(d.start))
      .attr('cy', (d: any) => newYScale(d.state)!)
      .style('display', (d: any) => {
        // Filter out data points outside the x-axis domain
        const dt = newXScale(d.start);
        return dt >= this.margin.left && dt <= this.innerWidth ? 'inherit' : 'none';
      });
    /* eslint-enable @typescript-eslint/no-explicit-any */

    // change cursor
    this.svg.attr('cursor', currentScale <= 1 ? 'auto' : 'ew-resize');
  };
}
