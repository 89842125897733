import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS, DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ProjectSceneAddSandbox } from './project-scene-add.sandbox';

@Component({
  selector: 'summa-project-scene-add',
  templateUrl: './project-scene-add.page.html',
  styleUrls: ['./project-scene-add.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectSceneAddSandbox],
})
export class ProjectSceneAddPage implements OnInit, OnDestroy {
  dynamic = 'dynamic';
  default = 'default';
  smartLightDaylight = 'smart_light_daylight';

  destroy$ = new Subject();
  submit$ = new Subject();

  form: FormGroup;
  loading$ = new BehaviorSubject<boolean>(true);
  filteredSpaces$ = new ReplaySubject<{ value: string; label: string }[]>(1);
  spaceFilter: FormControl;
  spaces$ = new Subject<{ value: string; label: string }[]>();
  // intervals = [10, 30, 60, 120];
  dynamicServices = [this.smartLightDaylight];
  scene$ = new BehaviorSubject<DTO.Scene | null>(null);

  constructor(public sandbox: ProjectSceneAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleSpaces();
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
  }

  private handleSpaces(): void {
    // INITIALIZE SPACES
    this.spaceFilter = this.fb.control('');
    const spaces = Object.values(CONSTANTS.dynamicSceneSpace).map((space) => ({ value: space, label: space.replace(/_/g, ' ') }));
    this.spaces$.next(spaces);
    this.filteredSpaces$.next(spaces);

    // FILTER SPACES
    this.spaceFilter.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      const filterValue = value.toLowerCase();
      const filteredSpaces = spaces.filter((space) => space.label.toLowerCase().includes(filterValue));
      this.filteredSpaces$.next(filteredSpaces);
    });
  }

  private handleData(): void {
    this.sandbox.data$
      .pipe(withLatestFrom(this.sandbox.currentProjectDefaultSettings$), takeUntil(this.destroy$))
      .subscribe(([scene, defaultScene]) => {
        if (!scene) {
          const dimValue = defaultScene?.dimValue ?? 100;
          this.form = this.fb.group({
            projectKey: '',
            name: ['', Validators.required],
            description: ['', Validators.required],
            sortOrder: [0, Validators.required],
            default: this.fb.group({
              dimValue: [dimValue, Validators.required],
              cct: [defaultScene?.cct ?? null, Validators.required],
              duv: [defaultScene?.duv ?? null, Validators.required],
              use: ['cct'],
              x: [null],
              y: [null],
            }),
            sceneType: [this.default, Validators.required],
            dynamic: this.fb.group({
              service: [this.smartLightDaylight],
              space: [null],
              // geolocation: this.fb.group({
              //   latitude: [null],
              //   longitude: [null],
              //   timezone: [null],
              // }),
            }),
          });
          this.loading$.next(false);
          this.handleFormChanges();
          return;
        }
        this.sandbox.getScene(scene.id);
      });

    this.sandbox.scene$
      .pipe(filter(isNotNullOrUndefined), withLatestFrom(this.sandbox.currentProjectDefaultSettings$), takeUntil(this.destroy$))
      // eslint-disable-next-line sonarjs/cognitive-complexity
      .subscribe(([scene, defaultScene]) => {
        this.scene$.next(scene);

        const defaultDimValue = defaultScene?.dimValue ?? 100;
        const sceneType = scene.dynamic ? this.dynamic : this.default;

        const useDefault = !scene.dynamic;
        const useCCT = scene.default?.cct || scene.default?.duv;

        this.form = this.fb.group({
          id: scene.id,
          projectKey: scene.projectKey,
          name: [scene.name, Validators.required],
          description: [scene.description, Validators.required],
          sortOrder: [scene.sortOrder, Validators.required],
          default: this.fb.group({
            dimValue: [scene.default?.dimValue ?? defaultDimValue, Validators.required],
            use: [useCCT ? 'cct' : 'xy'],
            cct: [...(useCCT && useDefault ? [scene.default?.cct, Validators.required] : [null])],
            duv: [...(useCCT && useDefault ? [scene.default?.duv, Validators.required] : [null])],
            x: [...(!useCCT && useDefault ? [scene.default?.x, Validators.required] : [null])],
            y: [...(!useCCT && useDefault ? [scene.default?.y, Validators.required] : [null])],
          }),
          sceneType: [sceneType, Validators.required],
          dynamic: this.fb.group({
            service: [...(!useDefault ? [scene.dynamic.service || this.smartLightDaylight, Validators.required] : [null])],
            space: [...(!useDefault ? [scene.dynamic.space, Validators.required] : [null])],
            // geolocation: this.fb.group({
            //   latitude: [scene.dynamic?.geolocation?.latitude ?? null],
            //   longitude: [scene.dynamic?.geolocation?.longitude ?? null],
            //   timezone: [scene.dynamic?.geolocation?.timezone ?? null],
            // }),
          }),
        });
        this.loading$.next(false);
        this.handleFormChanges();
      });
  }

  private setValidation = (field: string, required: boolean, value = null) => {
    const control = this.form.get(field);
    if (required) control.setValidators([Validators.required]);
    else control.clearValidators();
    control.reset(value);
  };

  private handleFormChanges(): void {
    // DEFAULT CCT OR XY CHANGE
    this.form
      .get('default.use')
      .valueChanges.pipe(withLatestFrom(this.scene$), takeUntil(this.destroy$))
      .subscribe(([use, scene]) => {
        const choice = use === 'cct';
        this.setValidation('default.cct', choice, choice ? scene?.default?.cct : null);
        this.setValidation('default.duv', choice, choice ? scene?.default?.duv : null);
        this.setValidation('default.x', !choice, !choice ? scene?.default?.x : null);
        this.setValidation('default.y', !choice, !choice ? scene?.default?.y : null);
      });
    // DYNAMIC OR DEFAULT CHANGE
    this.form
      .get('sceneType')
      .valueChanges.pipe(withLatestFrom(this.scene$), takeUntil(this.destroy$))
      .subscribe(([type, scene]) => {
        const choice = type === this.dynamic;
        this.setValidation('default.dimValue', !choice, scene?.default?.dimValue);
        this.setValidation('default.cct', !choice, !choice ? scene?.default?.cct : null);
        this.setValidation('default.duv', !choice, !choice ? scene?.default?.duv : null);
        this.setValidation('default.x', false, !choice ? scene?.default?.x : null);
        this.setValidation('default.y', false, !choice ? scene?.default?.y : null);
        this.setValidation('dynamic.service', choice, choice ? scene?.dynamic?.service : this.smartLightDaylight);
        this.setValidation('dynamic.space', choice, choice ? scene?.dynamic.space : null);
      });
  }

  private handleSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.sandbox.project$), takeUntil(this.destroy$)).subscribe(([, project]) => {
      if (this.form.invalid) return;
      const form = this.form.value;
      const { use, ...defaultForm } = form.default;
      const dynamicForm = form.dynamic;
      // when all values within the geolocation group are null, we want to set the entire group to null
      if (isNotNullOrUndefined(project.geolocation)) {
        dynamicForm.geolocation = {
          longitude: project.geolocation?.longitude,
          latitude: project.geolocation?.latitude,
          timezone: project?.timezone,
        };
      }

      const sceneInput: DTO.SceneInput = {
        ...(form.id && { id: form.id }),
        projectKey: project.key,
        name: form.name,
        description: form.description,
        sortOrder: form.sortOrder,
        default: form.sceneType === this.default ? defaultForm : null,
        dynamic: form.sceneType === this.dynamic ? { ...dynamicForm, use_geolocation: true, minute_interval: 10, limit_latitude: true } : null,
      };
      this.sandbox.upsertScene(sceneInput);
    });
  }

  private handleSuccess(): void {
    this.sandbox.updateSceneState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (!state.isSuccessful) return;
      this.sandbox.close();
    });
  }
}
