import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { I18NextModule } from 'angular-i18next';
import { NotifierModule } from 'angular-notifier';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PERMISSIONS } from '@summa/models';
import { PermissionGuard, SharedAuthUtilModule, AuthGuard } from '@summa/shared/dashboard/util';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';
import { SharedUtilTranslationsModule } from '@summa/shared/util/translations';

import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { MarkdownModule } from 'ngx-markdown';

import { SharedUiFloorplanModule } from '@summa/shared/ui/floorplan';
import { ApplicationsComponent } from './applications/applications.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { CustomNotifierOptions } from './notification.config';
import { ShellPage } from './shell/shell.page';
import { AgreementsDialogComponent } from './agreements-dialog/agreements-dialog.component';
import { SummaPortalResellerModule } from '../reseller';
import { SummaPortalCustomerModule } from '../customer';
import { SummaPortalAuthModule } from '../auth';
import { SummaPortalDataAccessModule } from '../../data-access';
import { MyProfilePage } from '../auth/my-profile/my-profile.page';
import { environment } from '../../environments/environment';
import { SummaPortalPermissionModule } from '../../permission-access/summa-portal-permission.module';

@NgModule({
  imports: [
    CommonModule,
    I18NextModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatRadioModule,
    MatSnackBarModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatStepperModule,
    MatButtonModule,
    SharedUiDialogsModule,
    SharedUtilTranslationsModule.forRoot({ version: environment.app.version, useCacheBuster: true }),
    SharedUiHeadersModule,
    SharedAuthUtilModule,
    SummaPortalDataAccessModule,
    SummaPortalPermissionModule,
    FlexLayoutModule,
    SharedUiFloorplanModule,
    MarkdownModule.forRoot(),
    NotifierModule.withConfig(CustomNotifierOptions),
    RouterModule.forChild([
      { path: '', redirectTo: '/login', pathMatch: 'full' },
      { path: 'my-profile', component: MyProfilePage },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'applications', canActivate: [AuthGuard], component: ApplicationsComponent },
      {
        path: 'reseller',
        canActivate: [PermissionGuard],
        loadChildren: () => SummaPortalResellerModule,
        data: {
          tag: 'reseller-base',
          permissions: {
            application: { name: PERMISSIONS.applicationReseller, qualifiers: [PERMISSIONS.wildcardQualifier] },
            action: PERMISSIONS.actionRead,
          },
        },
      },
      {
        path: 'customer',
        canActivate: [PermissionGuard],
        loadChildren: () => SummaPortalCustomerModule,
        data: {
          tag: 'customer-base',
          permissions: {
            application: { name: PERMISSIONS.applicationCustomer, qualifiers: [PERMISSIONS.wildcardQualifier] },
            action: PERMISSIONS.actionRead,
          },
        },
      },
      { path: 'login', loadChildren: () => SummaPortalAuthModule },
    ]),
  ],
  declarations: [ShellPage, ForbiddenComponent, ApplicationsComponent, AgreementsDialogComponent],
  exports: [ShellPage],
})
export class SummaPortalShellModule {}
