import { Injectable } from '@angular/core';
import { ApplicationQuery, GatewayQuery, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectNotificationBarSandbox {
  project$ = this.projectQuery.selectActive();
  application$ = this.applicationQuery.selectActive();
  gateway$ = this.gatewayQuery.selectActive();

  constructor(private projectQuery: ProjectQuery, private applicationQuery: ApplicationQuery, private gatewayQuery: GatewayQuery) {}
}
