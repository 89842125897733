import { gql } from 'apollo-angular';

export const GetScenariosQuery = gql`
  query getScenarios($skip: Int, $take: Int, $projectKey: String) {
    getScenarios(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      name
      description
      icon
      sortOrder
      hidden
    }
  }
`;
