import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { CONSTANTS } from '@summa/models';

import { RolesToggleService } from '../services/roles-toggle.service';

@Directive({
  selector: '[summaRolesToggle]',
})
export class RolesToggleDirective implements OnInit {
  @Input('summaRolesToggle') requiredRoles: CONSTANTS.Roles[] = [];

  @Input() userRoles: CONSTANTS.Roles[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(private el: ElementRef<any>, private rolesToggleService: RolesToggleService) {}

  ngOnInit(): void {
    if (!this.requiredRoles || !this.userRoles || !this.rolesToggleService.hasRequiredRoles(this.requiredRoles, this.userRoles)) {
      this.el.nativeElement.hidden = true;
      this.el.nativeElement.style.display = 'none';
    }
  }
}
