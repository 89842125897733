import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { CONSTANTS, DOMAIN } from '@summa/models';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FloorplanStore } from './floorplan.store';

import { GetFloorplanQuery, GetFloorplansQuery, insertFloorplanAndFixtures, overrideFloorplan, RemoveFloorplanQuery } from './graphql';

@Injectable({ providedIn: 'root' })
export class FloorplanService {
  destroy$ = new Subject();
  constructor(private apollo: Apollo, private floorplanStore: FloorplanStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  reset(): void {
    this.floorplanStore.reset();
  }
  resetFloorplanUIState(): void {
    this.floorplanStore.resetUIState();
  }

  storeFloorplan(floorplan: DOMAIN.Floorplan): void {
    this.floorplanStore.upsert(floorplan.id, floorplan);
  }

  setActive(id: ID | null): void {
    this.floorplanStore.setActive(id);
  }

  resetActive(): void {
    this.floorplanStore.resetUIState();
    this.floorplanStore.setActive(null);
    this.floorplanStore.setError(null);
  }

  upsertFloorplan(floorplan: DOMAIN.FloorplanInput, fixtures: DOMAIN.FixtureInput[]): void {
    this.floorplanStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });
    try {
      this.apollo
        .mutate({ mutation: insertFloorplanAndFixtures, variables: { floorplanInput: floorplan, fixtureInputs: fixtures } })
        .subscribe((results) => {
          if (!results || results.errors || !results.data) {
            this.floorplanStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
            const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
            this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `name ${floorplan.name}` }));

            this.floorplanStore.updateUpsert({ errors: results?.errors, isLoading: false });
            return;
          }
          const data = results.data as { insertFloorplanAndFixtures: DOMAIN.Floorplan };
          this.floorplanStore.upsert(data.insertFloorplanAndFixtures.id, data.insertFloorplanAndFixtures);
          this.floorplanStore.setActive(data.insertFloorplanAndFixtures.id);

          this.floorplanStore.updateUpsert({ isSuccessful: true, isLoading: false });
          this.notifier.notify('success', this.i18next.transform('message:successful-added'));
        });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  overrideFloorplan(floorplan: DOMAIN.FloorplanInput, fixtures: DOMAIN.FixtureInput[], floorplanOverrideOptions: any) {
    this.floorplanStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });
    try {
      this.apollo
        .mutate({
          mutation: overrideFloorplan,
          variables: { floorplanInput: floorplan, fixtureInputs: fixtures, floorplanOverrides: floorplanOverrideOptions },
        })
        .subscribe((results) => {
          if (!results || results.errors || !results.data) {
            this.floorplanStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
            const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
            this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `name ${floorplan.name}` }));

            this.floorplanStore.updateUpsert({ errors: results?.errors, isLoading: false });
            return;
          }
          const data = results.data as { overrideFloorplan: DOMAIN.Floorplan };
          this.floorplanStore.upsert(data.overrideFloorplan.id, data.overrideFloorplan);
          this.floorplanStore.setActive(data.overrideFloorplan.id);

          this.floorplanStore.updateUpsert({ isSuccessful: true, isLoading: false });
          this.notifier.notify('success', this.i18next.transform('message:successful-added'));
        });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  getFloorplans(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetFloorplansQuery, variables: { projectKey } })
      .pipe(map((f: any) => f.data.getFloorplans as DOMAIN.Floorplan[]))
      .subscribe((floorplans) => {
        this.floorplanStore.upsertMany(floorplans);
      });
  }

  getFloorplan(id: string): void {
    this.apollo
      .subscribe({ query: GetFloorplanQuery, variables: { id } })
      .pipe(map((f: any) => f.data.getFloorplan as DOMAIN.Floorplan))
      .subscribe((floorplan) => {
        this.floorplanStore.upsert(floorplan.id, floorplan);
        this.floorplanStore.setActive(floorplan.id);
      });
  }

  removeFloorplan(id: string): void {
    this.floorplanStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: RemoveFloorplanQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.floorplanStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `floorplan ${id}` }));

        this.floorplanStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.floorplanStore.remove(id);

      // set success;
      this.floorplanStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
