<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="sandbox.floorplan$|async as floorplan">
  <ng-container header>
    <h1>{{ 'project:floorplans.floorplan-preview' | i18nextCap }}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{ project.key }}) {{ project.name}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <div class="dxf-container">
      <summa-floorplan-dxf-viewer [fixtures]="fixtures$|async" [file]="floorplan.fileLocation"></summa-floorplan-dxf-viewer>
    </div>
  </ng-container>

  <ng-container footer>
    <section [permission-check]="this.sandbox.updatePermission$ |async" class="buttons" fxLayout="row wrap" fxLayoutAlign="end" fxLayoutGap="0.5rem">
      <button mat-button type="button" (click)="sandbox.close()" class="button save" [routerLink]="[currentRoute, floorplan.id]">
        {{'reseller:common.view-details' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
