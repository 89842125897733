<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="scenario$ | async as scenario">
  <ng-container header>
    <h1><span class="highlight">{{'reseller:scenarios.preview.title-prefix' |i18nextCap}}: </span>{{ scenario.name }}</h1>
  </ng-container>

  <ng-container info>
    <div class="scenario-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.name' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scenario.name }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.description' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scenario.description }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.project-key' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scenario.projectKey }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.icon' | i18nextCap }}</div>
            <div class="content" fxFlex="80">
              <mat-icon svgIcon="{{ scenario.icon }}"></mat-icon>
            </div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{'common:dictionary.visibility' |i18nextCap}}</div>
            <div class="content" fxFlex="80">{{ scenario.hidden }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'reseller:scenarios.preview.properties.nr-of-cards' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scenario.entries.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container footer>
    <!-- buttons -->
  </ng-container>
</summa-side-panel-content>
