/** DRIVER */
export const driverPuck = 'puck';
export const driverTrack = 'track';
export const driverCompact = 'compact';
export const isCategoryDriver = (type: string): boolean => [driverTrack, driverCompact, driverPuck].includes(type);
export const keyIsCategoryDriver = (key: string): boolean => key && key.length > 0 && ['C', 'P', 'T'].includes(key[0]);

export const defaultSensorBaselineFrames = 10;

/** CONTROL */
export const driverDimmer = 'dimmer';
export const driverSwitch = 'switch';
export const driverRelay = 'relay';
export const isCategoryControl = (type: string): boolean => [driverDimmer, driverSwitch, driverRelay].includes(type);

/** ALL */
export const driverType = [driverPuck, driverTrack, driverCompact, driverDimmer, driverSwitch, driverRelay] as const;
export type DriverType = typeof driverType[number];
export const defaultDriverTypeId = 1;
export const defaultDriverCurrent = 0.36;

/* DRIVER STATE */
export const driverReplaced = 'replaced';
export const driverDefect = 'defect';
export const driverState = [driverDefect, driverReplaced] as const;
export type DriverState = typeof driverState[number];
