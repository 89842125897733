import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { FixtureLogState, FixtureLogStore } from './log.store';

@Injectable({
  providedIn: 'root',
})
export class FixtureLogQuery extends QueryEntity<FixtureLogState, DOMAIN.FixtureLog> {
  constructor(protected store: FixtureLogStore) {
    super(store);
  }
}
