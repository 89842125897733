import { gql } from 'apollo-angular';
import { ControlPanelFields } from './control-panel.gql-fragment';

export const findControlPanelQuery = gql`
  query findControlPanel($id: String!) {
    findControlPanel(id: $id) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;

export const findControlPanelsQuery = gql`
  query FindControlPanels($projectKey: String!, $take: Int, $skip: Int) {
    findControlPanels(projectKey: $projectKey, take: $take, skip: $skip) {
      result {
        id
        name
        projectKey
        description
      }
      meta {
        total
        page
        limit
        query
        sort {
          field
          order
        }
        filters {
          field
          value
        }
      }
    }
  }
`;

export const findLocalControlPanelsQuery = gql`
  query FindControlPanels($take: Int, $skip: Int) {
    findControlPanels(take: $take, skip: $skip) {
      id
      name
      projectKey
      description
    }
  }
`;
