import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { PortalAPI, UI } from '@summa/models';

export interface CurrentCheckState extends EntityState<PortalAPI.CurrentCheckResult, string>, ActiveState {
  ui: {
    change: UI.State;
  };
}

const initialUiState = {
  ui: {
    change: {
      isLoading: false,
      errors: null,
      isSuccessful: false,
    },
  },
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'current-checks', idKey: 'driverKey', resettable: true })
export class CurrentCheckStore extends EntityStore<CurrentCheckState> {
  constructor() {
    super(initialUiState);
  }

  resetUIState = () => this.update({ ...initialUiState });
  updateChange = (change: Partial<UI.State>) => this.update({ ui: { ...this.getValue().ui, change: { ...this.getValue().ui.change, ...change } } });
}
