<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$|async) ? 'project:installers.upsert.edit-title' :'project:installers.upsert.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.installer$ | async as installer">
        <label fxFlex="15" class="mr-1">{{'common:dictionary.project-name' | i18nextCap}}:</label>
        <div fxFlex="auto">{{ installer.project.name }}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form px-0">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.title' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
          <mat-error *ngIf="form.get('name').invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>

        <mat-slide-toggle [checked]="form.get('enabled').value" formControlName="enabled">
          {{ 'project:installers.enable' | i18nextCap }}
        </mat-slide-toggle>
      </section>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
