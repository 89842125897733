import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { PERMISSIONS } from '@summa/models';
import { flatten } from '@summa/shared/util/typescript';
import { AuthQuery, ProjectQuery, ProjectService, CardService, FixtureStateService, AuthenticationService } from '../../data-access';

@Injectable()
export class CustomerSandbox {
  currentUser$ = this.authQuery.selectActive();
  currentCustomerPermissions$ = this.currentUser$.pipe(
    map((user) => {
      const permissions = user?.permissions
        .filter((p) => p.application.name === PERMISSIONS.applicationCustomer)
        .map((p) => p.application.qualifiers);
      // first flatten [[]] =>  then filter unique values
      return flatten(permissions);
    }),
  );
  currentProject$ = this.projectQuery.selectActive();

  routeChanges$ = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

  constructor(
    private authQuery: AuthQuery,
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private cardService: CardService,
    private fixtureStateService: FixtureStateService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  resetActiveProject(): void {
    this.projectService.reset();
    this.cardService.reset();
    this.fixtureStateService.unsubscribe();
    this.fixtureStateService.reset();
  }

  logout(): void {
    this.authenticationService.logOut();
  }
}
