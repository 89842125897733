import { I18NextModule } from 'angular-i18next';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { TimezoneSelectorComponent } from './timezone-selector/timezone-selector.component';
import { SensorBaselineDialogComponent } from './sensor-baseline-dialog/sensor-baseline-dialog.component';
import { DriverTypeDialogComponent } from './driver-type-dialog';
import { DriverCurrentDialogComponent } from './driver-current-dialog';
import { DriverContributionDialogComponent } from './driver-contribution-dialog';
import { DriverTargetRippleDialogComponent } from './driver-target-ripple-dialog';
import { ReplaceDriverDialogComponent } from './replace-driver-dialog';

const components = [
  TimezoneSelectorComponent,
  SensorBaselineDialogComponent,
  DriverTypeDialogComponent,
  DriverCurrentDialogComponent,
  DriverContributionDialogComponent,
  DriverTargetRippleDialogComponent,
  ReplaceDriverDialogComponent,
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    I18NextModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class SummaComponentsModule {}
