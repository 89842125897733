import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';

import { FixtureLogsSubscription, GetFixtureLogs } from './graphql/logs.gql-subscription';
import { FixtureLogStore } from './log.store';

@Injectable({
  providedIn: 'root',
})
export class FixtureLogService {
  subscriptions: Subscription;

  constructor(private apollo: Apollo, private fixtureLogStore: FixtureLogStore) {}

  findAll(): void {
    this.apollo
      .subscribe({ query: GetFixtureLogs, variables: {} })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .pipe(map((d: any) => d.data.getFixtureLogs as DOMAIN.FixtureLog[]))
      .subscribe((logs) => this.fixtureLogStore.set(logs));
  }

  subscribe(): void {
    this.unsubscribe();
    this.subscriptions = this.apollo.subscribe({ query: FixtureLogsSubscription }).subscribe((log) => {
      const newLog = (log.data as { observeFixtureLogs: DOMAIN.FixtureLog }).observeFixtureLogs;
      this.fixtureLogStore.upsert(newLog.topic, newLog);
    });
  }

  unsubscribe(): void {
    this.subscriptions?.unsubscribe();
    this.fixtureLogStore.reset();
  }
}
