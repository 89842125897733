/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * usage example:
 * var a = ['a', 1, 'a', 2, '1'];
 * var unique = a.filter(onlyUnique);
 * @param value
 * @param index
 * @param self
 */
export function onlyUnique(value: any, index: number, self: any): any {
  return self.indexOf(value) === index;
}

// /**
//  * usage example:
//  * var a = ['a', 1, 'a', 2, '1'];
//  * var unique = a.filter(onlyUniqueObjects);
//  * @param value
//  * @param index
//  * @param self
//  */
export function onlyUniqueObjects(obj: any, index: number, self: any): any {
  return self.findIndex((item: any) => JSON.stringify(item) === JSON.stringify(obj)) === index;
}
