import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';

import { AuthState, AuthStore } from './auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthQuery extends QueryEntity<AuthState> {
  isLoading$: Observable<boolean>;
  error$: Observable<unknown>;
  isSuccessful$: Observable<boolean>;

  constructor(protected store: AuthStore) {
    super(store);

    this.isLoading$ = this.select((state) => state.loading);
    this.error$ = this.select((state) => state.error);
    this.isSuccessful$ = this.select((state) => state.isSuccessful);
  }
}
