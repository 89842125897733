import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { PermissionsDirective } from './directives/permissions.directive';
import { RolesToggleDirective } from './directives/roles-toggle.directive';
import { TokenInterceptor } from './services';

@NgModule({
  imports: [CommonModule],
  declarations: [RolesToggleDirective, PermissionsDirective],
  exports: [RolesToggleDirective, PermissionsDirective],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
})
export class SharedAuthUtilModule {}
