import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { NotifierService } from 'angular-notifier';
import { DOMAIN } from '@summa/models';

import { executeCommandQuery } from './graphql/command.gql-mutation';

@Injectable({
  providedIn: 'root',
})
export class CommandService {
  constructor(private apollo: Apollo, private notifier: NotifierService) {}

  executeCommand(command: DOMAIN.CommandInput): void {
    this.apollo.mutate({ mutation: executeCommandQuery, variables: { command } }).subscribe();
  }
}
