import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';

import { TrimValidator } from '@summa/shared/util/validators';
import { DirtyCheck, isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ResellerClientAddSandbox } from './reseller-client-add.sandbox';

@Component({
  selector: 'summa-reseller-client-add',
  templateUrl: './reseller-client-add.page.html',
  styleUrls: ['./reseller-client-add.page.scss'],
  providers: [ResellerClientAddSandbox],
})
export class ResellerClientAddPage implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject();
  destroy$ = new Subject();
  store$ = new BehaviorSubject(null);

  constructor(public sandbox: ResellerClientAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleRoute();
    this.handleData();
    this.handleSubmit();
    this.handleFormStore();
    this.handleIsDirty();
  }

  onSubmit(): void {
    if (!this.form.valid) return;

    this.sandbox.upsertClient(this.form.value);
  }

  private handleIsDirty(): void {
    this.form.valueChanges
      .pipe(filter(isNotNullOrUndefined), DirtyCheck(this.store$), takeUntil(this.destroy$))
      .subscribe(this.sandbox.setHasChanges.bind(this.sandbox));
  }

  private handleFormStore(): void {
    this.store$.pipe(takeUntil(this.destroy$)).subscribe((store) => {
      this.form = this.fb.group({
        ...(store?.id && { id: store.id }),
        name: store.name ? [{ value: store.name, disabled: true }, [Validators.required]] : ['', [Validators.required, TrimValidator]],
        contact: this.fb.group({
          name: [store.contact.name, []],
          email: [store.contact.email, []],
          phone: [store.contact.phone, []],
        }),
      });
    });
  }

  private handleSubmit(): void {
    this.submit$.pipe(withLatestFrom(this.sandbox.reseller$, this.sandbox.client$), takeUntil(this.destroy$)).subscribe(([, reseller, client]) => {
      if (this.form.invalid) return;

      const clientInfo = { ...this.form.value, resellerId: reseller.id, name: this.form.value.name?.trim() ?? client.name.trim() };
      this.sandbox.upsertClient(clientInfo);
    });
  }

  private handleRoute(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ resellerId }) => this.sandbox.getReseller(resellerId));
  }

  private handleData(): void {
    // get latest data
    this.sandbox.data$.pipe(takeUntil(this.destroy$)).subscribe((data: PortalAPI.Client) => {
      if (!data) {
        this.store$.next({
          name: '',
          contact: {
            name: '',
            email: '',
            phone: '',
          },
        });
        return;
      }

      this.sandbox.getClient(data.id);
    });

    // WHEN EDIT MODE:
    this.sandbox.client$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((client) => {
      this.store$.next({
        id: client.id,
        name: client.name,
        contact: {
          name: client.contact.name,
          email: client.contact.email,
          phone: client.contact.phone,
        },
      });
    });
  }
}
