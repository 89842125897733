<div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <div class="reseller-card auto p-3">
        <div class="header">
          {{'project:control-panels.overview.title' | i18nextCap}}
          <div class="header-tools">
            <div class="filter">
              <mat-form-field floatLabel="never">
                <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
              </mat-form-field>
            </div>
            <div class="create" title="{{'project:control-panels.tooltips.create' | i18nextCap}}" (click)="upsertPanel$.next(null)">
              <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
            </div>
          </div>
        </div>

        <mat-table [dataSource]="controlPanels" matSort>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'common:dictionary.name' | i18nextCap}}</mat-header-cell>
            <mat-cell class="highlight" *matCellDef="let controlPanel">{{ controlPanel.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'common:dictionary.description' | i18nextCap}}</mat-header-cell>
            <mat-cell *matCellDef="let controlPanel">{{ controlPanel.description }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let row">
              <div class="preview" [title]="'common:dictionary.preview' | i18nextCap" (click)="detailsPanel$.next(row) ; $event.stopPropagation() ">
                <mat-icon svgIcon="eye"></mat-icon>
              </div>

              <ng-container *ngIf="sandbox.currentUser$ | async as user">
                <div
                  [userPermissions]="user.permissions"
                  [summaPermissions]="controlPanelUpsertPermission"
                  class="edit ml-2"
                  [title]="'common:dictionary.edit' | i18nextCap"
                  (click)="upsertPanel$.next(row.id) ; $event.stopPropagation() "
                >
                  <mat-icon svgIcon="file-document-edit-outline"></mat-icon>
                </div>

                <div
                  [userPermissions]="user.permissions"
                  [summaPermissions]="controlPanelDeletePermission"
                  class="remove ml-2"
                  [title]="'common:dictionary.remove' | i18nextCap"
                  (click)="remove$.next(row) ; $event.stopPropagation() "
                >
                  <mat-icon svgIcon="file-document-box-remove-outline"></mat-icon>
                </div>
              </ng-container>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" [routerLink]="[row.id]"></mat-row>
          <div class="filter-notification" *matNoDataRow>
            {{'project:control-panels.overview.filter-notification' | i18nextCap}} "{{input.value}}"
          </div>
        </mat-table>
        <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
