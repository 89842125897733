<ng-container *ngFor="let entity of (sandbox.card$ | async).devices">
  <ng-container *ngIf="sandbox.fixtureStates$ | async as fixtureStates">
    <article fxLayout="row" fxLayoutAlign="start center" class="entity-row">
      <mat-icon
        class="mr-3"
        [class.light-on]="isActive(entity, fixtureStates)"
        [svgIcon]="isActive(entity, fixtureStates) ? 'lightbulb-outline' : 'lightbulb-off-outline'"
      ></mat-icon>
      <main fxFlex="auto">{{ entity?.driver.address }}{{ entity?.address ? ':' + entity?.address : '' }}</main>
      <mat-slide-toggle
        [checked]="isActive(entity, fixtureStates)"
        (change)="entityToggle$.next({ state: $event, entity: entity })"
      ></mat-slide-toggle>
    </article>
  </ng-container>
</ng-container>
