import { gql } from 'apollo-angular';

export const heatGridLogSubscription = gql`
  subscription observeHeatGridLogs($projects: [String!]!) {
    observeHeatGridLogs(projects: $projects) {
      project
      timestamp
      address
      sensors
    }
  }
`;
