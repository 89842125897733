import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { CONSTANTS, DOMAIN } from '@summa/models';
import { ProjectAutomationPreviewSandbox } from './project-automation-preview.sandbox';

@Component({
  selector: 'summa-project-automation-preview',
  templateUrl: './project-automation-preview.page.html',
  styleUrls: ['./project-automation-preview.page.scss'],
  providers: [ProjectAutomationPreviewSandbox],
})
export class ProjectAutomationPreviewPage implements OnDestroy {
  destroy$ = new Subject();
  motion = DOMAIN.automationMotion;
  typeTimer = DOMAIN.automationTimer;
  typeMotion = DOMAIN.automationMotion;
  groupTrigger = DOMAIN.automationMotionGroupTrigger;
  scenarioTrigger = DOMAIN.automationMotionScenarioTrigger;

  public getCustomDay(days: CONSTANTS.Days[]) {
    return CONSTANTS.getCustomDay(days);
  }

  public motionSettings(automation: DOMAIN.Automation): DOMAIN.AutomationMotionSettings {
    return automation.settings as DOMAIN.AutomationMotionSettings;
  }

  public timerSettings(automation: DOMAIN.Automation): DOMAIN.AutomationTimerSettings {
    return automation.settings as DOMAIN.AutomationTimerSettings;
  }

  constructor(public sandbox: ProjectAutomationPreviewSandbox) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
