export interface Invoice {
  id: string;
  reseller: string;
  content: InvoiceContent;
  sendDate?: number;
  timestamp: number;
}
export class InvoiceContent {
  id: string;
  reseller: string;
  projects: InvoiceContentProject[];
}

export class InvoiceContentProject {
  key: string;
  name: string;
  drivers: InvoiceContentDriver[];
}

export class InvoiceContentDriver {
  driverType: string;
  numberOfDrivers: number;
  details: InvoiceContentDetails[];
}

export class InvoiceContentDetails {
  driverKey: string;
  driverAddress: string;
  devices: InvoiceContentDevices[];
}

export class InvoiceContentDevices {
  numberOfDevices: number;
  deviceCategory: string;
  deviceType: string;
}
