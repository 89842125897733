<ng-container *ngIf="sandbox.driver$ | async as driver">
  <section fxLayout="column" fxLayoutGap="0.5rem" fxFlex class="content">
    <form *ngIf="form" [formGroup]="form" class="my-3">
      <!-- CHANNELS -->
      <section formArrayName="channels">
        <mat-accordion>
          <mat-expansion-panel
            *ngFor="let _ of channels.controls; index as channelIndex"
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header class="header">
              <mat-panel-title class="orange"
                ><strong> {{ channels.controls[channelIndex].get('address').value | uppercase }}</strong></mat-panel-title
              >
              <mat-panel-description>
                <small class="pr-1" *ngFor="let ac of getActions(channelIndex).controls; let last = last">
                  {{ ac.value?.action | uppercase }} <span class="pl-1" *ngIf="!last">|</span></small
                >
              </mat-panel-description>
            </mat-expansion-panel-header>
            <section [formGroupName]="channelIndex" fxLayout="column">
              <!-- ACTIONS -->
              <section formArrayName="actions">
                <section
                  class="action px-1"
                  [class.mt-2]="!first"
                  *ngFor="let actionControl of getActions(channelIndex).controls; index as actionIndex; let first = first"
                >
                  <section [formGroupName]="actionIndex" fxLayout="column">
                    <section fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
                      <mat-form-field fxFlex appearance="legacy">
                        <mat-select formControlName="action" [placeholder]="'common:dictionary.action' | i18nextCap">
                          <mat-option *ngFor="let type of actionTypes" [value]="type" [disabled]="actionAlreadyTaken(channelIndex, type)">
                            {{ type }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>

                      <mat-radio-group fxFlex formControlName="choice" aria-label="Select an option" class="mb-1">
                        <mat-radio-button class="mr-2" *ngFor="let choice of actionChoices" [value]="choice">{{ choice }} </mat-radio-button>
                      </mat-radio-group>
                      <div class="remove">
                        <mat-icon
                          (click)="removeAction(channelIndex, actionIndex)"
                          title="{{ 'project:drivers.preview.remove-action' | i18nextCap }}"
                          [svgIcon]="'common:icons.remove' | i18next"
                        ></mat-icon>
                      </div>
                    </section>
                    <!-- SCENARIO -->
                    <section *ngIf="actionControl.get('choice')?.value === 'scenario'" class="group-row">
                      <mat-form-field appearance="legacy">
                        <mat-select formControlName="scenarioId" [placeholder]="'common:dictionary.scenario' | i18nextCap">
                          <mat-option *ngFor="let scenario of sandbox.scenarios$ | async" [value]="scenario.id"> {{ scenario.name }} </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </section>
                    <!-- GROUP -->
                    <ng-container *ngIf="actionControl.get('choice')?.value === 'groups'">
                      <section formArrayName="groups">
                        <section class="group-row" *ngFor="let actionGroup of getGroups(channelIndex, actionIndex).controls; index as groupIndex">
                          <section fxLayout="row" fxLayoutGap="0.5rem" [formGroupName]="groupIndex">
                            <mat-form-field fxFlex appearance="legacy">
                              <mat-select formControlName="group" [placeholder]="'common:dictionary.group' | i18nextCap">
                                <mat-option *ngFor="let group of sandbox.groups$ | async" [value]="group"> {{ group.title }} </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field fxFlex appearance="legacy">
                              <mat-select
                                [disabled]="!actionGroup.get('group')"
                                formControlName="sceneId"
                                [placeholder]="'common:dictionary.scene' | i18nextCap"
                              >
                                <mat-option *ngFor="let scene of actionGroup.get('group')?.value?.scenes" [value]="scene.id">
                                  {{ scene.name }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <section class="remove mt-2">
                              <mat-icon
                                (click)="removeActionGroup(channelIndex, actionIndex, groupIndex)"
                                title="{{ 'project:drivers.preview.remove-group' | i18nextCap }}"
                                [svgIcon]="'common:icons.remove' | i18next"
                              ></mat-icon>
                            </section>
                          </section>
                        </section>
                      </section>
                      <div
                        (click)="addActionGroup(channelIndex, actionIndex)"
                        class="group-button p-2"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                        fxLayoutGap="0.5rem"
                      >
                        <mat-icon title="{{ 'project:drivers.preview.add-group' | i18nextCap }}" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
                        <div>{{ 'project:drivers.preview.add-group' | i18nextCap }}</div>
                      </div>
                    </ng-container>
                  </section>
                </section>
              </section>
              <div
                *ngIf="getActions(channelIndex).controls.length < actionTypes.length"
                (click)="addAction(channelIndex)"
                class="group-button p-2"
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="0.5rem"
              >
                <mat-icon title="{{ 'project:drivers.preview.add-action' | i18nextCap }}" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
                <div>{{ 'project:drivers.preview.add-action' | i18nextCap }}</div>
              </div>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </section>
    </form>
  </section>

  <section class="footer" [summaPermissions]="updatePermission" [userPermissions]="(this.sandbox.currentUser$ | async)?.permissions">
    <!-- buttons -->
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{ 'common:buttons.cancel' | i18nextCap }}</button>
      <button mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{ 'common:buttons.save' | i18nextCap }}
      </button>
    </section>
  </section>
</ng-container>
