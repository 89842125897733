import { Injectable } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { ControlStore } from './control.store';
import { removeControlQuery, upsertControlQuery } from './graphql/control.gql-mutation';
import { GetControlQuery, GetControlsQuery } from './graphql/control.gql-query';

@Injectable({
  providedIn: 'root',
})
export class ControlService {
  constructor(private apollo: Apollo, private controlStore: ControlStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.controlStore.reset();
  }

  resetActive(): void {
    this.controlStore.resetUIState();
    this.setActive(null);
  }

  setActive(id: string | null): void {
    this.controlStore.setActive(id);
  }

  getControl(id: string): any {
    this.apollo
      .subscribe({ query: GetControlQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getControl as DOMAIN.Control))
      .subscribe((control) => {
        this.controlStore.upsert(control.id, control);
        this.setActive(control?.id);
      });
  }

  getControls(projectKey: string): void {
    this.controlStore.reset();

    this.apollo
      .subscribe({ query: GetControlsQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getControls as DOMAIN.Control[]))
      .subscribe((controls) => this.controlStore.set(controls));
  }

  upsertControl(control: DOMAIN.ControlInput): void {
    this.controlStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertControlQuery, variables: { control } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control ${control.name}` }));

        this.controlStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertControl: DOMAIN.Control };
      this.controlStore.upsert(data.upsertControl.id, data.upsertControl);
      this.controlStore.setActive(data.upsertControl.id);

      // set success;
      this.controlStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  remove(id: string): void {
    this.controlStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: removeControlQuery, variables: { id } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.controlStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `control ${id}` }));

        this.controlStore.updateRemove({ errors: results?.errors, isLoading: false });
        return;
      }

      this.controlStore.remove(id);

      // set success;
      this.controlStore.updateRemove({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
    });
  }
}
