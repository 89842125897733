import { gql } from 'apollo-angular';
import { ControlPanelFields } from './control-panel.gql-fragment';

export const ControlPanelSubscription = gql`
  subscription observeControlPanel($id: String!) {
    observeControlPanel(id: $id) {
      ...ControlPanelFields
    }
  }
  ${ControlPanelFields}
`;
