import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LoadingModalData {
  title?: string;
  content: string;
  time: number;
}

@Component({
  selector: 'summa-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingDialogComponent implements OnInit {
  loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: LoadingModalData, private dialogRef: MatDialogRef<LoadingDialogComponent>) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
    }, this.dialogData.time);
  }
}
