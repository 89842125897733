<section [formGroup]="form" fxLayout="column">
  <mat-radio-group formControlName="choice" aria-label="Select an option" class="mb-1">
    <mat-radio-button class="mr-2" *ngFor="let choice of [choiceScenario, choiceGroups]" [value]="choice">
      {{ 'reseller:controls.add.' + choice | i18nextCap }}
    </mat-radio-button>
  </mat-radio-group>

  <!-- Scenario -->
  <ng-container *ngIf="form.value.choice === choiceScenario">
    <mat-form-field appearance="legacy">
      <mat-select formControlName="scenario" placeholder="{{ 'common:dictionary.scenario' | i18nextCap }} *">
        <mat-option *ngFor="let scenario of sandbox.scenarios$ | async" [value]="scenario.id"> {{ scenario.name }} </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <!-- Groups -->
  <ng-container *ngIf="form.value.choice === choiceGroups">
    <ng-container formArrayName="groups" *ngIf="sandbox.groups$ | async as groups">
      <section class="inner-card-entry" *ngFor="let __ of groupsFormArray.controls; index as groupIndex">
        <section *ngIf="groupsFormArray.controls.length > 1" class="remove mt-2">
          <mat-icon (click)="removeGroup(groupIndex)" title="Remove channel" [svgIcon]="'common:icons.remove-card' | i18next"></mat-icon>
        </section>
        <section fxLayout="column" [formGroupName]="groupIndex">
          <mat-form-field appearance="legacy">
            <mat-select formControlName="group" [placeholder]="'common:dictionary.card' | i18nextCap">
              <mat-option *ngFor="let group of groups" [value]="group.id"> {{ group.title }} </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-select
              [disabled]="getGroupScenes(groupIndex, groups).length === 0"
              formControlName="scene"
              [placeholder]="'common:dictionary.scene' | i18nextCap"
            >
              <mat-option *ngFor="let scene of getGroupScenes(groupIndex, groups)" [value]="scene.id">
                {{ scene.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
      </section>
    </ng-container>

    <mat-icon
      class="add mt-2"
      (click)="addGroup()"
      [title]="'reseller:controls.add.card-tooltip' | i18nextCap"
      [svgIcon]="'common:icons.add-card' | i18next"
    >
      {{ 'reseller:controls.add.card-text' | i18nextCap }}
    </mat-icon>
  </ng-container>
</section>
