import { Inject, Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map, startWith, withLatestFrom } from 'rxjs/operators';

import { DOMAIN, UI } from '@summa/models';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { QueryEntity } from '@datorama/akita';
import {
  CardQuery,
  CardService,
  CommandService,
  FixtureLogQuery,
  FixtureStateQuery,
  modelToCardInput,
  SceneQuery,
  SceneService,
} from '../../../data-access';
import { AUTHENTICATION_QUERY, AuthState } from '../../../dashboard.module';

@Injectable()
export class CustomerCardSandbox {
  params$: Observable<Params>;
  fixtureStates$: Observable<DOMAIN.FixtureState[]>;
  fixtureLogs$: Observable<DOMAIN.FixtureLog[]>;
  cards$: Observable<DOMAIN.Card[]>;
  card$: Observable<DOMAIN.Card>;
  sortedScenes$: Observable<DOMAIN.Scene[]>;
  cardLogs$: Observable<DOMAIN.FixtureState[]>;
  currentUser$: Observable<DOMAIN.User>;

  activeScene$: Observable<DOMAIN.Scene>;
  updateSceneState$: Observable<UI.State>;

  constructor(
    private cardQuery: CardQuery,
    private fixtureStateQuery: FixtureStateQuery,
    private fixtureLogQuery: FixtureLogQuery,
    private cardService: CardService,
    private commandService: CommandService,
    private route: RouterQuery,
    private sceneService: SceneService,
    private sceneQuery: SceneQuery,
    @Inject(AUTHENTICATION_QUERY) private authQuery: QueryEntity<AuthState>,
  ) {
    this.params$ = this.route.selectParams().pipe(startWith([]));
    this.fixtureStates$ = this.fixtureStateQuery.selectAll().pipe(filter(isNotNullOrUndefined));
    this.fixtureLogs$ = this.fixtureLogQuery.selectAll().pipe(filter(isNotNullOrUndefined));

    this.cards$ = this.cardQuery.selectAll();
    this.card$ = this.cardQuery.selectActive().pipe(filter(isNotNullOrUndefined));

    this.sortedScenes$ = this.card$.pipe(
      filter((cards) => isNotNullUndefinedOrEmpty(cards.scenes)),
      map((cards) => cards.scenes.slice().sort((a, b) => a.sortOrder - b.sortOrder)),
    );

    this.cardLogs$ = this.fixtureStates$.pipe(
      withLatestFrom(this.card$),
      map(([fixtureStates, card]) => fixtureStates.filter((log) => card.devices.some((device) => device?.driver?.address === log.topic))),
    );

    this.activeScene$ = this.sceneQuery.selectActive().pipe(filter(isNotNullOrUndefined));
    this.updateSceneState$ = this.sceneQuery.selectUpsertState$;

    this.currentUser$ = this.authQuery.selectActive();
  }

  getCard(id: string): void {
    this.cardService.getCard(id);
  }

  getCards(projectKey?: string, skip = 0, take = 50): void {
    this.cardService.getCards(projectKey, skip, take);
  }

  activateScene(cardId: string, sceneId: string): void {
    this.cardService.activateCardScene(cardId, sceneId);
  }

  setCardActive(cardId?: string): void {
    this.cardService.setCardActive(cardId);
  }

  updateCard(card: DOMAIN.Card): void {
    this.cardService.upsertCard(modelToCardInput(card));
  }

  upsertScene(sceneInput: DOMAIN.SceneInput): void {
    this.sceneService.upsertScene(sceneInput);
  }

  resetActiveScene(): void {
    this.sceneService.resetActive();
  }

  executeSceneCommand(card: DOMAIN.Card, projectKey: string, sceneId: string): void {
    const command: DOMAIN.CommandInput = { sceneId, projectKey, state: 'ON', cardId: card.id };
    this.commandService.executeCommand(command);
  }

  executeCardCommand(card: DOMAIN.Card, state: 'ON' | 'OFF', dimValue: number, projectKey?: string, cct?: number, duv?: number): void {
    const command: DOMAIN.CommandInput = {
      state,
      cct,
      duv,
      cardId: card.id,
      ...(projectKey && { projectKey }),
      ...(dimValue !== undefined && dimValue !== -1 && { dimValue }),
    };
    this.commandService.executeCommand(command);
  }

  executeCardColorCommand(card: DOMAIN.Card, state: 'ON' | 'OFF', projectKey: string, channels?: number[], dimValue?: number): void {
    const command: DOMAIN.CommandInput = {
      state,
      channels,
      cardId: card.id,
      ...(projectKey && { projectKey }),
      ...(dimValue !== undefined && dimValue !== -1 && { dimValue }),
    };
    this.commandService.executeCommand(command);
  }

  executeDeviceCommand(device: DOMAIN.Device, projectKey: string, state: 'ON' | 'OFF', sceneId?: string, dimValue?: number): void {
    const command: DOMAIN.CommandInput = {
      state,
      sceneId,
      deviceIds: [device.id],
      ...(projectKey && { projectKey }),
      ...(dimValue !== undefined && dimValue !== -1 && { dimValue }),
    };
    this.commandService.executeCommand(command);
  }

  // #region helpers
  public isCardTurnedOn(card: DOMAIN.Card, fixtureStates: DOMAIN.FixtureState[]): boolean {
    if (fixtureStates?.length === 0) return false;
    // function:
    const intensityCheck = (channel: DOMAIN.Channel): boolean => channel?.intensity > 0;

    return card.devices.some((device) => {
      const topicLog = fixtureStates.find((l) => l.topic === device.driver?.address);

      // specific:  N-Channel logic ( only check channel intensity )
      if (topicLog && topicLog.type === DOMAIN.deviceNChannel) {
        const channelLog = topicLog.channels.find((ch) => ch.id === device.address);
        return channelLog ? intensityCheck(channelLog) : false;
      }
      // default: fusionCob logic ( only checks on dimlevel)
      return topicLog && topicLog.dimLevel > 0;
    });
  }

  // #endregion
}
