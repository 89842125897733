<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.data$|async) ? 'reseller:resellers.edit.title' :'reseller:resellers.add.title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <!-- info -->
  </ng-container>
  <ng-container body>
    <form [formGroup]="form" class="form">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <section class="section" formGroupName="contact">
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.contact-name' | i18nextCap}}</mat-label>
            <input matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.email' | i18nextCap}}</mat-label>
            <input type="email" matInput formControlName="email" />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.phone' | i18nextCap}}</mat-label>
            <input matInput formControlName="phone" />
          </mat-form-field>
        </section>

        <section class="section" formGroupName="paymentInfo">
          <h2>Billing information</h2>
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.payment-account-name' | i18nextCap}}</mat-label>
            <input matInput formControlName="accountName" />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.iban' | i18nextCap}}</mat-label>
            <input matInput formControlName="iban" />
          </mat-form-field>
          <mat-form-field appearance="legacy">
            <mat-label>{{'common:dictionary.bic' | i18nextCap}}</mat-label>
            <input matInput formControlName="bic" />
          </mat-form-field>
        </section>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button [disabled]="form.invalid" mat-button type="button" (click)="submit$.next(null)" class="button save">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
