import { Injectable } from '@angular/core';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, map, Observable } from 'rxjs';

import { ControlPanelQuery, ControlPanelService } from '../../../data-access';

@Injectable()
export class ControlPanelPreviewSandbox {
  controlPanel$: Observable<DTO.ControlPanel>;

  constructor(private controlPanelQuery: ControlPanelQuery, private controlPanelService: ControlPanelService) {
    this.controlPanel$ = this.controlPanelQuery.selectActive().pipe(
      filter(isNotNullOrUndefined),
      map((controlPanel) => ({
        ...controlPanel,
        controls: controlPanel.controls
          .slice()
          .filter((c) => c.id !== 'all')
          .sort((a, b) => a.sort - b.sort),
        scenarios: controlPanel.scenarios
          .slice()
          .filter((s) => !s.hidden)
          .sort((a, b) => a.sortOrder - b.sortOrder),
      })),
    );
  }

  resetActive(): void {
    this.controlPanelService.setActive(null);
  }
}
