import { Observable, combineLatest, debounceTime, map, startWith } from 'rxjs';
import { isEqual } from 'lodash';

export function DirtyCheck<U>(source: Observable<U>) {
  return <T>(valueChanges: Observable<T>): Observable<boolean> => {
    return combineLatest([source, valueChanges]).pipe(
      debounceTime(300),
      map(([a, b]) => isEqual(a, b) === false),
      startWith(false),
    );
  };
}
