<ng-container *ngIf="this.sandbox.updatePermission$ | async as updatePermission">
  <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card lg p-3">
          <div class="header">
            {{'reseller:controls.overview.title' | i18nextCap}}
            <div class="header-tools">
              <div class="filter">
                <mat-form-field floatLabel="never">
                  <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                  <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
                </mat-form-field>
              </div>
              <div
                [permission-check]="updatePermission"
                class="create"
                title="{{'reseller:tooltips.create-control' | i18nextCap}}"
                (click)="upsertPanel$.next(null)"
              >
                <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
              </div>
            </div>
          </div>

          <mat-table [dataSource]="controls" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:controls.overview.table-headers.name' | i18nextCap}}</mat-header-cell>
              <mat-cell class="highlight" *matCellDef="let control">{{ control.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef mat-sort-header
                >{{'reseller:controls.overview.table-headers.description' | i18nextCap}}</mat-header-cell
              >
              <mat-cell *matCellDef="let control">{{ control.description }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:controls.overview.table-headers.type' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let control">
                <div>{{ control.type }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="address">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'reseller:controls.overview.table-headers.address' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let control">
                <div>{{ control.address }}</div>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let row">
                <div
                  [permission-check]="updatePermission"
                  class="edit"
                  [title]="'reseller:controls.overview.edit' | i18nextCap"
                  (click)="upsertPanel$.next(row) ; $event.stopPropagation() "
                >
                  <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
                </div>
                <div
                  [permission-check]="updatePermission"
                  class="remove ml-2"
                  [title]="'reseller:controls.overview.remove' | i18nextCap"
                  (click)="remove$.next(row) ; $event.stopPropagation() "
                >
                  <mat-icon [svgIcon]="'file-document-box-remove-outline'"></mat-icon>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" (click)="detailsPanel$.next(row)"></mat-row>
            <div class="filter-notification" *matNoDataRow>{{'reseller:controls.overview.filter-notification' | i18nextCap}} "{{input.value}}"</div>
          </mat-table>
          <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-container>
