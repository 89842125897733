import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { distinctUntilChanged } from 'rxjs/operators';

import { DriverQuery, DriverService, ProjectQuery, ProjectService } from '../../../../../data-access';

@Injectable()
export class ProjectDriverAddSandbox {
  project$ = this.projectQuery.selectActive();
  params$ = this.router.selectParams();
  errors$ = this.driverQuery.selectError();
  upsertState$ = this.driverQuery.selectUpsertState$.pipe(distinctUntilChanged());
  checkState$ = this.driverQuery.selectCheckState$.pipe(distinctUntilChanged());

  constructor(
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private router: RouterQuery,
  ) {}

  addToProject(driverKey: string, projectKey: string): void {
    this.driverService.addToProject(driverKey, projectKey);
  }

  reset(): void {
    this.driverService.resetDriverUIState();
  }
}
