import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { I18NextModule } from 'angular-i18next';
import { NotificationOverviewPage } from './notification-overview/notification-overview.page';
import { NotificationPreviewPage } from './notification-preview/notification-preview.page';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    I18NextModule,
    MatIconModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatFormFieldModule,
    SharedUiDialogsModule,
    RouterModule.forChild([{ path: '', component: NotificationOverviewPage, data: { breadcrumb: 'Notifications' } }]),
  ],
  declarations: [NotificationPreviewPage, NotificationOverviewPage],
})
export class SummaPortalNotificationsModule {}
