import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CONSTANTS } from '@summa/models';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RequiredIfValidator } from '@summa/shared/util/validators';
import { combineLatest, debounceTime, filter, map, Subject, takeUntil } from 'rxjs';

import { animate, style, transition, trigger } from '@angular/animations';
import { MyProfileSandbox } from './my-profile.sandbox';

@Component({
  templateUrl: './my-profile.page.html',
  styleUrls: ['./my-profile.page.scss'],
  providers: [MyProfileSandbox],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(':leave', [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MyProfilePage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submitPassword$ = new Subject<void>();
  submitLanguage$ = new Subject<void>();
  previousPage$ = new Subject<void>();

  languages = Object.values(CONSTANTS.Languages);

  changePasswordForm: FormGroup;
  changeLanguageForm: FormGroup;

  previousUrl = '';

  newPasswordCheckError$ = null;

  constructor(private fb: FormBuilder, public sandbox: MyProfileSandbox, private router: Router, private location: Location) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.getCurrentUser();
    this.initForms();
    this.handleSubmit();
    this.handlePrevious();
    this.handleCurrentUser();
  }

  private initForms() {
    this.changePasswordForm = this.fb.group({
      password_old: ['', [Validators.required]],
      password_new: ['', [Validators.required, Validators.minLength(6)]],
      password_check: [
        '',
        [RequiredIfValidator(() => this.changePasswordForm.get('password_new').value === this.changePasswordForm.get('password_check').value)],
      ],
    });

    this.newPasswordCheckError$ = combineLatest([
      this.changePasswordForm.get('password_new').valueChanges,
      this.changePasswordForm.get('password_check').valueChanges,
    ]).pipe(
      debounceTime(500),
      map(([password, confirm]) => {
        return password !== confirm && password !== '' && confirm !== '';
      }),
      takeUntil(this.destroy$),
    );
  }

  private handleCurrentUser(): void {
    this.sandbox.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.changeLanguageForm = this.fb.group({
        language: [user.language ?? CONSTANTS.Languages.EN, [Validators.required]],
      });
    });
  }

  logOut(): void {
    this.sandbox.logout();
    this.router.navigate(['/login']);
  }

  private handlePrevious(): void {
    this.previousPage$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.location.back();
    });
  }

  private handleSubmit(): void {
    this.submitPassword$
      .pipe(
        filter(() => this.changePasswordForm.valid),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const form = this.changePasswordForm.value;

        const userInfo = {
          password: form.password_old,
          newPassword: form.password_new,
        };

        this.sandbox.updateUser(userInfo);
      });

    this.submitLanguage$
      .pipe(
        filter(() => this.changeLanguageForm.valid),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const form = this.changeLanguageForm.value;

        const userInfo = {
          language: form.language,
        };

        this.sandbox.updateUser(userInfo);
      });
  }
}
