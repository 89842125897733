import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import {
  AuthQuery,
  ControlPanelQuery,
  ControlPanelService,
  ProjectQuery,
  ResellerQuery,
  ResellerService,
  UserQuery,
  UserService,
} from '../../../data-access';

@Injectable()
export class UserUpsertSandbox {
  controlPanels$: Observable<DTO.ControlPanel[]>;
  params$ = this.router.selectParams();
  application$ = this.router.selectData('application');
  data$ = this.sidePanelService.getData<DOMAIN.User>();
  user$ = this.userQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  upsertState$ = this.userQuery.selectUpsertState$;
  project$ = this.projectQuery.selectActive();
  reseller$ = this.resellerQuery.selectActive();
  currentUser$ = this.authQuery.selectActive();
  clients$ = this.reseller$.pipe(map((reseller) => reseller.clients));

  constructor(
    private authQuery: AuthQuery,
    private userService: UserService,
    private router: RouterQuery,
    private projectQuery: ProjectQuery,
    private resellerQuery: ResellerQuery,
    private resellerService: ResellerService,
    private controlPanelQuery: ControlPanelQuery,
    private controlPanelService: ControlPanelService,
    private sidePanelService: SidePanelService,
    private userQuery: UserQuery,
  ) {
    this.controlPanels$ = this.controlPanelQuery.selectAll();
  }

  createUser(userInput: DOMAIN.UserInput): void {
    this.userService.createUser(userInput);
  }

  updateUser(userInput: DOMAIN.UserInput): void {
    this.userService.updateUser(userInput);
  }

  addUserPermissions(userInput: DOMAIN.UserInput): void {
    this.userService.addUserPermissions(userInput);
  }

  getControlPanels(projectKey: string): void {
    this.controlPanelService.getControlPanels(projectKey);
  }

  getReseller(id: string): void {
    if (this.resellerQuery.getActiveId() === id) return;
    this.resellerService.getReseller(id);
  }

  getUser(id: string, resellerId?: string): void {
    this.userService.getUser(id, resellerId);
  }

  removeUser(id: string, resellerId?: string): void {
    this.userService.removeUser(id, resellerId);
  }

  reset(): void {
    this.userService.resetActive();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
