import { Portal } from '@angular/cdk/portal';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'summa-cursor-panel',
  templateUrl: './cursor-panel.component.html',
  styleUrls: ['./cursor-panel.component.scss'],
})
export class CursorPanelComponent implements OnInit {
  opened = false;
  onCloseFunc: (() => Promise<void>) | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentPortal: Portal<any>;

  ngOnInit(): void {
    this.opened = true;
  }

  async onClose(): Promise<void> {
    if (!this.onCloseFunc) {
      return;
    }

    await this.onCloseFunc();
  }

  animateAway(): Promise<boolean> {
    this.opened = false;

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.opened);
      }, 300);
    });
  }
}
