import { AbstractControl, Validators } from '@angular/forms';

/**
 *  Makes the field required if the predicate function returns true
 *
 *  EXAMPLE:
 *  this.myForm = this.fb.group({
 *    myCheckbox: [''],
 *    myEmailField: ['', [
 *      // <--- here we pass predicate arrow function
 *      requiredIfValidator(() => this.myForm.get('myCheckbox').value)
 *   ]]
 * })
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function RequiredIfValidator(predicate: Function) {
  return (formControl: AbstractControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}
