import { NgModule } from '@angular/core';

import { AUTHENTICATION_QUERY } from '@summa/dashboard';
import { AuthQuery } from '../data-access';
import { PermissionAccessDirective } from './directive';
import { GlobalClientPermissions, GlobalProjectPermissions } from './rules';

@NgModule({
  declarations: [PermissionAccessDirective],
  exports: [PermissionAccessDirective],
  providers: [{ provide: AUTHENTICATION_QUERY, useExisting: AuthQuery }, GlobalProjectPermissions, GlobalClientPermissions],
})
export class SummaPortalPermissionModule {}
