import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'apollo-link';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { TrimValidator } from '@summa/shared/util/validators';
import { ResellerLocationAddSandbox } from './reseller-location-add.sandbox';

@Component({
  selector: 'summa-reseller-location-add',
  templateUrl: './reseller-location-add.page.html',
  styleUrls: ['./reseller-location-add.page.scss'],
  providers: [ResellerLocationAddSandbox],
})
export class ResellerLocationAddPage implements OnInit, OnDestroy {
  form: FormGroup;
  submit$ = new Subject();
  destroy$ = new Subject();

  @Output() cancelled$: Observable<void>;

  constructor(public sandbox: ResellerLocationAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleParams();
    this.handleData();
    this.handleSubmit();
  }

  private handleSubmit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.submit$.pipe(withLatestFrom(this.sandbox.client$, this.sandbox.location$), takeUntil(this.destroy$)).subscribe(([_, client, location]) => {
      if (this.form.invalid) return;

      const locationInfo = { ...this.form.value, name: this.form.getRawValue().name.trim() ?? location.name.trim(), clientId: client.id };

      this.sandbox.upsertLocation(locationInfo);
    });
  }

  private handleParams(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ clientKey }) => this.sandbox.getClient(clientKey));
  }

  private handleData(): void {
    this.sandbox.data$.pipe(takeUntil(this.destroy$)).subscribe((location: PortalAPI.Location) => {
      if (!location) {
        this.form = this.fb.group({
          name: ['', [Validators.required, TrimValidator]],
          street: ['', [Validators.required]],
          number: [null, [Validators.required]],
          zipCode: ['', [Validators.required]],
          city: ['', [Validators.required]],
          state: ['', [Validators.required]],
          country: ['', [Validators.required]],
        });
        return;
      }

      this.sandbox.getLocation(location.id);
    });

    // WHEN EDIT MODE:
    this.sandbox.location$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((location: PortalAPI.Location) => {
      this.form = this.fb.group({
        id: location.id,
        name: [{ value: location.name, disabled: true }, [Validators.required]],
        street: [location.street, [Validators.required]],
        number: [location.number, [Validators.required]],
        zipCode: [location.zipCode, [Validators.required]],
        city: [location.city, [Validators.required]],
        state: [location.state, [Validators.required]],
        country: [location.country, [Validators.required]],
      });
    });
  }
}
