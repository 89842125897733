import { Injectable, OnDestroy } from '@angular/core';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { DriverStore } from './driver.store';
import {
  removeDriverQuery,
  removeFromProjectQuery,
  upsertDriverQuery,
  upsertDriversQuery,
  GetDriverQuery,
  GetDriversQuery,
  replaceDriverQuery,
  GetAssemblyDriverQuery,
  SyncDriversByProjectKeyQuery,
  addToProjectQuery,
  GetDriverListQuery,
  GetDriverOverviewQuery,
  GetDriverDeviceListQuery,
} from './graphql';

@Injectable({
  providedIn: 'root',
})
export class DriverService implements OnDestroy {
  destroy$ = new Subject();
  graphQlError = 'GraphQL error: ';
  somethingWentWrong = 'message:something-went-wrong';
  sucessfullAdded = 'message:successful-added';

  constructor(private apollo: Apollo, public driverStore: DriverStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  storeDriver(driver: DTO.Driver): void {
    this.driverStore.upsert(driver.key, driver);
  }

  reset(): void {
    this.driverStore.reset();
  }

  resetActive(): void {
    this.driverStore.resetUIState();
    this.driverStore.setActive(null);
    this.driverStore.setError(null);
  }

  setLoading(state: boolean): void {
    this.driverStore.setLoading(state);
  }

  setActive(key: string | null): void {
    this.driverStore.setActive(key);
  }

  getDriver(key: string, address: string = null): void {
    this.resetActive();
    this.driverStore.setLoading(true);

    this.apollo
      .watchQuery({ query: GetDriverQuery, variables: { key, address }, fetchPolicy: 'no-cache' })
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ data }: any) => {
          const driver = data.getDriver;
          this.driverStore.upsert(driver.key, driver);
          this.setActive(driver?.key);
          this.driverStore.setLoading(false);
        },
        // FIXME: fix and remove eslint disable
        // eslint-disable-next-line rxjs/no-implicit-any-catch, @typescript-eslint/no-explicit-any
        (error: any) => {
          const er = error.message.replace(this.graphQlError, '');
          this.notifier.notify('error', this.i18next.transform(`assembly:fusion.errors.${er}`));
          this.driverStore.setError(er);
          this.driverStore.setLoading(false);
        },
      );
  }

  checkDriver(key: string, address: string = null): void {
    this.driverStore.updateCheck({ isLoading: true });

    this.apollo
      .watchQuery({ query: GetAssemblyDriverQuery, variables: { key, address }, fetchPolicy: 'no-cache' })
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: ({ data }: any) => {
          const driver = data.getAssemblyDriver;
          this.driverStore.updateCheck({ entity: driver, isLoading: false });
        },
        // FIXME: fix and remove eslint disable
        // eslint-disable-next-line rxjs/no-implicit-any-catch, @typescript-eslint/no-explicit-any
        error: (error: any) => {
          const er = error.message.replace(this.graphQlError, '');
          this.notifier.notify('error', this.i18next.transform(`assembly:fusion.errors.${er}`));
          this.driverStore.updateCheck({ isLoading: false, errors: er });
        },
      });
  }

  replaceDriver(oldDriverKey: string, newDriverKey: string, replaceState: string): void {
    this.driverStore.updateReplace({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: replaceDriverQuery, variables: { oldDriverKey, newDriverKey, replaceState } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.driverStore.updateReplace({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { replaceDriver: DTO.Driver };
        this.driverStore.upsert(newDriverKey, data.replaceDriver);
        this.driverStore.remove(oldDriverKey);
        this.driverStore.updateReplace({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  getDriverList(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetDriverListQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getDrivers as DTO.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }

  getDriverDeviceList(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetDriverDeviceListQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getDrivers as DTO.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }

  getDriverOverview(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetDriverOverviewQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getDrivers as DTO.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }

  getDrivers(projectKey: string): void {
    this.apollo
      .subscribe({ query: GetDriversQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.getDrivers as DTO.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }

  syncDriversByProjectKey(projectKey: string): void {
    this.apollo
      .subscribe({ query: SyncDriversByProjectKeyQuery, variables: { projectKey } })
      .pipe(map((d: any) => d.data.syncDriversByProjectKey as DTO.Driver[]))
      .subscribe((drivers) => {
        this.driverStore.upsertMany(drivers);
      });
  }

  resetDriverUIState(): void {
    this.driverStore.resetUIState();
  }

  upsertDriver(driver: DTO.DriverInput, isNew?: boolean, message = this.sucessfullAdded): void {
    this.resetDriverUIState();
    this.driverStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertDriverQuery, variables: { driver, ...(isNew && { create: isNew }) } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.driverStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertDriver: DTO.Driver };
        this.driverStore.upsert(data.upsertDriver.key, data.upsertDriver);
        this.driverStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform(message));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  addToProject(key: string, projectKey: string): void {
    this.resetDriverUIState();
    this.driverStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: addToProjectQuery, variables: { key, projectKey } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.driverStore.updateUpsert({ errors: results?.errors, isLoading: false });
          const resultErrorMessage = results.errors[0]?.message?.split('::');
          const errorMessage = resultErrorMessage[0] && resultErrorMessage[0].length > 0 ? resultErrorMessage[0] : 'something-went-wrong';
          this.notifier.notify(
            'error',
            this.i18next.transform(`message:${errorMessage}`, {
              field: resultErrorMessage[1] ?? key,
              field2: resultErrorMessage[2] ?? '',
            }),
          );

          return;
        }

        const data = results.data as { addToProject: DTO.Driver };
        this.driverStore.upsert(data.addToProject.key, data.addToProject);
        this.driverStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform(this.sucessfullAdded));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }


  upsertDrivers(drivers: DTO.DriverInput[]): void {
    this.resetDriverUIState();
    this.driverStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: upsertDriversQuery, variables: { drivers } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.driverStore.setError(results?.errors ?? this.i18next.transform(this.somethingWentWrong));
          // set error:
          const errorMessage = results.errors[0]?.message ?? this.somethingWentWrong;
          this.notifier.notify('error', this.i18next.transform(errorMessage, { field: 'address' }));

          this.driverStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { upsertDrivers: DTO.Driver[] };
        this.driverStore.upsertMany(data.upsertDrivers);

        // set success:
        this.driverStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform(this.sucessfullAdded));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  removeDriverFromProject(key: string, projectKey: string): void {
    this.driverStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeFromProjectQuery, variables: { key, projectKey } }).subscribe((results) => {
        if (!results || results.errors || !results.data) {
          this.driverStore.updateUpsert({ errors: results?.errors, isLoading: false });
          return;
        }

        const data = results.data as { removeFromProject: DTO.Driver };
        this.driverStore.updateUpsert({ isSuccessful: true, isLoading: false });
        this.driverStore.remove(data.removeFromProject.key);
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  removeDriver(driver: DTO.Driver): void {
    this.driverStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeDriverQuery, variables: { key: driver.key } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.driverStore.setError(results.errors);
          this.driverStore.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        this.driverStore.remove(driver.key);
        this.driverStore.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform(this.somethingWentWrong));
    }
  }

  checkDriverAvailability(driverKey: string, notify = true): void {
    this.driverStore.updateCheck({ isAvailable: false, isLoading: true, entity: null, errors: null });

    this.apollo
      .watchQuery({ query: GetAssemblyDriverQuery, variables: { key: driverKey }, fetchPolicy: 'no-cache' })
      .valueChanges.pipe(first(), takeUntil(this.destroy$))
      .subscribe(
        ({ data }: any) => {
          const driver = data.getAssemblyDriver;
          // check available
          if (driver.projectKey !== '' && driver.projectKey != null) {
            if (notify) {
              this.notifier.notify(
                'error',
                this.i18next.transform('message:driver-already-added', {
                  field: driverKey,
                  field2: driver.projectKey,
                }),
              );
            }

            return this.driverStore.updateCheck({ isAvailable: false, isLoading: false, entity: driver, errors: { 'not-available': true } });
          }
          return this.driverStore.updateCheck({ isAvailable: true, isLoading: false, entity: driver });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (error: any) => {
          const er = error.message.replace(this.graphQlError, '');
          if (notify) this.notifier.notify('error', this.i18next.transform(`assembly:fusion.errors.${er}`));

          this.driverStore.updateCheck({ isAvailable: false, isLoading: false, entity: null, errors: er });
        },
      );
  }
}
