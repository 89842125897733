import { gql } from 'apollo-angular';

export const GetScenariosQuery = gql`
  query getScenarios($skip: Int, $take: Int, $projectKey: String) {
    getScenarios(skip: $skip, take: $take, projectKey: $projectKey) {
      id
      # projectKey
      name
      description
      icon
      hidden
      sortOrder
      # entries {
      #   cardId
      #   sceneId
      # }
    }
  }
`;

export const GetScenarioQuery = gql`
  query getScenario($id: String!) {
    getScenario(id: $id) {
      id
      projectKey
      name
      description
      icon
      hidden
      sortOrder
      entries {
        cardId
        sceneId
        state
      }
    }
  }
`;
