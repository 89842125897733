import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectDetailsScenarioWidgetSandbox } from './project-scenario-widget.sandbox';

@Component({
  selector: 'summa-project-scenario-widget',
  templateUrl: './project-scenario-widget.component.html',
  styleUrls: ['./project-scenario-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectDetailsScenarioWidgetSandbox],
})
export class ProjectScenarioWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  sync$ = new Subject();

  constructor(public sandbox: ProjectDetailsScenarioWidgetSandbox, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getScenarios(projectKey);
    });
  }
}
