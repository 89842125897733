<ng-container *ngIf="{remove: this.sandbox.deletePermission$ | async, create: this.sandbox.createPermission$ |async} as permissions">
  <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutGap="16px" class="w-100">
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card xl p-3">
          <!-- TABLE: HEADER -->
          <div class="header">
            {{'project:overview.title' | i18nextCap}}
            <div class="header-tools">
              <div class="filter">
                <mat-form-field floatLabel="never">
                  <mat-icon matPrefix [svgIcon]="'common:icons.search' | i18next"></mat-icon>
                  <input matInput type="search" class="search-input" (keyup)="filterData($event)" #input />
                </mat-form-field>
              </div>
              <div
                [permission-check]="permissions.create"
                class="create"
                title="{{'reseller:tooltips.add-project' | i18nextCap}}"
                [routerLink]="['add']"
              >
                <mat-icon [svgIcon]="'plus-circle-outline'"></mat-icon>
              </div>
            </div>
          </div>
          <!-- TABLE: BODY -->
          <mat-table [dataSource]="projects" matSort class="w-100">
            <ng-container matColumnDef="key">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'project:overview.table-headers.project-key' | i18nextCap}}</mat-header-cell>
              <mat-cell class="highlight" *matCellDef="let project">{{ project.key }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'project:overview.table-headers.project-name' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let project">{{ project.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="clientName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'project:overview.table-headers.client-name' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let project">{{ project.location.client.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="locationName">
              <mat-header-cell *matHeaderCellDef mat-sort-header> {{'project:overview.table-headers.location-name' | i18nextCap}}</mat-header-cell>
              <mat-cell *matCellDef="let project">{{ project.location.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef>{{'project:overview.table-headers.state' | i18nextCap}} </mat-header-cell>
              <mat-cell *matCellDef="let project">
                <ng-container [ngSwitch]="project.gateway?.state">
                  <div class="badge success" *ngSwitchCase="'ON'">{{ 'common:dictionary.states.online' | i18next }}</div>
                  <div class="badge danger" *ngSwitchCase="'OFF'">{{ 'common:dictionary.states.offline' | i18next }}</div>
                  <div class="badge unknown" *ngSwitchDefault>{{ 'common:dictionary.states.unknown' | i18next }}</div>
                </ng-container>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let row">
                <div
                  [permission-check]="permissions.remove"
                  class="remove ml-2"
                  [matTooltip]="'project:overview.remove-project' | i18nextCap"
                  [title]="'project:overview.remove-project' | i18nextCap"
                  (click)="remove$.next(row); $event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="'domain-remove'"></mat-icon>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" [routerLink]="[row.key]"></mat-row>
            <div class="filter-notification" *matNoDataRow>{{'project:overview.filter-notification' | i18nextCap}} "{{input.value}}"</div>
          </mat-table>
          <!-- TABLE: FOOTER -->
          <mat-paginator class="pagination" [pageSizeOptions]="[15, 25, 50]" showFirstLastButtons></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</ng-container>
