import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS } from '@summa/portal/models/constants';
import { TrimValidator } from '@summa/shared/util/validators';
import { generateKey, isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { PortalAPI } from '@summa/models';
import { ProjectAddSandbox } from './project-add.sandbox';

@Component({
  selector: 'summa-project-add',
  templateUrl: './project-add.page.html',
  styleUrls: ['./project-add.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ProjectAddSandbox],
})
export class ProjectAddPage implements OnInit, OnDestroy {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  generateKey$ = new Subject<void>();

  clients$ = this.sandbox.clients$;
  presets$ = this.sandbox.presets$;
  locations$ = new Subject<PortalAPI.Location[]>();

  defaultSceneSettings = CONSTANTS.defaultSceneSettings;
  selectedClient$ = new Subject<PortalAPI.Client>();
  selectedLocation$ = new Subject<PortalAPI.Location>();

  projectForm: FormGroup;
  projectSectionCompleted = false;
  settingsSectionCompleted = false;
  sceneSettingsSectionCompleted = false;
  projectSettingsCompleted = false;
  step = 0;

  // Project information
  clientId = new FormControl('', [Validators.required]);
  locationId = new FormControl('', [Validators.required]);
  timezone = new FormControl('', [Validators.required]);
  name = new FormControl('', [Validators.required, TrimValidator]);
  key = new FormControl(null, [Validators.required]);
  apiKey = new FormControl(null, [Validators.required]);
  contractType = new FormControl();
  // geolocation
  latitude = new FormControl([null, [Validators.min(-90), Validators.max(90)]]);
  longitude = new FormControl(null, [Validators.min(-180), Validators.max(180)]);

  // Project settings
  wifiSsid = new FormControl('', [Validators.required]);
  wifiPassword = new FormControl('', [Validators.required]);

  // default Scene Settings
  dimValue = new FormControl(CONSTANTS.dimValueDefault, [
    Validators.required,
    Validators.min(CONSTANTS.dimValueMin),
    Validators.max(CONSTANTS.dimValueMax),
  ]);
  cct = new FormControl(CONSTANTS.cctDefault, [Validators.required, Validators.min(CONSTANTS.cctMin), Validators.max(CONSTANTS.cctMax)]);
  duv = new FormControl(CONSTANTS.duvDefault, [Validators.required, Validators.min(CONSTANTS.duvMin), Validators.max(CONSTANTS.duvMax)]);

  // Predictive Maintenance
  paymentInterval = new FormControl();

  destroy$ = new Subject();

  constructor(private formLocation: Location, private sandbox: ProjectAddSandbox, private fb: FormBuilder, private router: Router) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ resellerId }) => {
      this.sandbox.getReseller(resellerId);
    });

    // create projectForm
    this.projectForm = this.fb.group({
      clientId: this.clientId,
      locationId: this.locationId,
      name: this.name,
      key: this.key,
      apiKey: this.apiKey,
      timezone: this.timezone,
      geolocation: this.fb.group({
        longitude: this.longitude,
        latitude: this.latitude,
      }),
      settings: this.fb.group({
        wifiSsid: this.wifiSsid,
        wifiPassword: this.wifiPassword,
      }),
      defaultSceneSettings: this.fb.group({
        dimValue: this.dimValue,
        cct: this.cct,
        duv: this.duv,
      }),
    });

    this.handleQueryParams();
    this.handleFormChange();
    this.handleUpsertChange();
    this.handleGenerateKey();
  }

  cancel(): void {
    this.formLocation.back();
  }

  setStep(index: number): void {
    this.step = index;
  }

  nextStep(): void {
    this.step += 1;
  }

  prevStep(): void {
    this.step -= 1;
  }

  onSubmit(): void {
    if (!this.projectForm.valid) return;

    const { clientId, ...project } = this.projectForm.value;
    project.name = project.name.trim();
    this.sandbox.upsertProject(project);
  }

  private handleQueryParams(): void {
    this.sandbox.queryParams$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe(({ clientId, locationId }) => {
      if (clientId) {
        this.projectForm.get('clientId').setValue(clientId);
      }

      if (locationId) {
        this.projectForm.get('locationId').setValue(locationId);
      }
    });
  }

  private handleFormChange(): void {
    this.projectForm.valueChanges.pipe(withLatestFrom(this.clients$, this.presets$), takeUntil(this.destroy$)).subscribe(([form, clients]) => {
      if (form.clientId && isNotNullUndefinedOrEmpty(clients)) {
        this.locations$.next(clients.find((c) => c.id === form.clientId).locations);
        this.selectedClient$.next(clients.find((c) => c.id === form.clientId));
      }

      if (form.clientId && form.locationId && isNotNullUndefinedOrEmpty(clients)) {
        const locations = clients.find((c) => c.id === form.clientId)?.locations;
        this.selectedLocation$.next(locations.find((l) => l.id === form.locationId));
      }

      this.sceneSettingsSectionCompleted =
        isNotNullOrUndefined(form.defaultSceneSettings.dimValue) &&
        form.defaultSceneSettings.dimValue >= this.defaultSceneSettings.dimValueMin &&
        form.defaultSceneSettings.dimValue <= this.defaultSceneSettings.dimValueMax &&
        isNotNullOrUndefined(form.defaultSceneSettings.cct) &&
        form.defaultSceneSettings.cct >= this.defaultSceneSettings.cctMin &&
        form.defaultSceneSettings.cct <= this.defaultSceneSettings.cctMax &&
        isNotNullOrUndefined(form.defaultSceneSettings.duv) &&
        form.defaultSceneSettings.duv >= this.defaultSceneSettings.duvMin &&
        form.defaultSceneSettings.duv <= this.defaultSceneSettings.duvMax;
      this.projectSectionCompleted = form.clientId && form.locationId && form.name;
      this.settingsSectionCompleted = !!(form.settings.wifiSsid && form.settings.wifiPassword);

      // Validate if form project information is complete and correct
      this.projectSettingsCompleted = this.projectSectionCompleted && this.settingsSectionCompleted && this.sceneSettingsSectionCompleted;
    });
  }

  private handleUpsertChange(): void {
    this.sandbox.upsertState$
      .pipe(withLatestFrom(this.sandbox.projectKey$, this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([state, projectKey, params]) => {
        if (state.isSuccessful) {
          this.router.navigate(['reseller', params.resellerId, 'projects', projectKey]);
        }
      });
  }

  private handleGenerateKey(): void {
    this.generateKey$.pipe(withLatestFrom(this.clients$), takeUntil(this.destroy$)).subscribe(([, clients]) => {
      const client = clients.find((c) => c.id === this.clientId.value);
      const location = client?.locations.find((l) => l.id === this.locationId.value).name;

      this.key.setValue(this.newProjectKey(client.name, location, this.name.value).toLowerCase());
      this.apiKey.setValue(this.newApiKey(this.key.value).toLowerCase());
    });
  }

  private newProjectKey(client: string, location: string, name: string): string {
    const clientPart = client.replace(/[^a-zA-Z0-9]/g, '').substring(0, 2);
    const locationPart = location.replace(/[^a-zA-Z0-9]/g, '').substring(0, 2);
    const projectPart = name.replace(/[^a-zA-Z0-9]/g, '').substring(0, 2);
    const prefix = clientPart + locationPart + projectPart;
    return generateKey(prefix, '-xxxxxx');
  }

  private newApiKey(key: string): string {
    return generateKey(key, '-xxyxyx-xyxyxx');
  }
}
