import { Component, OnDestroy, OnInit } from '@angular/core';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { DTO } from '@summa/portal/models/dto';
import { ProjectInfoWidgetSandbox } from './project-info-widget.sandbox';
import { ProjectEditPage } from '../../project-edit/project-edit.page';

@Component({
  selector: 'summa-project-info-widget',
  templateUrl: './project-info-widget.component.html',
  styleUrls: ['./project-info-widget.component.scss'],
  providers: [ProjectInfoWidgetSandbox],
})
export class ProjectInfoWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  upsertProjectPanel$ = new Subject<DTO.Project>();
  updateLocal$ = new Subject<void>();
  openDashboard$ = new Subject<void>();

  constructor(public sandbox: ProjectInfoWidgetSandbox, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleOpenDashboard();
    this.handleUpsertProjectPanel();
    this.handleUpdateProject();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleUpsertProjectPanel(): void {
    this.upsertProjectPanel$.pipe(takeUntil(this.destroy$)).subscribe((project: DTO.Project) => {
      this.sidePanelService.open(ProjectEditPage, project);
    });
  }

  private handleUpdateProject(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.updateLocal$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([_, params]) => {
      this.sandbox.updateLocalProject(params.projectKey);
    });
  }

  private handleOpenDashboard(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.openDashboard$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([_, params]) => {
      window.open(`/customer/${params.projectKey}`, '_blank');
    });
  }
}
