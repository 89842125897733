import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { I18NextModule } from 'angular-i18next';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BreadcrumbModule, BreadcrumbService } from 'xng-breadcrumb';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { QrCodeModule } from 'ng-qrcode';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedUiUploadModule } from '@summa/shared/ui/upload';
import { SharedUiFloorplanModule } from '@summa/shared/ui/floorplan';
import { SharedAuthUtilModule } from '@summa/shared/dashboard/util';
import { MatTooltipModule } from '@angular/material/tooltip';
import { first } from 'rxjs';
import { SharedUiDiagramsModule } from '@summa/shared/ui/diagrams';
import { ControlPanelModule, DashboardModule } from '@summa/dashboard';
import { SharedDashboardUiModule } from '@summa/shared/dashboard/ui';
import { ProjectAddPage } from './project-add/project-add.page';
import { ProjectControlPreviewPage } from './project-controls/project-control-preview/project-control-preview.page';
import { ProjectCardOverviewPage } from './project-cards/project-card-overview/project-card-overview.page';
import { ProjectSceneAddPage } from './project-scenes/project-scene-add/project-scene-add.page';
import { ProjectControlOverviewPage } from './project-controls/project-control-overview/project-control-overview.page';
import { ProjectAutomationPreviewPage } from './project-automations/project-automation-preview/project-automation-preview.page';
import { ProjectFloorplansDetailsPage } from './project-floorplans/project-floorplans-details/project-floorplans-details.page';
import { ProjectScenarioAddPage } from './project-scenarios/project-scenario-add/project-scenario-add.page';
import { ProjectAutomationDetailsPage } from './project-automations/project-automation-details/project-automation-details.page';
import { ProjectCardPreviewPage } from './project-cards/project-card-preview/project-card-preview.page';
import { ProjectScenePreviewPage } from './project-scenes/project-scene-preview/project-scene-preview.page';
import { ProjectScenarioPreviewPage } from './project-scenarios/project-scenario-preview/project-scenario-preview.page';
import { ProjectScenarioOverviewPage } from './project-scenarios/project-scenario-overview/project-scenario-overview.page';
import { ProjectAutomationOverviewPage } from './project-automations/project-automation-overview/project-automation-overview.page';
import { ProjectDriverAddComponent } from './project-drivers/overview/project-driver-add/project-driver-add.component';
import { ProjectSceneDetailsPage } from './project-scenes/project-scene-details/project-scene-details.page';
import { ProjectScenarioDetailsPage } from './project-scenarios/project-scenario-details/project-scenario-details.page';
import { ProjectSceneOverviewPage } from './project-scenes/project-scene-overview/project-scene-overview.page';
import { ProjectFloorplansPreviewPage } from './project-floorplans/project-floorplans-preview/project-floorplans-preview.page';
import { ProjectCardDetailsPage } from './project-cards/project-card-details/project-card-details.page';
import { ProjectAutomationAddPage } from './project-automations/project-automation-add/project-automation-add.page';
import { ProjectOverviewPage } from './project-overview/project-overview.page';
import { ProjectControlDetailsPage } from './project-controls/project-control-details/project-control-details.page';
import { ProjectFloorplansAddPage } from './project-floorplans/project-floorplans-add/project-floorplans-add.page';
import { ProjectFloorplansOverviewPage } from './project-floorplans/project-floorplans-overview/project-floorplans-overview.page';
import { ProjectDetailsPage } from './project-details/project-details.page';
import { ProjectCardAddPage } from './project-cards/project-card-add/project-card-add.page';
import { ProjectControlAddPage } from './project-controls/project-control-add/project-control-add.page';
import { ProjectDriverOverviewPage } from './project-drivers/overview/project-driver-overview.page';
import { ProjectEditPage } from './project-edit/project-edit.page';
import { Routes } from './project.routes';
import { ProjectCardWidgetComponent } from './project-details/project-card-widget/project-card-widget.component';
import { ProjectSceneWidgetComponent } from './project-details/project-scene-widget/project-scene-widget.component';
import { ProjectDriverWidgetComponent } from './project-details/project-driver-widget/project-driver-widget.component';
import { ProjectScenarioWidgetComponent } from './project-details/project-scenario-widget/project-scenario-widget.component';
import { ProjectFloorplanCardsComponent } from './project-floorplans/project-floorplans-details/project-floorplan-cards/project-floorplan-cards.component';
import { ProjectFloorplanDriversComponent } from './project-floorplans/project-floorplans-details/project-floorplan-drivers/project-floorplan-drivers.component';
import { ProjectFloorplanAdminComponent } from './project-floorplans/project-floorplans-details/project-floorplan-admin/project-floorplan-admin.component';
import { ProjectSandbox } from './project.sandbox';
import { ProjectInstallerOverviewPage } from './project-installers/project-installer-overview/project-installer-overview.page';
import { ProjectInstallerUpsertPage } from './project-installers/project-installer-upsert/project-installer-upsert.page';
import { ProjectInstallerWidgetComponent } from './project-details/project-installer-widget/project-installer-widget.component';
import { ProjectGatewayWidgetComponent } from './project-details/project-gateway-widget/project-gateway-widget.component';
import { ProjectInstallerDetailsPage } from './project-installers/project-installer-details/project-installer-details.page';
import { ProjectDriverControlSettingsTabComponent, ProjectDriverPreviewPage, ProjectDriverActionsTabComponent } from './project-drivers/preview';
import { SummaPortalNotificationsSharedModule } from '../notifications';
import { ProjectGatewayUpsertPage } from './project-gateway/project-gateway-upsert/project-gateway-upsert.page';
import { ProjectGatewayDetailsPage } from './project-gateway/project-gateway-details/project-gateway-details.page';
import { SummaComponentsModule } from '../components/components.module';
import { ProjectAutomationWidgetComponent } from './project-details/project-automation-widget/project-automation-widget.component';
import { ProjectDriverInfoTabComponent } from './project-drivers/preview/info-tab';
import { ReUploadFloorplanPage } from './project-floorplans/project-floorplans-details/project-floorplan-admin/re-upload-floorplan/re-upload-floorplan.page';
import { ControlCardsComponent } from './project-controls/project-control-add/control-cards/control-cards.component';
import { ProjectAutomationInfoTabComponent } from './project-automations/project-automation-preview/info-tab';
import { ProjectAutomationHistoryTabComponent } from './project-automations/project-automation-preview/history-tab';
import { SummaPortalPermissionModule } from '../../permission-access/summa-portal-permission.module';
import { ProjectInfoWidgetComponent } from './project-details/project-info-widget/project-info-widget.component';
import { ProjectNotificationBarComponent } from './project-details/project-notification-bar/project-notification-bar.component';

@NgModule({
  imports: [
    QrCodeModule,
    CommonModule,
    LayoutModule,
    FlexLayoutModule,
    FormsModule,
    SharedUiHeadersModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatTableModule,
    MatBadgeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    DragDropModule,
    I18NextModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    BreadcrumbModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSortModule,
    ReactiveFormsModule,
    SharedUiDialogsModule,
    SharedUiUploadModule,
    SharedUiFloorplanModule,
    SharedDashboardUiModule,
    ControlPanelModule,
    SharedAuthUtilModule,
    SummaPortalPermissionModule,
    SharedUiDiagramsModule,
    RouterModule.forChild(Routes),
    MatTooltipModule,
    MatListModule,
    SummaPortalNotificationsSharedModule,
    SummaComponentsModule,
    DashboardModule,
  ],
  declarations: [
    ProjectOverviewPage,
    ProjectDetailsPage,
    ProjectAddPage,
    ProjectDriverOverviewPage,
    ProjectScenarioOverviewPage,
    ProjectScenarioDetailsPage,
    ProjectCardOverviewPage,
    ProjectCardDetailsPage,
    ProjectControlOverviewPage,
    ProjectControlDetailsPage,
    ProjectSceneOverviewPage,
    ProjectSceneDetailsPage,
    ProjectAutomationOverviewPage,
    ProjectAutomationDetailsPage,
    ProjectAutomationPreviewPage,
    ProjectAutomationInfoTabComponent,
    ProjectAutomationHistoryTabComponent,
    ProjectCardPreviewPage,
    ProjectControlPreviewPage,
    ProjectScenarioPreviewPage,
    ProjectScenePreviewPage,
    ProjectDriverAddComponent,
    ProjectControlAddPage,
    ProjectCardAddPage,
    ProjectAutomationAddPage,
    ProjectScenarioAddPage,
    ProjectSceneAddPage,
    ProjectFloorplansOverviewPage,
    ProjectFloorplansAddPage,
    ProjectDriverPreviewPage,
    ProjectFloorplansDetailsPage,
    ProjectFloorplansPreviewPage,
    ProjectFloorplanAdminComponent,
    ProjectFloorplanCardsComponent,
    ProjectFloorplanDriversComponent,
    ProjectEditPage,
    ProjectNotificationBarComponent,
    ProjectInfoWidgetComponent,
    ProjectCardWidgetComponent,
    ProjectSceneWidgetComponent,
    ProjectScenarioWidgetComponent,
    ProjectDriverWidgetComponent,
    ProjectGatewayWidgetComponent,
    ProjectInstallerWidgetComponent,
    ProjectAutomationWidgetComponent,
    ProjectDriverInfoTabComponent,
    ProjectDriverActionsTabComponent,
    ProjectInstallerUpsertPage,
    ProjectInstallerDetailsPage,
    ProjectInstallerOverviewPage,
    ProjectDriverControlSettingsTabComponent,
    ProjectGatewayUpsertPage,
    ProjectGatewayDetailsPage,
    ReUploadFloorplanPage,
    ControlCardsComponent,
  ],
  providers: [ProjectSandbox],
})
export class SummaPortalProjectsModule {
  constructor(private sandbox: ProjectSandbox, private breadcrumbService: BreadcrumbService) {
    this.handleParams();
    this.handleProject();
  }

  private handleProject(): void {
    this.sandbox.project$.pipe(first()).subscribe((project) => this.breadcrumbService.set('@projectName', project.name));
  }

  private handleParams(): void {
    this.sandbox.params$.subscribe((params) => {
      if (params?.projectKey) {
        this.sandbox.getProject(params.projectKey);
      } else {
        this.sandbox.reset();
      }
    });
  }
}
