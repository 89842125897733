/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ControlPanelUpsertControlSandbox } from './control-panel-upsert-control.sandbox';

@Component({
  selector: 'control-panel-upsert-control',
  templateUrl: './control-panel-upsert-control.page.html',
  styleUrls: ['./control-panel-upsert-control.page.scss'],
  providers: [ControlPanelUpsertControlSandbox],
})
export class ControlPanelUpsertControlPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  submit$ = new Subject<FormGroup>();

  form?: FormGroup;
  entityChoices = ['group', 'device'];

  deviceFilter: FormControl;
  filteredDevices$: ReplaySubject<DTO.Device[]>;

  sceneFilter: FormControl;
  filteredScenes$: ReplaySubject<DTO.Scene[]>;

  groupFilter: FormControl;
  filteredGroups$: ReplaySubject<DOMAIN.Card[]>;

  get entities(): FormArray {
    return this.form?.get('entities') as FormArray;
  }

  constructor(private fb: FormBuilder, public sandbox: ControlPanelUpsertControlSandbox) {
    this.deviceFilter = this.fb.control('');
    this.filteredDevices$ = new ReplaySubject<DTO.Device[]>(1);

    this.sceneFilter = this.fb.control('');
    this.filteredScenes$ = new ReplaySubject<DTO.Scene[]>(1);

    this.groupFilter = this.fb.control('');
    this.filteredGroups$ = new ReplaySubject<DOMAIN.Card[]>(1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleData();
    this.handleSubmit();
    this.handleSuccess();
    this.handleDevices();
    this.handleScenes();
    this.handleGroups();
  }

  removeEntity(index: number): void {
    this.entities.removeAt(index);
  }

  addEntity(entity?: DTO.ControlPanelControlEntity): void {
    this.entities.push(this.createEntity(entity));
  }

  toggleSelectAll(options: any, selectOptions: any, selectAllValue: boolean): void {
    options.pipe(first(), takeUntil(this.destroy$)).subscribe((val: any) => {
      if (selectAllValue) {
        selectOptions.patchValue(val.map((i: any) => i.id));
      } else {
        selectOptions.patchValue([]);
      }
    });
  }

  private createEntity(entity?: DTO.ControlPanelControlEntity): FormGroup {
    return this.fb.group({
      group: [entity && entity.group ? entity.group.id : '', []],
      device: [entity && entity.device ? entity.device.id : '', []],
      choice: [entity && entity.device ? 'device' : 'group', [Validators.required]],
      scene: [entity && entity.scene ? entity.scene.id : '', [Validators.required]],
    });
  }

  private handleData(): void {
    this.sandbox.data$.pipe(first(), takeUntil(this.destroy$)).subscribe(({ control }) => {
      this.form = this.fb.group({
        id: [control?.id ?? undefined, []],
        name: [control?.name ?? '', [Validators.required]],
        sort: [control?.sort ?? 0, [Validators.required]],
        icon: [control?.icon ?? 'lightbulb-group-outline', [Validators.required]],
        entities:
          control?.entities && control.entities.length > 0
            ? this.fb.array(control.entities.map((e) => this.createEntity(e)))
            : this.fb.array([this.createEntity()]),
      });
    });
  }

  private handleDevices(): void {
    this.sandbox.devices$.pipe(takeUntil(this.destroy$)).subscribe((devices) => this.filteredDevices$.next(devices));

    this.deviceFilter.valueChanges.pipe(withLatestFrom(this.sandbox.devices$), takeUntil(this.destroy$)).subscribe(([search, devices]) => {
      if (!devices) {
        return;
      }
      if (!search) {
        this.filteredDevices$.next(devices);
        return;
      }

      this.filteredDevices$.next(devices.filter((device) => device.key.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleScenes(): void {
    this.sandbox.scenes$.pipe(takeUntil(this.destroy$)).subscribe((scenes) => this.filteredScenes$.next(scenes));

    this.sceneFilter.valueChanges.pipe(withLatestFrom(this.sandbox.scenes$), takeUntil(this.destroy$)).subscribe(([search, scenes]) => {
      if (!scenes) {
        return;
      }
      if (!search) {
        this.filteredScenes$.next(scenes);
        return;
      }

      this.filteredScenes$.next(scenes.filter((scene) => scene.name.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleGroups(): void {
    this.sandbox.groups$.pipe(takeUntil(this.destroy$)).subscribe((groups) => this.filteredGroups$.next(groups));

    this.groupFilter.valueChanges.pipe(withLatestFrom(this.sandbox.groups$), takeUntil(this.destroy$)).subscribe(([search, groups]) => {
      if (!groups) {
        return;
      }
      if (!search) {
        this.filteredGroups$.next(groups);
        return;
      }

      this.filteredGroups$.next(groups.filter((group) => group.title.toLowerCase().indexOf(search.toLowerCase()) > -1));
    });
  }

  private handleSubmit(): void {
    this.submit$
      .pipe(
        filter((form) => isNotNullOrUndefined(form) && form.valid),
        withLatestFrom(this.sandbox.params$),
        takeUntil(this.destroy$),
      )
      .subscribe(([form, params]) => {
        const formValues = form.value;
        const input: DTO.ControlPanelControlInput = {
          ...(formValues.id && { id: formValues.id }),
          name: formValues.name ?? '',
          sort: formValues.sort ?? 0,
          icon: formValues.icon ?? '',
          isActive: false,
          entities: formValues.entities.map((e: any) => ({
            group: e.choice === 'group' ? e.group : undefined,
            device: e.choice === 'device' ? e.device : undefined,
            scene: e.scene,
          })),
        };

        this.sandbox.patchControlPanel(params.controlPanelId, input);
      });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state: any) => state.isSuccessful && this.sandbox.close());
  }
}
