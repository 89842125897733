import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectGatewayDetailsSandbox } from './project-gateway-details.sandbox';

@Component({
  selector: 'summa-project-gateway-details',
  templateUrl: './project-gateway-details.page.html',
  styleUrls: ['./project-gateway-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectGatewayDetailsSandbox],
})
export class ProjectGatewayDetailsPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertScenePanel$ = new Subject();
  columnsToDisplay = ['name', 'version', 'type', 'modified'];

  constructor(public sandbox: ProjectGatewayDetailsSandbox, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
