/* eslint-disable */
export default (tuples) => {
  let state;
  const header = { extMin: {}, extMax: {} };

  tuples.forEach((tuple) => {
    const type = tuple[0];
    const value = tuple[1];

    switch (value) {
      case '$MEASUREMENT': {
        state = 'measurement';
        break;
      }
      case '$INSUNITS': {
        state = 'insUnits';
        break;
      }
      case '$EXTMIN':
        header.extMin = {};
        state = 'extMin';
        break;
      case '$EXTMAX':
        header.extMax = {};
        state = 'extMax';
        break;
      default:
        switch (state) {
          case 'extMin':
          case 'extMax': {
            switch (type) {
              case 10:
                header[state].x = value;
                break;
              case 20:
                header[state].y = value;
                break;
              case 30:
                header[state].z = value;
                state = undefined;
                break;
            }
            break;
          }
          case 'measurement':
          case 'insUnits': {
            if (type === 70) {
              header[state] = value;
              state = undefined;
              break;
            }
            break;
          }
        }
    }
  });

  return header;
};
