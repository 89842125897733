import { flatten } from '@nestjs/common';
import { DOMAIN, PortalAPI } from '@summa/models';

export const RESELLER_ROLES = (reseller: PortalAPI.Reseller): DOMAIN.ApplicationRole => {
  const locationIds = flatten(reseller.clients.map((client) => client.locations.map((l) => l.id)));
  const userPermissions = [
    `reseller:${reseller.id}:project:*:::read`,
    `reseller:${reseller.id}:project:*:::upsert`,
    `reseller:${reseller.id}:project:*:::delete`,

    `reseller:${reseller.id}:project:*:user-management:*:read`,
    `reseller:${reseller.id}:project:*:user-management:*:upsert`,
    `reseller:${reseller.id}:project:*:user-management:*:delete`,
    ...(locationIds.length > 0 ? locationIds.map((id) => `customer:${id}:project:*:::read`) : []),
  ];

  const adminPermissions = [
    ...userPermissions,
    `reseller:${reseller.id}:project:*:::create`,
    `reseller:${reseller.id}:client:*:::upsert`,
    `reseller:${reseller.id}:user-management:*:::read`,
    `reseller:${reseller.id}:user-management:*:::upsert`,
    `reseller:${reseller.id}:user-management:*:::delete`,
  ];

  return {
    application: 'reseller',
    roles: [
      {
        role: 'roles.reseller-admin',
        permissions: adminPermissions,
      },
      {
        role: 'roles.reseller-user',
        permissions: userPermissions,
      },
    ],
  };
};
