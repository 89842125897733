<h1 class="mb-0" mat-dialog-title *ngIf="dialogData.title">
  {{ dialogData.title }}
</h1>

<div mat-dialog-content class="sensor-baseline-modal__content">
  <p class="alert alert-secondary" [innerHTML]="dialogData.content"></p>
  <form *ngIf="form" [formGroup]="form" class="form w-100" (ngSubmit)="submit$.next()">
    <section fxLayout="column" formArrayName="channels">
      <ng-container *ngFor="let _ of channels.controls; index as channelIndex">
        <ng-container [formGroupName]="channelIndex">
          <mat-form-field appearance="legacy">
            <mat-label>{{ 'project:drivers.actions.settings.channel-value' | i18nextCap: { field: channelIndex + 1 } }}</mat-label>
            <input matInput type="number" formControlName="targetRipple" />
          </mat-form-field>
        </ng-container>
      </ng-container>
    </section>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>
    {{ dialogData.cancelButton }}
  </button>

  <button *ngIf="form" mat-button mat-raised-button [disabled]="form.invalid" color="primary" (click)="submit$.next()">
    {{ dialogData.primaryButton }}
  </button>
</div>
