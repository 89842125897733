export const applicationSumma = 'summa';
export const applicationReseller = 'reseller';
export const applicationProject = 'project';
export const applicationControlPanel = 'control-panel';
export const applicationCustomer = 'customer';
export const applicationFinance = 'finance';
export const applicationRnD = 'rnd';

export const applicationQualifierUserManagement = 'user-management';

export const permissionApplications = [
  applicationSumma,
  applicationProject,
  applicationReseller,
  applicationCustomer,
  applicationFinance,
  applicationRnD,
] as const;
export type PermissionApplications = typeof permissionApplications[number];
