<mat-form-field class="columns">
  <mat-label *ngIf="selectedFiles$ | async as selectedFiles; else newFile">{{ selectedFiles[0].name }}</mat-label>
  <ng-template #newFile>
    <mat-label>Choose file</mat-label>
  </ng-template>
  <input matInput disabled />
  <button mat-button matSuffix (click)="fileInput.click()">
    <mat-icon svgIcon="file-upload-outline"></mat-icon>
  </button>
  <input hidden (change)="onFileSelect($event.target)" #fileInput type="file" accept=".dxf" id="file" />
</mat-form-field>
