import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { Observable } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { ControlQuery, ControlService } from '../../../../data-access';
import { GlobalProjectPermissions } from '../../../../permission-access/rules';

@Injectable()
export class ProjectControlOverviewSandbox {
  controls$ = this.controlQuery.selectAll();
  params$ = this.router.selectParams();
  removeState$ = this.controlQuery.selectRemoveState$;
  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  constructor(
    private controlService: ControlService,
    private controlQuery: ControlQuery,
    private router: RouterQuery,
    private globalProjectPermission: GlobalProjectPermissions,
  ) {}

  getControls(projectKey: string): void {
    this.controlService.getControls(projectKey);
  }

  getControl(controlId: string): void {
    this.controlService.getControl(controlId);
  }

  removeControl(id: string): void {
    this.controlService.remove(id);
  }

  reset(): void {
    this.controlService.reset();
  }
}
