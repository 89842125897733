<ng-container *ngIf="{updatePermission: this.sandbox.updatePermission$ | async} as permissions">
  <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start">
    <div fxLayout="column" fxLayoutGap="16px">
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div
          class="reseller-card lg p-3"
          *ngIf="sandbox.project$ | async"
          fxLayout="column"
          fxLayoutGap="16px"
          fxFlex="100%"
          fxLayoutAlign="start start"
        >
          <section fxLayout="row" class="w-100">
            <h1 class="m-0" fxFlex="auto">{{ 'project:installers.title' | i18nextCap }}</h1>
            <div
              [permission-check]="permissions.updatePermission"
              class="create"
              title="{{'reseller:tooltips.create-card' | i18nextCap}}"
              (click)="upsertPanel$.next(null)"
            >
              <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
            </div>
          </section>

          <mat-table [dataSource]="installers" class="w-100" matSort>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'common:dictionary.name' | i18nextCap }}</mat-header-cell>
              <mat-cell *matCellDef="let installer">{{ installer.name }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="enabled">
              <mat-header-cell *matHeaderCellDef> {{'common:dictionary.enabled' | i18nextCap}} </mat-header-cell>
              <mat-cell *matCellDef="let installer">
                <mat-icon *ngIf="installer.enabled; else disabledInstaller" class="icon-green" svgIcon="check-circle"></mat-icon>

                <ng-template #disabledInstaller>
                  <mat-icon class="icon-red" svgIcon="close-circle"></mat-icon>
                </ng-template>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell fxLayout="row" fxLayoutAlign="end center" *matCellDef="let installer">
                <div
                  [permission-check]="permissions.updatePermission"
                  class="edit mr-2"
                  [title]="'common:buttons.edit' | i18nextCap"
                  (click)="upsertPanel$.next(installer); $event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="'account-edit-outline'"></mat-icon>
                </div>

                <div
                  [permission-check]="permissions.updatePermission"
                  class="remove"
                  [title]="'common:buttons.remove' | i18nextCap"
                  (click)="remove$.next(installer); $event.stopPropagation()"
                >
                  <mat-icon [svgIcon]="'account-remove-outline'"></mat-icon>
                </div>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
            <mat-row *matRowDef="let row; columns: columnsToDisplay" class="clickable" (click)="detailsPanel$.next(row)"></mat-row>
          </mat-table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
