import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, map, withLatestFrom } from 'rxjs/operators';

import { isNotNullOrUndefined, onlyUniqueObjects } from '@summa/shared/util/typescript';
import { PERMISSIONS } from '@summa/models';
import { AuthQuery, ClientQuery, ResellerQuery, ResellerService } from '../../../../data-access';
import { filterResellerProjectsBasedOnPermissions, permittedProjects } from '../../../../permission-access/helpers';

@Injectable()
export class ResellerClientOverviewSandbox {
  reseller$ = this.resellerQuery.selectActive();
  params$ = this.router.params;
  upsertClientState$ = this.clientQuery.selectUpsertState$;
  clients$ = this.reseller$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.authQuery.selectActive()),
    map(([reseller, user]) => {
      // show all clients when user has wildcard on projects
      const permissions = permittedProjects(user.permissions, reseller.id);
      if (permissions.includes(PERMISSIONS.wildcardQualifier)) {
        return reseller.clients;
      }

      return filterResellerProjectsBasedOnPermissions(reseller, user.permissions)
        .map((project) => project.location.client)
        .filter(onlyUniqueObjects);
    }),
  );

  constructor(
    private resellerService: ResellerService,
    private resellerQuery: ResellerQuery,
    private router: ActivatedRoute,
    private clientQuery: ClientQuery,
    private authQuery: AuthQuery,
  ) {}

  checkActive(id: string): void {
    if (this.resellerQuery.getActive()?.id !== id) this.getReseller(id);
  }

  getReseller(id: string): void {
    this.resellerService.getReseller(id);
  }
}
