import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { DOMAIN } from '@summa/models';
import { AuthQuery, DriverQuery, DriverService, FixtureStateQuery } from '../../../../data-access';

@Injectable()
export class ProjectDriverPreviewSandbox {
  currentUser$ = this.authQuery.selectActive<DOMAIN.User | undefined>();
  driver$ = this.driverQuery.selectActive();
  sortedCards$ = this.driver$.pipe(
    filter((driver) => isNotNullUndefinedOrEmpty(driver.cards)),
    map((driver) => driver.cards.slice().sort((a, b) => a.sortOrder - b.sortOrder)),
  );

  fixtureState$ = this.driver$.pipe(
    filter(isNotNullOrUndefined),
    mergeMap((driver) => this.fixtureStateQuery.selectEntity(driver.address)),
  );
  upsertState$ = this.driverQuery.selectUpsertState$.pipe(distinctUntilChanged());
  replaceState$ = this.driverQuery.selectReplaceState$.pipe(distinctUntilChanged());

  constructor(
    private driverQuery: DriverQuery,
    private driverService: DriverService,
    private fixtureStateQuery: FixtureStateQuery,
    private authQuery: AuthQuery,
  ) {}

  resetActive(): void {
    this.driverService.setActive(null);
  }
}
