import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { CONSTANTS } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ProjectDetailsCardWidgetSandbox } from './project-card-widget.sandbox';

@Component({
  selector: 'summa-project-card-widget',
  templateUrl: './project-card-widget.component.html',
  styleUrls: ['./project-card-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectDetailsCardWidgetSandbox],
})
export class ProjectCardWidgetComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();
  sync$ = new Subject();

  constructor(public sandbox: ProjectDetailsCardWidgetSandbox, private sidePanelService: SidePanelService) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getCards(projectKey);
    });

    this.handleSync();
  }

  private handleSync(): void {
    this.sync$
      .pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .subscribe(([_, params]) => this.sandbox.executeSync(params.projectKey, CONSTANTS.syncCards, 'full'));
  }
}
