import { Injectable } from '@angular/core';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { Observable } from 'rxjs';

import { DTO } from '@summa/portal/models/dto';
import { GatewayQuery } from '../../../../data-access';

@Injectable()
export class ProjectGatewayDetailsSandbox {
  data$ = this.sidePanelService.getData();
  gateway$ = this.gatewayQuery.selectActive() as Observable<DTO.Gateway>;

  constructor(private sidePanelService: SidePanelService, private gatewayQuery: GatewayQuery) {}

  close(): void {
    this.sidePanelService.close();
  }
}
