import { gql } from 'apollo-angular';

export const upsertAutomationQuery = gql`
  mutation upsertAutomation($automation: AutomationInput!) {
    upsertAutomation(automation: $automation) {
      id
      # projectKey
      name
      description
      type
      state
      scenario {
        id
        name
        description
        # icon
        # sortOrder
      }
      settings {
        triggers {
          days
          startTime
          endTime
        }
        sensorDrivers {
          id
          key
        }
        threshold
        presence {
          scenario {
            id
            name
          }
          group {
            id
            title
          }
          scene {
            id
            name
          }
          holdTime
          fadeInDuration
        }
        idle {
          brightness
          holdTime
          fadeInDuration
        }
      }
    }
  }
`;

export const removeAutomationQuery = gql`
  mutation removeAutomation($id: String!) {
    removeAutomation(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
