import { gql } from 'apollo-angular';

export const GetClientsQuery = gql`
  query getClients($resellerId: String!) {
    getClients(resellerId: $resellerId) {
      id
      name
      # reseller {
      #   id
      #   name
      # }
      contact {
        name
        email
        phone
      }
      locations {
        id
        name
        # street
        # number
        # zipCode
        # city
        # state
        # country
        projects {
          id
          name
          key
        }
      }
    }
  }
`;

export const GetClientQuery = gql`
  query getClient($id: String!) {
    getClient(id: $id) {
      id
      name
      reseller {
        id
        name
      }
      contact {
        name
        email
        phone
      }
      locations {
        id
        name
        street
        number
        zipCode
        city
        state
        country
        projects {
          id
          name
          key
        }
      }
    }
  }
`;
