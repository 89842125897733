<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.scenario$ | async) ? 'reseller:scenarios.add.edit-title':'reseller:scenarios.add.add-title')| i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{ project.key}}) {{ project.name}}</div>
      </div>
      <div *ngIf="sandbox.scenario$ | async as scenario">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{ scenario.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.description' | i18nextCap}}</mat-label>
          <input matInput formControlName="description" />
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.sort-order' | i18nextCap}}</mat-label>
          <input matInput type="number" formControlName="sortOrder" />
        </mat-form-field>
        <mat-checkbox class="mb-2" formControlName="hidden">{{'common:dictionary.visibility' | i18nextCap}}</mat-checkbox>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.icon' | i18nextCap}}</mat-label>
          <mat-icon matSuffix [svgIcon]="form.get('icon').value" class="mr-1"></mat-icon>
          <input matInput formControlName="icon" />
        </mat-form-field>
        <ng-container formArrayName="entries">
          <section class="w-100" *ngFor="let _ of entries.controls; index as i">
            <section class="card-entry p-4" [formGroupName]="i">
              <section *ngIf="entries.length > 1" class="remove mt-2">
                <mat-icon (click)="removeCard(i)" title="Remove channel" [svgIcon]="'common:icons.remove-card' | i18next"></mat-icon>
              </section>
              <section fxLayout="column">
                <!-- cards -->
                <mat-form-field appearance="legacy">
                  <mat-select formControlName="card" [placeholder]="'common:dictionary.card' | i18nextCap">
                    <mat-option>
                      <ngx-mat-select-search
                        [formControl]="cardFilter"
                        [placeholderLabel]="'reseller:cards.find-card' | i18nextCap"
                        [noEntriesFoundLabel]="'reseller:cards.no-search-result' | i18nextCap"
                      ></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let card of filteredCards$ | async" [value]="card"> {{card.title }} </mat-option>
                  </mat-select>
                </mat-form-field>
                <!-- scene -->
                <mat-form-field appearance="legacy">
                  <mat-select
                    [disabled]="!entries.controls[i].get('card')?.value"
                    formControlName="sceneId"
                    [placeholder]="'common:dictionary.scene' | i18nextCap"
                  >
                    <mat-option *ngFor="let scene of entries.controls[i].get('card')?.value.scenes" [value]="scene.id"> {{scene.name }} </mat-option>
                  </mat-select>
                </mat-form-field>
                <div>
                  <span class="mr-2">State</span>
                  <mat-checkbox class="mb-1" formControlName="state">{{'common:dictionary.on' | i18nextCap}} </mat-checkbox>
                </div>
              </section>
            </section>
          </section>
          <mat-icon class="add mt-2" (click)="addEntry()" title="Add new entry" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
        </ng-container>
      </section>
    </form>
  </ng-container>
  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="button" (click)="submit$.next(null)" class="button save">{{'common:buttons.save' | i18nextCap}}</button>
    </section>
  </ng-container>
</summa-side-panel-content>
