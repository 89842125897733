import { Injectable } from '@angular/core';
import { PortalAPI } from '@summa/models';

import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter } from 'rxjs';
import { AuthenticationService, AuthQuery } from '../../../data-access';

@Injectable()
export class MyProfileSandbox {
  currentUser$ = this.authQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  constructor(private authQuery: AuthQuery, private authService: AuthenticationService) {}

  getCurrentUser() {
    this.authService.getCurrentUser();
  }

  updateUser(userInput: PortalAPI.CurrentUserInput) {
    this.authService.updateCurrentUser(userInput);
  }

  logout(): void {
    this.authService.logOut();
  }
}
