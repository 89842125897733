import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { DOMAIN, ENV, PortalAPI } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { DTO } from '@summa/portal/models/dto';
import {
  CommandService,
  DeviceQuery,
  DeviceService,
  DriverQuery,
  DriverService,
  FixtureQuery,
  FixtureService,
  FloorplanQuery,
  FloorplanService,
  ProjectQuery,
  ProjectService,
} from '../../../../../data-access';

@Injectable()
export class ProjectFloorplanDriversComponentSandbox {
  project$ = this.projectQuery.selectActive();
  floorplans$ = this.floorplanQuery.selectAll();
  floorplan$ = this.floorplanQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  fixtures$ = this.fixtureQuery.selectAll();
  drivers$ = this.driverQuery.selectAll();
  devices$ = this.deviceQuery.selectAll();
  params$ = this.router.parent.params;
  removeState$ = this.floorplanQuery.selectRemoveState$;
  errors$ = this.driverQuery.selectError();
  upsertState$ = this.driverQuery.selectUpsertState$.pipe(distinctUntilChanged());
  checkState$ = this.driverQuery.selectCheckState$.pipe(distinctUntilChanged());

  driverLoader$ = this.driverQuery.selectLoading();

  constructor(
    private projectService: ProjectService,
    private projectQuery: ProjectQuery,
    private floorplanQuery: FloorplanQuery,
    private floorplanService: FloorplanService,
    private fixtureQuery: FixtureQuery,
    private fixtureService: FixtureService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private commandService: CommandService,
    private deviceService: DeviceService,
    private deviceQuery: DeviceQuery,
    private router: ActivatedRoute,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  getProject(projectKey: string): void {
    this.projectService.getProject(projectKey);
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDriverList(projectKey);
  }

  getDriver(driverKey: string): void {
    this.driverService.getDriver(driverKey);
  }

  activateDriver(key: string): void {
    this.driverService.setActive(key);
  }

  getFloorplan(floorplanId: string): void {
    this.floorplanService.setActive(floorplanId);
  }

  getFloorplans(projectKey: string): void {
    this.floorplanService.getFloorplans(projectKey);
  }

  getFixtures(floorplanId: string): void {
    this.fixtureService.getFixtures(floorplanId);
  }

  upsertFixture(fixture: DOMAIN.Fixture) {
    this.fixtureService.upsertFixture(fixture);
  }

  upsertFixtures(fixtures: DOMAIN.Fixture[]) {
    this.fixtureService.upsertFixtures(fixtures);
  }

  updateLocation(deviceKey: string, locationKey: string | null): void {
    this.deviceService.updateDeviceLocation(deviceKey, locationKey);
  }

  checkDriverAvailability(driverKey: string): void {
    this.driverService.checkDriverAvailability(driverKey);
  }

  upsertDriver(driver: DTO.DriverInput): void {
    this.driverService.upsertDriver(driver, false);
  }

  executeDeviceCommand(projectKey: string, device: DOMAIN.Device, state: 'ON' | 'OFF', dimValue?: number): void {
    const command: PortalAPI.CommandInput = { projectKey, state, deviceIds: [device.id], ...(dimValue && dimValue !== -1 && { dimValue }) };
    this.commandService.executeCommand(command);
  }

  resetDriverUIState(): void {
    this.driverService.resetDriverUIState();
  }
}
