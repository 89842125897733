import { PERMISSIONS, PortalAPI } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';

import { flatten, onlyUnique } from '@summa/shared/util/typescript';

export const filterResellerProjectsBasedOnPermissions = (reseller: PortalAPI.Reseller, userPermissions: PERMISSIONS.Permission[]): DTO.Project[] => {
  const projects = flatten(
    reseller.clients.map((client) =>
      client.locations.map((location) => location.projects.map((proj) => ({ ...proj, location: { ...location, client } }))),
    ),
  );

  if (!projects) return [];
  const permissions = permittedProjects(userPermissions, reseller.id);

  return projects.filter((proj) => permissions.some((p) => p === PERMISSIONS.wildcardQualifier || p === proj.key));
};

export const permittedProjects = (userPermissions: PERMISSIONS.Permission[], resellerId: string): string[] =>
  flatten(
    userPermissions
      .filter(
        (p) =>
          p.application.name === PERMISSIONS.applicationReseller &&
          p.application.qualifiers.some((q) => q === PERMISSIONS.wildcardQualifier || q === resellerId) &&
          p.domain.name === PERMISSIONS.domainProject,
      )
      .map((p) => p.domain.qualifiers),
  ).filter(onlyUnique);
