import { Inject, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { from, InMemoryCache, split } from '@apollo/client/core';

import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Router } from '@angular/router';
import { AUTHENTICATION_QUERY } from '@summa/dashboard';
import { AUTHENTICATION_SERVICE } from '@summa/shared/dashboard/ui';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ENV } from '@summa/models';
import { AuthQuery, AuthenticationService } from './auth';

@NgModule({
  imports: [HttpClientModule, ApolloModule],
  providers: [
    { provide: AUTHENTICATION_SERVICE, useExisting: AuthenticationService },
    { provide: AUTHENTICATION_QUERY, useExisting: AuthQuery },
  ],
})
export class SummaPortalDataAccessModule {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private router: Router,
    @Inject('environment') private environment: ENV.Environment,
  ) {
    if (!this.environment.api) return;

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line array-callback-return
        graphQLErrors.map(({ extensions }) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const message = extensions ? extensions.response || (extensions.exception as { response: any })?.response : undefined;
          if (message) {
            switch (message?.statusCode) {
              case 401:
                localStorage.removeItem(this.environment.token);
                this.router.navigate(['/login']);
                break;
              case 403:
                this.router.navigate(['/forbidden'], {
                  state: { errorMessage: `${message.message}` },
                });
                break;
              default:
                // DO NOTHING
                break;
            }
          } else if (!environment.production) {
            // eslint-disable-next-line no-console
            console.error('Error-link', extensions);
          }
        });
        if (networkError) {
          // TODO: TEST NETWORK ERROR HANDLER
          // eslint-disable-next-line no-console
          console.error(`[Network error]: ${networkError.toString()}`);
        }
      }
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const mainDefinition = getMainDefinition(query);
        return mainDefinition.kind === 'OperationDefinition' && mainDefinition?.operation === 'subscription';
      },
      new WebSocketLink({
        uri: this.environment.api.webSocketHost,
        options: {
          reconnect: true,
        },
      }),
      this.httpLink.create({
        uri: this.environment.api.httpHost,
      }),
    );

    // create Apollo
    this.apollo.createDefault({
      link: from([errorLink, link]),
      cache: new InMemoryCache({ addTypename: false }),
      defaultOptions: {
        // query: {
        //   fetchPolicy: 'network-only',
        //   errorPolicy: 'all',
        // },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
  }
}
