import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSlider } from '@angular/material/slider';
import { debounceTime, Subject, takeUntil } from 'rxjs';

import { CursorPanelService } from '@summa/shared/ui/dialogs';

@Component({
  selector: 'summa-light-dimmer',
  templateUrl: './light-dimmer.component.html',
  styleUrls: ['./light-dimmer.component.scss'],
})
export class LightDimmerComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject();
  dimValue: number;
  disabled = false;
  sliderChanged$ = new Subject<number>();

  @ViewChild('matSlider') matSlider: MatSlider;

  constructor(private cursorPanelService: CursorPanelService) {
    this.handleDimValue();
  }

  ngOnInit(): void {
    this.sliderChanged$.pipe(debounceTime(500)).subscribe((dimValue) => {
      this.cursorPanelService.setData({ dimValue });
      this.cursorPanelService.close();
    });
  }

  ngAfterViewInit(): void {
    this.matSlider.focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private handleDimValue(): void {
    this.cursorPanelService
      .getData()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.dimValue = data.dimValue;
      });
  }
}
