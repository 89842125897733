import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DOMAIN } from '@summa/models';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { AgreementsDialogSandbox } from './agreements-dialog.sandbox';

@Component({
  selector: 'summa-agreements-dialog',
  templateUrl: './agreements-dialog.component.html',
  styleUrls: ['./agreements-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AgreementsDialogSandbox],
})
export class AgreementsDialogComponent implements OnInit, OnDestroy {
  loading = false;
  agreementFormGroups = [];
  destroy$ = new Subject();
  confirmAgreements$ = new Subject();
  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, public sandbox: AgreementsDialogSandbox) {}

  ngOnInit() {
    this.handleDocuments();
    this.handleOnConfirmClick();
    this.handleSuccess();
  }

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public decodeDocumentContent(encodedContent: string): string {
    return window.atob(encodedContent);
  }

  private handleOnConfirmClick(): void {
    this.confirmAgreements$.pipe(withLatestFrom(this.sandbox.documents$), takeUntil(this.destroy$)).subscribe(([, documents]) => {
      this.sandbox.updateUserAgreements(
        documents.map((document) => ({
          documentId: document.id,
          version: document.version,
        })),
      );
    });
  }

  private handleDocuments(): void {
    this.sandbox.documents$.pipe(takeUntil(this.destroy$)).subscribe((documents: DOMAIN.Document[]) => {
      documents.forEach(() => {
        this.agreementFormGroups.push(
          this.fb.group({
            accept: [false, Validators.required],
          }),
        );
      });
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.closeDialog.emit());
  }
}
