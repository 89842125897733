import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map } from 'rxjs/operators';

import { CONSTANTS } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { Counter } from '@summa/shared/dashboard/ui';
import { I18NextCapPipe } from 'angular-i18next';
import { CardQuery, CardService, CommandService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectDetailsCardWidgetSandbox {
  cards$ = this.cardQuery.selectAll();
  counters$ = this.cardQuery.selectAll().pipe(
    filter(isNotNullOrUndefined),
    map((cards) => {
      return { enabled: cards.filter((c) => !c.hidden), disabled: cards.filter((c) => c.hidden) };
    }),
  );
  params$ = this.router.selectParams();
  project$ = this.projectQuery.selectActive();

  cardCounters$ = this.counters$.pipe(
    map((cards): Counter[] => {
      return [
        {
          title: this.i18next.transform('project:card-widget.enabled'),
          class: 'success',
          count: cards.enabled.length,
        },
        {
          title: this.i18next.transform('project:card-widget.disabled'),
          class: 'warning',
          count: cards.disabled.length,
        },
      ];
    }),
  );

  constructor(
    private cardService: CardService,
    private commandService: CommandService,
    private cardQuery: CardQuery,
    private router: RouterQuery,
    private projectQuery: ProjectQuery,
    private i18next: I18NextCapPipe,
  ) {}

  getCards(projectKey: string): void {
    this.cardService.getCards(projectKey);
  }

  reset(): void {
    this.cardService.reset();
  }

  executeSync(projectKey: string, subject: CONSTANTS.SyncType, mode: 'partial' | 'full'): void {
    this.commandService.executeSyncCommand({ projectKey, subject, mode });
  }
}
