<ng-container *ngIf="sandbox.automation$ | async as automation">


  <div class="notification-info m-3 mb-0" fxLayout="column wrap">
    <div class="item mr-0" fxLayout="row wrap" >
      <div class="title" fxFlex="25">{{'common:dictionary.type' |i18nextCap}}</div>
      <div class="content" fxFlex="75">{{ automation.type }}</div>
    </div>

    <div class="item" fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="automation.type === typeTimer">
      <div class="title" fxFlex="25">{{'common:dictionary.scenario'|i18nextCap}}</div>
      <div class="content" fxFlex="75">{{ automation.scenario?.name }}</div>
    </div>

    <ng-container *ngIf="automation.type === typeMotion && motionSettings(automation).triggerType === groupTrigger">
      <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="title" fxFlex="25">{{ 'reseller:automation.preview.motionDetectedScenario' | i18nextCap }}</div>
        <div class="content" fxFlex="75">
          {{ motionSettings(automation).presence.group.title }} - {{ motionSettings(automation).presence.scene.name }}
        </div>
      </div>
      <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="title" fxFlex="25">{{ 'reseller:automation.preview.noMotionDetectedScenario' | i18nextCap }}</div>
        <div class="content" fxFlex="75">{{ motionSettings(automation).idle.brightness}}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="automation.type === typeMotion && motionSettings(automation).triggerType === scenarioTrigger">
      <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="title" fxFlex="25">{{ 'reseller:automation.preview.motionDetectedScenario' | i18nextCap }}</div>
        <div class="content" fxFlex="75">{{ motionSettings(automation).presence.scenario.name }}</div>
      </div>
      <div class="item" fxLayout="row wrap" fxLayoutAlign="start center">
        <div class="title" fxFlex="25">{{ 'reseller:automation.preview.noMotionDetectedScenario' | i18nextCap }}</div>
        <div class="content" fxFlex="75">{{ motionSettings(automation).idle.brightness }}</div>
      </div>
    </ng-container>
  </div>



  <mat-accordion displayMode="flat" multi="true">
    <!-- SENSORS -->
    <mat-expansion-panel *ngIf="automation.type === typeMotion">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'common:dictionary.sensor_plural' | i18nextCap | uppercase }}</mat-panel-title>
        <mat-panel-description>
          {{ motionSettings(automation).sensorDrivers.length }}
          {{ 'common:dictionary.sensor' | i18nextCap: { count: motionSettings(automation).sensorDrivers.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="control-info" fxLayout="row wrap">
        <div class="item" fxFlex="100">
          <div fxLayout="column" fxLayoutGap="8px">
            <ng-container *ngFor="let sensor of motionSettings(automation).sensorDrivers">
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="25">
                  {{ 'common:dictionary.address' | i18nextCap }}
                </div>
                <div class="content" fxFlex="20">{{ sensor.key }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <!-- TRIGGERS -->
    <mat-expansion-panel *ngIf="automation.settings.triggers.length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'common:dictionary.trigger_plural' | i18nextCap | uppercase }} </mat-panel-title>
        <mat-panel-description>
          {{ automation.settings.triggers.length }}
          {{ 'common:dictionary.trigger' | i18nextCap: { count:automation.settings.triggers.length } }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="control-info" fxLayout="row wrap">
        <div class="item" fxFlex="100">
          <div fxLayout="column" fxLayoutGap="8px">
            <ng-container *ngFor="let trigger of automation.settings.triggers">
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="25">
                  <span *ngIf="getCustomDay(trigger.days) !== 'custom'; else customDays">
                    {{ 'common:dictionary.days.' + getCustomDay(trigger.days) | i18nextCap }}
                  </span>
                  <ng-template #customDays>
                    <span *ngFor="let day of trigger.days; last as isLast">
                      {{ 'common:dictionary.days.' + day | i18nextCap }}<ng-container *ngIf="!isLast">, </ng-container>
                    </span>
                  </ng-template>
                </div>
                <div class="content" fxFlex="20">{{ trigger.startTime }}</div>
                <div class="content" fxFlex="20" *ngIf="automation.type === motion">{{ trigger.endTime }}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
