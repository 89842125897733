import { gql } from 'apollo-angular';

export const getFixturesQuery = gql`
  query GetFixtures($floorplanId: String) {
    getFixtures(floorplanKey: $floorplanId) {
      id
      floorplanKey
      location {
        x
        y
        height
      }
      mainGroup
      type
      devices {
        id
        key
      }
      iconData
      locationKey
      status
    }
  }
`;
