export interface InvoiceInput {
  id?: string;
  reseller: string;
  content: InvoiceInputContent;
  sendDate?: number;
}

export class InvoiceInputContent {
  id: string;
  reseller: string;
  projects: InvoiceInputContentProject[];
}

export class InvoiceInputContentProject {
  key: string;
  name: string;
  drivers: InvoiceInputContentDriver[];
}

export class InvoiceInputContentDriver {
  driverType: string;
  numberOfDrivers: number;
  details: InvoiceInputContentDetails[];
}

export class InvoiceInputContentDetails {
  driverKey: string;
  driverAddress: string;
  devices: InvoiceInputContentDevices[];
}

export class InvoiceInputContentDevices {
  numberOfDevices: number;
  deviceCategory: string;
  deviceType: string;
}
