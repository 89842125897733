import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, map } from 'rxjs/operators';

import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined, isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { DTO } from '@summa/portal/models/dto';
import { DOMAIN } from '@summa/models';
import {
  CardQuery,
  CardService,
  ControlQuery,
  ControlService,
  DriverQuery,
  DriverService,
  ProjectQuery,
  ScenarioQuery,
  ScenarioService,
} from '../../../../data-access';

@Injectable()
export class ProjectControlAddSandbox {
  groups$ = this.groupQuery.selectAll().pipe(filter(isNotNullUndefinedOrEmpty));
  project$ = this.projectQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  scenarios$ = this.scenarioQuery.selectAll();
  upsertState$ = this.controlQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData();
  control$ = this.controlQuery.selectActive().pipe(filter(isNotNullOrUndefined));
  params$ = this.routerQuery.selectParams();

  driverCheckEntity$ = this.driverQuery.selectCheckState$.pipe(
    filter((state) => isNotNullOrUndefined(state) && !state.isLoading),
    map((state) => state),
  );

  constructor(
    private projectQuery: ProjectQuery,
    private sidePanelService: SidePanelService,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
    private groupService: CardService,
    private groupQuery: CardQuery,
    private controlService: ControlService,
    private controlQuery: ControlQuery,
    private routerQuery: RouterQuery,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
  ) {}

  getGroups(projectKey: string): void {
    this.groupService.getCards(projectKey);
  }

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  getControl(id: string): void {
    this.controlService.getControl(id);
  }

  checkDriverAvailability(driverKey: string, driverAddress?: string): void {
    this.driverService.checkDriver(driverKey, driverAddress);
  }

  upsertDriver(driver: DTO.DriverInput): void {
    this.driverService.upsertDriver(driver);
  }

  upsertControl(control: DOMAIN.ControlInput): void {
    this.controlService.upsertControl(control);
  }

  reset(): void {
    this.controlService.resetActive();
  }

  resetDriver(): void {
    this.driverService.resetDriverUIState();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
