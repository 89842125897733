import { PermissionGuard } from '@summa/shared/dashboard/util';
import { PERMISSIONS } from '@summa/models';
import { Routes as RouterRoutes } from '@angular/router';
import { ControlPanelOverviewPage, ControlPanelDetailPage } from '@summa/dashboard';
import { ProjectScenarioDetailsPage } from './project-scenarios/project-scenario-details/project-scenario-details.page';
import { ProjectSceneOverviewPage } from './project-scenes/project-scene-overview/project-scene-overview.page';
import { ProjectCardOverviewPage } from './project-cards/project-card-overview/project-card-overview.page';
import { ProjectCardDetailsPage } from './project-cards/project-card-details/project-card-details.page';
import { ProjectControlOverviewPage } from './project-controls/project-control-overview/project-control-overview.page';
import { ProjectFloorplansDetailsPage } from './project-floorplans/project-floorplans-details/project-floorplans-details.page';
import { ProjectControlDetailsPage } from './project-controls/project-control-details/project-control-details.page';
import { ProjectAutomationDetailsPage } from './project-automations/project-automation-details/project-automation-details.page';
import { ProjectFloorplansOverviewPage } from './project-floorplans/project-floorplans-overview/project-floorplans-overview.page';
import { ProjectAutomationOverviewPage } from './project-automations/project-automation-overview/project-automation-overview.page';
import { ProjectScenarioOverviewPage } from './project-scenarios/project-scenario-overview/project-scenario-overview.page';
import { ProjectDetailsPage } from './project-details/project-details.page';
import { ProjectSceneDetailsPage } from './project-scenes/project-scene-details/project-scene-details.page';
import { ProjectDriverOverviewPage } from './project-drivers/overview/project-driver-overview.page';
import { ProjectFloorplanCardsComponent } from './project-floorplans/project-floorplans-details/project-floorplan-cards/project-floorplan-cards.component';
import { ProjectFloorplanDriversComponent } from './project-floorplans/project-floorplans-details/project-floorplan-drivers/project-floorplan-drivers.component';
import { ProjectFloorplanAdminComponent } from './project-floorplans/project-floorplans-details/project-floorplan-admin/project-floorplan-admin.component';
import { ProjectOverviewPage } from './project-overview/project-overview.page';
import { ProjectAddPage } from './project-add/project-add.page';
import { ProjectInstallerOverviewPage } from './project-installers/project-installer-overview/project-installer-overview.page';
import { SummaPortalUserManagementModule } from '../user-management';
import { SummaPortalNotificationsModule } from '../notifications';

export const Routes: RouterRoutes = [
  { path: '', component: ProjectOverviewPage },
  { path: 'add', component: ProjectAddPage },
  {
    canActivate: [PermissionGuard],
    data: {
      tag: 'project-key',
      permissions: {
        application: { name: PERMISSIONS.applicationReseller, qualifiers: [':resellerId'] },
        domain: { name: PERMISSIONS.applicationProject, qualifiers: [':projectKey'] },
        subdomain: null,
        action: PERMISSIONS.actionRead,
      },
    },
    path: ':projectKey',
    children: [
      {
        path: '',
        component: ProjectDetailsPage,
        data: {
          breadcrumb: {
            alias: 'projectName',
          },
        },
      },
      {
        path: 'automations',
        children: [
          { path: '', component: ProjectAutomationOverviewPage, data: { breadcrumb: 'Automations' } },
          { path: ':automationKey', component: ProjectAutomationDetailsPage },
        ],
      },
      {
        path: 'groups',
        children: [
          { path: '', component: ProjectCardOverviewPage, data: { breadcrumb: 'Groups' } },
          { path: ':cardKey', component: ProjectCardDetailsPage },
        ],
      },
      {
        path: 'controls',
        children: [
          { path: '', component: ProjectControlOverviewPage, data: { breadcrumb: 'Controls' } },
          { path: ':controlKey', component: ProjectControlDetailsPage },
        ],
      },
      {
        path: 'control-panels',
        canActivate: [PermissionGuard],
        data: {
          tag: 'control-panels',
          permissions: {
            application: { name: PERMISSIONS.applicationReseller, qualifiers: [PERMISSIONS.wildcardQualifier] },
            domain: { name: PERMISSIONS.applicationProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
            subdomain: { name: PERMISSIONS.applicationControlPanel, qualifiers: [PERMISSIONS.wildcardQualifier] },
            action: PERMISSIONS.actionRead,
          },
        },
        children: [
          { path: '', component: ControlPanelOverviewPage, data: { breadcrumb: 'Control Panels' } },
          {
            path: ':controlPanelId',
            component: ControlPanelDetailPage,
            canActivate: [PermissionGuard],
            data: {
              permissions: {
                application: { name: PERMISSIONS.applicationReseller, qualifiers: [PERMISSIONS.wildcardQualifier] },
                domain: { name: PERMISSIONS.applicationProject, qualifiers: [PERMISSIONS.wildcardQualifier] },
                subdomain: { name: PERMISSIONS.applicationControlPanel, qualifiers: [':controlPanelId'] },
                action: PERMISSIONS.actionRead,
              },
            },
          },
        ],
      },
      {
        path: 'devices',
        children: [{ path: '', component: ProjectDriverOverviewPage, data: { breadcrumb: 'Devices' } }],
      },
      {
        path: 'scenarios',
        children: [
          { path: '', component: ProjectScenarioOverviewPage, data: { breadcrumb: 'Scenarios' } },
          { path: ':scenarioKey', component: ProjectScenarioDetailsPage },
        ],
      },
      {
        path: 'scenes',
        children: [
          { path: '', component: ProjectSceneOverviewPage, data: { breadcrumb: 'Scenes' } },
          { path: ':sceneKey', component: ProjectSceneDetailsPage },
        ],
      },
      {
        path: 'floorplans',
        children: [
          { path: '', component: ProjectFloorplansOverviewPage, data: { breadcrumb: 'Floorplans' } },
          {
            path: ':floorplanKey',
            component: ProjectFloorplansDetailsPage,
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'drivers' },
              { path: 'drivers', component: ProjectFloorplanDriversComponent },
              { path: 'groups', component: ProjectFloorplanCardsComponent },
              { path: 'admin', component: ProjectFloorplanAdminComponent },
            ],
          },
        ],
        // data: { breadcrumb: (floorplanKey: string) => `Floorplan: ${floorplanKey.replace('floorplans/', '')}` },
      },
      {
        path: 'user-management',
        canActivate: [PermissionGuard],
        loadChildren: () => SummaPortalUserManagementModule,
        data: {
          // permissions: {
          //   application: { name: PERMISSIONS.applicationProject, qualifiers: [PERMISSIONS.applicationQualifierUserManagement] },
          //   action: PERMISSIONS.actionRead,
          // },
          application: PERMISSIONS.applicationProject,
        },
      },
      {
        path: 'notifications',
        loadChildren: () => SummaPortalNotificationsModule,
      },
      {
        path: 'installers',
        children: [{ path: '', component: ProjectInstallerOverviewPage, data: { breadcrumb: 'installers' } }],
      },
    ],
  },
];
