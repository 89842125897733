<summa-side-panel-content [showInfo]="true" class="side-panel" *ngIf="scene$ | async as scene">
  <ng-container header>
    <h1><span class="highlight">{{ 'common:dictionary.scene' | i18nextCap }}: </span>{{ scene.name }}</h1>
  </ng-container>

  <ng-container info>
    <div class="scene-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.name' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scene.name }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.description' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ scene.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <!-- DEFAULT -->
    <ng-container *ngIf="scene.default as default">
      <h4>{{ 'reseller:scenes.default' | i18next | uppercase }}</h4>
      <div fxLayout="row wrap">
        <div class="item" fxFlex="100">
          <div fxLayout="column" fxLayoutGap="8px">
            <div class="item" fxLayout="row wrap">
              <div class="title" fxFlex="20">{{ 'dictionary.dim-value' | i18nextCap }}</div>
              <div class="content" fxFlex="80">{{ default.dimValue }}</div>
            </div>
            <ng-container *ngIf="!!default.cct, else showXY">
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="20">{{ 'domain.cct' | i18nextCap }}</div>
                <div class="content" fxFlex="80">{{ default.cct }}</div>
              </div>
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="20">{{ 'domain.duv' | i18nextCap }}</div>
                <div class="content" fxFlex="80">{{ default.duv }}</div>
              </div>
            </ng-container>
            <ng-template #showXY>
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="20">{{ 'domain.x' | i18nextCap }}</div>
                <div class="content" fxFlex="80">{{ default.x }}</div>
              </div>
              <div class="item" fxLayout="row wrap">
                <div class="title" fxFlex="20">{{ 'domain.y' | i18nextCap }}</div>
                <div class="content" fxFlex="80">{{ default.y }}</div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- DYNAMIC -->
    <ng-container *ngIf="scene.dynamic as dynamic">
      <h4>{{ 'reseller:scenes.dynamic' | i18next | uppercase }}</h4>
      <div fxLayout="row wrap">
        <div class="item" fxFlex="100">
          <div fxLayout="column" fxLayoutGap="8px">
            <div class="item" fxLayout="row wrap">
              <div class="title" fxFlex="20">{{ 'reseller:scenes.add.service' | i18nextCap }}</div>
              <div class="content" fxFlex="80">{{ 'reseller:scenes.add.' + dynamic.service |i18next | titlecase}}</div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="8px">
            <div class="item" fxLayout="row wrap">
              <div class="title" fxFlex="20">{{ 'reseller:scenes.add.space' | i18nextCap }}</div>
              <div class="content" fxFlex="80">{{ dynamic.space }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</summa-side-panel-content>
