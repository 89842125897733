import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import Viewer from './src/Viewer';

@Component({
  selector: 'summa-floorplan-dxf-viewer',
  templateUrl: './floorplan-dxf-viewer.component.html',
  styleUrls: ['./floorplan-dxf-viewer.component.scss'],
})
export class FloorplanDxfViewerComponent implements OnDestroy, AfterViewInit, OnChanges {
  @Input() file: string;
  @Input() fixtures = [];
  @Input() customHeight = null;
  @Input() enableMoveFixture = false;
  @ViewChild('dxfviewer') dxfviewer: ElementRef;
  @Output() activateElement = new EventEmitter<object>();

  destroy$ = new Subject();
  viewer = null;
  fixtures$ = new BehaviorSubject([]);

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    // handle fixture changes
    this.fixtures$.pipe(takeUntil(this.destroy$)).subscribe((fixtures) => {
      if (!this.viewer) {
        this.createViewer(fixtures);
      } else {
        this.viewer?.updateDevicesData(fixtures, true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fixtures) {
      this.fixtures$.next(changes.fixtures.currentValue);
    }
    if (changes.enableMoveFixture) {
      this.viewer?.setEnableMoveFixture(changes.enableMoveFixture.currentValue);
    }
  }

  private createViewer(fixtures): void {
    if (this.viewer) return;

    this.viewer = new Viewer(this.dxfviewer.nativeElement, fixtures, '#293647');
    this.viewer.Load(encodeURI(this.file));

    this.viewer.onClick = (e, selectedDevices) => {
      this.activateElement.next(selectedDevices);
    };
  }

  public setSelectFixture(fixture): void {
    if (fixture) {
      this.viewer?.setSelectFixture(fixture);
    }
  }
}
