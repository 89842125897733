import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PortalAPI, UI } from '@summa/models';
import { Observable } from 'rxjs';

import { ResellerState, ResellerStore } from './reseller.store';

@Injectable({
  providedIn: 'root',
})
export class ResellerQuery extends QueryEntity<ResellerState, PortalAPI.Reseller> {
  // upsert
  selectUpsertState$: Observable<UI.State>;

  constructor(protected store: ResellerStore) {
    super(store);

    this.selectUpsertState$ = this.select((state) => state.ui.upsert);
  }
}
