import { DOMAIN } from '@summa/models';

const DriverTypeMap = {
  P: DOMAIN.driverPuck,
  C: DOMAIN.driverCompact, // driver
  T: DOMAIN.driverTrack, // driver
  D: DOMAIN.driverDimmer,
  S: DOMAIN.driverSwitch,
  R: DOMAIN.driverRelay,
};

export interface ResolvedDriver {
  driverType: DOMAIN.DriverType;
  channelCount: number;
  batch: string;
  id: string;
}

export function resolveDriverId(driverId: string): ResolvedDriver {
  // first char determines the device Type
  const driverType: DOMAIN.DriverType = DriverTypeMap[driverId.substr(0, 1).toUpperCase()];
  // next two char determines the channel amount
  const channelCount = Number(driverId.substr(1, 2));
  // next two char determines the batch
  const batch: string = driverId.substr(3, 2);
  // from separator S till end is the udeznique address
  const id = driverId.substr(6, driverId.length);

  return { driverType, channelCount, batch, id };
}
