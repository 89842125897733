import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DTO } from '@summa/portal/models/dto';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { filter, map, withLatestFrom } from 'rxjs';
import { PresetQuery, ProjectQuery, ProjectService, ResellerQuery, ResellerService } from '../../../data-access';

@Injectable()
export class ProjectAddSandbox {
  reseller$ = this.resellerQuery.selectActive();
  clients$ = this.resellerQuery.selectActive((reseller) => reseller.clients);
  queryParams$ = this.reseller$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.router.queryParams),
    map(([, params]) => params),
  );
  presets$ = this.presetQuery.selectAll();
  params$ = this.router.params;
  upsertState$ = this.projectQuery.selectUpsertState$;
  projectKey$ = this.projectQuery.selectActiveId();

  constructor(
    private resellerService: ResellerService,
    private resellerQuery: ResellerQuery,
    private router: ActivatedRoute,
    private presetQuery: PresetQuery,
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
  ) {}

  getReseller(resellerKey: string): void {
    this.resellerService.getReseller(resellerKey);
  }

  upsertProject(project: DTO.ProjectInput): void {
    this.projectService.upsertProject(project);
  }
}
