import { Injectable } from '@angular/core';

import { ControlQuery, ControlService } from '../../../../data-access';

@Injectable()
export class ProjectControlPreviewSandbox {
  control$ = this.controlQuery.selectActive();

  constructor(private controlQuery: ControlQuery, private controlService: ControlService) {}

  resetActive(): void {
    this.controlService.setActive(null);
  }
}
