<summa-side-panel-content [showInfo]="true" [hideFooter]="true" class="side-panel" *ngIf="sandbox.driver$ | async as driver">
  <ng-container header>
    <h1><span class="orange">{{'reseller:drivers.preview-title-prefix' |i18nextCap}}: </span>{{ driver.key }}</h1>
  </ng-container>

  <ng-container info>
    <div class="driver-info" fxLayout="row wrap">
      <div class="item" fxFlex="100">
        <div fxLayout="column" fxLayoutGap="8px">
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.address' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ driver.address }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.type' | i18nextCap }}</div>
            <div class="content" fxFlex="80">{{ driver.type }}</div>
          </div>
          <div class="item" fxLayout="row wrap">
            <div class="title" fxFlex="20">{{ 'common:dictionary.state' | i18nextCap }}</div>
            <div class="content">
              <ng-container [ngSwitch]="(sandbox.fixtureState$ | async)?.state">
                <div class="badge success m-0" *ngSwitchCase="'online'">{{ 'common:dictionary.states.online' | i18next }}</div>
                <div class="badge danger m-0" *ngSwitchCase="'offline'">{{ 'common:dictionary.states.offline' | i18next }}</div>
                <div class="badge warning m-0" *ngSwitchDefault>{{ 'common:dictionary.states.unknown' | i18next }}</div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container body>
    <mat-tab-group id="tabs" fxFill>
      <!-- Control Settings Check -->
      <mat-tab fxFill *ngIf="isCategoryControl(driver.type)">
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
            <mat-icon [svgIcon]="'format-list-checks'"></mat-icon>
            <content fxFlexAlign="center">{{ 'project:drivers.preview.control-settings' | i18nextCap }}</content>
          </section>
        </ng-template>
        <ng-template matTabContent>
          <summa-project-driver-control-settings-tab></summa-project-driver-control-settings-tab>
        </ng-template>
      </mat-tab>
      <!-- INFO -->
      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
            <mat-icon [svgIcon]="'common:icons.info' | i18next"></mat-icon>
            <content fxFlexAlign="center">{{ 'common:dictionary.info' | i18nextCap }}</content>
          </section>
        </ng-template>
        <ng-template matTabContent>
          <summa-project-driver-info-tab></summa-project-driver-info-tab>
        </ng-template>
      </mat-tab>
      <!-- Groups information -->
      <mat-tab>
        <ng-template mat-tab-label>
          <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
            <mat-icon svgIcon="lightbulb-group"></mat-icon>
            <content fxFlexAlign="center">{{ 'common:dictionary.card_plural' | i18nextCap }}</content>
          </section>
        </ng-template>
        <ng-template matTabContent>
          <div class="notification-info mt-2" fxLayout="row wrap">
            <div class="item" fxFlex="100">
              <div fxLayout="column" fxLayoutGap="8px">
                <table mat-table *ngIf="(sandbox.sortedCards$ | async) as cards else elseBlock" [dataSource]="cards">
                  <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef>{{'reseller:cards.overview.table-headers.title' | i18nextCap}}</th>
                    <td mat-cell *matCellDef="let card">{{ card.title }}</td>
                  </ng-container>

                  <ng-container matColumnDef="hidden">
                    <th mat-header-cell class="header-align-right" *matHeaderCellDef>
                      {{'reseller:cards.overview.table-headers.hidden' | i18nextCap}}
                    </th>
                    <td mat-cell fxLayoutAlign="end center" *matCellDef="let card">
                      <mat-icon [svgIcon]="card.hidden ? 'eye-off' : 'eye'"></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                  <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
                </table>
                <ng-template #elseBlock><p>{{'project:drivers.preview.no-groups-attached' | i18nextCap}}</p></ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>

      <!-- ACTIONS -->
      <ng-container *ngIf="hasActionPermission$ | async">
        <mat-tab *ngIf="isCategoryDriver(driver.type)">
          <ng-template mat-tab-label>
            <section class="tab-title" fxFlexAlign="row" fxLayout="start center" fxLayoutGap="0.5rem">
              <mat-icon [svgIcon]="'format-list-checks'"></mat-icon>
              <content fxFlexAlign="center">{{ 'project:drivers.preview.actions' | i18nextCap }}</content>
            </section>
          </ng-template>
          <ng-template matTabContent>
            <summa-project-driver-actions-tab></summa-project-driver-actions-tab>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </ng-container>
</summa-side-panel-content>
