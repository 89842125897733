import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { filter, Observable } from 'rxjs';

import { DOMAIN } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { isNotNullUndefinedOrEmpty } from '@summa/shared/util/typescript';
import { CardQuery, CardService, ScenarioQuery, ScenarioService } from '../../../../../data-access';

@Injectable()
export class ControlCardsAddSandbox {
  groups$: Observable<DOMAIN.Card[]>;
  project$: Observable<DTO.Project>;
  scenarios$: Observable<DOMAIN.Scenario[]>;
  params$: Observable<{ projectKey: string }>;

  constructor(
    private groupQuery: CardQuery,
    private routerQuery: RouterQuery,
    private groupService: CardService,
    private scenarioQuery: ScenarioQuery,
    private scenarioService: ScenarioService,
  ) {
    this.params$ = this.routerQuery.selectParams();
    this.groups$ = this.groupQuery.selectAll().pipe(filter(isNotNullUndefinedOrEmpty));
    this.scenarios$ = this.scenarioQuery.selectAll().pipe(filter(isNotNullUndefinedOrEmpty));
  }

  getGroups(projectKey: string): void {
    this.groupService.getCards(projectKey);
  }

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }
}
