import { Component, Input } from '@angular/core';

@Component({
  selector: 'summa-side-panel-content',
  templateUrl: './side-panel-content.component.html',
  styleUrls: ['./side-panel-content.component.scss'],
})
export class SidePanelContentComponent {
  @Input() showInfo: boolean;
  @Input() hideFooter = false;
}
