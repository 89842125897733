import { gql } from 'apollo-angular';

export const NotificationsSubscription = gql`
  subscription observeNotifications($projects: [String!]!) {
    observeNotifications(projects: $projects) {
      id
      timestamp
      project
      type
      title
      message
      trace
    }
  }
`;
