import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

import { DOMAIN } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { CardQuery, PresetService, ProjectQuery, SceneQuery, SceneService } from '../../../../data-access';

@Injectable()
export class ProjectSceneAddSandbox {
  card$ = this.cardQuery.selectActive();
  project$ = this.projectQuery.selectActive();
  devices$ = this.cardQuery.selectActive<DOMAIN.Device[]>((driver) => driver.devices);
  updateSceneState$ = this.sceneQuery.selectUpsertState$;
  data$ = this.sidePanelService.getData<DOMAIN.Scene | null>();
  currentProjectDefaultSettings$ = this.project$.pipe(map((project) => project.defaultScene));
  scene$ = this.sceneQuery.selectActive().pipe(filter(isNotNullOrUndefined));

  constructor(
    private sidePanelService: SidePanelService,
    private cardQuery: CardQuery,
    private projectQuery: ProjectQuery,
    private presetService: PresetService,
    private sceneService: SceneService,
    private sceneQuery: SceneQuery,
  ) {}

  getScene(sceneId: string): void {
    this.sceneService.getScene(sceneId);
  }

  upsertScene(sceneInput: DOMAIN.SceneInput): void {
    this.sceneService.upsertScene(sceneInput);
  }

  reset(): void {
    this.sceneService.resetActive();
    this.presetService.reset();
  }

  close(): void {
    this.sidePanelService.close();
  }
}
