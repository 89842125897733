import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';

import { I18NextCapPipe } from 'angular-i18next';
import { filter, map } from 'rxjs';
import { Counter } from '@summa/shared/dashboard/ui';
import { ProjectQuery, ScenarioQuery, ScenarioService } from '../../../../data-access';

@Injectable()
export class ProjectDetailsScenarioWidgetSandbox {
  scenarios$ = this.scenarioQuery.selectAll();
  counters$ = this.scenarios$.pipe(
    filter(isNotNullOrUndefined),
    map((scenarios) => {
      return { enabled: scenarios.filter((c) => !c.hidden), disabled: scenarios.filter((c) => c.hidden) };
    }),
  );

  params$ = this.router.selectParams();
  project$ = this.projectQuery.selectActive();

  displayCounters$ = this.counters$.pipe(
    map((scenarios): Counter[] => {
      return [
        {
          title: this.i18next.transform('common:dictionary.enabled'),
          class: 'success',
          count: scenarios.enabled.length,
        },
        {
          title: this.i18next.transform('common:dictionary.disabled'),
          class: 'warning',
          count: scenarios.disabled.length,
        },
      ];
    }),
  );

  constructor(
    private router: RouterQuery,
    private projectQuery: ProjectQuery,
    private i18next: I18NextCapPipe,
    private scenarioService: ScenarioService,
    private scenarioQuery: ScenarioQuery,
  ) {}

  getScenarios(projectKey: string): void {
    this.scenarioService.getScenarios(projectKey);
  }

  reset(): void {
    this.scenarioService.reset();
  }
}
