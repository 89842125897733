import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { I18NextModule } from 'angular-i18next';
import { ColorSliderModule } from 'ngx-color/slider';

import { SharedUiCardsModule } from '@summa/shared/ui/cards';
import { SharedUiDialogsModule } from '@summa/shared/ui/dialogs';
import { SharedUiHeadersModule } from '@summa/shared/ui/headers';

import { SharedAuthUtilModule } from '@summa/shared/dashboard/util';
import { CardDetailComponent } from './card-details/card-detail.component';
import { CardDetailTabInfoComponent } from './card-details/tabs/info/card-detail-tab-info.component';
import { CardDetailTabLabsComponent } from './card-details/tabs/labs/card-detail-tab-lab.component';
import { CardDetailTabLightsComponent } from './card-details/tabs/lights/card-detail-tab-lights.component';
import { CardDetailTabScenesComponent } from './card-details/tabs/scenes/card-detail-tab-scene.component';
import { CustomerCardComponent } from './customer-card/customer-card.component';
import { CustomerDashboardPage } from './customer-dashboard.page';
import { CustomerScenarioPage } from './scenarios/customer-scenario.page';
import { DashboardComponentsModule } from '../../components/components.module';

@NgModule({
  imports: [
    CommonModule,
    I18NextModule,
    LayoutModule,
    FlexLayoutModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSliderModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    ColorSliderModule,
    SharedUiCardsModule,
    SharedUiHeadersModule,
    SharedUiDialogsModule,
    SharedAuthUtilModule,
    DashboardComponentsModule,
  ],
  declarations: [
    CustomerDashboardPage,
    CustomerCardComponent,
    CustomerScenarioPage,
    CardDetailComponent,
    CardDetailTabInfoComponent,
    CardDetailTabLightsComponent,
    CardDetailTabScenesComponent,
    CardDetailTabLabsComponent,
  ],
  exports: [
    CustomerDashboardPage,
    CustomerCardComponent,
    CustomerScenarioPage,
    CardDetailComponent,
    CardDetailTabInfoComponent,
    CardDetailTabLightsComponent,
    CardDetailTabScenesComponent,
    CardDetailTabLabsComponent,
  ],
})
export class DashboardAppModule {}
