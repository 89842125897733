import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';

import { CONSTANTS, DOMAIN } from '@summa/models';

import { removeDocumentQuery, upsertDocumentQuery, GetDocumentQuery, GetDocumentsQuery } from './graphql';

import { DocumentStore } from './documents.store';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private apollo: Apollo, private store: DocumentStore, private notifier: NotifierService, private i18next: I18NextCapPipe) {}

  reset(): void {
    this.store.reset();
    this.resetActive();
  }

  resetActive(): void {
    this.store.resetDocumentUIState();
    this.store.setActive(null);
  }

  setActive(id: ID | null): void {
    this.store.setActive(id);
  }

  getDocuments(type: string): void {
    this.apollo
      .subscribe({ query: GetDocumentsQuery, variables: { type } })
      .pipe(map((d: any) => d.data.getDocuments as DOMAIN.Document[]))
      .subscribe((documents) => this.store.set(documents));
  }

  getDocument(id: string): void {
    this.apollo
      .subscribe({ query: GetDocumentQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getDocument as DOMAIN.Document))
      .subscribe((document) => {
        this.store.upsert(document.id, document);
        this.store.setActive(document.id);
      });
  }

  resetDocumentUIState(): void {
    this.store.resetDocumentUIState();
  }

  upsertDocument(documentInput: DOMAIN.DocumentInput): void {
    this.store.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertDocumentQuery, variables: { documentInput } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.store.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `document ${documentInput.name}` }));

        this.store.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertDocument: DOMAIN.Document };
      this.store.upsert(data.upsertDocument.id, data.upsertDocument);
      this.store.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-updated'));
    });
  }

  removeDocument(id: string): void {
    this.store.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeDocumentQuery, variables: { id } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.store.setError(results.errors);
          this.store.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        // const data = results.data as { removeDocument: PortalAPI.RemoveResponse };
        this.store.remove(id);
        this.store.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }

  removeDocumentFromStore(id: string) {
    this.store.remove(id);
  }

  resetUpsertState() {
    this.store.updateUpsert({ isSuccessful: false, isLoading: false, errors: null });
  }

  resetRemoveState() {
    this.store.updateRemove({ isSuccessful: false, isLoading: false, errors: null });
  }
}
