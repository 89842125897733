import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { filter, first, takeUntil, withLatestFrom } from 'rxjs/operators';

import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, defaultDeletionString, ConfirmationModalData } from '@summa/shared/ui/dialogs';
import { I18NextCapPipe } from 'angular-i18next';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { DTO } from '@summa/portal/models/dto';
import { ProjectOverviewSandbox } from './project-overview.sandbox';

@Component({
  selector: 'summa-project-overview',
  templateUrl: './project-overview.page.html',
  styleUrls: ['./project-overview.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectOverviewSandbox],
})
export class ProjectOverviewPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  remove$ = new Subject();
  projects = new MatTableDataSource<DTO.Project>();
  confirmDialog: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  columnsToDisplay = ['key', 'name', 'state', 'actions'];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private dialog: MatDialog, private i18next: I18NextCapPipe, public sandbox: ProjectOverviewSandbox) {}

  ngOnInit(): void {
    this.handleUserProjects();
    this.handleRemove();
    this.sandbox.resetActiveProject();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public filterData(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.projects.filter = filterValue.trim().toLowerCase();
  }

  private handleUserProjects(): void {
    this.sandbox.userProjects$
      .pipe(filter(isNotNullOrUndefined), first(), takeUntil(this.destroy$))
      .subscribe((projects) => this.sandbox.getGatewayStates(projects.map((p) => p.gatewayKey).filter(isNotNullOrUndefined)));

    this.sandbox.combinedProjects$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((projects) => {
      this.projects.data = projects;
      this.projects.sort = this.sort;
      this.projects.paginator = this.paginator;
      this.sandbox.resetActiveProject();
    });
  }

  private handleRemove(): void {
    this.remove$.pipe(takeUntil(this.destroy$)).subscribe((project: DTO.Project) => {
      const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
        autoFocus: true,
        data: {
          showConfirmationInput: true,
          title: this.i18next.transform('common:dialog.confirm-remove.title'),
          content: this.i18next.transform('common:dialog.confirm-remove.content', { field: `${project.name} - ${project.key}` }),
          cancelButton: this.i18next.transform('common:buttons.cancel'),
          primaryButton: this.i18next.transform('common:buttons.delete'),
          confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', { field: defaultDeletionString }),
          confirmationString: defaultDeletionString,
        },
      };

      this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      // eslint-disable-next-line rxjs/no-nested-subscribe
      this.confirmDialog.componentInstance.confirm.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.sandbox.removeProject(project.key, project.id);
      });
    });

    this.sandbox.removeState$.pipe(withLatestFrom(this.sandbox.reseller$), takeUntil(this.destroy$)).subscribe(([state, reseller]) => {
      if (state.isSuccessful && this.confirmDialog) {
        this.sandbox.resetReseller(reseller.id);
        this.confirmDialog.close();
      }
    });
  }
}
