import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { PortalAPI } from '@summa/models';

import { CurrentCheckState, CurrentCheckStore } from './current-check.store';

@Injectable({
  providedIn: 'root',
})
export class CurrentCheckQuery extends QueryEntity<CurrentCheckState, PortalAPI.CurrentCheckResult> {
  selectChangeState$ = this.select((state) => state.ui.change);

  constructor(protected store: CurrentCheckStore) {
    super(store);
  }
}
