import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';

import { DOMAIN } from '@summa/models';

import { GridEyeClusterState, GridEyeClusterStore } from './grid-eye-cluster.store';

@Injectable({
  providedIn: 'root',
})
@QueryConfig({
  sortBy: 'address',
  sortByOrder: Order.ASC,
})
export class GridEyeClusterQuery extends QueryEntity<GridEyeClusterState, DOMAIN.GridEyeCluster> {
  constructor(protected store: GridEyeClusterStore) {
    super(store);
  }
}
