<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <h1>{{((sandbox.scene$ |async) ? 'reseller:scenes.add.edit-title' :'reseller:scenes.add.add-title') | i18nextCap}}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto" [title]="project.key">{{ project.name}}</div>
      </div>
      <div *ngIf="sandbox.scene$ |async as scene">
        <label fxFlex="15">{{'common:dictionary.id' | i18nextCap}}</label>
        <div fxFlex="auto">{{scene.id}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body *ngIf="!(loading$ | async)">
    <form *ngIf="form" [formGroup]="form" class="form">
      <section fxLayout="column">
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.name' | i18nextCap}}</mat-label>
          <input matInput formControlName="name" />
          <mat-error *ngIf="form.get('name').invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.description' | i18nextCap}}</mat-label>
          <input matInput formControlName="description" />
          <mat-error *ngIf="form.get('description').invalid">{{'reseller:form.errors.required' | i18nextCap}} </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy">
          <mat-label>{{'common:dictionary.sort-order' | i18nextCap}}</mat-label>
          <input type="number" matInput formControlName="sortOrder" />
          <mat-error *ngIf="form.get('sortOrder').invalid">{{'reseller:form.errors.required' | i18nextCap}}</mat-error>
        </mat-form-field>
      </section>
      <!-- CHOICE -->
      <mat-button-toggle-group class="mb-4 toggle-group" formControlName="sceneType">
        <mat-button-toggle value="default">{{ 'reseller:scenes.add.default' | i18next | titlecase }}</mat-button-toggle>
        <mat-button-toggle value="dynamic">{{ 'reseller:scenes.add.dynamic' | i18next | titlecase }}</mat-button-toggle>
      </mat-button-toggle-group>
      <!-- DEFAULT -->
      <ng-container *ngIf="form.get('sceneType').value === 'default'">
        <section formGroupName="default" fxLayoutGap="0.5rem" fxLayout="column">
          <section fxLayout="row" fxLayoutGap="0.5rem">
            <mat-form-field appearance="legacy" fxFlex.gt-sm="50">
              <mat-label>{{'common:dictionary.dim-value' | i18nextCap}}</mat-label>
              <input type="number" matInput formControlName="dimValue" />
              <span matSuffix>%</span>
            </mat-form-field>
          </section>
          <!-- <section fxLayout="row" fxLayoutGap="0.5rem" class="mb-0">
            <span>{{'reseller:scenes.add.use-cct-duv' | i18nextCap}}</span>
            <mat-slide-toggle class="mb-2" formControlName="useCCT"></mat-slide-toggle>
          </section> -->
          <section fxLayout="row" fxLayoutGap="0.5rem">
            <mat-button-toggle-group class="mb-2 toggle-group" formControlName="use">
              <mat-button-toggle value="cct">{{ 'reseller:scenes.add.use-cct-duv' | i18next | titlecase }}</mat-button-toggle>
              <mat-button-toggle value="xy">{{ 'reseller:scenes.add.use-x-y' | i18next | titlecase }}</mat-button-toggle>
            </mat-button-toggle-group>
          </section>
          <section fxLayout="row wrap" fxLayoutGap="0.5rem grid">
            <ng-container *ngIf="form.get('default.use').value === 'cct'; else useXY">
              <mat-form-field fxFlex="50" appearance="legacy">
                <mat-label>{{'common:domain.cct' | i18nextCap}}</mat-label>
                <input formControlName="cct" type="number" matInput />
              </mat-form-field>
              <mat-form-field fxFlex="50" appearance="legacy">
                <mat-label>{{'common:domain.duv' | i18nextCap}}</mat-label>
                <input formControlName="duv" type="number" matInput />
              </mat-form-field>
            </ng-container>
            <ng-template #useXY>
              <mat-form-field fxFlex="50" appearance="legacy">
                <mat-label>{{'common:domain.x' | i18next}}</mat-label>
                <input formControlName="x" type="number" matInput />
              </mat-form-field>
              <mat-form-field fxFlex="50" appearance="legacy">
                <mat-label>{{'common:domain.y' | i18next}}</mat-label>
                <input formControlName="y" type="number" matInput />
              </mat-form-field>
            </ng-template>
          </section>
        </section>
      </ng-container>

      <!-- DYNAMIC -->
      <ng-container *ngIf="form.get('sceneType').value === 'dynamic'">
        <section formGroupName="dynamic" fxLayoutGap="0.5rem" fxLayout="column">
          <!-- <mat-form-field>
            <mat-select formControlName="minute_interval" [placeholder]="'reseller:scenes.add.minute_interval' | i18nextCap">
              <mat-option *ngFor="let interval of intervals" [value]="interval"> {{ interval }} {{'dictionary.minute_plural' | i18next}} </mat-option>
            </mat-select>
          </mat-form-field> -->
          <mat-form-field>
            <mat-select formControlName="service" [placeholder]="'reseller:scenes.add.service' | i18nextCap">
              <mat-option *ngFor="let service of dynamicServices" [value]="service">
                {{ 'reseller:scenes.add.' + service | i18next | titlecase}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select formControlName="space" [placeholder]="'reseller:scenes.add.space' | i18nextCap">
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="spaceFilter"
                  [placeholderLabel]="'reseller:scenes.add.find-space' | i18nextCap"
                  [noEntriesFoundLabel]="'dictionary.no-search-result' | i18nextCap"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let space of filteredSpaces$ | async" [value]="space.value"> {{space.label | titlecase}} </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- GEOLOCATION -->
          <!-- <section formGroupName="geolocation">
            <section fxLayout="row wrap" fxLayoutGap="0.5rem grid">
              <mat-form-field appearance="legacy" fxFlex="50">
                <mat-label>{{'common:dictionary.longitude' | i18nextCap}}</mat-label>
                <input type="number" matInput formControlName="longitude" />
              </mat-form-field>
              <mat-form-field appearance="legacy" fxFlex="50">
                <mat-label>{{'common:dictionary.latitude' | i18nextCap}}</mat-label>
                <input type="number" matInput formControlName="latitude" />
              </mat-form-field>
            </section>
          </section> -->
        </section>
      </ng-container>
    </form>
  </ng-container>

  <ng-container footer>
    <section *ngIf="form" class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
