import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { DxfService } from '@summa/shared/dxf';

import { ENV } from '@summa/models';
import { ProjectFloorplansAddSandbox } from './project-floorplans-add.sandbox';

@Component({
  selector: 'summa-project-floorplans-add',
  templateUrl: './project-floorplans-add.page.html',
  styleUrls: ['./project-floorplans-add.page.scss'],
  providers: [ProjectFloorplansAddSandbox],
})
export class ProjectFloorplansAddPage implements OnInit, OnDestroy {
  form: FormGroup;

  floorplanLayer: FormControl;
  fixtureLayer: FormControl;

  dxfString$ = new Subject();
  dxfLayers$ = new BehaviorSubject<any>(null);
  dxfFileName$ = new Subject<string>();

  fileName: string;

  destroy$ = new Subject();
  submit$ = new Subject();

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  constructor(
    private fb: FormBuilder,
    public sandbox: ProjectFloorplansAddSandbox,
    private helper: DxfService,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      floorplanLayer: ['', Validators.required],
      fixtureLayer: ['', Validators.required],
    });

    this.handleUploadData();
    this.handleSubmit();
    this.handleSuccess();
  }

  private handleUploadData(): void {
    this.dxfString$.pipe(takeUntil(this.destroy$)).subscribe((dxf: any) => {
      this.helper.setContent(dxf);
    });
  }

  private handleSubmit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.submit$.pipe(withLatestFrom(this.sandbox.project$, this.dxfFileName$), takeUntil(this.destroy$)).subscribe(([_, project, fileName]) => {
      if (this.form.invalid) return;
      const form = this.form.value;
      const dxfString = this.helper.parsedDxfString;
      const fixtures = this.helper.extractFixtureLocations(form.fixtureLayer);
      const dxfJsonData = this.helper.toJson(dxfString, form.floorplanLayer);
      const dataInFile = new Blob([dxfJsonData], { type: 'application/json;charset=utf-8' });
      const file = this.createFile(dataInFile, fileName);

      this.sandbox.uploadFile(this.environment.awsConfig.floorplan.bucket, file, project.key);
      // TODO: ADD TO PROJECT / MONGODB
      const floorplan = {
        name: fileName.toLowerCase(),
        projectKey: project.key,
        description: '',
        fileName: `${fileName}.json`,
        fileLocation: `${this.environment.floorplanUrl}/${project.key}/${file.name}`,
      };
      const fixtureInputs = fixtures.map((fixture) => ({
        floorplanKey: '',
        location: {
          x: fixture.x,
          y: fixture.y,
          height: 0,
        },
        locationKey: fixture.iconData.handle,
        iconData: fixture.iconData,
        mainGroup: '',
        devices: [],
        status: 'to-be-configured',
      }));

      this.sandbox.InsertFloorplanAndFixture(floorplan, fixtureInputs);
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      if (!state.isSuccessful) return;

      this.sandbox.close();
      this.sandbox.reset();
    });
  }

  private createFile = (data: Blob, fileName: string): File => {
    const blob: any = data;
    blob.lastModifiedDate = Date.now();
    blob.name = `${fileName}.json`;
    return data as File;
  };
}
