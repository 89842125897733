import { flatten } from '@nestjs/common';
import { DOMAIN, PortalAPI } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';

export const PROJECT_ROLES = (reseller: PortalAPI.Reseller, project: DTO.Project, controlPanelIds: string[] = []): DOMAIN.ApplicationRole => {
  const locationId =
    project.location?.id || flatten(reseller.clients.map((c) => c.locations.map((l) => l.projects.find((p) => p.id === project.key) && l.id)))[0];

  let controlPanelUserPermissions = [];
  let controlPanelAdminPermissions = [];
  // let controlPanelUserPermissions = [`reseller:${reseller.id}:project:${project.key}:control-panel:*:read`];
  // let controlPanelAdminPermissions = [
  //   ...controlPanelUserPermissions,
  //   `reseller:${reseller.id}:project:${project.key}:control-panel:*:upsert`,
  //   `reseller:${reseller.id}:project:${project.key}:control-panel:*:delete`,
  // ];

  if (controlPanelIds.length > 0) {
    controlPanelUserPermissions = controlPanelIds.map((c) => `reseller:${reseller.id}:project:${project.key}:control-panel:${c}:read`);

    controlPanelAdminPermissions = [
      ...controlPanelUserPermissions,
      ...controlPanelIds
        .map((c) => [
          `reseller:${reseller.id}:project:${project.key}:control-panel:${c}:upsert`,
          `reseller:${reseller.id}:project:${project.key}:control-panel:${c}:delete`,
        ])
        .flat(),
    ];
  }

  const dashboardPermissions = [`customer:${locationId}:project:${project.key}:::read`];

  const userPermissions = [
    ...dashboardPermissions,
    `customer:${locationId}:project:${project.key}:::read`,
    `reseller:${reseller.id}:project:${project.key}:::read`,
  ];

  const adminPermissions = [
    ...userPermissions,
    ...controlPanelAdminPermissions,
    `reseller:${reseller.id}:project:${project.key}:::upsert`,
    `reseller:${reseller.id}:project:${project.key}:::delete`,
    `reseller:${reseller.id}:project:${project.key}:user-management:*:read`,
    `reseller:${reseller.id}:project:${project.key}:user-management:*:upsert`,
    `reseller:${reseller.id}:project:${project.key}:user-management:*:delete`,
  ];

  return {
    application: 'project',
    roles: [
      {
        role: 'roles.project-admin',
        permissions: adminPermissions,
      },
      {
        role: 'roles.project-maintainer',
        permissions: userPermissions,
      },
      {
        role: 'roles.project-user',
        permissions: dashboardPermissions,
      },
      {
        role: 'roles.control-panel-user',
        permissions: controlPanelUserPermissions,
      },
      {
        role: 'roles.control-panel-admin',
        permissions: controlPanelAdminPermissions,
      },
    ],
  };
};
