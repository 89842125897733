<ng-container *ngIf="sandbox.automation$ | async as automation">
  <!-- INTERVAL SELECTOR -->
  <mat-form-field appearance="legacy" class="w-100 px-2 mt-2 mb-0">
    <mat-label>{{ 'component:form.timezone.placeholder' | i18nextCap }}</mat-label>
    <mat-select [value]="timeInterval$ | async" (selectionChange)="timeInterval$.next($event.value)">
      <mat-option *ngFor="let option of timeInterval" value="{{ option.key }}">
        {{ 'dictionary.time-interval.' + option.key | i18nextCap }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-accordion displayMode="flat" multi="true">
    <!-- HISTORY -->
    <ng-container *ngIf="sandbox.logbookChart$ | async as chart">
      <mat-expansion-panel [expanded]="true" *ngIf="automation.type == 'motion'">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'dictionary.history' | i18nextCap | uppercase }}</mat-panel-title>
        </mat-expansion-panel-header>
        <summa-timing-diagram [data]="chart"></summa-timing-diagram>
      </mat-expansion-panel>
    </ng-container>
    <!-- LOGBOOK -->
    <mat-expansion-panel *ngIf="automation.settings.triggers.length > 0">
      <mat-expansion-panel-header>
        <mat-panel-title> {{ 'dictionary.logbook' | i18nextCap | uppercase }} </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="logbook w-100" fxLayout="column">
        <section class="logbook-entry" fxLayout="row" fxLayoutAlign="start center" *ngFor="let log of sandbox.logbook$ | async">
          <div>
            <ng-container [ngSwitch]="log.state">
              <div class="badge success m-0" *ngSwitchCase="'on'">&nbsp;</div>
              <div class="badge unknown m-0" *ngSwitchCase="'off'">&nbsp;</div>
              <div class="badge warning m-0" *ngSwitchCase="'idle'">&nbsp;</div>
            </ng-container>
          </div>
          <div fxLayout="column" class="ml-2">
            <div>
              {{ log.state | uppercase }}
            </div>
            <div>
              {{ log.time | date: 'd/MM/yy, h:mm a' }}
            </div>
          </div>
        </section>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
