import { Injectable, Inject } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import { CONSTANTS, DOMAIN, ENV, PERMISSIONS } from '@summa/models';
import { I18NextCapPipe } from 'angular-i18next';
import { Observable, combineLatest, map, withLatestFrom } from 'rxjs';
import { Counter } from '@summa/shared/dashboard/ui';
import { DTO } from '@summa/portal/models/dto';
import { GlobalProjectPermissions } from '../../../../permission-access';
import { CommandService, DriverQuery, DriverService, FixtureStateService, ProjectQuery } from '../../../../data-access';

@Injectable()
export class ProjectDriverWidgetSandbox {
  project$ = this.projectQuery.selectActive();
  params$ = this.routerQuery.selectParams();
  drivers$ = this.driverQuery.selectAll().pipe(
    withLatestFrom(this.params$),
    map(([drivers, params]) => drivers.filter((d) => d.projectKey === params.projectKey)),
  );

  totalDrivers$ = this.drivers$.pipe(map((drivers) => drivers.length));
  onlineDrivers$ = this.drivers$.pipe(map((drivers) => drivers.filter((d: DTO.Driver) => d.state === 'online').length));
  offlineDrivers$ = combineLatest([this.totalDrivers$, this.onlineDrivers$]).pipe(
    map(([total, online]) => {
      return { count: total >= 0 ? total - online : 0 };
    }),
  );

  driverCounters$ = combineLatest([this.onlineDrivers$, this.offlineDrivers$]).pipe(
    map(([onlineDrivers, offlineDrivers]): Counter[] => {
      return [
        {
          title: this.i18next.transform('project:driver-widget.online'),
          class: 'success',
          count: onlineDrivers,
        },
        {
          title: this.i18next.transform('project:driver-widget.offline'),
          class: 'danger',
          count: offlineDrivers.count,
          tooltip: this.i18next.transform('project:driver-widget.offline-description', { field: `${this.logThreshold}` }),
        },
      ];
    }),
  );

  updatePermission$: Observable<PERMISSIONS.Permission> = this.globalProjectPermission.updateProjectPermission$;

  readonly logThreshold = this.environment.fixtureLogThreshold;

  constructor(
    private routerQuery: RouterQuery,
    private commandService: CommandService,
    private driverService: DriverService,
    private driverQuery: DriverQuery,
    private fixtureStateService: FixtureStateService,
    private projectQuery: ProjectQuery,
    private i18next: I18NextCapPipe,
    private globalProjectPermission: GlobalProjectPermissions,
    @Inject('environment') private environment: ENV.Environment,
  ) {}

  reset(): void {
    this.fixtureStateService.reset();
  }

  getDrivers(projectKey: string): void {
    this.driverService.getDriverList(projectKey);
  }

  observeFixtureState(projectKey: string): void {
    this.fixtureStateService.subscribe([projectKey]);
  }

  executeSync(projectKey: string, subject: CONSTANTS.SyncType, mode: 'partial' | 'full'): void {
    this.commandService.executeSyncCommand({ projectKey, subject, mode });
  }

  executeSetBaseline(projectKey: string, baseline: number = DOMAIN.defaultSensorBaselineFrames): void {
    this.commandService.executeSetBaselineCommand({ projectKey, baseline });
  }

  executeCurrentCheck(projectKey: string): void {
    this.commandService.executeMqttCommand({ projectKey, topic: CONSTANTS.mqttCurrentCheckIn, message: '{}' });
  }
}
