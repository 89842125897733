import { AbstractControl } from '@angular/forms';

/**
 *  Makes the field required to have a value after trimming leading & trailing whitespaces
 *
 *  EXAMPLE:
 *  this.myForm = this.fb.group({
 *    name: ['', Validators.required, TrimValidator],
 * })
 */
export function TrimValidator(formControl: AbstractControl) {
  if (!formControl.parent) return null;

  const isWhitespace = (formControl.value || '').trim().length === 0;
  return !isWhitespace ? null : { whitespace: true };
}
