import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { ProjectFloorplansDetailsSandbox } from './project-floorplans-details.sandbox';

@Component({
  selector: 'summa-project-floorplans-details',
  templateUrl: './project-floorplans-details.page.html',
  styleUrls: ['./project-floorplans-details.page.scss'],
  providers: [ProjectFloorplansDetailsSandbox],
})
export class ProjectFloorplansDetailsPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  links = ['drivers', 'groups', 'admin'];
  activeLink = '';

  constructor(public sandbox: ProjectFloorplansDetailsSandbox) {}

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleProjectKey();
    this.handleFloorplan();
  }

  private handleProjectKey(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe(({ projectKey }) => {
      this.sandbox.getDrivers(projectKey);
      this.sandbox.getFloorplans(projectKey);
    });

    this.sandbox.url$.pipe(takeUntil(this.destroy$)).subscribe((url) => {
      const activeLink = url.state.urlAfterRedirects.split('/').reverse()[0];
      this.activeLink = activeLink;
    });
  }

  private handleFloorplan(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.sandbox.floorplans$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([_, { floorplanKey }]) => {
      this.sandbox.getFloorplan(floorplanKey);
    });
  }
}
