import { DOMAIN } from '@summa/models';

export const staticMaxPHIv = 4095;

export function calculateDimPercentage(fixtureLog: DOMAIN.FixtureState, channel?: string): number {
  if (!fixtureLog) return -1;

  // when fusion
  if (fixtureLog?.type.toString() === DOMAIN.commandTypeFusion) {
    return Math.floor((fixtureLog.dimLevel / staticMaxPHIv) * 100);
  }

  // default = N-Channel
  const intensity = fixtureLog.channels.find((ch) => ch.id === channel)?.intensity;
  return intensity ? Math.floor((intensity / staticMaxPHIv) * 100) : -1;
}
