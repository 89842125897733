import { gql } from 'apollo-angular';

export const GetPresetsQuery = gql`
  query getPresets($resellerId: String!) {
    getPresets(resellerId: $resellerId) {
      id
      # resellerId
      name
      description
      # cct
      # duv
      # mA
      # x
      # y
    }
  }
`;

export const GetPresetQuery = gql`
  query getPreset($id: String!) {
    getPreset(id: $id) {
      id
      resellerId
      name
      description
      cct
      duv
      x
      y
    }
  }
`;
