import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { DOMAIN, UI } from '@summa/models';
import { Observable } from 'rxjs';

import { DocumentsState, DocumentStore } from './documents.store';

@Injectable({
  providedIn: 'root',
})
export class DocumentQuery extends QueryEntity<DocumentsState, DOMAIN.Document> {
  selectUpsertState$: Observable<UI.State>;
  selectRemoveState$: Observable<UI.State>;

  constructor(protected store: DocumentStore) {
    super(store);

    this.selectUpsertState$ = this.select((state) => state.ui.upsert);
    this.selectRemoveState$ = this.select((state) => state.ui.remove);
  }
}
