/** --------------------------------
 * DEVICE TYPE
 -------------------------------- */

export const deviceNChannel = 'nChannel';
export const device18Up = '18up'; // Fusion
export const device27Up = '27up'; // Fusion
export const device33Up = '33up'; // Fusion
export const deviceFusionCob = 'fusionCob'; // Fusion Cob
export const deviceLedStrip = 'ledStrip'; // Fusion
export const deviceBoard = 'board'; // Fusion
export const deviceBoard2 = 'board2'; // Fusion
export const deviceControl = 'control';

export const deviceType = [
  device18Up,
  device27Up,
  device33Up,
  deviceNChannel,
  deviceFusionCob,
  deviceLedStrip,
  deviceBoard,
  deviceBoard2,
  deviceControl,
] as const;
export type DeviceType = typeof deviceType[number];
export const fusionDeviceTypes = [device18Up, device27Up, device33Up, deviceFusionCob, deviceBoard, deviceBoard2, deviceLedStrip];

/**
 * Checks if the device type belongs to the fusion collection
 * @param type DriverType
 */
export const isFusion = (type: string): boolean => fusionDeviceTypes.includes(type);
export const isFusionUps = (type: string): boolean => [device18Up, device27Up, device33Up].includes(type);

/** --------------------------------
 * DEVICE CATEGORY
 -------------------------------- */

export const deviceCategoryLight = 'light';
export const deviceCategorySensor = 'sensor';
export const deviceCategoryControl = 'control';

export const deviceCategory = [deviceCategoryLight, deviceCategorySensor, deviceCategoryControl] as const;
export type DeviceCategory = typeof deviceCategory[number];

/** --------------------------------
 * DEVICE POWER USAGE
 -------------------------------- */
export const nChannelMinCurrent = 100;
export const nChannelMaxCurrent = 360;
export const nChannelDefaultCurrent = 360;

export const singleLedMaxCurrent = 1000;

/** --------------------------------
 * DEVICE CONTROL ACTIONS
 -------------------------------- */
export const deviceControlActionSingle = 'single';
export const deviceControlActionDouble = 'double';
export const deviceControlActionLongPress = 'long';

export const deviceControlAction = [deviceControlActionSingle, deviceControlActionDouble, deviceControlActionLongPress] as const;
export type DeviceControlAction = typeof deviceControlAction[number];
