/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * usage example:
 * var obj1 = { foo: 'bar' };
 * var obj2 = { foo: 'bar' };
 * obj1 === obj1 => false
 * isEqual(obj1, obj2) => true
 *
 * @param value1
 * @param value2
 */
export function isEqual(value1: any, value2: any): boolean {
  if (isObject(value1) && isObject(value2)) return JSON.stringify(value1) === JSON.stringify(value2);
  return value1 === value2;
}

/**
 * usage example:
 * isObject({ foo: 'bar' }) => true
 * isObject('foo') => false
 *
 * @param value
 */
export function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}
