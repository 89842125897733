import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';
import { SidePanelService } from '@summa/shared/ui/dialogs';

import { ResellerLocationAddPage } from '../../locations/location-add/reseller-location-add.page';
import { ResellerClientAddPage } from '../client-add/reseller-client-add.page';

import { ResellerClientDetailsSandbox } from './reseller-client-details.sandbox';

@Component({
  selector: 'summa-reseller-client-details',
  templateUrl: './reseller-client-details.page.html',
  styleUrls: ['./reseller-client-details.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResellerClientDetailsSandbox],
})
export class ResellerClientDetailsPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  upsertClientPanel$ = new Subject<PortalAPI.Client>();
  upsertLocationPanel$ = new Subject<PortalAPI.Location>();
  clientUpsertPermission = null;

  constructor(public sandbox: ResellerClientDetailsSandbox, private router: Router, private sidePanelService: SidePanelService) {}

  ngOnInit(): void {
    this.handleRoute();
    this.handleLocationUpsertSuccess();
    this.handleUpsertClientPanel();
    this.handleUpsertLocationPanel();
    this.sandbox.resetActiveProject();
  }

  ngOnDestroy(): void {
    this.sandbox.resetActiveClient();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  navigateTo(projectKey: string): void {
    this.sandbox.params$.subscribe((params: Params) => {
      this.router.navigate(['reseller', params.resellerId, 'projects', projectKey]);
    });
  }

  openTab(event: Event, projectKey: string): void {
    event.stopPropagation();
    window.open(`/customer/${projectKey}`, '_blank');
  }

  private handleUpsertLocationPanel(): void {
    this.upsertLocationPanel$.pipe(takeUntil(this.destroy$)).subscribe((location: PortalAPI.Location) => {
      this.sidePanelService.open(ResellerLocationAddPage, location);
    });
  }

  private handleUpsertClientPanel(): void {
    this.upsertClientPanel$.pipe(takeUntil(this.destroy$)).subscribe((client: PortalAPI.Client) => {
      this.sidePanelService.open(ResellerClientAddPage, client);
    });
  }

  private handleRoute(): void {
    this.sandbox.params$.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => this.sandbox.checkActive(params.clientKey));
  }

  private handleLocationUpsertSuccess(): void {
    this.sandbox.upsertLocationState$.pipe(withLatestFrom(this.sandbox.params$), takeUntil(this.destroy$)).subscribe(([state, params]) => {
      if (state.isSuccessful) {
        this.sandbox.getClient(params.clientKey);
        this.sidePanelService.close();
      }
    });
  }
}
