import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DTO } from '@summa/portal/models/dto';
import { DOMAIN, PERMISSIONS } from '@summa/models';

import { AuthenticationService, AuthQuery, ProjectQuery, ProjectService } from '../../../data-access';

@Injectable()
export class ApplicationSandbox {
  visibleApplications = [PERMISSIONS.applicationReseller, PERMISSIONS.applicationCustomer];
  currentUser$: Observable<DOMAIN.User>;
  activeProject$: Observable<DTO.Project>;
  applications$: Observable<string[]>;

  constructor(
    private authQuery: AuthQuery,
    private authenticationService: AuthenticationService,
    private projectQuery: ProjectQuery,
    private projectService: ProjectService,
  ) {
    this.currentUser$ = this.authQuery.selectActive();
    this.activeProject$ = this.projectQuery.selectActive();
    this.applications$ = this.currentUser$.pipe(
      map((user) =>
        user?.permissions
          .map((p) => p.application.name)
          .filter((x, i, a) => a.indexOf(x) === i)
          .filter((x) => this.visibleApplications.includes(x))
          .sort((a, b) => a.localeCompare(b)),
      ),
    );
  }

  getSpecificProject(key: string): void {
    this.projectService.getProject(key);
  }

  logout(): void {
    this.authenticationService.logOut();
  }
}
