import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CONSTANTS, ENV } from '@summa/models';
import { DTO } from '@summa/portal/models/dto';
import { ConfirmationDialogComponent, ConfirmationModalData, defaultDeletionString, SidePanelService } from '@summa/shared/ui/dialogs';
import { userHasPermission } from '@summa/shared/auth/util';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { I18NextCapPipe } from 'angular-i18next';
import { filter, first, Subject, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs';
import { ControlPanelUpsertPage } from '../upsert/control-panel-upsert.page';

import { ControlPanelDetailSandbox } from './control-panel-detail.sandbox';
import { ControlPanelUpsertControlPage } from './control-panel-upsert-control/control-panel-upsert-control.page';
import { controlPanelUpsertPermission } from '../control-panel.constants';

@Component({
  selector: 'summa-control-panel-detail',
  templateUrl: './control-panel-detail.page.html',
  styleUrls: ['./control-panel-detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ControlPanelDetailSandbox],
})
export class ControlPanelDetailPage implements OnInit, OnDestroy {
  isPortal = true;
  destroy$ = new Subject<void>();
  updateControlPanel$ = new Subject<string>();
  upsertControl$ = new Subject<DTO.ControlPanelControl | null>();
  removeControl$ = new Subject<DTO.ControlPanelControl | null>();
  executeControl$ = new Subject<{ control: DTO.ControlPanelControl; isActive: boolean }>();

  confirmDialog?: MatDialogRef<ConfirmationDialogComponent, MatDialogConfig>;

  userHasEditPermission = true;

  constructor(
    private dialog: MatDialog,
    private i18next: I18NextCapPipe,
    public sandbox: ControlPanelDetailSandbox,
    private sidePanelService: SidePanelService,
    @Inject('environment') private environment: ENV.Environment,
  ) {
    this.isPortal = this.environment.app.isPortal;
  }

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleRouteParam();
    this.handleUpsertControl();
    this.handleRemoveControl();
    this.handleExecuteControl();
    this.handleUpdateControlPanel();
    this.handleUser();
  }

  private handleUser(): void {
    this.sandbox.currentUser$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.userHasEditPermission = userHasPermission(user.permissions, controlPanelUpsertPermission);
    });
  }
  private handleRouteParam(): void {
    this.sandbox.routeParams$.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (this.isPortal) {
        this.sandbox.getProject(params.projectKey);
        this.sandbox.getScenes(params.projectKey);
        this.sandbox.getDrivers(params.projectKey);
        this.sandbox.getGroups(params.projectKey);
      }
      this.sandbox.getControlPanel(params.controlPanelId);
      this.sandbox.observeControlPanel(params.controlPanelId);
    });
  }

  private handleUpsertControl(): void {
    this.upsertControl$.pipe(withLatestFrom(this.sandbox.controlPanel$), takeUntil(this.destroy$)).subscribe(([control, controlPanel]) => {
      this.sidePanelService.open(ControlPanelUpsertControlPage, { control, availableDevices: controlPanel.devices });
    });
  }

  private handleRemoveControl(): void {
    this.removeControl$
      .pipe(
        filter(isNotNullOrUndefined),
        withLatestFrom(this.sandbox.routeParams$),
        switchMap(([control, params]) => {
          const dialogConfig: MatDialogConfig<ConfirmationModalData> = {
            autoFocus: true,
            data: {
              showConfirmationInput: true,
              title: this.i18next.transform('common:dialog.confirm-remove.title'),
              content: this.i18next.transform('common:dialog.confirm-remove.content', { field: control.name }),
              cancelButton: this.i18next.transform('common:buttons.cancel'),
              primaryButton: this.i18next.transform('common:buttons.delete'),
              confirmationInputLabel: this.i18next.transform('component:confirmation-dialog.delete-confirmation-input', {
                field: defaultDeletionString,
              }),
              confirmationString: defaultDeletionString,
            },
          };

          this.confirmDialog = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
          return this.confirmDialog.componentInstance.confirm.pipe(
            first(),
            tap(() => this.sandbox.removeControlPanelControl(control.id, params.controlPanelId)),
            tap(() => this.confirmDialog?.close()),
          );
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private handleExecuteControl(): void {
    this.executeControl$
      .pipe(filter(isNotNullOrUndefined), withLatestFrom(this.sandbox.routeParams$), takeUntil(this.destroy$))
      .subscribe(([{ control, isActive }, params]) => {
        const state = isActive ? CONSTANTS.stateOn : CONSTANTS.stateOff;
        this.sandbox.executeControlCommand(params.controlPanelId, control.id, state, params.projectKey);
      });
  }

  private handleUpdateControlPanel(): void {
    this.updateControlPanel$.pipe(takeUntil(this.destroy$)).subscribe((id) => {
      this.sidePanelService.open(ControlPanelUpsertPage, id);
    });
  }
}
