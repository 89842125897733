import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, withLatestFrom } from 'rxjs/operators';

import { PERMISSIONS } from '@summa/models';
import { flatten } from '@summa/shared/util/typescript';

import { ResellerSandbox } from './reseller.sandbox';

@Component({
  selector: 'summa-reseller-shell',
  template: `<section class="header-gradient">
      <summa-portal-page-header [title]="title$ | async" [currentUser]="(sandbox.currentUser$ | async).username" (logout)="logOut()">
        <mat-icon
          *ngIf="sandbox.currentReseller$ | async"
          (click)="notifications$.next(null)"
          class="mr-3"
          aria-hidden="false"
          aria-label="Logout"
          [svgIcon]="'common:icons.notification' | i18next"
          [matBadge]="'' + ((sandbox.liveNotifications$ | async)?.length || 0)"
        ></mat-icon>
      </summa-portal-page-header>
    </section>
    <router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResellerSandbox],
})
export class ResellerShellPage implements OnInit, OnDestroy {
  destroy$ = new Subject();
  title$ = new BehaviorSubject<string>('');
  notifications$ = new Subject();

  constructor(private router: Router, public sandbox: ResellerSandbox) {}

  ngOnInit(): void {
    this.handleTitleHeader();
    this.handleParams();
    this.handleNotificationClick();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  logOut(): void {
    this.sandbox.logout();
    this.sandbox.navigate(['/login']);
  }

  private handleTitleHeader(): void {
    const resolve = (url: string) => this.resolveTitle(url.split('/'));
    this.title$.next(resolve(this.router.url));
    this.sandbox.routeChanges$.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
      this.title$.next(resolve(event.url));
    });
  }

  private handleParams(): void {
    this.sandbox.params$
      .pipe(
        distinctUntilChanged((prev, curr) => prev.resellerId === curr.resellerId),
        takeUntil(this.destroy$),
      )
      .subscribe((params) => {
        // Get active reseller
        if (params.resellerId) {
          this.sandbox.getReseller(params.resellerId);
        } else {
          this.sandbox.resetActiveReseller();
        }
      });

    // When user is logged in and has selected a reseller
    this.sandbox.currentReseller$
      .pipe(withLatestFrom(this.sandbox.currentUser$, this.sandbox.params$), takeUntil(this.destroy$))
      .subscribe(([reseller, user, params]) => {
        if (!reseller || !user) return;
        const projects = params.projectKey
          ? [params.projectKey]
          : flatten(reseller.clients.map((client) => client.locations.map((location) => location.projects.map((project) => project.key)))).filter(
              (project) =>
                user.permissions.filter(
                  (perm) =>
                    perm.application.qualifiers.includes(reseller.id) &&
                    perm.domain.name === 'project' &&
                    perm.domain.qualifiers.some((q) => q === project || q === PERMISSIONS.wildcardQualifier),
                ),
            );
        // Observe all projects for notifications
        this.sandbox.observeNotifications(projects);
      });
  }

  private handleNotificationClick(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.notifications$.pipe(withLatestFrom(this.sandbox.currentReseller$), takeUntil(this.destroy$)).subscribe(([_, reseller]) => {
      this.sandbox.clearNotification();
      this.sandbox.navigate(['reseller', reseller.id, 'notifications']);
    });
  }

  // eslint-disable-next-line consistent-return
  private resolveTitle(urlSegments: string[]): string {
    switch (true) {
      case urlSegments.length === 2: // ''/reseller
        return 'reseller:common.overview';
      case urlSegments.length === 3: // ''/reseller/:id
        return 'reseller:common.dashboard';
      case urlSegments.length === 4 && urlSegments[3] === 'projects': // ''/reseller/:id/projects
        return 'reseller:common.projects';
      case urlSegments.length === 5 && urlSegments[3] === 'projects': // ''/reseller/:id/projects/:projectId
        return 'reseller:common.project-details';
      case urlSegments.length === 4 && urlSegments[3] === 'clients': // ''/reseller/:id/projects
        return 'reseller:common.clients';
      default:
    }
  }
}
