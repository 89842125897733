<ng-container *ngIf="sandbox.application$  |async as application">
  <ng-container *ngIf="notification$ | async as notification">
    <ng-container [ngSwitch]="notification">
      <div *ngSwitchCase="gatewayOutdatedVersion" class="alert alert-warning" fxLayout="row center">
        <mat-icon svgIcon="alert-octagon-outline"></mat-icon>
        <section
          class="pl-1"
          fxFlexAlign="center"
          [innerHTML]="'project:notification.' + notification | i18nextCap: { version: application?.minimumRequiredGatewayApiVersion }"
        ></section>
      </div>
      <div *ngSwitchCase="gatewayNewVersion" class="alert alert-info" fxLayout="row center">
        <mat-icon svgIcon="information-outline"></mat-icon>
        <section
          class="pl-1"
          fxFlexAlign="center"
          [innerHTML]="'project:notification.' + notification | i18nextCap: { version: application?.latestGatewayVersion }"
        ></section>
      </div>

      <div *ngSwitchDefault>
        <!-- show nothing -->
      </div>
    </ng-container>
  </ng-container>
</ng-container>
