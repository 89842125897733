import { duvDefault, cctDefault, dimValueDefault, cctMax, cctMin, dimValueMax, dimValueMin, duvMax, duvMin } from './spectrum.constant';

export const defaultSceneSettings = {
  cctDft: cctDefault,
  cctMin,
  cctMax,

  dimValueDft: dimValueDefault,
  dimValueMin,
  dimValueMax,

  duvDft: duvDefault,
  duvMin,
  duvMax,
};
