<summa-side-panel-content [showInfo]="true" class="side-panel">
  <ng-container header>
    <!-- <h1>{{ 'project:floorplans.add-floorplan' | i18nextCap }}</h1> -->
    <h1>{{ 'project:floorplans.admin.re-upload-floorplan' | i18nextCap }}</h1>
  </ng-container>
  <ng-container info>
    <section class="section">
      <div *ngIf="sandbox.project$ |async as project">
        <label fxFlex="15">{{'common:dictionary.project' | i18nextCap}}</label>
        <div fxFlex="auto">({{ project.key }}) {{ project.name}}</div>
      </div>
    </section>
  </ng-container>
  <ng-container body>
    <form *ngIf="form" [formGroup]="form" class="form">
      <section fxLayout="column">
        <summa-upload-dxf
          (dxfString)="dxfString$.next($event)"
          (dxfLayers)="dxfLayers$.next($event)"
          (dxfFileName)="dxfFileName$.next($event)"
        ></summa-upload-dxf>
      </section>
      <ng-container *ngIf="(dxfLayers$ |async)">
        <section fxLayout="column">
          <h4>{{ 'project:floorplans.select-floorplan-layer' | i18nextCap }}</h4>
          <mat-form-field>
            <mat-select formControlName="floorplanLayer" placeholder="Layers">
              <mat-option *ngFor="let layer of dxfLayers$ | async" [value]="layer"> {{ layer }} </mat-option>
            </mat-select>
          </mat-form-field>
          <hr />
          <h4>{{ 'project:floorplans.select-fixture-layer' | i18nextCap }}</h4>
          <mat-form-field>
            <mat-select formControlName="fixtureLayer" placeholder="Layers">
              <mat-option *ngFor="let layer of dxfLayers$ | async" [value]="layer"> {{ layer }} </mat-option>
            </mat-select>
          </mat-form-field>
        </section>
      </ng-container>
      <ng-container *ngIf="dxfFileName$ |async">
        <div>
          <h4>{{ 'project:floorplans.admin.select-re-upload-option' | i18nextCap }}</h4>
          <section fxLayout="row" class="mb-1">
            <mat-radio-group formControlName="shouldUploadFloorplanOnly" aria-label="Select an option" fxLayoutGap="1rem">
              <mat-radio-button [value]="true" (change)="isOverrideFixture$.next(false)"
                >{{ 'project:floorplans.admin.floorplan-only' | i18nextCap }}</mat-radio-button
              >
              <mat-radio-button [value]="false" (change)="isOverrideFixture$.next(true)"
                >{{ 'project:floorplans.admin.override-fixtures' | i18nextCap }}</mat-radio-button
              >
            </mat-radio-group>
          </section>
        </div>
        <section *ngIf="isOverrideFixture$ |async">
          <h4>{{ 'project:floorplans.admin.select-fixture-field' | i18nextCap }}</h4>
          <section fxLayout="row" fx fxLayoutGap="3rem">
            <section fxLayout="column">
              <mat-checkbox (change)="handleCheckboxValue('devices')"
                >{{ 'project:floorplans.admin.fixture-field.devices' | i18nextCap }}</mat-checkbox
              >
              <mat-checkbox (change)="handleCheckboxValue('mainGroup')"
                >{{ 'project:floorplans.admin.fixture-field.main-group' | i18nextCap }}</mat-checkbox
              >
              <mat-checkbox (change)="handleCheckboxValue('type')">{{ 'project:floorplans.admin.fixture-field.type' | i18nextCap }}</mat-checkbox>
            </section>
            <section fxLayout="column">
              <mat-checkbox (change)="handleCheckboxValue('location')"
                >{{ 'project:floorplans.admin.fixture-field.location' | i18nextCap }}</mat-checkbox
              >
              <mat-checkbox (change)="handleCheckboxValue('iconData')"
                >{{ 'project:floorplans.admin.fixture-field.icon-fixture' | i18nextCap }}</mat-checkbox
              >
            </section>
          </section>
        </section>
      </ng-container>
    </form>
  </ng-container>

  <ng-container footer>
    <section class="buttons" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <button (click)="sandbox.close()" mat-button type="button" class="button cancel">{{'common:buttons.cancel' | i18nextCap}}</button>
      <button mat-button type="button" class="button save" [disabled]="form.invalid" (click)="submit$.next(null)">
        {{'common:buttons.save' | i18nextCap}}
      </button>
    </section>
  </ng-container>
</summa-side-panel-content>
