import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PortalAPI } from '@summa/models';

import { isNotNullOrUndefined, DirtyCheck } from '@summa/shared/util/typescript';
import { TrimValidator } from '@summa/shared/util/validators';
import { ResellerAddSandbox } from './reseller-add.sandbox';

@Component({
  selector: 'summa-reseller-add',
  templateUrl: './reseller-add.page.html',
  styleUrls: ['./reseller-add.page.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ResellerAddSandbox],
})
export class ResellerAddPage implements OnInit, OnDestroy {
  form: FormGroup;
  destroy$ = new Subject();
  submit$ = new Subject();
  store$ = new BehaviorSubject(null);

  constructor(public sandbox: ResellerAddSandbox, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.sandbox.reset();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.handleOnSubmit();
    this.handleSuccess();
    this.handleData();
    this.handleFormStore();
    this.handleIsDirty();
  }

  handleOnSubmit(): void {
    this.submit$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.form.valid) return;

      this.sandbox.upsertReseller(this.form.value);
    });
  }

  private handleIsDirty(): void {
    this.form.valueChanges
      .pipe(filter(isNotNullOrUndefined), DirtyCheck(this.store$), takeUntil(this.destroy$))
      .subscribe(this.sandbox.setHasChanges.bind(this.sandbox));
  }

  private handleFormStore(): void {
    this.store$.pipe(takeUntil(this.destroy$)).subscribe((store) => {
      this.form = this.fb.group({
        ...(store?.id && { id: store.id }),
        name: [store.name, [Validators.required, TrimValidator]],
        contact: this.fb.group({
          name: [store.contact.name, []],
          email: [store.contact.email, []],
          phone: [store.contact.phone, []],
        }),
        paymentInfo: this.fb.group({
          accountName: [store.paymentInfo.accountName, []],
          iban: [store.paymentInfo.iban, []],
          bic: [store.paymentInfo.bic, []],
        }),
      });
    });
  }

  private handleData(): void {
    this.sandbox.data$.pipe(takeUntil(this.destroy$)).subscribe((reseller: PortalAPI.Reseller) => {
      if (!reseller) {
        this.store$.next({
          name: '',
          contact: {
            name: '',
            email: '',
            phone: '',
          },
          paymentInfo: {
            accountName: '',
            iban: '',
            bic: '',
          },
        });
        return;
      }

      this.sandbox.getReseller(reseller.id);
    });

    // WHEN EDIT MODE:
    this.sandbox.reseller$.pipe(filter(isNotNullOrUndefined), takeUntil(this.destroy$)).subscribe((reseller: PortalAPI.Reseller) => {
      this.store$.next({
        id: reseller.id,
        name: reseller.name,
        contact: {
          name: reseller.contact.name,
          email: reseller.contact.email,
          phone: reseller.contact.phone,
        },
        paymentInfo: {
          accountName: reseller.paymentInfo?.accountName ?? '',
          iban: reseller.paymentInfo?.iban ?? '',
          bic: reseller.paymentInfo?.bic ?? '',
        },
      });
    });
  }

  private handleSuccess(): void {
    this.sandbox.upsertState$.pipe(takeUntil(this.destroy$)).subscribe((state) => state.isSuccessful && this.sandbox.close());
  }
}
