<ng-container *ngIf="sandbox.card$ | async as card">
  <div class="p-4" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="center start" fxLayout.lt-sm="column">
    <!-- COLUMN.01 -->
    <div fxLayout="column" fxLayoutGap="16px">
      <!-- INFO -->
      <div fxLayout="row wrap" fxLayoutGap="16px">
        <div class="reseller-card md p-2">
          <div class="header" fxLayout="row wrap">{{ card.title }}</div>
          <div class="control-info" fxLayout="row wrap">
            <div class="item" fxFlex="200">
              <div fxLayout="column" fxLayoutGap="8px">
                <div class="item" fxLayout="row wrap">
                  <div class="title orange" fxFlex="16">{{'reseller:cards.details.info.selected-scene' |i18nextCap}}</div>
                  <div class="content" fxFlex="84">{{ card.selectedSceneId }}</div>
                </div>
                <div class="item" fxLayout="row wrap">
                  <div class="title orange" fxFlex="16">{{'reseller:cards.details.info.sort-order' |i18nextCap}}</div>
                  <div class="content" fxFlex="84">{{ card.sortOrder }}</div>
                </div>
                <div class="item" fxLayout="row wrap">
                  <div class="title orange" fxFlex="16">{{'reseller:cards.details.info.hidden' |i18nextCap}}</div>
                  <div class="content" fxFlex="84">{{ card.hidden }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ENTITIES / DEVICES -->
      <div class="reseller-card md p-2">
        <div class="header" fxLayout="row wrap">Devices & Cards</div>
        <div *ngFor="let device of sandbox.devices$ |async; let i=index" class="entity entry">
          <!-- device -->
          <span class="number">Device |</span>

          <div class="title">
            <strong>{{ device.type }} </strong>
            <span class="orange"> | </span>
            {{ device.address }}
          </div>
        </div>
      </div>
    </div>
    <!-- COLUMN.02 -->
    <div fxLayout="row wrap" fxLayoutGap="16px">
      <!-- SCENES -->
      <div class="reseller-card md p-2">
        <div class="header">
          {{'reseller:cards.details.scene-title' | i18nextCap}}
          <div class="create" title="{{'reseller:tooltips.create-control' | i18nextCap}}" (click)="upsertScenePanel$.next(null)">
            <mat-icon fxFlexAlign="start center" [svgIcon]="'common:icons.add' | i18next"></mat-icon>
          </div>
        </div>
        <div *ngFor="let scene of sandbox.scenes$ |async; let i=index" class="scene entry clickable" (click)="openScenePreview(scene.id)">
          <span class="number">{{scene.sortOrder}} |</span>
          <div class="title">{{ scene.name }}</div>
          <div class="action" [title]="'reseller:presets.edit' | i18nextCap" (click)="upsertScenePanel$.next(scene); $event.stopPropagation()">
            <mat-icon [svgIcon]="'file-document-edit-outline'"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
