import { gql } from 'apollo-angular';

export const getHeatGridLogsQuery = gql`
  query getHeatGridLogs($project: String!) {
    getHeatGridLogs(project: $project) {
      project
      timestamp
      address
      sensors
    }
  }
`;
