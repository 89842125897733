import { gql } from 'apollo-angular';
import { ControlFields } from './control.gql-fragment';

export const upsertControlQuery = gql`
  mutation upsertControl($control: ControlInput!) {
    upsertControl(control: $control) {
      ...ControlFields
    }
  }
  ${ControlFields}
`;

export const removeControlQuery = gql`
  mutation removeControl($id: String!) {
    removeControl(id: $id) {
      acknowledged
      deletedCount
    }
  }
`;
