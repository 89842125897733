import { Injectable } from '@angular/core';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { Observable, filter, map, withLatestFrom } from 'rxjs';
import { PERMISSIONS } from '@summa/models';
import { isNotNullOrUndefined } from '@summa/shared/util/typescript';
import { ProjectQuery } from '../../data-access';

@Injectable()
export class GlobalProjectPermissions {
  private project$ = this.projectQuery.selectActive();
  private reseller$ = this.router.selectParams().pipe(map((params) => params.resellerId));

  constructor(private projectQuery: ProjectQuery, private router: RouterQuery) {}

  updateProjectPermission$: Observable<PERMISSIONS.Permission> = this.project$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.reseller$),
    map(([project, reseller]) => ({
      application: {
        name: PERMISSIONS.applicationReseller,
        qualifiers: [reseller],
      },
      domain: {
        name: PERMISSIONS.domainProject,
        qualifiers: [project.key],
      },
      subdomain: null,
      action: PERMISSIONS.actionUpsert,
    })),
  );

  deleteProjectPermission$: Observable<PERMISSIONS.Permission> = this.project$.pipe(
    filter(isNotNullOrUndefined),
    withLatestFrom(this.reseller$),
    map(([project, reseller]) => ({
      application: {
        name: PERMISSIONS.applicationReseller,
        qualifiers: [reseller],
      },
      domain: {
        name: PERMISSIONS.domainProject,
        qualifiers: [project.key],
      },
      subdomain: null,
      action: PERMISSIONS.actionDelete,
    })),
  );

  createProjectPermission$: Observable<PERMISSIONS.Permission> = this.reseller$.pipe(
    filter(isNotNullOrUndefined),
    map((reseller) => ({
      application: {
        name: PERMISSIONS.applicationReseller,
        qualifiers: [reseller],
      },
      domain: {
        name: PERMISSIONS.domainProject,
        qualifiers: [PERMISSIONS.wildcardQualifier],
      },
      subdomain: null,
      action: PERMISSIONS.actionCreate,
    })),
  );
}
