import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export interface Counter {
  title: string;
  count: number;
  class: 'success' | 'warning' | 'danger';
  tooltip?: string;
}

export interface Route {
  destination: string[];
  tooltip: string;
}

@Component({
  selector: 'summa-details-tile-widget',
  templateUrl: './details-tile-widget.component.html',
  styleUrls: ['./details-tile-widget.component.scss'],
  providers: [],
})
export class DetailsTileWidgetComponent implements OnDestroy {
  @Input() counters?: Counter[];
  @Input() title: string;
  @Input() route: Route;
  @Input() totalCountLabel?: string;

  destroy$ = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  totalCount() {
    return this.counters.reduce((acc, curr) => acc + curr.count, 0);
  }
}
