import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { I18NextCapPipe } from 'angular-i18next';
import { NotifierService } from 'angular-notifier';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { BreadcrumbService } from 'xng-breadcrumb';

import { CONSTANTS, PortalAPI } from '@summa/models';

import { removeResellerQuery, upsertResellerQuery, GetResellerQuery, GetResellersQuery } from './graphql';

import { ResellerStore } from './reseller.store';

@Injectable({
  providedIn: 'root',
})
export class ResellerService {
  constructor(
    private apollo: Apollo,
    private breadcrumbService: BreadcrumbService,
    private resellerStore: ResellerStore,
    private notifier: NotifierService,
    private i18next: I18NextCapPipe,
  ) {}

  reset(): void {
    this.resellerStore.reset();
  }

  /* storeReseller(reseller: PortalAPI.Reseller): void {
    this.resellerStore.upsert(reseller.id, reseller);
  } */

  setActive(id: ID | null): void {
    this.resellerStore.setActive(id);
  }

  setBreadcrumb(value: string): void {
    this.breadcrumbService.set('@resellerName', value);
  }

  getReseller(id: string): void {
    this.apollo
      .subscribe({ query: GetResellerQuery, variables: { id } })
      .pipe(map((d: any) => d.data.getReseller as PortalAPI.Reseller))
      .subscribe((reseller: PortalAPI.Reseller) => {
        this.setBreadcrumb(reseller.name);
        this.resellerStore.upsert(reseller.id, reseller);
        this.setActive(reseller?.id);
      });
  }

  // eslint-disable-next-line default-param-last
  getResellers(skip = 0, take = 50, ids?: string[]): void {
    this.apollo
      .subscribe({ query: GetResellersQuery, variables: { skip, take, ...(ids && { ids }) } })
      .pipe(map((d: any) => d.data.getResellers as PortalAPI.Reseller[]))
      .subscribe((resellers: PortalAPI.Reseller[]) => this.resellerStore.set(resellers));
  }

  resetResellerUIState(): void {
    this.resellerStore.resetResellerUIState();
  }

  upsertReseller(reseller: PortalAPI.ResellerInput): void {
    this.resellerStore.updateUpsert({ isLoading: true, isSuccessful: false, errors: null });

    this.apollo.mutate({ mutation: upsertResellerQuery, variables: { reseller } }).subscribe((results) => {
      if (!results || results.errors || !results.data) {
        this.resellerStore.setError(results?.errors ?? this.i18next.transform(CONSTANTS.Errors.SOMETHING_WENT_WRONG));
        // set error:
        const errorMessage = results.errors[0]?.message ?? CONSTANTS.Errors.SOMETHING_WENT_WRONG;
        this.notifier.notify('error', this.i18next.transform(errorMessage, { field: `name ${reseller.name}` }));

        this.resellerStore.updateUpsert({ errors: results?.errors, isLoading: false });
        return;
      }

      const data = results.data as { upsertReseller: PortalAPI.Reseller };
      this.resellerStore.upsert(data.upsertReseller.id, data.upsertReseller);
      this.resellerStore.setActive(data.upsertReseller.id);

      // set success:
      this.resellerStore.updateUpsert({ isSuccessful: true, isLoading: false });
      this.notifier.notify('success', this.i18next.transform('message:successful-added'));
    });
  }

  removeReseller(reseller: PortalAPI.Reseller): void {
    this.resellerStore.updateRemove({ isLoading: true, isSuccessful: false, errors: null });

    try {
      this.apollo.mutate({ mutation: removeResellerQuery, variables: { id: reseller.id } }).subscribe((results) => {
        if (results.errors || !results.data) {
          this.resellerStore.setError(results.errors);
          this.resellerStore.updateUpsert({ errors: results.errors, isLoading: false });
          return;
        }

        this.resellerStore.remove(reseller.id);
        this.resellerStore.updateRemove({ isSuccessful: true, isLoading: false });
        this.notifier.notify('success', this.i18next.transform('message:successful-removed'));
      });
    } catch (e) {
      this.notifier.notify('error', this.i18next.transform('message:something-went-wrong'));
    }
  }
}
